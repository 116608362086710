// React APIs
import React from "react";

// 機能内部のAPIs
import { ModelHoc } from './ModelHoc';
import { ModelList } from './ModelList';
import { ModelDelete } from './ModelDelete';
import { ModelSearch } from './ModelSearch';
import { ModelDetail } from './ModelDetail';
import { ModelUpload } from './ModelUpload';

/**
 * 所要計画のコンポネート
 */
export function Model() {
    const model = ModelHoc(ModelList, ModelDelete, ModelSearch, ModelDetail, ModelUpload);

    return (
        <>
            { model }
        </>
    );
}