import React, { useEffect, useReducer, useState } from 'react';
import { useIntl, } from 'react-intl';

import { Alert, Drawer, List, Upload, } from 'antd';
import { UploadFile, UploadFileStatus } from "antd/es/upload/interface";
import { InboxOutlined } from '@ant-design/icons';

import dayjs, { Dayjs } from 'dayjs';
import XLSX from 'xlsx';

import './index.css';

import { excelUtil } from '../util';

const { Dragger } = Upload;

export interface Props {
  row: RowFormat;
  validate: (data: RowData[]) => any;
  upload: (data: any) => void;
}

export interface UnitValidator {
  validate: (value: any) => boolean;
  message: string;
}

export interface ColumnFormat {
  // キー
  key: string;
  // 項目名
  field: string;
  // タイプ
  type: 'string' | 'int' | 'decimal' | 'date';
  // 必須
  isRequired: boolean;
  // 単項目チェック
  validators: UnitValidator[];
}

export interface RowFormat {
  // 項目リスト
  columns: ColumnFormat[];
  // Unique項目
  uniqueItems: string[];
}

export interface RowData {
  [key: string]: string | number | Dayjs;
}

interface FixUploadFile extends UploadFile {
  uid: string;
  size: number;
  name: string;
  status: UploadFileStatus;
  type: string;
  percent: number;
}

interface UploadFileState {
  fileList: FixUploadFile[];
}

interface UploadFileAction {
  type: 'init' | 'process' | 'finish';
  file?: any;
  percent?: number;
  status?: UploadFileStatus;
}

const reducer = (state: UploadFileState, action: UploadFileAction) => {
  if (action.type === 'init') {

    const newFile: FixUploadFile = {
      uid: '1',
      size: action.file.size,
      name: action.file.name,
      status: 'uploading',
      type: action.file.type,
      percent: 10,
    };

    return { fileList: [newFile] };
  }
  else if (action.type === 'process' && !!action.percent) {

    return { fileList: [{ ...state.fileList[0], percent: state.fileList[0].percent + action.percent }, ...(state.fileList.slice(1))] };
  }
  else if (action.type === 'finish' && !!action.status) {

    return { fileList: [{ ...state.fileList[0], percent: 100, status: action.status }, ...(state.fileList.slice(1))] };
  }
  return state;
};

const init = (fileList: FixUploadFile[]) => {
  return { fileList: fileList };
};


export function CommonExcelUpload(props: Props) {

  // 共通のstates
  const intl = useIntl();
  const [state, dispath] = useReducer(reducer, [], init);
  const [errorList, setErrorList] = useState<string[]>([]);

  const onImportExcel = (options: any) => {
    const { file } = options;

    dispath({ type: 'init', file: file });
    setErrorList([]);

    const fileReader = new FileReader();
    fileReader.onload = readExcel;
    fileReader.readAsBinaryString(file);
  }

  const equalsRowDataItem = (item1: string | number | Dayjs, item2: string | number | Dayjs) => {

    if (!item1 || !item2) {
      return false;
    }

    if (!dayjs.isDayjs(item1)) {
      return item1 === item2;
    }

    if (!dayjs.isDayjs(item2)) {
      return false;
    }

    return item1.isSame(item2, 'day');
  }

  const fixRows = (data: any[]): RowData[] => {

    const rowDatas: RowData[] = [];
    const errors: string[] = [];

    // 行ごと解析する
    for (let i = 0; i < data.length; i++) {

      const row = data[i];
      const rowData: RowData = {};

      // 単項目チェックを実施する
      for (let column of props.row.columns) {

        const key = intl.formatMessage({ id: column.key });

        // 存在チェック
        if (row[key] === undefined) {
          if (column.isRequired) {
            errors.push(intl.formatMessage({ id: 'message.excelUploadFromatError' }, { i: i + 1, key: key }));
          }
          // 項目がなければ、後継ぎのチェックが要らない
          continue;
        }
        const inputValue = row[key].toString().trim();
        if (inputValue === '') {
          if (column.isRequired) {
            errors.push(intl.formatMessage({ id: 'message.excelUploadFromatError' }, { i: i + 1, key: key }));
          }
          // 項目がなければ、後継ぎのチェックが要らない
          continue;
        }

        let value, naFlag = false;

        switch (column.type) {
          case 'int':
            value = parseInt(inputValue);

            if (isNaN(value)) {
              errors.push(intl.formatMessage({ id: 'message.excelUploadFromatError' }, { i: i + 1, key: key }));
              naFlag = true;
            }
            break;
          case 'decimal':
            value = parseFloat(inputValue);

            if (isNaN(value)) {
              errors.push(intl.formatMessage({ id: 'message.excelUploadFromatError' }, { i: i + 1, key: key }));
              naFlag = true;
            }
            break;
          case 'date':
            value = excelUtil.toDate(inputValue);
            if (column.isRequired && value === null) {
              errors.push(intl.formatMessage({ id: 'message.excelUploadFromatError' }, { i: i + 1, key: key }));
            }
            break;
          default:

            value = inputValue;
            break;
        }

        if (naFlag) {
          continue;
        }

        // 指定チェック
        for (let validator of column.validators) {
          if (validator.validate(value)) {
            errors.push(intl.formatMessage({ id: validator.message }, { i: i + 1, key: key }));
            naFlag = true;
          }
        }

        if (naFlag) {
          continue;
        }

        rowData[column.field] = value;
      }

      // 重複チェック
      for (let j = 0; j < rowDatas.length; j++) {

        if (props.row.uniqueItems.length === 0) {
          continue;
        }

        const existFlag = !props.row.uniqueItems.some(uniqueItem => !equalsRowDataItem(rowDatas[j][uniqueItem], rowData[uniqueItem]));
        if (existFlag) {
          errors.push(intl.formatMessage({ id: 'message.excelUploadDuplicatedError' }, { i: i + 1, j: j + 1 }));
        }
      }

      rowDatas.push(rowData as RowData);
    }

    if (errors.length > 0) {

      setErrorList(errors);

      throw errors;
    }

    dispath({ type: 'process', percent: 20 });

    return rowDatas;
  }

  const readExcel = async (ev: ProgressEvent<FileReader>) => {

    try {
      let count = 0;
      if (!!ev.target) {

        const { result } = ev.target;
        const workbook = XLSX.read(result, { type: 'binary', cellText: false, cellDates: false, });
        for (const sheet in workbook.Sheets) {
          if (workbook.Sheets.hasOwnProperty(sheet)) {
            const data: any[] = XLSX.utils.sheet_to_json(workbook.Sheets[sheet], {
              defval: undefined,
              raw: false,
              dateNF: 'yyyy/mm/dd',
            });

            const rowDatas: RowData[] = fixRows(data);

            const uploadData = await props.validate(rowDatas);

            dispath({ type: 'finish', status: 'done' });

            props.upload(uploadData);

            count++;
            break;
          }
        }
      }
      if (count === 0) {
        setErrorList([intl.formatMessage({ id: 'message.excelUploadFileNotFoundError' })]);
        dispath({ type: 'finish', status: 'error' });

        props.upload([]);
      }
    } catch (errors) {
      if (Array.isArray(errors)) {
        setErrorList([intl.formatMessage({ id: 'message.excelUploadError' }), ...errors]);
      }
      console.log(errors);

      dispath({ type: 'finish', status: 'error' });

      props.upload([]);
    }
  };

  return (
    <>
      {errorList.length > 0 ? (
        <Alert
          message={intl.formatMessage({ id: 'message.error' })}
          description={<List
            dataSource={errorList}
            renderItem={item => <List.Item>{item}</List.Item>}
          />}
          type='error'
          showIcon
        />
      ) : (<></>)}
      <Dragger
        accept='.xlsm,.xlsx,.xls'
        maxCount={1}
        showUploadList={{ showRemoveIcon: false }}
        customRequest={onImportExcel}
        fileList={state.fileList}>
        <p className='ant-upload-drag-icon'>
          <InboxOutlined />
        </p>
        <p className='ant-upload-text'>Click or drag file to this area to upload</p>
        {/* <p className='ant-upload-hint'>
          Strictly prohibit from uploading company data or other band files
        </p> */}
      </Dragger>
    </>
  );
}

