import { Dayjs } from 'dayjs';
import { DateRange, } from '../common';
import { api, converter, CommonListVo, PagingSearchParaVo, SearchParaPropertis } from '../api'

const dayjs = require('dayjs');

// アクセスURI
const uri = '/api/forecasts';

// ソート順項目定義、repositoryと一致:受注者コード,受注者名称,初期納期,品番, 品名
const orderByColumns = ["supplier", "supplierName", "item", "itemName", "iniDeliveryDate"];

// 検索条件Dtoの項目一覧
const properties: SearchParaPropertis = {

  // 受注者コード
  suppliers: 'array',
  // 受注者名称
  supplierName: 'string',
  // 品目コード
  items: 'array',
  // 品名
  itemName: 'string',
  // 初期納期
  iniDeliveryDate: 'DateRange',
};
// 所要計画検索条件Dto
export interface ForecastSearchDto extends PagingSearchParaVo {
  // 受注者コード
  suppliers: string[];
  // 受注者名称
  supplierName?: string;
  // 品番
  items: string[];
  // 品名
  itemName?: string;
  // 初期納期
  iniDeliveryDate: DateRange;
}
// 所要計画の一覧VO
interface ForecastListRecordVo {
  // ID
  id: number;
  // MRP処理日
  dataCreationDate:number;
  // 計画番号
  planNo: string;
  // planNo
  detailNo: number;
  // 購買依頼日
  planningDate: number;
  // 発注者コード
  buyer: string;
  // 発注者名称
  buyerName: string;
  // 受注者コード
  supplier: string;
  // 受注者
  supplierName: string;
  // 品番
  item: string;
  // 品名
  itemName: string;
  // 納品予定日
  iniDeliveryDate: number;
  // 数量
  quantity: number;
  // 単位
  unit: string;
  // 購買担当
  purchasingManagerName: string;
}

// 所要計画の一覧DTO
export interface ForecastListRecordDto {
  // ID
  id: number;
  // MRP処理日
  dataCreationDate:Dayjs;
  // 計画番号
  planNo: string;
  // planNo
  detailNo: number;
  // 購買依頼日
  planningDate: Dayjs;
  // 発注者コード
  buyer: string;
  // 発注者名称
  buyerName: string;
  // 受注者コード
  supplier: string;
  // 受注者
  supplierName: string;
  // 品番
  item: string;
  // 品名
  itemName: string;
  // 初期納期
  iniDeliveryDate: Dayjs;
  // 数量
  quantity: number;
  // 数量(数量＋単位)
  quantity1: string;
  // 単位
  unit: string;
  // 購買担当
  purchasingManagerName: string;
}

// VOからListRecordDtoを変換する
const vo2ListRecordDto = (vo: ForecastListRecordVo): ForecastListRecordDto => {
  return {
    ...vo,
    quantity1:vo.quantity + " " +vo.unit,
    dataCreationDate: dayjs(vo.dataCreationDate),
    planningDate: dayjs(vo.planningDate),
    iniDeliveryDate: dayjs(vo.iniDeliveryDate),
  } as ForecastListRecordDto;
};

// 取得API
export const get = (query: string): Promise<[number, ForecastListRecordDto[]]> => {

  return api.get(`${uri}${query}`)
    .then((result: any) => {
      const data = result as CommonListVo<ForecastListRecordVo>;
      return [data.total, data.vos.map(vo2ListRecordDto)];
    });
};

// 履歴取得API
export const getHistoryDates = (): Promise<Dayjs[]> => {

  return api.get(`${uri}/history/dates`)
    .then((result: any) => {
      const data = result as number[];
      return data.map(dayjs);
    });
};

// 履歴取得API
export const getHistory = (query: string, historyDate: string): Promise<[number, ForecastListRecordDto[]]> => {

  return api.get(`${uri}/history/${historyDate}${query}`)
    .then((result: any) => {
      const data = result as CommonListVo<ForecastListRecordVo>;
      return [data.total, data.vos.map(vo2ListRecordDto)];
    });
};

// searchから検索条件Dtoへ変換する
export const search2Dto = (query?: string): ForecastSearchDto => {

  return converter.search2Dto(properties, orderByColumns, query) as ForecastSearchDto;
};

// 検索条件Dtoからsearchへ変換する
export const dto2search = (researchDto: ForecastSearchDto): string => {

  return converter.dto2search(properties, orderByColumns, researchDto);
};
