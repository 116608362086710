import React, { useState, useEffect } from "react";
import { FormattedMessage } from "react-intl";

import { Button, } from "antd";
import { DatePicker, DateRange } from '.'

const { RangePicker } = DatePicker;

interface Props {
  values: DateRange;
  onChange: (values: DateRange) => void;
  picker?: 'date' | 'week' | 'month' | 'quarter' | 'year';
}

export function RangeFilter(props: Props) {
  const [value, setValue] = useState(props.values);

  useEffect(() => {
    setValue(props.values);
  }, [props.values]);

  const onChange = (inputValues: DateRange | null) => {

    const values: DateRange = inputValues ? inputValues : [null, null];

    setValue(values);
  };

  const onSearch = () => {

    props.onChange(value);
  };

  const onReset = () => {

    setValue([null, null]);
  };

  return (
    <div className='filter'>
      <div>
        <RangePicker allowEmpty={[true, true]} value={value} onChange={onChange} picker={props.picker}/>
      </div>
      <div className='filterRow'>
        <Button type="primary" onClick={onSearch}><FormattedMessage id='common.conditions' /></Button>
        <Button className='reset-btn' onClick={onReset}><FormattedMessage id='common.reset' /></Button>
      </div>
    </div>
  );
}
