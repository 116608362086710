import React from 'react';
import { Card, Col, Row, Statistic } from 'antd';
import { useIntl, } from 'react-intl';
import './index.css';
import { displayUtil } from "../util";
import { HomeListRecordDto } from './api'
import { useUser } from '../authorization';

export interface Props {
  data?: HomeListRecordDto;
  clickDetail: (batchName: string) => void
}

interface StatisticValues {
  key: string | JSX.Element;
  value: any;
  url: string
}

interface BlockValues {

  //二级标题
  secondTitle?: string | JSX.Element;
  //统计数据,标题,url
  statisticValues: StatisticValues[];
}

interface ModuleDashboard {
  //一级标题
  firstTitle: string;

  blockValues: BlockValues[];
}
export function HomeList(props: Props) {

  //ユーザー
  const user = useUser();

  const intl = useIntl();

  const statisticalCard = (moduleDashboard: ModuleDashboard): any => {
    const block: JSX.Element[] = [];

    for (let value of moduleDashboard.blockValues) {
      block.push(
        <>

          {(value.secondTitle !== undefined || value.secondTitle !== null) ? <Row><Col span={1}></Col>{value.secondTitle}</Row> : ''}
          <Row gutter={16}>
            {
              innerCard(value.statisticValues)
            }
          </Row>

        </>
      )
    }

    return <>
      <Col span={1}></Col>
      <Col span={7} style={{ boxShadow: '0px 3px 16px rgba(0, 0, 0, 0.12)' }} >
        <Card title={moduleDashboard.firstTitle} headStyle={{ textAlign: 'left', textDecoration: 'underline', lineHeight: 3, fontWeight: 'bold', fontSize: 20 , padding:'0 0 0 7px'}} bordered={false}>
          {block}
        </Card>
      </Col>
    </>
  }


  const innerCard = (values: StatisticValues[]): JSX.Element => {
    const elements: JSX.Element[] = [];
    if (values !== undefined) {
      values.forEach((value) => {
        elements.push(<Col span={2}></Col>)
        elements.push(<Col span={10}><Statistic title={value.key} valueStyle={{paddingLeft:25}} value={value.value} formatter={(v) => {
          return <a href={value.url}>{v}</a>;
        }} /></Col>);
      });
    }
    return <>{elements}</>;
  }


  // 1．納入管理(supplier)
  const supplierResponseSecond = [
    // 納入回答
    {
      secondTitle: intl.formatMessage({ id: 'orderResponse' }),
      statisticValues: [{
        //未読データ件数 
        key: intl.formatMessage({ id: 'home.unreadData' }),
        value: props.data?.orderUnreadQuantity,
        url: '/orderresponses',
      }] as StatisticValues[]
    },
    // 出荷処理
    {
      secondTitle: intl.formatMessage({ id: 'delivery.unprocessed' }),
      statisticValues: [{
        key: intl.formatMessage({ id: 'home.waitingShipped' }),
        value: props.data?.waitForDeliveryQuantity,
        url: '/deliveryoutput'
      }]
    },
  ] as BlockValues[];
  
  if(props.data?.newestOrderDetailBatch?.id !== null) {
    supplierResponseSecond.push(
      // 最新バッチ情報
      {
        secondTitle: intl.formatMessage({id: 'batch.newestBatchInfo'}, {batchName: intl.formatMessage({ id: 'orderResponse.detail' })}),
        statisticValues: [
        {
          key: intl.formatMessage({id: 'batch.dataImportTime'}),
          value: displayUtil.date(props.data?.newestOrderDetailBatch?.batchRunTime) + ' ' + displayUtil.time(props.data?.newestOrderDetailBatch?.batchRunTime),
          url: '/orderresponses'
        }]
      }
    )
  }

  // 1．納入管理(buyer)
  const buyerResponseSecond = [
    // 納入回答
    {
      secondTitle: intl.formatMessage({ id: 'orderResponse' }),
      statisticValues: [{
        //未読データ件数 
        key: intl.formatMessage({ id: 'home.unreadData' }),
        value: props.data?.orderUnreadQuantity,
        url: '/orderresponses',
      }] as StatisticValues[]
    }
  ] as BlockValues[]

  // batch一次都没执行的场合，不会显示batch信息
  if(props.data?.newestOrderDetailBatch?.id !== null) {
    buyerResponseSecond.push(
      // 最新バッチ情報
      {
        secondTitle: <>{intl.formatMessage({id: 'batch.newestBatchInfo'}, {batchName: intl.formatMessage({id: 'orderResponse.detail'})})}&nbsp;&nbsp;&nbsp;<a onClick={() => {props.clickDetail('inOrderDetail')}}>{intl.formatMessage({id: 'batch.more'})}&gt;</a></>,
        statisticValues: [{
          //batch执行时间
          key: intl.formatMessage({id: 'batch.dataImportTime'}),
          value: displayUtil.date(props.data?.newestOrderDetailBatch?.batchRunTime) + ' ' + displayUtil.time(props.data?.newestOrderDetailBatch?.batchRunTime),
          url: '/orderresponses',
        },
        {
          // batch新数据件数
          key: intl.formatMessage({id: 'batch.newDataQuantity'}),
          value: props.data?.newestOrderDetailBatch?.batchInsertCount,
          url: '/orderresponses',
        },
        {
          // バッチ更新数量 
          key: intl.formatMessage({id: 'batch.updateDataQuantity'}),
          value: props.data?.newestOrderDetailBatch?.batchUpdateCount,
          url: '/orderresponses',
        },
        {
          // バッチエラー数量 
          key: intl.formatMessage({id: 'batch.errorDataQuantity'}),
          value: props.data?.newestOrderDetailBatch?.batchErrorCount,
          url: '/orderresponses',
        }] as StatisticValues[]
      }
    )
  }

  //２．所要計画管理
  const forecastSecond = [
    {
      statisticValues: [{
        // 最新データ着信日 
        key: intl.formatMessage({ id: 'home.arrivedate' }),
        value: displayUtil.date(props.data?.focastDateQuantity),
        url: '/forecasts',
      }] as StatisticValues[]
    }
  ] as BlockValues[];

  //３．検収管理 (supplier)
  const supplierAcceptanceSecond = [
    {
      statisticValues: [{
        // 未読データ件数 
        key: intl.formatMessage({ id: 'home.unreadData' }),
        value: props.data?.acceptancesQuantity,
        url: '/acceptances',
      }] as StatisticValues[]
    },
  ] as BlockValues[];

  if(props.data?.newestAcceptanceBatch?.id !== null) {
    supplierAcceptanceSecond.push(
      {
        secondTitle: intl.formatMessage({id: 'batch.newestBatchInfo'}, {batchName: intl.formatMessage({ id: 'acceptance' })}),
        statisticValues: [{
          // バッチ連携時間 
          key: intl.formatMessage({id: 'batch.dataImportTime'}),
          value: displayUtil.date(props.data?.newestAcceptanceBatch?.batchRunTime) + ' ' + displayUtil.time(props.data?.newestAcceptanceBatch?.batchRunTime),
          url: '/acceptances',
        },] as StatisticValues[]
      }
    )
  }

  //３．検収管理 (buyer)
  const buyerAcceptanceSecond = [
    {
      statisticValues: [{
        // 未読データ件数 
        key: intl.formatMessage({ id: 'home.unreadData' }),
        value: props.data?.acceptancesQuantity,
        url: '/acceptances',
      }] as StatisticValues[]
    },
  ] as BlockValues[];

  // batch一次都没执行的场合，不会显示batch信息
  if(props.data?.newestAcceptanceBatch?.id !== null) {
    buyerAcceptanceSecond.push(
      {
        secondTitle: <>{intl.formatMessage({id: 'batch.newestBatchInfo'}, {batchName: intl.formatMessage({id: 'acceptance'})})}&nbsp;&nbsp;&nbsp;<a onClick={() => {props.clickDetail('inAcceptance')}}>{intl.formatMessage({id: 'batch.more'})}&gt;</a></>,
        statisticValues: [{
          // バッチ連携時間 
          key: intl.formatMessage({id: 'batch.dataImportTime'}),
          value: displayUtil.date(props.data?.newestAcceptanceBatch?.batchRunTime) + ' ' + displayUtil.time(props.data?.newestAcceptanceBatch?.batchRunTime),
          url: '/acceptances',
        },
        {
          // バッチ新規数量 
          key: intl.formatMessage({id: 'batch.newDataQuantity'}),
          value: props.data?.newestAcceptanceBatch?.batchInsertCount,
          url: '/acceptances',
        },
        {
          // バッチ更新数量 
          key: intl.formatMessage({id: 'batch.updateDataQuantity'}),
          value: props.data?.newestAcceptanceBatch?.batchUpdateCount,
          url: '/acceptances',
        },
        {
          // バッチエラー数量 
          key: intl.formatMessage({id: 'batch.errorDataQuantity'}),
          value: props.data?.newestAcceptanceBatch?.batchErrorCount,
          url: '/acceptances',
        }] as StatisticValues[]
      }
    )
  }

  //  ４．(1)見積管理(supplier)
  const estimateSupplierSecond = [
    {
      secondTitle: intl.formatMessage({ id: 'estimatesSub' }),
      statisticValues: [{
        //未読データ件数 
        key: intl.formatMessage({ id: 'home.unreadData' }),
        value: props.data?.estimateUnreadQuantity,
        url: '/estimations',
      }, {
        //未回答データ件数 
        key: intl.formatMessage({ id: 'home.notanswered' }),
        value: props.data?.estimateUnresponseQuantity,
        url: '/estimations',
      }] as StatisticValues[]
    },
    {
      secondTitle: intl.formatMessage({ id: 'home.OurRequestQuotation' }),
      statisticValues: [{
        //未読データ件数 
        key: intl.formatMessage({ id: 'home.unreadData' }),
        value: props.data?.supplierEstimateUnreadQuantity,
        url: '/supplierestimates',
      }, {
        //未クローズ案件数
        key: intl.formatMessage({ id: 'home.estiamteUnclose' }),
        value: props.data?.supplierEstimateUncloseQuantity,
        url: '/supplierestimates',
      }] as StatisticValues[]
    }
  ]

  //  ４．(1)見積管理(buyer)
  const estimateBuyerSecond = [
    {
      secondTitle: intl.formatMessage({ id: 'estimatesSub' }),
      statisticValues: [{
        //未読データ件数 
        key: intl.formatMessage({ id: 'home.unreadData' }),
        value: props.data?.estimateUnreadQuantity,
        url: '/estimations',
      }, {
        //未クローズ案件数
        key: intl.formatMessage({ id: 'home.estiamteUnclose' }),
        value: props.data?.estimateUncloseQuantity,
        url: '/estimations',
      }] as StatisticValues[]
    },
    {
      secondTitle: intl.formatMessage({ id: 'supplierEstimates' }),
      statisticValues: [{
        key: intl.formatMessage({ id: 'home.unreadData' }),
        value: props.data?.supplierEstimateUnreadQuantity,
        url: '/supplierestimates'
      }, {
        key: intl.formatMessage({ id: 'home.notanswered' }),
        value: props.data?.supplierEstimateUnresponseQuantity,
        url: '/supplierestimates'
      }] as StatisticValues[]
    }
  ]

  const moldSupplierSecond = [{
    secondTitle: intl.formatMessage({ id: 'inventoryConfirm' }),
    statisticValues: [{
      //未読データ件数 
      key: intl.formatMessage({ id: 'home.unreadData' }),
      value: props.data?.moldUnreadQuantity,
      url: '/modelassets',
    }, {
      //未回答データ件数 
      key: intl.formatMessage({ id: 'home.notanswered' }),
      value: props.data?.moldUnresponseQuantity,
      url: '/modelassets',
    }] as StatisticValues[]
  }
  ]

  const moldBuyerSecond = [
    {
      secondTitle: intl.formatMessage({ id: 'inventoryConfirm' }),
      statisticValues: [{
        //未読データ件数 
        key: intl.formatMessage({ id: 'home.unreadData' }),
        value: props.data?.moldUnreadQuantity,
        url: '/modelassets',
      }, {
        //確認待データ件数  
        key: intl.formatMessage({ id: 'home.notanswered' }),
        value: props.data?.moldWaitForComQuantity,
        url: '/modelassets',
      }] as StatisticValues[]
    },
    {
      secondTitle: intl.formatMessage({ id: 'home.moldMaster' }),
      statisticValues: [{
        //未読データ件数 
        key: intl.formatMessage({ id: 'home.detailInfoIncompleteNumber' }),
        value: props.data?.moldMasterUnreadQuantity,
        url: '/models',
      }] as StatisticValues[]
    }
  ]

  const docSupplier = [{
    statisticValues: [{
      //未読データ件数 
      key: intl.formatMessage({ id: 'home.unreadData' }),
      value: props.data?.docUnreadQuantity,
      url: '/docs',
    }, {
      //未読データ件数 
      key: intl.formatMessage({ id: 'home.notanswered' }),
      value: props.data?.docUnresponseQuantity,
      url: '/docs',
    }]
  }
  ]

  const docBuyer =[
    {
      statisticValues: [{
        //未読データ件数 
        key: intl.formatMessage({ id: 'home.unreadData' }),
        value: props.data?.docUnreadQuantity,
        url: '/docs',
      }]
    }
  ]

  const payBuyer = []

  // batch一次都没执行的场合，不会显示batch信息
  if(!!props.data?.newestPaymentBatch?.id) {
    payBuyer.push(
      {
        secondTitle: <>{intl.formatMessage({id: 'batch.newestBatchInfo'}, {batchName: intl.formatMessage({id: 'pay'})})}&nbsp;&nbsp;&nbsp;<a onClick={() => {props.clickDetail('inPayment')}}>{intl.formatMessage({id: 'batch.more'})}&gt;</a></>,
        statisticValues: [{
          // バッチ連携時間 
          key: intl.formatMessage({id: 'batch.dataImportTime'}),
          value: displayUtil.date(props.data?.newestPaymentBatch?.batchRunTime) + ' ' + displayUtil.time(props.data?.newestPaymentBatch?.batchRunTime),
          url: '/pays',
        },
        {
          // バッチ新規数量 
          key: intl.formatMessage({id: 'batch.newDataQuantity'}),
          value: props.data?.newestPaymentBatch?.batchInsertCount,
          url: '/pays',
        },
        {
          // バッチ更新数量 
          key: intl.formatMessage({id: 'batch.updateDataQuantity'}),
          value: props.data?.newestPaymentBatch?.batchUpdateCount,
          url: '/pays',
        },
        {
          // バッチエラー数量 
          key: intl.formatMessage({id: 'batch.errorDataQuantity'}),
          value: props.data?.newestPaymentBatch?.batchErrorCount,
          url: '/pays',
        }] as StatisticValues[]
      }
    )
  }

  const supplierDeliveryAcceptanceData = {
    firstTitle: intl.formatMessage({ id: 'delivery' }),
    blockValues: user?.isSupplier ? supplierResponseSecond : buyerResponseSecond
  } as ModuleDashboard;

  const forecastData = {
    firstTitle: intl.formatMessage({ id: 'forecast' }),
    blockValues: forecastSecond
  } as ModuleDashboard;

  const acceptanceData = {
    firstTitle: intl.formatMessage({ id: 'acceptance' }),
    blockValues: user?.isSupplier ? supplierAcceptanceSecond : buyerAcceptanceSecond
  } as ModuleDashboard;

  const estimateData = {
    firstTitle: intl.formatMessage({ id: 'estimate' }),
    blockValues: user?.isSupplier ? estimateSupplierSecond : estimateBuyerSecond
  } as ModuleDashboard;

  const moldData = {
    firstTitle: intl.formatMessage({ id: 'inventory' }),
    blockValues: user?.isSupplier ? moldSupplierSecond : moldBuyerSecond
  }

  const docData = {
    firstTitle: intl.formatMessage({ id: 'docInfo' }),
    blockValues: user?.isSupplier ? docSupplier : docBuyer
  }

  const payDate = {
    firstTitle: intl.formatMessage({ id: 'pay' }),
    blockValues: payBuyer
  }

  const deliveryAcceptanceSupplier = statisticalCard(supplierDeliveryAcceptanceData);
  const forecastDashBoard = statisticalCard(forecastData);
  const acceptanceDashboard = statisticalCard(acceptanceData);
  const estimateDashboard = statisticalCard(estimateData);
  const moldDashboard = statisticalCard(moldData);
  const docDashboard = statisticalCard(docData);
  const payDashboard = statisticalCard(payDate);

  return (
    <>
      <Row style={{ margin: '1cm 1cm 1cm 0' }} gutter={[16, 16]} >
        {deliveryAcceptanceSupplier}
        {forecastDashBoard}
        {acceptanceDashboard}
        {estimateDashboard}
        {moldDashboard}
        {docDashboard}
        {user?.serviceState === 'jp' && !user?.isSupplier && payDashboard}
      </Row>
    </>
  );

}