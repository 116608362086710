// React APIs
import React from 'react';

import { CommonExcelUpload } from '../common';

// 機能内部のAPIs
import { SupplierEstimatesList } from './SupplierEstimatesList';
import { SupplierEstimatesSearch } from './SupplierEstimatesSearch';
import { SupplierEstimatesHoc } from './SupplierEstimatesHoc';
import { SupplierEstimatesDetail } from './SupplierEstimatesDetail';
import { SupplierEstimatesDetailDetail } from './SupplierEstimatesDetailDetail';
import { SupplierEstimatesUpload } from './SupplierEstimatesUpload';
import { SupplierEstimatesAnswerUpload } from './SupplierEstimatesAnswerUpload';


/**
 * 納期回答のコンポネート
 */
export function SupplierEstimates() {

  const SupplierEstimates = SupplierEstimatesHoc(SupplierEstimatesList, SupplierEstimatesSearch, SupplierEstimatesDetail, SupplierEstimatesDetailDetail, SupplierEstimatesUpload, SupplierEstimatesAnswerUpload);

  return (
    <>
      {SupplierEstimates}
    </>
  );
}
