import { Dayjs } from 'dayjs';
import { DateRange, NumberRange, } from '../common';
import { api, PagingSearchParaVo, CommonListVo, SearchParaPropertis, converter, } from '../api'

const dayjs = require('dayjs');

// アクセスURI
const uri = '/api/acceptances/';

// ページ毎行数
const rowCount = 10;

//1. 検索画面
// ソート順項目定義、repositoryと一致
const orderByCloumns = [
  // 受注者コード
  'supplier',
  // 受注者名称
  'supplierName',
  // 購買担当者
  'purchasingManager',
  // 検収番号
  'acceptanceNo',
  // 検収日
  'acceptanceDate',
  // 納品番号
  'deliveryNo',
  // 注文番号
  'orderNo',
  // 品番
  'item',
  // 品名
  'itemName',
  // 保管場所コード
  'locationCd',
  // 保管場所
  'locationName',
  // 検収単価
  'unitPrice',
  // 検収金額
  'amount',
  // 注文金額
  'orderAmount',
];

// 検索条件Dtoの項目一覧
const properties: SearchParaPropertis = {

  // 受注者コード
  suppliers: 'array',
  // 受注者名称
  supplierName: 'string',
  // 購買担当
  purchasingManager: 'string',
  // 検収日
  acceptanceDate: 'DateRange',
  // 検収番号
  acceptanceNo: 'string',
  //  納品番号
  deliveryNo: 'string',
  // 注文番号
  orderNo: 'string',
  // 品番
  items: 'array',
  // 品名
  itemName: 'string',
  // 保管場所
  locationName: 'string',
  // 保管場所コード
  locationCd: 'string',
  // 検収単価
  unitPrice: 'NumberRange',
  // 検収金額
  amount: 'NumberRange',
  // 注文金額
  orderAmount: 'NumberRange',
};
// 検収管理検索条件Dto
export interface AcceptanceSearchDto extends PagingSearchParaVo {
  // 受注者コード
  suppliers: string[];
  // 受注者名称
  supplierName?: string;
  // 購買担当
  purchasingManager?: string;
  // 検収日
  acceptanceDate: DateRange;
  // 検収番号
  acceptanceNo?: string;
  // 注文番号
  orderNo?: string;
  //納品番号
  deliveryNo?: string;
  // 品番
  items: string[];
  // 品名
  itemName?: string;
  // 保管場所
  locationName?: string;
  // 保管場所コード
  locationCd?:string;
  // 検収単価
  unitPrice: NumberRange;
  // 検収金額
  amount: NumberRange;
  // 注文金額
  orderAmount: NumberRange;
}

// searchから検索条件Dtoへ変換する
export const search2Dto = (query?: string): AcceptanceSearchDto => {

  return converter.search2Dto(properties, orderByCloumns, query) as AcceptanceSearchDto;
};

// 検索条件Dtoからsearchへ変換する
export const dto2search = (researchDto: AcceptanceSearchDto): string => {

  return converter.dto2search(properties, orderByCloumns, researchDto);
};

//２. 一覧画面
// 検収一覧のVOクラス
interface AcceptanceListRecordVo {
  //ID
  id: number;
  // 発注者コード
  buyer: string;
  // 発注者名称
  buyerName: string;
  // 受注者コード
  supplier: string;
  // 受注者名称
  supplierName: string;
  // 検収日
  acceptanceDate: number;
  // 品目コード
  item: string;
  // 品目名称
  itemName: string;
  // 注文番号
  orderNo: string;
  // 明細番号
  detailNo: number; 
  // 納品番号
  deliveryNo: string;
  // 検収番号
  acceptanceNo: string;
  // 検収明細番号
  acceptanceDetailNo: number;
  // 数量
  quantity: number;
  // 単位
  unit: string;
  // 単価
  unitPrice: number;
  // 金額
  amount: number;
  // 注文単価
  orderUnitPrice: number;
  // 注文数量
  orderQuantity: number;
  // 注文金額
  orderAmount: number;
  // 通貨
  currencyCode: string;
  // 保管場所
  locationCd: string;
  // 保管場所名称
  locationName: string,
  // 未读flag
  unReadFlag: boolean;
}

// 検収一覧Dto
export interface AcceptanceListRecordDto {

  //ID
  id: number;
  // 発注者コード
  buyer: string;
  // 発注者名称
  buyerName: string;
  // 受注者コード
  supplier: string;
  // 受注者名称
  supplierName: string;
  // 検収日
  acceptanceDate: Dayjs;
  // 品番
  item: string;
  // 品名
  itemName: string;
  // 注文番号
  orderNo: string;
  // 注文番号1
  orderNo1: string;
  // 明細番号
  detailNo: number;
  // 納品番号
  deliveryNo: string;
  // 検収番号
  acceptanceNo: string;
  // 検収番号1
  acceptanceNo1: string;
  // 検収明細番号
  acceptanceDetailNo: number;
  // 数量
  quantity: number;
  // 数量1
  quantity1: string;
  // 単位
  unit: string;
  // 単価
  unitPrice: number;
  // 金額
  amount: number;
  // 注文単価
  orderUnitPrice: number;
  // 注文数量
  orderQuantity: number;
  // 注文数量1
  orderQuantity1: string;
  // 注文金額
  orderAmount: number;
  // 通貨
  currencyCode:string;
  // 保管場所
  locationCd: string;
  // 保管場所名称
  locationName: string;
  // 未读flag
  unReadFlag: boolean;

}
const vo2ListRecordDto = (vo: AcceptanceListRecordVo): AcceptanceListRecordDto => {
  return {
    ...vo,
    // acceptanceNo1: (vo.acceptanceNo+'-'+vo.acceptanceDetailNo),
    // orderNo1: (vo.orderNo+'-'+vo.detailNo),
    quantity1: (vo.quantity+' '+vo.unit),
    orderQuantity1: vo.orderQuantity === null || vo.orderQuantity === 0 ? '' : (vo.orderQuantity+' '+vo.unit),
    acceptanceDate: dayjs(vo.acceptanceDate),
  } as AcceptanceListRecordDto;
};
export const get = (query: string): Promise<[number, AcceptanceListRecordDto[]]> => {

  if (!query || query.length === 0) {
    query = `?rowCount=${rowCount}`;
  } else {
    query += `&rowCount=${rowCount}`;
  }

  return api.get(`${uri}${query}`)
    .then((result: any) => {

      const data = result as CommonListVo<AcceptanceListRecordVo>;
      return [data.total, data.vos.map(vo2ListRecordDto)];
    });
};

//3.詳細画面
// 検収詳細のVOクラス
interface AcceptanceDetailVo {

  //ID
  id: number;
  // 注文番号
  orderNo: string;
  // 明細番号
  detailNo: string;
  // 注文年月日
  orderDate: number;
  //納品番号
  deliveryNo: string;
  // 発注者名称
  buyerName: string;
  // 発注者コード
  buyer: string;
  // 受注者名称
  supplierName: string;
  // 受注者コード
  supplier: string;
  // 発注者部門コード
  buyerDepartment :string;
  //　発注者部門名称
  buyerDepartmentName :string;
  // 購買担当
  purchasingManager: string;

  // 購買担当名称
  purchasingManagerName: string;
  // 初期納期
  iniDeliveryDate: number;
  // 再日程日
  productionPlanDate: number;
  // 品番
  item: string;
  // 品名
  itemName: string;
  // 単位
  unit: string;
  // 注文単価
  orderUnitPrice: number;
  // 数量
  orderQuantity: number;
  // 金額
  orderAmount: number;
  // 通貨
  currencyCode: string;
 
  // 検収番号
  acceptanceNo: string;
  // 検収明細番号
  acceptanceDetailNo: string;
   // 移動区分
  moveType: string;
  // 移動区分名称
  moveTypeName: string;
  // 検収日
  acceptanceDate: number;
  // 検収数量
  quantity: number;
  // 単価
  unitPrice: number;
  // 検収金額
  amount: number;
  // 保管場所コード
  locationCd: string;
  // 保管場所名称
  locationName: string;

}

// 検収詳細Dto
export interface AcceptanceDetailRecordDto {

  //ID
  id: number;
  // 注文番号
  orderNo: string;
  // 明細番号
  detailNo: string;
  // 注文年月日
  orderDate: Dayjs;
  //納品番号
  deliveryNo: string;
  // 発注者コード
  buyer: string;
  // 発注者名称
  buyerName: string;  
  // 受注者コード
  supplier: string;
  // 受注者名称
  supplierName: string;
  // 発注者部門コード
  buyerDepartment :string;
  //　発注者部門名称
  buyerDepartmentName :string;
  // 購買担当
  purchasingManager: string;
  // 購買担当
  purchasingManagerName: string;
  // 初期納期
  iniDeliveryDate: Dayjs;
  // 再日程日
  productionPlanDate: Dayjs;
  // 品番
  item: string;
  // 品名
  itemName: string;
  // 単位
  unit: string;
  // 注文単価
  orderUnitPrice: number;
  // 数量
  orderQuantity: number;
  // 金額
  orderAmount: number;
  // 通貨
  currencyCode:string;
  // 検収番号
  acceptanceNo: string;
  // 検収明細番号
  acceptanceDetailNo: string;
  // 移動タイプ
  moveType: string;
  // 移動タイプ名称
  moveTypeName: string;
  // 検収日
  acceptanceDate: Dayjs;
  // 検収数量
  quantity: number;
  // 単価
  unitPrice: number;
  // 検収金額
  amount: number;
  // 保管場所コード
  locationCd: string;
  // 保管場所名称
  locationName: string;

}

const detailVo2RecordDto = (vo: AcceptanceDetailVo): AcceptanceDetailRecordDto => {
  return {
    ...vo,
    orderDate: dayjs(vo.orderDate),
    iniDeliveryDate: dayjs(vo.iniDeliveryDate),
    productionPlanDate: dayjs(vo.productionPlanDate),
    acceptanceDate: dayjs(vo.acceptanceDate),
  } as AcceptanceDetailRecordDto;
}


export const getById = (urlId: string): Promise<AcceptanceDetailRecordDto> => {

  return api.get(`${uri}/${urlId}`)
    .then((result: any) => {
      const data = result as AcceptanceDetailVo;
      console.log(data);

      return detailVo2RecordDto(data);
    });
};


export const readAll = () => {
  return api.send(`${uri}/readAll`)
}
