
export const unitPriceCheck = (intl:any, value: string, currency: string): string => {
    const regJP = /^(0|[1-9]\d*)(.\d{1,4})?$/;
    const reg = /^(0|[1-9]\d*)(.\d{1,6})?$/;
    if ((currency === undefined || currency === ''|| currency === null) && (!!value  && !reg.test(value))) {
        return intl.formatMessage({ id: 'message.unitPriceMaxDecimal' });
    }
    if (currency !== undefined && currency !== null && currency !== '' && (currency === 'JPY' && !regJP.test(value))) {
        return intl.formatMessage({ id: 'message.DigitRetainedUnitPrice' }, { a: '4' });
    }
    if (currency !== undefined && currency !== null && currency !== '' && (currency !== 'JPY' && !reg.test(value))) {
        return intl.formatMessage({ id: 'message.DigitRetainedUnitPrice' }, { a: '6' });
    }
    return '';
}

export const otherPriceCheck = (intl:any, value: string, currency: string, itemName:string): string => {
    const regJP= /^(0|[1-9]\d*)?$/;
    const reg = /^(0|[1-9]\d*)(.\d{1,2})?$/;
    if ((currency === undefined || currency === ''|| currency === null) && !!value && !reg.test(value)) {
        return intl.formatMessage({ id: 'estimate.priceError' }, {a:itemName});
    }
    if (currency !== undefined && currency !== null && currency !== '' && (currency === 'JPY' && !regJP.test(value))) {
        return intl.formatMessage({ id: 'message.DigitRetainedAmountJP' });
    }
    if (currency !== undefined && currency !== null && currency !== '' &&(currency !== 'JPY' && !reg.test(value))) {
        return intl.formatMessage({ id: 'message.DigitRetainedAmount' });
    }
    return '';

}