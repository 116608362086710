import { Form, Input, InputNumber } from "antd";
import React from "react";
import { useIntl } from "react-intl";
import { dateUtil } from "../util";
import { DatePicker } from "./DatePicker";

interface Props {
  editing: boolean,
  dataIndex: number,
  title: any,
  inputType: 'number' | 'string' | 'date',
  record: any,
  index: number,
  children: any,
  requiredFlag?: boolean,
  [key: string]: any,
}

export const EditableCell = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  requiredFlag,
  ...restProps
}: Props) => {
  const inputNode = inputType === 'number' ? <InputNumber /> : inputType === 'date' ? <DatePicker /> : <Input />;
  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{
            margin: 0,
          }}
          rules={[
            {
              required: !!requiredFlag ? false : true,
              message: `Please Input ${title}!`,
            },
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

export const EditableCellNumberCheck = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  requiredFlag,
  min,
  max,
  notAllowValues,
  ...restProps
}: Props) => {
  const intl = useIntl();

  const InputNumberDom =
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{
            margin: 0,
          }}
          rules={[
            {
              type: 'number',
              required: !!requiredFlag ? false : true,
              min: !!min ? min : 0, max: !!max ? max : 999999999,
              message: intl.formatMessage({ id: 'orderResponse.over' }),
            },
          ]}
        >
          <InputNumber />
        </Form.Item>
      ) : (
        children
      )}
    </td>;
  const DatePickerDom =
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{
            margin: 0,
          }}
          rules={[
            {
              required: !!requiredFlag ? false : true,
              message: `Please Input ${title}!`,
            },
            {
              validator: (rule, value, callback) => {
                if (value === null || value === undefined) {
                  callback();
                } else if (!Array.isArray(notAllowValues) || notAllowValues.length === 0) {
                  callback();
                } else {
                  notAllowValues.forEach((element: any) => {
                    if (element !== null && element.isSame(value, 'day')) {
                      callback(intl.formatMessage({ id: 'orderResponse.duplicate' }));
                    }
                  });
                  callback();
                }
              }
            }
          ]}
        >
          <DatePicker style={{width: '160px'}} disabledDate={dateUtil.isOverDateLimit} />
        </Form.Item>
      ) : (
        children
      )}
    </td>;
  const InputDom =
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{
            margin: 0,
          }}
          rules={[
            {
              type: 'string',
              required: !!requiredFlag ? false : true,
              max: 50,
              message: `Please Input ${title}!`,
            },
          ]}
        >
          <Input />
        </Form.Item>
      ) : (
        children
      )}
    </td>;
  const inputNode = inputType === 'number' ? InputNumberDom : inputType === 'date' ? DatePickerDom : InputDom;

  return (
    inputNode
  );
};


// orderResponseDetail 画面专用，比普通的数据验证增加了 number input 框的自定义校验
export const OrderResponseEditableCellNumberCheck = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  requiredFlag,
  min,
  max,
  maxLength,
  numberInputValidator,
  notAllowValues,
  ...restProps
}: Props) => {
  const intl = useIntl();
  const fullNumberInputValidator = !!numberInputValidator ? numberInputValidator : () => ''
  // console.log('dataIndex', dataIndex, title)
  const InputNumberDom =
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{
            margin: 0,
          }}
          rules={[
            {
              type: 'number',
              // required: !!requiredFlag ? false : true,
              // min: !!min ? min : 0, 
              // max: !!max ? max : 999999999,
              // message: intl.formatMessage({ id: 'estimate.nullError'}, {name: intl.formatMessage(title.props)}),
            },
            {
              validator: (rule, value, callback) => {
                callback(fullNumberInputValidator(value)) // callback 中的函数执行结果如果有字符串，则记为不合法输入，将字符串显示出来，如果是 undefined 则记为合法输入
              }
            }
          ]}
        >
          <InputNumber maxLength={maxLength ? maxLength : 100} />
        </Form.Item>
      ) : (
        children
      )}
    </td>;

  const DatePickerDom =
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{
            margin: 0,
          }}
          rules={[
            {
              required: !!requiredFlag ? false : true,
              message: intl.formatMessage({id: 'estimate.nullError'}, {name: intl.formatMessage({id: title?.props?.id})}),
              
            },
            {
              validator: (rule, value, callback) => {
                if (value === null || value === undefined) {
                  callback();
                } else if (!Array.isArray(notAllowValues) || notAllowValues.length === 0) {
                  callback();
                } else {
                  notAllowValues.forEach((element: any) => {
                    if (element !== null && element.isSame(value, 'day')) {
                      callback(intl.formatMessage({ id: 'orderResponse.duplicate' }));
                    }
                  });
                  callback();
                }
              }
            }
          ]}
        >
          <DatePicker style={{width: '160px'}} disabledDate={dateUtil.isOverDateLimit} />
        </Form.Item>
      ) : (
        children
      )}
    </td>;
  const InputDom =
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{
            margin: 0,
          }}
          rules={[
            {
              type: 'string',
              required: !!requiredFlag ? false : true,
              max: 50,
              message: `Please Input ${title}!`,
            },
          ]}
        >
          <Input />
        </Form.Item>
      ) : (
        children
      )}
    </td>;
  const inputNode = inputType === 'number' ? InputNumberDom : inputType === 'date' ? DatePickerDom : InputDom;

  return (
    inputNode
  );
};

// inventory supplier detail 画面专用，比普通的验证增加了多个输入框的联动验证 (必须输入特記事項，実面数才能为 0)
// 实现原理，在调用是传入 表单 form 对象
export const InventoryEditableCellNumberCheck = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  requiredFlag,
  min,
  max,
  form,
  notAllowValues,
  ...restProps
}: Props) => {
  const intl = useIntl();

  const InputNumberDom =
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{
            margin: 0,
          }}
          rules={[
            {
              type: 'number',
              required: !!requiredFlag ? false : true,
              min: !!min ? min : 0, max: !!max ? max : 999999999,
              message: intl.formatMessage({id: 'message.noNegativeNumbers'}, {i: intl.formatMessage({id: 'inventory.actualMoldsNum'})}),
            },
            {validator: (rule: any, values: any, callback: any) => { 
              try {
                if(values !== null || values !== undefined ) { // 为空的时候
                  const valueType = typeof values;
                  if(valueType === 'number') {  // 不是数字的时候 
                    const formData = form?.getFieldsValue()
                    const remarkData = formData?.remarks
                    if(!!form && !!formData && values === 0 && (remarkData === '' || remarkData === null || remarkData === undefined)) {  // 実面数为 0 特記事項为空的时候
                      callback(intl.formatMessage({id: 'inventory.recordTheReason'}, {a: intl.formatMessage({id: 'inventory.actualMoldsNum'}), b: intl.formatMessage({id: 'inventory.remarks'})}))  // {a}を 0 で入れた場合、その理由を【{b}】に必ずご記載ください。

                    } else {
                      callback()
                    }
                  } else {
                    callback(intl.formatMessage({id: 'message.noNegativeNumbers'}, {i: intl.formatMessage({id: 'inventory.actualMoldsNum'})}))
                  }
                } else {
                  callback()
                }
              } catch {
                callback('System Error!')
              }
            }}
          ]}
        >
          <InputNumber style={{width: '95%'}} />
        </Form.Item>
      ) : (
        children
      )}
    </td>;
  const DatePickerDom =
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{
            margin: 0,
          }}
          rules={[
            {
              required: !!requiredFlag ? false : true,
              message: `Please Input ${title}!`,
            },
            {
              validator: (rule, value, callback) => {
                if (value === null || value === undefined) {
                  callback();
                } else if (!Array.isArray(notAllowValues) || notAllowValues.length === 0) {
                  callback();
                } else {
                  notAllowValues.forEach((element: any) => {
                    if (element !== null && element.isSame(value, 'day')) {
                      callback(intl.formatMessage({ id: 'orderResponse.duplicate' }));
                    }
                  });
                  callback();
                }
              }
            }
          ]}
        >
          <DatePicker style={{width: '160px'}} disabledDate={dateUtil.isOverDateLimit} />
        </Form.Item>
      ) : (
        children
      )}
    </td>;
  const InputDom =
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{
            margin: 0,
          }}
          rules={[
            {
              type: 'string',
              required: !!requiredFlag ? false : true,
              max: 50,
              message: `Please Input ${title}!`,
            },
          ]}
        >
          <Input />
        </Form.Item>
      ) : (
        children
      )}
    </td>;
  const inputNode = inputType === 'number' ? InputNumberDom : inputType === 'date' ? DatePickerDom : InputDom;

  return (
    inputNode
  );
};

// 这个是有问题的输入验证
export const inventoryEditableCell = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  requiredFlag,
  ...restProps
}: Props) => {

  console.log('--------------------------------------')
  console.log('editing', editing)
  console.log('dataIndex', dataIndex)
  console.log('title', title)
  console.log('inputType', inputType)
  console.log('record', record)
  console.log('index', index)
  console.log('children', children)
  console.log('requiredFlag', requiredFlag)
  console.log('restProps', restProps)
  // console.log('false',(inputType !== 'number' && inputType !== 'date' ) && record.moldsNum === record.actualMoldsNum)
  console.log('--------------------------------------')
  const inputNode = inputType === 'number' ? <InputNumber /> : inputType === 'date' ? <DatePicker /> : <Input />;
  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{
            margin: 0,
          }}
          rules={[
            {
              required: ((inputType !== 'number' && inputType !== 'date') && (record.moldsNum === record.actualMoldsNum)) ? false : true,
              message: `Please Input ${title}!`,
            },
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};