import { Button, Col, Form, Modal, Row } from 'antd';
import Table, { ColumnsType } from 'antd/lib/table';
import React from 'react'
import { FormattedMessage } from 'react-intl';
import { useFields } from '../fields';
import { displayUtil } from '../util';

import { ModelNumBySupplierDto, ModelRequestDto } from './api'

export interface Props {
    visible: boolean;
    close: () => void;
    resultData: ModelNumBySupplierDto[];
    requestData: ModelRequestDto;
    handlePersist: () => void;

}

export function InventoryEntryConfrim(props: Props) {

    const [form] = Form.useForm<ModelNumBySupplierDto>();

    const supplierOptions = useFields('supplier');

    // 回答希望日
    const answerDeadlineTitle = <FormattedMessage id='inventory.answerDeadline' />

    // 一次預け先
    const primaryLendersTitle = <FormattedMessage id='model.primaryLender' />
    const primaryLendersWidth = '100px';
    // 一次預け先名称
    const primaryLenderNameTitle = <FormattedMessage id='model.primaryLenderName' />
    const primaryLenderNameWidth = '150px';
    // 金型数
    const modelNumTitle = <FormattedMessage id='inventory.modelNum' />
    const modelNumWidth = '100px';

    const RequestListColumns: ColumnsType<ModelNumBySupplierDto> = [
        // 一次預け先
        { title: primaryLendersTitle, dataIndex: 'primaryLender', width: primaryLendersWidth },
        // 一次預け先名称
        { title: primaryLenderNameTitle, dataIndex: 'primaryLender', width: primaryLenderNameWidth, render: displayUtil.field(supplierOptions) },
        // 金型数
        { title: modelNumTitle, dataIndex: 'modelNum', width: modelNumWidth },
    ]


    const getDisabled = () => {
        return props.resultData.length > 0 ? false : true;
    }
    return (
        <Modal
            width={800}
            title={<FormattedMessage tagName='h4' id='inventory.inventoryRequest' />}
            footer={null}
            visible={props.visible}
            closable={false}
            afterClose={props.close}>

            <Form
                form={form}
                layout={'horizontal'}
                colon={false}
                size={'large'}
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 12 }}>
                <Row>
                    <Col offset={1} span={8}>
                    <Form.Item name='answerDeadline' label={answerDeadlineTitle} text-align='right'>
                        {displayUtil.date(props.requestData?.answerDeadline)}
                        {/* <Input defaultValue={Number(props.requestData?.answerDeadline)} /> */}
                    </Form.Item>
                    </Col>
                </Row>
                
                <Table<ModelNumBySupplierDto>
                    className="list-detail-table"
                    columns={RequestListColumns}
                    dataSource={props.resultData}
                    bordered
                    rowClassName={displayUtil.rowClassName}
                    rowKey={'id'}
                    tableLayout={'fixed'}
                    pagination={false}
                    scroll={{ y: 400 }}

                />
                <Row justify="center">
                    <Col>
                    <Form.Item>
                    <div className='search-button'>
                        <Button className='search-close-btn' onClick={props.close}>
                            <FormattedMessage id='common.previous' />
                        </Button>
                        <Button className='search-search-btn' onClick={props.handlePersist} disabled={getDisabled()}>
                            <FormattedMessage id='inventory.inventoryRequest' />
                        </Button>
                    </div>
                    </Form.Item>
                    </Col>
                </Row>
            </Form>

        </Modal>
    );
}