import React from 'react';
import { locales } from '../locales';
import { FormattedMessage, useIntl, } from 'react-intl';
import { Drawer, Table, Col, Row, Button, TablePaginationConfig } from 'antd';

import { BillingDetailDetailAmountDto, BillingDetailDetailDto } from './api'
import './index.css';
import { displayUtil } from '../util';
import { DownloadOutlined } from '@ant-design/icons';

export interface Props {
  visible: boolean;
  details?: BillingDetailDetailDto[];
  detailAmounts?: BillingDetailDetailAmountDto[];
  downloadDetail: () => void;
  close: () => void;
}

export function BillingDetailDetail(props: Props) {

  // 共通のstates
  const intl = useIntl();

  // language
  const currentLang = locales.getLang();

  const billingDateTitle = props?.details && props?.details[0] && `${props?.details[0].acquisitionDate.year()} ${intl.formatMessage({ id: 'billingDetail.year' })} ${props?.details[0].acquisitionDate.month() + 1} ${intl.formatMessage({ id: 'billingDetail.month' })}`
  const billintSupplierInfo = props?.details && props?.details[0] && `${props?.details[0].supplierCode}  ${props?.details[0].supplierName}`

  // タイトル
  // 転記日付
  const transcriptionDateTitle = <FormattedMessage id='billingDetail.transcriptionDate' />;
  // 納品伝票番号
  const deliverySlipNumberTitle = <FormattedMessage id='billingDetail.deliverySlipNumber' />;
  // 購買発注NO
  const purchaseOrderNoTitle = <FormattedMessage id='billingDetail.purchaseOrderNo' />;
  // 購買発注伝票明細
  const purchaseDetailNoTitle = <FormattedMessage id='billingDetail.purchaseDetailNo' />;
  // 品目コード
  const itemCodeTitle = <FormattedMessage id='billingDetail.itemCode' />;
  // 品目テキスト
  const itemTextTitle = <FormattedMessage id='billingDetail.itemText' />;
  // 仕入数量
  const incorporationQuantityTitle = <FormattedMessage id='billingDetail.incorporationQuantity' />;
  // 単位
  const unitTitle = <FormattedMessage id='billingDetail.unit' />;
  // 仕入単価
  const purchaseUnitPriceTitle = <FormattedMessage id='billingDetail.purchaseUnitPrice' />;
  // 仕入金額
  const purchaseAmountTitle = <FormattedMessage id='billingDetail.purchaseAmount' />;
  // 通貨
  const currencyCodeTitle = <FormattedMessage id='billingDetail.currencyCode' />;
  // 税率
  const purchaseTaxTitle = <FormattedMessage id='billingDetail.purchaseTax' />;

  const width100 = '100px'
  const width60 = '60px'
  const width80 = '80px'
  const width110 = '110px'
  const width120 = '120px'
  const width130 = '130px'

  const detailColumns = [

    // 転記日付
    {
      title: transcriptionDateTitle,
      dataIndex: 'transcriptionDate',
      width: width80,
    },
    // 納品伝票番号
    {
      title: deliverySlipNumberTitle,
      dataIndex: 'deliverySlipNumber',
      width: width120,
    },
    // 購買発注NO
    {
      title: purchaseOrderNoTitle,
      dataIndex: 'purchaseOrderNo',
      width: width100,
    },
    // 購買発注伝票明細
    {
      title: purchaseDetailNoTitle,
      dataIndex: 'purchaseDetailNo',
      width: width130,
    },
    // 品目コード
    {
      title: itemCodeTitle,
      dataIndex: 'itemCode',
      width: width110,
    },
    // 品目テキスト
    {
      title: itemTextTitle,
      dataIndex: 'itemText',
      width: width100,
    },
    // 仕入数量
    {
      title: incorporationQuantityTitle,
      dataIndex: 'incorporationQuantity',
      width: width100,
    },
    // 単位
    {
      title: unitTitle,
      dataIndex: 'unit',
      width: width60,
    },
    // 仕入単価
    {
      title: purchaseUnitPriceTitle,
      dataIndex: 'purchaseUnitPrice',
      width: width100,
    },
    // 仕入金額
    {
      title: purchaseAmountTitle,
      dataIndex: 'purchaseAmount',
      width: width100,
    },
    // 通貨
    {
      title: currencyCodeTitle,
      dataIndex: 'currencyCode',
      width: width60,
    },
    // 税率
    {
      title: purchaseTaxTitle,
      dataIndex: 'purchaseTax',
      width: width60,
    },
  ]

  const constListScroll = { scrollToFirstRowOnChange: true,y: 'calc(100vh - 400px)', x: '100%'};

  const constPageSizeOptions = ['10', '20', '50', '100']
  const fullDataLength = props.details?.length // データ長
  if (fullDataLength && fullDataLength > 100) { //　もしデータ長は 100 以上ければ
    constPageSizeOptions.push(`${fullDataLength}`) // 在每页的长度列表中加入和数据总长度一样长的每页长度 // ページごとの長さのリストに、データの全長と同じ長さのページごとの長さを追加する。
  } 
  const paginationProps: TablePaginationConfig = {
    size: 'default', // 对于已经声明了 size={'small'} 的 antd 表格，需要为分页器显式声明 size: 'default' 才能使用标准尺寸分页器，否则会缺省使用小尺寸分页器
    position: ['bottomLeft'],
    showSizeChanger: true,
    pageSizeOptions: constPageSizeOptions,
  }
  
  const detailTitle = 
    <>
      <FormattedMessage id='billingDetail' />
      <Button onClick={props.downloadDetail} style={{ marginLeft: 10, marginRight: 10 }} >
        <DownloadOutlined />
        <FormattedMessage id='common.download' />
      </Button>
    </>

  return (
    <Drawer
      title={detailTitle}
      width='1280px'
      footer={null}
      visible={props.visible}
      bodyStyle={{  paddingTop: 0,}}
      destroyOnClose={true}
      onClose={props.close}
    >
      <Row>
        <Col span={24} offset={0} style={{textAlign: 'center', fontSize: '27px', marginTop: '40px'}}>
          {billingDateTitle}
        </Col>  
      </Row>
      <Row>
        <Col span={24} offset={0} style={{textAlign: 'left', fontSize: '20px', marginTop: '20px'}}>
          {billintSupplierInfo}
        </Col>  
      </Row>
      <Row>
        <Col span={currentLang === 'ja-JP' || currentLang === 'zh-CN' ? 3 : 5} offset={0} style={{fontSize: '20px', marginTop: '10px', marginBottom: '10px', display: 'flex', flexDirection: 'row', flexWrap: 'wrap'}}>
          <FormattedMessage id='billingDetail.totalPurchaseAmount' />
        </Col>
        <Col span={19} offset={0} style={{fontSize: '20px', marginTop: '10px', marginBottom: '10px', display: 'flex'}}>
          {props?.detailAmounts?.map(amount => <div key={amount?.id} style={{marginRight: '80px'}}>{amount?.currencyCode}：{displayUtil.currency(amount?.currencyCode, amount?.purchaseAmount)}</div>)}
        </Col>
      </Row>

      <Row className='billling-table'>
        <Col span={24} offset={0} style={{textAlign: 'left'}}>
          <Table<BillingDetailDetailDto>
            className="billing-detail-table"
            columns={detailColumns}
            dataSource={props.details}
            bordered={false}
            size={'small'}
            rowKey={'id'}
            tableLayout={'fixed'}
            // pagination={{position: ['bottomLeft'], pageSize: 50}}
            pagination={paginationProps}
            scroll={constListScroll}
            rowClassName={displayUtil.rowClassName}
          />
        </Col>  
      </Row>
    </Drawer >

  );
}
