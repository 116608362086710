// React APIs
import React from 'react';

// 機能内部のAPIs
import { PayDetail } from './PayDetail';
import { PayList } from './PayList';
import { PaySearch } from './PaySearch';
import { PayHoc } from './PayHoc';

/**
 * 所要計画のコンポネート
 */
export function Pay() {

  const pay = PayHoc(PayDetail, PayList, PaySearch);

  return (
    <>
      {pay}
    </>
  );
}
