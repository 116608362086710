import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import { Field } from './api';

interface FieldsState {
  [field: string]: Field[];
}

const initialState: FieldsState = {
};

export const fieldsSlice = createSlice({
  name: 'fields',
  initialState,
  reducers: {
    addFields: (state, action: PayloadAction<{ field: string, values: Field[] }>) => {
      state[action.payload.field] = action.payload.values;
    },
  },
});

export const { addFields } = fieldsSlice.actions;
export const selectFields = (state: RootState) => state.fields;

export default fieldsSlice.reducer;
