// React APIs
import React from 'react'

// 機能内部のAPIs
import { NotificationHoc } from './NotificationHoc'
import { NotificationList } from './NotificationList'
import { NotificationSearch } from './NotificationSearch'

// システム情報お知らせ
export function Notification() {

    const notification = NotificationHoc(NotificationList, NotificationSearch)

    return (
        <>
            {notification}
        </>
    )

}
