import React, { useEffect, useState } from 'react';
import { FormattedMessage, } from 'react-intl';

import { Table, Button, Modal, } from 'antd';

import { displayUtil } from '../util';
import { constListScroll, } from '../common'
import { useFields } from '../fields';

import './index.css';
import { getOrderResponseHistoryById, OrderResponseHistoryDto, } from './api';

export interface Props {
  id?: number;
  visible: boolean;
  close: () => void;
  download: () => void;
}

export function OrderResponseDetailOrderResponseHistory(props: Props) {

  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<OrderResponseHistoryDto[]>([]);

  useEffect(
    () => {
      setLoading(true);

      if (props.visible && !!props.id && props.id > 0) {

        getOrderResponseHistoryById(props.id)
          .then((record: OrderResponseHistoryDto[]) => {
            setData(record);
            setLoading(false);
          })
          .catch(() => {
            setLoading(false);
          });
      } else {

        setData([]);
        setLoading(false);
      }
    }, [props.visible]
  );

  const stateCodeWidth = '100px';
  const preDeliveryPlanDateWidth = '185px';
  const quantityWidth = '100px';
  const deliveryPlanDateWidth = '160px';
  const userNameWidth = '160px';
  const userModifiedDateWidth = '130px';
  const detailNoWidth = '70px';

  const orderDetailHistoryStatus = useFields('orderDetailHistoryStatus')

  const stateCodeTitle = <FormattedMessage id='common.stateCode' />;
  const userNameTitle = <FormattedMessage id='common.userName' />;
  const userModifiedDateTitle = <FormattedMessage id='common.userModifiedDate' />;

  // 回答納期（変更前）
  const preDeliveryPlanDateTitle = <FormattedMessage id='orderResponse.preDeliveryPlanDate' />;
  // 回答数量（変更前）
  const preQuantityTitle = <FormattedMessage id='orderResponse.preQuantity' />;
  // 回答納期（変更後）
  const currDeliveryPlanDateTitle = <FormattedMessage id='orderResponse.currDeliveryPlanDate' />;
  // 回答数量（変更後）
  const currQuantityTitle = <FormattedMessage id='orderResponse.currQuantity' />;

  const detailNoTitle = <FormattedMessage id='orderResponse.detailNo' />;
  const orderResponseHistoryColumns: any = [
    { title: stateCodeTitle, dataIndex: 'stateCode', width: stateCodeWidth, render: displayUtil.field(orderDetailHistoryStatus) },
    // 明细号
    { title: detailNoTitle, dataIndex: 'detailNo', width: detailNoWidth},
    // 回答納期
    { title: preDeliveryPlanDateTitle, dataIndex: 'preDeliveryPlanDate', render: displayUtil.date, width: preDeliveryPlanDateWidth },
    // 回答数量
    { title: preQuantityTitle, dataIndex: 'preQuantity', width: quantityWidth },
    // 回答納期
    { title: currDeliveryPlanDateTitle, dataIndex: 'deliveryPlanDate', render: displayUtil.date, width: deliveryPlanDateWidth },
    // 回答数量
    { title: currQuantityTitle, dataIndex: 'quantity', width: quantityWidth },
    { title: userNameTitle, dataIndex: 'userName', width: userNameWidth },
    { title: userModifiedDateTitle, dataIndex: 'userModifiedDate', render: displayUtil.date, width: userModifiedDateWidth },
  ];

  const title = <>
      <FormattedMessage id='common.hisory' />
      <Button onClick={props.download} style={{ marginLeft: 10, marginRight: 10 }} ><FormattedMessage id='common.download' /></Button>
    </>

  return (<Modal
    title={title}
    footer={null}
    visible={props.visible}
    closable={true}
    onCancel={props.close}
    width={1300}>
    <Table<OrderResponseHistoryDto>
      bordered={false}
      className="list-detail-table"
      rowClassName={displayUtil.rowClassName}
      dataSource={data}
      loading={loading}
      columns={orderResponseHistoryColumns}
      pagination={false}
      size='small'
      scroll={constListScroll}
    />
    <div className='historyMiddleCloseButton'>
      <Button className='search-close-btn' onClick={props.close}>
        <FormattedMessage id='common.close' />
      </Button>
    </div>
  </Modal>);
}
