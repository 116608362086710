import React from 'react';
import { FormattedMessage, } from 'react-intl';
import { Button, Table, Drawer, } from 'antd';

import { DetailSmall as DetailNew } from '..//common/Detail'
import { SupplierEstimatesDetailDto, SupplierEstimatesDto } from './api';
import './index.css';
import { displayUtil } from '../util';
import { useUser } from '../authorization';
import { useFields } from '../fields';
import Popconfirm from 'antd/es/popconfirm';
import { CommonFileUpload } from '../common/CommonFileUpload';

export interface Props {
  visible: boolean;
  detail?: SupplierEstimatesDto;
  accept: (detail?: SupplierEstimatesDetailDto) => void;
  reject: (detail?: SupplierEstimatesDetailDto) => void;
  download: (id?: number) => void;
  answerList: () => void;
  finish: (dto?: SupplierEstimatesDto) => void;
  answerAll: (id: number, dtos: SupplierEstimatesDetailDto[], responseStatus: string, details?: SupplierEstimatesDto) => void;
  close: () => void;
  loading: boolean;
}

let rowsValue: SupplierEstimatesDetailDto[] = [];
export function SupplierEstimatesDetail(props: Props) {

  // 共通のstates
  const user = useUser();
  const statusOptions = useFields('status');
  const responseStatusOptions = useFields('responseStatus');
  const supplierOptions = useFields('supplier');
  const buyerOptions = useFields('buyer');

  // タイトル
  const cols: [JSX.Element, any][] = [];

  // 案件状態]
  cols.push([<FormattedMessage id='estimate.status' />, displayUtil.field(statusOptions)(props.detail?.status),]);
  // 入札依頼番号
  cols.push([<FormattedMessage id='estimate.requestNo' />, props.detail?.requestNo,]);
  // 依頼日
  cols.push([<FormattedMessage id='estimate.requestDate' />, displayUtil.date(props.detail?.requestDate),]);
  // 発注者コード
  cols.push([<FormattedMessage id='estimate.buyer' />, props.detail?.buyer,]);
  // 発注者名称
  cols.push([<FormattedMessage id='estimate.buyerName' />, displayUtil.field(buyerOptions)(props.detail?.buyer),]);
  // 購買担当
  cols.push([<FormattedMessage id='estimate.purchasingManager' />, props.detail?.purchasingManagerName,]);
  // 受注者コード
  cols.push([<FormattedMessage id='estimate.supplier' />, props.detail?.supplier,]);
  // 受注者名称
  cols.push([<FormattedMessage id='estimate.supplierName' />, displayUtil.field(supplierOptions)(props.detail?.supplier),]);
  // 更新ユーザー名称
  cols.push([<FormattedMessage id='common.userName' />, props.detail?.userName,]);
  // 更新時間
  cols.push([<FormattedMessage id='common.userModifiedDate' />, displayUtil.date(props.detail?.modifiedTime),]);
  // 添付ファイル
  const fileUploadProps = {
    attachments: !!props.detail ? props.detail.attachments : [],
    canUpload: false,
    persist: (uuid: string) => {
      // 処理なし
    },
    remove: (uuid: string) => {
      // 処理なし
    },
  };
  cols.push([<FormattedMessage id='estimate.upload' />, <CommonFileUpload {...fileUploadProps} />,]);

  const title = (
    !user?.isSupplier ?
    <>
      <FormattedMessage id='supplierEstimates.detail' />
    </>
    :
    <>
      <FormattedMessage id='supplierEstimates.supplierDetail' />
    </>
  );


  // 回答状態    			
  const responseStatusTitle = <FormattedMessage id='estimate.responseStatus' />;
  // 品番    			
  const itemTitle = <FormattedMessage id='estimate.item' />;
  // 品名    			
  const itemNameTitle = <FormattedMessage id='estimate.itemName' />;
  // 見積適用要望日    			
  const estimateStartDateTitle = <FormattedMessage id='estimate.estimateStartDate' />;
  // リードタイム    			
  const leadTimeTitle = <FormattedMessage id='estimate.leadTime' />;
  // 最低発注数量    			
  const moqTitle = <FormattedMessage id='estimate.moq' />;
  // 最小梱包数量    			
  const mpqTitle = <FormattedMessage id='estimate.mpq' />;
  // 単価    			
  const unitPriceTitle = <FormattedMessage id='estimate.unitPrice' />;
  // 単価通貨コード    			
  const unitCurrencyTitle = <FormattedMessage id='estimate.unitCurrency' />;
  // 修理費    			
  const repairsCostTitle = <FormattedMessage id='estimate.repairsCost' />;
  // 修理費通貨コード    			
  const repairsCurrencyTitle = <FormattedMessage id='estimate.repairsCurrency' />;
  const operationTitle = <FormattedMessage id='common.operation' />;

  // supplier更新ユーザー名称
  const supplierUsernameTitle = <FormattedMessage id='common.userName' />;
  // supplier更新時間
  const supplierModifyTimeTitle = <FormattedMessage id='common.userModifiedDate' />


  // const itemLink = (text: any, record: SupplierEstimatesDetailDto, index: number) => {

  //   return !user?.isSupplier
  //     ? <Link to={`/supplierestimates/${props.detail?.id}/details/${record.id}`}>{record.item}</Link>
  //     : <>{record.item}</>
  // };

  const showRepairsCost = (text: any, record: SupplierEstimatesDetailDto, index?: number) => {
    return !record.repairsCost ? displayUtil.currency(record.repairsCurrency, 0) : displayUtil.currency(record.repairsCurrency, record.repairsCost)
  };
  const showPriceCost = (text: any, record: SupplierEstimatesDetailDto, index?: number) => {
    return !record.unitCurrency ? "" : displayUtil.currency(record.unitCurrency, record.unitPrice)
  };

  //Width
  const width215 = '215px';
  const width200 = '200px';
  const width160 = '160px';
  const width135 = '135px';
  const width130 = '130px';
  const width120 = '120px';
  const width90 = '90px';
  const width80 = '80px';
  const width60 = '60px';

  const columns: any = [
    // 回答状態
    { title: responseStatusTitle, dataIndex: 'responseStatus', render: displayUtil.field(responseStatusOptions), width: width120 },
    // 品番
    // { title: itemTitle, dataIndex: 'item', render: itemLink, width: itemWidth },
    { title: itemTitle, dataIndex: 'item', width: width215 },
    // 品名
    { title: itemNameTitle, dataIndex: 'itemName', width: width160, ellipsis: true },
    // 見積適用要望日
    { title: estimateStartDateTitle, dataIndex: 'estimateStartDate', render: displayUtil.date, width: width130 },
    // リードタイム
    { title: leadTimeTitle, dataIndex: 'leadTime', width: width60 },
    // 最低発注数量
    { title: moqTitle, dataIndex: 'moq', width: width90 },
    // 最小梱包数量
    { title: mpqTitle, dataIndex: 'mpq', width: width90 },
    // 単価
    { title: unitPriceTitle, dataIndex: 'unitPrice', width: width135, render: showPriceCost },
    // 単価通貨コード
    { title: unitCurrencyTitle, dataIndex: 'unitCurrency', width: width80 },
    // 修理費
    { title: repairsCostTitle, dataIndex: 'repairsCost', width: width135, render: showRepairsCost },
    // 修理費通貨コード
    { title: repairsCurrencyTitle, dataIndex: 'repairsCurrency', width: width120 },
    // オペレーター
    { title: supplierUsernameTitle, dataIndex: 'userName', width: width120 },
    // 更新日
    { title: supplierModifyTimeTitle, dataIndex: 'supplierModifiedTime', render: displayUtil.date, width: width130 },
  ];

  if (!user?.isSupplier) {
    columns.push({
      title: operationTitle, dataIndex: '', width: width200, render: (v: SupplierEstimatesDetailDto | undefined) =>
        <div style={{ display: 'inline-block' }}>
          <Button type='link'
            // className='close-btn' 
            disabled={v?.responseStatus === '003' || (props.detail?.status !== '001' && props.detail?.status !== '004')} onClick={() => props.accept(v)}>
            <FormattedMessage id='supplierEstimates.accept' />
          </Button>
          <Button type='link'
            disabled={v?.responseStatus === '004' || (props.detail?.status !== '001' && props.detail?.status !== '004')} onClick={() => props.reject(v)}>
            <FormattedMessage id='supplierEstimates.reject' />
          </Button>

        </div>
    })
  }

  const selectChange = (selectedRowKeys: any, selectedRows: SupplierEstimatesDetailDto[]) => {
    rowsValue = [];
    rowsValue = selectedRows;
  }
  const tableTitle = () => (<>
    {
    !user?.isSupplier ?
    <div style={{ display: 'inline-block', fontSize: '16px', transform: 'translateY(0px) translateX(-20px)' }}><FormattedMessage id='supplierEstimates.detailList' /></div>:
    <div style={{ display: 'inline-block', fontSize: '16px', transform: 'translateY(0px) translateX(-20px)' }}><FormattedMessage id='supplierEstimates.supplierDetailList' /></div>
    }

    {props.detail?.status !== '002' && (
      !user?.isSupplier ?
        // <Button onClick={props.answerList}>
        //   <FormattedMessage id='supplierEstimates.answerList' />
        // </Button>
        <div style={{ display: 'inline-block' }}>
          <Popconfirm
            title={<FormattedMessage id='estimate.approve' />}
            onConfirm={() => {
              props.answerAll(!!props.detail?.id ? props.detail.id : 0, rowsValue, '003', props.detail)
            }}
            okText={<FormattedMessage id='common.confirm' />}
            cancelText={<FormattedMessage id='common.cancel' />} >
            <Button style={{ marginRight: 15 }}>
              <FormattedMessage id='supplierEstimates.accept' />
            </Button>
          </Popconfirm>
          <Popconfirm
            title={<FormattedMessage id='estimate.refuse' />}
            onConfirm={() => {
              props.answerAll(!!props.detail?.id ? props.detail.id : 0, rowsValue, '004', props.detail)
            }}
            okText={<FormattedMessage id='common.confirm' />}
            cancelText={<FormattedMessage id='common.cancel' />} >
            <Button>
              <FormattedMessage id='supplierEstimates.reject' />
            </Button>
          </Popconfirm>
        </div>
        :
        // <>
        //   <Popconfirm
        //     title={<FormattedMessage id='message.finishConfirm' />}
        //     onConfirm={() => {
        //       props.finish(props.detail)
        //     }}
        //     okText={<FormattedMessage id='common.confirm' />}
        //     cancelText={<FormattedMessage id='common.cancel' />} >
        //     <Button style={{ marginLeft: 15 }}>
        //       <FormattedMessage id='supplierEstimates.finishRequest' />
        //     </Button>
        //   </Popconfirm>
        // </>
        ''
    )
    }
  </>)

  return (
    <>
      <Drawer
        title={title}
        width='1380px'
        footer={null}
        visible={props.visible}
        bodyStyle={{ paddingBottom: 80 }}
        destroyOnClose={true}
        onClose={props.close}
        zIndex={1}
        style={{ overflowX: 'hidden' }}
      >
        <DetailNew
          title={
            <div><FormattedMessage id='estimate.estimateContent' />
              <Button onClick={() => props.download(props.detail?.id)} className={"bottom-padding-10"} style={{ marginBottom: '10px' }}>
                <FormattedMessage id='common.download' />
              </Button> {props.detail?.status !== '002' && (
                !user?.isSupplier ?
                  ''
                  : <>
                    <Popconfirm
                      title={<FormattedMessage id='message.finishConfirm' />}
                      onConfirm={() => {
                        props.finish(props.detail)
                      }}
                      okText={<FormattedMessage id='common.confirm' />}
                      cancelText={<FormattedMessage id='common.cancel' />} >
                      <Button style={{ marginLeft: 15 }}>
                        <FormattedMessage id='supplierEstimates.finishRequest' />
                      </Button>
                    </Popconfirm>
                  </>)}</div>}
          cols={cols}
        />

        {
          !user?.isSupplier
            ?
            <>
              <Table<SupplierEstimatesDetailDto>
                id="estimeation-detail-table"
                className={"list-detail-table"}
                title={tableTitle}
                loading={props.loading}
                dataSource={props.detail?.details}
                columns={columns}
                pagination={false}
                // size='small'
                scroll={{ x: 1000, y: 400 }}
                rowClassName={displayUtil.rowClassName}
                rowKey="id"
                rowSelection={{ type: 'checkbox', onChange: selectChange }}
                style={{ marginTop: '20px' }}
              />
            </>
            :
            <>
              <Table<SupplierEstimatesDetailDto>
                id="estimeation-detail-table"
                className={"list-detail-table"}
                title={tableTitle}
                loading={props.loading}
                dataSource={props.detail?.details}
                columns={columns}
                pagination={false}
                // size='small'
                scroll={{ x: 1000, y: 400 }}
                rowClassName={displayUtil.rowClassName}
                style={{ marginTop: '20px' }}
              />
            </>
        }
      </Drawer >
    </>
  );
}
