import React from 'react'
import { IntlProvider, FormattedMessage } from 'react-intl'

import { locales } from '../locales';

const [lang, locale,] = locales.getLocales();

export const LocaleMessage: React.FC<{ id: string }> = ({ id }) => {
  return (
    <IntlProvider locale={lang} messages={locale}>
      <FormattedMessage id={id} />
    </IntlProvider>
  );
};
