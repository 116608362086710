import React, { useEffect, useState } from 'react';
import { Row, Col, Form, Button, Select, Modal, Input, InputNumber, Popconfirm } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { EstimateRequestUpdateDto } from './api';
import { FormattedMessage, useIntl } from 'react-intl';
import { useFields } from '../fields';
import { dateUtil, filterUtil } from '../util';
import dayjs from 'dayjs';
import { unitPriceCheck } from '../util/priceCheck';
import { CommonFileUpload } from '../common/CommonFileUpload';
import { Attachment, DatePicker } from '../common';
export interface Props {

  visible: boolean;
  detail?: EstimateRequestUpdateDto;
  handleSubmit: (detail: EstimateRequestUpdateDto) => void;
  close: () => void;

}
export function EstimateEditDetail(props: Props) {

  const [form] = Form.useForm<EstimateRequestUpdateDto>();
  const [attachments, setAttachments] = useState<Attachment[]>(!!props.detail ? props.detail.attachments : []);
  useEffect(() => {
    if (!!props.detail) {
      setAttachments(props.detail.attachments);
      form.setFieldsValue(props.detail);
    }
  },[form, props.detail]);

  const fileUploadProps = {
    attachments: props.detail?.attachments !== null && props.detail?.attachments !== undefined ? props.detail.attachments : [],
    canUpload: true,
    maxCount: 5,
    persist: (uuid: string) => {
      setAttachments([...attachments, { id: -1, uuid: uuid }]);
      
    },
    remove: (uuid: string) => {
      setAttachments(attachments.filter((attachment: Attachment) => attachment.uuid !== uuid));
    },
  };

  //供应商
  const supplierOptions = useFields('supplier');
  const fullSupplierOptions = useFields('fullSupplier');
  //発注部門
  const buyerOptions = useFields('buyerDepartment');
  //金额类型
  const disireCurrencyOptions = useFields('currency').map(v => {
    return { label: v.label, value: v.label }
  })
  //品番
  const itemOptions = useFields('item');

  const statusOptions = useFields('estimateStatus');

  const estimatesTypeOptions = useFields('estimatesType');

  const intl = useIntl();

  const submit = (formValue: EstimateRequestUpdateDto)=>{
    formValue.attachments = attachments;
    props.handleSubmit(formValue);
  }
  
  return (
    <Modal title={<FormattedMessage id='estimate.editRequest' />}
      visible={props.visible}   //设置默认隐藏
      onCancel={props.close}  // 点击取消按钮，对话框消失
      footer={false}   //对话框的底部
      closable={false}
      destroyOnClose={true}
      width={1200}
    >
      <Form
        form={form}
        name="advanced_search"
        className="ant-advanced-search-form"
        onFinish={submit}
        layout={'horizontal'}
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        colon={false}
      >
        <Row>
          <Col span={10}>
            <Row>
              <Col span={24} >
                <Form.Item
                  className={'estimate-edite'}
                  name={'requestNo'}
                  label={<FormattedMessage id='estimate.requestNo' />}>
                  {<Input disabled />}
                </Form.Item>
              </Col>
              <Col span={24} >
                <Form.Item
                  label={<FormattedMessage id='estimate.status' />}
                  className={'estimate-edite'}
                  name={'status'}
                >
                  {<Select options={statusOptions} disabled />}
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  label={<FormattedMessage id='estimate.userName' />}
                  className={'estimate-edite'}
                >
                  {<Input disabled={true} value={props.detail?.userName} />}
                </Form.Item>
                <Form.Item
                  label={<FormattedMessage id='estimate.modifiedTime' />}
                  className={'estimate-edite'}
                  name={'modifiedTime'}
                >
                  {<DatePicker style={{ width: '100%' }} disabled={true} />}
                </Form.Item>
              </Col>
              <Col span={24} >
                <Form.Item
                  label={<FormattedMessage id='estimate.buyerDepartment' />}
                  className={'estimate-edite'}
                  name={'buyerDepartmentCode'}
                  rules={[{ required: true, message: intl.formatMessage({ id: 'estimate.nullError' }, { name: intl.formatMessage({ id: 'estimate.buyerDepartment' }) }) }]}
                >
                  {<Select options={buyerOptions} defaultValue={props.detail?.buyerDepartmentCode} />}
                </Form.Item>
              </Col>
              <Col span={24} >
                <Form.Item
                  label={<FormattedMessage id='estimate.purchasingManager' />}
                  className={'estimate-edite'}
                  name={'purchasingManagerName'}
                  rules={[{ required: true, message: intl.formatMessage({ id: 'estimate.nullError' }, { name: intl.formatMessage({ id: 'estimate.purchasingManager' }) }) },
                  { max: 6, message: intl.formatMessage({ id: 'estimate.maxError' }, { a: intl.formatMessage({ id: 'estimate.purchasingManager' }), b: '6' }) }]} >
                  {<Input />}
                </Form.Item>
              </Col>
              <Col span={24} >
                <Form.Item
                  label={<FormattedMessage id='estimate.estimatesType' />}
                  className={'estimate-edite'}
                  name={'estimatesType'}
                  rules={[{ required: true, message: intl.formatMessage({ id: 'estimate.nullError' }, { name: intl.formatMessage({ id: 'estimate.estimatesType' }) }) }]} >
                  {<Select options={estimatesTypeOptions} />}
                </Form.Item>
              </Col>
              <Col span={24} >
                <Form.Item
                  label={<FormattedMessage id='estimate.requestDate' />}
                  className={'estimate-edite'}
                  name={'requestDate'}
                  rules={[{ required: true, message: intl.formatMessage({ id: 'estimate.nullError' }, { name: intl.formatMessage({ id: 'estimate.requestDate' }) }) }, {
                    validator: (rule, value, callback) => {
                      const edate = form.getFieldValue('answerDeadline');
                      if (value === '' || value === undefined || value === null || edate === '' || edate === null || edate === undefined ||
                        (dayjs(edate).isAfter(dayjs(value), 'day'))) {
                        callback();
                      } else {
                        callback(intl.formatMessage({ id: 'estimate.answerDeadlineError' },
                          { a: intl.formatMessage({ id: 'estimate.answerDeadline' }), b: intl.formatMessage({ id: 'estimate.requestDate' }) }));
                      }
                    }
                  }]}
                >
                  {<DatePicker style={{ width: '100%' }} disabledDate={dateUtil.isOverDateLimit} disabled={true}/>}
                </Form.Item>
              </Col>
              <Col span={24} >
                <Form.Item
                  label={<FormattedMessage id='estimate.answerDeadline' />}
                  className={'estimate-edite'}
                  name={'answerDeadline'}
                  rules={[{ required: true, message: intl.formatMessage({ id: 'estimate.nullError' }, { name: intl.formatMessage({ id: 'estimate.answerDeadline' }) }) }]}
                >
                  {<DatePicker style={{ width: '100%' }} disabledDate={dateUtil.isOverDateLimit}/>}
                </Form.Item>
              </Col>
              <Col span={24} >
                <Form.Item
                  label={<FormattedMessage id='estimate.item' />}
                  className={'estimate-edite'}
                  name={'item'}
                >
                  {<Select showSearch allowClear optionFilterProp="children" filterOption={(input:any, option:any) => option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0  } options={itemOptions} />}
                </Form.Item>
              </Col>
              <Col span={24} >
                <Form.Item
                  label={<FormattedMessage id='estimate.itemName' />}
                  className={'estimate-edite'}
                  name={'itemName'}
                  rules={[{ 
                    required: true, 
                    message: intl.formatMessage({ id: 'estimate.nullError' }, { name: intl.formatMessage({ id: 'estimate.itemName' }) }) 
                  }, { 
                    max: 100, 
                    message: intl.formatMessage({ id: 'estimate.maxError' }, { a: intl.formatMessage({ id: 'estimate.itemName' }), b: '100' }) 
                  }]}
                >
                  {<Input />}
                </Form.Item>
              </Col>
              <Col span={24} >
                <Form.Item
                  label={<FormattedMessage id='estimate.desireUnitPrice' />}
                  className={'estimate-edite'}
                  name={'desireUnitPrice'}
                  rules={[{
                    validator: (rule, value, callback) => {
                      if(value !== null) {
                        if(value <= 0 ) {  // 判断输入值是否等于零
                          let message = intl.formatMessage({ id: 'message.onlyPositiveNumber' })
                          if (message !== '') {
                            callback(message);
                          } else {
                            callback();
                          }
                        } else {
                          let message = unitPriceCheck(intl, value, form.getFieldsValue().desireCurrency);  // 根据选择的货币判断小数点位数是否超出
                          if (message !== '') {
                            callback(message);
                          } else {
                            callback();
                          }
                        }
                      } else {
                        if(form.getFieldsValue().desireCurrency === null || form.getFieldsValue().desireCurrency === undefined) {
                          callback()
                        } else {
                          callback(intl.formatMessage({ id: 'estimate.nullError' }, { name: intl.formatMessage({ id: 'estimate.desireUnitPrice' }) }))
                        }
                      }
                    }
                  }]}
                >
                  {<InputNumber style={{ width: '100%' }} />}
                </Form.Item>
              </Col>
            </Row>
          </Col>

          <Col span={14}>
            <Row>
              <Col span={20} >
                <Form.Item
                  label={<FormattedMessage id='estimate.desireCurrency' />}
                  className={'estimate-edite'}
                  name={'desireCurrency'}
                  rules={[{
                    validator: (rule, value, callback) => {
                      if (!!form.getFieldsValue().desireUnitPrice && (value === null || value === undefined)) {
                        callback(intl.formatMessage({ id: 'estimate.nullError' }, { name: intl.formatMessage({ id: 'estimate.desireCurrency' }) }));
                      }
                      callback();
                    }
                  }]}
                >
                  <Select options={disireCurrencyOptions} allowClear={true} />
                </Form.Item>
              </Col>
              <Col span={4} />
              <Col span={20}>
                <Form.Item
                  label={<FormattedMessage id='estimate.planningMonths' />}
                  className={'estimate-edite'}
                  name={'planningMonths'}
                  rules={[{
                    validator: (rule, value, callback) => {
                      const reg = /^(0|[1-9]\d*)(.\d{1,2})?$/;
                      if (value === null || value === undefined) {
                        callback();
                      }
                      if (value < 0) {
                        const errorMessage = intl.formatMessage({id: 'message.onlyPositiveNumber'})
                        callback(errorMessage)
                      } else {
                        if (reg.test(value)) {
                          callback();
                        } else {
                          callback(intl.formatMessage({ id: 'estimate.priceError' }, { a: intl.formatMessage({ id: 'estimate.planningMonths' }) }));
                        }
                      }
                    }
                  }]}
                >
                  <InputNumber style={{ width: '100%' }} defaultValue={props.detail?.planningMonths} />
                </Form.Item>
              </Col>
              <Col span={4}><FormattedMessage id='estimate.monthUnit' /></Col>
              <Col span={20} >
                <Form.Item
                  label={<FormattedMessage id='estimate.planQuantity' />}
                  className={'estimate-edite'}
                  name={'planQuantity'}
                  rules={[{
                    validator: (rule, value, callback) => {
                      const reg = /^(0|[1-9]\d*)(.\d{1,3})?$/;
                      if (value === null || value === undefined) {
                        callback();
                      }
                      if (value < 0) {
                        const errorMessage = intl.formatMessage({id: 'message.onlyPositiveNumber'})
                        callback(errorMessage)
                      } else {
                        if (reg.test(value)) {
                          callback();
                        } else {
                          callback(intl.formatMessage({ id: 'message.DigitRetainedUnitPrice' }, { a: 3 }));
                        }
                      }
                    }
                  }]}
                >
                  <InputNumber style={{ width: '100%' }} defaultValue={props.detail?.planQuantity} />
                </Form.Item>
              </Col>
              <Col span={4}><FormattedMessage id='estimate.quantityUnit' /></Col>
              <Col span={20}>
                <Form.Item
                  label={<FormattedMessage id='estimate.monthlyPlanOrderQuantity' />}
                  className={'estimate-edite'}
                  name={'monthlyPlanOrderQuantity'}
                  rules={[{
                    validator: (rule, value, callback) => {
                      const reg = /^(0|[1-9]\d*)(.\d{1,3})?$/;
                      if (value === null || value === undefined) {
                        callback();
                      }
                      if (reg.test(value)) {
                        callback();
                      } else {
                        callback(intl.formatMessage({ id: 'estimate.priceError' },  { a: intl.formatMessage({ id: 'estimate.monthlyPlanOrderQuantity' }) }));
                      }
                    }
                  }]}
                >
                  <InputNumber style={{ width: '100%' }} defaultValue={props.detail?.monthlyPlanOrderQuantity} />
                </Form.Item>
              </Col>
              <Col span={4}><FormattedMessage id='estimate.quantityUnit' /></Col>
              <Col span={20}>
                <Form.Item
                  label={<FormattedMessage id='estimate.deliveryLocation' />}
                  className={'estimate-edite'}
                  name={'deliveryLocation'}
                  rules={[{ max: 150, message: intl.formatMessage({ id: 'estimate.maxError' }, { a: intl.formatMessage({ id: 'estimate.deliveryLocation' }), b: '150' }) }]}
                >
                  <Input defaultValue={props.detail?.deliveryLocation} />
                </Form.Item>
              </Col>
              <Col span={4} />
              <Col span={20}>
                <Form.Item
                  label={<FormattedMessage id='estimate.remarks' />}
                  className={'estimate-edite'}
                  name={'remarks'}
                  rules={
                    [{ max: 100, message: intl.formatMessage({ id: 'estimate.maxError' }, { a: intl.formatMessage({ id: 'estimate.remarks' }), b: '100' }) }]}>
                  <TextArea autoSize={{ minRows: 3, maxRows: 3 }} defaultValue={props.detail?.remarks} />
                </Form.Item>
              </Col>
              <Col span={4} />
              <Col span={20}>
                <Form.Item
                  label={<FormattedMessage id='estimate.supplier' />}
                  className={'estimate-edite'}
                  name={'supplierName'}
                  rules={[{ required: true, message: intl.formatMessage({ id: 'estimate.nullError' }, { name: intl.formatMessage({ id: 'estimate.supplier' }) }) }]}>
                  <Select
                    mode={'multiple'}
                    allowClear={true}
                    autoClearSearchValue={false}
                    loading={fullSupplierOptions.length === 0}
                    options={fullSupplierOptions}
                    onSearch={filterUtil.onSearchForm(form, 'supplierName', fullSupplierOptions)}
                    filterOption={filterUtil.selectFilter} />
                </Form.Item>
              </Col>
              <Col span={4} />
              <Col span={20}>
                <Form.Item
                  label={<FormattedMessage id='estimate.upload' />}
                  name={'attachments'}>
                  <CommonFileUpload {...fileUploadProps} />
                </Form.Item>
              </Col>
              <Col span={4} />
            </Row>
          </Col>
        </Row>

        <Row>
          <Col span={24} style={{ textAlign: 'center' }}>
            <Button onClick={props.close} style={{ marginRight: 20 }}>
              <FormattedMessage id='common.close' />
            </Button>
            <Popconfirm
              title={<FormattedMessage id='message.editConfirm' />}
              onConfirm={() => {
                form.submit()
              }}
              okText={<FormattedMessage id='common.confirm' />}
              cancelText={<FormattedMessage id='common.cancel' />} >
              <Button htmlType="submit">
                <FormattedMessage id='common.update' />
              </Button>
            </Popconfirm>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}
