import React from 'react'
import { DeliveryOuputForm } from './DeliveryOuputForm';

import { DeliveryOutputHoc } from './DeliveryOutputHoc';
import { DeliveryOutputList } from './DeliveryOutputList';
import { DeliveryOutputSearchForm } from './DeliveryOutputSearchForm';

export function DeliveryOutput() {

    const deliveryoutput = DeliveryOutputHoc(DeliveryOutputList,DeliveryOuputForm, DeliveryOutputSearchForm);

    return (
        <>
            {deliveryoutput}
        </>
    )
}
