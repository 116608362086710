import { Button, Modal, Popconfirm } from "antd";
import { ModelDto } from "./api";
import React from "react";
import { FormattedMessage } from "react-intl";
import { modelDeletePageTableScroll } from "../common";
import Table, { ColumnsType } from "antd/lib/table";

export interface Props {
  visible: boolean;
  close: () => void;
  selectedDeleteModelData: ModelDto[];
  handleDeleteSelect: (deleteModelData: ModelDto[]) => void;
}
export function ModelDelete(props: Props) {

  // タイトル
  // 資産番号
  const assetNoTitle = <FormattedMessage id='model.assetNo' />
  // 補助番号
  const auxiliaryNoTitle = <FormattedMessage id='model.auxiliaryNo' />
  
  const width150 = '150px';
  const width180 = '180px';
  const columns: ColumnsType<ModelDto> = [
    // 資産番号
    {
        title: assetNoTitle,
        width: width180,
        dataIndex: 'assetNo',
    },
    // 補助番号
    {
        title: auxiliaryNoTitle,
        width: width150,
        dataIndex: 'auxiliaryNo',
    }
  ];

  return (
    <Modal
      title={<FormattedMessage tagName='h4' id='model.confirmDeleteSelected' />}
      footer={null}
      visible={props.visible}
      closable={false}
      afterClose={props.close}
      destroyOnClose={true}
      width={600}
      // style={{top: '10px'}}
    >
      <Table<ModelDto>
        className="list-table"  
        dataSource={props.selectedDeleteModelData}
        columns={columns}
        scroll={modelDeletePageTableScroll}
        pagination={false}
      />
      <div className='model-delete-button'>
        <Button className='search-close-btn' onClick={props.close}>
          <FormattedMessage id='common.close' />
        </Button>

        <Popconfirm placement="top" title={<FormattedMessage id='message.delConfirm' />}
          onConfirm={() => props.handleDeleteSelect(props.selectedDeleteModelData)}
          okText={<FormattedMessage id='common.delete' />}
          cancelText={<FormattedMessage id='common.cancel' />} >
          <Button className='search-search-btn'>
            <FormattedMessage id='common.delete' />
          </Button>
        </Popconfirm>
        
      </div>
    </Modal>
  )
}
