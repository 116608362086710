// React APIs
import React from 'react';

// 機能内部のAPIs
import { DocList } from './DocList';
import { DocDetail } from './DocDetail';
import { DocReplyDetail } from './DocReplyDetail'
import { DocSearch } from './DocSearch';
import { DocHoc } from './DocHoc';
import { DocCreate } from './DocCreate';
import { DocCreateConfirm } from './DocCreateConfirm';

/**
 * 文書管理のコンポネート
 */
export function Doc() {

  const docs = DocHoc(DocList, DocDetail, DocSearch, DocCreate, DocCreateConfirm, DocReplyDetail);

  return (
    <>
      { docs }
    </>
  );
}
