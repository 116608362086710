// React APIs
import React, { useState, useEffect } from "react";
import { useParams, useLocation, useHistory } from "react-router-dom";
import { message } from "antd";
import { DetailStates, ListStates } from "../common";

// 共通のAPIs
// 機能内部のAPIs
import {
    get,
    getById,
    search2Dto,
    dto2search,
    DeliveryDto,
    DeliverySearchDto,
    removeById,
    downloadEIAJApi,
    downloadDNnListApi,
} from "./api";
import { Props as ListProps } from "./DeliveryList";
import { Props as SearchProps } from "./DeliverySearch";
import { Props as DetailProps } from "./DeliveryDetail";
import { useFields } from "../fields";

/**
 * 納品のコンポネート
 */
export function DeliveryHoc(
    List: React.ComponentType<ListProps>,
    Search: React.ComponentType<SearchProps>,
    Detail: React.ComponentType<DetailProps>
) {
    // URLのID
    const { id: urlId } = useParams<{ id?: string }>();
    // URLのquery文字列
    const { search } = useLocation();
    // URL変更のハンドル
    const { push, goBack } = useHistory();
    // 検索条件（フィルター、ページとソート順）
    const [searchDto, setSearchDto] = useState<DeliverySearchDto>(
        search2Dto(search)
    );
    const locationCds = useFields('locationName');
    const deliveryStatus = useFields('deliveryStatus')
    // 納品書一覧のstates
    const [listData, setListData] = useState<ListStates<DeliveryDto>>({
        loading: false,
        total: 0,
        data: [],
    });

    // 検索のstates
    // 検査画面の表示状態
    const [searchVisible, setSearchVisible] = useState<boolean>(false);

    // 詳細のstates
    // 納品書详细一览
    const [detailData, setDetailData] = useState<DetailStates<DeliveryDto>>({
        visible: false,
    });

    // reload trigger
    // 用户触发 useEffect 并刷新页面
    const [reload, setReload] = useState<boolean>(false)

    // URLのQuery変更によって検索を再実施する
    useEffect(() => {
        setDetailData((data) => ({ ...data, visible: false }));
        setSearchVisible(false);

        if (!!urlId) {
            getById(urlId)
                .then((record: DeliveryDto) => {
                    record.deliveryDetails?.forEach((item) => (item.id = record.id));
                    setDetailData({ visible: true, data: record });
                })
                .catch(() => { });
        }
        // 納品書一覧
        else {
            setListData((data) => ({ ...data, loading: true }));
            setSearchDto(search2Dto(search));

            get(search)
                .then((para: [number, DeliveryDto[]]) => {
                    const [count, vos] = para;
                    setListData({ loading: false, total: count, data: vos });
                })
                .catch(() => {
                    setListData((data) => ({ ...data, loading: false }));
                });
        }
        //  }
    }, [urlId, search, reload]);

    // 検索条件を変更する
    const setSearch = (researchDto: DeliverySearchDto) => {
        // 検索条件変更を反映する
        const query = dto2search(researchDto);

        // URLを再設定する
        push(`/deliveries${query}`);
    };

    const handleDelete = (id: number, version: number) => {
        setListData({ loading: true, total: listData.total, data: listData.data });
        removeById(id, version).then(() => {
            setListData({
                loading: false,
                total: listData.total,
                data: listData.data.filter((vo) => vo.id !== id),
            });
        }).catch(() => {
            setListData({ loading: false, total: listData.total, data: listData.data });
            setReload(!reload)
        });
    };
    //Todo -Zhang wen yue
    const downloadEIAJ = (deliveryDto: DeliveryDto) => {
        downloadEIAJApi(deliveryDto.id).then(() => {
            message.success("This is a success message");
        });
    };
    const downloadDNnList = (deliveryDto: DeliveryDto) => {
        downloadDNnListApi(deliveryDto.id).then(() => {
            message.success("This is a success message");
        });
    };

    const downloadAllPDF = (deliverys: DeliveryDto[]) => {
        const pros: Array<Promise<any>> = []
        setListData((data) => ({ ...data, loading: true }));
        deliverys.forEach( delivery => {
            if(delivery.deliveryReportType === '001') {
                pros.push(downloadEIAJApi(delivery.id))
            } else if(delivery.deliveryReportType === '002') {
                pros.push(downloadDNnListApi(delivery.id)) 
            }
        })
        Promise.all(pros).then(() => {
            setListData((data) => ({ ...data, loading: false }));
        })
    };

    const reset = (rowCount: number) => {
        setSearch({

            estimatedTimeArriva: [null, null],

            deliveryNo: '',

            locationCd: '',

            supplier: [],

            supplierName: '',

            userModifiedDate: [null, null],

            rowCount: rowCount,

        } as DeliverySearchDto)
    }

    // 纳品书一览
    const listProps = {
        // 画面loading状態
        loading: listData.loading,
        // 件数合計
        total: listData.total,
        // 一覧画面の表示データ
        data: listData.data,
        // 検索条件（フィルター、ページとソート順）
        searchDto: searchDto,
        downloadEIAJ: downloadEIAJ,
        downloadDNnList: downloadDNnList,
        downloadAllPDF: (keys: DeliveryDto[]) => downloadAllPDF(keys),
        // 検査画面を表示する
        openSearchModal: () => setSearchVisible(true),
        // 検査画面を表示する
        handleResearch: setSearch,
        handleDelete: handleDelete,
        locationCdOptions: locationCds,
        deliveryStatusOptions: deliveryStatus,
        reset: reset
    };

    // 检索画面
    const searchProps = {
        // 検査画面の表示状態
        visible: searchVisible,
        searchDto: searchDto,
        handleSearch: (value: DeliverySearchDto) => {
            setSearchVisible(false);
            setSearch(value);
        },
        close: () => setSearchVisible(false),
    };

    //  纳品书詳細一览
    const detailProps = {
        // 詳細画面の表示状態
        visible: detailData.visible,
        detail: detailData.data,
        close: () => {
            if (dto2search(searchDto) !== "") {
                goBack();
            } else {
                push("/deliveries");
            }
        },
    };

    return (
        <>
            <List {...listProps} />
            <Search {...searchProps} />
            <Detail {...detailProps} />
        </>
    );
}
