import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl, } from 'react-intl';

import { Button, Table, Form, Popconfirm } from 'antd';
import { ColumnsType } from 'antd/lib/table';

import './index.css';
import { useUser } from '../authorization';
import { EditableCellNumberCheck } from '../common'
import { displayUtil } from '../util';

import { OrderResponseRecordDto, OrderListRecordDto, DevInstrucstionRecordDto, DetailRecordDto } from './api';
import { Dayjs } from 'dayjs';

export interface Props {
  detail?: OrderListRecordDto;
  editingDetail?: DetailRecordDto;
  gap: number;
  orderResponseGap: number;
  loading: boolean;
  setEditingDetail: (dto?: DetailRecordDto) => void;
  save: (record: DetailRecordDto) => void;
  remove: (record: DetailRecordDto) => void;
}

export function OrderResponseDetailDevInstrucstion(props: Props) {

  // 共通のstates
  const intl = useIntl();

  const [form] = Form.useForm();
  const user = useUser();

  const [dataSource, setDataSource] = useState<DevInstrucstionRecordDto[]>([]);
  const [gap, setGap] = useState<number>(0);

  useEffect(
    () => {
      if (!!props.detail) {
        if (!!props.editingDetail && props.editingDetail.type === 'DevInstrucstion') {
          form.resetFields();
          form.setFieldsValue(props.editingDetail);
          if (props.editingDetail.id <= 0) {
            const tempDto = props.editingDetail as DevInstrucstionRecordDto;
            setDataSource([...props.detail.devInstrucstions, tempDto]);
            setGap(props.gap - (!!tempDto.instrucstionQuantity ? tempDto.instrucstionQuantity : 0));
            return;
          }
        }
        setDataSource(props.detail.devInstrucstions);
        setGap(props.gap);
        return;
      }
      setDataSource([]);
      setGap(0);
    }, [props.detail, props.editingDetail]
  );

  const isEditing = (record: DevInstrucstionRecordDto) => (!!props.editingDetail && props.editingDetail.type === 'DevInstrucstion' && props.editingDetail.id === record.id);

  const submitForm = (record: DevInstrucstionRecordDto) => {
    form.validateFields()
      .then((row) => {

        const dto: DevInstrucstionRecordDto = { ...record, ...row, };

        props.save(dto);
      });
  };

  // 納品回答　→　出荷情報の追加処理
  const addOrderResponse = (record: DevInstrucstionRecordDto) => {

    if (!!props.editingDetail) {
      return;
    }

    const dto: OrderResponseRecordDto = { type: 'OrderResponse', id: -1, deliveryPlanDate: record.devInstrucstionDate, quantity: record.instrucstionQuantity, delFlag:'', detailNo:0};
    props.setEditingDetail(dto);
  }

  // 編集ボタン関連
  const editButtons = (text: any, record: DevInstrucstionRecordDto, index?: number) => {

    // 編集中の場合、保存とキャンセルボタンを表示する
    if (isEditing(record)) {
      return (<>
        <Popconfirm
          title={intl.formatMessage({ id: 'message.editConfirm' })}
          onConfirm={() => submitForm(record)}
          okText={intl.formatMessage({ id: 'common.confirm' })}
          cancelText={intl.formatMessage({ id: 'common.cancel2' })} >
          <Button type='link'>
            <FormattedMessage id='common.save' />
          </Button>
        </Popconfirm>
        <Button type='link' onClick={() => props.setEditingDetail(undefined)}>
          <FormattedMessage id='common.cancel2' />
        </Button>
      </>)
    }

    // サプライヤー以外の場合
    if (!!user) {
      if (!user.isSupplier) {
        // 納品指示の場合は編集可能で表示する
        return (<>
          <Button type='link' onClick={() => props.setEditingDetail(record)} disabled={!!props.editingDetail}>
            <FormattedMessage id='common.edit' />
          </Button>
          <Popconfirm  disabled={!!props.editingDetail}
            title={intl.formatMessage({ id: 'message.cancel2Confirm' })}
            onConfirm={() => props.remove(record)}

            okText={intl.formatMessage({ id: 'common.yes' })}
            cancelText={intl.formatMessage({ id: 'common.no' })} >
            <Button type='link' disabled={!!props.editingDetail}>
              <FormattedMessage id='common.cancel2' />
            </Button>
          </Popconfirm>
        </>);
      } else {
        // 納品指示の「追加」を表示する
        return (<>
          <Button type='link' onClick={() => addOrderResponse(record)} disabled={!!props.editingDetail || props.orderResponseGap <= 0}>
            <FormattedMessage id='orderResponse.delivery' />
          </Button>
        </>);
      }
    }
  }

  const notAllow = (current: DevInstrucstionRecordDto): (Dayjs | null)[] => {
    return dataSource.filter(dto => (dto.id !== current.id)).map(dto => dto.devInstrucstionDate);
  }

  const instrucstionQuantityWidth = '100px';
  const editButtonsWidth = '130px';
  const devInstrucstionDateWidth = '160px';

  // 納入指示日
  const devInstrucstionDateTitle = <FormattedMessage id='orderResponse.devInstrucstionDate' />;
  // 納入数量
  const instrucstionQuantityTitle = <FormattedMessage id='orderResponse.instrucstionQuantity' />;

  const devInstrucstionColumns: any = [
    // 納入指示日
    { title: devInstrucstionDateTitle, dataIndex: 'devInstrucstionDate', editable: true, type: 'date', render: displayUtil.date, width: devInstrucstionDateWidth },
    // 納入数量
    { title: instrucstionQuantityTitle, dataIndex: 'instrucstionQuantity', editable: true, gap: gap, type: 'number', width: instrucstionQuantityWidth },
    // アクション
    { title: '', dataIndex: 'operation', render: editButtons, width: editButtonsWidth },
  ];

  const mergedDevInstrucstionColumns: ColumnsType<DevInstrucstionRecordDto> = devInstrucstionColumns.map(
    (col: any) => {
      if (!col.editable) {
        return col;
      }

      return {
        ...col,
        onCell: (record: any) => ({
          record,
          inputType: col.type,
          dataIndex: col.dataIndex,
          title: col.title,
          max: gap + (!!record.quantity ? record.quantity : 0),
          editing: isEditing(record),
          notAllowValues: notAllow(record),
        }),
      };
    });

  const titleButtons = () => {

    if (!user || user.isSupplier) {
      return (<></>);
    }

    if (!!props.editingDetail) {
      if (props.editingDetail.type === 'DevInstrucstion') {
        return (<Button onClick={() => props.setEditingDetail(undefined)} style={{ marginLeft: '20px' }}>
          <FormattedMessage id='common.cancel' />
        </Button>);
      } else {
        return (<></>);
      }
    }

    if (gap === 0) {
      return (<></>);
    }

    return (<Button onClick={() => props.setEditingDetail({ id: -1, type: 'DevInstrucstion' })} style={{ marginLeft: '20px' }}>
      <FormattedMessage id='common.add' />
    </Button>);
  }

  const title = () => (<div style={{height: '32px', display: 'flex', alignItems: 'center'}}>
    <FormattedMessage id='orderResponse.instructionList'/>
    {titleButtons()}
  </div>)

  return (<Form form={form} component={false}>
    <Table<DevInstrucstionRecordDto>
      title={title}
      loading={props.loading}
      className="list-detail-table"
      bordered={false}
      rowClassName={displayUtil.rowClassName}
      components={{
        body: {
          cell: EditableCellNumberCheck
        },
      }}
      dataSource={dataSource}
      columns={mergedDevInstrucstionColumns}
      pagination={false}
      size='small'
      style={{ transform: 'translateY(-20px)' }}
      scroll={{ scrollToFirstRowOnChange: true, y: '150px' }} />
  </Form>);
}
