// React APIs
import React from 'react';
import { FormattedMessage, useIntl, } from 'react-intl';
import { Modal, Form, Select, Input, Button, Row, Col, Popconfirm } from 'antd';

// 機能内部のAPIs
import { Field } from '../fields';
import { DocCreateDto } from './api'
import './index.css';
import { CommonFileDisplay } from '../common/CommonFileDisplay'

const { TextArea } = Input;

export interface Props {
  supplierOptions: Field[];
  categoryOptions: Field[];
  visible: boolean;
  confirmData?: DocCreateDto
  closeConfirm: () => void;
  handleCreate: (createDto: DocCreateDto) => void;
}

export function DocCreateConfirm(props: Props) {


  const [form] = Form.useForm<DocCreateDto>();
  const categoryTitle = <FormattedMessage id='docInfo.documentCategory' />;
  const supplierTitle = <FormattedMessage id='docInfo.destinationName' />;
  const remarksTitle = <FormattedMessage id='docInfo.sendContent' />;
  const uploadTitle = <FormattedMessage id='common.upload' />;
  const titleTitle = <FormattedMessage id='docInfo.title' />;
  const confirm = <FormattedMessage id='common.confirm' />;
  const createCancel = <FormattedMessage id='common.cancel' />;
  const createConfirm = <FormattedMessage id='message.sendConfirm' />;
  const intl = useIntl();

  if(!!props.confirmData) {
    form.setFieldsValue(props.confirmData)
  }

  const fileDownloadProps = {
    attachments: !!props.confirmData?.attachments ? props.confirmData.attachments : [], 
    canUpload: false
  }

  const handleSubmit = (docCreateDto: DocCreateDto) => {
    props.handleCreate(docCreateDto);
    form.resetFields()  // 清除表单所有数据
  };

  const handleCloseConfirm = () => {
    form.resetFields()  // 清除表单所有数据
    props.closeConfirm();
  };
  
  const width = "1024px"
  return (
    <Modal
      width={width}
      title={intl.formatMessage({id: 'docInfo.confirmSendDocument'})}
      footer={null}
      visible={props.visible}
      closable={false}
      afterClose={handleCloseConfirm}
      destroyOnClose={true}
      zIndex={800}>
      <Form
        className="form"
        form={form}
        preserve={false}
        onFinish={handleSubmit}
        layout={'horizontal'}
        colon={false}
        requiredMark={true}
        size={'large'}
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 16 }}
      >
        <Row>
          <Col span={13}>
            <Row>
              <Col span={24}>
                <Form.Item name='supplier' label={supplierTitle}>
                  <Select
                    options={props.supplierOptions}   // 选项数据源
                    mode={'multiple'}  //select模式，详见 Ant d 的select组件
                    size={'large'} //选择框的大小
                    disabled={true}
                    style={{maxHeight: '516px', overflow: 'auto'}}
                  />
                </Form.Item>
              </Col>
            </Row>
            {
              !!props.confirmData?.supplier && 
              <Row style={{transform: 'translate(0px, -25px)'}}>
                <Col span={16} offset={6} >
                  {intl.formatMessage({id: 'docInfo.numberOfSuppliers'}, {i: props.confirmData?.supplier.length})}
                </Col>
              </Row>
            }
          </Col>
          <Col span={11}>
            <Form.Item name='documentCategory' label={categoryTitle}>
              <Select disabled={true} options={props.categoryOptions}/>
            </Form.Item>
            <Form.Item name='title' label={titleTitle}>
              <TextArea showCount autoSize maxLength={120} disabled={true} />
            </Form.Item>
            <Form.Item name='message' label={remarksTitle}>
              <TextArea showCount autoSize={{ minRows: 15 }} disabled={true}/>
            </Form.Item>
            {
              !!props.confirmData?.attachments && props.confirmData?.attachments.length > 0 &&
                <Form.Item name='files' label={uploadTitle}>
                  <Form.Item name='attachments' valuePropName='fileList' noStyle>
                    <CommonFileDisplay  {...fileDownloadProps} />
                  </Form.Item>
                </Form.Item>
            }
          </Col>
        </Row>
        <Row>
          <Col span={5} offset={16}>
            <Row>
              <Col span={5} offset={7}>
                <Button onClick={handleCloseConfirm}>
                  <FormattedMessage id='common.previous' />
                </Button>
              </Col>
              <Col span={5} offset={3}>
                <Popconfirm
                  title={createConfirm}
                  onConfirm={() => {
                    form.submit()
                  }}
                  okText={confirm}
                  cancelText={createCancel}
                >
                  <Button
                    className='submitBtn'
                    htmlType='submit'
                  >
                    <FormattedMessage id='common.send' />
                  </Button>
                </Popconfirm>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}
