import React from 'react';
import { Layout } from 'antd';
import './header.css';

const { Footer } = Layout;

export function SuppierCommnunityFooter() {

    return (
        <Footer className='footer' style={{ textAlign: 'left' }}>
            <div>
                2020i-PROCo,Ltd,All Rights Reserved
            </div>
        </Footer>
    );

}

