// React APIs
import dayjs from 'dayjs';
import React, { useState, useEffect, } from 'react';
import { useIntl } from 'react-intl';
import { useLocation, useHistory, useParams, } from "react-router-dom";
// Antd APIs
// Library APIs
import XLSX from 'xlsx';
import { DetailStates, ListStates, } from '../common';
import { displayUtil } from '../util';
import { useFields } from '../fields';

// 共通のAPIs
// 機能内部のAPIs
import { get, search2Dto, dto2search, OrderListRecordDto, OrderResponseSearchDto, getById, uploadOrderResponses, uploadDeliveryInfos, uploadDevInstrucstions, readAll } from './api';

import { Props as DetailProps } from './OrderResponseDetail';
import { Props as ListProps } from './OrderResponseList'
import { Props as SearchProps } from './OrderResponseSearch'
import { Props as OrderResponseUploadProps } from './OrderResponseUpload'
import { Props as DeliveryUploadProps } from './DeliveryDetailUpload'
import { Props as DevInstrucstionUploadProps } from './DevInstrucstionUpload'
import { login } from '../authorization/api';

/**
 * 所要計画のコンポネート
 */
export function OrderResponseHoc(
  Detail: React.ComponentType<DetailProps>,
  List: React.ComponentType<ListProps>,
  Search: React.ComponentType<SearchProps>,
  OrderResponseUpload: React.ComponentType<OrderResponseUploadProps>,
  DeliveryDetailUpload: React.ComponentType<DeliveryUploadProps>,
  DevInstrucstionUpload: React.ComponentType<DevInstrucstionUploadProps>) {

  // 共通のstates
  const intl = useIntl();
  // URLのID
  const { id: urlId } = useParams<{ id?: string }>();
  // URLのquery文字列
  const { search } = useLocation();
  // URL変更のハンドル
  const { push, goBack } = useHistory();

  const infoTypeOptions = useFields('infoType');

  const qualityCheckFlagOptions = useFields('qualityCheckFlag');

  const locationNameOptions = useFields('locationName')

  // 検索条件（フィルター、ページとソート順）
  const [searchDto, setSearchDto] = useState<OrderResponseSearchDto>(search2Dto(search));

  // 一覧のstates
  // 画面loading状態
  const [listData, setListData] = useState<ListStates<OrderListRecordDto>>({ loading: false, total: 0, data: [] });

  // 検索のstates
  // 検査画面の表示状態
  const [searchVisible, setSearchVisible] = useState<boolean>(false);

  // 詳細のstates
  const [detailData, setDetailData] = useState<DetailStates<OrderListRecordDto>>({ visible: false });

  // uploadLoading
  const [orderResponseUploading, setOrderResponseUploading] = useState<boolean>(false);
  const [deliveryUploading, setDeliveryUploading] = useState<boolean>(false);
  const [devInstrucstionUploading, setDevInstrucstionUploading] = useState<boolean>(false);
  

  // URLのQuery変更によって検索を再実施する
  useEffect((): void => {
    setDetailData(data => ({ ...data, visible: false }));

    if (!!urlId) {
      if (/^\d+$/.test(urlId)) {
        getById(urlId)
          .then((record: OrderListRecordDto) => {

            setMessage(record);

            setDetailData({ visible: true, data: record });
          })
          .catch(() => {
          });
      }
    } else {
      // 画面loading状態を変更する
      setListData(data => ({ ...data, loading: true }));
      // 検索条件変更を反映する
      setSearchDto(search2Dto(search));

      get(search)
        .then((para: [number, OrderListRecordDto[]]) => {
          const [count, vos] = para;

          vos.forEach(vo => setMessage(vo));

          setListData({ loading: false, total: count, data: vos });
        })
        .catch((error) => {

          setListData(data => ({ ...data, loading: false }));
        });
    }
  }, [urlId, search]);

  const setMessage = (record: OrderListRecordDto) => {
    const today = dayjs().endOf('day');

    const max = record.orderResponses
      .filter(dto => dto.deliveryPlanDate?.isBefore(today));

    if (max.length === 0) {
      return;
    }

    const sumShipmentQuantity = record.deliveryDetails
      .filter(dto => dto.estimatedTimeArriva?.isBefore(today))
      .map(dto => !!dto.quantity ? dto.quantity : 0)
      .reduce((n, m) => (n + m), 0);

    const sumAnswerQuantity = max
      .map(dto => !!dto.quantity ? dto.quantity : 0)
      .reduce((n, m) => (n + m), 0);

    if (sumShipmentQuantity < sumAnswerQuantity) {
      max[max.length - 1].message = intl.formatMessage({ id: 'orderResponse.shortageMessage', }, { qty: sumAnswerQuantity - sumShipmentQuantity });
    } else if (sumShipmentQuantity > sumAnswerQuantity) {
      max[max.length - 1].message = intl.formatMessage({ id: 'orderResponse.shortageMessage2', }, { qty: sumShipmentQuantity - sumAnswerQuantity });
    }
  }

  // 検索条件を変更する
  const setSearch = (researchDto: OrderResponseSearchDto) => {

    // 期間範囲の編集
    if (!!researchDto.orderDate && !!researchDto.orderDate[0]) {
      researchDto.orderDate[0] = researchDto.orderDate[0].startOf('day');
    }
    if (!!researchDto.orderDate && !!researchDto.orderDate[1]) {
      researchDto.orderDate[1] = researchDto.orderDate[1].endOf('day');
    }
    if (!!researchDto.productionPlanDate && !!researchDto.productionPlanDate[0]) {
      researchDto.productionPlanDate[0] = researchDto.productionPlanDate[0].startOf('day');
    }
    if (!!researchDto.productionPlanDate && !!researchDto.productionPlanDate[1]) {
      researchDto.productionPlanDate[1] = researchDto.productionPlanDate[1].endOf('day');
    }

    const query = dto2search(researchDto)
    // URLを再設定する
    push(`/orderresponses${query}`);
  };

  const download = () => {

    const downloadSearchDto = { ...searchDto, rowCount: 0 };

    setListData(data => ({ ...data, loading: true }));

    login().then(userInfo => {
      get(dto2search(downloadSearchDto))
      .then((para: [number, OrderListRecordDto[]]) => {

        setListData(data => ({ ...data, loading: false }));

        const [, vos] = para;

        // FixME
        const excelData: any = [
          [
            intl.formatMessage({ id: 'orderDetail.buyer' }),
            intl.formatMessage({ id: 'orderDetail.buyerName' }),
            intl.formatMessage({ id: 'orderDetail.supplier' }),
            intl.formatMessage({ id: 'orderDetail.supplierName' }),
            intl.formatMessage({ id: 'orderDetail.orderDate' }),
            intl.formatMessage({ id: 'orderDetail.item' }),
            intl.formatMessage({ id: 'orderDetail.itemName' }),
            intl.formatMessage({ id: 'orderDetail.orderNo' }),
            intl.formatMessage({ id: 'orderDetail.detailNo' }),
            intl.formatMessage({ id: 'orderDetail.infoType' }),
            intl.formatMessage({ id: 'orderDetail.iniDeliveryDate' }),
            intl.formatMessage({ id: 'orderDetail.productionPlanDate' }),
            intl.formatMessage({ id: 'orderDetail.quantity' }),
            intl.formatMessage({ id: 'orderDetail.acceptanceQuantity' }),
            intl.formatMessage({ id: 'orderDetail.deliveryQuantity' }),
            intl.formatMessage({ id: 'orderDetail.balanceQuantity' }),
            intl.formatMessage({ id: 'orderDetail.unit' }),
            intl.formatMessage({ id: 'orderDetail.unitPrice' }),
            intl.formatMessage({ id: 'orderDetail.amount' }),
            intl.formatMessage({ id: 'orderDetail.balanceAmount' }),
            intl.formatMessage({ id: 'orderDetail.currencyCode' }),
            intl.formatMessage({ id: 'orderDetail.purchasingManager' }),
            intl.formatMessage({ id: 'orderDetail.locationCd' }),
            intl.formatMessage({ id: 'delivery.locationCd' }),
            intl.formatMessage({ id: 'orderDetail.remark' }),
            intl.formatMessage({ id: 'acceptance.deliveryDetailNo' }),
            intl.formatMessage({ id: 'orderResponse.deliveryPlanDate' }),
            intl.formatMessage({ id: 'orderResponse.responseQuantity' }),
            intl.formatMessage({ id: 'orderResponse.responseDeleteFlag' }),
            intl.formatMessage({ id: 'orderResponse.detailNo' }),
            intl.formatMessage({ id: 'orderResponse.shipDate' }),
            intl.formatMessage({ id: 'orderResponse.estimatedTimeArriva' }),
            intl.formatMessage({ id: 'orderResponse.planDeliveryQuantity' }),
            intl.formatMessage({ id: 'orderResponse.deliveryDeleteFlag' }),
          ],
        ];
        for (let vo of vos) {
          const count = Math.max(1, vo.orderResponses.length, vo.deliveryDetails.length);

          for (let i = 0; i < count; i++) {
            excelData.push([
              vo.buyer,
              vo.buyerName,
              vo.supplier,
              vo.supplierName,
              displayUtil.date(vo.orderDate),
              vo.item,
              vo.itemName,
              vo.orderNo,
              vo.detailNo,
              displayUtil.field(infoTypeOptions)(vo.infoType),
              displayUtil.date(vo.iniDeliveryDate),
              userInfo?.serviceState === 'cn' ? displayUtil.date(vo.productionPlanDate) : '',
              vo.quantity,
              vo.acceptanceQuantity,
              vo.deliveryQuantity,
              (vo.quantity === undefined ? 0 : vo.quantity) - (vo.acceptanceQuantity === undefined ? 0 : vo.acceptanceQuantity),
              vo.unit, 
              vo.unitPrice,
              vo.amount,
              displayUtil.amount(vo.currencyCode,
                ((vo.quantity === undefined ? 0 : vo.quantity) - (vo.acceptanceQuantity === undefined ? 0 : vo.acceptanceQuantity)), vo.unitPrice),
              vo.currencyCode,
              vo.purchasingManagerName,
              vo.locationCd,
              displayUtil.field(locationNameOptions)(vo.locationName),
              displayUtil.field(qualityCheckFlagOptions)(vo.qualityCheckFlag) + "／" + vo.text,
              i < vo.orderResponses.length ? vo.orderResponses[i].detailNo : '',
              i < vo.orderResponses.length ? displayUtil.date(vo.orderResponses[i].deliveryPlanDate) : '',
              i < vo.orderResponses.length ? vo.orderResponses[i].quantity : '',
              '',
              i < vo.deliveryDetails.length ? vo.deliveryDetails[i].detailNo : '',
              i < vo.deliveryDetails.length ? displayUtil.date(vo.deliveryDetails[i].shipDate) : '',
              i < vo.deliveryDetails.length ? displayUtil.date(vo.deliveryDetails[i].estimatedTimeArriva) : '',
              i < vo.deliveryDetails.length ? vo.deliveryDetails[i].quantity : '',
            ]);

          }
        }

        const worksheet = XLSX.utils.aoa_to_sheet(excelData);
        const workbook = XLSX.utils.book_new();

        XLSX.utils.book_append_sheet(workbook, worksheet, intl.formatMessage({ id: 'orderResponse' }));
        XLSX.writeFile(workbook, `${intl.formatMessage({ id: 'orderResponse' })}_${dayjs().format('YYYYMMDDHHmmss')}.xlsx`);
      })
      .catch(() => {
        setListData(data => ({ ...data, loading: false }));
      });
    })
  };

  const downloadInstrucstion = () => {

    const downloadSearchDto = { ...searchDto, rowCount: 0 };

    setListData(data => ({ ...data, loading: true }));
    login().then(userInfo => {
      get(dto2search(downloadSearchDto))
      .then((para: [number, OrderListRecordDto[]]) => {

        setListData(data => ({ ...data, loading: false }));

        const [, vos] = para;
        const excelData: any = [
          [
            intl.formatMessage({ id: 'orderDetail.buyer' }),
            intl.formatMessage({ id: 'orderDetail.buyerName' }),
            intl.formatMessage({ id: 'orderDetail.supplier' }),
            intl.formatMessage({ id: 'orderDetail.supplierName' }),
            intl.formatMessage({ id: 'orderDetail.orderDate' }),
            intl.formatMessage({ id: 'orderDetail.item' }),
            intl.formatMessage({ id: 'orderDetail.itemName' }),
            intl.formatMessage({ id: 'orderDetail.orderNo' }),
            intl.formatMessage({ id: 'orderDetail.detailNo' }),
            intl.formatMessage({ id: 'orderDetail.infoType' }),
            intl.formatMessage({ id: 'orderDetail.iniDeliveryDate' }),
            intl.formatMessage({ id: 'orderDetail.productionPlanDate' }),
            intl.formatMessage({ id: 'orderDetail.quantity' }),
            intl.formatMessage({ id: 'orderDetail.acceptanceQuantity' }),
            intl.formatMessage({ id: 'orderDetail.deliveryQuantity' }),
            intl.formatMessage({ id: 'orderDetail.balanceQuantity' }),
            intl.formatMessage({ id: 'orderDetail.unit' }),
            intl.formatMessage({ id: 'orderDetail.unitPrice' }),
            intl.formatMessage({ id: 'orderDetail.amount' }),
            intl.formatMessage({ id: 'orderDetail.balanceAmount' }),
            intl.formatMessage({ id: 'orderDetail.currencyCode' }),
            intl.formatMessage({ id: 'orderDetail.purchasingManager' }),
            // intl.formatMessage({ id: 'orderDetail.locationCode' }),
            intl.formatMessage({ id: 'orderDetail.locationCd' }),
            intl.formatMessage({ id: 'orderDetail.remark' }),
            intl.formatMessage({ id: 'devInstrucstion.devInstrucstionDate' }),
            intl.formatMessage({ id: 'devInstrucstion.instrucstionQuantity' })
          ],
        ];
        for (let vo of vos) {
          for (let i = 0; i < vo.devInstrucstions.length; i++) {
            excelData.push([
              vo.buyer,
              vo.buyerName,
              vo.supplier,
              vo.supplierName,
              displayUtil.date(vo.orderDate),
              vo.item,
              vo.itemName,
              vo.orderNo,
              vo.detailNo,
              displayUtil.field(infoTypeOptions)(vo.infoType),
              displayUtil.date(vo.iniDeliveryDate),
              userInfo?.serviceState === 'cn' ? displayUtil.date(vo.productionPlanDate) : '',
              vo.quantity,
              vo.acceptanceQuantity,
              vo.deliveryQuantity,
              (vo.quantity === undefined ? 0 : vo.quantity) - (vo.acceptanceQuantity === undefined ? 0 : vo.acceptanceQuantity),
              vo.unit,
              vo.unitPrice,
              vo.amount,
              ((vo.quantity === undefined ? 0 : vo.quantity) - (vo.acceptanceQuantity === undefined ? 0 : vo.acceptanceQuantity))
              * (vo.unitPrice === undefined ? 0 : vo.unitPrice),
              vo.currencyCode,
              vo.purchasingManagerName,
              vo.locationCd,
              // vo.locationName,
              displayUtil.field(qualityCheckFlagOptions)(vo.qualityCheckFlag) + "／" + vo.text,
              i < vo.devInstrucstions.length ? displayUtil.date(vo.devInstrucstions[i].devInstrucstionDate) : '',
              i < vo.devInstrucstions.length ? vo.devInstrucstions[i].instrucstionQuantity : ''
            ]);
          }
        }

        const worksheet = XLSX.utils.aoa_to_sheet(excelData);
        const workbook = XLSX.utils.book_new();

        XLSX.utils.book_append_sheet(workbook, worksheet, intl.formatMessage({ id: 'devInstrucstion' }));
        XLSX.writeFile(workbook, `${intl.formatMessage({ id: 'devInstrucstion' })}_${dayjs().format('YYYYMMDDHHmmss')}.xlsx`);
      })
      .catch(() => {
        setListData(data => ({ ...data, loading: false }));
      });
    })
  }
  const closeDetail = () => {
    if (dto2search(searchDto) !== '') {
      goBack();
    } else {
      push('/orderresponses');
    }
  }

  const reset = (rowCount: number) => {
    setSearch({
      suppliers: [],

      items: [],

      infoType: '',

      iniDeliveryDate: [null, null],

      productionPlanDate: null,

      rowCount: rowCount,

      orderDate: null,

      orderNo: '',

      supplierName: '',

      itemName: '',

      purchasingManagerName: '',

      locationCd: '',

      unitPrice: [undefined, undefined],

      amount: [undefined, undefined],

      balanceAmount: [undefined, undefined],

    } as OrderResponseSearchDto)
  }

  const listProps: ListProps = {
    // 画面loading状態
    loading: listData.loading,
    // 件数合計
    total: listData.total,
    // 一覧画面の表示データ
    data: listData.data,
    // 検索条件（フィルター、ページとソート順）
    searchDto: searchDto,
    // 検査画面を表示する
    openSearchModal: () => setSearchVisible(true),
    openUpload: (url: 'uploadorderresponses' | 'uploaddeliveryinfos' | 'uploadDevInstrucstions') => () => {
      // URLを再設定する
      push(`/orderresponses/${url}`);
    },
    download: download,
    // フィルター、ページ又はソート順の変更より再検索する
    handleResearch: setSearch,
    downloadInstrucstion: downloadInstrucstion,
    readAll: () => {
      // 画面loading状態を変更する
      setListData(data => ({ ...data, loading: true }));
      readAll().then(() => {
        // 検索条件変更を反映する
        setSearchDto(search2Dto(search));
        get(search).then((para: [number, OrderListRecordDto[]]) => {
          const [count, vos] = para;

          vos.forEach(vo => setMessage(vo));

          setListData({ loading: false, total: count, data: vos });
        }).catch((error) => {

          setListData(data => ({ ...data, loading: false }));
        });
      }).catch((error) => {

        setListData(data => ({ ...data, loading: false }));
      });
    },
    reset: reset,
  };

  const searchProps: SearchProps = {
    // 検査画面の表示状態
    visible: searchVisible,
    searchDto: searchDto,
    close: () => setSearchVisible(false),
    handleSearch: (value: OrderResponseSearchDto) => {
      setSearchVisible(false);
      setSearch(value);
    }
  };


  const detailProps = {
    // 詳細画面の表示状態
    visible: detailData.visible,
    detail: detailData.data,
    close: closeDetail,
    refresh: () => {
      if (!!(urlId)) {
        getById(urlId)
          .then((record: OrderListRecordDto) => {

            setMessage(record);

            setDetailData({ visible: true, data: record });
          })
          .catch(() => {
          });
      }
    },
  };

  const handleUploadOrderResponses = (data: OrderListRecordDto[]) => {
    setOrderResponseUploading(true)
    return uploadOrderResponses(data).then(() => {
      setOrderResponseUploading(false)
    }).catch(() => {
      setOrderResponseUploading(false)
    })
  }

  const handleDeliveryUpload = (data: OrderListRecordDto[]) => {
    setDeliveryUploading(true)
    return uploadDeliveryInfos(data).then(() => {
      setDeliveryUploading(false)
    }).catch(() => {
      setDeliveryUploading(false)
    })
  }

  const handleUploadDevInstrucstions = (data: OrderListRecordDto[]) => {
    setDevInstrucstionUploading(true)
    return uploadDevInstrucstions(data).then(() => {
      setDevInstrucstionUploading(false)
    }).catch(() => {
      setDevInstrucstionUploading(false)
    })
  }


  const orderResponseUploadProps: OrderResponseUploadProps = {
    visible: urlId === 'uploadorderresponses',
    upload: handleUploadOrderResponses,
    close: closeDetail,
    uploading: orderResponseUploading,
  };

  const deliveryDetailUploadProps: DeliveryUploadProps = {
    visible: urlId === 'uploaddeliveryinfos',
    upload: handleDeliveryUpload,
    close: closeDetail,
    uploading: deliveryUploading,
  };

  const devInstrucstionUploadProps: DevInstrucstionUploadProps = {
    visible: urlId === 'uploadDevInstrucstions',
    upload: handleUploadDevInstrucstions,
    close: closeDetail,
    uploading: devInstrucstionUploading
  };
  return (
    <>
      <List {...listProps} />
      <Search {...searchProps} />
      <Detail {...detailProps} />
      <OrderResponseUpload {...orderResponseUploadProps} />
      <DeliveryDetailUpload {...deliveryDetailUploadProps} />
      <DevInstrucstionUpload {...devInstrucstionUploadProps} />
    </>
  );
}
