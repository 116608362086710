// React APIs
import React, { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useParams, useHistory, useLocation, } from "react-router-dom";

// Library APIs
import XLSX from 'xlsx';

// 共通のAPIs
import { useFields } from '../fields';

// 機能内部のAPIs
import {
  get, getById, getByDetail, createDoc,
  DocListRecordDto, DocDetailDto, DocSearchDto, DocCreateDto, search2Dto, dto2search, DocDetailSupplierDto,
  DocReplyDto, accept, notAccept, detailDelete, removeById, replyCommit, getFileList, downloadTemplate
} from './api'
import { Props as ListProps } from './DocList';
//FormProps->replyProps
import { Props as DocReplyProps } from './DocReplyDetail';
import { Props as DetailProps } from './DocDetail';
import { Props as SearchProps } from './DocSearch';
import { Props as CreateProps } from './DocCreate';
import { Props as CreateConfirmProps } from './DocCreateConfirm';
import { DetailStates, ListStates } from '../common';
import dayjs from 'dayjs';
import { useUser } from '../authorization';
import { displayUtil } from '../util';



/**
 * 文書管理のコンポネート
 */
export function DocHoc(
  List: React.ComponentType<ListProps>,
  Detail: React.ComponentType<DetailProps>,
  Search: React.ComponentType<SearchProps>,
  Create: React.ComponentType<CreateProps>,
  CreateConfirm: React.ComponentType<CreateConfirmProps>,
  DocReplyDetail: React.ComponentType<DocReplyProps>,
) {
  // 共通のstates
  const intl = useIntl();
  // URLのID
  const { id: urlId } = useParams<{ id?: string }>();
  const { detailId } = useParams<{ detailId?: string }>();
  const categoryOptions = useFields('category');
  const supplierOptions = useFields('supplier');
  const fullSuppliers = useFields('fullSupplier');
  const fullLoginedSupplierOptions = useFields('fullLoginedSupplier')
  const buyerOptions = useFields('buyer');
  const replyFlagOptions = useFields('replyFlag');
  const acceptFlag = useFields('acceptFlag');
  const readFlag = useFields('readFlag');

  // URL変更のハンドル
  const { push, goBack } = useHistory();
  // URLのquery文字列
  const { search } = useLocation();
  // 検索条件（フィルター、ページとソート順）
  const [searchDto, setSearchDto] = useState<DocSearchDto>(search2Dto(search));

  // 一覧のstates
  const [listData, setListData] = useState<ListStates<DocListRecordDto>>({ loading: false, total: 0, data: [] });

  // 詳細のstates
  const [detailData, setDetailData] = useState<DetailStates<DocDetailDto>>({ visible: false });
  const [detailData1, setDetailData1] = useState<DetailStates<DocDetailSupplierDto>>({ visible: false });
  const [disabled, setDisabled] = useState<boolean>(false);

  // Refresh
  const [refresh, setRefresh] = useState<boolean>(false);

  // 検索のstates
  const [searchVisible, setSearchVisible] = useState<boolean>(false);

  // 新規のstates
  const [createVisible, setCreateVisible] = useState<boolean>(false);

  // selectのstates
  const [createSelectVisible, setCreateSelectVisible] = useState<boolean>(false);

  // 是否清除数据的状态　データを削除のstates
  const [formDataClearable, setFormDataClearable] = useState<boolean>(false);

  // 送信确认页面 visable
  const [createConfirmVisible, setCreateConfirmVisible] = useState<boolean>(false);

  // 送信数据
  const [createData, setCreateData] = useState<DocCreateDto>();

  const user = useUser();
  // URLのQuery変更によって検索を再実施する
  useEffect(() => {
    if (!!urlId && !!detailId) { //supplier详细画面
      getByDetail(urlId, detailId)
        .then((record: DocDetailSupplierDto) => {
          setDetailData1({ visible: true, data: record });
          if (record.acceptFlag === '002') {
            setDisabled(true)
          } else {
            setDisabled(false)
          }
        })
    } else if (!!urlId) {
      getById(urlId)
        .then((record: DocDetailDto) => {
          setDetailData({ visible: true, data: record });
        })
    } else {
      setDetailData({ ...detailData.data, visible: false })
      setDetailData1({ ...detailData1.data, visible: false })
      setListData(data => ({ ...data, loading: true }))
      setSearchDto(search2Dto(search));
      // search = '/list'+search
      get(search)
        .then((para: [number, DocListRecordDto[]]) => {
          const [count, vos] = para;
          setListData({ loading: false, total: count, data: vos })
        })
        .catch(() => {
          setListData(data => ({ ...data, loading: false }))
        });
    }
  }, [urlId, search, detailId, refresh]);

  // 検索条件を変更する
  const setSearch = (researchDto: DocSearchDto) => {

    // 期間範囲の編集
    if (!!researchDto.sendTime && !!researchDto.sendTime[0]) {
      researchDto.sendTime[0] = researchDto.sendTime[0].startOf('day');
    }
    if (!!researchDto.sendTime && !!researchDto.sendTime[1]) {
      researchDto.sendTime[1] = researchDto.sendTime[1].endOf('day');
    }

    // 検索条件変更を反映する
    const query = dto2search(researchDto);

    // URLを再設定する
    push(`/docs${query}`);
  }


  // レコードを削除する
  const handleDelete = (data: DocListRecordDto) => {

    removeById(data)
      .then(() => {
        setRefresh(!refresh)
      })
  };

  // 詳細の処理
  const handleSubmit = (value: DocDetailSupplierDto) => {

    replyCommit(value, detailData1.data)
      .then(() => {
        setRefresh(!refresh)
      });
  };

  // 検索の処理
  const handleSearch = (value: any) => {
    setSearchVisible(false);
    setSearch(value);
  };

  const handleCreate = (value: DocCreateDto) => {
    createDoc(value)
      .then(() => {
        setCreateConfirmVisible(false)
        setCreateSelectVisible(true)
        setFormDataClearable(!formDataClearable)
        setRefresh(!refresh);
      }).catch( () => {
        setCreateConfirmVisible(false)
        setCreateSelectVisible(true)
      })
  }

  const handleOpenCreateConfirm = (value: DocCreateDto) => {
    
    const fileUuidList = value.attachments.map( attachment => attachment.uuid)
    if(fileUuidList.length > 0) {
      getFileList(fileUuidList).then((result) => {
        value.attachments = result
        setCreateData(value)
      })
    } else {
      setCreateData(value)
    }

    setCreateSelectVisible(false)
    setCreateConfirmVisible(true)
  }

  const handleDetailRecepted = (data: DocReplyDto) => {
    accept(data)
      .then(() => {
        setRefresh(!refresh);
      })
  }

  const handleDetailNotRecept = (data: DocReplyDto) => {
    notAccept(data)
      .then(() => {
        setRefresh(!refresh);
      })
  }

  const handleDetailDelete = (dto: DocReplyDto) => {
    detailDelete(dto)
      .then(() => {
        setRefresh(!refresh);
      })
    detailData.data?.details.filter(d => d === dto);
  }

  const download = () => {

    const downloadSearchDto = { ...searchDto, rowCount: 0 };

    setListData(data => ({ ...data, loading: true }));

    get(dto2search(downloadSearchDto))
      .then((para: [number, DocListRecordDto[]]) => {

        setListData(data => ({ ...data, loading: false }));

        const [, vos] = para;

        const excelData = [
          [
            intl.formatMessage({ id: 'docInfo.sendTime' }),
            intl.formatMessage({ id: 'docInfo.documentCategory' }),
            intl.formatMessage({ id: 'docInfo.title' }),
            intl.formatMessage({ id: 'docInfo.sendContent' }),
            intl.formatMessage({ id: 'docInfo.sendQuantity' }),
            intl.formatMessage({ id: 'docInfo.acceptQuantity' }),
            intl.formatMessage({ id: 'docInfo.replayQuantity' })
          ],
          ...(vos.map(vo => [
            displayUtil.date(vo.sendTime),
            displayUtil.field(categoryOptions)(vo.documentCategory),
            vo.title,
            vo.message,
            vo.sendQuantity,
            vo.acceptQuantity,
            vo.replayQuantity
          ])),
        ];

        const excelSupplierData = [
          [
            intl.formatMessage({ id: 'docInfo.replyFlag' }),
            intl.formatMessage({ id: 'docInfo.buyerName' }),
            intl.formatMessage({ id: 'docInfo.supplier' }),
            intl.formatMessage({ id: 'docInfo.supplierName' }),
            intl.formatMessage({ id: 'docInfo.receiptTime' }),
            intl.formatMessage({ id: 'docInfo.documentCategory' }),
            intl.formatMessage({ id: 'docInfo.title' }),
            intl.formatMessage({ id: 'docInfo.sendContent' }),
          ],
          ...(vos.map(vo => [
            displayUtil.field(replyFlagOptions)(vo.replyFlag),
            displayUtil.field(buyerOptions)(vo.buyer),
            vo.supplier,
            displayUtil.field(supplierOptions)(vo.supplier),
            displayUtil.date(vo.sendTime),
            displayUtil.field(categoryOptions)(vo.documentCategory),
            vo.title,
            vo.message
          ])),
        ];

        const worksheet = XLSX.utils.aoa_to_sheet(!!user?.isSupplier ? excelSupplierData : excelData);
        const workbook = XLSX.utils.book_new();

        XLSX.utils.book_append_sheet(workbook, worksheet, intl.formatMessage({ id: 'return' }));
        XLSX.writeFile(workbook, `${intl.formatMessage({ id: 'docInfo' })}_${dayjs().format('YYYYMMDDHHmmss')}.xlsx`);
      })
      .catch(() => {

        setListData(data => ({ ...data, loading: false }));
      });
  }

  const downloanDetail = () => {
    if(!!detailData.data?.details && detailData.data.details.length > 0) {
      const excelData = [
        [
          intl.formatMessage({ id: 'docInfo.acceptFlag' }),
          intl.formatMessage({ id: 'docInfo.readFlag' }),
          intl.formatMessage({ id: 'docInfo.replyFlag' }),
          intl.formatMessage({ id: 'docInfo.supplier' }),
          intl.formatMessage({ id: 'docInfo.replyContent' }),
          intl.formatMessage({ id: 'docInfo.replyUserName' }),
          intl.formatMessage({ id: 'docInfo.replyTime' })
        ],
        ...(detailData.data.details.map(detail => [
          displayUtil.field(acceptFlag)(detail.acceptFlag),
          displayUtil.field(readFlag)(detail.readFlag),
          displayUtil.field(replyFlagOptions)(detail.replyFlag),
          displayUtil.field(fullSuppliers)(detail.supplier),
          detail.replyMessage,
          detail.userName,
          displayUtil.date(detail.replyTime),
        ])),
      ];

      const worksheet = XLSX.utils.aoa_to_sheet(excelData);
      const workbook = XLSX.utils.book_new();

      XLSX.utils.book_append_sheet(workbook, worksheet, intl.formatMessage({ id: 'return' }));
      XLSX.writeFile(workbook, `${intl.formatMessage({ id: 'docInfo.replyInfo' })}_${dayjs().format('YYYYMMDDHHmmss')}.xlsx`);
    }
  }

  const reset = (rowCount: number) => {
    setSearch({
      documentCategory: [],

      title: '',

      sendTime: [null, null],

      replyFlag: [],
      
      suppliers: [],

      rowCount: rowCount

    } as DocSearchDto)
  }

  const listProps = {
    // 画面loading状態
    loading: listData.loading,
    // 件数合計
    total: listData.total,
    // 一覧画面の表示データ
    data: listData.data,
    // 検索条件（フィルター、ページとソート順）
    searchDto: searchDto,
    categoryOptions: categoryOptions,
    supplierOptions: supplierOptions,
    buyerOptions: buyerOptions,
    replyFlagOptions: replyFlagOptions,
    download: download,
    // 検査画面を表示する
    openSearchModal: () => { setSearchVisible(true) },
    // 详细画面を表示する
    openFormModal: () => { setDetailData({ visible: true }) },
    // 新規画面を表示する
    openCreateModal: () => {
      setCreateVisible(true)
      setTimeout(() => {
        setCreateSelectVisible(true);
      }, 500)
    },
    handleDelete: handleDelete,
    handleResearch: setSearch,
    handleSearch: handleSearch,
    handleSubmit: (data: any) => {
      getByDetail(data.id, data.detailId)
        .then((record: DocDetailSupplierDto) => {
          record.replyMessage = data.replyMessage
          handleSubmit(record)
        })
    },
    reset: reset
  }

  const detailProps = {
    urlId: urlId,
    detailData: detailData,
    visible: detailData.visible,
    formDto: detailData.data,
    detailOptions: detailData.data?.details,
    disabled: disabled,
    buyerOptions: buyerOptions,
    handleDetailDelete: handleDetailDelete,
    handleDetailNotRecept: handleDetailNotRecept,
    handleDetailRecepted: handleDetailRecepted,
    handleDownload: downloanDetail,
    close: () => {
      if (dto2search(searchDto) !== '') {
        goBack();
        setDetailData({ visible: false })
      } else {
        setDetailData({ visible: false })
        push('/docs');
      }
    }
  }

  const formProps = {
    visible: detailData1.visible,
    formDto: detailData1.data,
    disabled: disabled,
    buyerOptions: buyerOptions,
    handleSubmit: handleSubmit,
    close: () => {
      if (dto2search(searchDto) !== '') {
        goBack();
        setDetailData({ visible: false })
      } else {
        setDetailData1({ visible: false })
        goBack();
        // push('/docs');
      }
    }
  }

  const searchProps = {
    visible: searchVisible,
    searchDto: searchDto,
    categoryOptions: categoryOptions,
    supplierOptions: supplierOptions,
    buyerOptions: buyerOptions,
    handleSearch: handleSearch,
    replyFlagOptions: replyFlagOptions,
    close: () => {
      setSearchVisible(false);
    }
  }

  const createProps = {
    disabled: disabled,
    visible: createVisible,
    supplierOptions: fullLoginedSupplierOptions,
    categoryOptions: categoryOptions,
    handleOpenCreateConfirm: handleOpenCreateConfirm,
    selectVisible: createSelectVisible,
    setSelectVisible: () => {
      setCreateSelectVisible(!createSelectVisible)
    },
    formDataClearable: formDataClearable,
    close: () => {
      setCreateSelectVisible(false);
      setCreateVisible(false);
    },
    downloadTemplate: () => {
      downloadTemplate()
    }
  }

  const createConfirmProps = {
    visible: createConfirmVisible,
    confirmData: createData,
    supplierOptions: fullLoginedSupplierOptions,
    categoryOptions: categoryOptions,
    closeConfirm: () => {
      setCreateConfirmVisible(false)
      setCreateSelectVisible(true)
    },
    handleCreate: handleCreate,
  }

  return (
    <>
      <List {...listProps} />
      <Detail {...detailProps} />
      <DocReplyDetail {...formProps} />
      <Search {...searchProps} />
      <Create {...createProps} />
      <CreateConfirm {...createConfirmProps} />
    </>
  );
}

