import React, { useEffect } from 'react';
import { FormattedMessage, } from 'react-intl';

import { Modal, Form, Input, Button } from 'antd';

import { DatePicker, } from '../common'
import { NotificationSearchDto } from './api';

const { RangePicker } = DatePicker;

export interface Props {
  visible: boolean;
  searchDto: NotificationSearchDto;
  close: () => void;
  handleSearch: (searchDto: NotificationSearchDto) => void;
}

export function NotificationSearch(props: Props) {

  return (
    <Modal
      title={<FormattedMessage tagName='h4' id='common.search' />}
      footer={null}
      visible={props.visible}
      closable={false}
      afterClose={props.close}
      destroyOnClose={true}>
      {props.visible && <NotificationSearchForm {...props} />}
    </Modal>
  );
}

const NotificationSearchForm = (props: Props) => {

  const [form] = Form.useForm<NotificationSearchDto>();

  useEffect(() => {
    form.setFieldsValue(props.searchDto);
  });

  // タイトル
  const senderCodeTitle = <FormattedMessage id='notification.senderCode' />
  const subjectTitle = <FormattedMessage id='notification.subject' />
  const incomingDateAndTimeTitle = <FormattedMessage id='notification.incomingDateAndTime' />

  return (
    <Form
      form={form}
      onFinish={props.handleSearch}
      layout={'horizontal'}
      colon={false}
      size={'large'}
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 12 }}>
      <Form.Item name='senderCode' label={senderCodeTitle}>
        <Input min={1} max={40} />
      </Form.Item>
      <Form.Item name='subject' label={subjectTitle} >
        <Input min={1} max={40} />
      </Form.Item>
      <Form.Item name='incomingDateAndTime' label={incomingDateAndTimeTitle}>
        <RangePicker allowEmpty={[true, true]} style={{ width: '100%' }} />
      </Form.Item>
      <Form.Item>
        <div className='search-button'>
          <Button className='search-close-btn' onClick={props.close}>
            <FormattedMessage id='common.close' />
          </Button>
          <Button className='search-search-btn' htmlType='submit'>
            <FormattedMessage id='common.search' />
          </Button>
        </div>
      </Form.Item>
    </Form>
  );
};
