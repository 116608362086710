import React from 'react';

// 機能内部のAPIs
import { HomeList } from './HomeList';
import { HomeBatchDetail } from './HomeBatchDetail';
import { HomeHoc } from './HomeHoc';

/**
 * 所要計画のコンポネート
 */
export function Home() {

  const Home = HomeHoc(HomeList, HomeBatchDetail);

  return (
    <>
      {Home}
    </>
  );
}