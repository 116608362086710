import React from 'react'

import { Button, Form, Input, Modal, Popconfirm, Select } from 'antd'
import { FormattedMessage, useIntl } from 'react-intl'
import { useFields } from '../fields';
import { OutPutFormCondition } from './api';
export interface Props {
    visible: boolean;
    close: () => void;
    handleOutput: (outPut: OutPutFormCondition) => void;
}

export function DeliveryOuputForm(props: Props) {
    const intl = useIntl();
    const reportTypeOptions = useFields('reportType');
    const modalTitle = <FormattedMessage id='delivery.modalTitle' />;
    const [form] = Form.useForm<OutPutFormCondition>()

    // warning message
    // 納品書出力に進めていきますが再度出荷内容が正しいかご確認ください。
    // ※納品書出力後一定時間を過ぎたら、納品書の取消ができなくなりますため
    const deliveryOutputFormSubmitWarningMessage = <>{intl.formatMessage({ id: 'message.deliveryOutputFormSubmitWarning1' })} <br /> {intl.formatMessage({ id: 'message.deliveryOutputFormSubmitWarning2' })}</>
    
    const handleSubmit = () => {
        form.submit()
    }
    return (
        <>
            <Modal
                visible={props.visible}
                footer={null}
                closable={false}
                afterClose={props.close}
                title={modalTitle}
                destroyOnClose={true}
            >
                <Form
                    form={form}
                    onFinish={props.handleOutput}
                    layout={'horizontal'}
                    colon={false}
                    size={'large'}
                    labelCol={{ span: 8 }}
                    wrapperCol={{ span: 12 }}
                    preserve={false} >
                    <Form.Item name='deliveryNo' label={<FormattedMessage id='delivery.deliveryNo' />} normalize={(value) => !!value ? value.trim() : value} rules={[{ max: 16, message: intl.formatMessage({ id: 'estimate.maxError' }, { a: intl.formatMessage({ id: 'delivery.deliveryNo' }), b: '16' }) }, { pattern: /^[\x2D\x30-\x39\x41-\x5A\x5F\x61-\x7A]+$/, message: <><FormattedMessage id='message.formateError' /><br/><FormattedMessage id='message.allowedSpecialMarkings' /></> }]}>
                        <Input />
                    </Form.Item>
                    <Form.Item name='deliveryReportType' initialValue={'002'} label={<FormattedMessage id='delivery.reportType' />} rules={[{ required: true, message: intl.formatMessage({ id: 'message.inputRequired' }, { title: intl.formatMessage({ id: 'delivery.reportType' }) }) }]}>
                        <Select options={reportTypeOptions} allowClear={true} />
                    </Form.Item>
                    <Form.Item>
                        <div className='search-button'>
                            <Button className='search-close-btn' onClick={props.close}>
                                <FormattedMessage id='common.close' />
                            </Button>
                            <Popconfirm
                                title={deliveryOutputFormSubmitWarningMessage}
                                placement="bottom" // warning メッセージはたくさんので、bottom からポップアップはちょっといいと思います
                                onConfirm={handleSubmit}
                                okText={intl.formatMessage({ id: 'common.confirmed' })}
                                cancelText={intl.formatMessage({ id: 'common.cancel2' })} >
                                <Button className='search-search-btn'>
                                    <FormattedMessage id='delivery.output' />
                                </Button>
                            </Popconfirm>
                        </div>
                    </Form.Item>
                </Form>
            </Modal>
        </>
    )
}
