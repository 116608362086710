import dayjs, { Dayjs } from 'dayjs';
import { api } from '../api'
import { User } from '../authorization';

// アクセスURI
const uri = '/api/home';
// batch info アクセスURI
const batchInfoUri = '/api/batch/newest'
// batch limited アクセスURI
const batchLimitedUri = '/api/batch/limit'


// ホームページの一覧VO
interface HomeListRecordVo {

  // 注文データ	未読データ件数
  orderUnreadQuantity: number;

  // 出荷処理	出荷待データ件数
  waitForDeliveryQuantity: number;

  // 検収管理	新着データ件数
  acceptancesQuantity: number;

  // 所要計画管理	最新データ着信日
  focastDateQuantity: number;

  // 一般見積	新着データ件数 バイヤー
  estimateUnreadQuantity: number;

  // 一般見積	未クローズ案件数
  estimateUncloseQuantity: number;

  // 一般見積	未回答データ出件数
  estimateUnresponseQuantity: number;

  // 先方依頼見積	新着データ件数
  supplierEstimateUnreadQuantity: number;

  // 先方依頼見積	未回答データ出件数
  supplierEstimateUnresponseQuantity: number;

  // 先方依頼見積 未クローズ案件数
  supplierEstimateUncloseQuantity:number;

  // ドキュメント管理	未読件数
  docUnreadQuantity: number;

  // ドキュメント管理 未回答データ件数
  docUnresponseQuantity: number;

  // 金型マスター 新規金型データ
  moldMasterUnreadQuantity: number;

  // 有高確認 新規金型データ
  moldUnreadQuantity: number;

  // 有高確認 未回答データ
  moldUnresponseQuantity: number;

  // 有高確認 確認待データ件数
  moldWaitForComQuantity: number;

  // Acceptance 検収 最新バッチ実行情報
  newestAcceptanceBatch?: BatchRecordVo;

  // OrderDetail 注文 最新バッチ実行情報
  newestOrderDetailBatch?: BatchRecordVo;

  // Payment 支付 最新バッチ実行情報
  newestPaymentBatch?: BatchRecordDto;
}

// ホームページの一覧DTO
export interface HomeListRecordDto {

  // 注文データ	未読データ件数
  orderUnreadQuantity: number;

  // 出荷処理	出荷待データ件数
  waitForDeliveryQuantity: number;

  // 検収管理	新着データ件数
  acceptancesQuantity: number;

  // 所要計画管理	最新データ着信日
  focastDateQuantity: Dayjs;

  // 一般見積	新着データ件数 バイヤー
  estimateUnreadQuantity: number;

  // 一般見積	未クローズ案件数
  estimateUncloseQuantity: number;

  // 一般見積	未回答データ出件数
  estimateUnresponseQuantity: number;

  // 先方依頼見積	新着データ件数
  supplierEstimateUnreadQuantity: number;

  // 先方依頼見積	未回答データ出件数
  supplierEstimateUnresponseQuantity: number;

  // 先方依頼見積 未クローズ案件数
  supplierEstimateUncloseQuantity:number;

  // ドキュメント管理	未読件数
  docUnreadQuantity: number;

  // ドキュメント管理 未回答データ件数
  docUnresponseQuantity: number;

  // 金型マスター 新規金型データ
  moldMasterUnreadQuantity: number;

  // 有高確認 新規金型データ
  moldUnreadQuantity: number;

  // 有高確認 未回答データ
  moldUnresponseQuantity: number;

  // 有高確認 確認待データ件数
  moldWaitForComQuantity: number;

  // Acceptance 検収 最新バッチ実行情報
  newestAcceptanceBatch?: BatchRecordDto;

  // OrderDetail 注文 最新バッチ実行情報
  newestOrderDetailBatch?: BatchRecordDto;

  // Payment 支付 最新バッチ実行情報
  newestPaymentBatch?: BatchRecordDto;
}


interface BatchRecordVo {
  
  // id
  id: string;
  
  // バッチ名称
  batchName: string;

  //　バッチ実行状態
  batchStatus: string;

  //　バッチ読み取り数量
  batchInsertCount: number;

  //　バッチ更新数量
  batchUpdateCount: number;

  //　バッチスキップ数量
  batchSkipCount: number;

  //　バッチエラー数量
  batchErrorCount: number;

  //　バッチ実行時間
  batchRunTime?: number;
}


export interface BatchRecordDto {
  
  // id
  id: string;
  
  // バッチ名称
  batchName: string;

  //　バッチ実行状態
  batchStatus: string;

  //　バッチ読み取り数量
  batchInsertCount: number;

  //　バッチ更新数量
  batchUpdateCount: number;

  //　バッチスキップ数量
  batchSkipCount: number;

  //　バッチエラー数量
  batchErrorCount: number;

  //　バッチ実行時間
  batchRunTime?: Dayjs;
}


// VOからListRecordDtoを変換する
const vo2ListRecordDto = (vo: HomeListRecordVo, user: User | null): HomeListRecordDto => {

  return {
    ...vo,
    newestAcceptanceBatch: batchRecordVo2Dto(user, vo.newestAcceptanceBatch),
    newestOrderDetailBatch: batchRecordVo2Dto(user, vo?.newestOrderDetailBatch),
    focastDateQuantity: dayjs(vo.focastDateQuantity),
  } as HomeListRecordDto;

};


// batchVOからbatchDtoを変換する
const batchRecordVo2Dto = (user: User | null, vo?: BatchRecordVo, ): BatchRecordDto => {

  //ユーザー
  // const user = useUser();

  let localBatchRunTime
  try {
    let batchRunTime = dayjs(vo?.batchRunTime !== undefined ? vo.batchRunTime : 0)
    if(user?.serviceState === 'jp') {
      localBatchRunTime = batchRunTime.add(9, 'hour')
    } else if(user?.serviceState === 'cn') {
      localBatchRunTime = batchRunTime.add(8, 'hour')
    }
  } catch (error) {
    console.error(error)
    localBatchRunTime = dayjs(0)
  }
  return {
    ...vo,
    batchRunTime: localBatchRunTime,
  } as BatchRecordDto;

};

// 取得API
export const get = (user: User | null): Promise<HomeListRecordDto> => {

  return api.get(`${uri}`)
    .then((result: any) => {
      const data = result as HomeListRecordVo;
      return vo2ListRecordDto(data, user)
    });

};

export const getNewesBatchRecord = (batchName: string, user: User | null): Promise<BatchRecordDto> => {
  return api.get(`${batchInfoUri}?batchName=${batchName}`)
    .then( (result: BatchRecordVo) => {
      return batchRecordVo2Dto(user, result)
    })
}

export const getNewesLimited6BatchRecord = (batchName: string, user: User | null): Promise<BatchRecordDto[]> => {
  return api.get(`${batchLimitedUri}?batchName=${batchName}&dataLimit=7`)
    .then( (results: BatchRecordVo[]) => {
      return results.map((result) => batchRecordVo2Dto(user,result))
    })
}
