import React, { useState } from 'react';
import { Row, Col, Form, Button, Select, Modal, Input, InputNumber, Popconfirm } from 'antd';
import { EstimateRequestUpdateDto } from './api';
import { FormattedMessage, useIntl } from 'react-intl';
import { Attachment, DatePicker, } from '../common';
import { useFields } from '../fields';
import dayjs from 'dayjs';
import TextArea from 'antd/lib/input/TextArea';
import { unitPriceCheck } from '../util/priceCheck';
import { CommonFileUpload } from '../common/CommonFileUpload';
import { dateUtil, filterUtil, } from '../util'

export interface Props {

  visible: boolean;
  detail?: EstimateRequestUpdateDto;
  handleSubmit: (data: EstimateRequestUpdateDto) => Promise<any>;
  close: () => void;

}
// eslint-disable-next-line react-hooks/rules-of-hooks

export function EstimatsCreateDetail(props: Props) {

  const [form] = Form.useForm<EstimateRequestUpdateDto>();
  const [attachments, setAttachments] = useState<Attachment[]>([]);
  // useEffect(() => {
  //   if (!!props.detail) {
  //     props.detail.attachments = attachments;
  //     form.setFieldsValue(props.detail);

  //   }
  // });

  const fileUploadProps = {
    attachments: [],
    canUpload: true,
    maxCount:5,
    persist: (uuid: string) => {
      setAttachments([...attachments, { id: -1, uuid: uuid }]);
      form.setFieldsValue({attachments:attachments});
    },
    remove: (uuid: string) => {
      setAttachments(attachments.filter((attachment: Attachment) => attachment.uuid !== uuid));
      form.setFieldsValue({attachments:attachments});
    },
  };

  const upload = (formData: EstimateRequestUpdateDto) => {
    formData.attachments = attachments;
    props.handleSubmit(formData).then(() => {
      console.log('resetForm is on')
      form.resetFields()
      setAttachments([])
    });
  };
  //供应商
  const supplierOptions = useFields('fullSupplier');
  //発注部門
  const buyerOptions = useFields('buyerDepartment');
  //金额类型
  const disireCurrencyOptions  = useFields('currency').map(v => {
    return { label: v.label, value: v.label }
  })
  const estimatesTypeOptions = useFields('estimatesType');
  const intl = useIntl();

  const [abled, setAbled] = useState<boolean>(false);
  //品番
  const itemOptions = useFields('item');

  const changeAbled = (value: string) => {
    if (value === '001') {
      setAbled(true);
    } else {
      setAbled(false);
    }
  }

  return (
    <Modal title={<FormattedMessage id='estimate.newEstimate' />}
      visible={props.visible}   //设置默认隐藏
      onCancel={props.close}  // 点击取消按钮，对话框消失
      closable={false}
      footer={false}   //对话框的底部
      width={1200}
      destroyOnClose={true}
    >
      <Form
        form={form}
        name="advanced_search"
        className="ant-advanced-search-form"
        onFinish={upload}
        layout={'horizontal'}
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        colon={false}
        preserve={false}
      >
        <Row gutter={[16, 0]}>

          <Col span={12}>
            <Row>
              <Col span={20} >
                <Form.Item
                  label={<FormattedMessage id='estimate.buyerDepartment' />}
                  style={{ textAlign: 'left' }}
                  name={'buyerDepartmentCode'}
                  rules={[{ required: true, message: intl.formatMessage({ id: 'estimate.nullError' }, { name: intl.formatMessage({ id: 'estimate.buyerDepartment' }) }) }]}
                >
                  {<Select options={buyerOptions} />}
                </Form.Item>
              </Col>
              <Col span={4} />
              <Col span={20} >
                <Form.Item
                  label={<FormattedMessage id='estimate.purchasingManager' />}
                  style={{ textAlign: 'left' }}
                  name={'purchasingManagerName'}
                  rules={[{ required: true, message: intl.formatMessage({ id: 'estimate.nullError' }, { name: intl.formatMessage({ id: 'estimate.purchasingManager' }) }) },
                  { max: 6, message: intl.formatMessage({ id: 'estimate.maxError' }, { a: intl.formatMessage({ id: 'estimate.purchasingManager' }), b: '6' }) }]} >
                  {<Input />}
                </Form.Item>
              </Col>
              <Col span={4} />
              <Col span={20} >
                <Form.Item
                  label={<FormattedMessage id='estimate.estimatesType' />}
                  style={{ textAlign: 'left' }}
                  name={'estimatesType'}
                  rules={[{ required: true, message: intl.formatMessage({ id: 'estimate.nullError' }, { name: intl.formatMessage({ id: 'estimate.estimatesType' }) }) }]} >
                  {<Select options={estimatesTypeOptions} onChange={changeAbled} />}
                </Form.Item>
              </Col>
              <Col span={4} />
              <Col span={20} >
                <Form.Item
                  label={<FormattedMessage id='estimate.requestDate' />}
                  style={{ textAlign: 'left' }}
                  name={'requestDate'}
                  rules={[{ required: true, message: intl.formatMessage({ id: 'estimate.nullError' }, { name: intl.formatMessage({ id: 'estimate.requestDate' }) }) }, {
                    validator: (rule, value, callback) => {
                      const edate = form.getFieldValue('answerDeadline');
                      if (value === '' || value === undefined || value === null || edate === '' || edate === null || edate === undefined ||
                        (dayjs(edate).isAfter(dayjs(value), 'day'))) {
                        callback();
                      } else {
                        callback(intl.formatMessage({ id: 'estimate.answerDeadlineError' },
                          { a: intl.formatMessage({ id: 'estimate.answerDeadline' }), b: intl.formatMessage({ id: 'estimate.requestDate' }) }));
                      }
                    }
                  }]}
                >
                  {<DatePicker className={'number-input-overwrite'} disabledDate={dateUtil.isOverDateLimit}/>}
                </Form.Item>
              </Col>
              <Col span={4} />
              <Col span={20} >
                <Form.Item
                  label={<FormattedMessage id='estimate.answerDeadline' />}
                  style={{ textAlign: 'left' }}
                  name={'answerDeadline'}
                  rules={[{ required: true, message: intl.formatMessage({ id: 'estimate.nullError' }, { name: intl.formatMessage({ id: 'estimate.answerDeadline' }) }) }
                  ]}
                >
                  {<DatePicker className={'number-input-overwrite'} disabledDate={dateUtil.isOverDateLimit}/>}
                </Form.Item>
              </Col>
              <Col span={4} />
              <Col span={20} >
                <Form.Item
                  label={<FormattedMessage id='estimate.item' />}
                  style={{ textAlign: 'left' }}
                  name={'item'}
                >
                  {<Select showSearch allowClear={true} optionFilterProp="children" filterOption={(input:any, option:any) => option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0  } options={itemOptions} />}
                </Form.Item>
              </Col>
              <Col span={4} />
              <Col span={20} >
                <Form.Item
                  label={<FormattedMessage id='estimate.itemName' />}
                  style={{ textAlign: 'left' }}
                  name={'itemName'}
                  rules={[{ 
                    required: true, 
                    message: intl.formatMessage({ id: 'estimate.nullError' }, { name: intl.formatMessage({ id: 'estimate.itemName' }) }) 
                  }, { 
                    max: 100, 
                    message: intl.formatMessage({ id: 'estimate.maxError' }, { a: intl.formatMessage({ id: 'estimate.itemName' }), b: '100' }) 
                  }]}
                >
                  {<Input />}
                </Form.Item>
              </Col>
              <Col span={4} />
              <Col span={20} >
                <Form.Item
                  label={<FormattedMessage id='estimate.desireUnitPrice' />}
                  style={{ textAlign: 'left' }}
                  name={'desireUnitPrice'}
                  rules={[{
                    validator: (rule, value, callback) => {
                      if(value !== null) {
                        if(value <= 0 ) {  // 判断输入值是否小于等于零
                          let message = intl.formatMessage({ id: 'message.onlyPositiveNumber' })
                          if (message !== '') {
                            callback(message);
                          } else {
                            callback();
                          }
                        } else {
                          let message = unitPriceCheck(intl, value, form.getFieldsValue().desireCurrency);  // 根据选择的货币判断小数点位数是否超出
                          if (message !== '') {
                            callback(message);
                          } else {
                            callback();
                          }
                        }
                      } else {
                        if(form.getFieldsValue().desireCurrency === null || form.getFieldsValue().desireCurrency === undefined) {
                          callback()
                        } else {
                          callback(intl.formatMessage({ id: 'estimate.nullError' }, { name: intl.formatMessage({ id: 'estimate.desireUnitPrice' }) }))
                        }
                      }
                    }
                  }]}
                >
                  <InputNumber min={0} className={'number-input-overwrite'} />
                </Form.Item>
              </Col>
              <Col span={4} />
              <Col span={20} >
                <Form.Item
                  label={<FormattedMessage id='estimate.desireCurrency' />}
                  style={{ textAlign: 'left' }}
                  name={'desireCurrency'}
                  rules={[{
                    validator: (rule, value, callback) => {
                      if (!!form.getFieldsValue().desireUnitPrice && (value === null || value === undefined)) {
                        callback(intl.formatMessage({id:'estimate.nullError'},{ name:intl.formatMessage({id:'estimate.desireCurrency'})}));
                      }
                      callback();
                    }
                  }]}
                >
                  <Select options={disireCurrencyOptions} allowClear={true} />
                </Form.Item>
              </Col>
              <Col span={4} />
              <Col span={20} >
                <Form.Item
                  label={<FormattedMessage id='estimate.planningMonths' />}
                  style={{ textAlign: 'left' }}
                  name={'planningMonths'}
                  rules={[{
                    validator: (rule, value, callback) => {
                      const reg = /^(0|[1-9]\d*)(.\d{1,2})?$/;
                      if (value === null || value === undefined) {
                        callback();
                      }
                      if (reg.test(value)) {
                        callback();
                      } else {
                        callback(intl.formatMessage({ id: 'estimate.priceError' }, { a: intl.formatMessage({ id: 'estimate.planningMonths' }) }));
                      }
                    }
                  }]}
                >
                  <InputNumber min={0} className={'number-input-overwrite'} disabled={abled} />
                </Form.Item>
              </Col>
              <Col span={4} >
                <FormattedMessage id='estimate.monthUnit' />
              </Col>
            </Row>
          </Col>

          <Col span={12}>
            <Row>
              <Col span={20} >
                <Form.Item
                  label={<FormattedMessage id='estimate.planQuantity' />}
                  style={{ textAlign: 'left' }}
                  name={'planQuantity'}
                  rules={[{
                    validator: (rule, value, callback) => {
                      const reg = /^(0|[1-9]\d*)(.\d{1,3})?$/;
                      if (value === null || value === undefined) {
                        callback();
                      }
                      if (reg.test(value)) {
                        callback();
                      } else {
                        callback(intl.formatMessage({ id: 'estimate.priceError' }, { a: intl.formatMessage({ id: 'estimate.planQuantity' }) }));
                      }
                    }
                  }]}
                >
                  <InputNumber min={0} className={'number-input-overwrite'} disabled={abled} />
                </Form.Item>
              </Col>
              <Col span={4}><FormattedMessage id='estimate.quantityUnit' /></Col>
              <Col span={20} >
                <Form.Item
                  label={<FormattedMessage id='estimate.monthlyPlanOrderQuantity' />}
                  style={{ textAlign: 'left' }}
                  name={'monthlyPlanOrderQuantity'}
                  rules={[{
                    validator: (rule, value, callback) => {
                      const reg = /^(0|[1-9]\d*)(.\d{1,3})?$/;
                      if (value === null || value === undefined) {
                        callback();
                      }
                      if (reg.test(value)) {
                        callback();
                      } else {
                        callback(intl.formatMessage({ id: 'estimate.priceError' },{ a: intl.formatMessage({ id: 'estimate.monthlyPlanOrderQuantity' }) }));
                      }
                    }
                  }]}
                >
                  <InputNumber min={0} className={'number-input-overwrite'} disabled={abled} />
                </Form.Item>
              </Col>
              <Col span={4}> <FormattedMessage id='estimate.quantityUnit' /></Col>
              <Col span={20}>
                <Form.Item
                  label={<FormattedMessage id='estimate.deliveryLocation' />}
                  style={{ textAlign: 'left' }}
                  name={'deliveryLocation'}
                  rules={[{ max: 150, message: intl.formatMessage({ id: 'estimate.maxError' }, { a: intl.formatMessage({ id: 'estimate.deliveryLocation' }), b: '150' }) }]}
                  >
                  <TextArea autoSize={{ minRows: 3, maxRows: 3 }} />
                </Form.Item>
              </Col>
              <Col span={4} />
              <Col span={20}>
                <Form.Item
                  label={<FormattedMessage id='estimate.remarks' />}
                  style={{ textAlign: 'left', marginBottom: 20 }}
                  name={'remarks'}
                  rules={
                    [{ max: 100, message: intl.formatMessage({ id: 'estimate.maxError' }, { a: intl.formatMessage({ id: 'estimate.remarks' }), b: '100' }) }, {
                    }]}>
                  <TextArea autoSize={{ minRows: 3, maxRows: 3 }} />
                </Form.Item>

              </Col>
              <Col span={4} />

              <Col span={20}>
                <Form.Item
                  label={<FormattedMessage id='estimate.supplier' />}
                  style={{
                    textAlign: 'left'
                  }}
                  name={'supplierName'}
                  rules={[{ required: true, message: intl.formatMessage({ id: 'estimate.nullError' }, { name: intl.formatMessage({ id: 'estimate.supplier' }) }) }]}
                >
                  <Select
                    mode={'multiple'}
                    allowClear={true}
                    autoClearSearchValue={false}
                    loading={supplierOptions.length === 0}
                    options={supplierOptions} 
                    filterOption={filterUtil.selectFilter} />
                </Form.Item>
              </Col>
              <Col span={4} />

              <Col span={20}>
                <Form.Item
                  label={<FormattedMessage id='estimate.upload' />}
                   name = {'attachments'}>
                 <CommonFileUpload  {...fileUploadProps}/>
                </Form.Item>
              </Col>
              <Col span={4} />
            </Row>
          </Col>

        </Row>
        <Row>
          <Col span={24} style={{ textAlign: 'center' }}>
            <Button onClick={props.close} style={{ marginRight: 20 }}>
              <FormattedMessage id='common.close' />
            </Button>
            <Popconfirm
              title={<FormattedMessage id='message.addConfirm' />}
              onConfirm={() => {
                form.submit()
              }}
              okText={<FormattedMessage id='common.confirm' />}
              cancelText={<FormattedMessage id='common.cancel' />} >
              <Button htmlType="submit" >
                <FormattedMessage id='common.add' />
              </Button>
            </Popconfirm>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}
