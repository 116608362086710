import { DeleteOutlined, RollbackOutlined, SearchOutlined, DownloadOutlined } from '@ant-design/icons';
import { Button, Popconfirm, Modal } from 'antd';
import { Table } from 'antd';
import { ColumnsType, TablePaginationConfig } from 'antd/lib/table';
import { SorterResult } from 'antd/lib/table/interface';
import React, { useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl';
import { Link, useHistory } from 'react-router-dom';
import { useUser } from '../authorization';
import { constListScroll, constPageSizeOptions, Filter, SelectFilter, InputFilter, RangeFilter, DateRange } from '../common';
import { displayUtil, arrayUtil, filterUtil } from '../util';
import { DeliveryDto, DeliverySearchDto } from './api'
import { Field, useFields } from '../fields';

export interface Props {
    loading: boolean;
    total: number;
    data: DeliveryDto[];
    downloadEIAJ: (deliveryDto: DeliveryDto) => void;
    downloadDNnList: (deliveryDto: DeliveryDto) => void;
    downloadAllPDF: (deliverys: DeliveryDto[]) => void;
    searchDto: DeliverySearchDto;
    openSearchModal: () => void;
    handleResearch: (researchDto: DeliverySearchDto) => void;
    handleDelete: (id: number, version: number) => void;
    locationCdOptions: Field[];
    deliveryStatusOptions: Field[];
    reset: (rowCount: number) => void;

}

// 检索一览
export function DeliveryList(props: Props) {

    const { push } = useHistory();
    const user = useUser();
    const intl = useIntl();
    const supplierOptions = useFields('supplier');
    const fullSupplierOptions = useFields('fullSupplier')
    const locationNames = useFields('locationName');
    const [rowCount, setRowCount] = useState<number>(10);

    // ページング、フィルター、ソート順の処理
    const handleTableChange = (pagination: TablePaginationConfig,
        filters: Record<string, (string | number | boolean)[] | null>,
        sorter: SorterResult<DeliveryDto> | SorterResult<DeliveryDto>[],
        extra: any) => {

        const { locationName: preLocationName } = props.searchDto;
        const { locationName } = filters;
        const preLocationNames = preLocationName ? [preLocationName] : [];
        const curentLocationNames = locationName ? locationName as string[] : [];

        if (!arrayUtil.equals(curentLocationNames, preLocationNames)) {
            props.searchDto.locationName = curentLocationNames[0];
            props.handleResearch(props.searchDto);
        }

        const { deliveryStatus: preDeliveryStatus } = props.searchDto;
        const { deliveryStatus } = filters;
        const preDeliveryStatuses = preDeliveryStatus ? [preDeliveryStatus] : [];
        const curentDeliveryStatuses = deliveryStatus ? deliveryStatus as string[] : [];

        if (!arrayUtil.equals(curentDeliveryStatuses, preDeliveryStatuses)) {
            props.searchDto.deliveryStatus = curentDeliveryStatuses[0];
            props.handleResearch(props.searchDto);
        }

        // 再検索の条件を作成する
        const researchDto: DeliverySearchDto = { ...props.searchDto };
        let researchFlag = false;

        if (!!pagination.pageSize && props.searchDto.rowCount !== pagination.pageSize) {
            researchDto.page = 1;
            researchDto.rowCount = pagination.pageSize;
            researchFlag = true;
        }
        else if (props.searchDto.page !== pagination.current) {
            researchDto.page = pagination.current;
            researchFlag = true;
        }

        const fixSorter = sorter as SorterResult<DeliveryDto>;
        if (props.searchDto.sortField !== fixSorter.field
            || props.searchDto.sortOrder !== fixSorter.order) {
            researchDto.sortField = fixSorter.field as string | undefined;
            researchDto.sortOrder = fixSorter.order;
            researchFlag = true;
        }

        if (researchFlag) {
            props.handleResearch(researchDto);
        }
        
        if (!!pagination.pageSize && rowCount !== pagination.pageSize) {
            setRowCount(pagination.pageSize)
        }
    };

    const filterInput = (itemTitle: 'supplierName' | 'deliveryNo' | 'userName') =>
        (value?: string) => {
            if (props.searchDto[itemTitle] !== value) {
                const researchDto: DeliverySearchDto = { ...props.searchDto };
                researchDto[itemTitle] = value;
                researchDto.page = 1;
                props.handleResearch(researchDto);
            }
        };

    const filterSelect = (itemTitle: 'supplier') =>
        (value: string[]) => {
            if (props.searchDto[itemTitle] !== value) {
                const researchDto: DeliverySearchDto = { ...props.searchDto };
                researchDto[itemTitle] = value;
                researchDto.page = 1;
                props.handleResearch(researchDto);
            }
        };

    const filterRanger = (itemTitle: 'estimatedTimeArriva' | 'userModifiedDate') =>
        (value: DateRange) => {
            if (props.searchDto[itemTitle] !== value) {
                const researchDto: DeliverySearchDto = { ...props.searchDto };
                researchDto[itemTitle] = value;
                researchDto.page = 1;
                props.handleResearch(researchDto);
            }
        };
    const sortOrder = (itemTitle: string): 'ascend' | 'descend' | null | undefined => {

        if (itemTitle === props.searchDto.sortField) {
            return props.searchDto.sortOrder;
        }

        return null;
    };

    // タイトル
    const purchaseFlagTitle = <FormattedMessage id='delivery.deliveryStatus' />;
    const locationTitle = <FormattedMessage id='delivery.locationCd' />;
    const estimatedTimeArrivaTitle = <FormattedMessage id='delivery.estimatedTimeArriva' />;
    const deliveryNoTitle = <FormattedMessage id='delivery.deliveryNo' />;
    const supplierTitle = <FormattedMessage id='delivery.supplier' />;
    const supplierNameTitle = <FormattedMessage id='delivery.supplierName' />;
    const buyerTitle = <FormattedMessage id='delivery.buyer' />;
    const buyerNameTitle = <FormattedMessage id='delivery.buyerName' />;
    const userNameTitle = <FormattedMessage id='delivery.userName' />;
    const userModifiedDateTitle = <FormattedMessage id='delivery.dataCreationDate' />;
    const deliveryNoteTitle = <FormattedMessage id='common.download' />;

    // アクション
    const operationTitle = <FormattedMessage id='common.operation' />;
    //filter
    type filterType = Filter<DeliveryDto>;

    const DeliveryStatusFilter: filterType = {
        filters: filterUtil.field2Fileter(props.deliveryStatusOptions), filterMultiple: false,
        filteredValue: !props.searchDto.deliveryStatus ? [] : [props.searchDto.deliveryStatus],
        onFilter: (value: any, record: DeliveryDto) => record.deliveryStatus === value
    };

    const LocationNameFilter: filterType = {
        filters: filterUtil.field2Fileter(locationNames), filterMultiple: false,
        filteredValue: !props.searchDto.locationName ? [] : [props.searchDto.locationName],
        onFilter: (value: any, record: DeliveryDto) => record.locationName === value
    };

    const estimatedTimeFilter: filterType = {
        filterIcon: <SearchOutlined />,
        filterDropdown: <RangeFilter values={props.searchDto.estimatedTimeArriva} onChange={filterRanger('estimatedTimeArriva')} />,
        filteredValue: filterUtil.range2stringArray(props.searchDto.estimatedTimeArriva),
    };

    const userModifiedDateFilter: filterType = {
        filterIcon: <SearchOutlined />,
        filterDropdown: <RangeFilter values={props.searchDto.userModifiedDate} onChange={filterRanger('userModifiedDate')} />,
        filteredValue: filterUtil.range2stringArray(props.searchDto.userModifiedDate),
    };

    const suppliersFilter: filterType = {
        filterIcon: <SearchOutlined />,
        filterDropdown: <SelectFilter fields={fullSupplierOptions} values={props.searchDto.supplier} onChange={filterSelect('supplier')} />,
        filteredValue: props.searchDto.supplier,
    };


    const supplierNameFilter: filterType = {
        filterIcon: <SearchOutlined />,
        filterDropdown: <InputFilter value={props.searchDto.supplierName} onChange={filterInput('supplierName')} />,
        filteredValue: !props.searchDto.supplierName ? [] : [props.searchDto.supplierName],
    };

    const deliveryNoFilter: filterType = {
        filterIcon: <SearchOutlined />,
        filterDropdown: <InputFilter value={props.searchDto.deliveryNo} onChange={filterInput('deliveryNo')} />,
        filteredValue: !props.searchDto.deliveryNo ? [] : [props.searchDto.deliveryNo],
    };

    const userNameFilter: filterType = {
        filterIcon: <SearchOutlined />,
        filterDropdown: <InputFilter value={props.searchDto.userName} onChange={filterInput('userName')} />,
        filteredValue: !props.searchDto.userName ? [] : [props.searchDto.userName],
    };

    // const deliveryNoLink = (text: any, record: DeliveryDto, index: number) => {
    //     return <Link to={`/deliveries/${record.deliveryNo}`}><FormattedMessage id='delivery.eiaj' /></Link> && 
    //     <Link to={`/deliveries/${record.deliveryNo}`}><FormattedMessage id='delivery.dnList' /></Link>;
    // };
    const width240 = '240px';
    const width200 = '200px';
    const width160 = '160px';
    const width150 = '150px';
    const width140 = '140px';
    const width130 = '130px';
    const width120 = '120px';

    const array = [
        // 仕入状态
        {
            title: purchaseFlagTitle, dataIndex: 'deliveryStatus', width: width150,
            render: displayUtil.field(props.deliveryStatusOptions),
            sorter: true, sortOrder: sortOrder('deliveryStatus'),
            ...DeliveryStatusFilter,
            ellipsis: true
        },
        // 保管場所
        {
            title: locationTitle, dataIndex: 'locationName', width: width150,
            render: displayUtil.field(props.locationCdOptions),
            sorter: true, sortOrder: sortOrder('locationName'),
            ...LocationNameFilter,
            ellipsis: true
        },
        // 着荷日
        {
            title: estimatedTimeArrivaTitle, dataIndex: 'estimatedTimeArriva', render: displayUtil.date, width: width160,
            sorter: true,
            sortOrder: sortOrder('estimatedTimeArriva'),
            ...estimatedTimeFilter,
        },
        //  納品番号
        {
            title: deliveryNoTitle, dataIndex: 'deliveryNo', width: width240,
            sorter: true, sortOrder: sortOrder('deliveryNo'),
            ...deliveryNoFilter
        },
        // //  発注者コード
        // { title: buyerTitle, dataIndex: 'buyer', width: width80 },
        //  発注者名称
        { 
            title: buyerNameTitle, dataIndex: 'buyerName', width: width160, ellipsis: true 
        },
        //  受注者コード
        {
            title: supplierTitle, dataIndex: 'supplier', width: width150,
            sorter: true, sortOrder: sortOrder('supplier'),
            ...suppliersFilter
        },
        //  受注者名称
        {
            title: supplierNameTitle, dataIndex: 'supplierName', width: width200, ellipsis: true,
            sorter: true, sortOrder: sortOrder('supplierName'),
            ...supplierNameFilter
        },
        //  担当者
        { 
            title: userNameTitle, dataIndex: 'userName', width: width130, 
            sorter: true, sortOrder: sortOrder('userName'),
            ...userNameFilter 
        },
        //  作成日
        { 
            title: userModifiedDateTitle, dataIndex: 'userModifiedDate', render: displayUtil.date, width: width130, 
            sorter: true, sortOrder: sortOrder('userModifiedDate'),
            ...userModifiedDateFilter,
        },

    ];
    const columns: ColumnsType<DeliveryDto> = user?.isSupplier ? array : array.filter(item => item.dataIndex !== 'buyer' && item.dataIndex !== 'buyerName');
    // 納品書タウンロード
    const pdfBttons = (text: any, record: any, index?: number) => {

        return (<>
            {
                record.deliveryReportType !== '002' 
                ? 
                <Button style={{height: '30px', lineHeight: '100%'}} type='link' onClick={() => props.downloadEIAJ(record)} >
                    <FormattedMessage id='delivery.eiaj' />
                </Button> 
                : 
                ''
            }
            {
                record.deliveryReportType !== '001' 
                ? 
                <Button style={{height: '30px', lineHeight: '100%'}} type='link' onClick={() => props.downloadDNnList(record)}>
                    <FormattedMessage id='delivery.dnList' />
                </Button> 
                : 
                ''
            }
        </>)
    }

    const delBtn = (id: number, ver: number, deliveryStatus: string | undefined) => {
        const deleteDisabled = deliveryStatus === '002' || deliveryStatus === '003' || deliveryStatus === '' || deliveryStatus === null || deliveryStatus === undefined
        return (
            <>
                {!!user?.isSupplier &&
                    <Popconfirm placement="left" title={<FormattedMessage id='message.cancelConfirm2' />}
                        onConfirm={(event) => props.handleDelete(id, ver)}
                        okText={<FormattedMessage id='common.confirm' />}
                        cancelText={<FormattedMessage id='common.cancel' />} 
                        disabled={deleteDisabled} 
                    >
                        <Button style={{height: '30px', lineHeight: '100%'}} type='link' disabled={deleteDisabled} >
                            <FormattedMessage id='common.cancel2' />
                        </Button>
                    </Popconfirm>
                }
            </>
        )
    };
    columns.push({ title: deliveryNoteTitle, dataIndex: '', width: width130, render: pdfBttons })
    if (!!user?.isSupplier)
        columns.push({
            title: operationTitle, dataIndex: 'id', width: width140,
            render: (text: any, record: DeliveryDto, index: number) => delBtn(record.id, record.version, record.deliveryStatus)
        })

    const paginationProps: TablePaginationConfig = {
        current: props.searchDto.page,
        total: props.total,
        defaultPageSize: props.searchDto.rowCount,
        position: ['bottomLeft'],
        showSizeChanger: true,
        pageSizeOptions: constPageSizeOptions,
    }
    
    const [deliverys, setDeliverys] = useState<DeliveryDto[]>([]);
    const [keys, setKeys] = useState<React.Key[]>([]);

    const noSelectError = () => {
        Modal.error({
            title: intl.formatMessage({ id: 'delivery.selectErrorTitle' }),
            content: intl.formatMessage({ id: 'delivery.noSelectBody' }),
            okText: intl.formatMessage({ id: 'common.close' })
        });
    }

    const selectTooMuchError = () => {
        Modal.error({
            title: intl.formatMessage({ id: 'delivery.selectErrorTitle' }),
            content: intl.formatMessage({ id: 'delivery.selectTooManyBody' }),
            okText: intl.formatMessage({ id: 'common.close' })
        });
    }

    const downloadDeliveryPDF = () => {
        if (deliverys.length === 0) {
            noSelectError()
        } else if (deliverys.length > 20) {
            selectTooMuchError()
        } else {
            props.downloadAllPDF(deliverys)
        }
        setDeliverys([])
        setKeys([])
    }


    const rowSelection = {
        selectedRowKeys: keys,
        onChange: (selectedRowKeys: React.Key[], selectedRows: DeliveryDto[]) => {
            setDeliverys(selectedRows);
            setKeys(selectedRowKeys)
        }
    };

    const title = () => (
        <>
            <FormattedMessage id='delivery.list' />
            <Button onClick={props.openSearchModal} style={{ marginLeft: 10, marginRight: 10 }} >
                <SearchOutlined />
                <FormattedMessage id='common.conditions' />
            </Button>
            <Button onClick={downloadDeliveryPDF} style={{ marginLeft: 10, marginRight: 10 }} >
                <DownloadOutlined />
                <FormattedMessage id='delivery.downloadSelected' />
            </Button>
            <Button className='reset-btn' onClick={() => { props.reset(rowCount); }}>
                <RollbackOutlined />
                <FormattedMessage id='common.reset' />
            </Button>
        </>)

    return (
        <>
            <Table<DeliveryDto>
                className="list-table"
                pagination={paginationProps}
                columns={columns}
                dataSource={props.data}
                loading={props.loading}
                rowClassName={displayUtil.rowClassName}
                rowKey={'id'}
                tableLayout={'fixed'}
                title={title}
                onChange={handleTableChange}
                scroll={constListScroll}
                rowSelection={rowSelection}
            />
        </>
    )
}
