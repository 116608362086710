import React from 'react'

import { InventoryHoc } from './InventoryHoc';

import { InventoryList } from './InventoryList';
import { InventorySearch } from './InventorySearch';
import { InventoryDetail } from './InventoryDetail';
import { SupplierInventoryDetail } from './SupplierInventoryDetail';
import { InventoryEntry } from './InventoryEntry';
import { InventoryEntryConfrim } from './InventoryEntryConfrim';

export function Inventory() {

    const inventory = InventoryHoc(InventoryList, InventorySearch, InventoryDetail, SupplierInventoryDetail, InventoryEntry, InventoryEntryConfrim);

    return (
        <>
            {inventory}
        </>
    )
}
