import React, { ChangeEvent, useEffect, useState } from 'react';
import { FormattedMessage, useIntl, } from 'react-intl';
import { Drawer, Form, Input, Button, Row, Col, Card, Popconfirm } from 'antd';
import { DocDetailSupplierDto } from './api';
import './index.css';
import { Field, useFields } from '../fields';
import { displayUtil } from '../util';
import { CommonFileUpload } from '../common/CommonFileUpload';
import { Attachment } from '../common';
const { TextArea } = Input;

export interface Props {
  formDto?: DocDetailSupplierDto;
  visible: boolean;
  disabled: boolean;
  buyerOptions: Field[];
  handleSubmit: (data: DocDetailSupplierDto) => void;
  close: () => void;
}

export function DocReplyDetail(props: Props) {
  const [form] = Form.useForm<DocDetailSupplierDto>();
  const buyerOption = useFields('buyer');
  const documentCategoryOption = useFields('category');
  const replyFlagOptions = useFields('replyFlag');
  const acceptFlagOptions = useFields('acceptFlag');
  const intl = useIntl();
  const [detailAttachments, setDetailAttachments] = useState<Attachment[]>([]);
  const [isEdited, setIsEdited] = useState<boolean>(false);

  useEffect(() => {
    if (!!props.formDto) {
      form.setFieldsValue(props.formDto)
      setDetailAttachments(props.formDto.detailAttachments);
    }
  }, [form, props.formDto]);

  const title = <FormattedMessage tagName='h4' id='docInfo.detail' />
  const suppliertitle = <FormattedMessage id='docInfo.title' />;
  const sendUsername = <FormattedMessage id='docInfo.userName' />;
  const replayUsernametitle = <FormattedMessage id='docInfo.replyUserName' />;
  const replayTimetitle = <FormattedMessage id='docInfo.replyTime' />;
  const reply = <FormattedMessage id='docInfo.reply' />;
  const fileList = <FormattedMessage id='docInfo.docFiles' />;
  const buyer = <FormattedMessage id='docInfo.buyer' />;
  const sendContent = <FormattedMessage id='docInfo.sendInfo' />;
  const replyContent = <FormattedMessage id='docInfo.replyInfo' />;
  const sendMessage = <FormattedMessage id='docInfo.sendContent' />;
  const replyMessage = <FormattedMessage id='docInfo.replyContent' />;
  const category = <FormattedMessage id='docInfo.documentCategory' />;
  const confirm = <FormattedMessage id='common.confirm' />;
  const replyCancel = <FormattedMessage id='common.cancel' />;
  const replyConfirm = <FormattedMessage id='message.replyConfirm' />;
  const replyFlagTitle = <FormattedMessage id='docInfo.replyFlag' />;
  const docSendTime = <FormattedMessage id='docInfo.sendTime' />;
  const docReplyTime = <FormattedMessage id='docInfo.replyTime' />;
  const acceptFlagTitle = <FormattedMessage id='docInfo.acceptFlag' />;

  const handleSubmit = (form: DocDetailSupplierDto) => {

    form.detailAttachments = detailAttachments;

    props.handleSubmit(form);
  }

  const handleEditReplyMessage = (record: ChangeEvent<HTMLTextAreaElement>) => {
    if (record?.target?.value) {
      setIsEdited(true)
    } else {
      setIsEdited(false)
    }
  }

  const sendTimeVisible = props.formDto?.replyTime === null && props.formDto?.replyFlag !== '002' ? 'none' : 'block'
  const replyFlagText = displayUtil.field(replyFlagOptions)(props.formDto?.replyFlag)
  const acceptFlagText = displayUtil.field(acceptFlagOptions)(props.formDto?.acceptFlag)
  const replyTimeText = displayUtil.date(props.formDto?.replyTime)

  const replyDisabled = props.disabled || ( !form.getFieldValue('replyMessage') && !isEdited)

  return (
    <Drawer
      title={title}
      width={1280}
      footer={null}
      visible={props.visible}
      destroyOnClose={true}
      onClose={props.close}
      bodyStyle={{padding: 5}}
      style={{overflowY:'hidden'}}>

      <Form
        className="form"
        form={form}
        preserve={false}
        onFinish={handleSubmit}
        layout={'vertical'} 
           
        colon={false}
        requiredMark={true}
        size={'large'}>

        <Row justify="start">
          <Col span={12}>
            <Card title={sendContent} bordered={false}
             headStyle={{ 
               paddingTop: 5, 
               minHeight: '0px' 
              }} 
              // bodyStyle={{ marginTop: 20 }}
             >
              <Row gutter={[16, 0]} align="top" justify="start" style={{
                // height: '62vh', 
                alignContent: 'space-between', 
                overflowY: 'hidden'
                }}>
                <Col span={5} style={{ textAlign: 'right', fontWeight: 'bold', padding: '3px 8px' }}>
                  {acceptFlagTitle}
                </Col>
                <Col span={19}>
                  <Input
                    bordered={false}
                    disabled
                    style={{ color: 'rgba(0, 0, 0, 0.85)', textDecoration: 'underline', cursor: 'text', padding: '0' }}
                    value={acceptFlagText}
                    onChange={e => e.preventDefault}
                  />
                </Col>

                <Col span={5} style={{ textAlign: 'right', fontWeight: 'bold', padding: '3px 8px' }}>
                  {buyer}
                </Col>
                <Col span={19} >
                  <Input
                    bordered={false}
                    disabled
                    style={{ color: 'rgba(0, 0, 0, 0.85)', textDecoration: 'underline', cursor: 'text', padding: '0' }}
                    value={displayUtil.field(buyerOption)(props.formDto?.buyer)}
                    onChange={e => e.preventDefault}
                  />
                </Col>

                <Col span={5} style={{ textAlign: 'right', fontWeight: 'bold', padding: '3px 8px' }}>
                  {category}
                </Col>
                <Col span={19} >
                  <Input
                    bordered={false}
                    disabled
                    style={{ color: 'rgba(0, 0, 0, 0.85)', textDecoration: 'underline', cursor: 'text', padding: '0' }}
                    value={displayUtil.field(documentCategoryOption)(props.formDto?.documentCategory)}
                    onChange={e => e.preventDefault}
                  />
                </Col>
                <Col span={5} style={{ textAlign: 'right', fontWeight: 'bold', padding: '3px 8px' }}>
                  {suppliertitle}
                </Col>
                <Col span={19} >
                  <TextArea
                    autoSize
                    bordered={false}
                    disabled
                    style={{ color: 'rgba(0, 0, 0, 0.85)', textDecoration: 'underline', cursor: 'text', padding: '0' }}
                    value={props.formDto?.title}
                    onChange={e => e.preventDefault}
                  />
                </Col>
                {/* 授信者 */}
                <Col span={5} style={{ textAlign: 'right', fontWeight: 'bold', padding: '3px 8px' }}>
                  {sendUsername}
                </Col>
                <Col span={19} >
                  <Input
                    bordered={false}
                    disabled
                    style={{ color: 'rgba(0, 0, 0, 0.85)', textDecoration: 'underline', cursor: 'text', padding: '0' }}
                    value={props.formDto?.userName}
                    onChange={e => e.preventDefault}
                  />
                </Col>

                {/* 授信时间 */}
                <Col span={5} style={{ textAlign: 'right', fontWeight: 'bold', padding: '3px 8px' }}>
                  {docSendTime}
                </Col>
                <Col span={19} >
                  <Input
                    bordered={false}
                    disabled
                    style={{ color: 'rgba(0, 0, 0, 0.85)', textDecoration: 'underline', cursor: 'text', padding: '0' }}
                    value={displayUtil.date(props.formDto?.sendTime)}
                    onChange={e => e.preventDefault}
                  />
                </Col>
                <Col span={5} style={{ textAlign: 'right', fontWeight: 'bold', padding: '3px 8px' }}>
                  {sendMessage}
                </Col>
                <Col span={19} >
                  <TextArea
                    // bordered={false} 
                    disabled
                    style={{ color: 'rgba(0, 0, 0, 0.85)', textDecoration: 'underline', cursor: 'text', padding: '0px 10px' }}
                    value={props.formDto?.message}
                    onChange={e => e.preventDefault}
                    autoSize={{ minRows: 12, maxRows: 12 }}
                  />
                </Col>
                <Col span={5} style={{ textAlign: 'right', fontWeight: 'bold', padding: '3px 8px' }}>
                  {fileList}
                </Col>
                <Col span={19}>
                  <CommonFileUpload
                    attachments={!!props.formDto ? props.formDto.attachments : []}
                    canUpload={false}
                    persist={(uuid: string) => {
                      // 処理なし
                    }}
                    remove={(uuid: string) => {
                      // 処理なし
                    }}
                  />
                </Col>
              </Row>
            </Card>
          </Col>
          <Col span={12} style={{ borderLeft: '#f0f0f0 1px solid', paddingLeft: -16 }}>
            <Card title={replyContent} bordered={false} 
              headStyle={{ paddingTop: 5, minHeight: '0px' }} 
              // bodyStyle={{ marginTop: 20 }}
            >
              <Row gutter={[16, 0]} align="top" justify="start" style={{
                // height: '62vh', 
                alignContent: 'space-between', 
                overflowY: 'auto'
                }}>
                <Col span={5} style={{ textAlign: 'right', fontWeight: 'bold', padding: '3px 8px' }}>
                  {replyFlagTitle}
                </Col>
                <Col span={19}>
                  <Input
                    bordered={false}
                    disabled
                    style={{ color: 'rgba(0, 0, 0, 0.85)', textDecoration: 'underline', cursor: 'text', padding: '0' }}
                    value={replyFlagText}
                    onChange={e => e.preventDefault}
                  />
                </Col>
                <Col span={5} style={{ textAlign: 'right', fontWeight: 'bold', padding: '3px 8px' }}>
                  {replayUsernametitle}
                </Col>
                <Col span={19} >
                  <Input
                    bordered={false}
                    disabled
                    style={{ color: 'rgba(0, 0, 0, 0.85)', textDecoration: 'underline', cursor: 'text', padding: '0' }}
                    value={props.formDto?.replyUserName}
                    onChange={e => e.preventDefault}
                  />
                </Col>
                {/* <Col span={5} style={{ textAlign: 'right', fontWeight: 'bold', padding: '3px 8px' }}>
                  {replayTimetitle}
                </Col>
                <Col span={19} >
                  <Input
                    bordered={false}
                    disabled
                    style={{ color: 'rgba(0, 0, 0, 0.85)', textDecoration: 'underline', cursor: 'text', padding: '0' }}
                    value={displayUtil.date(props.formDto?.replyTime)}
                    onChange={e => e.preventDefault}
                  />
                </Col> */}

                <Col span={5} style={{ textAlign: 'right', fontWeight: 'bold', padding: '3px 8px', display: sendTimeVisible }}>
                  {docReplyTime}
                </Col>
                <Col span={19} style={{ display: sendTimeVisible }}>
                  <Input
                    bordered={false}
                    disabled
                    style={{ color: 'rgba(0, 0, 0, 0.85)', textDecoration: 'underline', cursor: 'text', padding: '0' }}
                    value={replyTimeText}
                    onChange={e => e.preventDefault}
                  />
                </Col>


                <Col span={5} style={{ textAlign: 'right', fontWeight: 'bold' }}>
                  {replyMessage}
                </Col>
                <Col span={19}>
                  <Form.Item name='replyMessage' style={{ marginBottom: '0px' }}
                    rules={[{ max: 200, message: intl.formatMessage({ id: 'estimate.maxError' }, { a: intl.formatMessage({ id: 'docInfo.replyContent' }), b: '200' }) }]}>
                    <TextArea 
                      autoSize={{ minRows: 13, maxRows: 13 }} 
                      disabled={props.disabled}
                      maxLength={200}
                      showCount
                      onChange={handleEditReplyMessage}
                    />
                  </Form.Item>
                </Col>
                <Col span={5} style={{ textAlign: 'right', fontWeight: 'bold', padding: '3px 8px' }}>
                  {fileList}
                </Col>
                <Col span={19}>
                  <div className='reSize'>
                    <Form.Item name='attachments' noStyle>
                      <CommonFileUpload
                        attachments={!!props.formDto ? props.formDto.detailAttachments : []}
                        canUpload={!props.disabled}
                        persist={(uuid: string) => {
                          setDetailAttachments([...detailAttachments, { id: -1, uuid: uuid }]);
                        }}
                        remove={(uuid: string) => {
                          setDetailAttachments(detailAttachments.filter((attachment: Attachment) => attachment.uuid !== uuid))
                        }}
                      />
                    </Form.Item>
                  </div>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
        <Row justify={"center"}>
          <Col>
            {!props.disabled && <Popconfirm
              title={replyConfirm}
              onConfirm={() => {
                form.submit()
              }}
              okText={confirm}
              cancelText={replyCancel}
              disabled={replyDisabled}
            >
              <Button style={{ marginTop: 40 }} disabled={replyDisabled} >{reply}</Button>
            </Popconfirm>}
          </Col>
        </Row>
      </Form>
    </Drawer >
  );
}




























