import React from 'react';

import { Upload, } from 'antd';
import { UploadFile, } from "antd/es/upload/interface";
import './index.css';
import { Attachment } from '.';

export interface Props {
  attachments: Attachment[];
  canUpload: boolean;
  maxCount?: number;
}

interface FixUploadFile extends UploadFile {
  uuid?: string;
}

export function CommonFileDisplay(props: Props) {

  const fileVo2UploadFile = (attachment: Attachment): FixUploadFile => {

    let name = !!attachment.name ? attachment.name : '';
    const type = !!attachment.type ? attachment.type : '';
    const size = !!attachment.size ? attachment.size : 0;
    if (name.length > 0 && type.length > 0) {
      name = `${name}.${type}`
    }

    return {
      ...attachment,
      name: name,
      type: type,
      size: size,
      uid: attachment.uuid,
      uuid: attachment.uuid,
      url: `/api/file/download/${attachment.uuid}`,
      status: 'done',
    };
  }

  return (
    <>

      <Upload
        fileList={props.attachments.map(fileVo2UploadFile)}
        maxCount={props.maxCount}
        showUploadList={{ showRemoveIcon: props.canUpload, showDownloadIcon: props.canUpload }}>
      </Upload >
    </>
  );
}

