import { api } from '../api';

interface UserVo {
  companyType?: string;
  userName?: string;
  companyid?: string;
  auth: boolean;
  serviceState?: string;
}

export const login = (): Promise<UserVo> => {

  return api.get('/api/user');
}
