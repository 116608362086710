import React, { useEffect, useRef, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'

import { Button, Col, Drawer, Row, Table, Popconfirm, message, FormInstance, Modal } from 'antd';

import { FileExcelOutlined } from '@ant-design/icons';
import { ColumnsType } from 'antd/lib/table';

import { useFields } from '../fields';
import { displayUtil } from '../util'
import { InventoryDto, InventoryDetailDto } from './api'
import { Detail } from '../common';
import { Rule } from 'antd/lib/form';
import { EditableCell, EditableRow } from './InventoryEditableTable';

export const EditableContext = React.createContext<FormInstance<InventoryDetailDto> | null>(null);

export interface Props {
    visible: boolean;
    detail?: InventoryDto;
    close: () => void;
    refresh: () => void;
    download: (id?: number) => void;
    reply: (detailDto: InventoryDetailDto[]) => Promise<void>;
}

// supplier 的 有高確認情報 页
export function SupplierInventoryDetail(props: Props) {

    useEffect(() => {
        if (props.detail?.details) {
            setDataSource({edited: false, data: props.detail.details})
        }
    }, [props.detail?.details]);

    const supplierOptions = useFields('supplier');
    const statusOptions = useFields('moldStatus');

    const intl = useIntl();

    const [messageApi, contextHolder] = message.useMessage();

    const [dataSource, setDataSource] = useState<{edited: boolean, data: InventoryDetailDto[] | undefined}>({edited: false, data: props.detail?.details || []})

    const checkInput = (record: InventoryDetailDto): boolean => {
        if (record.actualMoldsNum === null || record.actualMoldsNum === undefined || `${record.actualMoldsNum}` === '') {
            return false
        }
        try {
            const currectActualMoldsNum = parseInt(`${record.actualMoldsNum}`, 10)

            if (currectActualMoldsNum < 0) { // 不能为负数
                return false            
            }

            if (currectActualMoldsNum > 999999999) {
                return false
            }

            if (currectActualMoldsNum === 0 && (record.remarks === null || record.remarks === undefined || record.remarks === '') ) {
                return false
            }
        } catch (error) {
            return false
        }
        return true
    }

    const handleSave = (row: InventoryDetailDto) => {
        const newDatas = dataSource.data ? [...dataSource.data] : [];
        const index = newDatas.findIndex(InventoryDetail => row.id === InventoryDetail.id);
        const item = newDatas[index];
        newDatas.splice(index, 1, {
          ...item,
          ...row,
        });
        setDataSource({edited: true, data: newDatas});
    };

    const clearAllCache = () => {
        recordDataRef.current = undefined
        editingCellRowRef.current = undefined
        editingCellColRef.current = undefined
        nextEditingCellRowRef.current = undefined
        nextEditingCellColRef.current = undefined
        nextRecordDataRef.current = undefined
        validationFailed.current = false
    }

    const handleReply = () => {
        if (dataSource?.data?.every(checkInput)) {
            const currectDataSource = dataSource.data.map(data => { return {...data, actualMoldsNum: parseInt(`${data.actualMoldsNum}`, 10)} as InventoryDetailDto })
            props.reply(currectDataSource)
            clearAllCache()
            props.refresh()
        } else {
            popNotAllowReplyError()
        }
    }

    const title = (
        <>
            <FormattedMessage id='inventory.detail' />
            <Button style={{ marginLeft: '20px'}} onClick={() => props.download(props.detail?.id)}>
                <FileExcelOutlined />
                <FormattedMessage id='common.download' />
            </Button>
        </>
    )

    const inventoryCols: [JSX.Element, any][] = [
        // 确认状态
        [<FormattedMessage id='inventory.status' />, displayUtil.field(statusOptions)(props.detail?.status)],
        // 有高確認依頼番号
        [<FormattedMessage id='inventory.inventoryRequestNo' />, props.detail?.inventoryRequestNo],
        // 有高確認依頼日
        [<FormattedMessage id='inventory.requestDate' />, displayUtil.date(props.detail?.requestDate)],
        // 回答希望日
        [<FormattedMessage id='inventory.answerDeadline' />, displayUtil.date(props.detail?.answerDeadline)],
        // 一次預け先コード
        [<FormattedMessage id='model.primaryLender' />, props.detail?.primaryLender],
        // 一次預け先名称
        [<FormattedMessage id='model.primaryLenderName' />, displayUtil.field(supplierOptions)(props.detail?.primaryLender)],
        
    ];
    const modifiedCols: [JSX.Element, any][] = [
        // 返信担当
        [<FormattedMessage id='inventory.responseUser' />, props.detail?.userName2],
        // 返信日時
        [<FormattedMessage id='inventory.responseTime' />, displayUtil.date(props.detail?.responseTime)]
    ];
    const confirmationCols: [JSX.Element, any][] = [
        // 確認担当
        [<FormattedMessage id='inventory.confirmationUser' />, props.detail?.userName3],
        // 確認時間
        [<FormattedMessage id='inventory.confirmationTime' />, displayUtil.date(props.detail?.confirmationTime)]
    ];

    const actualMoldsNumEditableCellInputRules = (form: FormInstance<InventoryDetailDto>): Rule[] | undefined => {
        return [
            {
                validator: (rule: any, values: any, callback: any) => {
                    if (form?.getFieldsValue()) {
                        const actualMoldsNum = form?.getFieldsValue()?.actualMoldsNum
                        const remarks = form?.getFieldsValue()?.remarks
                        if (actualMoldsNum === null || actualMoldsNum === undefined || `${actualMoldsNum}` === '') {
                            // 不能为空
                            callback(intl.formatMessage({id: 'estimate.nullError'}, {name: actualMoldsNumTitle}))
                            
                        }
                        try {
                            const currectActualMoldsNum = parseInt(`${actualMoldsNum}`, 10)
    
                            if (currectActualMoldsNum < 0) {
                                // 不能为负数
                                callback(intl.formatMessage({id: 'message.noNegativeNumbers'}, {i: intl.formatMessage({id: 'inventory.actualMoldsNum'})}))
                            }
        
                            if (currectActualMoldsNum > 999999999) {
                                // 数值过大
                                callback(intl.formatMessage({id: 'message.numberTooLarge'}))
                            }
        
                            if (currectActualMoldsNum === 0 && (remarks === null || remarks === undefined || remarks === '') ) {
                                // 实面数为 0 时必须填写备注
                                callback(intl.formatMessage({id: 'inventory.recordTheReason'}, {a: intl.formatMessage({id: 'inventory.actualMoldsNum'}), b: intl.formatMessage({id: 'inventory.remarks'})}))
                            }
                        } catch (error) {
                            // 请输入数正数
                            callback(intl.formatMessage({id: 'message.onlyPositiveNumber'}))
                        }
                    }
                    callback()
                }
            }
        ]
    }

    const remarksEditableCellInputRules = (form: FormInstance<InventoryDetailDto>): Rule[] | undefined => {
        return [
            {
                validator: (rule: any, values: any, callback: any) => {
                    const actualMoldsNum = form?.getFieldsValue()?.actualMoldsNum
                    const remarks = form?.getFieldsValue()?.remarks

                    const currectActualMoldsNum = parseInt(`${actualMoldsNum}`, 10)
                    if (currectActualMoldsNum === 0 && (remarks === null || remarks === undefined || remarks === '') ) {
                        // 实面数为 0 时必须填写备注
                        callback(intl.formatMessage({id: 'inventory.recordTheReason'}, {a: intl.formatMessage({id: 'inventory.actualMoldsNum'}), b: intl.formatMessage({id: 'inventory.remarks'})}))
                    }
                    callback()
                }
            }
        ]
    }

    // 資産番号
    const assetNoTitle = intl.formatMessage({id: 'model.assetNo'})
    // 補助番号
    const auxiliaryNoTitle = intl.formatMessage({id: 'model.auxiliaryNo'})
    // 金型品番
    const moldNoTitle = intl.formatMessage({id: 'model.moldNo'}) 
    // 最終預け先名称
    const finalLenderNameTitle = intl.formatMessage({id: 'model.finalLenderName'})
    // 面数
    const moldsNumTitle = intl.formatMessage({id: 'model.moldsNum'}) 
    // 実面数
    const actualMoldsNumTitle = intl.formatMessage({id: 'inventory.actualMoldsNum'}) 
    // 特記事項
    const remarksTitle = intl.formatMessage({id: 'inventory.remarks'})

    const width90 = '90px'
    const width100 = '100px';
    const width160 = '160px';
    const width200 = '200px';
    const width260 = '260px';
    
    // inventory detail cols ColumnsTypeInventoryDetail
    const detailColumns = [
        // 資産番号
        { title: assetNoTitle,width: width160,dataIndex: 'assetNo'},
        // 補助番号
        { title: auxiliaryNoTitle,width: width100,dataIndex: 'auxiliaryNo'},
        // 金型品番
        {title: moldNoTitle,width: width260,dataIndex: 'moldNo'},
        // 最終預け先名称
        {title: finalLenderNameTitle,width: width200,dataIndex: 'finalLenderName'},
        // 面数
        {title: moldsNumTitle,width: width90,dataIndex: 'moldsNum'},
        // 実面数
        { title: actualMoldsNumTitle, width: width200, dataIndex: 'actualMoldsNum', editable: true, required: false, editableCellInputRules: actualMoldsNumEditableCellInputRules, inputType: 'number' },
        // 特記項事
        { title: remarksTitle, width: width260, dataIndex: 'remarks', editable: true, required: true, editableCellInputRules: remarksEditableCellInputRules, inputType: 'input' },
    ]

    const dataIndexs = detailColumns.map(col => col.dataIndex)


    const recordDataRef = useRef<InventoryDetailDto | undefined>()
    const editingCellRowRef = useRef<React.Key | undefined>()
    const editingCellColRef = useRef<React.Key | undefined>()

    const nextEditingCellRowRef = useRef<React.Key | undefined>()
    const nextEditingCellColRef = useRef<React.Key | undefined>()
    const nextRecordDataRef = useRef<InventoryDetailDto | undefined>()

    const validationFailed = useRef<boolean>(false)

    const [refresh, setRefresh] = useState<boolean>(false)
    const handleRefresh = () => {
        if (nextEditingCellRowRef.current && nextEditingCellColRef.current) {
            handleClickInput()
        } else {
            handleClickOutside()
        }
    }
    const handleClickInput = () => {
        editingCellRowRef.current = nextEditingCellRowRef.current
        editingCellColRef.current = nextEditingCellColRef.current
        recordDataRef.current = nextRecordDataRef.current

        if (recordDataRef.current) {
            handleSave(recordDataRef.current)
        } else {
            setRefresh(!refresh)
        }
    }

    const handleClickOutside = () => {
        editingCellRowRef.current = undefined
        editingCellColRef.current = undefined
        recordDataRef.current = nextRecordDataRef.current
        if (recordDataRef.current) {
            handleSave(recordDataRef.current)
        } else {
            setRefresh(!refresh)
        }
    }

    const mergedDetailColumnsColumns: ColumnsType<InventoryDetailDto> = detailColumns.map(col => {
        if (!col.editable) {
            return col;
        }

        return {
            ...col,
            onCell: (record: InventoryDetailDto) => ({
                record: record,
                editable: col.editable,
                dataIndex: col.dataIndex,
                dataIndexs,
                title: col.title,
                editableCellInputRules: col.editableCellInputRules || [],
                inputType: col.inputType,
                handleSave,

                recordDataRef,
                editingCellRowRef,
                editingCellColRef,
            
                nextRecordDataRef,
                nextEditingCellRowRef,
                nextEditingCellColRef,

                validationFailed,
            
                handleRefresh,
            }),
        };
    });

    let components = {
        body: {
            row: EditableRow,
            cell: EditableCell,
        },
    };
    let cueectComponents
    // 有高確認済の場合、編集不可（編集列なくなる）
    if (props.detail?.status !== '003') {
        cueectComponents = components
    }
    
    const rowHeightLightStyle = (record: InventoryDetailDto, index?: number) => {
        if( props.detail && (props.detail.status === '002' || props.detail.status === '001') ){
            return `${displayUtil.rowClassInventoryHightLight(record,index)} inventory-detail-editable-row` // 注意中间有个空格不能删掉
        } else {
            return `${displayUtil.rowClassName(record,index)} inventory-detail-editable-row` // 注意中间有个空格不能删掉
        }
    };

    const detailListTitle = <FormattedMessage id='inventory.detailList' />

    const submitDisable = (
        editingCellRowRef.current !== undefined
        || editingCellColRef.current !== undefined
        || !dataSource.edited
    )

    const closeDetail = () => {
        props.close()
        setDataSource({edited: false, data: dataSource.data})
        clearAllCache()
    }

    const handleCloseDrawer = () => {
        if (dataSource.edited) {
            popNoSaveError()
        } else {
            closeDetail()
        }
    }

    const popNoSaveError = () => {
        Modal.confirm({
            title: intl.formatMessage({id: 'message.sureYouWantToClose'}), // 編集中の内容がまだ返信できていないので、明細画面を閉じますか？
            content: intl.formatMessage({id: 'message.closeWillLostEdit'}), // 您已编辑，但未回复，关闭页面将会丢失已编辑内容
            autoFocusButton: 'ok',
            okText: intl.formatMessage({id: 'common.cancel'}), // キャンセル
            cancelText: intl.formatMessage({id: 'message.waiverChangeThenClose'}), // 画面を閉じる
            onCancel: closeDetail,
        });
    }

    const popNotAllowReplyError = () => {
        Modal.error({
            title: intl.formatMessage({id: 'inventory.recordTheReason'}, {a: intl.formatMessage({id: 'inventory.actualMoldsNum'}), b: intl.formatMessage({id: 'inventory.remarks'})}), // 実面数を 0 で入れた場合、その理由を【特記事項】に必ずご記載ください。
            okText: intl.formatMessage({id: 'common.close'}), // 閉じる
        });
    }

    return (
        <Drawer
            title={title}
            width='1280px'
            footer={null}
            visible={props.visible}
            bodyStyle={{ paddingBottom: 80 }}
            destroyOnClose={true}
            onClose={handleCloseDrawer}
        >
            {contextHolder}
            <Detail title={<FormattedMessage id='inventory.head' />} cols={inventoryCols} />
            <p style={{ 
                fontSize: 16, 
                fontWeight: 500, 
                marginBottom: 0, 
                marginTop: 10, 
                paddingLeft: "25px", 
                height: "35px", 
                borderBottom: '1px solid #f0f0f0' 
            }}>
                {detailListTitle}
            </p>
            <Table<InventoryDetailDto>
                className="list-detail-table inventory-detail-table"
                columns={mergedDetailColumnsColumns}
                dataSource={dataSource.data}
                bordered ={false}
                components={cueectComponents}
                rowClassName={rowHeightLightStyle}
                tableLayout={'fixed'}
                scroll={{y: 350}}
                pagination={false}
                size='small'
            />
            <Detail cols={modifiedCols}/>
            <Detail cols={confirmationCols}/>
            {props.detail?.status !== "003" &&
                <Row className="drawer-row" >
                    <Col span={22} style={{ textAlign: 'right' }}>
                        <Popconfirm title={<FormattedMessage id='message.editConfirm' />}
                            disabled={submitDisable}
                            onConfirm={handleReply}
                            okText={<FormattedMessage id='common.save' />}
                            cancelText={<FormattedMessage id='common.cancel' />} >
                            <Button type="primary"  htmlType="submit" disabled={submitDisable}>
                                <FormattedMessage id='common.response' />
                            </Button>
                        </Popconfirm>
                    </Col>
                </Row>
            }
        </Drawer >
    )
}
