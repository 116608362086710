import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { Button, Modal, Spin, } from 'antd';

import './index.css';

import { CommonExcelUpload, CommonExcelUploadProps, RowData } from '../common';
import { fillOrderDetails, OrderListRecordDto } from './api';
import { Dayjs } from 'dayjs';
import { displayUtil } from '../util';
export interface Props {
  visible: boolean;
  upload: (data: OrderListRecordDto[]) => Promise<any>;
  close: () => void;
  uploading: boolean
}

export function OrderResponseUpload(props: Props) {

  // 共通のstates
  const [dtos, setDtos] = useState<OrderListRecordDto[]>([]);
  const intl = useIntl();

  const validate = async (rowDatas: RowData[]) => {
    const uploadDtos = rowDatas.reduce(
      (currentDtos: OrderListRecordDto[], e: RowData) => {
        let existFlag: boolean = false;

        for (const element of currentDtos) {

          if (element.orderNo === e['orderNo'] && element.detailNo === e['detailNo']) {
            element.orderResponses.push({
              type: 'OrderResponse',
              id: -1,
              deliveryPlanDate: e['deliveryPlanDate'] as Dayjs,
              quantity: e['quantity'] as number,
              delFlag: e['delFlag'] as string,
              detailNo: e['deliveryDetailNo'] as number
            });

            existFlag = true;
          }
        }

        if (!existFlag) {
          currentDtos.push({
            id: -1,
            orderNo: e['orderNo'] as string,
            detailNo: e['detailNo'] as number,
            orderDate: null,
            iniDeliveryDate: null,
            productionPlanDate: null,
            orderResponses: [{
              type: 'OrderResponse',
              id: -1,
              deliveryPlanDate: e['deliveryPlanDate'] as Dayjs,
              quantity: e['quantity'] as number,
              delFlag: e['delFlag'] as string,
              detailNo: e['deliveryDetailNo'] as number
            }],
            deliveryDetails: [],
            devInstrucstions: [],
          })
        }

        return currentDtos;
      },
      []
    );

    const existDtos: OrderListRecordDto[] = await fillOrderDetails(uploadDtos);

    const errors: string[] = [];

    uploadDtos.forEach(dto => {

      const haveExistData = existDtos && existDtos.filter(existDto => existDto.orderNo === dto.orderNo && existDto.detailNo === dto.detailNo).length > 0 // 判断文件中上传的订单号和订单详细号在已有的数据中是否存在，存在返回ture，否则返回false
      if(!haveExistData) {
        errors.push(`注文番号：${dto.orderNo}、明細番号：${dto.detailNo}の注文情報が存在していません。`);
      }

      if (existDtos && existDtos.length > 0) {
        for (let existDto of existDtos) {
          if (existDto.orderNo === dto.orderNo && existDto.detailNo === dto.detailNo) {
            dto.id = existDto.id;

            // データチェック
            let sumQuantiy = 0;

            const existDetailNos = existDto.orderResponses.map(m => m.detailNo);

            dto.orderResponses.forEach(orderResponse => {

              // 削除対象以外の場合、加算する
              if (!orderResponse.delFlag) {
                sumQuantiy += !!orderResponse.quantity ? orderResponse.quantity : 0;
              }

              if (!existDetailNos.includes(orderResponse.detailNo) && (!!orderResponse.delFlag && orderResponse.id === -1)
                || (!existDetailNos.includes(orderResponse.detailNo) && orderResponse.detailNo !== undefined && orderResponse.detailNo !== 0)) {

                //削除データはＤＢに存在しません
                errors.push(`注文番号：${dto.orderNo}、明細番号：${dto.detailNo}、納入明細番号：${orderResponse.detailNo}で削除データはＤＢに存在しません`);
              }
            });

            const detailNosDuplicate: number[] = [];
            let responseDetailNos = dto.orderResponses.filter(f => f.detailNo !== undefined).map(m => m.detailNo);
            responseDetailNos.forEach((d) => {
              if (!!d && !detailNosDuplicate.includes(d)) {
                detailNosDuplicate.push(d);
              }
            })

            if (detailNosDuplicate.length < responseDetailNos.length) {
              // 存在チェック&重複チェック
              errors.push(`注文番号：${dto.orderNo}、明細番号：${dto.detailNo}で出荷明細番号が繰り返される`);
            }

            existDto.orderResponses.forEach(existOrderResponse => {

              if (!responseDetailNos.includes(existOrderResponse.detailNo)) {

                sumQuantiy += !!existOrderResponse.quantity ? existOrderResponse.quantity : 0;
              }
            })

            const quantity = !!existDto.quantity ? existDto.quantity : 0;
            if (sumQuantiy > quantity) {
              errors.push(`注文番号：${dto.orderNo}、明細番号：${dto.detailNo}：の回答件数は注文件数を超えってしまいました。`);
            }
          }
        }
      }

      dto.orderResponses.forEach((orderResponses) => {

        const quantityO: number | undefined = orderResponses.quantity

        if(quantityO === undefined || quantityO === null) {
          return undefined
        } else {
          const quantityDecimalIsOverThree = quantityO !== +( +quantityO.toFixed(3)) // 将数字四舍五入保留三位小数截取，如果截取前的数和截取后的数相等，则证明输入的数字小数点小于三位小数

          if(quantityO <= 0) {
            errors.push(intl.formatMessage({id: 'message.excelUploadItemMustGreatThenZeroError'}, {orderNoTitle: intl.formatMessage({id: 'shipment.orderNo'}), orderNo: dto.orderNo, orderDetailTitle: intl.formatMessage({id: 'acceptance.detailNo'}), orderDetailNo: dto.detailNo, dateTitle: intl.formatMessage({id: 'orderResponse.deliveryPlanDate'}), date: displayUtil.date(orderResponses.deliveryPlanDate), item: intl.formatMessage({id: 'orderResponse.responseQuantity'}) }))
          } else if (quantityDecimalIsOverThree) {
            errors.push(intl.formatMessage({id: 'message.excelUploadItemDecimalOverError'},       {orderNoTitle: intl.formatMessage({id: 'shipment.orderNo'}), orderNo: dto.orderNo, orderDetailTitle: intl.formatMessage({id: 'acceptance.detailNo'}), orderDetailNo: dto.detailNo, dateTitle: intl.formatMessage({id: 'orderResponse.deliveryPlanDate'}), date: displayUtil.date(orderResponses.deliveryPlanDate), item: intl.formatMessage({id: 'orderResponse.responseQuantity'}), n: 3 }))
          }
        }
      })
    });
    if (errors.length > 0) {
      throw errors;
    }

    return uploadDtos;
  };

  interface UnitValidator {
    validate: (value: any) => boolean;
    message: string;
  }

  // 判定文件上传时纳入预定数量是否是合规的回调函数 和 报错信息
  const responseQuantityCheck: UnitValidator[] = [{validate: (value: any) => {

    if(value !== undefined || value !== null) {

      const valueDecimalIsOverThree = value !== +( +value.toFixed(3)) // 将输入的数字四舍五入保留三位小数截取，如果截取前的数和截取后的数相等，则证明输入的数字小数点小于三位小数

      if(value <= 0) {
        return true
      } else if (valueDecimalIsOverThree) {
        return true
      } else { 
        return false
      }
    }
    return false
  }, message: `message.excelUploadFromatError`}]

  const uploadProps: CommonExcelUploadProps = {
    row: {
      columns: [
        { key: 'orderDetail.orderNo', field: 'orderNo', type: 'string', isRequired: true, validators: [], },
        { key: 'orderDetail.detailNo', field: 'detailNo', type: 'int', isRequired: true, validators: [], },
        { key: 'acceptance.deliveryDetailNo', field: 'deliveryDetailNo', type: 'int', isRequired: false, validators: [], },
        { key: 'orderResponse.deliveryPlanDate', field: 'deliveryPlanDate', type: 'date', isRequired: true, validators: [], },
        { key: 'orderResponse.responseQuantity', field: 'quantity', type: 'decimal', isRequired: true, validators: [], },
        { key: 'orderResponse.responseDeleteFlag', field: 'delFlag', type: 'string', isRequired: false, validators: [], }
      ],
      uniqueItems: [],
    },
    validate: validate,
    upload: (data: OrderListRecordDto[]) => {
      setDtos(data);
    },
  };

  const upload = () => {
    props.upload(dtos).then(() => {
      setDtos([]);
      props.close();
    });
  };

  return (
    <Modal
      title={<FormattedMessage id='orderResponse.uploadOrderResponses' />}
      width='1080px'
      footer={null}
      visible={props.visible}
      bodyStyle={{ paddingBottom: 80 }}
      destroyOnClose={true}
      onCancel={props.close}>
      <Spin 
        size='default'
        spinning={props.uploading}
      >
        <CommonExcelUpload {...uploadProps} />
      </Spin>
      <div className='search-button upload-buttom'>
        <Button className='search-close-btn' onClick={props.close}>
          <FormattedMessage id='common.cancel' />
        </Button>
        <Button className='search-search-btn' type='primary' onClick={upload} disabled={dtos.length === 0}>
          <FormattedMessage id='common.upload' />
        </Button>
      </div>
    </Modal >
  );
}

