import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { filterUtil, } from '../util'
import { Button, Form, Input, Modal, Select } from 'antd';
import { Field, useFields } from '../fields';
import { EstimateSearchDto } from './api';
import { useUser } from '../authorization';
import { DatePicker } from '../common';

const { RangePicker } = DatePicker;

export interface Props {
    visible: boolean;
    searchDto: EstimateSearchDto;
    close: () => void;
    handleSearch: (searchDto: EstimateSearchDto) => void;
}

export function EstimationSearch(props: Props) {

    return (
        <Modal
            width={560}
            title={<FormattedMessage tagName='h4' id='common.search' />}
            footer={null}
            visible={props.visible}
            closable={false}
            afterClose={props.close}
            destroyOnClose={true}
            style={{ top: '50px' }}>
            {props.visible && <EstimationSearchForm {...props} />}
        </Modal>
    );
}

const EstimationSearchForm = (props: Props) => {

    const buyerDepartments = useFields('buyerDepartment');
    const mulStatus = useFields('estimateStatus');
    const responseStatusOptions = useFields('responseStatus');
    const estimatesTypeOptions = useFields('estimatesType');
    const itemOptions = useFields('item');
    const [form] = Form.useForm<EstimateSearchDto>();
    const user = useUser();
    useEffect(() => {
        form.setFieldsValue(props.searchDto);
    });


    // 入札依頼番号
    const requestNoTitle = <FormattedMessage id='estimate.requestNo' />
    // 発注部門
    const buyerDepartmentTitle = <FormattedMessage id='estimate.buyerDepartment' />
    // 購買担当
    const purchasingManagerNameTitle = <FormattedMessage id='estimate.purchasingManager' />
    // 依頼カテゴリー
    const estimatesTypeTitle = <FormattedMessage id='estimate.estimatesType' />
    // 品番
    const itemTitle = <FormattedMessage id='estimate.item' />
    //  品名
    const itemNameTitle = <FormattedMessage id='estimate.itemName' />
    // 見積依頼日
    const requestDateTitle = <FormattedMessage id='estimate.requestDate' />
    // 回答希望日
    const answerDeadlineTitle = <FormattedMessage id='estimate.answerDeadline' />
    // 案件状態
    const statusTitle = <FormattedMessage id='estimate.status' />
    // 案件状態
    const responseStatusTitle = <FormattedMessage id='estimate.responseStatus' />
    const supplierOptions = useFields('fullSupplier');
    const supplierTitle = <FormattedMessage id='acceptance.supplier' />;
    return (
        <Form
            form={form}
            onFinish={props.handleSearch}
            layout={'horizontal'}
            colon={false}
            size={'large'}
            labelCol={{ span: 6 }}
            wrapperCol={{ span: 16 }}>
           
            <Form.Item name='requestNo' label={requestNoTitle} style={{ marginBottom: '10px' }}>
                <Input min={1} max={40} />
            </Form.Item>
            { user?.isSupplier &&
           <Form.Item name='suppliers' label={supplierTitle} style={{marginBottom:0}}>
                <Select<Field>
                    mode={'multiple'}
                    allowClear={true}
                    className="select-mutil"
                    autoClearSearchValue={false}
                    filterOption={filterUtil.selectFilter}
                    onSearch={filterUtil.onSearchForm(form, 'suppliers', supplierOptions)}
                    loading={supplierOptions.length === 0}
                    options={supplierOptions} />
            </Form.Item>
            }
           {!user?.isSupplier && <Form.Item name='buyerDepartments' label={buyerDepartmentTitle} style={{ marginBottom: '10px' }}>
                <Select options={buyerDepartments} mode={'multiple'} allowClear />
            </Form.Item>} 
            {!user?.isSupplier && <>
                <Form.Item name='purchasingManagerName' label={purchasingManagerNameTitle} style={{ marginBottom: '10px' }}>
                    <Input min={1} max={40} />
                </Form.Item>
                <Form.Item name='estimatesType' label={estimatesTypeTitle} style={{ marginBottom: '10px' }}>
                    <Select options={estimatesTypeOptions} allowClear={true} />
                </Form.Item>
            </>}
            <Form.Item name='item' label={itemTitle} style={{ marginBottom: '5px' }}>
                <Select<Field>
                    mode={'multiple'}
                    className="select-mutil"
                    allowClear={true}
                    autoClearSearchValue={false}
                    filterOption={filterUtil.selectFilter}
                    onSearch={filterUtil.onSearchForm(form, 'item', itemOptions)}
                    loading={itemOptions.length === 0}
                    options={itemOptions}
                    style={{ maxHeight: '100px' }} />
            </Form.Item>
            <Form.Item name='itemName' label={itemNameTitle} style={{ marginBottom: '10px' }}>
                <Input min={1} max={40} />
            </Form.Item>
            <Form.Item name='requestDate' label={requestDateTitle} style={{ marginBottom: '10px' }}>
                <RangePicker allowEmpty={[true, true]} style={{ width: '100%' }} />
            </Form.Item>
            <Form.Item name='answerDeadline' label={answerDeadlineTitle} style={{ marginBottom: '10px' }}>
                <RangePicker allowEmpty={[true, true]} style={{ width: '100%' }} />
            </Form.Item>
            <Form.Item name='status' label={statusTitle} style={{ marginBottom: '10px' }}>
                <Select options={mulStatus} mode={'multiple'} allowClear />
            </Form.Item>
            {!!user?.isSupplier && <>
                <Form.Item name='responseStatus' label={responseStatusTitle} style={{ marginBottom: '10px' }}>
                    <Select options={responseStatusOptions} mode={'multiple'} allowClear />
                </Form.Item>
            </>}
            <Form.Item style={{ marginLeft: '20px' }}>
                <div className='search-button'>
                    <Button onClick={props.close} style={{ marginRight: 20, marginLeft: 32 }}>
                        <FormattedMessage id='common.close' />
                    </Button>
                    <Button htmlType='submit'>
                        <FormattedMessage id='common.search' />
                    </Button>
                </div>
            </Form.Item>
        </Form>
    );
};