import React from 'react';
import { FormattedMessage, } from 'react-intl';
import { FilePdfOutlined } from '@ant-design/icons'
import { Drawer, Button } from 'antd';
import { Detail } from '../common'
import { AcceptanceDetailRecordDto} from './api'
import { displayUtil } from '../util'
import { useFields } from "../fields";

import './index.css';

export interface Props {
  visible: boolean;
  detail?: AcceptanceDetailRecordDto;
  close: () => void;
  download:() => void
}

export function AcceptanceDetail(props: Props) {

  const locationNames = useFields('locationName');

  // 発注者情报
  const BuyerCols: [JSX.Element, any][] = [
    // 発注者コード
    [<FormattedMessage id='acceptance.buyer' />, props.detail?.buyer],
    // 発注者名称
    [<FormattedMessage id='acceptance.buyerName' />, props.detail?.buyerName],
    // 発注者部門  名称
    [<FormattedMessage id='acceptance.buyerDepartmentName' />, props.detail?.buyerDepartmentName],
  ]
 // 受注者者情报
 const supplierCols: [JSX.Element, any][] = [
    // 受注者コード
    [<FormattedMessage id='acceptance.supplier' />, props.detail?.supplier],    
    // 受注者名称
    [<FormattedMessage id='acceptance.supplierName' />, props.detail?.supplierName],
  ]  
  //数量編集
  const getQuantity = (num1?: number, str2?: string) => {
    if (num1 === undefined || str2 === undefined) {
      return "";
    } 
    return num1+" " + str2;
  };
  // 注文情报
  const OrderCols: [JSX.Element, any][] = [
    // 注文番号
    [<FormattedMessage id='acceptance.orderNo' />, props.detail?.orderNo],
    // 明細番号
    [<FormattedMessage id='acceptance.detailNo' />, props.detail?.detailNo],
    // 購買担当
    [<FormattedMessage id='acceptance.purchasingManager' />, props.detail?.purchasingManager],
    // 注文年月日
    [<FormattedMessage id='acceptance.orderDate' />, displayUtil.date(props.detail?.orderDate)],
    // 初期納品日
    [<FormattedMessage id='acceptance.iniDeliveryDate' />, displayUtil.date(props.detail?.iniDeliveryDate)],
    // 再日程日
    [<FormattedMessage id='orderDetail.productionPlanDate' />,!!props.detail?.productionPlanDate ? displayUtil.date(props.detail?.productionPlanDate): ''],
    // 数量
    [<FormattedMessage id='acceptance.orderQuantity' />,getQuantity( props.detail?.orderQuantity,props.detail?.unit)],
    // 単価
    [<FormattedMessage id='acceptance.orderUnitPrice' />, displayUtil.currency(props.detail?.currencyCode,props.detail?.orderUnitPrice)],
    // 金額
    [<FormattedMessage id='acceptance.orderAmount' />, displayUtil.currency(props.detail?.currencyCode, props.detail?.orderAmount)],
    //通貨
    [<FormattedMessage id='acceptance.currencyCode' />, props.detail?.currencyCode],  
  ]
  // 検収情报
  const AcceptanceCols: [JSX.Element, any][] = [

    // 検収番号
    [<FormattedMessage id='acceptance.acceptanceNo' />, props.detail?.acceptanceNo],
    // 検収詳細番号
    [<FormattedMessage id='acceptance.acceptanceDetailNo' />, props.detail?.acceptanceDetailNo],
    // 検収日
    [<FormattedMessage id='acceptance.acceptanceDate' />, displayUtil.date(props.detail?.acceptanceDate)],
    // 品番
    [<FormattedMessage id='acceptance.item' />, props.detail?.item],
    // 品名
    [<FormattedMessage id='acceptance.itemName' />, props.detail?.itemName],
    // 検収数量
    [<FormattedMessage id='acceptance.acceptanceQuantity' />, getQuantity(props.detail?.quantity,props.detail?.unit)],
    // 単価
    [<FormattedMessage id='acceptance.acceptanceUnitPrice' />, displayUtil.currency(props.detail?.currencyCode,props.detail?.unitPrice)],
    // 検収金額
    [<FormattedMessage id='acceptance.acceptanceAmount' />, displayUtil.currency(props.detail?.currencyCode, displayUtil.amount(props.detail?.currencyCode, 1, props.detail?.amount))],
    //通貨
    [<FormattedMessage id='acceptance.currencyCode' />, props.detail?.currencyCode],  
    // 移動区分
    [<FormattedMessage id='acceptance.moveType' />, props.detail?.moveTypeName],
    // 保存場所コード
    [<FormattedMessage id='acceptance.locationCd' />, props.detail?.locationCd],
    // 保存場所名称
    [<FormattedMessage id='delivery.locationCd' />, displayUtil.field(locationNames)(props.detail?.locationName)],
    // 纳品番号
    [<FormattedMessage id='acceptance.deliveryNo' />, props.detail?.deliveryNo],

  ]

  const title = (
    <>
      <FormattedMessage id='acceptance.detail' />
      {/* <Button className='download-btn' onClick={props.download}>
        <FilePdfOutlined style={{ color: 'red' }} />
        <FormattedMessage id='common.download' />
      </Button> */}
    </>
  )

  return (
    <Drawer
      title={title}
      width='1180px'
      footer={null}
      visible={props.visible}
      bodyStyle={{ paddingBottom: 80 }}
      destroyOnClose={true}
      onClose={props.close}>
      <Detail
        title={<FormattedMessage id='acceptance.buyerInfo' />}
        cols={BuyerCols}
      />
      <Detail
        title={<FormattedMessage id='acceptance.supplierInfo' />}
        cols={supplierCols}
      />
      <Detail
        title={<FormattedMessage id='acceptance.acceptanceInfo' />}
        cols={AcceptanceCols}
      />
      { props.detail?.orderNo !== '' && props.detail?.orderNo !== null && 
          <Detail
            title={<FormattedMessage id='acceptance.orderInfo' />}
            cols={OrderCols}
          />
      }
      
    </Drawer>
  )
}
