import { Row, Col, Button, Form, Input, Select, InputNumber, Drawer, } from 'antd';
import { EstiamteRespondentsResponseDto, EstimateRequestSpecifySupplierDto } from './api';
import { FormattedMessage, useIntl } from 'react-intl';
import React, { useEffect, useState } from 'react';
import { dateUtil, displayUtil } from '../util';
import { useFields } from '../fields';
import dayjs from 'dayjs';
import Popconfirm from 'antd/es/popconfirm';
import { otherPriceCheck, unitPriceCheck } from '../util/priceCheck';
import { Attachment, DatePicker } from '../common';
import { CommonFileUpload } from '../common/CommonFileUpload';
export interface Props {
  visible: boolean;
  detail?: EstimateRequestSpecifySupplierDto;
  enable: boolean;
  close: () => void;
  joining: (data: EstiamteRespondentsResponseDto) => void;
  absenting: () => void;

}
export function EstimateSpecifyEditDetail(props: Props) {
  useEffect(() => {
    if (props.visible === true) {
      if (!!props.detail!.remarks && props.detail!.remarks.length > 18) {
        const remarkTextAreaElement = document.getElementById('remarkTextArea');
        if (!!remarkTextAreaElement) {
          remarkTextAreaElement.style.overflowY = 'scroll'
          setTimeout(() => {
            if (!!remarkTextAreaElement) {
              remarkTextAreaElement.style.height = '2000px'
            }
          }, 200);
        }
      }
    }
  }, [props.detail, props.visible])

  const [form] = Form.useForm<EstiamteRespondentsResponseDto>();
  useEffect(() => {

    if (!!props.detail?.supplier) {
      setAttachments(props.detail.supplier.attachments);
      form.setFieldsValue(props.detail.supplier);
    }
  }, [form, props.detail]);

  //案件状態
  const statusOptions = useFields('estimateStatus');
  const responseStatus = useFields('responseStatus');
  const buyerDepartmentOptions = useFields('buyerDepartment');
  //供应商
  const supplierOptions = useFields('supplier');
  //品番
  const kindsOptions = useFields('kinds');
  const intl = useIntl();

  //カテゴリー
  const estimatesTypeOptions = useFields('estimatesType');

  //金额类型
  const disireCurrencyOptions = useFields('currency').map(v => {
    return { label: v.label, value: v.label }
  })

  const [attachments, setAttachments] = useState<Attachment[]>([]);
  const fileDownloadProps = {
    attachments: !!props.detail?.attachments ? props.detail.attachments : [],
    canUpload: false,
    persist: () => { },
    remove: () => { },
  };

  const fileUploadProps = {
    attachments: !!props.detail?.supplier.attachments ? props.detail?.supplier.attachments : [],
    canUpload: (props.detail?.status === '002' || props.detail?.status === '003' || props.detail?.supplier.responseStatus === '003' || props.detail?.supplier.responseStatus === '004') ? false : true,
    maxCount: 5,
    persist: (uuid: string) => {
      setAttachments([...attachments, { id: -1, uuid: uuid }]);
    },
    remove: (uuid: string) => {
      setAttachments(attachments.filter((attachment: Attachment) => attachment.uuid !== uuid));
    },
  }

  //見積依頼編集画面依頼項目
  const estimateSpecifyRequestEditDetailCols: [JSX.Element, any, any][] = [

    //案件状態
    [<FormattedMessage id='estimate.status' />, displayUtil.field(statusOptions)(props.detail?.status), ''],
    //入札依頼番号
    [<FormattedMessage id='estimate.requestNo' />, props.detail?.requestNo, ''],
    //発注部門
    [<FormattedMessage id='estimate.buyerDepartment' />, displayUtil.field(buyerDepartmentOptions)(props.detail?.buyerDepartmentCode), ''],
    //購買担当
    [<FormattedMessage id='estimate.purchasingManager' />, props.detail?.purchasingManagerName, ''],
    //見積依頼日
    [<FormattedMessage id='estimate.requestDate' />, displayUtil.date(props.detail?.requestDate), ''],
    //回答希望日
    [<FormattedMessage id='estimate.answerDeadline' />, displayUtil.date(props.detail?.answerDeadline), ''],
    //カテゴリー
    [<FormattedMessage id='estimate.estimatesType' />, displayUtil.field(estimatesTypeOptions)(props.detail?.estimatesType), ""],
    //品番
    [<FormattedMessage id='estimate.item' />, props.detail?.item, ''],
    //品名
    [<FormattedMessage id='estimate.itemName' />, props.detail?.itemName, ''],
    //希望単価
    [<FormattedMessage id='estimate.desireUnitPrice' />, displayUtil.currency(props.detail?.desireCurrency, props.detail?.desireUnitPrice), ''],
    //希望単価通貨
    [<FormattedMessage id='estimate.desireCurrency' />, props.detail?.desireCurrency, ''],
    //企画月数
    [<FormattedMessage id='estimate.planningMonths' />, props.detail?.planningMonths, <FormattedMessage id='estimate.monthUnit' />],
    //企画数量
    [<FormattedMessage id='estimate.planQuantity' />, props.detail?.planQuantity, <FormattedMessage id='estimate.quantityUnit' />],
    //月発注予定数
    [<FormattedMessage id='estimate.monthlyPlanOrderQuantity' />, props.detail?.monthlyPlanOrderQuantity, <FormattedMessage id='estimate.quantityUnit' />],
    //受渡場所
    [<FormattedMessage id='estimate.userName' />, props.detail?.userName, ''],

    [<FormattedMessage id='estimate.modifiedTime' />, displayUtil.date(props.detail?.modifiedTime), ''],
    // // サプライヤー
    // [<FormattedMessage id='estimate.supplier' />, props.detail?.supplier,''],
    // 備考
    [<FormattedMessage id='estimate.remarks' />, <Input.TextArea
      bordered={false}
      disabled
      id={'remarkTextArea'}
      autoSize={{ maxRows: 5 }}
      value={props.detail?.remarks}
      style={{ padding: '0', color: 'rgba(0, 0, 0, 0.85)', textDecoration: 'underline', cursor: 'text' }}></Input.TextArea>, ''],
    [<FormattedMessage id='estimate.upload' />,
    <Row>
      <CommonFileUpload  {...fileDownloadProps} />
    </Row>, ""]

  ]

  const paragraph1: [JSX.Element, any, string, any, any, any][] = [
    //メーカー名
    [<FormattedMessage id='estimate.makerName' />, props.detail?.supplier?.makerName, 'makerName', <Input disabled={props.enable} />, '', [{ max: 100, message: intl.formatMessage({ id: 'estimate.maxError' }, { a: intl.formatMessage({ id: 'estimate.makerName' }), b: '100' }) }]],
    // メーカー品番
    [<FormattedMessage id='estimate.makerItem' />, props.detail?.supplier?.makerItem, 'makerItem', <Input disabled={props.enable} />, '', [{ max: 40, message: intl.formatMessage({ id: 'estimate.maxError' }, { a: intl.formatMessage({ id: 'estimate.makerItem' }), b: '40' }) }]],
    //部品種別
    [<FormattedMessage id='estimate.itemTypeCode' />, props.detail?.supplier?.itemTypeCode, 'itemTypeCode', <Select options={kindsOptions} disabled={props.enable} allowClear={true} />, '', []],
    //契約条件
    [<FormattedMessage id='estimate.contractConditionsCode' />, props.detail?.supplier?.contractConditionsCode, 'contractConditionsCode', <Input disabled={props.enable} />, '', [{ max: 40, message: intl.formatMessage({ id: 'estimate.maxError' }, { a: intl.formatMessage({ id: 'estimate.contractConditionsCode' }), b: '40' }) }]],
    //生産場所（前工程）
    [<FormattedMessage id='estimate.prevLocation' />, props.detail?.supplier?.prevLocation, 'prevLocation', <Input disabled={props.enable} />, '', [{ max: 150, message: intl.formatMessage({ id: 'estimate.maxError' }, { a: intl.formatMessage({ id: 'estimate.prevLocation' }), b: '150' }) }]],
    //生産場所（後工程）
    [<FormattedMessage id='estimate.nextLocation' />, props.detail?.supplier?.nextLocation, 'nextLocation', <Input disabled={props.enable} />, '', [{ max: 150, message: intl.formatMessage({ id: 'estimate.maxError' }, { a: intl.formatMessage({ id: 'estimate.nextLocation' }), b: '150' }) }]]
  ];

  const paragraph2: [JSX.Element, any, string, any, any, any][] = [
    //型費用
    [<FormattedMessage id='estimate.moldCost' />, props.detail?.supplier?.moldCost, 'moldCost', <InputNumber disabled={props.enable} className={'number-input-overwrite'} />,
    <Form.Item name='moldCurrency' style={{ textAlign: 'left', fontWeight: 'bold', marginBottom: 12 }}
      rules={[{
        validator: (rule, value, callback) => {
          if (!!form.getFieldsValue().moldCost && (value === null || value === undefined)) {
            callback(intl.formatMessage({ id: 'estimate.nullError' }, { name: intl.formatMessage({ id: 'estimate.desireCurrency' }) }));
          }
          callback();
        }
      }]}><Select options={disireCurrencyOptions} disabled={props.enable} allowClear={true} /></Form.Item>, [{
        validator: (rule: any, value: string, callback: any) => {
          let message = otherPriceCheck(intl, value, form.getFieldsValue().moldCurrency, intl.formatMessage({ id: 'estimate.moldCost' }));
          if (message !== '') {
            callback(message);
          } else {
            callback();
          }
        }
      }],
    ],
    //型の種類
    [<FormattedMessage id='estimate.moldType' />, props.detail?.supplier?.moldType, 'moldType', <Input disabled={props.enable} />, '', [{ max: 4, message: intl.formatMessage({ id: 'estimate.maxError' }, { a: intl.formatMessage({ id: 'estimate.moldType' }), b: '4' }) }]],
    //型の工程数
    [<FormattedMessage id='estimate.moldProcessNum' />, props.detail?.supplier?.moldProcessNum, 'moldProcessNum', <InputNumber disabled={props.enable} className={'number-input-overwrite'} max={99999} />, '', [{
      validator: (rule: any, value: string, callback: any) => {
        const reg = /^[1-9]\d{0,4}$/;
        if (value === null || value === undefined || value === '' || reg.test(value)) {
          callback();
        } else {
          callback(intl.formatMessage({ id: 'message.positiveIntegerError' }, { a: '5' }));
        }
      }
    }]],
    //型の面数
    [<FormattedMessage id='estimate.moldNum' />, props.detail?.supplier?.moldNum, 'moldNum', <InputNumber disabled={props.enable} className={'number-input-overwrite'} max={99999} />, '', [{
      validator: (rule: any, value: string, callback: any) => {
        const reg = /^[1-9]\d{0,4}$/;
        if (value === null || value === undefined || value === '' || reg.test(value)) {
          callback();
        } else {
          callback(intl.formatMessage({ id: 'message.positiveIntegerError' }, { a: '5' }));
        }
      }
    }]],
    //治具费
    [<FormattedMessage id='estimate.jigCost' />, props.detail?.supplier?.jigCost, 'jigCost', <InputNumber disabled={props.enable} className={'number-input-overwrite'} />,
    <Form.Item name='jigCurrency' style={{ textAlign: 'left', fontWeight: 'bold', marginBottom: 12 }}
      rules={[{
        validator: (rule, value, callback) => {
          if (!!form.getFieldsValue().jigCost && (value === null || value === undefined)) {
            callback(intl.formatMessage({ id: 'estimate.nullError' }, { name: intl.formatMessage({ id: 'estimate.jigCurrency' }) }));
          }
          callback();
        }
      }]}>
      <Select options={disireCurrencyOptions} disabled={props.enable} allowClear={true} /></Form.Item>, [{
        validator: (rule: any, value: string, callback: any) => {
          let message = otherPriceCheck(intl, value, form.getFieldsValue().jigCurrency, intl.formatMessage({ id: 'estimate.jigCost' }));
          if (message !== '') {
            callback(message);
          } else {
            callback();
          }
        }
      }]],
    //版費
    [<FormattedMessage id='estimate.editionFee' />, props.detail?.supplier?.editionFee, 'editionFee',
    <InputNumber disabled={props.enable} className={'number-input-overwrite'} />,
    <Form.Item name='editionCurrency' style={{ textAlign: 'left', fontWeight: 'bold', marginBottom: 12 }}
      rules={[{
        validator: (rule, value, callback) => {
          if (!!form.getFieldsValue().editionFee && (value === null || value === undefined)) {
            callback(intl.formatMessage({ id: 'estimate.nullError' }, { name: intl.formatMessage({ id: 'estimate.editionCurrency' }) }));
          }
          callback();
        }
      }]}>
      <Select options={disireCurrencyOptions} disabled={props.enable} allowClear={true} /></Form.Item>, [{
        validator: (rule: any, value: string, callback: any) => {
          let message = otherPriceCheck(intl, value, form.getFieldsValue().editionCurrency, intl.formatMessage({ id: 'estimate.editionFee' }));
          if (message !== '') {
            callback(message);
          } else {
            callback();
          }
        }
      }]
    ],
    //修理費
    [<FormattedMessage id='estimate.repairsCost' />, props.detail?.supplier?.repairsCost, 'repairsCost',
    <InputNumber disabled={props.enable} className={'number-input-overwrite'} />,
    <Form.Item name='repairsCurrency'
      style={{ textAlign: 'left', fontWeight: 'bold', marginBottom: 12 }}
      rules={[{
        validator: (rule: any, value: string, callback: any) => {
          if (!!form.getFieldsValue().repairsCost && (value === null || value === undefined || value === '')) {
            callback(intl.formatMessage({ id: 'estimate.nullError' }, { name: intl.formatMessage({ id: 'estimate.repairsCurrency' }) }));
          }
          callback();
        }
      }]}>
      <Select options={disireCurrencyOptions} disabled={props.enable} allowClear={true} /></Form.Item>, [{
        validator: (rule: any, value: string, callback: any) => {
          let message = otherPriceCheck(intl, value, form.getFieldsValue().repairsCurrency, intl.formatMessage({ id: 'estimate.repairsCost' }));
          if (message !== '') {
            callback(message);
          } else {
            callback();
          }
        }
      }],
    ]
  ];

  const paragraph3: [JSX.Element, any, string, any, any, any][] = [
    // //回答状態
    // [<FormattedMessage id='estimate.responseStatus' />, props.detail?.supplier?.responseStatus, 'responseStatus', <Select options={responseStatus} disabled />, '', []]
  ];

  const requestCols = [];
  for (let col of estimateSpecifyRequestEditDetailCols) {
    requestCols.push(<Col span={12} style={{ textAlign: 'right', fontWeight: 'bold' }}>{col[0]}</Col>);
    //控件
    requestCols.push(<Col span={12} style={{ textDecorationLine: 'underline' }}><pre>{col[1]}{!!col[2] ? <>&nbsp;&nbsp;{col[2]}</> : <>{col[2]}</>}</pre></Col>);
  }

  const resposeCols1 = [];
  for (let col of paragraph1) {
    resposeCols1.push(<Col span={24} style={{ textAlign: 'right', fontWeight: 'bold', }}><Form.Item label={col[0]} style={{ textAlign: 'left', fontWeight: 'bold', marginBottom: 12 }} name={col[2]} rules={col[5]}>{col[3]}</Form.Item></Col>);
  }
  const resposeCols2 = [];
  for (let col of paragraph2) {
    resposeCols2.push(
      <Row>
        <Col span={18} style={{ textAlign: 'right', fontWeight: 'bold', }}>
          <Form.Item
            label={col[0]}
            style={{ textAlign: 'left', fontWeight: 'bold', marginBottom: 12 }}
            name={col[2]}
            rules={col[5]}>
            {col[3]}
          </Form.Item>
        </Col>
        <Col span={6} style={{ textAlign: 'left', fontWeight: 'bold', }}>
          {col[4]}
        </Col>
      </Row>
    );
  }

  const resposeCols3 = [];
  for (let col of paragraph3) {
    resposeCols3.push(<Col span={24} style={{ textAlign: 'right', fontWeight: 'bold', }}><Form.Item label={col[0]} style={{ textAlign: 'left', fontWeight: 'bold', marginBottom: 12 }} name={col[2]} rules={col[5]}>{col[3]}</Form.Item></Col>);
  }

  const join = (formValue: EstiamteRespondentsResponseDto) => {
    formValue.attachments = attachments;
    props.joining(formValue);
  }
  return (
    <Drawer title={<FormattedMessage id='estimate.reponseDetail' />}
      visible={props.visible}   //设置默认隐藏 
      footer={false}   //对话框的底部
      width={1500}  //宽度设置
      onClose={props.close}
      zIndex={2}
      destroyOnClose={true}
    >
      <Form
        form={form}
        name="advanced_search"
        className="ant-advanced-search-form"
        layout={'horizontal'}
        labelCol={{ span: 10 }}
        wrapperCol={{ span: 14 }}
        colon={false}
        onFinish={join}>

        <Row style={{ paddingBottom: 10 }}>
          <Col span={8} style={{ textAlign: 'left' }}><FormattedMessage id='estimate.requestInfo' /></Col>
          <Col span={10} style={{ textAlign: 'left' }}><FormattedMessage id='estimate.reponseInfo' /></Col>
        </Row>
        <Row>

          <Col span={6} style={{ borderStyle: 'solid', borderColor: '#d9d9d9', borderWidth: 1, paddingTop: 15, paddingBottom: 15 }}>
            <Row gutter={[16, 8]}>
              {requestCols}
            </Row>
          </Col>

          <Col span={17} offset={1} style={{ borderStyle: 'solid', borderColor: '#d9d9d9', borderWidth: 1, paddingTop: 15, paddingBottom: 15, marginLeft: 10 }}>
            <Row>
              <Col span={10}>
                {resposeCols3}
                {/* supplier */}
                <Col span={24} style={{ textAlign: 'right', fontWeight: 'bold', }}>
                  <Form.Item label={<FormattedMessage id='estimate.supplier' />}
                    style={{ textAlign: 'left', fontWeight: 'bold', marginBottom: 12 }}
                    name='supplier' ><Select options={supplierOptions} disabled />
                  </Form.Item>
                </Col>
                {/* estimatedEffectiveDate */}
                <Col span={24} style={{ textAlign: 'right', fontWeight: 'bold', }}>
                  <Form.Item label={<FormattedMessage id='estimate.estimatedEffectiveDate' />}
                    style={{ textAlign: 'left', fontWeight: 'bold', marginBottom: 12 }}
                    rules={[{
                      required: true,
                      message: intl.formatMessage({ id: 'estimate.nullError' }, { name: intl.formatMessage({ id: 'estimate.estimatedEffectiveDate' }) })
                    }]}
                    name='estimatedEffectiveDate' >
                      <DatePicker disabled={props.enable} className={'picker-overwrite'} disabledDate={dateUtil.isOverDateLimit}/>
                  </Form.Item>
                </Col>
                {/* leadTime */}
                <Col span={24} style={{ textAlign: 'right', fontWeight: 'bold', }}>
                  <Form.Item label={<FormattedMessage id='estimate.leadTime' />}
                    style={{ textAlign: 'left', fontWeight: 'bold', marginBottom: 12 }}
                    name='leadTime'
                    rules={[{
                      required: true,
                      message: intl.formatMessage({ id: 'estimate.nullError' }, { name: intl.formatMessage({ id: 'estimate.leadTime' }) })
                    }, {
                      validator: (rule, value, callback) => {
                        if(value !== null && value <= 0) {
                          const message = intl.formatMessage({id: 'message.onlyPositiveNumber'})
                          return callback(message)
                        } else {
                          return callback()
                        }
                      },
                    }]}>
                    <InputNumber disabled={props.enable} max={2147483647} min={-2147483648} className={'number-input-overwrite'} />
                  </Form.Item>
                </Col>
                {/* originalCountryName */}
                <Col span={24} style={{ textAlign: 'right', fontWeight: 'bold', }}>
                  <Form.Item label={<FormattedMessage id='estimate.originalCountryName' />}
                    style={{ textAlign: 'left', fontWeight: 'bold', marginBottom: 12 }}
                    name='originalCountryName'
                    rules={[{
                      required: true,
                      message: intl.formatMessage({ id: 'estimate.nullError' },
                        { name: intl.formatMessage({ id: 'estimate.originalCountryName' }) })
                    }, { 
                      max: 100, 
                      message: intl.formatMessage({ id: 'estimate.maxError' }, { a: intl.formatMessage({ id: 'estimate.originalCountryName' }), b: '100' }) 
                    }]}
                  >
                    <Input disabled={props.enable} />
                  </Form.Item>
                </Col>
                {resposeCols1}
                {/* estimateStartDate */}
                <Col span={24} style={{ textAlign: 'right', fontWeight: 'bold', }}>
                  <Form.Item label={<FormattedMessage id='estimate.estimateStartDate' />}
                    style={{ textAlign: 'left', fontWeight: 'bold', marginBottom: 12 }}
                    name='estimateStartDate'
                    rules={[{ required: true, message: intl.formatMessage({ id: 'estimate.nullError' }, { name: intl.formatMessage({ id: 'estimate.estimateStartDate' }) }) }, {
                      validator: (rule, value, callback) => {
                        const edate = form.getFieldValue('estimateEndDate');
                        if (value === '' || value === undefined || value === null || edate === '' || edate === null || edate === undefined ||
                          (dayjs(edate).isAfter(dayjs(value), 'day'))) {
                          callback();
                        } else {
                          callback(intl.formatMessage({ id: 'estimate.answerDeadlineError' },
                            { a: intl.formatMessage({ id: 'estimate.estimateEndDate' }), b: intl.formatMessage({ id: 'estimate.estimateStartDate' }) }));
                        }
                      }
                    }]}>
                    <DatePicker disabled={props.enable} className={'picker-overwrite'} disabledDate={dateUtil.isOverDateLimit}/>
                  </Form.Item>
                </Col>
                {/* estimateEndDate */}
                <Col span={24} style={{ textAlign: 'right', fontWeight: 'bold', }}>
                  <Form.Item label={<FormattedMessage id='estimate.estimateEndDate' />}
                    style={{ textAlign: 'left', fontWeight: 'bold', marginBottom: 12 }}
                    name='estimateEndDate'
                    rules={[{ required: true, message: intl.formatMessage({ id: 'estimate.nullError' }, { name: intl.formatMessage({ id: 'estimate.estimateEndDate' }) }) }]}>
                    <DatePicker disabled={props.enable} className={'picker-overwrite'} disabledDate={dateUtil.isOverDateLimit}/>
                  </Form.Item>
                </Col>
                <Col span={24} style={{ textAlign: 'right', fontWeight: 'bold', }}>
                  <Form.Item label={<FormattedMessage id='estimate.remarks' />}
                    style={{ textAlign: 'left', fontWeight: 'bold', marginBottom: 12 }}
                    name='remarks'
                    rules={[{ max: 100, message: intl.formatMessage({ id: 'estimate.maxError' }, { a: intl.formatMessage({ id: 'estimate.remarks' }), b: '100' }) }]}
                  >
                    <Input.TextArea autoSize={{ minRows: 3, maxRows: 6 }} disabled={props.enable} />
                  </Form.Item>
                </Col>
              </Col>
              <Col span={14}>
                <Row>
                  <Col span={18} style={{ textAlign: 'right', fontWeight: 'bold', }} >
                    <Form.Item label={<FormattedMessage id='estimate.unitPrice' />}
                      style={{ textAlign: 'left', fontWeight: 'bold', marginBottom: 12 }}
                      name='unitPrice'
                      rules={[{ required: true, message: intl.formatMessage({ id: 'estimate.nullError' }, { name: intl.formatMessage({ id: 'estimate.unitPrice' }) }) }, {
                        validator: (rule, value, callback) => {
                          let message = unitPriceCheck(intl, value, form.getFieldsValue().unitCurrency);
                          if (message !== '') {
                            callback(message);
                          } else {
                            callback();
                          }
                        }
                      }]}>
                      <InputNumber disabled={props.enable} className={'number-input-overwrite'} />
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <Form.Item name='unitCurrency' style={{ textAlign: 'left', fontWeight: 'bold', marginBottom: 12 }}
                      rules={[{ required: true, message: intl.formatMessage({ id: 'estimate.nullError' }, { name: intl.formatMessage({ id: 'estimate.unitCurrency' }) }) }, {
                        validator: (rule, value, callback) => {
                          if (!!form.getFieldsValue().unitPrice && (value === null || value === undefined)) {
                            callback(intl.formatMessage({ id: 'estimate.nullError' }, { name: intl.formatMessage({ id: 'estimate.unitCurrency' }) }));
                          }
                          callback();
                        }
                      }]}>
                      <Select options={disireCurrencyOptions} disabled={props.enable} allowClear={true} /></Form.Item>
                  </Col>
                </Row>
                {resposeCols2}
                {/* moq */}
                <Row>
                  <Col span={18} style={{ textAlign: 'right', fontWeight: 'bold', }}>
                    <Form.Item label={<FormattedMessage id='estimate.moq' />}
                      style={{ textAlign: 'left', fontWeight: 'bold', marginBottom: 12 }}
                      name='moq'
                      rules={[{ required: true, message: intl.formatMessage({ id: 'estimate.nullError' }, { name: intl.formatMessage({ id: 'estimate.moq' }) }) }, {
                        validator: (rule, value, callback) => {
                          const reg = /^[1-9]\d{0,5}$/;
                          if (value === null || value === undefined || value === '' || reg.test(value)) {
                            callback();
                          } else {
                            callback(intl.formatMessage({ id: 'message.positiveIntegerError' }, { a: '6' }));
                          }
                        }
                      }]}
                    >
                      <InputNumber disabled={props.enable} className={'number-input-overwrite'} max={999999} />
                    </Form.Item>
                  </Col>
                  <Col span={6}><FormattedMessage id='estimate.quantityUnit' /></Col>
                </Row>
                {/* mpq */}
                <Row>
                  <Col span={18} style={{ textAlign: 'right', fontWeight: 'bold', }}>
                    <Form.Item label={<FormattedMessage id='estimate.mpq' />}
                      style={{ textAlign: 'left', fontWeight: 'bold', marginBottom: 12 }}
                      name='mpq'
                      rules={[{ required: true, message: intl.formatMessage({ id: 'estimate.nullError' }, { name: intl.formatMessage({ id: 'estimate.mpq' }) }) }, {
                        validator: (rule, value, callback) => {
                          const reg = /^[1-9]\d{0,5}$/;
                          if (value === null || value === undefined || value === '' || reg.test(value)) {
                            callback();
                          } else {
                            callback(intl.formatMessage({ id: 'message.positiveIntegerError' }, { a: '6' }));
                          }
                        }
                      }]}>
                      <InputNumber disabled={props.enable} className={'number-input-overwrite'} max={999999} />
                    </Form.Item>
                  </Col>
                  <Col span={6}><FormattedMessage id='estimate.quantityUnit' /></Col>
                </Row>
                <Row>
                  <Col span={18} style={{ textAlign: 'right', fontWeight: 'bold', }}>
                    <Form.Item label={<FormattedMessage id='estimate.userName' />}
                      style={{ textAlign: 'left', fontWeight: 'bold', marginBottom: 12 }}
                      name='userName'
                    >
                      <Input disabled />
                    </Form.Item>
                  </Col>
                  <Col span={6}></Col>
                </Row>
                <Row>
                  <Col span={18} style={{ textAlign: 'right', fontWeight: 'bold', }}>
                    <Form.Item label={<FormattedMessage id='estimate.modifiedTime' />}
                      style={{ textAlign: 'left', fontWeight: 'bold', marginBottom: 12 }}
                      name='modifiedTime'
                    >
                      <DatePicker disabled style={{ width: '100%' }} />
                    </Form.Item>
                  </Col>
                  <Col span={6}></Col>
                </Row>
                <Row>
                  <Col span={18}>
                    <Form.Item label={<FormattedMessage id='estimate.upload' />}
                      style={{ textAlign: 'left', fontWeight: 'bold', marginBottom: 12 }}
                      name={'attachments'}
                    >
                      <CommonFileUpload  {...fileUploadProps} />
                    </Form.Item>
                  </Col>
                  <Col span={6}></Col>
                </Row>

              </Col>
            </Row>
          </Col>

        </Row>
        <Row style={{ marginTop: 20 }}>
          <Col span={4} offset={10}>
            <Row>
              <Col span={8} style={{ textAlign: 'right' }}>
                <Popconfirm
                  title={<FormattedMessage id='message.approveConfirm' />}
                  onConfirm={() => {
                    form.submit();
                  }}
                  okText={<FormattedMessage id='common.confirm' />}
                  cancelText={<FormattedMessage id='common.cancel' />}
                  disabled={props.enable}>
                  <Button htmlType="submit" disabled={props.enable}>
                    <FormattedMessage id='estimate.bidding' />
                  </Button>
                </Popconfirm>
              </Col>
              <Col span={8} offset={6} style={{ textAlign: 'left' }}>
                <Popconfirm
                  title={<FormattedMessage id='message.approveConfirm' />}
                  onConfirm={() => {
                    props.absenting();
                  }}
                  okText={<FormattedMessage id='common.confirm' />}
                  cancelText={<FormattedMessage id='common.cancel' />}
                  disabled={props.enable}>
                  <Button disabled={props.enable}>
                    <FormattedMessage id='estimate.decline' />
                  </Button>
                </Popconfirm>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </Drawer>
  );
}

