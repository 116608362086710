import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { locales } from '../locales';

import { Field, getByField } from './api';
import { selectFields, addFields } from './fieldsSlice'

const filedsWaitingList: string[] = [];

export const useFields = (field: string) => {

  const fields = useSelector(selectFields);
  const dispatch = useDispatch();
  const lang = locales.getLang();
  const key = `${field}/${lang}`;

  if (!fields[key] && !filedsWaitingList.includes(key)) {
    filedsWaitingList.push(key);

    getByField(field, lang)
      .then((data: Field[]) => {
        dispatch(addFields({ field: key, values: data }));

        remove(key);
      })
      .catch(() => {

        remove(key);
      });
  }

  return fields[key] || [];
}

function remove(key: string) {
  if (filedsWaitingList.includes(key)) {
    filedsWaitingList.splice(filedsWaitingList.indexOf(key), 1);
  }
}

