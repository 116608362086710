import React, { useEffect } from 'react';
import { FormattedMessage, } from 'react-intl';
import { Modal, Form, Select, Input, Button, } from 'antd';
import { filterUtil, } from '../util'
import { DatePicker, } from '../common'
import { Field, useFields } from '../fields';
import { DocSearchDto } from './api';
import './index.css';
import { useUser } from '../authorization';

const { RangePicker } = DatePicker;

export interface Props {
  categoryOptions: Field[];
  supplierOptions: Field[];
  buyerOptions: Field[];
  replyFlagOptions: Field[];
  visible: boolean;
  searchDto: DocSearchDto;
  close: () => void;
  handleSearch: (searchDto: DocSearchDto) => void;
}

export function DocSearch(props: Props) {

  return (
    <Modal
      title={<FormattedMessage tagName='h4' id='common.search' />}
      footer={null}
      visible={props.visible}
      closable={false}
      afterClose={props.close}
      destroyOnClose={true}
      width={560}>
      {props.visible && <DocSearchForm {...props} />}
    </Modal>
  );
}

const DocSearchForm = (props: Props) => {

  const [form] = Form.useForm<DocSearchDto>();
  const user = useUser();

  useEffect(() => {
    form.setFieldsValue(props.searchDto);
  });
  const categoryTitle = <FormattedMessage id='docInfo.documentCategory' />;
  const title1 = <FormattedMessage id='docInfo.title' />;
  const sendTime = <FormattedMessage id='docInfo.sendTime' />;
  const replyFlag = <FormattedMessage id='docInfo.replyFlag' />;
  const supplierTitle = <FormattedMessage id='acceptance.supplier' />;
  const supplierOptions = useFields('fullSupplier');
  return (
    <Form
      form={form}
      onFinish={props.handleSearch}
      layout={'horizontal'}
      colon={false}
      size={'large'}
      labelCol={{ span: 6 }}
      wrapperCol={{ span: 16 }}>
      <Form.Item name='documentCategory' label={categoryTitle}>
        <Select options={props.categoryOptions} allowClear />
      </Form.Item>
      <Form.Item name='suppliers' label={supplierTitle} >
        <Select<Field>
          mode={'multiple'}
          allowClear={true}
          className="select-mutil"
          autoClearSearchValue={false}
          filterOption={filterUtil.selectFilter}
          onSearch={filterUtil.onSearchForm(form, 'suppliers', supplierOptions)}
          loading={supplierOptions.length === 0}
          options={supplierOptions} />
      </Form.Item>
      <Form.Item name='title' label={title1} >
        <Input min={1} max={40} />
      </Form.Item>
      {
        user?.isSupplier &&
        <Form.Item name='replyFlag' label={replyFlag} >
          <Select options={props.replyFlagOptions} allowClear />
        </Form.Item>
      }
      <Form.Item name='sendTime' label={sendTime}>
        <RangePicker allowEmpty={[true, true]} order={true} style={{ width: '100%' }} />
      </Form.Item>
      <Form.Item style={{marginLeft: '20px'}}>
        <div className='search-button' style={{ paddingLeft: '20px' }}>
          <Button className='search-close-btn' onClick={props.close}>
            <FormattedMessage id='common.close' />
          </Button>
          <Button className='search-search-btn' htmlType='submit'>
            <FormattedMessage id='common.search' />
          </Button>
        </div>
      </Form.Item>
    </Form>
  );
}
