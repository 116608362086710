import React, {useState} from 'react';
import { Button, Row, Col, Checkbox } from 'antd';

import { userAgree } from './api';

import './header.css';
import './agreement.css';
import './siderMenu.css';
import './index.css'

export function SuppierCommnunityAgreement() {
  const [disabled, setDisabled] = useState<boolean>(true);


  // 用户点击同意协议的checkbox会改变进入系统按钮是否可用
  const clickAgreeCheckbox = () => {
    setDisabled(!disabled)
  }

  // 用户点击进入系统按钮做的操作
  const userAgreeAction = () => {

    // 向后台发送一条请求，记录用户同意了协议
    userAgree()
      .then((result: any) => {  // 请求成功的回调
        
        // get Element
        const routerBlur = document.getElementById('userAgreementBlur')
        const Agreement = document.getElementById('userAgreement')

        // 判定是否拿到了element
        if(routerBlur !== null && Agreement !== null){

          // 把毛玻璃的假router和用户协议窗口移除, 如果移除报错了就尝试隐藏
          try {
            routerBlur.remove();
            Agreement.remove();
          } catch {
            routerBlur.style.display = 'false'
            Agreement.style.display = 'false'
          }
        }
      });
  }

  return (
    <>
      <div className='agreementBox'>
        <span className='agreementMainTitle'>使用許諾について</span>
        
        <div className='agreementTextBox'>
          
          <span className='agreementSmallTitle'>第1条　使用許諾の適用</span>
            <p className='agreementText'>
              <ol>
                <li>i-PROソリューションズ株式会社(以下「i-PRO」といいます)は、このサプライヤコミュニティサービス使用許諾 (以下「使用許諾」といいます)を定め、この使用許諾に従い、本システムにおいてお客様に対し、サプライヤコミュニティサービスを提供します。</li>
                <li>利用者は、本約款の内容を十分に理解し、遵守することをi-PROに保証するものとします。</li>
                <li>i-PROは、本サービス提供にあたり、別途規約を定めることがあり、当該規約もこの使用許諾の一部をなすものとし、利用者はこれを遵守するものとします。</li>
              </ol>
            </p>
          
          <span className='agreementSmallTitle'>第2条　使用許諾の変更</span>
          <p className='agreementText'>
            i-PROは、利用者の承諾を得ることなく、この使用許諾を変更することがあります。
          </p>
          
          <span className='agreementSmallTitle'>第3条　協議事項</span>
          <p className='agreementText'>
            この使用許諾に記載のない実施上必要な細目については、お客様とi-PROの協議によって定めます。
          </p>
          
          <span className='agreementSmallTitle'>第4条　用語の定義</span>
          <p className='agreementTextLittleMargin'>
            この使用許諾において、次の用語は、それぞれ次の意味で使用するものとします。
          </p>
          <p className='agreementText'>
            <ol>
              <li>i-PROおよびi-PROが指定する関係会社の総称</li>
              <li>利用者は i-PROに対し利用申請を申し込みされてIDを付与された法人・特定の担当者並びにi-PRO従業員</li>
              <li>顧客設備は本サービスを利用するため、利用者が自らの資任と資用負担で準備し使用する。</li>
              <li>使用証明は本サービスの利用の際に、本サービスへの利用者や本件取引にかかる意思表示または通知の発信者がお客様本人であること等を証するために、i-PROが利用者に対して発行するID、パスワードにてログインすることで使用許諾を証明する。</li>
            </ol>
          </p>
          
          <span className='agreementSmallTitle'>第5条　本サ-ビスおよび付加サービスの内容</span>
          <p className='agreementTextLittleMargin'>
            本サービスの内容は以下の通りとします。
          </p>
          <p className='agreementText'>
            <ol>
              <li>
                <p className='agreementTextNoMargin'>納入回答</p>
                <p className='agreementTextLittleMargin'>本件取引データ作成、検索、照会</p>
              </li>
              <li>
                <p className='agreementTextNoMargin'>出荷処理</p>
                <p className='agreementTextLittleMargin'>出荷通知の作成、納品書作成</p>
              </li>
              <li>
                <p className='agreementTextNoMargin'>見積管理</p>
                <p className='agreementTextLittleMargin'>見積依頼作成、提供内容合意プロセス管理</p>
              </li>
              <li>
                <p className='agreementTextNoMargin'>金型有高確認</p>
                <p className='agreementTextLittleMargin'>お預け金型有高確認作成と棚上げ実査処理</p>
              </li>
              <li>
                <p className='agreementTextNoMargin'>所要計画管理</p>
                <p className='agreementTextLittleMargin'>所要データ検索、照会</p>
              </li>
              <li>
                <p className='agreementTextNoMargin'>検収管理</p>
                <p className='agreementTextLittleMargin'>検収データ通知、照会</p>
              </li>
              <li>
                <p className='agreementTextNoMargin'>支払管理</p>
                <p className='agreementTextLittleMargin'>月次経理支払確定金額通知</p>
              </li>
              <li>
                <p className='agreementTextNoMargin'>ドキュメント管理</p>
                <p className='agreementText'>事前協議、有事確認その他のデータ共有</p>
              </li>
            </ol>
          </p>
          
          <span className='agreementSmallTitle'>第6条　変更の届出</span>
          <p className='agreementText'>
            利用者は、申込書記載の事項に変更があった場合は、すみやかにi-PRO所定の届出書をi-PROに提出していただきます。
          </p>
          
          <span className='agreementSmallTitle'>第7条　費用負担</span>
          <p className='agreementText'>
            利用者はi-PROの本サービスの提供を受けるにあたっては、利用者の責任と費用負担にて、WEBクラウドにアクセスするために必要となるインターネット接続サービス等の通信サービス料金などの費用を負担していただきます。
          </p>
          
          <span className='agreementSmallTitle'>第8条　ID、パスワード証明書等</span>
          <p className='agreementText'>
            <ol>
              <li>)i-PROは、本システムの利用に必要ID、パスワードを個々の利用者に対して付与し、書面等により利用者に通知するものとします。</li>
              <li>利用者は、i-PROに対し、当該使用規約を承諾の上、遵守するものとします。</li>
              <li>ID、パスワードおよび証明書の管理(パスワードの変更を含みます)ならびに使用は利用者の資任とします。</li>
              <li>ID、パスワードを利用して本システムを利用したときは、利用者における業務として行われたとみなし、また、利用者以外の第三者が不正に使用して本システムを利用した場合は、当該利用者による本システムの利用が行われたものとみなし、これらにより発生する債務は、利用者が負うものとします。</li>
            </ol>
          </p>
          
          <span className='agreementSmallTitle'>第9条　禁止事項</span>
          <p className='agreementTextLittleMargin'>
            利用者は、次の行為を行わないものとします。
          </p>
          <p className='agreementText'>
            <ol>
              <li>ID、パスワードを第三者に開示、譲渡、貸与、送信、再使用許諾を行う行為</li>
              <li>第三者のID、パスワードを使用して本システムを利用する行為</li>
              <li>本サービスで提供される情報を、本件取引以外の目的で利用したり、第三者に利用させる行為</li>
            </ol>
          </p>
          
          <span className='agreementSmallTitle'>第10条　料金</span>
          <p className='agreementText'>
            本サ-ビスに対する対価として利用者にお支払いいただく料金(以下「料金」という)は、ありません。
          </p>
          
          <span className='agreementSmallTitle'>第11条　本システム障害時の措置</span>
          <p className='agreementText'>
            利用者は、本システムに障害が発生したことを発見したときは、i-PROに対し直ちにその旨通知し、i-PROは速やかに対応を図るものとし、その内容について利用者に通知するものとする。i-PROは、データのバックアップを行うなど自ら損害を発生させないよう合理的な努力を行うものとします。
          </p>
          
          <span className='agreementSmallTitle'>第12条　免實、損害賠償</span>
          <p className='agreementText'>
            i-PROは、本システムの障害(天災、地変その他不可抗力による障害を含む)および本システムの利用により発生した損害に対し、いかなる責任も負わないものとし、一切損害賠償する義務はないものとします。但し、故意または重過失による場合はこの限りではありません。
          </p>
          
          <p className='agreementTextLittleMargin'>
            この契約約款は、令和4年2月1日より効力を発するものとします。
          </p>

          <p className='agreementText'>
            以 上
          </p>
        </div>

        <Row className='agreementButtonBox'>
          <Col span={10} offset={5}>
            <Checkbox style={{marginTop: '5px', fontSize: 'calc(calc(1vw + 1vh) / 1.6)'}} onChange={clickAgreeCheckbox}>
              上記の使用許諾に同意します
            </Checkbox>
          </Col>
          <Col span={6} offset={0}>
            <Button disabled={disabled} onClick = {userAgreeAction}>
              システムへ
            </Button>
          </Col>
        </Row>     
      </div>
    </>
  )
}