import { Dayjs } from 'dayjs';
import { DateRange, NumberRange, } from '../common';
import { api, PagingSearchParaVo, CommonListVo, SearchParaPropertis, converter, } from '../api'
const dayjs = require('dayjs');
// アクセスURI
const uri = '/api/payments';
// ページ毎行数
const rowCount = 10;
// ソート順項目定義、repositoryと一致
const orderByColumns = [
  // 支払コード
  "paymentId",
  // 仕入先コード
  "supplier",
  // 受注者名称
  "supplierName",
  //取引年月
  "acquisitionDate",
  // 支払日
  "paymentDate",
  // 既読フラグ
  "readFlag",
  // 発行日
  "dateOfIssue",
  // 支払合計金額
  "totalPaymentAmount",
  // 前月支払修正額(合計)
  "lastMonthPayAdjustAmountAll",
  // 仕入代金(合計)
  "purchasePriceAll",
  // 売掛金相殺(合計)
  "accountsReceivableOffset",
  // その他相殺(合計)
  "otherOffset",
  // 支払修正額(合計)
  "payAdjustAmountAll",
  // 差引お支払金額
  "balanceAmountPay"
];

// 検索条件Dtoの項目一覧
const properties: SearchParaPropertis = {
  // 仕入先コード
  suppliers:'array',
  // 支払コード
  paymentId: 'string',
  // 受注者名称
  supplierName: 'string',
  // 取引年月
  acquisitionDate: 'DateRange',
  // 支払日
  paymentDate: 'DateRange',
  // 既読フラグ
  readFlag: 'array',
  // 発行日
  dateOfIssue: 'DateRange',
  // 支払合計金額
  totalPaymentAmount: 'NumberRange',
  // 前月支払修正額(合計)
  lastMonthPayAdjustAmountAll: 'NumberRange',
  // 仕入代金(合計)
  purchasePriceAll: 'NumberRange',
  // 売掛金相殺(合計)
  accountsReceivableOffset: 'NumberRange',
  // その他相殺(合計)
  otherOffset: 'NumberRange',
  // 支払修正額(合計)
  payAdjustAmountAll: 'NumberRange',
  // 差引お支払金額
  balanceAmountPay: 'NumberRange'
};

// 支払一覧VO
interface PayListVo {
  // ID
  id:number,
  // 支払コード
  paymentId:string,
  // 仕入先コード
  supplier:string,
  // 受注者名称
  supplierName:string,
  // 発行日
  dateOfIssue:number,
  // 発注者コード
  buyer:string,
  // 発注者名称
  buyerName:string,
  // 支払金額合計
  totalPaymentAmount:number,
  // 通貨コード
  currencyCode:string,
  // 支払日
  paymentDate:number,
  // 前月支払修正額
  lastMonthPayAdjustAmountAll:number,
  // 仕入代金
  purchasePriceAll:number,
  // 売掛金相殺
  accountsReceivableOffset:number,
  // その他相殺
  otherOffset:number,
  // 支払修正額
  payAdjustAmountAll:number,
  // 差引お支払金額
  balanceAmountPay:number,
  // 既読フラグ
  readFlag: string
  // 都度支払フラグ
  paymentUponEachUseFlag: string;
}

// 支払詳細VO
export interface PayDetailVo {
  // ID
  id:number,
  // 支払コード
  paymentId:string,
  // 取引年月
  acquisitionDate:number,
  // 仕入先コード
  supplier:string,
  // 支払日
  paymentDate:number,
  // インボイス番号
  invoiceNo:string,
  // 適格請求書発行事業者登録番号
  qualifiedInvoiceIssuerRegistrationNumber: string,
  // 郵便番号
  zipCode:string,
  // 受注者住所
  supplierAddress:string,
  // 受注者名称
  supplierName:string,
  // FAX
  fax:string,
  // 発行日
  dateOfIssue:number,
  // 発注者コード
  buyer:string,
  // 発注者名称
  buyerName:string,
  // 発注者部署名
  departmentName:string,
  // 通貨コード
  currencyCode:string,
  // 支払金額合計
  totalPaymentAmount:number,
  // 既読フラグ
  readFlag: string
  // 注記1-1
  note1_1: string,
  // 注記1-2
  note1_2: string,
  // 注記2
  note2: string,
  // 注記3
  note3: string,
  // 注記4
  note4: string,
  // 注記5
  note5: string,
  // 注記6
  note6: string,
  // 注記7
  note7: string,
  // 注記8
  note8: string,
  // 注記9
  note9: string,
  // 支払金額詳細情報
  paymentDetailTableVos ?: PaymentDetailTableVo[],
  // 都度支払フラグ
  paymentUponEachUseFlag: string,
}
//支払費用明細
export interface PaymentDetailTableVo {
  // 税率テキスト
  taxRate:string,
  // 項目
  items:string,
  // 前月支払修正額
  lastMonthPayAdjustAmount:number,
  // 仕入代金
  purchasePrice:number,
  // 売掛金相殺
  accountsReceivableOffset:number,
  // その他相殺
  otherOffset:number,
  // 支払修正額
  payAdjustAmount:number,
  // 差引お支払金額
  balanceAmountPay:number 
  // 差引お支払金額
  currencyCode:string,
}
// 支払検索条件Dto
export interface PaySearchDto extends PagingSearchParaVo {
  // 支払コード
  paymentId?: string,
  // 仕入先コード
  suppliers: string[],
  // 受注者名称
  supplierName?: string,
  // 取引年月
  acquisitionDate: DateRange,
  // 支払日
  paymentDate: DateRange,
  // 既読フラグ
  readFlag: string[]
  // 发行日
  dateOfIssue: DateRange,
  // 支払合計金額
  totalPaymentAmount: NumberRange,
  // 前月支払修正額(合計)
  lastMonthPayAdjustAmountAll: NumberRange,
  // 仕入代金(合計)
  purchasePriceAll: NumberRange,
  // 売掛金相殺(合計)
  accountsReceivableOffset: NumberRange,
  // その他相殺(合計)
  otherOffset: NumberRange,
  // 支払修正額(合計)
  payAdjustAmountAll: NumberRange,
  // 差引お支払金額
  balanceAmountPay: NumberRange
}
//支払一覧DTO
export interface PayListDto {
  // ID
  id:number,
  // 支払コード
  paymentId:string,
  // 仕入先コード
  supplier:string,
  // 受注者名称
  supplierName:string,
  // 発行日
  dateOfIssue:Dayjs,
  // 発注者コード
  buyer:string,
  // 発注者名称
  buyerName:string,
  // 支払金額合計
  totalPaymentAmount:number,
  // 通貨コード
  currencyCode:string,
  // 支払日
  paymentDate:Dayjs,
  // 前月支払修正額
  lastMonthPayAdjustAmountAll:number,
  // 仕入代金
  purchasePriceAll:number,
  // 売掛金相殺
  accountsReceivableOffset:number,
  // その他相殺
  otherOffset:number,
  // 支払修正額
  payAdjustAmountAll:number,
  // 差引お支払金額
  balanceAmountPay:number,
  // 既読フラグ
  readFlag: string,
  // 都度支払フラグ
  paymentUponEachUseFlag: string,
  
}
//支払詳細DTO
export interface PayDetailDto {
  // ID
  id:number,
  // 支払コード
  paymentId:string,
  // 取引年月
  acquisitionDate:Dayjs,
  // 仕入先コード
  supplier:string,
  // 支払日
  paymentDate:Dayjs,
  // インボイス番号
  invoiceNo:string,
  // 適格請求書発行事業者登録番号
  qualifiedInvoiceIssuerRegistrationNumber: string,
  // 郵便番号
  zipCode:string,
  // 受注者住所
  supplierAddress:string,
  // 受注者名称
  supplierName:string,
  // FAX
  fax:string,
  // 発行日
  dateOfIssue:Dayjs,
  // 発注者コード
  buyer:string,
  // 発注者名称
  buyerName:string,
  // 発注者部署名
  departmentName:string,
  // 通貨コード
  currencyCode:string,
  // 支払金額合計
  totalPaymentAmount:number,
  // 既読フラグ
  readFlag: string,
  // 注記1-1
  note1_1: string,
  // 注記1-2
  note1_2: string,
  // 注記2
  note2: string,
  // 注記3
  note3: string,
  // 注記4
  note4: string,
  // 注記5
  note5: string,
  // 注記6
  note6: string,
  // 注記7
  note7: string,
  // 注記8
  note8: string,
  // 注記9
  note9: string,
  // 支払金額詳細情報
  paymentDetailTableVos ?: PaymentDetailTableVo[],
  // 都度支払フラグ
  paymentUponEachUseFlag: string,

}

const listVo2Dto = (vo: PayListVo): PayListDto => {
  return {
    ...vo,
    paymentDate: dayjs(vo.paymentDate),
    dateOfIssue: dayjs(vo.dateOfIssue)
  } as PayListDto;
};

export const detailVo2Dto = (vo: PayDetailVo): PayDetailDto => {
  return {
    ...vo,
    dateOfIssue: dayjs(vo.dateOfIssue),
    paymentDate: dayjs(vo.paymentDate),
    acquisitionDate: dayjs(vo.acquisitionDate),
  } as PayDetailDto;
};
//支払一覧情報取得
export const get = (query: string): Promise<[number, PayListDto[]]> => {
  if (!query || query.length === 0) {
    query = `?rowCount=${rowCount}`;
  } else {
    query += `&rowCount=${rowCount}`;
  }
  return api.get(`${uri}${query}`)
    .then((result: any) => {

      const data = result as CommonListVo<PayListVo>;
      return [data.total, data.vos.map(listVo2Dto)];
    });
};
//支払詳細情報取得
export const getById = (urlId: string): Promise<PayDetailDto> => {
  return api.get(`${uri}/${urlId}`)
    .then((result: any) => {
      const data = result as PayDetailVo;
      return detailVo2Dto(data);
    });
};

// searchから検索条件Dtoへ変換する
export const search2Dto = (query?: string): PaySearchDto => {
  return converter.search2Dto(properties, orderByColumns, query) as PaySearchDto;
};

// 検索条件Dtoからsearchへ変換する
export const dto2search = (researchDto: PaySearchDto): string => {

  if(typeof(researchDto.readFlag)=='string'){
    researchDto.readFlag = [researchDto.readFlag]
  }
  return converter.dto2search(properties, orderByColumns, researchDto);
};