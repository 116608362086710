import React from 'react'
import { FormattedMessage } from 'react-intl'

import { Button, Col, Drawer, Popconfirm, Row, Table } from 'antd';
import { FileExcelOutlined } from '@ant-design/icons';
import { ColumnsType } from 'antd/lib/table';

import { Detail } from '../common';
import { useFields } from '../fields';
import { displayUtil } from '../util'
import { InventoryDto, InventoryDetailDto } from './api'
import { useUser } from '../authorization';
import './index.css';

export interface Props {
    visible: boolean;
    detail?: InventoryDto;
    close: () => void;
    download: (id?: number) => void;
    updateStatus: (status: string) => void;
}

// buyer 的 有高確認情報 页
export function InventoryDetail(props: Props) {

    const supplierOptions = useFields('supplier');
    const statusOptions = useFields('moldStatus');

    const user = useUser();
    // inventory
    const inventoryCols: [JSX.Element, any][] = [
        // 确认状态
        [<FormattedMessage id='inventory.status' />, displayUtil.field(statusOptions)(props.detail?.status)],
        // 有高確認依頼番号
        [<FormattedMessage id='inventory.inventoryRequestNo' />, props.detail?.inventoryRequestNo],
        // 有高確認依頼日
        [<FormattedMessage id='inventory.requestDate' />, displayUtil.date(props.detail?.requestDate)],
        // 回答希望日
        [<FormattedMessage id='inventory.answerDeadline' />, displayUtil.date(props.detail?.answerDeadline)],
        // 一次預け先コード
        [<FormattedMessage id='model.primaryLender' />, props.detail?.primaryLender],
        // 一次預け先名称
        [<FormattedMessage id='model.primaryLenderName' />, displayUtil.field(supplierOptions)(props.detail?.primaryLender)],
        // 有高確認依頼者
        [<FormattedMessage id='inventory.requester' />, props.detail?.userName1],
        // 依頼日
        [<FormattedMessage id='inventory.requestDate2' />, displayUtil.date(props.detail?.modifiedTime)]
    ];

    const modifiedCols: [JSX.Element, any][] = [
        // 返信担当
        [<FormattedMessage id='inventory.responseUser' />, props.detail?.userName2],
        // 返信日時
        [<FormattedMessage id='inventory.responseTime' />, displayUtil.locationDateTime(props.detail?.responseTime, user?.serviceState)]
    ];

    const confirmationCols: [JSX.Element, any][] = [
        // 確認担当
        [<FormattedMessage id='inventory.confirmationUser' />, props.detail?.userName3],
        // 確認時間
        [<FormattedMessage id='inventory.confirmationTime' />, displayUtil.locationDateTime(props.detail?.confirmationTime, user?.serviceState)]
    ];

    // 資産番号
    const assetNoTitle = <FormattedMessage id='model.assetNo' />
    // 補助番号
    const auxiliaryNoTitle = <FormattedMessage id='model.auxiliaryNo' />
    // 金型品番
    const moldNoTitle = <FormattedMessage id='model.moldNo' />
    // 最終預け先名称
    const finalLenderNameTitle = <FormattedMessage id='model.finalLenderName' />
    // 面数
    const moldsNumTitle = <FormattedMessage id='model.moldsNum' />
    // 実面数
    const actualMoldsNumTitle = <FormattedMessage id='inventory.actualMoldsNum' />
    // 特記事項
    const remarksTitle = <FormattedMessage id='inventory.remarks' />
    // 
    const detailListTitle = <FormattedMessage id='inventory.detailList' />
    

    const width100 = '100px';
    const width110 = '110px';
    const widht160 = '160px';
    const width200 = '200px';
    const width270 = '270px';
    const width280 = '280px';
    // inventory detail cols ColumnsTypeInventoryDetailDto
    const detailColumns: ColumnsType<InventoryDetailDto> = [
        // 資産番号
        { title: assetNoTitle,width: widht160,dataIndex: 'assetNo'},
        // 補助番号
        { title: auxiliaryNoTitle,width: width110,dataIndex: 'auxiliaryNo'},
        // 金型品番
        {title: moldNoTitle,width: width270,dataIndex: 'moldNo'},
        // 最終預け先名称
        {title: finalLenderNameTitle,width: width200,dataIndex: 'finalLenderName'},
        // 面数
        {title: moldsNumTitle,width: width100,dataIndex: 'moldsNum'},
        // 実面数
        {title: actualMoldsNumTitle,width: width100,dataIndex: 'actualMoldsNum'},
        // 特記事項
        {title: remarksTitle,width: width280,dataIndex: 'remarks'},
    ]

    const title = (
        <>
            <FormattedMessage id='inventory.detail' />
            <Button style={{ marginLeft: '20px'}} onClick={() => props.download(props.detail?.id)}>
                <FileExcelOutlined />
                <FormattedMessage id='common.download' />
            </Button>
        </>
    )

    const rowHeightLightStyle = (record?: any, index?: number) => {
        
        if(props.detail && props.detail.status === '002'){
            return displayUtil.rowClassInventoryHightLight(record,index)
        } else {
            return displayUtil.rowClassName(record,index)
        }
    };

    return (
        <Drawer
            title={title}
            width='1180px'
            footer={null}
            visible={props.visible}
            bodyStyle={{ paddingBottom: 80 }}
            destroyOnClose={true}
            onClose={props.close}
        >
            <Detail
                title={<FormattedMessage id='inventory.head' />}
                cols={inventoryCols}
            />

            <p style={{ 
                    fontSize: 16, 
                    fontWeight: 500, 
                    marginBottom: 0, 
                    marginTop: 10, 
                    paddingLeft: "25px", 
                    height: "35px", 
                    borderBottom: '1px solid #f0f0f0' 
                }}>
                {detailListTitle}
            </p>
            {<Table<InventoryDetailDto>
                className="list-detail-table inventory-detail-table"
                columns={detailColumns}
                dataSource={props.detail?.details}
                bordered={false}
                rowClassName={rowHeightLightStyle}
                rowKey={'id'}
                scroll={{y: 350}}
                tableLayout={'fixed'}
                pagination={false}
                // style={{ transform: 'translateY(-30px)'}}
            />}
            
            <Detail cols={modifiedCols}/>
            <Detail cols={confirmationCols}/>
            {props.detail?.status === "002" &&
                <Row className="drawer-row" >
                    <Col span={22} style={{ textAlign: 'right' }}>
                        <Popconfirm title={<FormattedMessage id='message.editConfirm' />}
                            onConfirm={() => props.updateStatus("3")}
                            okText={<FormattedMessage id='common.save' />}
                            cancelText={<FormattedMessage id='common.cancel' />} >
                            <Button type="primary"  htmlType="submit" >
                                <FormattedMessage id='common.confirm' />
                            </Button>
                        </Popconfirm>
                    </Col>
                </Row>
            }
        </Drawer >
    )
}
