import { Dayjs } from 'dayjs';
import { api, CommonListVo, converter, PagingSearchParaVo, SearchParaPropertis } from '../api';
import { Attachment, DateRange } from '../common';
import { locales } from '../locales';

const dayjs = require('dayjs');

// アクセスURI
const uri = '/api/estimations/requests'
const urise = '/api/estimations/responses'
//*** １．検索画面用 ***
const rowCount = 10;

// ソート順項目定義、repositoryと一致（ZhiTong.Bian@ibm.com 2021/11/30: 最重要的是要与后端的ORDER_BY_CLOUMNS常量对应）
const orderByColumns = [
    // 入札依頼番号
    "requestNo",
    // 発注部門
    "buyerDepartmentCode",
    // 購買担当
    "purchasingManagerName",
    // 見積タイプ
    "estimatesType",
    // 品番
    "item",
    // 品名
    "itemName",
    // 見積依頼日
    "requestDate",
    // 回答希望日
    "answerDeadline",
    // 案件状態
    "status",
];

// ソート順項目定義、repositoryと一致
const orderByColumns2 = [
    // 回复状態
    "responseStatus",
    // 入札依頼番号
    "requestNo",
    // 品番
    "item",
    // 品名
    "itemName",
    // 見積依頼日
    "requestDate",
    // 回答希望日
    "answerDeadline",
    // サプライヤ
    "supplier",
    // 案件状態
    "status"
];

// 検索条件Dtoの項目一覧
const properties: SearchParaPropertis = {
    // 入札依頼番号
    requestNo: 'string',
    // 発注部門
    buyerDepartments: 'array',
    // 購買担当
    purchasingManagerName: 'string',
    // 品番
    item: 'array',
    // 品名
    itemName: 'string',
    // 見積依頼日
    requestDate: 'DateRange',
    // 回答希望日
    answerDeadline: 'DateRange',
    // 案件状態
    status: 'array',

    estimatesType: 'array',

    responseStatus: 'array',

    suppliers:'array'
};

//検索条件DTO
export interface EstimateSearchDto extends PagingSearchParaVo {
    // 入札依頼番号
    requestNo?: string,
    // 発注部門
    buyerDepartments: string[],
    // 購買担当
    purchasingManagerName?: string,
    // 品番
    item: string[],
    // 品名
    itemName?: string,
    // 見積依頼日
    requestDate: DateRange,
    // 回答希望日
    answerDeadline: DateRange,
    // 案件状態
    status: string[],
    // response状態
    responseStatus: string[],
    // 見積タイプ
    estimatesType: string[],
    //サプライヤー
    suppliers:string[]
};

// searchから検索条件Dtoへ変換する
export const convertSearchDto = (query?: string): EstimateSearchDto => {
    return converter.search2Dto(properties, orderByColumns, query) as EstimateSearchDto;
};
// // searchから検索条件Dtoへ変換する
export const convertSearch2Dto = (query?: string): EstimateSearchDto => {
    return converter.search2Dto(properties, orderByColumns2, query) as EstimateSearchDto;
};

// 検索条件Dtoからsearchへ変換する
export const dto2search = (researchDto: any): string => {
    return converter.dto2search(properties, orderByColumns, researchDto);
};

// 検索条件Dtoからsearchへ変換する
export const supplierDto2search = (researchDto: any): string => {
    return converter.dto2search(properties, orderByColumns2, researchDto);
};

//*** ２．一覧画面用 （i-pro用）***
//検索一覧DTO（i-pro用）
export interface EstimateRequestListDto extends EstimateRequestDto {
    // 依頼
    requestCount: string;
    // 回答
    responseCount: string;
    // 発注者名称
    buyerName: string;
    //読み取りフラグ
    unReadFlag: boolean;

};

//検索一覧Vo（i-pro用）
interface EstimateRequestListVo extends EstimateRequestVo {
    // 依頼
    requestCount: string;
    // 回答
    responseCount: string;
    // 発注者名称
    buyerName: string;
    // 読み取りフラグ
    unReadFlag: boolean;

};

//検索URL取得処理（i-pro用）
export const get = (query: string): Promise<[number, EstimateRequestListDto[]]> => {
    if (!query || query.length === 0) {
        query = `?rowCount=${rowCount}`;
    } else if(query.indexOf('rowCount') === -1) {
        query += `&rowCount=${rowCount}`;
    }
    return api.get(`${uri}${query}`)
        .then((result: any) => {
            const data = result as CommonListVo<EstimateRequestListVo>;
            return [data.total, data.vos.map(vo2ListRecordDto)];
        });
};

//検索URL取得処理（i-pro用）
export const getAll = (query: string): Promise<void | downloadPassedDto[]> => {
    if (!query || query.length === 0) {
        query = `?rowCount=${rowCount}`;
    } else if(query.indexOf('rowCount') === -1) {
        query += `&rowCount=${rowCount}`;
    }
    return api.get(`${uri}${query}`)
        .then((result: any) => {
            const data = result as CommonListVo<EstimateRequestListVo>;
            let asyncFun : Promise<EstimateRequestDetailListDto>[] = []
            data.vos.forEach(async list => {
                asyncFun.push(getById(list.id.toString()))
            })
            return Promise.all(asyncFun).then(suppliersDatas => {
                let downloadDataList : downloadPassedDto[] = []
                data.vos.forEach( list => {
                    let rightData = suppliersDatas.filter(supplierData => supplierData.id === list.id)
                    rightData[0].suppliers?.forEach(supplier => {
                        let downloadData : downloadPassedDto = {
                                            ...list,
                                            requestDate: dayjs(list.requestDate),
                                            answerDeadline: dayjs(list.answerDeadline),
                                            ...supplier
                                        }
                        downloadDataList.push(downloadData)
                    })
                })
                return downloadDataList
            })
        });
};
//検索一覧List（vo⇒dto）（i-pro用）
const vo2ListRecordDto = (vo: EstimateRequestListVo): EstimateRequestListDto => {
    return {
        ...vo,
        requestNo: vo.requestNo,
        requestDate: vo.requestDate === 0 ? undefined : dayjs(vo.requestDate),
        answerDeadline: vo.answerDeadline === 0 ? undefined : dayjs(vo.answerDeadline),
        modifiedTime: vo.modifiedTime === 0 ? undefined : dayjs(vo.modifiedTime)
    } as EstimateRequestListDto;
};

//*** ３．一覧画面用（サプライヤー用） ***
//検索一覧DTO（サプライヤー用）
export interface EstimateRequestSupplierResponseListDto {
    // 見積依頼キー 
    id: number;
    // 回答状態 
    responseStatus: string;
    // 案件状態
    status: string;
    // 入札依頼番号 
    requestNo: string;
    // 依頼日 
    requestDate: Dayjs;
    // 回答希望日 
    answerDeadline: Dayjs;
    // 品番 
    item: string;
    // 品名 
    itemName: string;
    // 見積有効日 */
    estimatedEffectiveDate: Dayjs;
    // 希望単価 
    unitPrice: number;
    // 希望単価 
    unitCurrency: string;
    // 最低発注数量 */
    moq: number;
    // 最小梱包数量 */
    mpq: number;
    // 見積適用開始日 
    estimateStartDate: Dayjs;
    // 見積依頼先ID 
    requestId: number;
    // 発注者名称
    purchasingManagerName: string;
    // サプライヤ
    supplier:String;

};
//検索一覧Vo（サプライヤー用）
interface EstimateRequestSupplierResponseListVo {
    // 見積依頼キー 
    id: number;
    // 回答状態 
    responseStatus: string;
    // 案件状態
    status: string;
    // 入札依頼番号 
    requestNo: string;
    // 依頼日 
    requestDate: number;
    // 回答希望日 
    answerDeadline: number;
    // 品番 
    item: string;
    // 品名 
    itemName: string;
    // 見積有効日 */
    estimatedEffectiveDate: number;
    // 希望単価 
    unitPrice: number;
    // 希望単価 
    unitCurrency: string;
    // 最低発注数量 */
    moq: number;
    // 最小梱包数量 */
    mpq: number;
    // 見積適用開始日 
    estimateStartDate: number;
    // 見積依頼先ID 
    requestId: number;
    // 発注者名称
    purchasingManagerName: string;
    // サプライヤ
    supplier:string;
};
//検索URL取得処理（サプライヤー用）
export const getSupplier = (query: string): Promise<[number, EstimateRequestSupplierResponseListDto[]]> => {
    if (!query || query.length === 0) {
        query = `?rowCount=${rowCount}`;
    } else if(query.indexOf('rowCount') === -1) {
        query += `&rowCount=${rowCount}`;
    }
    return api.get(`${uri}/suppliers${query}`)
        .then((result: any) => {
            const data = result as CommonListVo<EstimateRequestSupplierResponseListVo>;
            return [data.total, data.vos.map(supplierVo2Dto)];
        });
};
//検索一覧List（vo⇒dto）（サプライヤー用）
const supplierVo2Dto = (vo: EstimateRequestSupplierResponseListVo): EstimateRequestSupplierResponseListDto => {
    return {
        ...vo,
        requestDate: vo.requestDate === 0 ? undefined : dayjs(vo.requestDate),
        answerDeadline: vo.answerDeadline === 0 ? undefined : dayjs(vo.answerDeadline),
        estimateStartDate: vo.estimateStartDate === 0 ? undefined : dayjs(vo.estimateStartDate),
        estimatedEffectiveDate: vo.estimatedEffectiveDate === 0 ? undefined : dayjs(vo.estimatedEffectiveDate)
    } as EstimateRequestSupplierResponseListDto;
};

//*** ４．見積依頼詳画面 ***

//見積依頼詳細Dto
export interface EstimateRequestDetailListDto extends EstimateRequestDto {
    // 見積回答一覧    　　
    estimateReponseDtos?: EstimateReponseDto[]
}

//見積依頼,回答一覧詳細取得
export const getById = (urlId?: string, subRulId?: string): Promise<EstimateRequestDetailListDto> => {
    return api.get(`${uri}/detail/list/${urlId}`)
        .then((result: any) => {
            const data = result as EstimateRequestDetailListVo;
            return estimateDetilVo2Dto(data);
        });
};

export const requestFinish = (dto: EstimateRequestDetailListDto): Promise<EstimateRequestDetailListDto> => {
    return api.merge(`${uri}/finish/`, dto.id, estimateDetilDto2Vo(dto)).then((result: EstimateRequestDetailListDto) => {
        return result;
    });
};

export const requestsCancel = (dto: EstimateRequestDetailListDto): Promise<EstimateRequestDetailListDto> => {
    return api.merge(`${uri}/cancel/`, dto.id, estimateDetilDto2Vo(dto)).then((result: EstimateRequestDetailListDto) => {
        return result;
    });
};

//見積依頼,回答一覧詳細（vo⇒dto）
const estimateDetilVo2Dto = (vo: EstimateRequestDetailListVo): EstimateRequestDetailListDto => {
    return {
        ...vo,
        requestDate: vo.requestDate === 0 ? undefined : dayjs(vo.requestDate),
        answerDeadline: vo.answerDeadline === 0 ? undefined : dayjs(vo.answerDeadline),
        estimateReponseDtos: vo.suppliers?.map(v => estimateReponseVo2Dto(v, vo.id)),
        modifiedTime: vo.modifiedTime === 0 ? undefined : dayjs(vo.modifiedTime)
    } as EstimateRequestDetailListDto;
};
//見積回答詳細（vo⇒dto）
const estimateReponseVo2Dto = (vo: EstiamteRespondentsResponseVo, id: number): EstimateReponseDto => {

    return {
        ...vo,
        //見積有効日
        estimatedEffectiveDate: vo.estimatedEffectiveDate === 0 ? undefined : dayjs(vo.estimatedEffectiveDate),
        //見積適用開始日
        estimateStartDate: vo.estimateStartDate === 0 ? undefined : dayjs(vo.estimateStartDate),
        //見積適用終了日
        estimateEndDate: vo.estimateEndDate === 0 ? undefined : dayjs(vo.estimateEndDate),
        //見積依頼キー
        requestId: id,
        modifiedTime: vo.modifiedTime === 0 ? undefined : dayjs(vo.modifiedTime)
    };
};

const estimateDetilDto2Vo = (dto: EstimateRequestDetailListDto): EstimateRequestDetailListVo => {
    return {
        ...dto,
        requestDate: dayjs(dto.requestDate).valueOf(),
        answerDeadline: dayjs(dto.answerDeadline).valueOf(),
        suppliers: dto.estimateReponseDtos?.map(s => estimateReponseDto2Vo(s)),
        modifiedTime: dayjs(dto.modifiedTime).valueOf()
    } as EstimateRequestDetailListVo;
};

//見積回答詳細（vo⇒dto）
const estimateReponseDto2Vo = (dto: EstimateReponseDto): EstiamteRespondentsResponseVo => {
    let reponseAttachments: Attachment[] = [];
    if (!!dto.reponseAttachments) {
        reponseAttachments = dto.reponseAttachments
    }

    return {
        ...dto,
        //見積有効日
        estimatedEffectiveDate: dayjs(dto.estimatedEffectiveDate).valueOf(),
        //見積適用開始日
        estimateStartDate: dayjs(dto.estimateStartDate).valueOf(),
        //見積適用終了日
        estimateEndDate: dayjs(dto.estimateEndDate).valueOf(),
        //見積依頼キー
        requestId: dto.requestId,
        attachments: reponseAttachments,
        lang: '',
        modifiedTime: dayjs(dto.modifiedTime).valueOf()
    };
};
//５．見積編集画面
//見積編集画面VO
export interface EstimateRequestUpdateVo extends EstimateRequestVo {

    supplierName: string[];
    //添付ファイル
    attachments: Attachment[];

    lang: string;

    //見積依頼
    suppliers?: EditRespondentDto[];
}

export interface EstimateRequestUpdateDto extends EstimateRequestDto {

    supplierName: string[];
    //添付ファイル
    attachments: Attachment[];

    lang: string;

    suppliers?: EditRespondentDto[];
}

const formDto2Vo = (dto: EstimateRequestUpdateDto): EstimateRequestUpdateVo => {
    return {
        ...dto,
        requestDate: dayjs(dto.requestDate).valueOf(),
        answerDeadline: dayjs(dto.answerDeadline).valueOf(),
        modifiedTime: dayjs(dto.modifiedTime).valueOf()
    };
};

//获取编辑見積依頼页面
export const getEstiamteDetail = (urlId: string): Promise<EstimateRequestUpdateDto> => {
    //获取语言
    const lang = locales.getLang();
    return api.get(`${uri}/detail/edit?id=${urlId}&languageCd=${lang}`)
        .then((result: any) => {
            const data = result as EstimateRequestUpdateVo;
            return vo2formDto(data);
        });
}
//修改依赖信息
export const estimateModify = (data: EstimateRequestUpdateDto): Promise<EstimateRequestUpdateDto> => {
    data.lang = locales.getLang();
    return api.merge(`${uri}/modify/`, data.id, formDto2Vo(data)).then((result: EstimateRequestUpdateVo) => {
        return vo2formDto(result);
    });
}

export const estimateCreate = (data: EstimateRequestUpdateDto): Promise<[number, EstimateRequestListDto[]]> => {
    data.lang = locales.getLang();
    return api.persist(`${uri}/add`, formDto2Vo(data)).then((result: any) => {
        const data = result as CommonListVo<EstimateRequestListVo>;
        return [data.total, data.vos.map(vo2ListRecordDto)]
    });
}
const vo2formDto = (form: EstimateRequestUpdateVo): EstimateRequestUpdateDto => {
    return {
        ...form,
        requestDate: form.requestDate === 0 ? undefined : dayjs(form.requestDate),
        answerDeadline: form.answerDeadline === 0 ? undefined : dayjs(form.answerDeadline),
        modifiedTime: form.modifiedTime === 0 ? undefined : dayjs(form.modifiedTime),

    };
};

// 99. 共通：interface定義
//見積依頼先情報
export interface RespondentVo {
    // ID
    id: number;
    //サプライヤー
    supplier: string;
    //回答状態
    status: string;
    //バージョン
    version: number;
    //添付ファイル
    stimateRequestAttachments?: Attachment[];
}

//見積依頼編集画面
export interface EditRespondentVo {
    // ID
    id: number;
    //見積依頼ID
    requestId: number;
    //サプライヤー
    supplier: string;
    //回答状態
    responseStatus: string;
    //バージョン
    version: number;
}

//見積依頼編集画面
export interface EditRespondentDto {
    // ID
    id: number;
    //見積依頼ID
    requestId: number;
    //サプライヤー
    supplier: string;
    //回答状態
    responseStatus: string;
    //バージョン
    version: number;
}

//見積依頼 VO
interface EstimateRequestVo {
    // ID
    id: number
    // 入札依頼番号
    requestNo: string,
    // 案件状態
    status: string,
    // 見積タイプ
    estimatesType: string,
    // 購入元コード
    buyer: string,
    // 発注部門
    buyerDepartmentCode: string,
    // 購買担当
    purchasingManagerName: string,
    // 見積依頼日
    requestDate: number,
    // 回答希望日
    answerDeadline: number,
    // 品番
    item: string,
    //品名
    itemName: string,
    // 希望単価
    desireUnitPrice: number,
    // 希望単価通貨コード
    desireCurrency: string,
    // 企画月数
    planningMonths: number,
    // 企画数量
    planQuantity: number,
    // 月発注予定数
    monthlyPlanOrderQuantity: number;
    // 受渡場所
    deliveryLocation: string,
    // 備考
    remarks: string,
    //添付ファイル
    attachments?: Attachment[];
    // バージョン
    version: number;
    //ユーザーID
    userName: string;
    //更新日時
    modifiedTime: number;
}
//見積依頼 DTO
interface EstimateRequestDto {
    // ID
    id: number
    // 入札依頼番号
    requestNo: string,
    // 見積タイプ
    estimatesType: string,
    // 案件状態
    status: string,
    // 購買会社
    buyer: string,
    // 発注部門
    buyerDepartmentCode: string,
    // 購買担当
    purchasingManagerName: string,
    // 見積依頼日
    requestDate: Dayjs,
    // 回答希望日
    answerDeadline: Dayjs,
    // 品番
    item: string,
    //品名
    itemName: string,
    // 希望単価
    desireUnitPrice: number,
    // 希望単価通貨コード
    desireCurrency: string,
    // 企画月数
    planningMonths: number,
    // 企画数量
    planQuantity: number,
    // 月発注予定数
    monthlyPlanOrderQuantity: number;
    // 受渡場所
    deliveryLocation: string,
    // 備考
    remarks: string,
    //添付ファイル
    attachments: Attachment[];
    // バージョン
    version: number;
    //ユーザーID
    userName: string,
    //更新日時
    modifiedTime: Dayjs
}

//見積回答DTO
export interface EstimateReponseDto {
    //ID
    id: number;
    //見積依頼ID
    requestId: number;
    //見積依頼先ID
    respondentsId: number;
    //購買先ID
    supplier: string;
    //見積有効日
    estimatedEffectiveDate: Dayjs;
    //リードタイム
    leadTime: number;
    //原産国
    originalCountryName: string;
    //メーカー名
    makerName: string;
    //メーカー品番
    makerItem: string;
    //部品種別
    itemTypeCode: string;
    //最低発注数量
    moq: number;
    //最小梱包数量
    mpq: number;
    //単価
    unitPrice: number;
    //単価通貨コード
    unitCurrency: string;
    //型費
    moldCost: number;
    //型通貨コード
    moldCurrency: string;
    //型の種類
    moldType: string;
    //型の工程数
    moldProcessNum: number;
    //型の面数
    moldNum: number;
    //治具费
    jigCost: number;
    //治具费通貨コード
    jigCurrency: string;
    //修理費
    repairsCost: number;
    //修理費通貨コード
    repairsCurrency: string;
    //版费
    editionFee: number;
    //版费通貨コード
    editionCurrency: string;
    //契約条件
    contractConditionsCode: string;
    //見積適用開始日
    estimateStartDate: Dayjs;
    //見積適用終了日
    estimateEndDate: Dayjs;
    //生産場所（前工程）
    prevLocation: string;
    //生産場所（後工程）
    nextLocation: string;
    //備考
    remarks: string;
    // バージョン
    version: number;
    //
    responseVersion: number;
    //添付ファイル
    reponseAttachments?: Attachment[];

    responseStatus: string;
    //ユーザーID
    userName: string,
    //更新日時
    modifiedTime: Dayjs,
    // 読み取りフラグ
    unReadFlag: boolean

}


export interface downloadPassedDto extends EstimateReponseDto, EstimateRequestListDto {

}

export interface EstiamteRespondentsResponseVo {
    //ID
    id: number;
    //見積依頼ID
    requestId: number;
    /** 購入先コード **/
    supplier: string,
    /** バージョン **/
    version: number,
    /** 回答状態 **/
    responseStatus: string,
    /** 見積依頼先ID **/
    respondentsId: number,
    /** 見積有効日 **/
    estimatedEffectiveDate: number,
    /** リードタイム **/
    leadTime: number,
    /** 原産国 **/
    originalCountryName: string,
    /** メーカー名 **/
    makerName: string,
    /** メーカー品番 **/
    makerItem: string,
    /** 部品種別 **/
    itemTypeCode: string,
    /** 最低発注数量 **/
    moq: number,
    /** 最小梱包数量 **/
    mpq: number,
    /** 単価 **/
    unitPrice: number,
    /** 単価通貨コード **/
    unitCurrency: string,
    /** 型費 **/
    moldCost: number,
    /** 型通貨コード **/
    moldCurrency: string,
    /** 型の種類 **/
    moldType: string,
    /** 型の工程数 **/
    moldProcessNum: number,
    /** 型の面数 **/
    moldNum: number,
    /** 治具费 **/
    jigCost: number,
    /** 治具费通貨コード **/
    jigCurrency: string,
    /** 修理費 **/
    repairsCost: number,
    /** 修理費通貨コード **/
    repairsCurrency: string,
    /** 版费 **/
    editionFee: number,
    /** 版费通貨コード **/
    editionCurrency: string,
    /** 契約条件 **/
    contractConditionsCode: string,
    /** 見積適用開始日 **/
    estimateStartDate: number,
    /** 見積適用終了日 **/
    estimateEndDate: number,
    /** 生産場所（前工程） **/
    prevLocation: string,
    /** 生産場所（後工程） **/
    nextLocation: string,
    /** 見積回答のバージョン **/
    responseVersion: number,
    /** 備考 **/
    remarks: string,
    /** 添付ファイル情報 **/
    attachments: Attachment[],
    lang: string,
    /** ユーザー名称**/
    userName: string,
    /** 更新日時**/
    modifiedTime: number,
    /** 読み取りフラグ*/
    unReadFlag: boolean
}

export interface EstiamteRespondentsResponseDto {
    //ID
    id: number;
    //見積依頼ID
    requestId: number;
    /** 購入先コード **/
    supplier: string,
    /** バージョン **/
    version: number,
    /** 回答状態 **/
    responseStatus: string,
    /** 見積依頼先ID **/
    respondentsId: number,
    /** 見積有効日 **/
    estimatedEffectiveDate: Dayjs,
    /** リードタイム **/
    leadTime: number,
    /** 原産国 **/
    originalCountryName: string,
    /** メーカー名 **/
    makerName: string,
    /** メーカー品番 **/
    makerItem: string,
    /** 部品種別 **/
    itemTypeCode: string,
    /** 最低発注数量 **/
    moq: number,
    /** 最小梱包数量 **/
    mpq: number,
    /** 単価 **/
    unitPrice: number,
    /** 単価通貨コード **/
    unitCurrency: string,
    /** 型費 **/
    moldCost: number,
    /** 型通貨コード **/
    moldCurrency: string,
    /** 型の種類 **/
    moldType: string,
    /** 型の工程数 **/
    moldProcessNum: number,
    /** 型の面数 **/
    moldNum: number,
    /** 治具费 **/
    jigCost: number,
    /** 治具费通貨コード **/
    jigCurrency: string,
    /** 修理費 **/
    repairsCost: number,
    /** 修理費通貨コード **/
    repairsCurrency: string,
    /** 版费 **/
    editionFee: number,
    /** 版费通貨コード **/
    editionCurrency: string,
    /** 契約条件 **/
    contractConditionsCode: string,
    /** 見積適用開始日 **/
    estimateStartDate: Dayjs,
    /** 見積適用終了日 **/
    estimateEndDate: Dayjs,
    /** 生産場所（前工程） **/
    prevLocation: string,
    /** 生産場所（後工程） **/
    nextLocation: string,
    /** 見積回答のバージョン **/
    responseVersion: number,
    /** 備考 **/
    remarks: string,
    /** 添付ファイル情報 **/
    attachments: Attachment[],
    /** ユーザー名称**/
    userName: string,
    /** 更新日時**/
    modifiedTime: Dayjs,

    lang: string,
    /**読み取りフラグ*/
    unReadFlag: boolean
}

export interface EstimateRequestDetailListVo extends EstimateRequestVo {
    /** 見積回答 **/
    suppliers?: EstiamteRespondentsResponseVo[],

}

export interface EstimateRequestSpecifySupplierVo extends EstimateRequestVo {
    /** 見積回答 **/
    supplier?: EstiamteRespondentsResponseVo,

}

export interface EstimateRequestDetailListDto extends EstimateRequestDto {
    /** 見積回答 **/
    suppliers?: EstiamteRespondentsResponseDto[],
}

export interface EstimateRequestSpecifySupplierDto extends EstimateRequestDto {
    /** 見積回答 **/
    supplier: EstiamteRespondentsResponseDto,
}


const estimateRequestSpecifySupplierVoToDto = (vo: EstimateRequestSpecifySupplierVo): EstimateRequestSpecifySupplierDto => {
    return {
        ...vo,
        supplier: {
            ...vo.supplier,
            id: vo.supplier?.id,
            estimateEndDate: vo.supplier?.estimateEndDate === 0 ? undefined : (!!vo.supplier?.estimateEndDate ? dayjs(vo.supplier.estimateEndDate) : undefined),
            estimateStartDate: vo.supplier?.estimateStartDate === 0 ? undefined : (!!vo.supplier?.estimateStartDate ? dayjs(vo.supplier.estimateStartDate) : undefined),
            estimatedEffectiveDate: vo.supplier?.estimatedEffectiveDate === 0 ? undefined : (!!vo.supplier?.estimatedEffectiveDate ? dayjs(vo.supplier.estimatedEffectiveDate) : undefined),
            modifiedTime: vo.supplier?.modifiedTime === 0 ? undefined : (!!vo.supplier?.modifiedTime ? dayjs(vo.supplier.modifiedTime) : undefined),
            moldCost: vo.supplier?.moldCost === 0 ? undefined : vo.supplier?.moldCost,
            moldProcessNum: vo.supplier?.moldProcessNum === 0 ? undefined : vo.supplier?.moldProcessNum,
            moldNum: vo.supplier?.moldNum === 0 ? undefined : vo.supplier?.moldNum,
            jigCost: vo.supplier?.jigCost === 0 ? undefined : vo.supplier?.jigCost,
            editionFee: vo.supplier?.editionFee === 0 ? undefined : vo.supplier?.editionFee,
            repairsCost: vo.supplier?.repairsCost === 0 ? undefined : vo.supplier?.repairsCost,
            leadTime: vo.supplier?.leadTime === 0 ? undefined : vo.supplier?.leadTime,
            moq: vo.supplier?.moq === 0 ? undefined : vo.supplier?.moq,
            mpq: vo.supplier?.mpq === 0 ? undefined : vo.supplier?.mpq,
            unitPrice: vo.supplier?.unitPrice === 0 ? undefined : vo.supplier?.unitPrice
        },

        requestDate: vo.requestDate === 0 ? undefined : dayjs(vo.requestDate),
        answerDeadline: vo.answerDeadline === 0 ? undefined : dayjs(vo.answerDeadline),
        modifiedTime: vo.modifiedTime === 0 ? undefined : dayjs(vo.modifiedTime)
    } as EstimateRequestSpecifySupplierDto;
}

const estimateRequestSpecifySupplierDtoToVo = (dto: EstimateRequestSpecifySupplierDto): EstimateRequestSpecifySupplierVo => {
    let sFinalVo = {
        ...dto.supplier,
        estimateStartDate: dayjs(dto.supplier?.estimateStartDate).valueOf(),
        estimateEndDate: dayjs(dto.supplier?.estimateEndDate).valueOf(),
        estimatedEffectiveDate: dayjs(dto.supplier?.estimatedEffectiveDate).valueOf(),
        modifiedTime: dayjs(dto.supplier?.modifiedTime).valueOf(),
        leadTime: dayjs(dto.supplier?.leadTime).valueOf(),
        lang: locales.getLang()
    }

    return {
        ...dto,
        supplier: sFinalVo,
        requestDate: dayjs(dto.requestDate).valueOf(),
        answerDeadline: dayjs(dto.answerDeadline).valueOf(),
        modifiedTime: dayjs(dto.modifiedTime).valueOf()

    } as EstimateRequestSpecifySupplierVo;
}

//获取特定的依赖信息
export const getSpecifyResponse = (id?: string, respondentsId?: string): Promise<EstimateRequestSpecifySupplierDto> => {
    return api.get(`${uri}/specify/response?id=${id}&respondentsId=${respondentsId}`)
        .then((result: any) => {
            const data = result as EstimateRequestSpecifySupplierVo;
            return estimateRequestSpecifySupplierVoToDto(data);
        });
}

//更新respondents表状态
export const updateRespondentsStatus = (dto: EstimateRequestSpecifySupplierDto): Promise<EstimateRequestDetailListDto> => {
    return api.merge(`${urise}/update/status/`, dto.id, estimateRequestSpecifySupplierDtoToVo(dto)).then((result: any) => {
        const data = result as EstimateRequestDetailListVo;
        return estimateDetilVo2Dto(data);
    });
}

//更新或新规表状态
export const updateRespondents = (dto: EstimateRequestSpecifySupplierDto): Promise<any> => {
    return api.merge(`${urise}/detail/`, dto.id, estimateRequestSpecifySupplierDtoToVo(dto));
}