import { Button, Form, Input, Modal, Select } from 'antd';
import dayjs from 'dayjs';
import React, { useEffect } from 'react'
import { FormattedMessage } from 'react-intl';
import { DatePicker } from '../common';
import { Field, useFields } from '../fields';
import { filterUtil } from '../util';
import { InventorySearchDto } from './api'

const { RangePicker } = DatePicker;
export interface Props {
  visible: boolean;
  searchDto: InventorySearchDto;
  close: () => void;
  handleSearch: (searchDto: InventorySearchDto) => void;
}

export function InventorySearch(props: Props) {

  return (
    <Modal
      title={<FormattedMessage tagName='h4' id='common.search' />}
      footer={null}
      visible={props.visible}
      closable={false}
      afterClose={props.close}
      destroyOnClose={true}
      width={600}>
      {props.visible && <InventorySearchForm {...props} />}
    </Modal>
  );
}

const InventorySearchForm = (props: Props) => {

  const primaryLenders = useFields('fullSupplier');
  const statusOptions = useFields('moldStatus');

  const [form] = Form.useForm<InventorySearchDto>();

  useEffect(() => {
    form.setFieldsValue(props.searchDto);
  });

  // 有高確認依頼番号
  const inventoryRequestNoTitle = <FormattedMessage id='inventory.inventoryRequestNo' />
  // 一次預け先コード
  const primaryLendersTitle = <FormattedMessage id='model.primaryLender' />
  // 一次預け先名称
  const primaryLenderNameTitle = <FormattedMessage id='model.primaryLenderName' />
  // 請求日
  const requestDateTitle = <FormattedMessage id='inventory.requestDate' />
  // 希望回答日
  const answerDeadlineTitle = <FormattedMessage id='inventory.answerDeadline' />
  // 回答ステータス
  const statusTitle = <FormattedMessage id='inventory.status' />

  const defalutRequestDate = [dayjs().add(-1, 'y'), dayjs()]

  return (
    <Form
      form={form}
      onFinish={props.handleSearch}
      layout={'horizontal'}
      colon={false}
      size={'large'}
      labelCol={{ span: 7 }}
      wrapperCol={{ span: 15 }}>
      <Form.Item name='inventoryRequestNo' label={inventoryRequestNoTitle}>
        <Input min={1} max={40} />
      </Form.Item>
      <Form.Item name='primaryLenders' label={primaryLendersTitle} >
        <Select<Field>
          mode={'multiple'}
          allowClear={true}
          autoClearSearchValue={false}
          filterOption={filterUtil.selectFilter}
          onSearch={filterUtil.onSearchForm(form, 'primaryLenders', primaryLenders)}
          loading={primaryLenders.length === 0}
          options={primaryLenders} />
      </Form.Item>
      <Form.Item name='primaryLenderName' label={primaryLenderNameTitle}>
        <Input min={1} max={40} />
      </Form.Item>
      <Form.Item name='answerDeadline' label={answerDeadlineTitle}>
        <RangePicker allowEmpty={[true, true]} style={{ width: '100%' }} />
      </Form.Item>
      <Form.Item
        name='requestDate'
        label={requestDateTitle}
        initialValue={defalutRequestDate}
      >
        <RangePicker allowEmpty={[true, true]} style={{ width: '100%' }} />
      </Form.Item>
      <Form.Item name='status' label={statusTitle} >
        <Select options={statusOptions} mode={'multiple'} allowClear/>
      </Form.Item>
      <Form.Item style={{marginLeft: '45px'}}>
        <div className='search-button' style={{ paddingLeft: '20px' }}>
          <Button className='search-close-btn' onClick={props.close}>
            <FormattedMessage id='common.close' />
          </Button>
          <Button className='search-search-btn' htmlType='submit'>
            <FormattedMessage id='common.search' />
          </Button>
        </div>
      </Form.Item>
    </Form>
  );
}