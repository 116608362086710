import React, { useEffect } from "react";
import { FormattedMessage } from "react-intl";

import { Button, Form, Input, InputNumber, Modal, Select } from "antd";

import { Field, useFields } from "../fields";
import { filterUtil } from "../util";
import { ModelSearchDto } from "./api";
import { DatePicker } from "../common";

const { RangePicker } = DatePicker;

export interface Props {
  visible: boolean;
  searchDto: ModelSearchDto;
  close: () => void;
  handleSearch: (searchDto: ModelSearchDto) => void;
}

export function ModelSearch(props: Props) {

  return (
    <Modal
      title={<FormattedMessage tagName='h4' id='common.search' />}
      footer={null}
      visible={props.visible}
      closable={false}
      afterClose={props.close}
      destroyOnClose={true}
      width={560} 
      style={{top: '10px'}}>
      {props.visible && <ModelSearchForm {...props}/>}
    </Modal>
  );
}

const ModelSearchForm = (props: Props) => {

  const materials = useFields('material');
  const managements = useFields('management');
  const kinds = useFields('kinds');
  const deleteFlagOptions = useFields('deleteFlag');
  const primaryLenders = useFields('mold');
  const itemOptions = useFields('item');

  const [form] = Form.useForm<ModelSearchDto>();

  useEffect(() => {
    form.setFieldsValue(props.searchDto);
  });

  // 資産番号
  const assetNoTitle = <FormattedMessage id='model.assetNo' />
  // 補助番号
  const auxiliaryNoTitle = <FormattedMessage id='model.auxiliaryNo' />
  // 金型品番
  const moldNoTitle = <FormattedMessage id='model.moldNo' />
  // 機種名
  const modelNameTitle = <FormattedMessage id='model.modelName' />
  // 品番
  const itemTitle = <FormattedMessage id='model.item' />
  // 品名
  const itemNameTitle = <FormattedMessage id='model.itemName' />
  // 材質
  const materialTitle = <FormattedMessage id='model.material' />
  // 管理
  const managementTitle = <FormattedMessage id='model.management' />
  // 種別
  const kindsTitle = <FormattedMessage id='model.kinds' />
  // 一次預け先コード
  const primaryLendersTitle = <FormattedMessage id='model.primaryLender' />
  // 一次預け先住所
  const primaryLenderAddrTitle = <FormattedMessage id='model.primaryLenderAddr' />
  // 最終預け先コード
  const finalLenderTitle = <FormattedMessage id='model.finalLender' />
  // 最終預け先名称
  const finalLenderNameTitle = <FormattedMessage id='model.finalLenderName' />
  // 最終預け先住所
  const finalLenderAddrTitle = <FormattedMessage id='model.finalLenderAddr' />
  // 取得年月日
  const acquisitionDateTitle = <FormattedMessage id='model.acquisitionDate' />
    // 削除フラグ
  const deleteFlagTitle = <FormattedMessage id='model.deleteFlag' />;
  // 固定資産登録決裁番号
  const fixedAssetRegistrationDecisionNumberTitle = <FormattedMessage id='model.fixedAssetRegistrationDecisionNumber' />;

  return (
    <Form
      form={form}
      onFinish={props.handleSearch}
      layout={'horizontal'}
      colon={false}
      size={'large'}
      labelCol={{ span: 7 }}
      wrapperCol={{ span: 14 }}>
      <div className='formItem-high'>
        <Form.Item className='formItem-high' name='assetNo' label={assetNoTitle}>
          <Input min={1} max={40} />
        </Form.Item>
        <Form.Item className='formItem-high' name='auxiliaryNo' label={auxiliaryNoTitle}>
          <InputNumber min={0} max={99} />
        </Form.Item>
        <Form.Item className='formItem-high' name='moldNo' label={moldNoTitle}>
          <Input min={1} max={40} />
        </Form.Item>
        <Form.Item className='formItem-high' name='modelName' label={modelNameTitle}>
          <Input min={1} max={40} />
        </Form.Item>
        <Form.Item className='formItem-high' name='item' label={itemTitle}>
          <Select<Field>
            mode={'tags'}
            className="select-mutil"
            allowClear={true}
            autoClearSearchValue={false}
            filterOption={filterUtil.selectFilter}
            onSearch={filterUtil.onSearchForm(form, 'item', itemOptions)}
            loading={itemOptions.length === 0}
            options={itemOptions} 
            style={{maxHeight: '68px'}}/>
        </Form.Item>
        <Form.Item className='formItem-high' name='itemName' label={itemNameTitle}>
          <Input min={1} max={40} />
        </Form.Item>
        <Form.Item className='formItem-high' name='material' label={materialTitle} >
          <Select options={materials} allowClear={true} />
        </Form.Item>
        <Form.Item className='formItem-high' name='management' label={managementTitle} >
          <Select options={managements} allowClear={true} />
        </Form.Item>
        <Form.Item className='formItem-high' name='kinds' label={kindsTitle} >
          <Select options={kinds} allowClear={true} />
        </Form.Item>
        <Form.Item className='formItem-high' name='primaryLenders' label={primaryLendersTitle} >
          <Select<Field>
            mode={'multiple'}
            className="select-mutil"
            allowClear={true}
            autoClearSearchValue={false}
            filterOption={filterUtil.selectFilter}
            onSearch={filterUtil.onSearchForm(form, 'primaryLenders', primaryLenders)}
            loading={primaryLenders.length === 0}
            options={primaryLenders} 
            style={{maxHeight: '68px'}}/>
        </Form.Item>
        {/* <Form.Item className='formItem-high' name='primaryLenderName' label={primaryLenderNameTitle}>
          <Input min={1} max={40} />
        </Form.Item> */}
        <Form.Item className='formItem-high' name='primaryLenderAddr' label={primaryLenderAddrTitle}>
          <Input min={1} max={40} />
        </Form.Item>
        <Form.Item className='formItem-high' name='finalLender' label={finalLenderTitle}>
          <Input min={1} max={40} />
        </Form.Item>
        <Form.Item className='formItem-high' name='finalLenderName' label={finalLenderNameTitle}>
          <Input min={1} max={40} />
        </Form.Item>
        <Form.Item className='formItem-high' name='finalLenderAddr' label={finalLenderAddrTitle}>
          <Input min={1} max={40} />
        </Form.Item>
        <Form.Item className='formItem-high' name='acquisitionDate' label={acquisitionDateTitle}>
          <RangePicker allowEmpty={[true, true]} style={{ width: '100%' }} />
        </Form.Item>
        <Form.Item className='formItem-high' name='deleteFlag' label={deleteFlagTitle}>
          <Select options={deleteFlagOptions} allowClear={true} />
        </Form.Item>
        <Form.Item className='formItem-high' name='fixedAssetRegistrationDecisionNumber' label={fixedAssetRegistrationDecisionNumberTitle}>
          <Input min={1} max={100} />
        </Form.Item>
      </div>
      <Form.Item className='formItem-high' style={{marginLeft: '40px'}}>
        <div className='search-button'>
          <Button className='search-close-btn' onClick={props.close}>
            <FormattedMessage id='common.close' />
          </Button>
          <Button className='search-search-btn' htmlType='submit'>
            <FormattedMessage id='common.search' />
          </Button>
        </div>
      </Form.Item>
    </Form>
  );
};
