import { Dayjs } from 'dayjs';
import { api, CommonListVo, converter, PagingSearchParaVo, SearchParaPropertis } from '../api'
import { Attachment, DateRange } from '../common';
import { locales } from '../locales';

const dayjs = require('dayjs');

// アクセスURI
const uri = '/api/models/';
// ページ毎行数
const rowCount = 10;
// ソート順項目定義、repositoryと一致
const orderByColumns = [
    // 資産番号
    "assetNo",
    // 補助番号
    "auxiliaryNo",
    // 金型品番
    "moldNo",
    // 機種名
    "modelName",
    // 品番
    "item",
    // 品名
    "itemName",
    // 材質
    "material",
    // 管理
    "management",
    // 種別
    "kinds",
    // 一次預け先コード
    "primaryLender",
    // 一次預け先名称
    "primaryLenderName",
    // 一次預け先住所
    "primaryLenderAddr",
    // 最終預け先
    "finalLender", 
    // 最終預け先名称
    "finalLenderName", 
    // 最終預け先住所
    "finalLenderAddr",
    // 最終預け先住所コード
    "finalLenderAddrCode",
    // 取得年月日
    "acquisitionDate",
    // 削除フラグ
    "deleteFlag",
    // 固定資産登録決裁番号
    "fixedAssetRegistrationDecisionNumber",
];

// 検索条件Dtoの項目一覧
const properties: SearchParaPropertis = {
    // 資産番号
    assetNo: 'string',
    // 補助番号
    auxiliaryNo: 'string',
    // 金型品番
    moldNo: 'string',
    // 機種名
    modelName: 'string',
    // 品番
    item: 'array',
    // 品名
    itemName: 'string',
    // 材質
    material: 'string',
    // 管理
    management: 'string',
    // 種別
    kinds: 'string',
    // 一次預け先コード
    primaryLenders: 'array',
    // 一次預け先名称
    primaryLenderName: 'string',
    // 一次預け先住所
    primaryLenderAddr: 'string',
    // 最終預け先コード
    finalLender: 'string',
    // 最終預け先名称
    finalLenderName: 'string',
    // 最終預け先住所
    finalLenderAddr: 'string',
    // 最終預け先住所コード
    finalLenderAddrCode: 'string',
    // 取得年月日
    acquisitionDate: 'DateRange',
    // 削除フラグ
    deleteFlag: 'string',
    // 固定資産登録決裁番号
    fixedAssetRegistrationDecisionNumber: 'string',
};

interface ModelVo {
    // ID
    id: number;
    // 資産番号
    assetNo: string;
    // 補助番号
    auxiliaryNo: string;
    // text
    text: string;
    // 金型品番
    moldNo: string;
    // 機種名
    modelName: string;
    // 品番
    items: string[];
    // 品名
    itemName: string;
    // 材質
    material: string;
    // 管理
    management: string;
    // 種別
    kinds: string;
    // 一次預け先コード
    primaryLender?: string;
    // 一次預け先住所
    primaryLenderAddr: string;
    // 最終預け先コード
    finalLender: string;
    // 最終預け先名称
    finalLenderName: string;
    // 最終預け先住所
    finalLenderAddr: string;
    // 最終預け先住所コード
    finalLenderAddrCode?: string;
    // 取得年月日
    acquisitionDate: number;
    // 受注者コード
    // supplier: string;
    // 原価センター
    costCenter: string;
    // 重量
    weight: string;
    // 取数
    moldCavityNum: number;
    // ライフショット数
    lifeShotsNum: number;
    // 面数
    moldsNum: number;
    // 一次預け先の貸与日
    primaryLeadDate: number;
    // 決済NO
    settlementNo: string;
    // 仕入金額
    purchaseAmount: number;
    // 仕入通貨
    purchaseCurrency: string;
    // 注文番号
    orderNo: string;
    // 償却開始日
    depreciationStartDate: number;
    // 廃棄日
    discardDate: number;
    // 備考
    remarks: string;
    // バージョン
    version: number;
    // 削除フラグ
    deleteFlag: string;
    // 固定資産登録決裁番号
    fixedAssetRegistrationDecisionNumber: string;
    // 資産流動区分
    assetFlowDifferentiation: string;
    // 流動日付
    flowDate: number;
    // 登録日
    createdDate: number;
    // 更新日
    modifiedDate: number;
    // 更新者
    modifiedUser: string;
    // 資産流動申請番号
    assetFlowApplyCodes: string[];
    // 原価センター番号
    costCenterNo: string;
    // 原価センター名
    costCenterName: string;
    // 添付ファイル
    attachments: Attachment[];
}

interface ModelUploadVo {
    // 資産番号
    assetNo: string;
    // 補助番号
    auxiliaryNo: string;
    // 取得年月日
    acquisitionDate: number;
    // 受注者コード
    // supplier?: string;
    // text
    text?: string;
    // 原価センター
    costCenter?: string;
}

export interface ModelDto {
    // ID
    id: number;
    // 資産番号
    assetNo: string;
    // 補助番号
    auxiliaryNo: string;
    // text
    text: string;
    // 金型品番
    moldNo: string;
    // 機種名
    modelName: string;
    // 品番
    items: string[];
    // 品名
    itemName: string;
    // 材質
    material: string;
    // 管理
    management: string;
    // 種別
    kinds: string;
    // 一次預け先コード
    primaryLender?: string;
    // 一次預け先住所
    primaryLenderAddr: string;
    // 最終預け先コード
    finalLender: string;
    // 最終預け先名称
    finalLenderName: string;
    // 最終預け先住所
    finalLenderAddr: string;
    // 最終預け先住所コード
    finalLenderAddrCode?: string;
    // 取得年月日
    acquisitionDate: Dayjs;
    // 受注者コード
    // supplier: string;
    // 原価センター
    costCenter: string;
    // 重量
    weight: string;
    // 取数
    moldCavityNum: number;
    // ライフショット数
    lifeShotsNum: number;
    // 面数
    moldsNum: number;
    // 一次預け先の貸与日
    primaryLeadDate?: Dayjs;
    // 決済NO
    settlementNo: string;
    // 仕入金額
    purchaseAmount: number;
    // 仕入通貨
    purchaseCurrency: string;
    // 注文番号
    orderNo: string;
    // 償却開始日
    depreciationStartDate?: Dayjs;
    // 廃棄日
    discardDate?: Dayjs;
    // 備考
    remarks: string;
    // master-バージョン
    version: number;
    // 削除フラグ
    deleteFlag: string;
    // 固定資産登録決裁番号
    fixedAssetRegistrationDecisionNumber: string;
    // 資産流動区分
    assetFlowDifferentiation: string;
    // 流動日付
    flowDate?: Dayjs;
    // 登録日
    createdDate?: Dayjs;
    // 更新日
    modifiedDate?: Dayjs;
    // 更新者
    modifiedUser: string;
    // 資産流動申請番号
    assetFlowApplyCodes: string[];
    // 原価センター番号
    costCenterNo: string;
    // 原価センター名
    costCenterName: string;
    // 添付ファイル
    attachments: Attachment[];
}

export interface ModelSearchDto extends PagingSearchParaVo {
    // 資産番号
    assetNo?: string;
    // 補助番号
    auxiliaryNo?: string;
    // 金型品番
    moldNo?: string;
    // 機種名
    modelName?: string;
    // 品番
    item: string[];
    // 品名
    itemName?: string;
    // 材質
    material: string;
    // 管理
    management: string;
    // 種別
    kinds: string;
    // 一次預け先コード
    primaryLenders: string[];
    // 一次預け先名称
    primaryLenderName?: string;
    // 一次預け先住所
    primaryLenderAddr?: string;
    // 最終預け先コード
    finalLender?: string;
    // 最終預け先名称
    finalLenderName?: string;
    // 最終預け先住所
    finalLenderAddr?: string;
    // 最終預け先住所コード
    finalLenderAddrCode?: string;
    // 取得年月日
    acquisitionDate: DateRange;
    // 削除フラグ
    deleteFlag: string;
    // 固定資産登録決裁番号
    fixedAssetRegistrationDecisionNumber?: string;
}

export interface ModelUploadDto {
    // 資産番号
    assetNo: string;
    // 補助番号
    auxiliaryNo: string;
    // 取得年月日
    acquisitionDate: Dayjs | null;
    // 受注者コード
    // supplier?: string;
    // text
    text?: string;
    // 原価センター番号
    costCenterNo?: string;
    // 原価センター名
    costCenterName?: string;
}


const formDto2Vo = (form: ModelDto): ModelVo => {
    return {
        ...form,
        acquisitionDate: (form.acquisitionDate !== undefined && form.acquisitionDate !== null) ? dayjs(form.acquisitionDate).valueOf():0,
        primaryLeadDate: (form.primaryLeadDate !== undefined && form.primaryLeadDate !== null) ? dayjs(form.primaryLeadDate).valueOf():0,
        depreciationStartDate: (form.depreciationStartDate!== undefined && form.depreciationStartDate !== null) ? dayjs(form.depreciationStartDate).valueOf(): 0,
        discardDate:(form.discardDate!== undefined && form.discardDate !== null) ? dayjs(form.discardDate).valueOf(): 0,

        flowDate: (form.flowDate!== undefined && form.flowDate !== null) ? dayjs(form.flowDate).valueOf(): 0,
        createdDate: (form.createdDate!== undefined && form.createdDate !== null) ? dayjs(form.createdDate).valueOf(): 0,
        modifiedDate: (form.modifiedDate!== undefined && form.modifiedDate !== null) ? dayjs(form.modifiedDate).valueOf(): 0,
        // weight: Number(form.weight),
    } as ModelVo;
};

const vo2Dto = (form: ModelVo): ModelDto => {
    return {
        ...form,
        primaryLender: form.primaryLender === null ? undefined : form.primaryLender,
        acquisitionDate: dayjs(form.acquisitionDate),
        primaryLeadDate: form.primaryLeadDate ? dayjs(form.primaryLeadDate) : undefined,
        depreciationStartDate: form.depreciationStartDate === 0 ? null : dayjs(form.depreciationStartDate),
        discardDate: form.discardDate === 0 ? null : dayjs(form.discardDate),
        flowDate: form.flowDate === 0 ? null : dayjs(form.flowDate),
        createdDate: form.createdDate === 0 ? null : dayjs(form.createdDate),
        modifiedDate: form.modifiedDate === 0 ? null : dayjs(form.modifiedDate),
        // weight: `${form.weight}`,  // sring => number
    };
};

export const get = (query: string): Promise<[number, ModelDto[]]> => {
    if (!query || query.length === 0) {
        query = `?rowCount=${rowCount}`;
    } else {
        query += `&rowCount=${rowCount}`;
    }
    return api.get(`${uri}${query}`)
        .then((result: any) => {

            const data = result as CommonListVo<ModelVo>;
            return [data.total, data.vos.map(vo2Dto)];
        });
};

export const getById = (urlId: string): Promise<ModelDto> => {
    return api.get(`${uri}/${urlId}`)
        .then((result: any) => {
            const data = result as ModelVo;
            return vo2Dto(data);
        });
};
export const removeById = (id: number, version: number): Promise<any> => {
    return api.removeById(uri, id, { id: id, version: version });
};

export const removeByModelList = (selectedDeleteModel: ModelDto[]): Promise<any> => {
    const modelVo = selectedDeleteModel.map(formDto2Vo)
    return api.removeByList(`${uri}deleteList`, modelVo);
};

export const fill = (forms: ModelDto[]): Promise<ModelDto[]> => {
    return api.getByData(`${uri}/upload`, forms)
        .then((result: any) => {
            const data = result as ModelVo[];

            return data.map(vo2Dto);
        });
};

export const merge = (form: ModelDto): Promise<ModelDto> => {
    const vo = formDto2Vo(form);
    return api.merge(uri, vo.id as number, vo)
        .then((result: any) => {
            const data = result as ModelVo;
            return vo2Dto(data);
        });
};

export const upload = (dtos: ModelUploadDto[]): Promise<any> => {
    const vos: ModelUploadVo[] = dtos.map(dto => ({ ...dto, acquisitionDate: !!dto.acquisitionDate ? dto.acquisitionDate.valueOf() : 0 }));
    return api.upload(`${uri}/upload`, vos);
}

export const downloadTemplateApi =()=>{
    const lang = locales.getLang();
    return api.downloadTemplate(`${uri}/downloadTemplate`, lang).then(res=>{
          res.blob().then((blob: Blob | MediaSource) => {
              const aLink = document.createElement('a');
              aLink.setAttribute('charset', 'UTF-8')
              document.body.appendChild(aLink);
              aLink.style.display='none';
              const objectUrl = window.URL.createObjectURL(blob);
              aLink.href = objectUrl;
              aLink.download = decodeURIComponent(res.headers.get('content-disposition').split('filename=')[1]);
              aLink.click();
              document.body.removeChild(aLink);
            });
    })
  }

// searchから検索条件Dtoへ変換する
export const search2Dto = (query?: string): ModelSearchDto => {
    return converter.search2Dto(properties, orderByColumns, query) as ModelSearchDto;
};

// 検索条件Dtoからsearchへ変換する
export const dto2search = (researchDto: ModelSearchDto): string => {
    return converter.dto2search(properties, orderByColumns, researchDto);
};



