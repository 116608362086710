import dayjs, { Dayjs } from 'dayjs';
import { Attachment, DateRange, } from '../common';
import { api, converter, CommonListVo, PagingSearchParaVo, SearchParaPropertis } from '../api'
import { locales } from '../locales';

// アクセスURI
const uri = '/api/supplierestimates';

// ソート順項目定義、repositoryと一致
const orderByColumns = ['status', 'requestNo', 'buyer', 'supplier', 'purchasingManagerName', 'requestDate'];

// 検索条件Dtoの項目一覧
const properties: SearchParaPropertis = {
  requestNo: 'string',
  suppliers: 'array',
  buyers: 'array',
  supplierName: 'string',
  purchasingManagerName: 'string',
  requestDate: 'DateRange',
  status: 'array',
};

// サプライヤー見積依頼VO
interface SupplierEstimatesVo {
  // ID
  id: number,
  // 案件状態    
  status?: string,
  // 入札依頼番号    
  requestNo?: string,
  // 購入元コード    
  buyer?: string,
  // 購入先コード    
  supplier?: string,
  // 購買担当コード    
  purchasingManagerName?: string,
  // 依頼日    
  requestDate?: number,
  // バージョン    
  version?: number,
  // 言語
  lang: string,
  // 読み取りフラグ
  unReadFlag: boolean,
  // 更新ユーザーID
  userId: string;
  // 更新ユーザー名称
  userName: string;
  // 更新時間
  modifiedTime: number;

  details: SupplierEstimatesDetailVo[];

  attachments: Attachment[];
}

interface SupplierEstimatesDetailVo {
  // ID
  id: number,
  // 回答状態    
  responseStatus?: string,
  // 見積依頼ID    
  requestId?: number,
  // 品番    
  item?: string,
  // 品名    
  itemName?: string,
  // 見積適用要望日    
  estimateStartDate?: number,
  // リードタイム    
  leadTime?: number,
  // 最低発注数量    
  moq?: number,
  // 最小梱包数量    
  mpq?: number,
  // 単価    
  unitPrice?: number,
  // 単価通貨コード    
  unitCurrency?: string,
  // 修理費    
  repairsCost?: number,
  // 修理費通貨コード    
  repairsCurrency?: string,
  // 備考    
  remarks?: string,
  // バージョン    
  version: number,
  // userid
  userId: string,
  // username
  userName: string,
  // 更新日
  supplierModifiedTime: number,
}

// 納期回答検索条件Dto
export interface SupplierEstimatesSearchDto extends PagingSearchParaVo {
  // 入札依頼番号 
  requestNo?: string,
  // 購入元コード    
  buyers: string[],
  // 購入先コード    
  suppliers: string[],
  // 受注者名称
  supplierName?: string,
  // 購買担当コード    
  purchasingManagerName?: string,
  // 依頼日    
  requestDate: DateRange,
  // 案件状態 
  status: string[],
}

export interface SupplierEstimatesDto {
  // ID
  id: number,
  // 案件状態    
  status?: string,
  // 入札依頼番号    
  requestNo?: string,
  // 購入元コード    
  buyer?: string,
  // 購入先コード    
  supplier?: string,
  // 購買担当コード    
  purchasingManagerName?: string,
  // 依頼日    
  requestDate: Dayjs | null,
  // バージョン    
  version?: number,
  // 言語
  lang: string,
  // 読み取りフラグ
  unReadFlag: boolean,

  // 更新ユーザーID
  userId: string;
  // 更新ユーザー名称
  userName: string;
  //更新時間
  modifiedTime: Dayjs;

  details: SupplierEstimatesDetailDto[];

  attachments: Attachment[];
}

// 納期回答のVOクラス
export interface SupplierEstimatesDetailDto {
  // ID
  id: number,
  // 回答状態    
  responseStatus?: string,
  // 見積依頼ID    
  requestId?: number,
  // 品番    
  item?: string,
  // 品名    
  itemName?: string,
  // 見積適用要望日    
  estimateStartDate: Dayjs | null,
  // リードタイム    
  leadTime?: number,
  // 最低発注数量    
  moq?: number,
  // 最小梱包数量    
  mpq?: number,
  // 単価    
  unitPrice?: number,
  // 単価通貨コード    
  unitCurrency?: string,
  // 修理費    
  repairsCost?: number,
  // 修理費通貨コード    
  repairsCurrency?: string,
  // 備考    
  remarks?: string,
  // バージョン    
  version: number,
  // userid
  userId: string,
  // username
  userName: string,
  // 更新日
  supplierModifiedTime: Dayjs,
}

export interface downloadPassedDto {
  // ID
  id: number,
  // 案件状態    
  status?: string,
  // 入札依頼番号    
  requestNo?: string,
  // 購入元コード    
  buyer?: string,
  // 購入先コード    
  supplier?: string,
  // 購買担当コード    
  purchasingManagerName?: string,
  // 依頼日    
  requestDate: Dayjs | null,
  // バージョン    
  version?: number,
  // 言語
  lang: string,
  // 読み取りフラグ
  unReadFlag: boolean,

  // 更新ユーザーID
  userId: string;
  // 更新ユーザー名称
  userName: string;
  //更新時間
  modifiedTime: Dayjs;




  // ID
  detailId: number,
  // 回答状態    
  responseStatus?: string,
  // 見積依頼ID    
  requestId?: number,
  // 品番    
  item?: string,
  // 品名    
  itemName?: string,
  // 見積適用要望日    
  estimateStartDate: Dayjs | null,
  // リードタイム    
  leadTime?: number,
  // 最低発注数量    
  moq?: number,
  // 最小梱包数量    
  mpq?: number,
  // 単価    
  unitPrice?: number,
  // 単価通貨コード    
  unitCurrency?: string,
  // 修理費    
  repairsCost?: number,
  // 修理費通貨コード    
  repairsCurrency?: string,
  // 備考    
  remarks?: string,
  // バージョン    
  detailVersion: number,
  // userid
  detailUserId: string,
  // username
  detailUserName: string,
  // 更新日
  supplierModifiedTime: Dayjs,
}

// VOからListRecordDtoを変換する
const vo2Dto = (vo: SupplierEstimatesVo): SupplierEstimatesDto => {
  return {
    ...vo,
    requestDate: dayjs(vo.requestDate),
    modifiedTime: dayjs(vo.modifiedTime),
    details: !!vo.details ? vo.details.map(vo2DetailDto) : [],
  };
};

// VOからListRecordDtoを変換する
const vo2DetailDto = (vo: SupplierEstimatesDetailVo): SupplierEstimatesDetailDto => {
  return {
    ...vo,
    estimateStartDate: dayjs(vo.estimateStartDate),
    supplierModifiedTime: dayjs(vo.supplierModifiedTime)
  };
};

// ListRecordDtoからVOを変換する
const dto2Vo = (dto: SupplierEstimatesDto): SupplierEstimatesVo => {
  return {
    ...dto,
    requestDate: !!dto.requestDate ? dto.requestDate.valueOf() : 0,
    modifiedTime: !!dto.modifiedTime ? dto.modifiedTime.valueOf() : 0,
    details: dto.details.map(detailDto2Vo),
    id: dto.id,
    status: dto.status,
    supplier: dto.supplier,
    version: dto.version,
    requestNo: dto.requestNo,
    lang: locales.getLang()
  };
};

// ListRecordDtoからVOを変換する
const detailDto2Vo = (dto: SupplierEstimatesDetailDto): SupplierEstimatesDetailVo => {
  return {
    ...dto,
    estimateStartDate: !!dto.estimateStartDate ? dto.estimateStartDate.valueOf() : 0,
    supplierModifiedTime: !!dto.supplierModifiedTime ? dto.supplierModifiedTime.valueOf() : 0,
    id: dto.id,
    responseStatus: dto.responseStatus,
    requestId: dto.requestId,
    version: dto.version,
    remarks: dto.remarks
  };
};

// 取得API
export const get = (query: string): Promise<[number, SupplierEstimatesDto[]]> => {

  return api.get(`${uri}${query}`)
    .then((result: any) => {
      const data = result as CommonListVo<SupplierEstimatesVo>;
      console.log(data);
      return [data.total, data.vos.map(vo2Dto)];
    });
};

// 取得全部(包括detail)API
export const getAll = (query: string): Promise<downloadPassedDto[]> => {

  return api.get(`${uri}${query}`)
    .then((result: any) => {
      const data = result as CommonListVo<SupplierEstimatesVo>;
      
      let downloadDataList : downloadPassedDto[] = []
      data.vos.forEach(list => {
        // detail right join list
        list.details.forEach(detail => {
          let downloadData : downloadPassedDto = {
            // ID
            id: list.id,
            // 案件状態    
            status: list.status,
            // 入札依頼番号    
            requestNo: list.requestNo,
            // 購入元コード    
            buyer: list.buyer,
            // 購入先コード    
            supplier: list.supplier,
            // 購買担当コード    
            purchasingManagerName: list.purchasingManagerName,
            // 依頼日    
            requestDate: dayjs(list.requestDate),
            // バージョン    
            version: list.version,
            // 言語
            lang: list.lang,
            // 読み取りフラグ
            unReadFlag: list.unReadFlag,
            // 更新ユーザーID
            userId: list.userId,
            // 更新ユーザー名称
            userName: list.userName,
            //更新時間
            modifiedTime: dayjs(list.modifiedTime),


            // ID
            detailId: detail.id,
            // 回答状態    
            responseStatus: detail.responseStatus,
            // 見積依頼ID    
            requestId: detail.requestId,
            // 品番    
            item: detail.item,
            // 品名    
            itemName: detail.itemName,
            // 見積適用要望日    
            estimateStartDate: dayjs(detail.estimateStartDate),
            // リードタイム    
            leadTime: detail.leadTime,
            // 最低発注数量    
            moq: detail.moq,
            // 最小梱包数量    
            mpq: detail.mpq,
            // 単価    
            unitPrice: detail.unitPrice,
            // 単価通貨コード    
            unitCurrency: detail.unitCurrency,
            // 修理費    
            repairsCost: detail.repairsCost,
            // 修理費通貨コード    
            repairsCurrency: detail.repairsCurrency,
            // 備考    
            remarks: detail.remarks,
            // バージョン    
            detailVersion: detail.version,
            // userid
            detailUserId: detail.userId,
            // username
            detailUserName: detail.userName,
            // 更新日
            supplierModifiedTime: dayjs(detail.supplierModifiedTime),
          }
          downloadDataList.push(downloadData)
        })
      })
      return downloadDataList
    });
};

export const getById = (urlId: string): Promise<SupplierEstimatesDto> => {

  return api.get(`${uri}/${urlId}`)
    .then((result: any) => {
      const data = result as SupplierEstimatesVo;

      return vo2Dto(data);
    });
};

export const persist = (dto: SupplierEstimatesDto): Promise<[number, SupplierEstimatesDto[]]> => {
  return api.persist(`${uri}`, dto2Vo(dto))
    .then((result: any) => {
      const data = result as CommonListVo<SupplierEstimatesVo>;

      return [data.total, data.vos.map(vo2Dto)];
    });
};

export const removeById = (id: number, version?: number): Promise<any> => {

  return api.removeById(`${uri}/${id}`, id, { id: id, version: version });
};


export const downloadTemplateApi =()=>{
  const lang = locales.getLang();
  return api.downloadTemplate(`${uri}/downloadTemplate`, lang).then(res=>{
        res.blob().then((blob: Blob | MediaSource) => {
            const aLink = document.createElement('a');
            aLink.setAttribute('charset', 'UTF-8')
            document.body.appendChild(aLink);
            aLink.style.display='none';
            const objectUrl = window.URL.createObjectURL(blob);
            aLink.href = objectUrl;
            aLink.download = decodeURIComponent(res.headers.get('content-disposition').split('filename=')[1]);
            aLink.click();
            document.body.removeChild(aLink);
          });
  })
}

export const merge = (id: number, dto: SupplierEstimatesDto): Promise<SupplierEstimatesDto> => {
  return api.merge(`${uri}/`, id, dto2Vo(dto))
    .then((result: any) => {
      const data = result as SupplierEstimatesVo;

      return vo2Dto(data);
    });
};

// searchから検索条件Dtoへ変換する
export const search2Dto = (query?: string): SupplierEstimatesSearchDto => {

  return converter.search2Dto(properties, orderByColumns, query) as SupplierEstimatesSearchDto;
};

// 検索条件Dtoからsearchへ変換する
export const dto2search = (researchDto: SupplierEstimatesSearchDto): string => {

  return converter.dto2search(properties, orderByColumns, researchDto);
};
