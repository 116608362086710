import { useState } from "react";
import {api} from '../api'

// アクセスURI
const uri = '/api/userAgreement';

//支払一覧情報取得
export const CheckUser = () => {
  
  const [agreed, setAgreed] = useState<boolean>(true);
  const [needCheck, setNeedCheck] = useState<boolean>(true);
  if(agreed === true && needCheck === true) {
    api.get(`${uri}/check`)
      .then((result: boolean) => {
        setNeedCheck(!result)
        setAgreed(result)
      });
  }

  return agreed;
};

export const userAgree = (): Promise<boolean> => {

  return api.get(`${uri}/agree`)
    
};