import React, { useEffect, useState } from 'react';
import { FormattedMessage, } from 'react-intl';
import { Modal, Form, Input, Button, Select, Row, Col} from 'antd';

import { DatePicker, NumberRange, } from '../common'
import { filterUtil, } from '../util'

import { AcceptanceSearchDto } from './api';
import './index.css';
import { Field, useFields } from '../fields';
import { NumberRangePicker } from '../common/NumberRangePicker';

const { RangePicker } = DatePicker;

export interface Props {
  visible: boolean;
  searchDto: AcceptanceSearchDto;
  close: () => void;
  handleSearch: (searchDto: AcceptanceSearchDto) => void;
}

export function AcceptanceSearch(props: Props) {
  return (
    <Modal
      title={<FormattedMessage tagName='h4' id='common.search' />}
      footer={null}
      visible={props.visible}
      closable={false}
      afterClose={props.close}
      destroyOnClose={true}
      centered
      width={560}>
      {props.visible && <AcceptanceSearchForm {...props} />}
    </Modal>
  );
}

const AcceptanceSearchForm = (props: Props) => {

  const supplierOptions = useFields('fullSupplier');
  const itemOptions = useFields('item');

  const [form] = Form.useForm<AcceptanceSearchDto>();

  useEffect(() => {
    form.setFieldsValue(props.searchDto);
  });

  const handleSubmit = (record: AcceptanceSearchDto) => {
    // 自动调换最大值和最小值的位置，让大值在右，小值在左
    const currectUnitPriceValue = unitPriceValue?.[0] && unitPriceValue[1] && unitPriceValue[0] > unitPriceValue[1] ? [unitPriceValue[1], unitPriceValue[0]] as NumberRange : unitPriceValue
    const currectAmountValue = amountValue?.[0] && amountValue[1] && amountValue[0] > amountValue[1] ? [amountValue[1], amountValue[0]] as NumberRange : amountValue
    const currectOrderAmountValueValue = orderAmountValue?.[0] && orderAmountValue[1] && orderAmountValue[0] > orderAmountValue[1] ? [orderAmountValue[1], orderAmountValue[0]] as NumberRange : orderAmountValue

    record.unitPrice = currectUnitPriceValue
    record.amount = currectAmountValue
    record.orderAmount = currectOrderAmountValueValue

    props.handleSearch(record)
  }

  const supplierTitle = <FormattedMessage id='acceptance.supplier' />;
  const supplierNameTitle = <FormattedMessage id='acceptance.supplierName' />;
  const orderNoTitle = <FormattedMessage id='acceptance.orderNo' />;
  const acceptanceNoTitle = <FormattedMessage id='acceptance.acceptanceNo' />;
  const deliveryNoTitle = <FormattedMessage id='acceptance.deliveryNo' />;
  const acceptanceDateTitle = <FormattedMessage id='acceptance.acceptanceDate' />;
  const itemTitle = <FormattedMessage id='acceptance.item' />;
  const itemNameTitle = <FormattedMessage id='acceptance.itemName' />;
  const locationCdTitle = <FormattedMessage id='acceptance.locationCd' />;
  const unitPriceTitle = <FormattedMessage id='acceptance.acceptanceUnitPrice' />;
  const amountTitle = <FormattedMessage id='acceptance.acceptanceAmount' />;
  const orderAmountTitle = <FormattedMessage id='acceptance.orderAmount' />;

  const [unitPriceValue, setUnitPriceValue] = useState(props.searchDto.unitPrice)
  const [amountValue, setAmountValue] = useState(props.searchDto.amount)
  const [orderAmountValue, setOrderAmountValue] = useState(props.searchDto.orderAmount)

  return (
    <Form
      form={form}
      onFinish={handleSubmit}
      layout={'horizontal'}
      colon={false}
      size={'large'}
      labelCol={{ span: 6 }}
      wrapperCol={{ span: 16 }}
      className="acceptance-search-form">
      <Form.Item name='suppliers' label={supplierTitle} >
        <Select<Field>
          mode={'multiple'}
          allowClear={true}
          className="select-mutil"
          autoClearSearchValue={false}
          filterOption={filterUtil.selectFilter}
          onSearch={filterUtil.onSearchForm(form, 'suppliers', supplierOptions)}
          loading={supplierOptions.length === 0}
          options={supplierOptions} />
      </Form.Item>
      <Form.Item name='supplierName' label={supplierNameTitle} >
        <Input maxLength={50} />
      </Form.Item>
      <Form.Item name='acceptanceDate' label={acceptanceDateTitle}>
        <RangePicker allowEmpty={[true, true]} style={{ width: '100%' }} />
      </Form.Item>
      <Form.Item name='items' label={itemTitle}>
        <Select<Field>
          mode={'multiple'}
          className="select-mutil"
          allowClear={true}
          autoClearSearchValue={false}
          filterOption={filterUtil.selectFilter}
          onSearch={filterUtil.onSearchForm(form, 'items', itemOptions)}
          loading={itemOptions.length === 0}
          options={itemOptions}/>
      </Form.Item>
      <Form.Item name='itemName' label={itemNameTitle}>
        <Input maxLength={50} allowClear={true}/>
      </Form.Item>
      <Form.Item name='orderNo' label={orderNoTitle} >
        <Input maxLength={10} allowClear={true}/>
      </Form.Item>
      <Form.Item name='deliveryNo' label={deliveryNoTitle}>
        <Input maxLength={10} allowClear={true}/>
      </Form.Item>
      <Form.Item name='acceptanceNo' label={acceptanceNoTitle}>
        <Input maxLength={10} allowClear={true}/>
      </Form.Item>
      {/* 検収単価 */}
      <Row style={{marginBottom: '10px'}}>
        <Col span={6} style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end', paddingRight: '10px'}}>
          {unitPriceTitle}
        </Col>
        <Col span={16}>
          <NumberRangePicker values={unitPriceValue} onChange={record => setUnitPriceValue(record)} style={{ width: '100%' }} />
        </Col>
      </Row>
      {/* 検収単価 */}
      <Row style={{marginBottom: '10px'}}>
        <Col span={6} style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end', paddingRight: '10px'}}>
          {amountTitle}
        </Col>
        <Col span={16}>
          <NumberRangePicker values={amountValue} onChange={record => setAmountValue(record)} style={{ width: '100%' }} />
        </Col>
      </Row>
      {/* 注文残金額 */}
      <Row style={{marginBottom: '10px'}}>
        <Col span={6} style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end', paddingRight: '10px'}}>
          {orderAmountTitle}
        </Col>
        <Col span={16}>
          <NumberRangePicker values={orderAmountValue} onChange={record => setOrderAmountValue(record)} style={{ width: '100%' }} />
        </Col>
      </Row>
      <Form.Item name='locationCd' label={locationCdTitle} >
        <Input  allowClear={true}/>
      </Form.Item>
      <Form.Item style={{marginLeft: '20px'}}>
        <div className='search-button' style={{ paddingLeft: '20px' }}>
          <Button className='search-close-btn' onClick={props.close}>
            <FormattedMessage id='common.close' />
          </Button>
          <Button className='search-search-btn' htmlType='submit'>
            <FormattedMessage id='common.search' />
          </Button>
        </div>
      </Form.Item>
    </Form>
  );
}
