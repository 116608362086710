import React, { FC } from 'react';
import { IntlProvider, } from 'react-intl';
import { BrowserRouter as Router, } from "react-router-dom";
import { ConfigProvider, } from 'antd';
import dayjs from 'dayjs';
import './assets/App.css';
import { SuppierCommnunity } from './features/router';
import { locales } from './features/locales';

const [lang, locale, antdLocale, dayjsLocale] = locales.getLocales();
dayjs.locale(dayjsLocale);

const App: FC = () => (
  <div className="App">
    <ConfigProvider locale={antdLocale}>
      <IntlProvider locale={lang} messages={locale}>
        <Router>
          <SuppierCommnunity />
        </Router>
      </IntlProvider>
    </ConfigProvider>
  </div>
);

export default App;
