// React APIs
import React from 'react';

// 機能内部のAPIs
import { OrderResponseDetail } from './OrderResponseDetail';
import { OrderResponseList } from './OrderResponseList';
import { OrderResponseSearch } from './OrderResponseSearch';
import { OrderResponseHoc } from './OrderResponseHoc';
import { OrderResponseUpload } from './OrderResponseUpload';
import { DeliveryDetailUpload } from './DeliveryDetailUpload';
import { DevInstrucstionUpload } from './DevInstrucstionUpload';

/**
 * 納期回答のコンポネート
 */
export function OrderResponse() {

  const componet = OrderResponseHoc(OrderResponseDetail, OrderResponseList, OrderResponseSearch, OrderResponseUpload, DeliveryDetailUpload, DevInstrucstionUpload);

  return (<>
    {componet}
  </>);
}
