import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { Button, Modal, Spin, } from 'antd';

import './index.css';

import { CommonExcelUpload, CommonExcelUploadProps, RowData } from '../common';
import { fillOrderDetails, OrderListRecordDto } from './api';
import { Dayjs } from 'dayjs';
import { displayUtil } from '../util';

export interface Props {
  visible: boolean;
  upload: (data: OrderListRecordDto[]) => Promise<any>;
  close: () => void;
  uploading: boolean;
}

export function DevInstrucstionUpload(props: Props) {

  // 共通のstates
  const [dtos, setDtos] = useState<OrderListRecordDto[]>([]);

  const validate = async (rowDatas: RowData[]) => {
    const uploadDtos = rowDatas.reduce(
      (currentDtos: OrderListRecordDto[], e: RowData) => {

        const orderDetailKey = e['orderDetailKey'] as string;

        const orderNo = orderDetailKey.slice(0, orderDetailKey.length - 5);
        const detailNo = parseInt(orderDetailKey.slice(orderDetailKey.length - 5));

        let existFlag: boolean = false;

        for (const element of currentDtos) {

          if (element.orderNo === orderNo && element.detailNo === detailNo) {
            element.devInstrucstions.push({
              type: 'DevInstrucstion',
              id: -1,
              devInstrucstionDate: e['devInstrucstionDate'] as Dayjs,
              instrucstionQuantity: e['instrucstionQuantity'] as number
            });

            existFlag = true;
          }
        }

        if (!existFlag) {
          currentDtos.push({
            id: -1,
            orderNo: orderNo,
            detailNo: detailNo,
            orderDate: null,
            iniDeliveryDate: null,
            productionPlanDate: null,
            devInstrucstions: [{
              type: 'DevInstrucstion',
              id: -1,
              devInstrucstionDate: e['devInstrucstionDate'] as Dayjs,
              instrucstionQuantity: e['instrucstionQuantity'] as number
            }],
            orderResponses: [],
            deliveryDetails: [],
          })
        }

        return currentDtos;
      },
      []
    );

    const existDtos: OrderListRecordDto[] = await fillOrderDetails(uploadDtos);

    const errors: string[] = [];

    uploadDtos.forEach(dto => {
      for (let existDto of existDtos) {
        if (existDto.orderNo === dto.orderNo && existDto.detailNo === dto.detailNo) {
          dto.id = existDto.id;

          // データチェック
          let sumQuantiy = 0;
          const deliveryPlanDates: Map<Dayjs, number> = new Map();
          dto.devInstrucstions.forEach(devInstrucstion => {
            // 存在チェック&重複チェック
            if (!!devInstrucstion.devInstrucstionDate) {
              let count = deliveryPlanDates.get(devInstrucstion.devInstrucstionDate);

              count = !!count ? count : 0;

              deliveryPlanDates.set(devInstrucstion.devInstrucstionDate, count + 1);
            }

            sumQuantiy += !!devInstrucstion.instrucstionQuantity ? devInstrucstion.instrucstionQuantity : 0;
          });

          deliveryPlanDates.forEach((count, deliveryPlanDate) => {

            if (count > 1) {
              errors.push(`注文番号：${dto.orderNo}、明細番号：${dto.detailNo}の回答に${count}件の納品指示：${displayUtil.date(deliveryPlanDate)}のレコードがありますけど、納品指示が重複できません。`);
            }
          });

          const quantity = !!existDto.quantity ? existDto.quantity : 0;
          if (sumQuantiy > quantity) {
            errors.push(`注文番号：${dto.orderNo}、明細番号：${dto.detailNo}の納品指示件数は注文件数を超えってしまいました。`);
          }

          return;
        }
      }
      errors.push(`注文番号：${dto.orderNo}、明細番号：${dto.detailNo}の注文情報が存在していません。`);
    });
    if (errors.length > 0) {
      throw errors;
    }

    return uploadDtos;
  };

  const uploadProps: CommonExcelUploadProps = {
    row: {
      columns: [
        { key: 'devInstrucstion.orderDetailKey', field: 'orderDetailKey', type: 'string', isRequired: true, validators: [], },
        { key: 'devInstrucstion.devInstrucstionDate', field: 'devInstrucstionDate', type: 'date', isRequired: true, validators: [], },
        { key: 'devInstrucstion.instrucstionQuantity', field: 'instrucstionQuantity', type: 'decimal', isRequired: true, validators: [], },
      ],
      uniqueItems: ['orderDetailKey', 'devInstrucstionDate'],
    },
    validate: validate,
    upload: (data: OrderListRecordDto[]) => {
      setDtos(data);
    },
  };

  const upload = () => {
    props.upload(dtos).then(() => {
      setDtos([]);
      props.close();
    });
  };

  return (
    <Modal
      title={<FormattedMessage id='orderResponse.uploadDeliveryInstruction' />}
      width='1080px'
      footer={null}
      visible={props.visible}
      bodyStyle={{ paddingBottom: 80 }}
      destroyOnClose={true}
      onCancel={props.close}>
      <Spin 
        size='default'
        spinning={props.uploading}
      >
        <CommonExcelUpload {...uploadProps} />
      </Spin>
      <div className='search-button upload-buttom'>
        <Button className='search-close-btn' onClick={props.close}>
          <FormattedMessage id='common.cancel' />
        </Button>
        <Button className='search-search-btn' type='primary' onClick={upload} disabled={dtos.length === 0}>
          <FormattedMessage id='common.upload' />
        </Button>
      </div>
    </Modal >
  );
}

