// React APIs
import React, { useState, useEffect, KeyboardEvent, ChangeEvent } from 'react';
import { FormattedMessage, useIntl, } from 'react-intl';
import { Modal, Form, Select, Input, Button, Checkbox, Divider, Row, Col, Popconfirm } from 'antd';
import { DownloadOutlined } from "@ant-design/icons";

// 機能内部のAPIs
import { Field } from '../fields';
import { filterUtil, } from '../util'
import { DocCreateDto } from './api'
import './index.css';
import { Attachment, CommonExcelUpload, CommonExcelUploadProps, RowData } from '../common';
import { CommonFileUpload } from '../common/CommonFileUpload';

const { TextArea } = Input;

export interface Props {
  supplierOptions: Field[];
  categoryOptions: Field[];
  disabled: boolean;
  visible: boolean;
  selectVisible: boolean;
  setSelectVisible: () => void;
  formDataClearable: boolean;
  close: () => void;
  handleOpenCreateConfirm: (value: DocCreateDto) => void;
  downloadTemplate: () => void;
}

export function DocCreate(props: Props) {

  const [attachments, setAttachments] = useState<Attachment[]>([]);

  const [form] = Form.useForm<DocCreateDto>();
  const categoryTitle = <FormattedMessage id='docInfo.documentCategory' />;
  const supplierTitle = <FormattedMessage id='docInfo.destinationName' />;
  const remarksTitle = <FormattedMessage id='docInfo.sendContent' />;
  const uploadTitle = <FormattedMessage id='common.upload' />;
  const titleTitle = <FormattedMessage id='docInfo.title' />;
  const selectAll = <FormattedMessage id='docInfo.selectAll' />;
  const modalTitle = <FormattedMessage tagName='h4' id='docInfo.add' />;
  const confirm = <FormattedMessage id='common.confirm' />;
  const createCancel = <FormattedMessage id='common.cancel' />;
  const createConfirm = <FormattedMessage id='message.addConfirm' />;
  const intl = useIntl();
  const selectRequiredMsg = (itemTitle: any) => <FormattedMessage id='message.inputRequired' values={{ title: itemTitle, }} />

  useEffect(() => {
    setAttachments([]);
    setSelectState(false)  // 将选择全部按钮设为未选中
    form.resetFields()  // 清除表单所有数据
  }, [form, props.formDataClearable])

  useEffect(() => {
    if (props.visible === false) {   // 如果窗口被设为不可见(用户点击关闭按钮)，那么300毫秒后异步清除表单里的数据
      setAttachments([]);
      setSelectState(false)  // 将选择全部按钮设为未选中
      form.resetFields()  // 清除表单所有数据
    }
  }, [form, props.visible])

  const handleSubmit = (docCreateDto: DocCreateDto) => {
    docCreateDto.attachments = attachments;
    props.handleOpenCreateConfirm(docCreateDto);
  };


  const setUploadSuppliersListToSelect = (newSuppliersList: string[]) => {
    const selectedValue = form.getFieldValue('supplier')  // 获取在上传前已经选中的项

      let newSelect: string[] = newSuppliersList
      if ( !!selectedValue && selectedValue.length > 0) {
         newSelect = Array.from(new Set([...newSuppliersList, ...selectedValue]))  // 合并已经选中的项和文件上传的项
      }
      form.setFieldsValue({ supplier: newSelect }) //设置选中的项

      if (newSelect.length < props.supplierOptions.length) {  // 如果新的 select 小于总数，则将全选 checkbox 设为未选中，否则设为选中
        setSelectState(false)
      } else {
        setSelectState(true)
      }

      return newSelect
  }

  const validate = (rowDatas: RowData[]) => {
    const suppliers = props.supplierOptions
    const suppliersList: string[] = suppliers.map( supplier => supplier.value )
    const rowDatasList: string[] = rowDatas.map( rowData => `${rowData.destinationName}` )
    const errors: string[] = []

    if(!!suppliersList && !!rowDatasList && suppliersList.length > 0 && rowDatasList.length > 0) {  
      const tempRowDatasList = [...rowDatasList]  // 先进行一个浅拷贝，生成一个可能用于重复检测并且随时会被删除元素的数组
      const newRowDatasList = rowDatasList.filter( (rowData, index) => {  // 检测重复的字段并存储到错误列表，检测 送信先 是否合法，并且过滤得到合法的送信先
        if(tempRowDatasList.length > index + 1) {
          tempRowDatasList[index] = ''
          tempRowDatasList.forEach( (tempRowData, tempIndex) => {
            if(tempRowData === rowData) {
              tempRowDatasList[tempIndex] = ''
              errors.push(intl.formatMessage({id: 'message.excelUploadDuplicatedError'}, {i: index + 1, j: tempIndex + 1}))
            }
          })
        }
        
        const checkRowDataInSupplierResult = suppliersList.includes(rowData)
        if(!checkRowDataInSupplierResult) {
          errors.push(intl.formatMessage({id: 'docInfo.destinationNotExist'}, {i: index + 1, name: rowData}))
          
        }
        return checkRowDataInSupplierResult
      })



      setUploadSuppliersListToSelect(newRowDatasList)

    } else {
      errors.push(intl.formatMessage({id: 'message.uploadedFileEmpty'}))
    }
    if (errors.length > 0) {
      throw errors;
    }
    return rowDatas
  };

  const uploadProps: CommonExcelUploadProps = {
    row: {
      columns: [
        // 送信先リスト
        { key: 'docInfo.destinationList', field: 'destinationName', type: 'string', isRequired: true, validators: [], },
      ],
      uniqueItems: [],
    },
    validate: validate,
    upload: (datas: RowData[]) => {

      const selectFromUpload: string[] = datas.map((data) => `${data.destinationName}`) // 获取文件上传的想
      setUploadSuppliersListToSelect(selectFromUpload)

    },
  };

  /**
   * @param event 用户按下键盘事件
   * 禁止用户通过键盘回车输入换行符（阻止回车键默认行为）
   */
  const handleDisableEnterKeyDown = (event?: KeyboardEvent<HTMLTextAreaElement>) => {
    try {
      if (event?.key === 'Enter') {
        event.preventDefault();
      }
    } catch (error) {
      console.error('ERROR', 'Can not disable user entered "Enter(/n)" key in DocCreate.tsx -> handleDisableEnterKeyDown')
    }
  }

  /**
   * @param evnet 用户 onChange 事件
   * 将
   */
  const handleCheckUserInputAndClearEnterCharacter = (evnet?: ChangeEvent<HTMLTextAreaElement>) => {
    try {
      form.setFieldsValue({ title: evnet?.target?.value.replace(/[\r\n]/g, "") })
    } catch (error) {
      console.error('ERROR', 'Can not clear user typed "Enter(/n)" character in DocCreate.tsx -> handleCheckUserInputAndClearEnterCharacter')
    }
  }

  //单选框状态
  const [selectState, setSelectState] = useState(false)

  const width = "1024px"
  return (
    <Modal
      width={width}
      title={modalTitle}
      footer={null}
      visible={props.visible}
      closable={false}
      afterClose={props.close}
      destroyOnClose={true}
      zIndex={700}>
      <Form
        className="form"
        form={form}
        preserve={false}
        onFinish={handleSubmit}
        layout={'horizontal'}
        colon={false}
        requiredMark={true}
        size={'large'}
        labelCol={{ span: 7 }}
        wrapperCol={{ span: 15 }}
      >
        <Row>
          <Col span={13}>
            
            <Row>
              <Col span={24}>
                <Form.Item name='supplier' label={supplierTitle}
                  rules={[
                    {
                      required: true,
                      message: intl.formatMessage({ id: 'estimate.nullError' }, { name: intl.formatMessage({ id: 'docInfo.supplier' }) })
                    },
                  ]}
                >
                  <Select
                    allowClear // 添加清空按钮
                    placeholder={selectRequiredMsg(supplierTitle)} // 默认显示文本
                    options={props.supplierOptions}   // 选项数据源
                    mode={'multiple'}  //select模式，详见 Ant d 的select组件
                    maxTagCount={5}  // 选择框中最大允许显示数量(不包括最后提示还有额外多少个的数字选项)
                    listHeight={290}  // 下拉列表长度
                    open={props.selectVisible} //下拉的开启状态
                    showArrow={true} //是否展示下拉箭头
                    size={'large'} //选择框的大小
                    filterOption={filterUtil.selectFilter}  //过滤下拉条件
                    onChange={ //当被选中的项发生改变时调用此事件，如果选中的项目数小于总项目数，那么将全选checkbox改为未选中，否则改为选中
                      () => {
                        if (form.getFieldValue('supplier').length < props.supplierOptions.length) {
                          setSelectState(false)
                        } else {
                          setSelectState(true)
                        }
                      }
                    }
                    getPopupContainer={  //设置下拉跟随父节点滚动
                      triggerNode => {
                        return triggerNode.parentNode || document.body;
                      }
                    }
                    dropdownRender={allSelectValue => (  // 全选按钮 2021/11/xx by ZhiTong.Bian@ibm.com
                      <div>
                        <div style={{ padding: '4px 8px 8px 8px', cursor: 'pointer' }}>
                          <Checkbox checked={selectState} onChange={(e) => {
                            // 判断 是否 选中
                            if (e.target.checked === true) {
                              setSelectState(true) //选中时 给 checked 改变状态
                              // 当选的时候 把所有列表值赋值给functionIds
                              form.setFieldsValue({
                                supplier: props.supplierOptions.map((item) => item.value) //如果选中 给select 赋data里面所有的值
                              })
                            } else {
                              setSelectState(false)
                              form.setFieldsValue({
                                supplier: [] //如果取消全选 这清空select全部选中状态
                              })
                            }
                          }}>{selectAll}</Checkbox>
                          {/* <div onClick={props.setSelectVisible}>close</div> */}
                        </div>
                        <Divider style={{ margin: '0' }} />
                        {/* Option 标签值 */}
                        {allSelectValue}
                      </div>
                    )}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row style={{marginTop: '340px'}}>
              <Col span={24}>
                <div style={{ display: 'inline-block' }}>
                    <Button onClick={props.downloadTemplate} style={{ margin: '0 0 0 10px', marginRight: 10 }}>
                        <DownloadOutlined />
                        <FormattedMessage id='supplierEstimates.templateDownload' />
                    </Button>
                </div>
              </Col>
            </Row>

            <Row style={{marginTop: '20px'}}>
              <Col span={7} style={{textAlign: 'right', paddingRight: '10px'}}>
                {intl.formatMessage({id: 'docInfo.uploadDestination'})}
              </Col>
              <Col span={15} >
                <div className='docDestinationNameUpload'>
                  <CommonExcelUpload {...uploadProps} />
                </div>
              </Col>
            </Row>
          </Col>
          <Col span={11}>
            <Form.Item name='documentCategory' label={categoryTitle}
              rules={[
                {
                  required: true,
                  message: intl.formatMessage({ id: 'estimate.nullError' }, { name: intl.formatMessage({ id: 'docInfo.documentCategory' }) }),
                },
              ]}>
              <Select placeholder={selectRequiredMsg(categoryTitle)}
                options={props.categoryOptions} />
            </Form.Item>
            <Form.Item name='title' label={titleTitle}
              rules={[
                {
                  required: true,
                  message: intl.formatMessage({ id: 'estimate.nullError' }, { name: intl.formatMessage({ id: 'docInfo.title' }) }),
                },
              ]}>
              <TextArea showCount autoSize allowClear maxLength={120} onKeyDown={handleDisableEnterKeyDown} onChange={handleCheckUserInputAndClearEnterCharacter} />
            </Form.Item>
            <Form.Item
              name='message'
              label={remarksTitle}
              rules={[
                {
                  required: true,
                  message: intl.formatMessage({ id: 'estimate.nullError' }, { name: intl.formatMessage({ id: 'docInfo.sendContent' }) })
                },
              ]}>
              <TextArea
                // rows={8} 
                maxLength={1000}
                showCount
                autoSize={{ minRows: 11 }}
              />
            </Form.Item>
            <Form.Item name='files' label={uploadTitle}>
              <Form.Item name='attachments' valuePropName='fileList' noStyle>
                <CommonFileUpload
                  attachments={[]}
                  canUpload={true}
                  persist={(uuid: string) => {
                    setAttachments([...attachments, { id: -1, uuid: uuid }]);
                  }}
                  remove={(uuid: string) => {
                    setAttachments(attachments.filter((attachment: Attachment) => attachment.uuid !== uuid))
                  }}
                />
              </Form.Item>
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={5} offset={16}>
            <Row>
              <Col span={5} offset={7}>
                <Button onClick={props.close}>
                  <FormattedMessage id='common.close' />
                </Button>
              </Col>
              <Col span={5} offset={3}>


                <Popconfirm
                  title={createConfirm}
                  onConfirm={() => {
                    form.submit()
                  }}
                  okText={confirm}
                  cancelText={createCancel}
                >
                  <Button
                    className='submitBtn'
                    htmlType='submit'
                    disabled={props.disabled}
                  >
                    <FormattedMessage id='common.add' />
                  </Button>
                </Popconfirm>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}
