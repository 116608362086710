import React, { useState, useEffect } from 'react';
import { FormattedMessage } from "react-intl";
import { Input, Button, } from "antd";

import './index.css';

interface Props {
  value?: string;
  onChange: (value?: string) => void;
}

export function InputFilter(props: Props) {

  const [value, setValue] = useState(props.value);

  useEffect(() => {
    setValue(props.value);
  }, [props.value]);

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {

    setValue(event.target.value);
  };

  const onSearch = () => {

    props.onChange(value);
  };

  const onReset = () => {

    setValue(''); 
  };

  return (
    <div className='filter'>
      <div>
        <Input value={value} onChange={onChange} />
      </div>
      <div className='filterRow'>
        <Button type='primary' onClick={onSearch}><FormattedMessage id='common.conditions' /></Button>
        <Button className='reset-btn' onClick={onReset}><FormattedMessage id='common.reset' /></Button>
      </div>
    </div>
  );
}
