import React from 'react';
import { FormattedMessage, } from 'react-intl';
import { Button, Modal, } from 'antd';

import { Detail, } from '../common'
import { SupplierEstimatesDetailDto } from './api';
import './index.css';
import { displayUtil } from '../util';
import { useUser } from '../authorization';
import { useFields } from '../fields';

export interface Props {
  visible: boolean;
  detail?: SupplierEstimatesDetailDto;
  accept: (detail?: SupplierEstimatesDetailDto) => void;
  reject: (detail?: SupplierEstimatesDetailDto) => void;
  close: () => void;
}

export function SupplierEstimatesDetailDetail(props: Props) {

  // 共通のstates
  const user = useUser();
  const responseStatusOptions = useFields('responseStatus');
  // タイトル
  const cols: [JSX.Element, any][] = [];
  // 回答状態    			
  cols.push([<FormattedMessage id='estimate.responseStatus' />, displayUtil.field(responseStatusOptions)( props.detail?.responseStatus),]);
  // 品番    			
  cols.push([<FormattedMessage id='estimate.item' />, props.detail?.item,]);
  // 品名    			
  cols.push([<FormattedMessage id='estimate.itemName' />, props.detail?.itemName,]);
  // 見積適用要望日    			
  cols.push([<FormattedMessage id='estimate.estimateStartDate' />, displayUtil.date(props.detail?.estimateStartDate),]);
  // リードタイム    			
  cols.push([<FormattedMessage id='estimate.leadTime' />, props.detail?.leadTime,]);
  // 最低発注数量    			
  cols.push([<FormattedMessage id='estimate.moq' />, props.detail?.moq,]);
  // 最小梱包数量    			
  cols.push([<FormattedMessage id='estimate.mpq' />, props.detail?.mpq,]);
  // 単価    			
  cols.push([<FormattedMessage id='estimate.unitPrice' />, props.detail?.unitPrice,]);
  // 単価通貨コード    			
  cols.push([<FormattedMessage id='estimate.unitCurrency' />, props.detail?.unitCurrency,]);
  // 修理費    			
  cols.push([<FormattedMessage id='estimate.repairsCost' />, props.detail?.repairsCost,]);
  // 修理費通貨コード    			
  cols.push([<FormattedMessage id='estimate.repairsCurrency' />, props.detail?.repairsCurrency,]);

  return (
    <Modal
      title={<FormattedMessage tagName='h4' id='supplierEstimates.detailDetail' />}
      footer={null}
      visible={props.visible}
      closable={true}
      onCancel={props.close}
      width={1080}
      zIndex ={1}>
      <Detail
        title={<FormattedMessage id='supplierEstimates.detailDetail' />}
        cols={cols}
      />
      {!user?.isSupplier &&
        <div className='search-button'>
          <Button className='search-close-btn' disabled={props.detail?.responseStatus === '003'} onClick={() => props.accept(props.detail)}>
            <FormattedMessage id='supplierEstimates.accept' />
          </Button>
          <Button className='search-search-btn' disabled={props.detail?.responseStatus === '004'} onClick={() => props.reject(props.detail)}>
            <FormattedMessage id='supplierEstimates.reject' />
          </Button>
        </div>
      }
    </Modal >
  );
}
