// React APIs
import dayjs from 'dayjs';
import React, { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useLocation, useHistory, useParams, } from "react-router-dom";
// Antd APIs
// Library APIs
import XLSX from 'xlsx';
import { DetailStates, ListStates } from '../common';
import { displayUtil } from '../util';

// 共通のAPIs
// 機能内部のAPIs
import { get, getAll, search2Dto, dto2search, SupplierEstimatesDto, SupplierEstimatesSearchDto, getById, SupplierEstimatesDetailDto, downloadPassedDto, merge, removeById, persist, downloadTemplateApi } from './api';

import { Props as ListProps } from './SupplierEstimatesList'
import { Props as SearchProps } from './SupplierEstimatesSearch'
import { Props as DetailProps } from './SupplierEstimatesDetail';
import { Props as DetailDetailProps } from './SupplierEstimatesDetailDetail';
import { Props as UploadProps } from './SupplierEstimatesUpload';
import { Props as AnswerUploadProps } from './SupplierEstimatesAnswerUpload';
import { useFields } from '../fields';
import { useUser } from '../authorization';

/**
 * 所要計画のコンポネート
 */
export function SupplierEstimatesHoc(
  List: React.ComponentType<ListProps>,
  Search: React.ComponentType<SearchProps>,
  Detail: React.ComponentType<DetailProps>,
  DetailDetail: React.ComponentType<DetailDetailProps>,
  Upload: React.ComponentType<UploadProps>,
  AnswerUpload: React.ComponentType<AnswerUploadProps>) {

  // 共通のstates
  const intl = useIntl();
  // URLのID
  const { id: urlId } = useParams<{ id?: string }>();
  const { detailId: urlDetailId } = useParams<{ detailId?: string }>();
  // URLのquery文字列
  const { search } = useLocation();
  // URL変更のハンドル
  const { push, goBack } = useHistory();
  // 検索条件（フィルター、ページとソート順）
  const [searchDto, setSearchDto] = useState<SupplierEstimatesSearchDto>(search2Dto(search));

  // 一覧のstates
  // 画面loading状態
  const [listData, setListData] = useState<ListStates<SupplierEstimatesDto>>({ loading: false, total: 0, data: [] });

  // 検索のstates
  // アップロード画面の表示状態
  const [requestVisible, setRequestVisible] = useState<boolean>(false);
  // 検査画面の表示状態
  const [searchVisible, setSearchVisible] = useState<boolean>(false);

  const [answerVisible, setAnswerVisible] = useState<boolean>(false);

  // upload loading
  const [supplierEstimatesUploading, setSupplierEstimatesUploading] = useState<boolean>(false);
  // detail loading
  const [detailLoading, setDetailLoading] = useState<boolean>(false);

  // 詳細のstates
  const [detailData, setDetailData] = useState<DetailStates<SupplierEstimatesDto>>({ visible: false });
  const [detailDetailData, setDetailDetailData] = useState<DetailStates<SupplierEstimatesDetailDto>>({ visible: false });
  const buyerOptions = useFields('buyer');
  const supplierOptions = useFields('supplier');
  const statusOptions = useFields('status');
  const user = useUser();
  const responseStatusOptions = useFields('responseStatus');
  // Refresh
  const [refresh, setRefresh] = useState<boolean>(false);
  // URLのQuery変更によって検索を再実施する
  useEffect((): void => {
    // setDetailData(data => ({ ...data, visible: false }));
    setDetailDetailData(data => ({ ...data, visible: false }));

    if (!!urlId) {
      if (/^\d+$/.test(urlId)) {
        getById(urlId)
          .then((record: SupplierEstimatesDto) => {
            setDetailData({ visible: true, data: record });

            console.log('urlDetailId:' + urlDetailId);

            if (!!urlDetailId) {
              if (/^\d+$/.test(urlDetailId)) {
                setDetailDetailData({ visible: true, data: record.details.find(dto => (dto.id === +urlDetailId)) });
              }
            }
          })
          .catch(() => {
          });
      }
    } else {
      // 画面loading状態を変更する
      setListData(data => ({ ...data, loading: true }));
      // 検索条件変更を反映する
      setSearchDto(search2Dto(search));

      get(search)
        .then((para: [number, SupplierEstimatesDto[]]) => {
          const [count, vos] = para;

          setListData({ loading: false, total: count, data: vos });
        })
        .catch((error) => {

          setListData(data => ({ ...data, loading: false }));

          throw error;
        });
    }
  }, [urlId, urlDetailId, search, refresh]);

  // 検索条件を変更する
  const setSearch = (researchDto: SupplierEstimatesSearchDto) => {

    // 期間範囲の編集
    if (!!researchDto.requestDate && !!researchDto.requestDate[0]) {
      researchDto.requestDate[0] = researchDto.requestDate[0].startOf('day');
    }
    if (!!researchDto.requestDate && !!researchDto.requestDate[1]) {
      researchDto.requestDate[1] = researchDto.requestDate[1].endOf('day');
    }

    const query = dto2search(researchDto)
    // URLを再設定する
    push(`/supplierestimates${query}`);
  };

  const remove = (dto: SupplierEstimatesDto) => {
    removeById(dto.id, dto.version).then(() => {
      setRefresh(!refresh)
      // setListData(tempListData => ({ ...tempListData, data: tempListData.data.filter(item => (item.id !== dto.id)) }));
    });
  };

  // 受領済
  const finish = (dto?: SupplierEstimatesDto) => {

    if (!!dto) {
      merge(dto.id, { ...dto, status: '002' });
    }
    setDetailData({ visible: false });
    push('/supplierestimates');
  };

  // すべて採用、不採用または一部採用、不採用
  const answerAll = (id: number, detailDtos: SupplierEstimatesDetailDto[], responseStatus: string, dto?: SupplierEstimatesDto) => {
    if (!!dto && id !== 0 && !!detailDtos && detailDtos.length > 0) {
      const detailDtosData: SupplierEstimatesDetailDto[] = []
      detailDtos.forEach( detailDto => detailDtosData.push({...detailDto, responseStatus}) )
      const dtoData: SupplierEstimatesDto = {...dto, details: detailDtosData}
      setDetailLoading(true)
      merge(dtoData.id, dtoData).then((result: SupplierEstimatesDto) => {
        setDetailLoading(false)
        setRefresh(!refresh)
      }).catch(() => {
        setDetailLoading(false)
      })
    }
  };

  // 採用
  const accept = (dto?: SupplierEstimatesDto) => (detail?: SupplierEstimatesDetailDto) => {
    if (!!dto && !!detail) {
      setDetailLoading(true)
      merge(dto.id, { ...dto, details: [{ ...detail, responseStatus: '003' }] }).then((result: SupplierEstimatesDto) => {
        setRefresh(!refresh);
        setDetailLoading(false);
      }).catch(() => {
        setDetailLoading(false);
      });
    }
  };

  // 不採用
  const reject = (dto?: SupplierEstimatesDto) => (detail?: SupplierEstimatesDetailDto) => {
    if (!!dto && !!detail) {
      setDetailLoading(true)
      merge(dto.id, { ...dto, details: [{ ...detail, responseStatus: '004' }] }).then((result: SupplierEstimatesDto) => {
        setRefresh(!refresh)
        setDetailLoading(false);
      }).catch(() => {
        setDetailLoading(false);
      });

    }
  };

  const download = (id?: number) => {
    if (!!id) {

      setListData(data => ({ ...data, loading: true }));

      getById(`${id}`)
        .then((dto: SupplierEstimatesDto) => {

          setListData(data => ({ ...data, loading: false }));

          let excelData: any[][] = new Array<Array<any>>();;
          // excelData.push([intl.formatMessage({ id: 'supplierEstimates.detailList' })]);
          // excelData.push([intl.formatMessage({ id: 'estimate.estimateContent' })]);
          // excelData.push([intl.formatMessage({ id: 'estimate.status' }),
          // displayUtil.field(statusOptions)(dto.status),
          // intl.formatMessage({ id: 'estimate.requestNo' }),
          // dto.requestNo,
          // intl.formatMessage({ id: 'estimate.requestDate' }),
          // displayUtil.date(dto.requestDate)]);
          // excelData.push([intl.formatMessage({ id: 'estimate.buyer' }),
          // dto.buyer,
          // intl.formatMessage({ id: 'estimate.buyerName' }),
          // displayUtil.field(buyerOptions)(dto.buyer),
          // !user?.isSupplier ? intl.formatMessage({ id: 'estimate.supplier' }) : '',
          // !user?.isSupplier ? dto.supplier : ''
          // ])
          // excelData.push([!user?.isSupplier ? intl.formatMessage({ id: 'estimate.supplierName' }) : '',
          // !user?.isSupplier ? displayUtil.field(supplierOptions)(dto.supplier) : '',
          // intl.formatMessage({ id: 'estimate.purchasingManager' }),
          // dto.purchasingManagerName])
          let rowTitle = [
            intl.formatMessage({ id: 'estimate.status' }),
            intl.formatMessage({ id: 'estimate.requestNo' }),
            intl.formatMessage({ id: 'estimate.requestDate' }),
            intl.formatMessage({ id: 'estimate.buyer' }),
            intl.formatMessage({ id: 'estimate.buyerName' })];
          // excelData.push(rowTitle);
          if (!user?.isSupplier) {
            rowTitle.push(intl.formatMessage({ id: 'estimate.supplier' }));
            rowTitle.push(intl.formatMessage({ id: 'estimate.supplierName' }));
          }
          rowTitle.push(
            intl.formatMessage({ id: 'estimate.purchasingManager' }),
            intl.formatMessage({ id: 'estimate.responseStatus' }),
            intl.formatMessage({ id: 'estimate.item' }),
            intl.formatMessage({ id: 'estimate.itemName' }),
            intl.formatMessage({ id: 'estimate.estimateStartDate' }),
            intl.formatMessage({ id: 'estimate.leadTime' }),
            intl.formatMessage({ id: 'estimate.moq' }),
            intl.formatMessage({ id: 'estimate.mpq' }),
            intl.formatMessage({ id: 'estimate.unitPrice' }),
            intl.formatMessage({ id: 'estimate.unitCurrency' }),
            intl.formatMessage({ id: 'estimate.repairsCost' }),
            intl.formatMessage({ id: 'estimate.repairsCurrency' }),
            intl.formatMessage({ id: 'estimate.responseUser' }),
            intl.formatMessage({ id: 'common.userModifiedDate' }),
          );

          excelData.push(rowTitle);
          dto.details.forEach(vo => {
            let rowData = new Array<any>();
            rowData.push(displayUtil.field(statusOptions)(dto.status));
            rowData.push(dto.requestNo);
            rowData.push(displayUtil.date(dto.requestDate));
            rowData.push(dto.buyer);
            rowData.push(displayUtil.field(buyerOptions)(dto.buyer));
            if (!user?.isSupplier) {
              rowData.push(dto.supplier)
              rowData.push(displayUtil.field(supplierOptions)(dto.supplier));
            }
            rowData.push(dto.purchasingManagerName);
            rowData.push(displayUtil.field(responseStatusOptions)(vo.responseStatus));
            rowData.push(vo.item);
            rowData.push(vo.itemName);
            rowData.push(displayUtil.date(vo.estimateStartDate));
            rowData.push(vo.leadTime);
            rowData.push(vo.moq);
            rowData.push(vo.mpq);
            rowData.push(vo.unitPrice);
            rowData.push(vo.unitCurrency);
            rowData.push(vo.repairsCost);
            rowData.push(vo.repairsCurrency);
            rowData.push(vo.userName);
            rowData.push(displayUtil.date(vo.supplierModifiedTime));
            excelData.push(rowData);
          });
          const worksheet = XLSX.utils.aoa_to_sheet(excelData);
          const workbook = XLSX.utils.book_new();

          XLSX.utils.book_append_sheet(workbook, worksheet, !user?.isSupplier ? intl.formatMessage({ id: 'supplierEstimates' }) : intl.formatMessage({ id: 'supplierEstimates.supplier' }));
          XLSX.writeFile(workbook, `${!user?.isSupplier ? intl.formatMessage({ id: 'supplierEstimates' }) : intl.formatMessage({ id: 'supplierEstimates.supplier' })}_${dayjs().format('YYYYMMDDHHmmss')}.xlsx`);
        })
        .catch(() => {

          setListData(data => ({ ...data, loading: false }));
        });
    }
  };

  const closeDetailDetail = () => {
    setDetailDetailData({ visible: false });
    if (dto2search(searchDto) !== '') {
      goBack();
    } else if (!!urlId) {
      push(`/supplierestimates/${urlId}`);

    } else {
      push('/supplierestimates');
    }
  };

  const closeDetail = () => {
    setDetailData({ visible: false });
    if (dto2search(searchDto) !== '') {
      goBack();
    } else {
      push('/supplierestimates');
    }
  };

  const upload = (dto: SupplierEstimatesDto) => {
    setSupplierEstimatesUploading(true)
    persist(dto).then((para: [number, SupplierEstimatesDto[]]) => {
      const [count, vos] = para;
      setListData({ loading: false, total: count, data: vos });
      setRequestVisible(false)
      setSupplierEstimatesUploading(false)
    }).catch( () => {
      setSupplierEstimatesUploading(false)
    })
  }

  const uploadAnswer = (dto: SupplierEstimatesDto) => {
    merge(dto.id, dto).then(value => {
      setDetailData({ ...detailData, data: value });
    })
  }

  const downloadModel = () => {

    const downloadSearchDto = { ...searchDto, rowCount: 0 };

    setListData(data => ({ ...data, loading: true }));

    get(dto2search(downloadSearchDto))
      .then((para: [number, SupplierEstimatesDto[]]) => {

        setListData(data => ({ ...data, loading: false }));

        const [, vos] = para;

        const excelData = [
          [
            intl.formatMessage({ id: 'estimate.status' }),
            intl.formatMessage({ id: 'estimate.requestNo' }),

            intl.formatMessage({ id: 'estimate.supplier' }),
            intl.formatMessage({ id: 'estimate.supplierName' }),

            intl.formatMessage({ id: 'estimate.purchasingManager' }),
            intl.formatMessage({ id: 'estimate.requestDate' })
          ],
          ...(vos.map(vo => [
            displayUtil.field(statusOptions)(vo.status),
            vo.requestNo,

            vo.supplier,
            displayUtil.field(supplierOptions)(vo.supplier),

            vo.purchasingManagerName,
            displayUtil.date(vo.requestDate),
          ]))
        ];

        const excelSupplierData = [
          [
            intl.formatMessage({ id: 'estimate.status' }),
            intl.formatMessage({ id: 'estimate.requestNo' }),

            intl.formatMessage({ id: 'estimate.buyerName' }),
            intl.formatMessage({ id: 'estimate.supplier' }),
            intl.formatMessage({ id: 'estimate.supplierName' }),
            intl.formatMessage({ id: 'estimate.purchasingManager' }),
            intl.formatMessage({ id: 'estimate.requestDate' })
          ],
          ...(vos.map(vo => [
            displayUtil.field(statusOptions)(vo.status),
            vo.requestNo,
            displayUtil.field(buyerOptions)(vo.buyer),
            vo.supplier,
            displayUtil.field(supplierOptions)(vo.supplier),
            vo.purchasingManagerName,
            displayUtil.date(vo.requestDate),
          ]))
        ];


        const worksheet = XLSX.utils.aoa_to_sheet(!!user?.isSupplier ? excelSupplierData : excelData);
        const workbook = XLSX.utils.book_new();

        XLSX.utils.book_append_sheet(workbook, worksheet, intl.formatMessage({ id: 'supplierEstimates' }));
        const fileTitle = !!user?.isSupplier ? intl.formatMessage({ id: 'supplierEstimates.supplier' }) : intl.formatMessage({ id: 'supplierEstimates' })
        XLSX.writeFile(workbook, `${fileTitle}_${dayjs().format('YYYYMMDDHHmmss')}.xlsx`);

      }).catch(() => {

        setListData(data => ({ ...data, loading: false }));
      });

    // download en, jp, zh language template


  }

  const downloadTemplate = () => {
    downloadTemplateApi();
  }

  const downloadPassed = () => {
    const downloadSearchDto = { ...searchDto, rowCount: 0 };

    setListData(data => ({ ...data, loading: true }));

    getAll(dto2search(downloadSearchDto))
      .then((para: downloadPassedDto[]) => {

        setListData(data => ({ ...data, loading: false }));
        
        const rightData = para.filter(p => p.responseStatus === '003')

        const excelData = [
          [
            intl.formatMessage({ id: 'estimate.status' }),
            intl.formatMessage({ id: 'estimate.requestNo' }),

            intl.formatMessage({ id: 'estimate.supplier' }),
            intl.formatMessage({ id: 'estimate.supplierName' }),

            intl.formatMessage({ id: 'estimate.purchasingManager' }),
            intl.formatMessage({ id: 'estimate.requestDate' }),


            // detail
            intl.formatMessage({ id: 'estimate.responseStatus' }), // 回答状態
            intl.formatMessage({ id: 'estimate.item' }), // 品番
            intl.formatMessage({ id: 'estimate.itemName' }), // 品名
            intl.formatMessage({ id: 'estimate.estimateStartDate' }), // 見積適用要望日
            intl.formatMessage({ id: 'estimate.leadTime' }), // リードタイム
            intl.formatMessage({ id: 'estimate.moq' }), // 最低発注数量
            intl.formatMessage({ id: 'estimate.mpq' }), // 最小梱包数量
            intl.formatMessage({ id: 'estimate.unitPrice' }), // 単価
            intl.formatMessage({ id: 'estimate.unitCurrency' }), // 単価通貨コード
            intl.formatMessage({ id: 'estimate.repairsCost' }), // 修理費
            intl.formatMessage({ id: 'estimate.repairsCurrency' }), // 修理費通貨コード
            intl.formatMessage({ id: 'common.userName' }), // オペレーター
            intl.formatMessage({ id: 'common.userModifiedDate' }), // 更新日

          ],
          ...(rightData.map(vo => [
            displayUtil.field(statusOptions)(vo.status),
            vo.requestNo,

            vo.supplier,
            displayUtil.field(supplierOptions)(vo.supplier),

            vo.purchasingManagerName,
            displayUtil.date(vo.requestDate),

            // detail
            displayUtil.field(responseStatusOptions)(vo.responseStatus), // 回答状態
            vo.item, // 品番
            vo.itemName, // 品名
            displayUtil.date(vo.estimateStartDate), // 見積適用要望日
            vo.leadTime, // リードタイム
            vo.moq, // 最低発注数量
            vo.mpq, // 最小梱包数量
            vo.unitPrice, // 単価
            vo.unitCurrency, // 単価通貨コード
            vo.repairsCost, // 修理費
            vo.repairsCurrency, // 修理費通貨コード
            vo.detailUserName, // オペレーター
            displayUtil.date(vo.supplierModifiedTime) // 更新日
          ]))
        ];


        const excelSupplierData = [
          [
            intl.formatMessage({ id: 'estimate.status' }),
            intl.formatMessage({ id: 'estimate.requestNo' }),

            intl.formatMessage({ id: 'estimate.buyer' }),
            intl.formatMessage({ id: 'estimate.buyerName' }),

            intl.formatMessage({ id: 'estimate.supplier' }),
            intl.formatMessage({ id: 'estimate.supplierName' }),

            intl.formatMessage({ id: 'estimate.purchasingManager' }),
            intl.formatMessage({ id: 'estimate.requestDate' }),

            // detail
            intl.formatMessage({ id: 'estimate.responseStatus' }), // 回答状態
            intl.formatMessage({ id: 'estimate.item' }), // 品番
            intl.formatMessage({ id: 'estimate.itemName' }), // 品名
            intl.formatMessage({ id: 'estimate.estimateStartDate' }), // 見積適用要望日
            intl.formatMessage({ id: 'estimate.leadTime' }), // リードタイム
            intl.formatMessage({ id: 'estimate.moq' }), // 最低発注数量
            intl.formatMessage({ id: 'estimate.mpq' }), // 最小梱包数量
            intl.formatMessage({ id: 'estimate.unitPrice' }), // 単価
            intl.formatMessage({ id: 'estimate.unitCurrency' }), // 単価通貨コード
            intl.formatMessage({ id: 'estimate.repairsCost' }), // 修理費
            intl.formatMessage({ id: 'estimate.repairsCurrency' }), // 修理費通貨コード
            intl.formatMessage({ id: 'common.userName' }), // オペレーター
            intl.formatMessage({ id: 'common.userModifiedDate' }), // 更新日
          ],
          ...(rightData.map(vo => [
            displayUtil.field(statusOptions)(vo.status),
            vo.requestNo,

            vo.buyer,
            displayUtil.field(buyerOptions)(vo.buyer),

            vo.supplier,
            displayUtil.field(supplierOptions)(vo.supplier),

            vo.purchasingManagerName,
            displayUtil.date(vo.requestDate),

            // detail
            displayUtil.field(responseStatusOptions)(vo.responseStatus), // 回答状態
            vo.item, // 品番
            vo.itemName, // 品名
            displayUtil.date(vo.estimateStartDate), // 見積適用要望日
            vo.leadTime, // リードタイム
            vo.moq, // 最低発注数量
            vo.mpq, // 最小梱包数量
            vo.unitPrice, // 単価
            vo.unitCurrency, // 単価通貨コード
            vo.repairsCost, // 修理費
            vo.repairsCurrency, // 修理費通貨コード
            vo.detailUserName, // オペレーター
            displayUtil.date(vo.supplierModifiedTime) // 更新日
          ]))
        ];

        const worksheet = XLSX.utils.aoa_to_sheet(!!user?.isSupplier ? excelSupplierData : excelData);
        const workbook = XLSX.utils.book_new();

        XLSX.utils.book_append_sheet(workbook, worksheet, intl.formatMessage({ id: 'supplierEstimates' }));
        const fileTitle = !!user?.isSupplier ? intl.formatMessage({ id: 'supplierEstimates.supplier' }) : intl.formatMessage({ id: 'supplierEstimates' })
        XLSX.writeFile(workbook, `${fileTitle}(${intl.formatMessage({ id: 'estimate.approve' })})_${dayjs().format('YYYYMMDDHHmmss')}.xlsx`);

      }).catch(() => {
        
        setListData(data => ({ ...data, loading: false }));
      });
  }
  // 検索の処理
  const handleSearch = (value: SupplierEstimatesSearchDto) => {
    setSearchVisible(false);
    setSearch(value);
  }

  const reset = (rowCount: number) => {
    setSearch({

      // 入札依頼番号 
      requestNo: '',
      // 購入元コード    
      buyers: [],
      // 購入先コード    
      suppliers: [],
      // 受注者名称
      supplierName: '',
      // 購買担当コード    
      purchasingManagerName: '',
      // 依頼日    
      requestDate: null,
      // 案件状態 
      status: [],

      rowCount: rowCount

    } as SupplierEstimatesSearchDto)
  }

  const listProps: ListProps = {
    // 画面loading状態
    loading: listData.loading,
    // 件数合計
    total: listData.total,
    // 一覧画面の表示データ
    data: listData.data,
    // 検索条件（フィルター、ページとソート順）
    searchDto: searchDto,
    // 削除する
    remove: remove,
    // 依頼画面を表示する
    openRequestModal: () => setRequestVisible(true),
    // 検査画面を表示する
    openSearchModal: () => setSearchVisible(true),
    // フィルター、ページ又はソート順の変更より再検索する
    handleResearch: setSearch,

    handleSearch: handleSearch,

    downloadModel: downloadModel,

    downloadTemplate: downloadTemplate,

    downloadPassed: downloadPassed,
    reset: reset
  };

  const searchProps: SearchProps = {
    // 検査画面の表示状態
    visible: searchVisible,
    searchDto: searchDto,
    handleSearch: handleSearch,
    close: () => setSearchVisible(false),
  };

  const detailProps: DetailProps = {
    // 詳細画面の表示状態
    visible: detailData.visible,
    detail: detailData.data,
    download: download,
    accept: accept(detailData.data),
    reject: reject(detailData.data),
    // answerList: () => setAnswerVisible(true),
    answerList: () => setAnswerVisible(true),
    finish: finish,
    close: closeDetail,
    answerAll: answerAll,
    loading: detailLoading,
  };

  const detailDetailProps: DetailDetailProps = {
    // 詳細画面の表示状態
    visible: detailDetailData.visible,
    detail: detailDetailData.data,
    accept: accept(detailData.data),
    reject: reject(detailData.data),
    close: closeDetailDetail,
  };

  const uploadProps: UploadProps = {
    visible: requestVisible,
    upload: upload,
    close: () => setRequestVisible(false),
    uploading: supplierEstimatesUploading,
  };

  const answerUploadProps: AnswerUploadProps = {
    urlId: urlId,
    visible: answerVisible,
    upload: uploadAnswer,
    close: () => setAnswerVisible(false),
  };

  return (
    <>
      <List {...listProps} />
      <Search {...searchProps} />
      <Upload {...uploadProps} />
      <AnswerUpload {...answerUploadProps} />
      <Detail {...detailProps} />
      <DetailDetail {...detailDetailProps} />
    </>
  );
}
