// React APIs
import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { DetailStates, ListStates } from "../common";

// Library APIs
import XLSX from 'xlsx';

// 機能内部のAPIs
import { AcceptanceDetailRecordDto, AcceptanceListRecordDto, AcceptanceSearchDto, dto2search, get, getById, search2Dto, readAll as readAllData } from "./api";
import { Props as ListProps } from './AcceptanceList'
import { Props as SearchProps } from './AcceptanceSearch'
import { Props as DetailProps } from './AcceptanceDetail'
import { displayUtil } from "../util";
import dayjs from "dayjs";

// 検収のコンポネート
export function AcceptanceHoc(
    Detail: React.ComponentType<DetailProps>,
    List: React.ComponentType<ListProps>,
    Search: React.ComponentType<SearchProps>) {

    // 共通のstates
    const intl = useIntl();
    // URLのID
    const { id: urlId } = useParams<{ id?: string }>();
    // URLのquery文字列
    const { search } = useLocation();
    // URL変更のハンドル
    const { push, goBack } = useHistory();
    // 検索条件（フィルター、ページとソート順）
    const [searchDto, setSearchDto] = useState<AcceptanceSearchDto>(search2Dto(search))
    // 一覧のstates
    const [listData, setListData] = useState<ListStates<AcceptanceListRecordDto>>({ loading: false, total: 0, data: [] });
    // 検索のstates
    // 検査画面の表示状態
    const [searchVisible, setSearchVisible] = useState<boolean>(false);
    // 詳細のstates
    const [detailData, setDetailData] = useState<DetailStates<AcceptanceDetailRecordDto>>({ visible: false });
    // URLのQuery変更によって検索を再実施する
    useEffect(() => {
        setDetailData(data => ({ ...data, visible: false }));

        if (!!urlId) {
            getById(urlId)
                .then((record: AcceptanceDetailRecordDto) => {
                    setDetailData({ visible: true, data: record });
                })
                .catch(() => {
                });
        } else {
            setListData(data => ({ ...data, loading: true }))
            setSearchDto(search2Dto(search));

            get(search)
                .then((para: [number, AcceptanceListRecordDto[]]) => {
                    const [count, vos] = para;

                    setListData({ loading: false, total: count, data: vos })
                })
                .catch(() => {
                    setListData(data => ({ ...data, loading: false }))
                });
        }
    }, [urlId, search]);

    // 検索条件を変更する
    const setSearch = (researchDto: AcceptanceSearchDto) => {

        console.log('researchDto', researchDto)

        // 期間範囲の編集
        if (!!researchDto.acceptanceDate && !!researchDto.acceptanceDate[0]) {
            researchDto.acceptanceDate[0] = researchDto.acceptanceDate[0].startOf('day');
        }
        if (!!researchDto.acceptanceDate && !!researchDto.acceptanceDate[1]) {
            researchDto.acceptanceDate[1] = researchDto.acceptanceDate[1].endOf('day');
        }

        // 検索条件変更を反映する
        const query = dto2search(researchDto);
        console.log('query', query)

        // URLを再設定する
        push(`/acceptances${query}`);
    }

    //数量編集
    const getQuantity = (num1?: number, str2?: string) => {
        if (num1 === undefined || str2 === undefined) {
            return "";
        }
        return num1 + " " + str2;
    };

    // readAll
    const readAll = () => {
        setListData({ loading: true, total: listData.total, data: listData.data });
        readAllData().then(() => {
            // 検索条件変更を反映する
            setSearchDto(search2Dto(search));
            get(search).then((para: [number, AcceptanceListRecordDto[]]) => {
                const [count, vos] = para;
                setListData({ loading: false, total: count, data: vos })
            }).catch((error) => {
                setListData(data => ({ ...data, loading: false }));
            });
        }).catch((error) => {
            setListData(data => ({ ...data, loading: false }));
        });
    }

    const downloadList = () => {

        setListData(data => ({ ...data, loading: true }));

        const downloadSearchDto = { ...searchDto, rowCount: 0 };

        get(dto2search(downloadSearchDto))
            .then((para: [number, AcceptanceListRecordDto[]]) => {

                setListData(data => ({ ...data, loading: false }));

                const [, vos] = para;

                const excelData: any = [];
                const titles: any = [];
                titles.push(intl.formatMessage({ id: 'acceptance.buyer' }));
                titles.push(intl.formatMessage({ id: 'acceptance.buyerName' }));
                titles.push(intl.formatMessage({ id: 'acceptance.supplier' }));
                titles.push(intl.formatMessage({ id: 'acceptance.supplierName' }));
                titles.push(intl.formatMessage({ id: 'acceptance.acceptanceDate' }));
                titles.push(intl.formatMessage({ id: 'acceptance.item' }));
                titles.push(intl.formatMessage({ id: 'acceptance.itemName' }));
                titles.push(intl.formatMessage({ id: 'acceptance.acceptanceNo' }));
                titles.push(intl.formatMessage({ id: 'acceptance.acceptanceDetailNo' }));
                titles.push(intl.formatMessage({ id: 'acceptance.orderNo' }));
                titles.push(intl.formatMessage({ id: 'acceptance.detailNo' }));
                titles.push(intl.formatMessage({ id: 'acceptance.acceptanceQuantity' }));
                titles.push(intl.formatMessage({ id: 'acceptance.acceptanceUnitPrice' }));
                titles.push(intl.formatMessage({ id: 'acceptance.acceptanceAmount' }));
                titles.push(intl.formatMessage({ id: 'acceptance.orderQuantity' }));
                titles.push(intl.formatMessage({ id: 'acceptance.orderUnit' }));
                titles.push(intl.formatMessage({ id: 'acceptance.orderAmount' }));
                titles.push(intl.formatMessage({ id: 'acceptance.currencyCode' }));
                titles.push(intl.formatMessage({ id: 'acceptance.deliveryNo' }));
                titles.push(intl.formatMessage({ id: 'acceptance.locationCd' }));
                excelData.push(titles);

                vos.forEach(vo => {
                    const datas: any = [];
                    datas.push(vo.buyer);
                    datas.push(vo.buyerName);
                    datas.push(vo.supplier);
                    datas.push(vo.supplierName);
                    datas.push(displayUtil.date(vo.acceptanceDate));
                    datas.push(vo.item);
                    datas.push(vo.itemName);
                    datas.push(vo.acceptanceNo);
                    datas.push(vo.acceptanceDetailNo);
                    datas.push(vo.orderNo);
                    datas.push(vo.detailNo);
                    datas.push(vo.quantity);
                    datas.push(vo.unitPrice);
                    datas.push(vo.amount);
                    datas.push(vo.orderQuantity);
                    datas.push(vo.unit);
                    datas.push(vo.orderAmount);
                    datas.push(vo.currencyCode);
                    datas.push(vo.deliveryNo);
                    datas.push(vo.locationCd);
                    excelData.push(datas);
                }
                );

                const worksheet = XLSX.utils.aoa_to_sheet(excelData);
                const workbook = XLSX.utils.book_new();

                XLSX.utils.book_append_sheet(workbook, worksheet, intl.formatMessage({ id: 'acceptance' }));
                XLSX.writeFile(workbook, `${intl.formatMessage({ id: 'acceptance' })}_${dayjs().format('YYYYMMDDHHmmss')}.xlsx`);
            })
            .catch(() => {

                setListData(data => ({ ...data, loading: false }));
            });
    }
    const downloadDetail = () => {
        if (!!urlId) {

            setListData(data => ({ ...data, loading: true }));

            getById(urlId)
                .then((vo: AcceptanceDetailRecordDto) => {

                    setListData(data => ({ ...data, loading: false }));

                    const excelData = [
                        [
                            intl.formatMessage({ id: 'acceptance.buyer' }),
                            intl.formatMessage({ id: 'acceptance.buyerName' }),
                            intl.formatMessage({ id: 'acceptance.buyerDepartmentName' }),
                            intl.formatMessage({ id: 'acceptance.supplier' }),
                            intl.formatMessage({ id: 'acceptance.supplierName' }),

                            intl.formatMessage({ id: 'acceptance.orderNo' }),
                            intl.formatMessage({ id: 'acceptance.detailNo' }),
                            intl.formatMessage({ id: 'acceptance.purchasingManager' }),
                            intl.formatMessage({ id: 'acceptance.orderDate' }),
                            intl.formatMessage({ id: 'acceptance.iniDeliveryDate' }),
                            intl.formatMessage({ id: 'acceptance.productionPlanDate' }),
                            intl.formatMessage({ id: 'acceptance.orderQuantity' }),
                            // intl.formatMessage({ id: 'acceptance.orderUnitPrice' }),
                            intl.formatMessage({ id: 'acceptance.orderAmount' }),
                            intl.formatMessage({ id: 'acceptance.currencyCode' }),

                            intl.formatMessage({ id: 'acceptance.acceptanceNo' }),
                            intl.formatMessage({ id: 'acceptance.acceptanceDetailNo' }),
                            intl.formatMessage({ id: 'acceptance.acceptanceDate' }),
                            intl.formatMessage({ id: 'acceptance.item' }),
                            intl.formatMessage({ id: 'acceptance.itemName' }),
                            intl.formatMessage({ id: 'acceptance.acceptanceQuantity' }),
                            intl.formatMessage({ id: 'acceptance.acceptanceUnitPrice' }),
                            intl.formatMessage({ id: 'acceptance.acceptanceAmount' }),
                            intl.formatMessage({ id: 'acceptance.currencyCode' }),
                            intl.formatMessage({ id: 'acceptance.moveType' }),
                            intl.formatMessage({ id: 'acceptance.locationCd' }),
                            intl.formatMessage({ id: 'acceptance.locationName' }),
                            intl.formatMessage({ id: 'acceptance.deliveryNo' }),

                        ],
                        [
                            vo.buyer,
                            vo.buyerName,
                            vo.buyerDepartmentName,
                            vo.supplier,
                            vo.supplierName,

                            vo.orderNo,
                            vo.detailNo,
                            vo.purchasingManager,
                            displayUtil.date(vo.orderDate),
                            displayUtil.date(vo.iniDeliveryDate),
                            (!vo.productionPlanDate ? displayUtil.date(vo.productionPlanDate) : ''),
                            getQuantity(vo.orderQuantity, vo.unit),
                            // displayUtil.currency(vo.currencyCode,vo.orderUnitPrice),
                            displayUtil.currency(vo.currencyCode, vo.orderAmount),
                            vo.currencyCode,

                            vo.acceptanceNo,
                            vo.acceptanceDetailNo,
                            displayUtil.date(vo.acceptanceDate),
                            vo.item,
                            vo.itemName,
                            getQuantity(vo.quantity, vo.unit),
                            displayUtil.currency(vo.currencyCode, vo.unitPrice),
                            displayUtil.currency(vo.currencyCode, vo.amount),
                            vo.currencyCode,
                            vo.moveTypeName,
                            vo.locationCd,
                            vo.locationName,
                            vo.deliveryNo,
                        ],
                    ];

                    const worksheet = XLSX.utils.aoa_to_sheet(excelData);
                    const workbook = XLSX.utils.book_new();

                    XLSX.utils.book_append_sheet(workbook, worksheet, intl.formatMessage({ id: 'acceptance.detail' }));
                    XLSX.writeFile(workbook, `${intl.formatMessage({ id: 'acceptance.detail' })}_${dayjs().format('YYYYMMDDHHmmss')}.xlsx`);
                })
                .catch(() => {

                    setListData(data => ({ ...data, loading: false }));
                });

        }
    }

    const reset = (rowCount: number) => {
        setSearch({
    
            acceptanceDate: [null, null],
    
            itemName: '',
    
            items: [],
    
            suppliers: [],
    
            supplierName: '',
    
            rowCount: rowCount,

            purchasingManager:'',

            acceptanceNo:'',

            orderNo:'',

            deliveryNo:'',

            locationName:'',

            locationCd:'',
            
            unitPrice: [undefined, undefined],

            amount: [undefined, undefined],

            orderAmount: [undefined, undefined],
    
        } as AcceptanceSearchDto)
    }

    const listProps = {
        // 画面loading状態
        loading: listData.loading,
        // 件数合計
        total: listData.total,
        // 一覧画面の表示データ
        data: listData.data,
        // 検索条件（フィルター、ページとソート順）
        searchDto: searchDto,
        // 検査画面を表示する
        openSearchModal: () => setSearchVisible(true),
        // 検査画面を表示する
        handleResearch: setSearch,
        //データタウンロード
        download: downloadList,
        reset: reset,
        readAll: readAll,
    }

    const searchProps = {
        visible: searchVisible,
        searchDto: searchDto,
        handleSearch: (value: AcceptanceSearchDto) => {
            setSearchVisible(false);
            setSearch(value);
        },
        close: () => {
            setSearchVisible(false);
        }
    }

    const detailProps = {
        // 詳細画面の表示状態
        visible: detailData.visible,
        detail: detailData.data,
        close: () => {
            if (dto2search(searchDto) !== '') {
                goBack();
            } else {
                push('/acceptances');
            }
        },
        //データタウンロード
        download: downloadDetail,
    };

    return (
        <>
            < List {...listProps} />
            < Search {...searchProps} />
            < Detail {...detailProps} />
        </>
    )

}