const enUS = {

  'message.reapplyConfirm' : 'Do you want to reply?',
  'message.approveConfirm' : 'Do you approve it?',
  'message.refuseConfirm' : 'Do you refuse it?',
  'message.inputRequired' : 'Please enter {title}.',
  'message.selectRequired' : 'Please select {title}.',
  'message.addSuccess' : 'Added successfully',
  'message.updateSuccess' : 'Updated successfully',
  'message.deleteSuccess' : 'Deleted successfully',
  'message.uploadSuccess' : 'Uploaded successfully',
  'message.addFailure' : 'Addition failed.',
  'message.updateFailure' : 'Update failed.',
  'message.deleteFailure' : 'Deletion failed.',
  'message.uploadFailure' : 'Upload failed.',
  'message.requestFailure400' : 'An error occurred in transmission parameter.',
  'message.requestFailure401' : 'Authentication is required.',
  'message.requestFailure404' : 'The server is not responding.',
  'message.requestFailure408' : 'It is timeout',
  'message.requestFailure409' : 'The data is updated by other user.',
  'message.requestFailure500' : 'Server error occurred.',
  'message.requestFailure504' : 'Timeout occurred.',
  'message.delConfirm' : 'Do you want to delete it?',
  'message.editConfirm' : 'Do you want to update it?',
  'message.replyConfirm' : 'Do you want to reply?',
  'message.addConfirm' : 'Do you want to add it?',
  'message.sendConfirm' : 'Do you want to send it?',
  'message.finishConfirm' : 'Do you want to exit?',
  'message.cancelConfirm' : 'Do you want to cancel it?',
  'message.cancelConfirm2' : 'Do you want to cancel it?',
  'message.checkGoodsIssue' : 'Please enter the goods issue number.',
  'message.checkLocation' : 'Location and ETA are inconsistent.',
  'message.currencyNull' : 'The monetary unit must be empty',
  'message.deliveryNoUnique' : 'Delivery No already exists, please input it again',
  'message.readAllConfirm' : 'Do you want to set all items readed?',
  'message.cancel2Confirm' : 'Do you want to cancel it?',
  'message.noForecastError' : 'Your company does not have the forecast data for the current week.',

  'estimate.checkSelect' : 'Please select the plan goods issue information.',

  'orderResponse.shortageMessage' : 'The number of delivered is more than number of answered.',
  'orderResponse.shortageMessage2' : 'The number of answered is more than number of delivered.',

  'message.integerError' : 'Only integers up to {a} bits can be entered',
  'message.positiveIntegerError' : 'Only positive integers up to {a} bits can be entered',
  'message.excelSPQError' : 'The SPQ on line {i} must be a positive integers up to 6 bits.',
  'message.excelMPQError' : 'The MPQ on line {i} must be a positive integers up to 6 bits.',
  'message.excelPostiveNumberError' : 'The {key} in line {i} must be a positive number.',
  'message.excelNegativeNumberError' : 'The {key} in line {i} must not be negative.',
  'message.excelLTPositiveNumberError' : 'The {key} in line {i} must be positive number and greater than 2147483647.',
  'message.excelAssetNo' : 'The {key} in line {i} must be less than 15 bits.',
  'message.excelAuxiliaryNo' : 'The {key} in line {i} must be a positive integer between 0 and 99.',
  'message.excelCostCenterNo' : 'The {key} in line {i} must be less than 10 bits.',
  'message.excelEstimateStartDateError' : 'The {key} in line {i} is a date in the past.',

  'estimate.answerDeadlineError' : '{b} should be earlier than {a}.',
  'estimate.maxError' : 'The number of characters in {a} must be less than {b}.',
  'estimate.nullError' : '{name} is required.',
  'estimate.zeroError' : '{name} cannot be zero.',
  'estimate.priceError' : 'The decimal point of the {a} shall be kept below two digits.',

  'shipment' : 'Delivery',
  'shipment.search' : 'Search',
  'shipment.orderNo' : 'Order No',
  'shipment.itemCode' : 'Part No',
  'shipment.deliveryDate' : 'Delivery Date',

  'forecast' : 'Forecast',
  'forecast.search' : 'Search',
  'forecast.list' : 'Forecast List',
  'forecast.planNo' : 'Forecast No',
  'forecast.detailNo' : 'Detail No',
  'forecast.planningDate' : 'MRP Processed Date',
  'forecast.buyer' : 'Purchaser',
  'forecast.buyerName' : 'Purchaser Name',
  'forecast.supplier' : 'Supplier',
  'forecast.supplierName' : 'Supplier Name',
  'forecast.item' : 'Part No',
  'forecast.itemName' : 'Part Name',
  'forecast.iniDeliveryMonth' : 'Plan Delivery Month',
  'forecast.iniDeliveryDate' : 'Plan Delivery Date',
  'forecast.quantity' : 'Qty',
  'forecast.unit' : 'Unit',
  'forecast.purchasingManager' : 'Procurement PIC',
  'forecast.now' : 'Latest data',

  'pay' : 'Payment',

  'pays' : 'Payment Notification',

  'pay.list' : 'Payment List',
  'pay.detail' : 'Payment Informaton Detail',
  'pay.payment' : 'Payment Amount',
  'pay.contracor' : 'Purchaser Information',
  'pay.order' : 'Supplier Information',
  'pay.taxRate' : 'Tax rate',
  'pay.items' : 'Items',
  'pay.id' : 'id',
  'pay.dataCreationDate' : 'Creation Date',
  'pay.paymentId' : 'Payment No',
  'pay.acquisitionDate' : 'Transaction Date',
  'pay.supplierCode' : 'Supplier Code',
  'pay.paymentDate' : 'Payment Date',
  'pay.reissueFlag' : 'Reissue Segment',
  'pay.zipCode' : 'Zip Code',
  'pay.supplierAddress' : 'Supplier Addr',
  'pay.supplierName' : 'Supplier Name',
  'pay.fax' : 'FAX',
  'pay.dateOfIssue' : 'Issue Date',
  'pay.buyer' : 'Purchaser',
  'pay.buyerName' : 'Purchaser Name',
  'pay.departmentName' : 'Procurement Department',
  'pay.qualifiedInvoiceIssuerRegistrationNumber' : 'Registered number of Invoice issuing business operator',
  'pay.currencyCode' : 'Currency',
  'pay.totalPaymentAmount' : 'Total Payment Amount',
  'pay.lastMonthPayAdjustAmount' : 'Last Month Pay Correction Amount',
  'pay.purchasePrice' : 'Purchase Price',
  'pay.accountsReceivableOffset' : 'Accounts Receivable Offset',
  'pay.otherOffset' : 'Other Offset',
  'pay.payAdjustAmount' : 'Payment Correction Amount',
  'pay.balanceAmountPay' : 'Balance Amount',
  'pay.invoiceNo' : 'Invoice No',
  'pay.excludingTax' : 'Excluding Tax',
  'pay.consumptionTax' : 'Consumption Tax',
  'pay.totalExcludingTax' : 'Total Excluding Tax',
  'pay.readFlag' : 'Read Flag',
  'pay.paymentUponEachUse' : 'Payment Upon Each Use',

  'billingDetail' : 'Billing Detail',
  'billingDetail.list' : 'Billing Detail List',
  'billingDetail.companyId' : 'Company code',
  'billingDetail.acquisitionDate' : 'Transaction Date',
  'billingDetail.supplierCode' : 'Supplier Code',
  'billingDetail.supplierName' : 'Supplier name',
  'billingDetail.transcriptionDate' : 'Transcription date',
  'billingDetail.deliverySlipNumber' : 'Delivery voucher number',
  'billingDetail.purchaseOrderNo' : 'Purchase order NO.',
  'billingDetail.purchaseDetailNo' : 'Purchase order voucher details',
  'billingDetail.itemCode' : 'Item code',
  'billingDetail.itemText' : 'Item text',
  'billingDetail.incorporationQuantity' : 'Number of purchases',
  'billingDetail.unit' : 'Unit',
  'billingDetail.purchaseUnitPrice' : 'Purchase unit price',
  'billingDetail.purchaseAmount' : 'Purchase Amount',
  'billingDetail.currencyCode' : 'Currency',
  'billingDetail.purchaseTax' : 'Tax rate',
  'billingDetail.totalPurchaseAmount' : 'Total purchase amount:',
  'billingDetail.year' : ' ',
  'billingDetail.month' : ' ',
  'billingDetail.detail' : 'Detail',
  'billingDetail.totalPurchaseAmountGetFailed' : 'Failed to get the total purchase amount data.',

  'acceptance' : 'Goods Receipt',
  'acceptance.list' : 'Goods Receipt List',
  'acceptance.detail' : 'Goods Receipt Detail',
  'acceptance.orderInfo' : 'Order Information',
  'acceptance.orderNo' : 'Order No',
  'acceptance.orderDetailNo' : 'GR Detail No',
  'acceptance.detailNo' : 'Order Detail No',
  'acceptance.orderDate' : 'Issue Date',
  'acceptance.purchasingManager' : 'Procurement PIC',
  'acceptance.iniDeliveryDate' : 'Plan Delivery Date',
  'acceptance.productionPlanDate' : 'Plan Delivery Date',
  'acceptance.item' : 'Part No',
  'acceptance.itemName' : 'Part Name',
  'acceptance.unit' : 'Unit',
  'acceptance.orderUnit' : 'Order Unit',
  'acceptance.buyer' : 'Purchaser',
  'acceptance.buyerName' : 'Purchaser Name',
  'acceptance.supplier' : 'Supplier',
  'acceptance.supplierName' : 'Supplier Name',
  'acceptance.currencyCode' : 'Currency',
  'acceptance.acceptanceInfo' : 'Goods Receipt Information',
  'acceptance.acceptanceNo' : 'GR No',
  'acceptance.acceptanceDetailNo' : 'GR Detail No',
  'acceptance.acceptanceDate' : 'GR Date',
  'acceptance.moveType' : 'Move Type',
  'acceptance.acceptanceUnitPrice' : 'GR Unit Price',
  'acceptance.acceptanceQuantity' : 'GR Qty',
  'acceptance.acceptanceQuantityUnit' : 'GR Qty Unit',
  'acceptance.acceptanceAmount' : 'GR Amount',
  'acceptance.orderQuantity' : 'Order Qty',
  'acceptance.orderAmount' : 'Order Amount',
  'acceptance.orderUnitPrice' : 'Order Unit Price',
  'acceptance.locationCd' : 'Location Code ',
  'acceptance.locationName' : 'Location Name',
  'acceptance.deliveryNo' : 'Delivery No',
  'acceptance.deliveryDetailNo' : 'Delivery Detail No',
  'acceptance.search' : 'Search',
  'acceptance.buyerInfo' : 'Purchaser Information',
  'acceptance.supplierInfo' : 'Supplier Information',
  'acceptance.buyerDepartmentName' : 'Procurement Div',

  'delivery.modalTitle' : 'Goods Issue',
  'delivery' : 'Delivery',
  'delivery.detail' : 'Delivery Detail List',
  'delivery.list' : 'Delivery Note List',
  'delivery.userModifiedDate' : 'Modified Date',
  'delivery.dataCreationDate' : 'Creation Date',
  'delivery.deliveryNo' : 'Delivery No',
  'delivery.buyer' : 'Purchaser',
  'delivery.buyerName' : 'Purchaser Name',
  'delivery.supplier' : 'Supplier',
  'delivery.supplierName' : 'Supplier Name',
  'delivery.userName' : 'Creater',
  'delivery.estimatedTimeArriva' : 'ETA',
  'delivery.item' : 'Part No',
  'delivery.itemName' : 'Part Name',
  'delivery.quantity' : 'Qty',
  'delivery.orderNo' : 'Order No',
  'delivery.detailNo' : 'Order Detail No',
  'delivery.search' : 'Search',
  'delivery.unprocessed' : 'Goods Issue Process',
  'delivery.unprocessedlist' : 'Waiting Parts List',
  'delivery.output' : 'Goods Issue',
  'delivery.deliveryStatus' : 'Purchase Flag',
  'delivery.locationCd' : 'Delivery Addr',
  'delivery.unitPrice' : 'Unit Price',
  'delivery.re-output' : 'Delivery Note Print',
  'delivery.currencyCode' : 'Currency',
  'delivery.eiaj' : 'EIAJ',
  'delivery.dnList' : 'List',
  'delivery.reportType' : 'Delivery  Type',
  'delivery.downloadSelected' : 'Delivery Note Download',
  'delivery.selectErrorTitle' : 'Select error',
  'delivery.noSelectBody' : 'Please select the Delivery Note.',
  'delivery.selectTooManyBody' : 'Up to 20 records can be downloaded for one batch.',

  'orderDetail.orderInfo' : 'Order Information',
  'orderDetail.orderNo' : 'Order No',
  'orderDetail.detailNo' : 'Order Detail No',
  'orderDetail.item' : 'Part No',
  'orderDetail.itemName' : 'Part Name',
  'orderDetail.orderDate' : 'Issue Date',
  'orderDetail.buyer' : 'Purchaser',
  'orderDetail.buyerName' : 'Purchaser Name',
  'orderDetail.supplier' : 'Supplier',
  'orderDetail.supplierName' : 'Supplier Name',
  'orderDetail.unitPrice' : 'Unit Price',
  'orderDetail.unit' : 'Unit',
  'orderDetail.quantity' : 'Order Qty',
  'orderDetail.amount' : 'Order Amount',
  'orderDetail.purchasingManager' : 'Procurement PIC',
  'orderDetail.iniDeliveryDate' : 'Plan Delivery Date',
  'orderDetail.productionPlanDate' : 'Rescheduled Date',
  'orderDetail.currencyCode' : 'Currency',
  'orderDetail.locationCd' : 'Location',
  'orderDetail.locationCode' : 'Location Code ',
  'orderDetail.infoType' : 'Category',
  'orderDetail.orderFinishFlag' : 'Delivery Finish Flag',
  'orderDetail.remark' : 'Remarks',
  'orderDetail.acceptanceQuantity' : 'GR Qty',
  'orderDetail.balanceQuantity' : 'Order Remaining Qty',
  'orderDetail.deliveryBalanceQuantity' : 'Delivery Remaining Qty',
  'orderDetail.deliveryQuantity' : 'GI Qty',
  'orderDetail.balanceAmount' : 'Order Remaining Amount',
  'orderDetail.deliveryBalanceAmount' : 'Delivery Remaining Amount',
  'orderDetail.deliveryFlag' : 'VMI Segment',
  'orderDetail.qualityCheckFlag' : 'Quality Check',
  'orderResponse' : 'Delivery Response',
  'orderResponse.devInstrucstionDate' : 'Delivery Instruction Date',
  'orderResponse.instrucstionQuantity' : 'Instruction Quantity',
  'orderResponse.instruction' : 'Delivery Instruction',
  'orderResponse.list' : 'Order List',
  'orderResponse.detail' : 'Order Detail',
  'orderResponse.orderResponseList' : 'Delivery Response list',
  'orderResponse.instructionList' : 'Delivery Instruction Information',
  'orderResponse.deliveryInfoList' : 'Goods Issue Information',
  'orderResponse.deliveryPlanDate' : 'Response Delivery Date',
  'orderResponse.quantity' : 'Qty',
  'orderResponse.responseQuantity' : 'Reponse Qty',
  'orderResponse.planDeliveryQuantity' : 'Plan GI Qty',
  'orderResponse.deliveryInfo' : 'Goods Issue Information',
  'orderResponse.detailNo' : 'Detail No',
  'orderResponse.estimatedTimeArriva' : 'ETA',
  'orderResponse.addDeliveryInfo' : 'Add Delivery Infomation',
  'orderResponse.uploadDeliveryInstruction' : 'Upload Delivery Instruction',
  'orderResponse.uploadOrderResponses' : 'Upload Delivery Responses',
  'orderResponse.uploadDeliveryInfos' : 'Upload GI Information',
  'orderResponse.delivery' : 'Goods Issue',
  'orderResponse.response' : 'Accept',
  'orderResponse.preDeliveryPlanDate' : 'Delivery Reponse Date(Before)',
  'orderResponse.preQuantity' : 'Qty(Before)',
  'orderResponse.currDeliveryPlanDate' : 'Delivery Reponse Date(After)',
  'orderResponse.currQuantity' : 'Qty(After)',
  'orderResponse.preEstimatedTimeArriva' : 'ETA（Before）',
  'orderResponse.currEstimatedTimeArriva' : 'ETA（After）',
  'orderResponse.preShipDate' : 'GI Date(Before)',
  'orderResponse.currShipDate' : 'Shipping Date(After)',
  'orderResponse.preRemarks' : 'Remarks(Before)',
  'orderResponse.currRemarks' : 'Remarks(After)',
  'orderResponse.shipDate' : 'GI Date',
  'orderResponse.remarks' : 'Remarks',
  'orderResponse.duplicate' : 'Duplicate Date',
  'orderResponse.over' : 'Over Quality',
  'orderResponse.responseDeleteFlag' : 'Reply delete flag ',
  'orderResponse.deliveryDeleteFlag' : 'Delivery delete flag ',
  'orderResponse.downloadDevInstrucstionInfos' : 'Download Delivery Instruction',

  'devInstrucstion.orderDetailKey' : 'PO_NUMPO_LINE',
  'devInstrucstion.devInstrucstionDate' : 'Delivery Date',
  'devInstrucstion.instrucstionQuantity' : 'Delivery Quantity',

  'notification' : 'Notification',

  'subnotification' : 'System Notification',

  'notification.id' : 'id',
  'notification.incomingDateAndTime' : 'Receipt Time',
  'notification.senderCode' : 'Sender Code',
  'notification.sender' : 'Sender',
  'notification.recipientCode' : 'Recipient Code',
  'notification.subject' : 'Subject',
  'notification.text' : 'Text',
  'notification.list' : 'Notification List',

  'estimate' : 'Quotation',
  'estimatesSub' : 'Request for quotation',

  'supplierEstimates' : 'Supplier Request for quotation',
  'supplierEstimates.supplier' : 'Request for quotation',
  'supplierEstimates.list' : 'Request for quotation List',
  'supplierEstimates.supplierList' : 'Request for quotation List',
  'supplierEstimates.requestList' : 'Upload Request for quotation',
  'supplierEstimates.upload' : 'Upload Data',
  'supplierEstimates.detail' : 'Request for quotation Detail',
  'supplierEstimates.supplierDetail' : 'Our request details page',
  'supplierEstimates.detailList' : 'Request for quotation Parts List',
  'supplierEstimates.supplierDetailList' : 'Our request for quotation Parts List',
  'supplierEstimates.templateDownload' : 'Template download ',

  'estimate.list' : 'Request for quotation List',
  'estimate.requestEdit' : 'Edit RFQ ',
  'estimate.requestClose' : 'Close RFQ',
  'estimate.cancel' : 'Cancel RFQ ',
  'estimate.id' : 'id',
  'estimate.status' : 'Status',
  'estimate.requestNo' : 'Quotation No',
  'estimate.buyer' : 'Purchaser',
  'estimate.buyerName' : 'Purchaser Name',
  'estimate.estimatesType' : 'Category',
  'estimate.buyerDepartment' : 'Procurement Div',
  'estimate.purchasingManager' : 'Procurement PIC',
  'estimate.requestDate' : 'Request Date',
  'estimate.answerDeadline' : 'Answer Deadline',
  'estimate.item' : 'Part No',
  'estimate.itemName' : 'Part Name',
  'estimate.planningMonths' : 'Plan Months',
  'estimate.planQuantity' : 'Pland Qty',
  'estimate.monthlyPlanOrderQuantity' : 'Monthly Plan Order Qty',
  'estimate.desireUnitPrice' : 'Desired Unit Price',
  'estimate.desireCurrency' : 'Desire Currency',
  'estimate.deliveryLocation' : 'Location',
  'estimate.responseInfo' : 'Request for quotation Response Information',
  'estimate.responseStatus' : 'Response Status',
  'estimate.supplier' : 'Supplier',
  'estimate.supplierName' : 'Supplier Name',
  'estimate.estimatedEffectiveDate' : 'Quotation Validity Period',
  'estimate.leadTime' : 'L/T',
  'estimate.originalCountryName' : 'Country of origin',
  'estimate.makerName' : 'Manufacturer Name',
  'estimate.makerItem' : 'Manufacturer Part No',
  'estimate.itemTypeCode' : 'Part Type',
  'estimate.moq' : 'SPQ',
  'estimate.mpq' : 'MPQ',
  'estimate.unitPrice' : 'Unit Price',
  'estimate.unitCurrency' : 'Currency',
  'estimate.moldCost' : 'Mold Cost',
  'estimate.moldCurrency' : 'Mold Charge Currency',
  'estimate.moldType' : 'Mold Type',
  'estimate.moldProcessNum' : 'Number of processes',
  'estimate.moldNum' : 'Number of molds',
  'estimate.jigCost' : 'Jig Cost',
  'estimate.jigCurrency' : 'Jig Currency',
  'estimate.repairsCost' : 'Repairs Cost',
  'estimate.repairsCurrency' : 'Repairs Currency',
  'estimate.editionFee' : 'Edition fee',
  'estimate.editionCurrency' : 'Edition currency',
  'estimate.contractConditionsCode' : 'Terms And Conditions',
  'estimate.estimateStartDate' : 'RFQ Start Date',
  'estimate.estimateEndDate' : 'RFQ End Date',
  'estimate.prevLocation' : 'Previous Location ',
  'estimate.nextLocation' : 'Next Location',
  'estimate.newEstimate' : 'Create RFQ  ',
  'estimate.requestDetail' : 'Request for quotation Detail',
  'estimate.estimateContent' : 'Request for quotation Information',
  'estimate.responseContent' : 'Request for quotation Response Information',
  'estimate.requestInfo' : 'Request for quotation Information',
  'estimate.reponseDetail' : 'Request for quotation Response Detail',
  'estimate.reponseInfo' : 'Request for quotation Response Information',
  'estimate.reponseList' : 'Request for quotation Response List',
  'estimate.editRequest' : 'Edit RFQ ',
  'estimate.currency' : 'Unit Currency',
  'estimate.itemInfo' : 'Part information',
  'estimate.moldInfo' : 'Mold Informaiton',
  'estimate.cost' : 'Cost',
  'estimate.processNum' : 'Number of processes',
  'estimate.mum' : 'Number of molds',
  'estimate.type' : 'Type',
  'estimate.quantity' : 'Qty',
  'estimate.responseCurrencyCode' : 'Currency',
  'estimate.requestCount' : 'Request Count',
  'estimate.responseCount' : 'Response Quantity',
  'estimate.remarks' : 'Remarks',
  'estimate.monthUnit' : 'Months',
  'estimate.quantityUnit' : 'PC',
  'estimate.bidding' : 'Bidding ',
  'estimate.decline' : 'Decline',
  'estimate.userName' : 'Oprerator',
  'estimate.userID' : 'Oprerator ID',
  'estimate.modifiedTime' : 'Modified Date',
  'estimate.upload' : 'Attachment',
  'estimate.reapply' : 'Re-quotation Request',
  'estimate.approve' : 'Accept',
  'estimate.refuse' : 'Reject',
  'estimate.makerInfo' : 'Manufacturer Information',
  'estimate.makerName2' : 'Manufacturer Name',
  'estimate.makerItem2' : 'Part No',
  'estimate.itemTypeCode2' : 'Sort',
  'estimate.moldsNum' : 'Number of molds',
  'estimate.responseUser' : 'Reply User',
  'estimate.responseTime' : 'Reply Time',

  'modelManagement' : 'Mold',
  'model' : 'Mold Information Entry',
  'model.detail' : 'Mold Edit',
  'model.list' : 'Mold List',
  'model.assetNo' : 'Asset No',
  'model.auxiliaryNo' : 'Auxiliary No',
  'model.moldNo' : 'Mold Part No',
  'model.modelName' : 'Model Name',
  'model.item' : 'Part No',
  'model.itemName' : 'Part Name',
  'model.material' : 'Material',
  'model.management' : 'Management',
  'model.kinds' : 'Sort',
  'model.primaryLender' : 'Primary Lender',
  'model.primaryLenderName' : 'Primary Lender Name',
  'model.primaryLenderAddr' : 'Primary Lender Addr',
  'model.finalLender' : 'Final Lender',
  'model.finalLenderName' : 'Final Lender Name',
  'model.finalLenderAddr' : 'Final Lender Addr',
  'model.acquisitionDate' : 'Capitalization date',
  'model.supplier' : 'Mold Supplier',
  'model.text' : 'Text',
  'model.supplierName' : 'Mold Supplier Name',
  'model.costCenter' : 'Cost Center',
  'model.costCenterNo' : 'Cost Center No.',
  'model.costCenterName' : 'Cost Center Name',
  'model.weight' : 'Weight(KG)',
  'model.moldCavityNum' : 'Quantity of cavity',
  'model.lifeShotsNum' : 'Number of life shots',
  'model.moldsNum' : 'Number of molds',
  'model.primaryLeadDate' : 'Primary Lender Borrow Date',
  'model.settlementNo' : 'Approval No',
  'model.purchaseAmount' : 'Purchase Amount',
  'model.orderNo' : 'Order No',
  'model.depreciationStartDate' : 'Depreciation Start Date',
  'model.discardDate' : 'Discard Date',
  'model.remarks' : 'Remarks',
  'model.upload' : 'Upload Mold information',
  'model.assets' : 'Asset Information',
  'model.moldProperty' : 'Mold Informaiton',
  'model.deposit' : 'Lender information',
  'model.other' : 'Others',
  'model.purchaseCurrency' : 'Purchase Currency ',
  'model.fixedAssetRegistrationDecisionNumber' : 'Fixed Asset Registration Approval No',
  'model.assetFlowDifferentiation' : 'Asset Flow Division',
  'model.flowDate' : 'Flow date',
  'model.finalLenderAddrCode' : 'Final Lender Addr Code',
  'model.assetFlowApplyCode' : 'Asset Flow Apply No',
  'model.haveAttachments' : 'Have',
  'model.notHaveAttachments' : 'Do Not Have',
  'model.deleteFlag' : 'Delete Flag',
  'model.haveOrNotHaveAttachments' : 'Any Attachments',
  'model.deleteSelected' : 'Delete',
  'model.confirmDeleteSelected' : 'Confirm deletion',
  'model.deleteMustSelectFirst' : 'The item to be deleted must be selected',

  'inventory.confirmationUser' : 'IPRO Confirmation User',
  'inventory.confirmationTime' : 'Confirmation Time',
  'inventoryConfirm' : 'Mold Inventory Confirmation',
  'inventory' : 'Mold Inventory',
  'inventory.status' : 'Response Status',
  'inventory.inventoryRequestNo' : 'Request No',
  'inventory.requestDate' : 'Request Date',
  'inventory.requestDate2' : 'Request Date',
  'inventory.requester' : 'Mold Inventory Sender',
  'inventory.answerDeadline' : 'Answer Deadline',
  'inventory.modelNum' : 'Mold Qty',
  'inventory.moldsNum' : 'Number of molds',
  'inventory.actualMoldsNum' : 'Number of actual molds',
  'inventory.remarks' : 'Remarks',
  'inventory.buyer' : 'Procurement Side',
  'inventory.detail' : 'Mold Inventory Confirming Information',
  'inventory.head' : 'Mold Inventory Confirmation Head information',
  'inventory.detailList' : 'Mold Inventory Confirming Detail information',
  'inventory.list' : 'Mold Inventory Confirming List',
  'inventory.inventoryRequest' : 'Inventory Confirming Request',
  'inventory.modifiedTime' : 'Modified Date',
  'inventory.userName' : 'Updater',
  'inventory.responseUser' : 'Supplier Reply User',
  'inventory.responseTime' : 'Reply Time',
  'inventory.recordTheReason' : 'When {a} is 0, be sure to record the reason in [{b}].',
  'inventory.waitForReply' : 'You have not finished the inventory confirming reply yet. Click the Reply button on the bottom right.',

  'docInfo' : 'Document',
  'docInfo.list' : 'Document List',
  'docInfo.add' : 'Send Document',
  'docInfo.detail' : 'Document Detail Information',
  'docInfo.sendInfo' : 'Send Information',
  'docInfo.replyInfo' : 'Reply Information',
  'docInfo.buyer' : 'Purchaser',
  'docInfo.buyerName' : 'Purchaser Name',
  'docInfo.documentCategory' : 'Category',
  'docInfo.title' : 'Title',
  'docInfo.sendContent' : 'Send Content',
  'docInfo.sendTime' : 'SendTime',
  'docInfo.receiptContent' : 'Receipt Message',
  'docInfo.receiptTime' : 'Receipt Time',
  'docInfo.acceptingFlag' : 'Read Flag',
  'docInfo.supplier' : 'Supplier',
  'docInfo.supplierName' : 'Supplier Name',
  'docInfo.reply' : 'Reply ',
  'docInfo.replyTime' : 'Reply Time',
  'docInfo.replyContent' : 'Reply Content',
  'docInfo.sendQuantity' : 'Sent Qty',
  'docInfo.acceptQuantity' : 'Read Qty',
  'docInfo.replayQuantity' : 'Reply Qty',
  'docInfo.docFiles' : 'Attachment',
  'docInfo.selectAll' : 'ALL',
  'docInfo.acceptFlag' : 'Accept Flag',
  'docInfo.replyFlag' : 'Reply Flag',
  'docInfo.readFlag' : 'Read Flag',
  'docInfo.userName' : 'Sending User',
  'docInfo.replyUserName' : 'Reply User',
  'docInfo.destinationName' : 'Destination ',
  'docInfo.destinationList' : 'Destination List',
  'docInfo.uploadDestination' : 'Upload Destination',
  'docInfo.confirmSendDocument' : 'Confirm Send Document',
  'docInfo.numberOfSuppliers' : 'Number of Suppliers: {i}',
  'docInfo.destinationNotExist' : 'The destination {name} in line {i} does not exist.',

  'common.stateCode' : 'Correction Status',
  'common.userName' : 'Updater',
  'common.userModifiedDate' : 'Modified Date',
  'common.logout' : 'Logout',
  'common.save' : 'Save',
  'common.add' : 'Add',
  'common.update' : 'Update',
  'common.clear' : 'Clear',
  'common.delete' : 'Delete',
  'common.deleted' : 'Deleted',
  'common.search' : 'Search',
  'common.conditions' : 'Search',
  'common.download' : 'Download',
  'common.downloadPassed' : 'Download Passed Data',
  'common.upload' : 'Upload',
  'common.history' : 'History Data',
  'common.close' : 'Close',
  'common.cancel' : 'Cancel',
  'common.cancel2' : 'Cancel',
  'common.reset' : 'Reset',
  'common.readAll' : 'Read all',
  'common.edit' : 'Edit',
  'common.next' : 'Next',
  'common.previous' : 'Previous',
  'common.confirm' : 'Confirm',
  'common.confirmed' : 'Confirmed',
  'common.response' : 'Reply ',
  'common.hisory' : 'History',
  'common.operation' : 'Opreration',
  'common.modalTitle' : 'Input error',
  'common.modalErrorTitle' : 'Error',
  'common.basicInfomation' : 'Basic Infomation',
  'common.yes' : 'Yes',
  'common.no' : 'No',
  'common.send' : 'Send',
  'common.dataCreationDate' : 'Creation Date',
  'common.minimun' : 'Minimun',
  'common.maximum' : 'Maximum',

  'supplierEstimates.answerList' : 'Upload Answers',
  'supplierEstimates.finishRequest' : 'Close',
  'supplierEstimates.detailDetail' : 'Request for quotation Parts Detail',
  'supplierEstimates.accept' : 'Accept',
  'supplierEstimates.reject' : 'Reject',

  'guide' : 'Support',
  'guide.docNotReadyNow' : 'This document is under preparation',
  'guide.getBackLater' : 'Please check back later',
  'guide.os' : 'OS',
  'guide.browser' : 'Browser',
  'guide.display' : 'Display',
  'guide.language' : 'System Support Language',
  'guide.osVersion' : 'Windows8.1 or later',
  'guide.browserName' : 'Google Chrome、Microsoft Edge',
  'guide.displayPixel' : '1920 × 1080 Pixel',
  'guide.zoomTitle' : 'System scaling and layout',
  'guide.zoom' : '100% or 125%',
  'guide.languageName' : 'Japanese, Chinese, English',
  'guide.chinese' : 'Chinese',
  'guide.English' : 'English',
  'guide.Japanese' : 'Japanese',
  'guide.systemManual' : 'System Manual',
  'guide.tel' : 'Contact',
  'guide.receptionTime' : 'Reception time',
  'guide.china' : 'China',
  'guide.japan' : 'Japan',
  'guide.telChina' : '00086-XXX-XXXX-XXXX',
  'guide.telJapan' : '00081-XXX-XXXX-XXXX',
  'guide.supportInfo' : 'Support Information',
  'guide.download' : 'Download',
  'guide.environment' : 'System Environment',
  'guide.downloadIproChinaText' : 'i-Pro China',
  'guide.downloadIproJapanText' : 'i-Pro Japan',
  'guide.downloadSupplierChinaText' : 'Supplier for i-Pro China',
  'guide.downloadSupplierJapanText' : 'Supplier for i-Pro Japan',
  'guide.supplierOnly' : 'Supplier Version',
  'guide.iproOnly' : 'i-PRO Version',
  'guide.dataSynchronizationTime' : 'Data synchronization time',
  'guide.dataSendTime' : 'SendTime',
  'guide.dataReceptionTime' : 'Reception time',
  'guide.pipsSendTime' : '08:45／10:45／12:45／14:45／16:45／18:45／21:45',
  'guide.pipsReceptionTime' : '02:35／09:35／11:35／13:35／15:35／17:35／19:35',
  'guide.psnsSendTime' : '10:15 / 15:15 / 21:15',
  'guide.psnsReceptionTime' : '11:50 / 16:50 / 22:50',

  'home' : 'Dashboard',
  'home.pieces' : 'Pieces',
  'home.unreadData' : 'Unread Pieces',
  'home.arrivedate' : 'Latest Data Arrival Date',
  'home.waitingShipped' : 'Waiting To Be Shipped',
  'home.notanswered' : 'Number Of Unanswered',
  'home.quote' : 'Quote From Supplier',
  'home.moldMaster' : 'Mold Master ',
  'home.detailInfoIncompleteNumber' : 'number of incomplete detail mold',
  'home.OurRequestQuotation' : 'Our Request Quotation',
  'home.estiamteUnclose' : 'Open cases',

  'batch.newestBatchInfo' : 'Newest {batchName} batch info',
  'batch.dataImportTime' : 'Data import time',
  'batch.newDataQuantity' : 'New data quantity',
  'batch.updateDataQuantity' : 'Update data quantity',
  'batch.skipDataQuantity' : 'Skip data quantity',
  'batch.errorDataQuantity' : 'Error data quantity',
  'batch.more' : 'more',
  'batch.newestBatchRunTime' : 'Newest {batchName} batch run time',
  'batch.batchDetail' : 'Batch detail',
  'batch.batchName' : 'Batch type',
  'batch.batchStatus' : 'Batch status',
  'batch.inOrderDetail' : 'Order detail',
  'batch.inAcceptance' : 'Goods Receipt',
  'batch.inPayment' : 'Payment Informaton',

  'message.DigitRetainedUnitPrice' : 'The maximum number of decimal places is {a}, please confirm again.',
  'message.DigitRetained2' : 'Only up to 3 decimal places are allowed',
  'message.DigitRetainedAmountJP' : 'Decimal places are forbidden, please confirm again. ',
  'message.DigitRetainedAmount' : 'Up to 2 decimal places are reserved, please reconfirm. ',
  'message.unitPriceMaxDecimal' : 'Up to 6 decimal places ',
  'message.lengthFailure' : 'The number of characters must be less than {length}.',
  'message.lengthFailureSingleTAG' : 'The number of characters must be less than {length} on single TAG.',
  'message.IntegerPartLengthFailure' : 'You can only enter up to {length} digits in the integer part.',
  'message.fixedLengthLimit' : 'The number of characters must be {length} bits.',
  'message.onlyHalfString' : 'Please input half-width text.',
  'message.onlyPositiveInteger' : 'Please input a positive integer.',
  'message.onlyPositiveNumber' : 'Please input a positive number.',
  'message.noNegativeNumbers' : 'Negative numbers in {i} are prohibited.',
  'message.formateError' : 'Please enter with half-width alphanumeric characters and the following special symbols.',
  'message.allowedSpecialMarkings' : 'special symbols：-　_ ',
  'message.outputDataLinesError' : 'Multiple data selection is not allowed.',
  'message.commonElementError' : 'Cannot select the same data as {a}.',
  'message.itemNotAllowed' : 'Part number on line {i} is not exist in the part number table.',
  'message.destinationNotAllowed' : 'Destination on line {i} is not exist in this system.',
  'message.timeOverTheLimit' : '{key} for line {i} cannot exceed 12/31/9999.',
  'message.excelUploadFromatError' : 'The {key} on line {i} is empty or malformed.',
  'message.excelUploadDuplicatedError' : 'Lines {i} and {j} are duplicated.',
  'message.excelUploadFileNotFoundError' : 'Upload file not found.',
  'message.excelUploadError' : 'Error uploading.',
  'message.excelUploadItemDecimalOverError' : '{orderNoTitle}: {orderNo}, {orderDetailTitle}: {orderDetailNo} and {dateTitle}: {date}  {item} allows up to {n} decimal places.',
  'message.excelUploadItemMustGreatThenZeroError' : '{orderNoTitle}: {orderNo}, {orderDetailTitle}: {orderDetailNo} and {dateTitle}: {date}  {item} must be greater than zero.',
  'message.error' : 'Error',
  'message.priceOrCurrencyNullError' : 'Please enter {i} and {j} in same times.',
  'message.priceOrCurrencyNullError2' : 'Please enter {i} and {j} of {item} in same times.',
  'message.uploadedFileEmpty' : 'The uploaded file is empty.',
  'message.deliveryResponseQtyOver' : 'The total number of Delivery Response Qty are greater than the Order Qty.',
  'message.goodIssueQtyOver' : 'The total number of Plan GI Qty are greater than the Order Qty.',
  'message.notAllowLineAtEnd' : 'Horizontal lines are not allowed at the end',
  'message.noMultipleLines' : 'Multiple horizontal lines are not allowed',
  'message.onlyNumberOrNumberAndLine' : 'Only numbers or numbers and horizontal lines ‘-’ are allowed',
  'message.deliveryOutputFormSubmitWarning1' : 'Please confirm that the goods issue are correct again before proceeding to output the delivery note.',
  'message.deliveryOutputFormSubmitWarning2' : 'After a certain amount of time has elapsed after outputting the delivery note, you will not be able to cancel it.',
  'message.numberTooLarge' : 'Value too large',
  'message.sureYouWantToClose' : 'Would you like to close the detail page because the content you are editing has not yet been replied?',
  'message.closeWillLostEdit' : '※Please click [Reply] in the lower right corner if you wish to reply.',
  'message.waiverChangeThenClose' : 'Close detail page',
  'message.canNotDeleteDeletedItem' : 'Some selected items have been marked for deletion and therefore cannot be deleted',

  'agreement.readed' : 'I have read and agree to the above license',
  'agreement.enter' : 'Enter System',
};
export default enUS;
