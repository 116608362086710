import { Drawer, Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { DeliveryDto, DeliveryNoteDto } from '../delivery/api'
import { displayUtil } from '../util';

export interface Props {
    visible: boolean;
    detail?: DeliveryDto;
    close: () => void;
}

export function DeliveryDetail(props: Props) {

    // 納品番号
    const deliveryNoTitle = <FormattedMessage id='delivery.deliveryNo' />;
    //着荷予定日
    const estimatedTimeArrivaTitle = <FormattedMessage id='delivery.estimatedTimeArriva' />;
    // 品目コード
    const itemTitle = <FormattedMessage id='delivery.item' />;
    // 品目名称
    const itemNameTitle = <FormattedMessage id='delivery.itemName' />;
    // 数量
    const quantityTitle = <FormattedMessage id='delivery.quantity' />;
    // 注文番号
    const orderNoTitle = <FormattedMessage id='delivery.orderNo' />;
    // 注文明細番号
    const detailNoTitle = <FormattedMessage id='delivery.detailNo' />;

    // 详细columns
    const columns: ColumnsType<DeliveryNoteDto> = [
        // 納品番号
        { title: deliveryNoTitle, dataIndex: 'deliveryNo'},
        // 着荷予定日
        { title: estimatedTimeArrivaTitle, dataIndex: 'estimatedTimeArriva', render: displayUtil.date},
        // 品目コード
        { title: itemTitle, dataIndex: 'item' },
        // 品目名称
        { title: itemNameTitle, dataIndex: 'itemName' },
        // 数量
        { title: quantityTitle, dataIndex: 'quantity' },
        // 注文番号
        { title: orderNoTitle, dataIndex: 'orderNo' },
        // 注文明細番号
        { title: detailNoTitle, dataIndex: 'detailNo' }
    ];


    return (
        <>
            <Drawer
                title={<FormattedMessage id='delivery.detail' />}
                width='1180px'
                footer={null}
                visible={props.visible}
                bodyStyle={{ paddingBottom: 80 }}
                destroyOnClose={true}
                onClose={props.close}>
                <Table<DeliveryNoteDto>
                    className="list-table"
                    pagination={false}
                    columns={columns}
                    dataSource={props.detail?.deliveryDetails}
                    rowClassName={displayUtil.rowClassName}
                    rowKey={'id'}
                    tableLayout={'fixed'}
                />
            </Drawer >
        </>
    )
}





