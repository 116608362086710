export const equals = (str1: string[], str2: string[]) => {

  if (!str2 && !str1) {
    return true;
  }

  if (!str1) {
    return false;
  }

  if (!str2) {
    return false;
  }

  if (str1.length !== str2.length)
    return false;

  for (var i = 0, l = str1.length; i < l; i++) {

    if (str1[i] !== str2[i]) {
      return false;
    }
  }

  return true;
};
