import React from 'react';

import { Row, Col, Form } from 'antd';

interface Props {
    cols: [];
    className : string;
}

export function EditableDetail(props: Props) {

    const cols = [];

    for (let col of props.cols) {
        cols.push(

            <Col span={8} >
                <Form.Item
                    labelCol={{ span: 10 }}
                    wrapperCol={{ span: 14 }}
                    className={props.className}
                    label={col[1]}
                    name={col[2]}
                    rules={col[3]}
                >
                    {col[0]}
                </Form.Item>
            </Col>
        );



        //     cols.push(<Col span={3} >{col[1]} </Col>);
        //     cols.push(<Col span={5}> <Form.Item rules={col[3]}>{col[0]}</Form.Item></Col>);
    }






    return (
        <Row gutter={[16, 0]}>
            {cols}
        </Row>
    );
}
