import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl, } from 'react-intl';

import { Button, Table, Form, Popconfirm } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { HistoryOutlined } from '@ant-design/icons';

import './index.css';
import { useUser } from '../authorization';
import { EditableCellNumberCheck, OrderResponseEditableCellNumberCheck } from '../common'
import { displayUtil } from '../util';

import { DeliveryInfoRecordDto, OrderListRecordDto, OrderResponseRecordDto, DetailRecordDto } from './api';
import { Dayjs } from 'dayjs';

export interface Props {
  detail?: OrderListRecordDto;
  editingDetail?: DetailRecordDto;
  gap: number;
  deliveryInfoGap: number;
  loading: boolean;
  setEditingDetail: (dto?: DetailRecordDto) => void;
  save: (record: DetailRecordDto) => void;
  remove: (record: DetailRecordDto) => void;
  showHistory: () => void;
}

export function OrderResponseDetailOrderResponse(props: Props) {

  // 共通のstates
  const intl = useIntl();

  const [form] = Form.useForm();
  const user = useUser();

  const [dataSource, setDataSource] = useState<OrderResponseRecordDto[]>([]);
  const [addGap, setAddGap] = useState<number>(0);

  useEffect(
    () => {
      if (!!props.detail) {
        if (!!props.editingDetail && props.editingDetail.type === 'OrderResponse') {
          form.resetFields();
          form.setFieldsValue(props.editingDetail);
          if (props.editingDetail.id <= 0) {
            const tempDto = props.editingDetail as OrderResponseRecordDto;
            setDataSource([...props.detail.orderResponses, tempDto]);
            setAddGap(!!tempDto.quantity ? tempDto.quantity : 0);
            return;
          }
        }
        setDataSource(props.detail.orderResponses);
        return;
      }
      setDataSource([]);
    }, [props.detail, props.editingDetail]
  );

  const isEditing = (record: OrderResponseRecordDto) => (!!props.editingDetail && props.editingDetail.type === 'OrderResponse' && props.editingDetail.id === record.id);

  const submitForm = (record: OrderResponseRecordDto) => {
    form.validateFields()
      .then((row) => {

        const dto: OrderResponseRecordDto = { ...record, ...row, };

        props.save(dto);
      });
  };

  // 納品回答　→　出荷情報の追加処理
  const addDeliveryInfo = (record: OrderResponseRecordDto) => {

    if (!!props.editingDetail) {
      return;
    }

    const dto: DeliveryInfoRecordDto = { type: 'DeliveryInfo', id: -1, estimatedTimeArriva: record.deliveryPlanDate, quantity: record.quantity, delFlag:'' };
    props.setEditingDetail(dto);
  }

  // 編集ボタン関連
  const editButtons = (text: any, record: OrderResponseRecordDto, index?: number) => {

    // 編集中の場合、保存とキャンセルボタンを表示する
    if (isEditing(record)) {
      return (<>
        <Popconfirm
          title={intl.formatMessage({ id: 'message.editConfirm' })}
          onConfirm={() => submitForm(record)}
          okText={intl.formatMessage({ id: 'common.confirm' })}
          cancelText={intl.formatMessage({ id: 'common.cancel2' })} >
          <Button type='link'>
            <FormattedMessage id='common.save' />
          </Button>
        </Popconfirm>
        <Button type='link' onClick={() => props.setEditingDetail(undefined)}>
          <FormattedMessage id='common.cancel2' />
        </Button>
      </>)
    }

    // サプライヤーの場合
    if (user?.isSupplier) {
      // 納品指示以外の場合は編集可能で、納品指示と納品回答の「追加」を表示する
      return (<>
        <Button type='link' onClick={() => props.setEditingDetail(record)} disabled={!!props.editingDetail}>
          <FormattedMessage id='common.edit' />
        </Button>
        <Popconfirm disabled={!!props.editingDetail}
          title={intl.formatMessage({ id: 'message.cancel2Confirm' })}
          onConfirm={() => props.remove(record)}

          okText={intl.formatMessage({ id: 'common.yes' })}
          cancelText={intl.formatMessage({ id: 'common.no' })} >
          <Button type='link' disabled={!!props.editingDetail}>
            <FormattedMessage id='common.cancel2' />
          </Button>
        </Popconfirm>
        <Button type='link' onClick={() => addDeliveryInfo(record)} disabled={!!props.editingDetail || props.deliveryInfoGap <= 0}>
          <FormattedMessage id='orderResponse.delivery' />
        </Button>
      </>);
    }
  }

  // const notAllow = (current: OrderResponseRecordDto): (Dayjs | null)[] => {
  //   return dataSource.filter(dto => (dto.id !== current.id)).map(dto => dto.deliveryPlanDate);
  // }

  const detailNoWidth = '70px';
  const deliveryPlanDateWidth = '140px';
  const responseQuantityWidth = '100px';
  const editButtonWidth = '160px';

  const detailNoTitle = <FormattedMessage id='orderResponse.detailNo' />;
  // 回答納期
  const deliveryPlanDateTitle = <FormattedMessage id='orderResponse.deliveryPlanDate' />;
  // 回答数量
  const responseQuantityTitle = <FormattedMessage id='orderResponse.responseQuantity' />;

  const orderResponseColumns: any = [
    // 明细号
    { title: detailNoTitle, dataIndex: 'detailNo', width: detailNoWidth },
    // 回答納期
    { title: deliveryPlanDateTitle, dataIndex: 'deliveryPlanDate', editable: true, type: 'date', render: displayUtil.date, width: deliveryPlanDateWidth },
    // 回答数量
    { title: responseQuantityTitle, dataIndex: 'quantity', editable: true, gap: props.gap - addGap, type: 'number', width: responseQuantityWidth, maxLength: 100 },
    // アクション
    { title: '', dataIndex: 'operation', render: editButtons, width: editButtonWidth },
  ];

  const mergedOrderResponseColumns: ColumnsType<OrderResponseRecordDto> = orderResponseColumns.map(
    (col: any) => {
      if (!col.editable) {
        return col;
      }

      return {
        ...col,
        onCell: (record: any) => {
          
          const numberInputValidator = (value: any): string | undefined => {

            if(value === undefined || value === null || value === '') {
              return intl.formatMessage({ id: 'estimate.nullError'}, {name: intl.formatMessage(col?.title?.props)})
            }

            const valueDecimalIsOverThree = value !== +( +value.toFixed(3)) // 将输入的数字四舍五入保留三位小数截取，如果截取前的数和截取后的数相等，则证明输入的数字小数点小于三位小数

            if(value <= 0) {
              return intl.formatMessage({id: 'message.onlyPositiveNumber'})
            } else if(value > col.gap + (!!record.quantity ? record.quantity : 0)) {
              return intl.formatMessage({id: 'message.deliveryResponseQtyOver'})
            } else if (valueDecimalIsOverThree) {
              return intl.formatMessage({ id: 'message.DigitRetained2' }, { n: '3' })
            } else { 
              return undefined
            }
          }

          return {
            record,
            inputType: col.type,
            dataIndex: col.dataIndex,
            title: col.title,
            numberInputValidator: numberInputValidator,
            maxLength: col.maxLength,
            editing: isEditing(record),
            // notAllowValues: notAllow(record),
          }
        },
      };
    });

  const titleButtons = () => {
    if (!(user?.isSupplier)) {
      return (<></>);
    }

    if (!!props.editingDetail) {
      if (props.editingDetail.type === 'OrderResponse') {
        return (<Button onClick={() => props.setEditingDetail(undefined)} style={{ marginLeft: '20px' }}>
          <FormattedMessage id='common.cancel' />
        </Button>);
      } else {
        return (<></>);
      }
    }

    if (props.gap === 0) {
      return (<></>);
    }

    return (<Button onClick={() => props.setEditingDetail({ id: -1, type: 'OrderResponse' })} style={{ marginLeft: '20px' }}>
      <FormattedMessage id='common.add' />
    </Button>);
  };

  const title = () => (<>
    <FormattedMessage id='orderResponse.orderResponseList' />
    {titleButtons()}
    <Button onClick={props.showHistory} style={{ marginLeft: '20px' }}>
      <HistoryOutlined />
      <FormattedMessage id='common.history' />
    </Button>
  </>);

  return (<Form form={form} component={false}>
    <Table<OrderResponseRecordDto>
      title={title}
      loading={props.loading}
      className="list-detail-table"
      bordered={false}
      rowClassName={displayUtil.rowClassName}
      components={{
        body: {
          cell: OrderResponseEditableCellNumberCheck
        },
      }}
      dataSource={dataSource}
      columns={mergedOrderResponseColumns}
      pagination={false}
      size='small'
      style={{ transform: 'translateY(-20px)' }}
      scroll={{ scrollToFirstRowOnChange: true, y: '150px' }} />
  </Form>);
}
