import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { Button, Modal, Spin} from 'antd';

import './index.css';

import { CommonExcelUpload, CommonExcelUploadProps, RowData } from '../common';
import { fillOrderDetails, OrderListRecordDto } from './api';
import { Dayjs } from 'dayjs';
import { displayUtil } from '../util';

export interface Props {
  visible: boolean;
  upload: (data: OrderListRecordDto[]) => Promise<any>;
  close: () => void;
  uploading: boolean
}

export function DeliveryDetailUpload(props: Props) {

  // 共通のstates
  const [dtos, setDtos] = useState<OrderListRecordDto[]>([]);
  const intl = useIntl();

  const validate = async (rowDatas: RowData[]) => {

    const uploadDtos = rowDatas.reduce(
      (currentDtos: OrderListRecordDto[], e: RowData) => {
        let existFlag: boolean = false;

        for (const element of currentDtos) {

          if (element.orderNo === e['orderNo'] && element.detailNo === e['detailNo']) {
            element.deliveryDetails.push({
              type: 'DeliveryInfo',
              id: -1,
              detailNo: e['deliveryDetailNo'] as number,
              shipDate: e['shipDate'] as Dayjs,
              estimatedTimeArriva: e['estimatedTimeArriva'] as Dayjs,
              quantity: e['quantity'] as number,
              delFlag: e['delFlag'] as string,
            });

            existFlag = true;
          }
        }

        if (!existFlag) {
          currentDtos.push({
            id: -1,
            orderNo: e['orderNo'] as string,
            detailNo: e['detailNo'] as number,
            orderDate: null,
            iniDeliveryDate: null,
            productionPlanDate: null,
            orderResponses: [],
            devInstrucstions: [],

            deliveryDetails: [{
              type: 'DeliveryInfo',
              id: -1,
              detailNo: e['deliveryDetailNo'] as number,
              shipDate: e['shipDate'] as Dayjs,
              estimatedTimeArriva: e['estimatedTimeArriva'] as Dayjs,
              quantity: e['quantity'] as number,
              delFlag: e['delFlag'] as string
            }],
          })
        }

        return currentDtos;
      },
      []
    );

    const existDtos: OrderListRecordDto[] = await fillOrderDetails(uploadDtos);

    const errors: string[] = [];
    uploadDtos.forEach(dto => {

      const haveExistData = existDtos && existDtos.filter(existDto => existDto.orderNo === dto.orderNo && existDto.detailNo === dto.detailNo).length > 0 // 判断文件中上传的订单号和订单详细号在已有的数据中是否存在，存在返回ture，否则返回false
      if(!haveExistData) {
        errors.push(`注文番号：${dto.orderNo}、明細番号：${dto.detailNo}の注文情報が存在していません。`);
      }

      if (existDtos && existDtos.length > 0) {
        for (let existDto of existDtos) {

          if (existDto.orderNo === dto.orderNo && existDto.detailNo === dto.detailNo) {

            dto.id = existDto.id;

            // データチェック
            let sumQuantiy = 0;
            const existDetailNos = existDto.deliveryDetails.map(e => e.detailNo);

            dto.deliveryDetails.forEach(deliveryDetail => {

              // 削除対象以外の場合、加算する
              if (!deliveryDetail.delFlag) {
                sumQuantiy += !!deliveryDetail.quantity ? deliveryDetail.quantity : 0;
              }

              if ((!existDetailNos.includes(deliveryDetail.detailNo) && !!deliveryDetail.delFlag && deliveryDetail.id === -1)
                || (!existDetailNos.includes(deliveryDetail.detailNo) && deliveryDetail.detailNo !== undefined && deliveryDetail.detailNo !== 0)) {
                //削除データはＤＢに存在しません
                errors.push(`注文番号：${dto.orderNo}、注文明細番号：${dto.detailNo}、明細番号：${!!deliveryDetail.detailNo ? deliveryDetail.detailNo : '空'}で削除データはＤＢに存在しません`);
              }

              existDto.deliveryDetails.forEach(existDeliveryDetail => {
                if (deliveryDetail.detailNo === existDeliveryDetail.detailNo && !!existDeliveryDetail.deliveryId) {
                  errors.push(`注文番号：${dto.orderNo}、注文明細番号：${dto.detailNo}、出荷明細番号：${existDeliveryDetail.detailNo}の出荷書出力済みので、更新不可となりました。`);
                }
              });
            });


            const detailNosDuplicate: number[] = [];
            let devlieryDetailNos = dto.deliveryDetails.filter(f => f.detailNo !== undefined).map(m => m.detailNo);
            devlieryDetailNos.forEach((d) => {
              if (!!d && !detailNosDuplicate.includes(d)) {
                detailNosDuplicate.push(d);
              }
            })

            if (detailNosDuplicate.length < devlieryDetailNos.length) {
              // 存在チェック&重複チェック
              errors.push(`注文番号：${dto.orderNo}、注文明細番号：${dto.detailNo}で出荷明細番号が繰り返される`);
            }

            existDto.deliveryDetails.forEach(existDeliveryDetail => {

              if (!devlieryDetailNos.includes(existDeliveryDetail.detailNo)) {

                sumQuantiy += !!existDeliveryDetail.quantity ? existDeliveryDetail.quantity : 0;
              }
            })

            const quantity = !!existDto.quantity ? existDto.quantity : 0;
            if (sumQuantiy > quantity) {
              errors.push(`注文番号：${dto.orderNo}、注文明細番号：${dto.detailNo}の出荷件数は注文件数を超えってしまいました。`);
            }
          }
        }
      }

      dto.deliveryDetails.forEach((deliveryDetailDto) => {

        const quantityD: number | undefined = deliveryDetailDto.quantity

        if(quantityD === undefined || quantityD === null) {
          return undefined
        } else {
          const quantityDecimalIsOverThree = quantityD !== +( +quantityD.toFixed(3)) // 将数字四舍五入保留三位小数截取，如果截取前的数和截取后的数相等，则证明输入的数字小数点小于三位小数

          if(quantityD <= 0) {
            errors.push(intl.formatMessage({id: 'message.excelUploadItemMustGreatThenZeroError'}, {orderNoTitle: intl.formatMessage({id: 'shipment.orderNo'}), orderNo: dto.orderNo, orderDetailTitle: intl.formatMessage({id: 'acceptance.detailNo'}), orderDetailNo: dto.detailNo, dateTitle: intl.formatMessage({id: 'orderResponse.estimatedTimeArriva'}), date: displayUtil.date(deliveryDetailDto.estimatedTimeArriva), item: intl.formatMessage({id: 'orderResponse.planDeliveryQuantity'}) }))
          } else if (quantityDecimalIsOverThree) {
            errors.push(intl.formatMessage({id: 'message.excelUploadItemDecimalOverError'},       {orderNoTitle: intl.formatMessage({id: 'shipment.orderNo'}), orderNo: dto.orderNo, orderDetailTitle: intl.formatMessage({id: 'acceptance.detailNo'}), orderDetailNo: dto.detailNo, dateTitle: intl.formatMessage({id: 'orderResponse.estimatedTimeArriva'}), date: displayUtil.date(deliveryDetailDto.estimatedTimeArriva), item: intl.formatMessage({id: 'orderResponse.planDeliveryQuantity'}), n: 3 }))
          }
        }
      })
    });

    if (errors.length > 0) {
      throw errors;
    }

    return uploadDtos;
  };


  interface UnitValidator {
    validate: (value: any) => boolean;
    message: string;
  }

  // 判定文件上传时出荷数量是否是合规的回调函数 和 报错信息
  const planDeliveryQuantityCheck: UnitValidator[] = [{validate: (value: any) => {

    if(value !== undefined || value !== null) {

      const valueDecimalIsOverThree = value !== +( +value.toFixed(3)) // 将输入的数字四舍五入保留三位小数截取，如果截取前的数和截取后的数相等，则证明输入的数字小数点小于三位小数

      if(value <= 0) {
        return true
      } else if (valueDecimalIsOverThree) {
        return true
      } else { 
        return false
      }
      
    }
    return false
  }, message: `message.excelUploadFromatError`}]




  const uploadProps: CommonExcelUploadProps = {
    row: {
      columns: [
        { key: 'orderDetail.orderNo', field: 'orderNo', type: 'string', isRequired: true, validators: [], },
        { key: 'orderDetail.detailNo', field: 'detailNo', type: 'int', isRequired: true, validators: [], },
        { key: 'orderResponse.detailNo', field: 'deliveryDetailNo', type: 'int', isRequired: false, validators: [], },
        { key: 'orderResponse.shipDate', field: 'shipDate', type: 'date', isRequired: false, validators: [], },
        { key: 'orderResponse.estimatedTimeArriva', field: 'estimatedTimeArriva', type: 'date', isRequired: true, validators: [], },
        { key: 'orderResponse.planDeliveryQuantity', field: 'quantity', type: 'decimal', isRequired: true, validators: [], },
        { key: 'orderResponse.deliveryDeleteFlag', field: 'delFlag', type: 'string', isRequired: false, validators: [], }
      ],
      uniqueItems: [],
    },
    validate: validate,
    upload: (data: OrderListRecordDto[]) => {
      setDtos(data);
    },
  };

  const upload = () => {
    props.upload(dtos).then(() => {
      setDtos([]);
      props.close();
    });
  };

  return (
    <Modal
      title={<FormattedMessage id='orderResponse.uploadDeliveryInfos' />}
      width='1080px'
      footer={null}
      visible={props.visible}
      bodyStyle={{ paddingBottom: 80 }}
      destroyOnClose={true}
      onCancel={props.close}>
      <Spin 
        size='default'
        spinning={props.uploading}
      >
        <CommonExcelUpload {...uploadProps} />
      </Spin>
      <div className='search-button upload-buttom'>
        <Button className='search-close-btn' onClick={props.close}>
          <FormattedMessage id='common.cancel' />
        </Button>
        <Button className='search-search-btn' type='primary' onClick={upload} disabled={dtos.length === 0}>
          <FormattedMessage id='common.upload' />
        </Button>
      </div>
    </Modal >
  );
}

