import { Row, Col, Button, Modal, Space, Upload, Form, Input, Popconfirm } from 'antd';
import { EstimateRequestSpecifySupplierDto } from './api';
import { FormattedMessage } from 'react-intl';
import React, { useEffect, useState } from 'react';
import { displayUtil } from '../util';
import { useFields } from '../fields';
import { DownloadOutlined } from '@ant-design/icons';
import { Attachment } from '../common';
import { CommonFileUpload } from '../common/CommonFileUpload';
export interface Props {
  visible: boolean;
  detail?: EstimateRequestSpecifySupplierDto;
  enable: boolean;
  reApply: () => void;
  adopt: () => void;
  refuse: () => void;
  close: () => void;

}
export function EstimationSpecifyDetail(props: Props) {

  useEffect(() => {
    if (props.visible === true) {
      if (!!props.detail && !!props.detail.supplier && !!props.detail.supplier.remarks) {
        if (props.detail.supplier.remarks.length > 18) {
          const remarkTextAreaElement = document.getElementById('remarkTextAreasupplier');
          if (!!remarkTextAreaElement) {
            remarkTextAreaElement.style.overflowY = 'scroll'
            setTimeout(() => {
              if (!!remarkTextAreaElement) {
                remarkTextAreaElement.style.height = '2000px'
              }
            }, 200);
          }
        }
      }
      if (!!props.detail && !!props.detail.remarks) {
        if (props.detail.remarks.length > 22) {
          const remarkTextAreaElement = document.getElementById('remarkTextArea');
          if (!!remarkTextAreaElement) {
            remarkTextAreaElement.style.overflowY = 'scroll'
            setTimeout(() => {
              if (!!remarkTextAreaElement) {
                remarkTextAreaElement.style.height = '2000px'
              }
            }, 200);
          }
        }
      }
    } else {
      // document.getElementById('remarkTextAreasupplier')!.innerHTML = ''
    }
  }, [props.detail, props.visible])

  //案件状態
  const statusOptions = useFields('estimateStatus');
  const responseStatus = useFields('responseStatus');
  const buyerDepartmentOptions = useFields('buyerDepartment');
  //供应商
  const supplierOptions = useFields('supplier');
  //カテゴリー
  const estimatesTypeOptions = useFields('estimatesType');

//見積依頼編集画面依頼項目
const estimateSpecifyRequestEditDetailCols: [JSX.Element, any, any][] = [

  //案件状態
  [<FormattedMessage id='estimate.status' />, displayUtil.field(statusOptions)(props.detail?.status), ""],
  //入札依頼番号
  [<FormattedMessage id='estimate.requestNo' />, props.detail?.requestNo, ""],
  //発注部門
  [<FormattedMessage id='estimate.buyerDepartment' />, displayUtil.field(buyerDepartmentOptions)(props.detail?.buyerDepartmentCode), ""],
  //購買担当
  [<FormattedMessage id='estimate.purchasingManager' />, props.detail?.purchasingManagerName, ""],
  //見積依頼日
  [<FormattedMessage id='estimate.requestDate' />, displayUtil.date(props.detail?.requestDate), ""],
  //回答希望日
  [<FormattedMessage id='estimate.answerDeadline' />, displayUtil.date(props.detail?.answerDeadline), ""],
  //カテゴリー
  [<FormattedMessage id='estimate.estimatesType' />, displayUtil.field(estimatesTypeOptions)(props.detail?.estimatesType), ""],
  //品番
  [<FormattedMessage id='estimate.item' />, props.detail?.item, ""],
  //品名
  [<FormattedMessage id='estimate.itemName' />, props.detail?.itemName, ""],
  //希望単価
  [<FormattedMessage id='estimate.desireUnitPrice' />, props.detail?.desireUnitPrice, ""],
  //希望単価通貨
  [<FormattedMessage id='estimate.desireCurrency' />, props.detail?.desireCurrency, ""],
  //企画月数
  [<FormattedMessage id='estimate.planningMonths' />, props.detail?.planningMonths, <text>&nbsp;&nbsp;&nbsp;<FormattedMessage id='estimate.monthUnit' /></text>],
  //企画数量
  [<FormattedMessage id='estimate.planQuantity' />, props.detail?.planQuantity, <text>&nbsp;&nbsp;&nbsp;<FormattedMessage id='estimate.quantityUnit' /></text>],
  //月発注予定数
  [<FormattedMessage id='estimate.monthlyPlanOrderQuantity' />, props.detail?.monthlyPlanOrderQuantity, <text>&nbsp;&nbsp;&nbsp;<FormattedMessage id='estimate.quantityUnit' /></text>],
  //受渡場所
  [<FormattedMessage id='estimate.deliveryLocation' />, props.detail?.deliveryLocation, ""],
  // ユーザー名称
  [<FormattedMessage id='estimate.userName' />, props.detail?.userName, ""],
  // 更新日時
  [<FormattedMessage id='estimate.modifiedTime' />, displayUtil.date(props.detail?.modifiedTime), ""],
  //サプライヤー
  // [<FormattedMessage id='estimate.supplier' />, props.detail?.supplier,""],
  //備考
  [<FormattedMessage id='estimate.remarks' />, <Input.TextArea
    bordered={false}
    disabled
    id={'remarkTextArea'}
    autoSize={{ maxRows: 5 }}
    value={props.detail?.remarks}
    style={{ padding: '0', color: 'rgba(0, 0, 0, 0.85)', textDecoration: 'underline', cursor: 'text' }}></Input.TextArea>, ""],
  [<FormattedMessage id='estimate.upload' />,
  <Row>
    <CommonFileUpload attachments={!!props.detail?.attachments ? props.detail.attachments : []}
      canUpload={false}
      persist={(uuid: string) => {

      }}
      remove={(uuid: string) => {
      }} />
  </Row>, ""]
]
const estimateSpecifyResposeEditDetailColsLeft: [JSX.Element, any, any][] = [
  //回答状態
  [<FormattedMessage id='estimate.responseStatus' />, displayUtil.field(responseStatus)(props.detail?.supplier?.responseStatus), ""],
  //購入先
  [<FormattedMessage id='estimate.supplier' />, displayUtil.field(supplierOptions)(props.detail?.supplier?.supplier), ""],
  //見積有効期限
  [<FormattedMessage id='estimate.estimatedEffectiveDate' />, displayUtil.date(props.detail?.supplier?.estimatedEffectiveDate), ""],
  //リードタイム
  [<FormattedMessage id='estimate.leadTime' />, props.detail?.supplier?.leadTime, ""],
  //原産国
  [<FormattedMessage id='estimate.originalCountryName' />, props.detail?.supplier?.originalCountryName, ""],
  //メーカー名
  [<FormattedMessage id='estimate.makerName' />, props.detail?.supplier?.makerName, ""],
  //メーカー品番
  [<FormattedMessage id='estimate.makerItem' />, props.detail?.supplier?.makerItem, ""],
  //部品種別
  [<FormattedMessage id='estimate.itemTypeCode' />, props.detail?.supplier?.itemTypeCode, ""],
  //契約条件
  [<FormattedMessage id='estimate.contractConditionsCode' />, props.detail?.supplier?.contractConditionsCode, ""],
  //見積適用開始日
  [<FormattedMessage id='estimate.estimateStartDate' />, displayUtil.date(props.detail?.supplier?.estimateStartDate), ""],
  //見積適用終了日
  [<FormattedMessage id='estimate.estimateEndDate' />, displayUtil.date(props.detail?.supplier?.estimateEndDate), ""],
  //生産場所（前工程）
  [<FormattedMessage id='estimate.prevLocation' />, props.detail?.supplier?.prevLocation, ""],
  //生産場所（後工程）
  [<FormattedMessage id='estimate.nextLocation' />, props.detail?.supplier?.nextLocation, ""],
  //備考
  [<FormattedMessage id='estimate.remarks' />, <Input.TextArea
    bordered={false}
    disabled
    id={'remarkTextAreasupplier'}
    autoSize={{ maxRows: 5 }}
    value={props.detail?.supplier?.remarks}
    style={{ padding: '0', color: 'rgba(0, 0, 0, 0.85)', textDecoration: 'underline', cursor: 'text' }}></Input.TextArea>, '']
  //補足資料
  // [<FormattedMessage id='estimate.responseStatus' />, props.detail?.requestNo,""],
]

//随机数



const estimateSpecifyResposeEditDetailColsRight: [JSX.Element, any, any][] = [
  //単価
  [<FormattedMessage id='estimate.unitPrice' />, props.detail?.supplier?.unitPrice, props.detail?.supplier?.unitCurrency],
  //型費用
  [<FormattedMessage id='estimate.moldCost' />, props.detail?.supplier?.moldCost, props.detail?.supplier?.moldCurrency],
  //型の種類
  [<FormattedMessage id='estimate.moldType' />, props.detail?.supplier?.moldType, ""],
  //型の工程数
  [<FormattedMessage id='estimate.moldProcessNum' />, props.detail?.supplier?.moldProcessNum, ""],
  //型の面数
  [<FormattedMessage id='estimate.moldNum' />, props.detail?.supplier?.moldNum, ""],
  //治具费
  [<FormattedMessage id='estimate.jigCost' />, !!props.detail?.supplier?.jigCost ? displayUtil.currency(props.detail?.supplier?.jigCurrency, props.detail?.supplier?.jigCost) : 0, ''],
  //版費
  [<FormattedMessage id='estimate.editionFee' />, !!props.detail?.supplier?.editionFee ? displayUtil.currency(props.detail?.supplier?.editionCurrency, props.detail?.supplier?.editionFee) : 0, ''],
  //修理費
  [<FormattedMessage id='estimate.repairsCost' />, !!props.detail?.supplier?.repairsCost ? displayUtil.currency(props.detail?.supplier?.repairsCurrency, props.detail?.supplier?.repairsCost) : 0, ''],
  //最低発注数量(MOQ)
  [<FormattedMessage id='estimate.moq' />, props.detail?.supplier?.moq, <text>&nbsp;&nbsp;&nbsp;<FormattedMessage id='estimate.quantityUnit' /></text>],
  //最小梱包数量(MPQ)
  [<FormattedMessage id='estimate.mpq' />, props.detail?.supplier?.mpq, <text>&nbsp;&nbsp;&nbsp;<FormattedMessage id='estimate.quantityUnit' /></text>],
  //userName
  [<FormattedMessage id='estimate.userName' />, props.detail?.supplier?.userName, ''],
  //modifiedTime
  [<FormattedMessage id='estimate.modifiedTime' />, !!props.detail?.supplier?.modifiedTime ? displayUtil.date(props.detail?.supplier?.modifiedTime) : '', ''],
  [<FormattedMessage id='estimate.upload' />, <CommonFileUpload  
    attachments={ !!props.detail?.supplier.attachments ? props.detail.supplier.attachments : []}
    canUpload={ false}
    persist={ (uuid: string) => {
  
    }}
    remove={ (uuid: string) => {
    }}
   />, ""]
]


const requestCols: any = [];
estimateSpecifyRequestEditDetailCols.forEach((item, index) => {
  requestCols.push(<Col span={12} style={{ textAlign: 'right', fontWeight: 'bold' }} key={index}>{item[0]}</Col>);
  //控件
  requestCols.push(<Col span={12} style={{ textDecorationLine: 'underline' }} key={index + estimateSpecifyRequestEditDetailCols.length}><pre style={{marginBottom: '0px'}}>{item[1]}{!!item[2] && !!item[1] ? <>{item[2]}</> : <></>}</pre></Col>);
  //单位
  // requestCols.push(<Col span={4} style={{ textAlign: 'left', fontWeight: 'bold', }}>{col[2]}</Col>);
});

const resposeColsLeft = [];
for (let col of estimateSpecifyResposeEditDetailColsLeft) {
  // resposeColsLeft.push(<Col span={10} style={{ textAlign: 'right', fontWeight: 'bold', }}>{col[0]}</Col>);
  // //控件
  // resposeColsLeft.push(<Col span={10} style={{textDecorationLine: 'underline'}}>{col[1]}</Col>);
  // //单位
  // resposeColsLeft.push(<Col span={4} style={{ textAlign: 'left', fontWeight: 'bold', }}>{col[2]}</Col>);
  resposeColsLeft.push(
    <Row
    // gutter={[16, 200]}
    >
      <Col span={10} style={{ textAlign: 'right', fontWeight: 'bold', margin: '4px 0px', padding: '0px 8px' }}>
        {col[0]}
      </Col>
      <Col span={14} style={{ textDecorationLine: 'underline', margin: '4px 0px', padding: '0px 8px' }}>
        {col[1]}
      </Col>
    </Row>
  );
}



const resposeColsRight = [];
for (let col of estimateSpecifyResposeEditDetailColsRight) {
  resposeColsRight.push(
    <Row>
      <Col span={12} style={{ textAlign: 'right', fontWeight: 'bold', margin: '4px 0px', padding: '0px 8px' }}>
        {col[0]}
      </Col>
      <Col span={12} style={{ textDecorationLine: 'underline', margin: '4px 0px', padding: '0px 8px' }}>
        {col[1]}
        {col[2]}
      </Col>
    </Row>
  );
}


return (
  <Modal title={<FormattedMessage id='estimate.reponseDetail' />}
    visible={props.visible}   //设置默认隐藏
    onCancel={props.close}  // 点击取消按钮，对话框消失
    footer={false}   //对话框的底部
    width={1200}  //宽度设置
    bodyStyle={{ paddingTop: 10 }}
    zIndex={2}
    destroyOnClose={true}
  >
    <Row style={{ paddingBottom: 10 }}>
      <Col span={9} style={{ textAlign: 'left' }}><FormattedMessage id='estimate.requestInfo' /></Col>
      <Col span={15} style={{ textAlign: 'left' }}><FormattedMessage id='estimate.reponseInfo' /></Col>
    </Row>


    <Row>
      <Col span={8} style={{ borderStyle: 'solid', borderColor: '#d9d9d9', borderWidth: 1, padding: '8px 0' }}>
        <Row gutter={[16, 8]}>
          {requestCols}
        </Row>
      </Col>
      <Col span={15} offset={1} style={{ borderStyle: 'solid', borderColor: '#d9d9d9', borderWidth: 1, padding: '4px 0' }}>
        <Row >
          <Col span={12}>
            {resposeColsLeft}
          </Col>
          <Col span={12}>
            {resposeColsRight}
          </Col>
        </Row>
      </Col>
    </Row>

    <Row style={{ marginTop: 20 }}>
      <Col span={8} style={{ textAlign: 'right' }}>
        <Popconfirm
          title={<FormattedMessage id='message.reapplyConfirm' />}
          onConfirm={() => {
            props.reApply()
          }}
          okText={<FormattedMessage id='common.confirm' />}
          cancelText={<FormattedMessage id='common.cancel' />}
          disabled={props.detail?.status !== '001' || (props.detail.supplier.responseStatus === '001' || props.detail.supplier.responseStatus === '002' || props.detail.supplier.responseStatus === '006' || props.detail.supplier.responseStatus === '005')} >
          <Button htmlType="submit" disabled={props.detail?.status !== '001' || (props.detail.supplier.responseStatus === '001' || props.detail.supplier.responseStatus === '002' || props.detail.supplier.responseStatus === '006' || props.detail.supplier.responseStatus === '005')}>
            <FormattedMessage id='estimate.reapply' />
          </Button>
        </Popconfirm>
      </Col>
      <Col span={5} style={{ textAlign: 'center' }}>
        <Popconfirm
          title={<FormattedMessage id='message.approveConfirm' />}
          onConfirm={() => {
            props.adopt()
          }}
          okText={<FormattedMessage id='common.confirm' />}
          cancelText={<FormattedMessage id='common.cancel' />}
          disabled={props.detail?.supplier?.responseStatus === '003' || props.detail?.status !== '001' || props.detail.supplier.responseStatus === '006' || props.detail.supplier.responseStatus === '002' || props.detail.supplier.responseStatus === '005'}>
          <Button htmlType="submit" disabled={props.detail?.supplier?.responseStatus === '003' || props.detail?.status !== '001' || props.detail.supplier.responseStatus === '006' || props.detail.supplier.responseStatus === '002' || props.detail.supplier.responseStatus === '005'}>
            <FormattedMessage id='estimate.approve' />
          </Button>
        </Popconfirm>
      </Col>
      <Col span={3} style={{ textAlign: 'left' }}>
        <Popconfirm
          title={<FormattedMessage id='message.refuseConfirm' />}
          onConfirm={() => {
            props.refuse()
          }}
          okText={<FormattedMessage id='common.confirm' />}
          cancelText={<FormattedMessage id='common.cancel' />}
          disabled={props.detail?.supplier?.responseStatus === '004' || props.detail?.status !== '001' || props.detail.supplier.responseStatus === '006' || props.detail.supplier.responseStatus === '002' || props.detail.supplier.responseStatus === '005'}>
          <Button htmlType="submit" disabled={props.detail?.status !== '001' || props.detail?.supplier?.responseStatus === '004' || props.detail.supplier.responseStatus === '006' || props.detail.supplier.responseStatus === '002' || props.detail.supplier.responseStatus === '005'}>
            <FormattedMessage id='estimate.refuse' />
          </Button>
        </Popconfirm>
      </Col>
      <Col span={8} style={{ textAlign: 'left' }}>
        <Button onClick={props.close}>
          <FormattedMessage id='common.close' />
        </Button>
      </Col>
    </Row>

  </Modal>
);
}

