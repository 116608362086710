import React, { useEffect } from 'react'
import { DeliveryOutputSearchDto } from './api';
import { Modal, Form, Select, Input, Button,  } from 'antd';
import { useUser } from '../authorization';
import { FormattedMessage } from 'react-intl';
import { Field, useFields } from '../fields';
import { filterUtil, } from '../util';
import { DatePicker } from '../common';
const { RangePicker } = DatePicker;

export interface Props {
    visible: boolean;
    close: () => void;
    searchDto: DeliveryOutputSearchDto;
    handleSearch: (outPut: DeliveryOutputSearchDto) => void;

}

export function DeliveryOutputSearchForm(props: Props) {

    return (
        <Modal
            title={<FormattedMessage tagName='h4' id='delivery.search' />}
            footer={null}
            visible={props.visible}
            closable={false}
            afterClose={props.close}
            destroyOnClose={true}
            width={560}>
            {props.visible && <DeliveryOutputSearch {...props} />}
        </Modal>
    );
}

const DeliveryOutputSearch = (props: Props) => {

    const [form] = Form.useForm<DeliveryOutputSearchDto>();
    const user = useUser();
    const estimatedTimeArrivaTitle = <FormattedMessage id='delivery.estimatedTimeArriva' />;
    const supplierTitle = <FormattedMessage id='delivery.supplier' />;
    const supplierOptions = useFields('fullSupplier');
    const locationCdOptions = useFields('locationName');
    const locationCdTitle = <FormattedMessage id='delivery.locationCd' />;
    // 注文番号
    const orderNoTitle = <FormattedMessage id='orderDetail.orderNo' />;
    // 品番
    const itemTitle = <FormattedMessage id='estimate.item' />
    //  品名
    const itemNameTitle = <FormattedMessage id='estimate.itemName' />
    const itemOptions = useFields('item');

    useEffect(() => {
        form.setFieldsValue(props.searchDto);
    });

    return (
        <Form
            form={form}
            onFinish={props.handleSearch}
            layout={'horizontal'}
            colon={false}
            size={'large'}
            labelCol={{ span: 6 }}
            wrapperCol={{ span: 16 }}>
            <Form.Item name='suppliers' label={supplierTitle} >
                <Select<Field>
                    mode={'multiple'}
                    className="select-mutil"
                    allowClear={true}
                    autoClearSearchValue={false}
                    filterOption={filterUtil.selectFilter}
                    onSearch={filterUtil.onSearchForm(form, 'supplier', supplierOptions)}
                    loading={supplierOptions.length === 0}
                    options={supplierOptions} />
            </Form.Item>
            <Form.Item name='locationName' label={locationCdTitle} >
                <Select options={locationCdOptions} allowClear={true} />
            </Form.Item>
            <Form.Item name='estimatedTimeArriva' label={estimatedTimeArrivaTitle} >
                <RangePicker allowEmpty={[true, true]} style={{ width: '100%' }} allowClear={true} />
            </Form.Item>
            <Form.Item name='item' label={itemTitle} >
                <Select<Field>
                    mode={'multiple'}
                    className="select-mutil"
                    allowClear={true}
                    autoClearSearchValue={false}
                    filterOption={filterUtil.selectFilter}
                    onSearch={filterUtil.onSearchForm(form, 'item', itemOptions)}
                    loading={itemOptions.length === 0}
                    options={itemOptions}
                    style={{ maxHeight: '100px' }} />
            </Form.Item>
            <Form.Item name='itemName' label={itemNameTitle} >
                <Input min={1} max={40} />
            </Form.Item>
            <Form.Item name='orderNo' label={orderNoTitle}>
                <Input min={1} max={40} allowClear={true} />
            </Form.Item>
            <Form.Item style={{ marginLeft: '20px' }}>
                <div className='search-button' style={{ paddingLeft: '20px' }}>
                    <Button className='search-close-btn' onClick={props.close}>
                        <FormattedMessage id='common.close' />
                    </Button>
                    <Button className='search-search-btn' htmlType='submit'>
                        <FormattedMessage id='common.search' />
                    </Button>
                </div>
            </Form.Item>
        </Form>
    );
}