// React APIs
import React from 'react';

// 機能内部のAPIs
import { BillingDetailDetail } from './BillingDetailDetail';
import { BillingDetailList } from './BillingDetailList';
import { BillingDetailSearch } from './BillingDetailSearch';
import { BillingDetailHoc } from './BillingDetailHoc';

/**
 * 所要計画のコンポネート
 */
export function BillingDetail() {

  const billingDetail = BillingDetailHoc(BillingDetailDetail, BillingDetailList, BillingDetailSearch);

  return (
    <>
      {billingDetail}
    </>
  );
}
