import { api } from '../api'

const uri = '/api/fields/';

export interface Field {
  value: string;
  label: string;
}

export const getByField = (field: string, lang: string): Promise<Field[]> => {

  return api.get(`${uri}${field}/${lang}`)
    .then((data: any) => {

      return data as Field[];
    });
}
