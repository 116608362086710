// React APIs
import React from 'react';

// 機能内部のAPIs
import { AcceptanceDetail } from './AcceptanceDetail';
import { AcceptanceList } from './AcceptanceList';
import { AcceptanceSearch } from './AcceptanceSearch';
import { AcceptanceHoc } from './AcceptanceHoc';

/**
 * 所要計画のコンポネート
 */
export function Acceptance() {

  const acceptance = AcceptanceHoc(AcceptanceDetail, AcceptanceList, AcceptanceSearch);

  return (
    <>
      {acceptance}
    </>
  )

}
