import React, { useEffect, useRef } from 'react'
import { FormattedMessage, useIntl } from 'react-intl';
import { AutoComplete, Button, Card, Col, Drawer, Form, Input, InputNumber, Row, Select, Popconfirm } from 'antd';
import { useFields } from '../fields';
import { ModelDto } from './api'
import { dateUtil, displayUtil, filterUtil } from '../util';
import { Attachment, DatePicker, Detail, EditableDetail } from '../common'
import TextArea from 'rc-textarea';
import './index.css';
import { unitPriceCheck } from '../util/priceCheck';
import { CommonFileUpload } from '../common/CommonFileUpload';

export interface Props {
    visible: boolean;
    detail?: ModelDto;
    handleSubmit: (data: ModelDto) => void;
    handleDeleteInDetail: (id: number, version: number) => void;
    close: () => void;
}

export function ModelDetail(props: Props) {

    const intl = useIntl();
    const attachmentsRef = useRef<Attachment[]>([]);
    const [form] = Form.useForm<ModelDto>();

    const materialOptions = useFields('material');
    const managementOptions = useFields('management');
    const kindsOptions = useFields('kinds');
    const supplierOptions = useFields('fullSupplier');
    const itemOptions = useFields('item');
    const purchaseCurrencyOptions = useFields('currency').map(v => ({ label: v.label, value: v.label }));
    const assetFlowDifferentiationOption = useFields("assetFlowDifferentiation")


    if (!!props.detail) {
        form.setFieldsValue(props.detail);
    }
    
    useEffect(() => {
        attachmentsRef.current = props.detail?.attachments || []
    }, [props.detail?.attachments]);

    const columns: [JSX.Element, any][] = [
        // 資産番号
        [<FormattedMessage id='model.assetNo' />, props.detail?.assetNo],
        // 補助番号
        [<FormattedMessage id='model.auxiliaryNo' />, props.detail?.auxiliaryNo],
        // text
        [<FormattedMessage id='model.text' />, props.detail?.text],
        // 金型取得年月日
        [<FormattedMessage id='model.acquisitionDate' />, displayUtil.date(props.detail?.acquisitionDate)],
        // 原価センター
        [<FormattedMessage id='model.costCenter' />, props.detail?.costCenterNo],
        // 原価センター名
        [<FormattedMessage id='model.costCenterName' />, props.detail?.costCenterName],
    ]

    const inputRequiredMsg = (itemTitle: any) => <FormattedMessage id='message.inputRequired' values={{ title: itemTitle, }} />
    const selectRequiredMsg = (itemTitle: any) => <FormattedMessage id='message.selectRequired' values={{ title: itemTitle, }} />

    // 金型品番
    const moldNoTitle = <FormattedMessage id='model.moldNo' />;
    // 機種名
    const modelNameTitle = <FormattedMessage id='model.modelName' />;
    // 部品品番
    const itemTitle = <FormattedMessage id='model.item' />;
    // 部品品名
    const itemNameTitle = <FormattedMessage id='model.itemName' />;
    // 重量
    const weightTitle = <FormattedMessage id='model.weight' />;
    // 材質
    const materialTitle = <FormattedMessage id='model.material' />;
    // 管理
    const managementTitle = <FormattedMessage id='model.management' />;
    // 種別
    const kindsTitle = <FormattedMessage id='model.kinds' />;
    // 取数
    const moldCavityNumTitle = <FormattedMessage id='model.moldCavityNum' />;
    // ライフショット数
    const lifeShotsNumTitle = <FormattedMessage id='model.lifeShotsNum' />;
    // 面数
    const moldsNumTitle = <FormattedMessage id='model.moldsNum' />;
    // 一次預け先コード
    const primaryLenderTitle = <FormattedMessage id='model.primaryLender' />;
    // 一次預け先名称
    const primaryLenderNameTitle = <FormattedMessage id='model.primaryLenderName' />;
    // 一次預け先住所
    const primaryLenderAddrTitle = <FormattedMessage id='model.primaryLenderAddr' />;
    // 一次預け先の貸与日
    const primaryLeadDateTitle = <FormattedMessage id='model.primaryLeadDate' />;
    // 最終預け先コード
    const finalLenderTitle = <FormattedMessage id='model.finalLender' />;
    // 最終預け先名称
    const finalLenderNameTitle = <FormattedMessage id='model.finalLenderName' />;
    // 最終預け先住所
    const finalLenderAddrTitle = <FormattedMessage id='model.finalLenderAddr' />;
    // 決済NO
    const settlementNoTitle = <FormattedMessage id='model.settlementNo' />;
    // 仕入金額
    const purchaseAmountTitle = <FormattedMessage id='model.purchaseAmount' />;
    // 仕入通貨
    const purchaseCurrencyTitle = <FormattedMessage id='model.purchaseCurrency' />;
    // 発注番号
    const orderNoTitle = <FormattedMessage id='model.orderNo' />;
    // 償却開始日
    const depreciationStartDateTitle = <FormattedMessage id='model.depreciationStartDate' />;
    // 廃棄日
    const discardDateTitle = <FormattedMessage id='model.discardDate' />;
    // 固定資産登録決裁番号
    const fixedAssetRegistrationDecisionNumberTitle = <FormattedMessage id='model.fixedAssetRegistrationDecisionNumber' />;
    // 資産流動区分
    const assetFlowDifferentiationTitle = <FormattedMessage id='model.assetFlowDifferentiation' />;
    // 流動日付
    const flowDateTitle = <FormattedMessage id='model.flowDate' />;
    // 最終預け先住所コード
    const finalLenderAddrCodeTitle = <FormattedMessage id='model.finalLenderAddrCode' />;
    // 登録日
    const dataCreationDateTitle = <FormattedMessage id='common.dataCreationDate' />;
    // 更新日
    const userModifiedDateTitle =  <FormattedMessage id='common.userModifiedDate' />;
    // 更新者
    const modifiedUserNameTitle = <FormattedMessage id='common.userName' />;
    // 資産流動申請番号
    const assetFlowApplyCodesTitle = <FormattedMessage id='model.assetFlowApplyCode' />;
    // 備考
    const remarksTitle = <FormattedMessage id='model.remarks' />;
    // 添付ファイル
    const attachmentsFileTitle = <FormattedMessage id='docInfo.docFiles' />;
    // 削除
    const deleteTitle = <FormattedMessage id='common.delete' />;
    // 削除済
    const deletedTitle = <FormattedMessage id='common.deleted' />;

    const moldPropertiesColumns: any = [
        // 金型品番 (正则匹配全角字符)
        // eslint-disable-next-line no-control-regex
        [<Input />, moldNoTitle, "moldNo", [{ required: true, message: inputRequiredMsg(moldNoTitle) }, { max: 50, message: <FormattedMessage id='message.lengthFailure' values={{ length: 50 }} /> }]],
        // 機種名
        [<Input />, modelNameTitle, "modelName", [{ required: true, message: inputRequiredMsg(modelNameTitle) }, { max: 50, message: <FormattedMessage id='message.lengthFailure' values={{ length: 50 }} /> }]],
        // 管理
        [<Select style={{ fontWeight: 'normal' }} options={managementOptions} allowClear />, managementTitle, "management", [{ required: false, message: selectRequiredMsg(managementTitle) }]],
        // 種別
        [<Select style={{ fontWeight: 'normal' }} options={kindsOptions} allowClear />, kindsTitle, "kinds", [{ required: false, message: selectRequiredMsg(kindsTitle) }]],
        // 材質
        [<Select style={{ fontWeight: 'normal' }} options={materialOptions} allowClear />, materialTitle, "material", [{ required: false, message: selectRequiredMsg(materialTitle) }]],
        // 重量
        [<InputNumber type="number" stringMode style={{width: '100%'}} />, weightTitle, "weight", [{ required: false, message: inputRequiredMsg(weightTitle) }, {
            validator: (rule: any, value: any, callback: any) => {
                if(value !== null && value !== undefined && value !== '') {
                    if(value <= 0) {
                        const message = intl.formatMessage({ id: 'message.onlyPositiveNumber' })
                        if ( message !== '' ) {
                            callback(message)
                        } else {
                            callback()
                        }
                    } else {
                        const ValueArray = (value as string).split('.')
                        const numberValue = ValueArray[0]
                        const decimalValue = ValueArray[1]
                        
                        if(!!value && value.indexOf('e') > 0) {
                            callback(intl.formatMessage({ id: 'message.error' }))
                        } else {
                            if(!!numberValue && numberValue.length > 18) {
                                if(!!decimalValue && decimalValue.length > 6) {
                                    callback(`${intl.formatMessage({ id: 'message.IntegerPartLengthFailure' }, { length: '18' })}　${intl.formatMessage({ id: 'message.DigitRetainedUnitPrice' }, { a: '6' })}`)
                                } else {
                                    callback(intl.formatMessage({ id: 'message.IntegerPartLengthFailure' }, { length: '18' }))
                                }
                            } else {
                                if(!!decimalValue && decimalValue.length > 6) {
                                    callback(intl.formatMessage({ id: 'message.DigitRetainedUnitPrice' }, { a: '6' }))  
                                } else {
                                    callback()
                                }
                            }
                        }
                    }
                } else {
                    callback()
                }
            }
        }]],
        // 取数
        [<Input type="number" />, moldCavityNumTitle, "moldCavityNum", [{ required: true, message: inputRequiredMsg(moldCavityNumTitle) }, { pattern: /^[+]{0,1}(\d+)$/, message: <FormattedMessage id='message.onlyPositiveInteger' />, trigger: 'blur' }, {validator: (rule: any, value: number, callback: any) => { if(value <= 999999999){ callback() } else { callback( intl.formatMessage({ id: 'message.positiveIntegerError'}, { a: 9 })) }}}, ]],
        // ライフショット数
        [<Input type="number" />, lifeShotsNumTitle, "lifeShotsNum", [{ required: true, message: inputRequiredMsg(lifeShotsNumTitle) }, { pattern: /^[+]{0,1}(\d+)$/, message: <FormattedMessage id='message.onlyPositiveInteger' />, trigger: 'blur' }, {validator: (rule: any, value: number, callback: any) => { if(value <= 999999999){ callback() } else { callback( intl.formatMessage({ id: 'message.positiveIntegerError'}, { a: 9 })) }}}, ]],
        // 面数
        [<Input type="number" />, moldsNumTitle, "moldsNum", [{ required: true, message: inputRequiredMsg(moldsNumTitle) }, { pattern: /^[+]{0,1}(\d+)$/, message: <FormattedMessage id='message.onlyPositiveInteger' />, trigger: 'blur' }, {validator: (rule: any, value: number, callback: any) => { if(value <= 999999999){ callback() } else { callback( intl.formatMessage({ id: 'message.positiveIntegerError'}, { a: 9 })) }}}, ]],
    ];
    const otherColumns: any = [
        // 決済NO
        [<Input defaultValue={props.detail?.settlementNo} />, settlementNoTitle, "settlementNo", [{ required: false, message: inputRequiredMsg(settlementNoTitle) }, { max: 15, message: <FormattedMessage id='message.lengthFailure' values={{ length: 15 }} /> }]],
        // 固定資産登録決裁番号
        [<Input defaultValue={props.detail?.fixedAssetRegistrationDecisionNumber} />, fixedAssetRegistrationDecisionNumberTitle, "fixedAssetRegistrationDecisionNumber", [{ required: false, message: inputRequiredMsg(fixedAssetRegistrationDecisionNumberTitle) }, { max: 100, message: <FormattedMessage id='message.lengthFailure' values={{ length: 100 }} /> }]],
        // 仕入金額
        [<InputNumber defaultValue={props.detail?.purchaseAmount} stringMode type='number' style={{width: '100%'}} />, purchaseAmountTitle, "purchaseAmount", [{ required: false, message: inputRequiredMsg(purchaseAmountTitle) }, {
            validator: (rule: any, value: any, callback: any) => {
                if(value !== null && value !== undefined && value !== '') {
                    if(value < 0 ) {  // 判断输入值是否小于零
                        let message = intl.formatMessage({ id: 'message.noNegativeNumbers' }, { i: intl.formatMessage({ id: 'model.purchaseAmount' }) } )
                        if (message !== '') {
                        callback(message);
                        } else {
                        callback();
                        }
                    } else {
                        
                        const ValueArray = (value as string).split('.')
                        const numberValue = ValueArray[0]
                        
                        if(!!value && value.indexOf('e') > 0) {
                            callback(intl.formatMessage({ id: 'message.error' }))
                        } else {
                            let message = unitPriceCheck(intl, value, form.getFieldsValue().purchaseCurrency);  // 根据选择的货币判断小数点位数是否超出
                            if(!!numberValue && numberValue.length > 18) {
                                if (message !== '') {
                                    callback(`${intl.formatMessage({ id: 'message.IntegerPartLengthFailure' }, { length: '18' })}　${message}`);
                                } else {
                                    callback(intl.formatMessage({ id: 'message.IntegerPartLengthFailure' }, { length: '18' }))
                                }
                            } else {
                                if (message !== '') {
                                    callback(message);
                                } else {
                                    callback();
                                }
                            }
                        }
                    }
                } else {
                    if(form.getFieldsValue().purchaseCurrency !== null && form.getFieldsValue().purchaseCurrency !== undefined && form.getFieldsValue().purchaseCurrency !== '') {
                        const purchaseAmountTitleText = intl.formatMessage({id: 'model.purchaseAmount'})
                        const purchaseCurrencyTitleText = intl.formatMessage({id: 'model.purchaseCurrency'})
                        const message = intl.formatMessage({id: 'message.priceOrCurrencyNullError'}, {i: purchaseAmountTitleText, j: purchaseCurrencyTitleText})
                        if (message !== '') {
                            callback(message)
                        } else {
                            callback()
                        }
                    } else {
                        callback()
                    }
                }
            }
        }]],
        // 仕入通貨
        [<Select allowClear={true} options={purchaseCurrencyOptions} />, purchaseCurrencyTitle, "purchaseCurrency", [{ required: false, message: selectRequiredMsg(purchaseCurrencyTitle) }, {
            validator: (rule: any, value: any, callback: any) => {
                if(value !== null && value !== undefined && value !== '') {
                    callback()
                } else {
                    if(!!form.getFieldsValue().purchaseAmount) {
                        const purchaseCurrencyTitleText = intl.formatMessage({id: 'model.purchaseCurrency'})
                        const purchaseAmountTitleText = intl.formatMessage({id: 'model.purchaseAmount'})
                        const message = intl.formatMessage({id: 'message.priceOrCurrencyNullError'}, {i: purchaseCurrencyTitleText, j: purchaseAmountTitleText})
                        if (message !== '') {
                            callback(message)
                        } else {
                            callback()
                        }
                    } else {
                        callback()
                    }
                }
                
            }
        }]],
        // 注文番号
        [<Input defaultValue={props.detail?.orderNo} />, orderNoTitle, "orderNo", [{ required: false, message: inputRequiredMsg(orderNoTitle) }, 
            
            {validator: (rule: any, value: any, callback: any) => {
                // 只允许两种风格： 450000324100010, 4500003241-10, 没横线的时候最长 15 位，有横线的时候最长 16 位
                try {
                    const isNumber = /^[0-9]*$/.test(value) // 判断是否是纯数字
                    const isNumberAndLineInMiddle = /^[0-9]+-[0-9]+$/.test(value) // 判断是否是数字中间夹横线
                    const isNumberButStartWithLine = /^-[0-9]+$/.test(value) // 判断是否是纯数字但横线开头
                    const isNumberButEndWithLine = /^[0-9]+-$/.test(value) // 判断是否是纯数字但横线结尾
                    const tooManyLines = !!value.match(/-/g) ? value.match(/-/g).length > 1 : false // 判断是否有两个或两个以上的横线
                    
                    if (isNumber && value.length > 15) { // 纯数字但大于 15 位，报错
                        callback(intl.formatMessage({id: 'message.lengthFailure'}, {length: 15}))
                    } 

                    if (isNumberAndLineInMiddle && value.length > 16) { // 纯数字中间夹横线但大于 16 位，报错
                        callback(intl.formatMessage({id: 'message.lengthFailure'}, {length: 16}))
                    }

                    if (isNumberButStartWithLine) { // 纯数字但横线开头 (负数)
                        callback(intl.formatMessage({id: 'message.noNegativeNumbers'}, {i: intl.formatMessage({id: 'model.orderNo'})}))
                    }

                    if (isNumberButEndWithLine) { // 纯数字但横线结尾
                        callback(intl.formatMessage({id: 'message.notAllowLineAtEnd'}))
                    }

                    if (tooManyLines) { // 多个横线 (数字中间夹横线但横线开头 或 数字中间夹横线但横线结尾)
                        callback(intl.formatMessage({id: 'message.noMultipleLines'}))
                    }

                    if (!isNumber && !isNumberAndLineInMiddle) { // 出现其他字符，既不是纯数字，也不是数字中间夹横线
                        callback(intl.formatMessage({id: 'message.onlyNumberOrNumberAndLine'}))
                    }

                    callback()
                } catch {
                    callback()   
                }
            }}
        ]],
        // 償却開始日
        [<DatePicker defaultValue={props.detail?.depreciationStartDate} style={{ width: '100%' }} disabledDate={dateUtil.isOverDateLimit}/>, depreciationStartDateTitle, "depreciationStartDate", [{ required: false, message: inputRequiredMsg(depreciationStartDateTitle) }]],
        // 資産流動区分
        [<Select allowClear={true} options={assetFlowDifferentiationOption} />, assetFlowDifferentiationTitle, "assetFlowDifferentiation", [{ required: false, message: selectRequiredMsg(assetFlowDifferentiationTitle) }, {}]],
        // 流動日付
        [<DatePicker defaultValue={props.detail?.flowDate} style={{ width: '100%' }} disabledDate={dateUtil.isOverDateLimit}/>, flowDateTitle, "flowDate", [{ required: false, message: inputRequiredMsg(flowDateTitle) }]],
        // 資産流動申請番号
        [<Select mode="tags" open={false} style={{ fontWeight: 'normal' }} className="form-select" />, assetFlowApplyCodesTitle, "assetFlowApplyCodes", [{ required: false, message: selectRequiredMsg(itemTitle)}, {validator: (rule: any, values: string[], callback: any) => { if(!!values && values.length !== 0) { if( values.some(value => value.length > 100) ) { callback( intl.formatMessage({ id: 'message.lengthFailureSingleTAG' }, {length: 100}) ) } else { callback() } } else { callback() } }}]],
        // 登録日
        [<pre>{displayUtil.date(props.detail?.createdDate)}</pre>, dataCreationDateTitle, "createdDate", []],
        // 更新日
        [<pre>{displayUtil.date(props.detail?.modifiedDate)}</pre>, userModifiedDateTitle, "modifiedDate", []],
        // 更新者
        [<pre>{props.detail?.modifiedUser}</pre>, modifiedUserNameTitle, "modifiedUser", []],
        // 廃棄日
        [<DatePicker defaultValue={props.detail?.discardDate} style={{ width: '100%' }} disabledDate={dateUtil.isOverDateLimit}/>, discardDateTitle, "discardDate", [{ required: false, message: inputRequiredMsg(discardDateTitle) }]],
    ];
    if (props.detail?.primaryLeadDate) {
        if (props.detail.primaryLeadDate.unix() === 0) {
            props.detail.primaryLeadDate = undefined
        }
    }
    if (props.detail?.depreciationStartDate) {
        if (props.detail.depreciationStartDate.unix() === 0) {
            props.detail.depreciationStartDate = undefined
        }
    }
    if (props.detail?.discardDate) {
        if (props.detail.discardDate.unix() === 0) {
            props.detail.discardDate = undefined
        }
    }

    const perSubmit = (moldForm: ModelDto) => {
        moldForm.attachments = attachmentsRef.current;
        props.handleSubmit(moldForm)
    }

    const closeDrawer = () => {
        attachmentsRef.current = []
        props.close()
    }

    return (
        <Drawer
            title={<FormattedMessage id='model.detail' />}
            width='1380px'
            footer={null}
            visible={props.visible}
            bodyStyle={{ paddingBottom: 0, paddingTop: 0, }}
            destroyOnClose={true}
            onClose={closeDrawer}>
            <Card className='Card' bordered={false} >
                <Form
                    form={form}
                    name="advanced_search"
                    className="form"
                    onFinish={perSubmit}
                    colon={false}
                >
                    {/* 資産情報 */}
                    <Detail title={<FormattedMessage id='model.assets' />} cols={columns} />

                    {/* 金型属性情報 */}
                    <Card bordered={false} title={<FormattedMessage id='model.moldProperty' />} headStyle={{ display: 'flex' }}>
                        <EditableDetail cols={moldPropertiesColumns} className='formItem-style' />
                        <Row gutter={[16, 0]}>
                            {/* 部品品番 */}
                            <Col span={16} style={{transform: 'translateX(-4px)', paddingRight: '4px'}}>
                                <Form.Item
                                    labelCol={{ span: 5 }}
                                    wrapperCol={{ span: 19 }}
                                    className='formItem-style'
                                    label={itemTitle}
                                    name="items"
                                    rules={[{ required: false, message: selectRequiredMsg(itemTitle)}, {validator: (rule: any, values: string[], callback: any) => { if(!!values && values.length !== 0) { if( values.some(value => value.length > 40) ) { callback( intl.formatMessage({ id: 'message.lengthFailureSingleTAG' }, {length: 40}) ) } else { callback() } } else { callback() } }}]}
                                >
                                    <Select mode="tags" placeholder={props.detail?.items} style={{ fontWeight: 'normal' }} options={itemOptions} className="form-select" />
                                </Form.Item>
                            </Col>
                            {/* 部品品名 */}
                            <Col span={8} >
                                <Form.Item
                                    labelCol={{ span: 10 }}
                                    wrapperCol={{ span: 14 }}
                                    className='formItem-style'
                                    label={itemNameTitle}
                                    name="itemName"
                                    rules={[{ required: false, message: inputRequiredMsg(itemNameTitle) }, { max: 50, message: <FormattedMessage id='message.lengthFailure' values={{ length: 50 }} /> }]}
                                >
                                    <Input defaultValue={props.detail?.itemName} />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Card>

                    {/* 預け先情報  */}
                    <Card bordered={false} title={<FormattedMessage id='model.deposit' />} headStyle={{ display: 'flex' }}>
                        {/* <EditableDetail cols={depositColumns} /> */}
                        <Row gutter={[16, 0]}>
                            {/* 一次預け先コード */}
                            <Col span={10} >
                                <Form.Item
                                    labelCol={{ span: 8 }}
                                    wrapperCol={{ span: 16 }}
                                    className='formItem-style'
                                    label={primaryLenderTitle}
                                    name="primaryLender"
                                    rules={[{ required: true, message: inputRequiredMsg(primaryLenderTitle) }, {max: 10, message: <FormattedMessage id='message.lengthFailure' values={{ length: 10 }} /> }]}
                                >
                                    <AutoComplete 
                                        allowClear 
                                        style={{ fontWeight: 'normal' }} 
                                        options={supplierOptions} 
                                        showSearch={true} 
                                        filterOption={filterUtil.selectFilter}  //过滤下拉条件
                                    />
                                </Form.Item>
                            </Col>
                            {/* 一次預け先名称 */}
                            <Col span={14} >
                                <Form.Item
                                    labelCol={{ span: 5 }}
                                    wrapperCol={{ span: 19 }}
                                    className='formItem-style'
                                    label={primaryLenderNameTitle}
                                    name="primaryLenderName"
                                >
                                    <pre>{displayUtil.field(supplierOptions)(props.detail?.primaryLender)}</pre>
                                </Form.Item>
                            </Col>
                            {/* 一次預け先住所 */}
                            <Col span={10} >
                                <Form.Item
                                    labelCol={{ span: 8 }}
                                    wrapperCol={{ span: 16 }}
                                    className='formItem-style'
                                    label={primaryLenderAddrTitle}
                                    name="primaryLenderAddr"
                                    rules={[{ required: false, message: inputRequiredMsg(primaryLenderAddrTitle) }, { max: 50, message: <FormattedMessage id='message.lengthFailure' values={{ length: 50 }} /> }]}
                                >
                                    <Input defaultValue={props.detail?.primaryLenderAddr} />
                                </Form.Item>
                            </Col>
                            {/* 一次預け先の貸与日 */}
                            <Col span={14} >
                                <Form.Item
                                    labelCol={{ span: 5 }}
                                    wrapperCol={{ span: 19 }}
                                    className='formItem-style'
                                    label={primaryLeadDateTitle}
                                    name="primaryLeadDate"
                                    rules={[{ required: true, message: inputRequiredMsg(primaryLeadDateTitle) }]}
                                >
                                    <DatePicker allowClear defaultValue={props.detail?.primaryLeadDate} style={{ width: '100%' }} disabledDate={dateUtil.isOverDateLimit}/>
                                </Form.Item>
                            </Col>
                            {/* 最終預け先コード */}
                            <Col span={10} >
                                <Form.Item
                                    labelCol={{ span: 8 }}
                                    wrapperCol={{ span: 16 }}
                                    className='formItem-style'
                                    label={finalLenderTitle}
                                    name="finalLender"
                                    rules={[{ required: false, message: inputRequiredMsg(finalLenderTitle) }, { max: 20, message: <FormattedMessage id='message.lengthFailure' values={{ length: 20 }} /> }]}
                                >
                                    <Input defaultValue={props.detail?.finalLender} />
                                </Form.Item>
                            </Col>
                            {/* 最終預け先住所コード */}
                            <Col span={14} >
                                <Form.Item
                                    labelCol={{ span: 5 }}
                                    wrapperCol={{ span: 19 }}
                                    className='formItem-style'
                                    label={finalLenderAddrCodeTitle}
                                    name="finalLenderAddrCode"
                                    rules={[{ required: false, message: inputRequiredMsg(finalLenderAddrCodeTitle) }, { max: 20, message: <FormattedMessage id='message.lengthFailure' values={{ length: 20 }} /> }]}
                                >
                                    <Input defaultValue={props.detail?.finalLenderName} />
                                </Form.Item>
                            </Col>
                            {/* 最終預け先名称 */}
                            <Col span={10} >
                                <Form.Item
                                    labelCol={{ span: 8 }}
                                    wrapperCol={{ span: 16 }}
                                    className='formItem-style'
                                    label={finalLenderNameTitle}
                                    name="finalLenderName"
                                    rules={[{ required: false, message: inputRequiredMsg(finalLenderNameTitle) }, { max: 200, message: <FormattedMessage id='message.lengthFailure' values={{ length: 200 }} /> }]}
                                >
                                    <Input defaultValue={props.detail?.finalLenderName} />
                                </Form.Item>
                            </Col>
                            {/* 最終預け先住所 */}
                            <Col span={14} >
                                <Form.Item
                                    labelCol={{ span: 5 }}
                                    wrapperCol={{ span: 19 }}
                                    className='formItem-style'
                                    label={finalLenderAddrTitle}
                                    name="finalLenderAddr"
                                    rules={[{ required: false, message: inputRequiredMsg(finalLenderAddrTitle) }, { max: 200, message: <FormattedMessage id='message.lengthFailure' values={{ length: 200 }} /> }]}
                                >
                                    <Input defaultValue={props.detail?.finalLenderAddr} />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Card>

                    {/* その他 */}
                    <Card bordered={false} title={<FormattedMessage id='model.other' />}>
                        <EditableDetail cols={otherColumns} className='formItem-style' />
                        {/* 備考 */}
                        <Row gutter={[16, 0]}>
                            <Col span={16} style={{transform: 'translateX(-4px)', paddingRight: '4px'}}>
                                <Form.Item
                                    labelCol={{ span: 5 }}
                                    wrapperCol={{ span: 19 }}
                                    className='formItem-style'
                                    label={remarksTitle}
                                    name="remarks"
                                    rules={[{ required: false, max: 300, message: <FormattedMessage id='message.lengthFailure' values={{ length: 300 }} /> }]}
                                >
                                    <TextArea rows={3} defaultValue={props.detail?.remarks} autoSize={{ minRows: 3, maxRows: 3 }} className="form-textarea" style={{ width: '100%' }} />
                                </Form.Item>
                            </Col>
                        </Row>
                        {/* 添付ファイル */}
                        <Row style={{transform: 'translateX(-3px)'}}>
                            <Col span={3} style={{ textAlign: 'right', fontWeight: 'bold'}}>{attachmentsFileTitle}</Col>
                            <Col style={{ paddingLeft: '16px'}}>
                                <div className='reSize'>
                                    <Form.Item name='attachments' noStyle>
                                        <CommonFileUpload
                                            attachments={props.detail && props.detail.attachments ? props.detail.attachments : []}
                                            canUpload={props.detail?.deleteFlag === '0'}
                                            persist={(uuid: string) => {
                                                attachmentsRef.current = [...(attachmentsRef.current), { id: -1, uuid: uuid }]
                                            }}
                                            remove={(uuid: string) => {
                                                attachmentsRef.current = attachmentsRef.current.filter((attachment: Attachment) => attachment.uuid !== uuid)
                                            }}
                                        />
                                    </Form.Item>
                                </div>
                            </Col>
                        </Row> 
                    </Card>
                    
                    <Row style={{marginTop: '5px', transform: 'translateX(5px)'}}>
                        <Col span={3} style={{ textAlign: 'right', fontWeight: 'bold'}}>{deleteTitle}</Col>
                        <Col style={{ paddingLeft: '16px'}}>
                            <Popconfirm placement="top" title={<FormattedMessage id='message.delConfirm' />}
                                disabled={props.detail?.deleteFlag === '1'}
                                onConfirm={() => {
                                    if (props.detail?.id && props.detail?.version !== null && props.detail?.version !== undefined) {
                                        props.handleDeleteInDetail(props.detail?.id, props.detail?.version)
                                    }
                                }}
                                okText={<FormattedMessage id='common.delete' />}
                                cancelText={<FormattedMessage id='common.cancel' />} >
                                <Button disabled={props.detail?.deleteFlag === '1'}>
                                    {props.detail?.deleteFlag === '1' ? deletedTitle : deleteTitle}
                                </Button>
                            </Popconfirm>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={22} style={{ textAlign: 'right' }}>
                            <Button type="primary" disabled={props.detail?.deleteFlag === '1'} onClick={() => form.submit()}>
                                <FormattedMessage id='common.update' />
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </Card>
        </Drawer >
    )
}