import React from 'react';
import { message, } from 'antd';

import { LocaleMessage, } from '../common';

export const get = (uri: string) => {
  return fetchApi(uri, 'GET');
};

export const getById = (uri: string, id: number) => {
  return fetchApi(`${uri}${id}`, 'GET');
};

export const getByData = (uri: string, data: object) => {
  return fetchApi(uri, 'POST', data, true);
};

export const download = (uri: string, id: number) => {
  return fetchApi(`${uri}/${id}`, 'GET');
};

export const upload = (uri: string, data: object) => {
  return fetchApi(uri, 'UPLOAD', data);
};

export const persist = (uri: string, data: object) => {
  return fetchApi(uri, 'POST', data);
};

export const persistNoReturn = (uri: string, data: object,) => {
  return fetchApi(uri, 'POST', data, false, true);
};

export const merge = (uri: string, id: number, data: object) => {
  return fetchApi(`${uri}${id}`, 'PUT', data);
};

export const mergeWithoutMessage = (uri: string, id: number, data: object) => {
  return fetchApiWithoutMessage(`${uri}${id}`, 'PUT', data);
};

export const removeById = (uri: string, id: number, data: object) => {
  return fetchApi(`${uri}${id}`, 'DELETE', data);
};

export const removeByList = (uri: string, data: object) => {
  return fetchApi(uri, 'DELETE', data);
};

export const deleteFile = (uri: string, filename: String) => {
  return fetchApi(`${uri}${filename}`, 'DELETE', undefined, true, true);
};

export const deleteFile2 = (uri: string, filename: String) => {
  return fetchApi(`${uri}?filename=${filename}`, 'DELETE', undefined, true, true);
};


export const downloadPDF = (uri: string, id: number) => {
  return fetchApi(`${uri}/${id}`, 'GET', undefined, true, true);
};

export const downloadTemplate = (uri: string, language: string) => {
  return fetchApi(`${uri}/${language}`, 'GET', undefined, true, true);
};

export const send = (uri: string) => {
  return fetchApi(uri, 'POST', undefined, true, true);
};

const fetchApi = async (uri: string, method: 'GET' | 'POST' | 'PUT' | 'DELETE' | 'UPLOAD', data?: object, hideMessageFlag?: boolean, noReturnFlag?: boolean) => {

  return fetch(
    uri, {
    method: method === 'UPLOAD' ? 'POST' : method,
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'TimeZone': (new Date().getTimezoneOffset()).toString(),
    },
    credentials: "include" as RequestCredentials,
    body: !!data ? JSON.stringify(data) : null,
  })
    .then(res => {
      if (res.status === 200 || res.status === 201) {
        if (!hideMessageFlag) {
          infoSuccess(method);
        }
        if (!noReturnFlag && method !== 'DELETE' && method !== 'UPLOAD') {
          return res.json();
        }

        return !!res ? res : '';
      }
      // if (res.status === 401) {
      //   sessionStorage.removeItem('token');
      // }
      // else {
      infoFailure(method);
      // }

      throw res.status;
    })
    .catch(errHandler);
};

const fetchApiWithoutMessage = async (uri: string, method: 'GET' | 'POST' | 'PUT' | 'DELETE' | 'UPLOAD', data?: object, hideMessageFlag?: boolean, noReturnFlag?: boolean) => {

  return fetch(
    uri, {
    method: method === 'UPLOAD' ? 'POST' : method,
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'TimeZone': (new Date().getTimezoneOffset()).toString(),
    },
    credentials: "include" as RequestCredentials,
    body: !!data ? JSON.stringify(data) : null,
  })
    .then(res => {
      if (res.status === 200 || res.status === 201) {
        if (!noReturnFlag && method !== 'DELETE' && method !== 'UPLOAD') {
          return res.json();
        }
        return !!res ? res : '';
      }
      infoFailure(method);
      throw res.status;
    })
    .catch(errHandler);
};

const infoSuccess = (method: string) => {
  info(method, true);
};

const infoFailure = (method: string) => {
  info(method, false);
};

const info = (method: string, successFlag: boolean) => {

  if (method === 'GET') {
    return;
  }

  let action = 'update';

  if (method === 'POST') {
    action = 'add';
  } else if (method === 'DELETE') {
    action = 'delete';

  } else if (method === 'UPLOAD') {
    action = 'upload';
  }

  message.info(<LocaleMessage id={`message.${action}${successFlag ? 'Success' : 'Failure'}`} />);
};

const showErrorMessage = (status: number) => {

  message.error(<LocaleMessage id={`message.requestFailure${status}`} />);

};

const errHandler = (err: any) => {
  showErrorMessage(err);
  console.log(err);
  throw err;
};
