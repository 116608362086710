import React, { useState } from 'react';
import { FormattedMessage, useIntl, } from 'react-intl';

import { Button, Modal, } from 'antd';

import './index.css';

import { CommonExcelUpload, CommonExcelUploadProps, RowData } from '../common';
import { getById, SupplierEstimatesDetailDto, SupplierEstimatesDto } from './api';
import { Field, useFields } from '../fields';
import { toKey } from '../util/excel';

export interface Props {
  urlId?: string;
  visible: boolean;
  upload: (data: SupplierEstimatesDto) => void;
  close: () => void;
}

export function SupplierEstimatesAnswerUpload(props: Props) {

  // 共通のstates
  const intl = useIntl();
  const responseStatusOptions = useFields('responseStatus');

  const [dto, setDto] = useState<SupplierEstimatesDto | undefined>(undefined);

  const validate = async (rowDatas: RowData[]) => {

    if (!!props.urlId) {
      const currentDto = await getById(props.urlId);

      if (!currentDto) {
        throw [`先方依頼が存在していません。`];
      }

      const details: SupplierEstimatesDetailDto[] = [];

      const errors: string[] = [];

      const dtos = rowDatas.forEach(
        (rowData: RowData) => {

          const responseStatus = toKey(responseStatusOptions, rowData['responseStatus'] as string);
          if (!responseStatus) {
            errors.push(`品番：${rowData['item']}の回答状態が対象外となりました。`);
          }
          if (responseStatus != '003' && responseStatus != '004') {
            errors.push(`品番：${rowData['item']}の回答状態が003：採用または004：不採用になるはずです。`);
          }

          for (const detail of currentDto.details) {


            if (detail.item === rowData['item']) {
              details.push({ ...detail, responseStatus: responseStatus } as SupplierEstimatesDetailDto);
              return;
            }
          }
          errors.push(`品番：${rowData['item']}の先方依頼が存在していません。`);
        });
      if (errors.length > 0) {
        throw errors;
      }

      currentDto.details = details;

      return currentDto;
    } else {

      throw [`先方依頼が存在していません。`];
    }
  };

  const uploadProps: CommonExcelUploadProps = {
    row: {
      columns: [
        { key: 'estimate.item', field: 'item', type: 'string', isRequired: true, validators: [], },
        { key: 'estimate.responseStatus', field: 'responseStatus', type: 'string', isRequired: true, validators: [], },
      ],
      uniqueItems: ['item'],
    },
    validate: validate,
    upload: (data: SupplierEstimatesDto) => {
      setDto(data);
    },
  };

  const upload = () => {
    if (!!dto) {
      props.upload(dto);
      setDto(undefined);
      props.close();
    }
  };

  return (
    <Modal
      title={<FormattedMessage id='supplierEstimates.answerList' />}
      width='600px'
      footer={null}
      visible={props.visible}
      bodyStyle={{ paddingBottom: 80 }}
      destroyOnClose={true}
      onCancel={props.close}>
      <CommonExcelUpload {...uploadProps} />
      <div className='search-button'>
        <Button className='search-close-btn' onClick={props.close}>
          <FormattedMessage id='common.cancel' />
        </Button>
        <Button className='search-search-btn' type='primary' onClick={upload} disabled={!dto}>
          <FormattedMessage id='common.upload' />
        </Button>
      </div>
    </Modal >
  );
}


