import { Dayjs } from 'dayjs';
import { api, CommonListVo, converter, PagingSearchParaVo, SearchParaPropertis } from '../api'
import { DateRange } from '../common';
import { Field, getByField } from '../fields/api';
import { locales } from '../locales';

const dayjs = require('dayjs');

// アクセスURI
const uri = '/api/modelassets/';
const uriSuppliers = '/api/models/suppliers';

//*** １．検索画面用 ***

// ページ毎行数
const rowCount = 10;
// ソート順項目定義、repositoryと一致  to fix
const orderByColumns = [
    // 回答状況
    "status",
    // 有高確認依頼番号
    "inventoryRequestNo",
    // 一次預け先コード
    "primaryLender",
    // 依頼日
    "requestDate",
    // 回答希望日
    "answerDeadline",
];

// 検索条件Dtoの項目一覧
const properties: SearchParaPropertis = {
    // 回答状況
    status: 'array',
    // 有高確認依頼番号
    inventoryRequestNo: 'string',
    // 一次預け先コード
    primaryLenders: 'array',
    // 一次預け先名称
    primaryLenderName: 'string',
    // 依頼日
    requestDate: 'DateRange',
    // 回答希望日
    answerDeadline: 'DateRange',
};
//検索条件DTO
export interface InventorySearchDto extends PagingSearchParaVo {
    // 回答状況
    status: string[]
    // 有高確認依頼番号
    inventoryRequestNo?: string;
    // 一次預け先コード
    primaryLenders: string[];
    // 一次預け先名称
    primaryLenderName?: string;
    // 依頼日
    requestDate: DateRange;
    // 回答希望日
    answerDeadline: DateRange;
}
// searchから検索条件Dtoへ変換する
export const search2Dto = (query?: string): InventorySearchDto => {
    return converter.search2Dto(properties, orderByColumns, query) as InventorySearchDto;
};
// 検索条件Dtoからsearchへ変換する
export const dto2search = (researchDto: InventorySearchDto): string => {
    return converter.dto2search(properties, orderByColumns, researchDto);
};
//一覧データを取得する
export const get = (query: string): Promise<[number, InventoryDto[]]> => {
    if (!query || query.length === 0) {
        query = `?rowCount=${rowCount}`;
    } else {
        query += `&rowCount=${rowCount}`;
        console.log('query', query)
    }
    return api.get(`${uri}${query}`)
        .then((result: any) => {

            const data = result as CommonListVo<InventoryVo>;
            return [data.total, data.vos.map(vo2ListRecordDto)];
        });
};
//*** ２．一覧画面用 ***

//有高確認一覧VO/DTO
interface InventoryVo {
    // Id
    id: number;
    // 回答状況
    status: string;
    // 有高確認依頼番号
    inventoryRequestNo: string;
    // 一次預け先コード
    primaryLender: string;
    // 購買元コード
    buyer: string;
    // 依頼日
    requestDate: number;
    // 回答希望日
    answerDeadline: number;
    //更新ユーザーID
    userId1: string;
    // 更新ユーザー名称
    userName1: string;
    // 更新ユーザー名称
    modifiedTime: number;
    // 回答ユーザーID
    userId2: string;
    // 回答ユーザー名称
    userName2: string;
    // 回答日時
    responseTime: number;
    // 確認ユーザーID
    userId3: string;
    // 確認ユーザー名称
    userName3: string;
    // 確認日時
    confirmationTime: number;
    // バージョン
    version: number;
    // 金型数
    modelNum: number;
    // 未读フラグ
    unReadFlag: boolean;
    //明細一覧
    details?: InventoryDetailDto[]
}
//有高確認情報一覧Dto
export interface InventoryDto {
    // Id
    id: number;
    // 回答状況
    status: string;
    // 有高確認依頼番号
    inventoryRequestNo: string;
    // 一次預け先コード
    primaryLender: string;
    // 一次預け先名称
    primaryLenderName: string;
    // 購買元コード
    buyer: string;
    // 依頼日
    requestDate: Dayjs;
    // 回答希望日
    answerDeadline: Dayjs;
    // 更新ユーザーID
    userId1: string;
    // 更新ユーザー名称
    userName1: string;
    //更新時間
    modifiedTime: Dayjs;
    // 回答ユーザーID
    userId2: string;
    // 回答ユーザー名称
    userName2: string;
    // 回答日時
    responseTime: Dayjs;
    // 確認ユーザーID
    userId3: string;
    // 確認ユーザー名称
    userName3: string;
    // 確認日時
    confirmationTime: Dayjs;
    // バージョン
    version: number;
    // 金型数
    modelNum: number;
    // 未读フラグ
    unReadFlag: boolean;
    //金型有高確認詳細
    details?: InventoryDetailDto[]
}
//有高確認金型情報一覧Dto
export interface InventoryDetailDto {
    id: number;
    // 有高確認ID
    inventoryId: number;
    // 資産番号
    assetNo: string;
    // 補助番号
    auxiliaryNo: number;
    // 金型品番
    moldNo: string;
    // 最終預け先コード
    finalLender: string,
    // 最終預け先名称
    finalLenderName: string,
    // 面数
    moldsNum: number;
    // 実面数
    actualMoldsNum: number;
    // 特記事項
    remarks: string;
    // バージョン
    version: number;
    // detailVersion
    detailVersion: number;
}

const vo2ListRecordDto = (vo: InventoryVo): InventoryDto => {
    return {
        ...vo,
        modifiedTime: vo.modifiedTime ? dayjs(vo.modifiedTime) : null,
        responseTime: vo.responseTime ? dayjs(vo.responseTime) : null,
        requestDate: vo.requestDate ? dayjs(vo.requestDate) : null,
        answerDeadline: vo.answerDeadline ? dayjs(vo.answerDeadline) : null,
        confirmationTime: vo.confirmationTime ? dayjs(vo.confirmationTime) : null,
    } as InventoryDto;
};

const List2VoRecordDto = (dto: InventoryDto): InventoryVo => {
    return {
        ...dto,
        modifiedTime: Number(dto.modifiedTime),
        responseTime: Number(dto.responseTime),
        requestDate: Number(dto.requestDate),
        answerDeadline: Number(dto.answerDeadline),
        confirmationTime: Number(dto.confirmationTime),
    };
};
export const getById = (urlId: string): Promise<InventoryDto> => {
    return api.get(`${uri}/${urlId}`)
        .then((result: any) => {
            const data = result as InventoryVo;
            return vo2ListRecordDto(data);
        });
};
export const removeById = (id: number, version: number): Promise<any> => {

    return api.removeById(uri, id, { id: id, version: version });
};


//*** ３．有高確認登録画面 ***

//有高確認作成画面VO  
export interface ModelRequestVo {
    // 一次預け先
    primaryLenders: string[],
    // 回答希望日
    answerDeadline: number
}
//有高確認作成画面DTO  
export interface ModelRequestDto {
    // 一次預け先
    primaryLenders: string[],
    // 回答希望日
    answerDeadline: Dayjs|null 
}
//有高確認作成確認VO  
export interface ModelNumBySupplierVo {
    // 一次預け先名称
    primaryLender: string;
    // 金型数
    modelNum: number;
}

//有高確認作成確認Dto  
export interface ModelNumBySupplierDto {
    // 一次預け先名称
    primaryLender: string;
    // 金型数
    modelNum: number;
}
//有高確認依頼情報変換Dto⇒VO
const requestDto2Vo = (dto: ModelRequestDto): ModelRequestVo => {
    return {
        answerDeadline: !!dto.answerDeadline ? dto.answerDeadline.valueOf() : 0,
        primaryLenders: !!dto.primaryLenders ? dto.primaryLenders : [],
    };
};
//有高確認一覧確認情報変換Vo⇒Dto
const requestVo2Dto = (vo: ModelNumBySupplierVo): ModelNumBySupplierDto => {
    return {
        ...vo,
    } as ModelNumBySupplierDto;
}
//一次貸し出し先と金型数情報を取得する。
export const getModelNumBySupplier = (dto: ModelRequestDto): Promise<ModelNumBySupplierDto[]> => {
        
    const query = `?primaryLenders=${!!dto.primaryLenders ? dto.primaryLenders : []}&answerDeadline=${Number(dto.answerDeadline)}`;
    return api.get(`${uriSuppliers}${query}`)
        .then((result: any) => {
            const data = result as ModelNumBySupplierVo[];
            return data.map(requestVo2Dto);
        });
};
//有高確認作成（Insert処理）
export const persist = (dto: ModelRequestDto): Promise<ModelRequestDto>=> {

    return api.persist(uri, requestDto2Vo(dto))
    .then((result: any) => {
        const data = result as ModelRequestDto;
        return data;
    }); 
};
//有高確認実面数を保存する。
export const merge = (form: InventoryDetailDto): Promise<InventoryDetailDto> => {
    return api.mergeWithoutMessage(`${uri}${form.id}/detail/`,form.inventoryId, form)
        .then((result: any) => {
            const data = result as InventoryDetailDto;
            return data;
        });
};
//有高ステータス変更
export const mergeInventory = (form: InventoryDto): Promise<InventoryDto>  => {
    const inventoryVo=List2VoRecordDto(form);
    return api.merge(`${uri}/status/`,inventoryVo.id, inventoryVo)
        .then((result: any) => {
            const data = vo2ListRecordDto(result);
            return data;
        });
};


export const getSuppliers = async ():Promise<Field[]>=>{
    const lang = locales.getLang();
    return await getByField('mold', lang)
      .then((data: Field[]) => {
        return  data;
      });
   
}

export const reply = (dto: InventoryDto) => {
    const inventoryVo = List2VoRecordDto(dto);
    return api.merge(`${uri}detail/`,inventoryVo.id, inventoryVo).then((result: any) => {
        const data = result as InventoryVo;
        return vo2ListRecordDto(data);
    });
}
