import dayjs, { Dayjs } from "dayjs";
import { Field } from "../fields";
import { locales } from "../locales";

export const toDate = (text?: string): Dayjs | null => {

  if (!text || text.length === 0) {
    return null;
  }

  let isNum = /^\d+$/.test(text);

  if (isNum) {
    const numDate = dayjs('1900-01-01').add((parseInt(text) - 2), 'day');
    if (!numDate.isValid()) {
      return null;
    }
    return numDate;
  }


  const [dayjsLocale] = locales.getLocales();

  const textDate = dayjs(text, ['YYYY/M/D', 'YYYY/MM/DD', 'YYYY-M-D', 'YYYY-MM-DD', 'YYYY年MM月DD日', 'YYYY年M月D日', 'MM/DD/YYYY', 'M/D/YYYY', 'MMM/D/YYYY', 'MMMM/D/YYYY', 'MM/DD/YY', 'M/D/YY', 'MMM/D/YY', 'MMMM/D/YY'], dayjsLocale, true);

  if (!textDate.isValid()) {
    return null;
  }
  // TODO　一時修正
  // return textDate.add(1, 'day');
  return textDate;
}

export const toKey = (fields: Field[], key: string): string | undefined => {

  const valueMatchs = fields.filter(entry => entry.value === key);

  if (valueMatchs.length > 0) {
    return valueMatchs[0].value;
  }

  const keyMatchs = fields.filter(entry => entry.label === key);

  if (keyMatchs.length > 0) {
    return keyMatchs[0].value;
  }

  return undefined;
}