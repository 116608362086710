import React from 'react';
import './index.css';
import { Popover, Descriptions, Row, Col } from 'antd';
import { useUser } from '../authorization';
import { useIntl } from 'react-intl';
import { FormattedMessage, } from 'react-intl';
import { locales } from '../locales';


export function Guide() {


  const intl = useIntl();
  const user = useUser();

  const downloadIproChinaText = intl.formatMessage({ id: 'guide.downloadIproChinaText' });
  const downloadIproJapanText = intl.formatMessage({ id: 'guide.downloadIproJapanText' });
  const downloadSupplierChinaText = intl.formatMessage({ id: 'guide.downloadSupplierChinaText' });
  const downloadSupplierJapanText = intl.formatMessage({ id: 'guide.downloadSupplierJapanText' });

  const docNotReadyNow = <div><FormattedMessage id='guide.getBackLater' /></div>
  const docNotReadyNowTitle = intl.formatMessage({ id: 'guide.docNotReadyNow' })

  const supportTitle = intl.formatMessage({ id: 'guide.supportInfo' })

  const os = <FormattedMessage id='guide.os' />
  const osVersion = <FormattedMessage id='guide.osVersion' />

  const browser = <FormattedMessage id='guide.browser' />
  const browserName = <FormattedMessage id='guide.browserName' />

  const display = <FormattedMessage id='guide.display' />
  const displayPixel = <FormattedMessage id='guide.displayPixel' />

  const zoom = <FormattedMessage id='guide.zoomTitle' />
  const zoomSize = <FormattedMessage id='guide.zoom' />

  const language = <FormattedMessage id='guide.language' />
  const languageName = <FormattedMessage id='guide.languageName' />

  const chinese = <FormattedMessage id='guide.chinese' />
  const japanese = <FormattedMessage id='guide.Japanese' />

  const supplierOnly = <FormattedMessage id='guide.supplierOnly' /> 
  const iproOnly = <FormattedMessage id='guide.iproOnly' /> 

  const china = <FormattedMessage id='guide.china' />
  const telChina = <FormattedMessage id='guide.telChina' />

  const japan = <FormattedMessage id='guide.japan' />
  const telJapan = <FormattedMessage id='guide.telJapan' />

  const systemEnvironment = intl.formatMessage({ id: 'guide.environment' })
  const systemManual = intl.formatMessage({ id: 'guide.systemManual' })
  const tel = intl.formatMessage({ id: 'guide.tel' })
  const receptionTime = intl.formatMessage({ id: 'guide.receptionTime' })

  

  const systemEnvironmentTitle = <><span style={{ color: 'rgba(0, 0, 0, 0.85)', fontWeight: 'bold', fontSize: '20px' }}>{systemEnvironment}</span></>
  const systemManualTitle = <><span style={{ color: 'rgba(0, 0, 0, 0.85)', fontWeight: 'bold', fontSize: '20px' }}>{systemManual}</span></>
  const telTitle = <><span style={{ color: 'rgba(0, 0, 0, 0.85)', fontWeight: 'bold', fontSize: '20px' }}>{tel}</span></>
  const workdTime = <><span style={{ color: 'rgba(0, 0, 0, 0.5)', fontSize: '14px', marginLeft: '10px' }}>{receptionTime}</span></>

  const dataSynchronizationTime = intl.formatMessage({ id: 'guide.dataSynchronizationTime' })
  const dataSynchronizationTimeTitle = <><span style={{ color: 'rgba(0, 0, 0, 0.85)', fontWeight: 'bold', fontSize: '20px' }}>{dataSynchronizationTime}</span></>
  const dataSendTime = <FormattedMessage id='guide.dataSendTime' />
  const pipsSendTime = <FormattedMessage id='guide.pipsSendTime' />
  const psnsSendTime = <FormattedMessage id='guide.psnsSendTime' />
  const dataReceptionTime = <FormattedMessage id='guide.dataReceptionTime' />
  const pipsReceptionTime = <FormattedMessage id='guide.pipsReceptionTime' />
  const psnsReceptionTime = <FormattedMessage id='guide.psnsReceptionTime' />

  const currentLang = locales.getLang();  // 获取当前页面语言

  // 当页面语言为英语时，sider宽度为230， 否则是200
  const trueWidth = currentLang === 'en-US' ? 'calc(100vw - 280px)' : 'calc(100vw - 200px)'

  return (
    <>
      <div className='scrollBox' style={{width: trueWidth}}>
        <div className='guidBox' style={{width: trueWidth}}>
          <div className='guidItems'>
            <Row style={{ padding: '0px 0px 2vh 0px' }}>
              <div style={{ fontSize: '26px', fontWeight: 'bold', marginBottom: 'calc(5vh - 20px)' }}>
                {supportTitle}
              </div>
            </Row>

            <Row><Col span={24} style={{ height: '0px', borderBottom: '1px solid black' }}></Col></Row>

            <Row style={{ padding: '1.5vh 0px' }}>
              <Col span={7} >{systemEnvironmentTitle}</Col>
              <Col span={17} style={{ paddingTop: '3px' }}>
                <Row>
                  <Col span={8} style={{ fontSize: '16px' }}>{os}</Col>
                  <Col span={2} style={{ fontSize: '16px', textAlign: 'center' }}>:</Col>
                  <Col span={14} style={{ fontSize: '16px' }}>{osVersion}</Col>
                </Row>
                <Row>
                  <Col span={8} style={{ fontSize: '16px' }}>{display}</Col>
                  <Col span={2} style={{ fontSize: '16px', textAlign: 'center' }}>:</Col>
                  <Col span={14} style={{ fontSize: '16px' }}>{displayPixel}</Col>
                </Row>
                <Row>
                  <Col span={8} style={{ fontSize: '16px' }}>{zoom}</Col>
                  <Col span={2} style={{ fontSize: '16px', textAlign: 'center' }}>:</Col>
                  <Col span={14} style={{ fontSize: '16px' }}>{zoomSize}</Col>
                </Row>
                <Row>
                  <Col span={8} style={{ fontSize: '16px' }}>{browser}</Col>
                  <Col span={2} style={{ fontSize: '16px', textAlign: 'center' }}>:</Col>
                  <Col span={14} style={{ fontSize: '16px' }}>{browserName}</Col>
                </Row>
                <Row>
                  <Col span={8} style={{ fontSize: '16px' }}>{language}</Col>
                  <Col span={2} style={{ fontSize: '16px', textAlign: 'center' }}>:</Col>
                  <Col span={14} style={{ fontSize: '16px' }}>{languageName}</Col>
                </Row>
              </Col>
            </Row>

            <Row><Col span={24} style={{ height: '0px', borderBottom: '1px solid black' }}></Col></Row>

            <Row style={{ padding: '1.5vh 0px' }}>
              <Col span={7}>{systemManualTitle}</Col>
              <Col span={17} style={{ paddingTop: '3px' }}>
                <Row>
                  {user?.serviceState === 'jp' && 
                    <>
                      {user?.isSupplier && 
                        <>
                          <Col span={8} style={{ fontSize: '16px' }}>{supplierOnly}({japanese})</Col>
                          <Col span={2} style={{ fontSize: '16px', textAlign: 'center' }}>:</Col>
                          <Col style={{ fontSize: '16px' }}>
                              {/* <Popover content={docNotReadyNow} title={docNotReadyNowTitle}> */}
                                <a style={{ fontSize: '16px' }} href='/api/guide/supplierJapan/'>[{downloadSupplierJapanText}]</a>
                              {/* </Popover> */}
                          </Col>
                        </>
                      }
                      {!user?.isSupplier && 
                        <>
                          <Col span={8} style={{ fontSize: '16px' }}>{iproOnly}({japanese})</Col>
                          <Col span={2} style={{ fontSize: '16px', textAlign: 'center' }}>:</Col>
                          <Col style={{ fontSize: '16px' }}>
                            <a style={{ fontSize: '16px' }} href='/api/guide/iproJapan/'>[{downloadIproJapanText}]</a>
                          </Col>
                        </>
                      }
                    </>
                  }

                  {user?.serviceState === 'cn' && 
                    <>
                      {user?.isSupplier && 
                        <>
                          <Col span={8} style={{ fontSize: '16px' }}>{supplierOnly}({chinese})</Col>
                          <Col span={2} style={{ fontSize: '16px', textAlign: 'center' }}>:</Col>
                          <Col style={{ fontSize: '16px' }}>
                            <a style={{ fontSize: '16px' }} href='/api/guide/supplierChina/'>[{downloadSupplierChinaText}]</a>
                          </Col>
                        </>
                      }
                      {!user?.isSupplier && 
                        <>
                          <Col span={8} style={{ fontSize: '16px' }}>{iproOnly}({chinese})</Col>
                          <Col span={2} style={{ fontSize: '16px', textAlign: 'center' }}>:</Col>
                          <Col style={{ fontSize: '16px' }}>
                            <a style={{ fontSize: '16px' }} href='/api/guide/iproChina/'>[{downloadIproChinaText}]</a>
                          </Col>
                        </>
                      }
                    </>
                  }
                </Row>
              </Col>
            </Row>

            <Row><Col span={24} style={{ height: '0px', borderBottom: '1px solid black' }}></Col></Row>

            <Row style={{ padding: '1.5vh 0px' }}>
              <Col span={7}>{telTitle}</Col>
              <Col span={17} style={{ paddingTop: '3px' }}>
                {user?.serviceState === 'jp' && 
                  <>
                    <Row>
                      <Col span={8} style={{ fontSize: '16px' }}>王 睿婧</Col>
                      <Col span={2} style={{ fontSize: '16px', textAlign: 'center' }}>:</Col>
                      <Col style={{ fontSize: '16px' }}>ruijing.wang@i-pro.com</Col>
                    </Row>
                    <Row>
                      <Col span={8} style={{ fontSize: '16px' }}>石田 智之</Col>
                      <Col span={2} style={{ fontSize: '16px', textAlign: 'center' }}>:</Col>
                      <Col style={{ fontSize: '16px' }}>tomoyuki.ishida@i-pro.com</Col>
                    </Row>
                    <Row>
                      <Col span={8} style={{ fontSize: '16px' }}>堀 宏之</Col>
                      <Col span={2} style={{ fontSize: '16px', textAlign: 'center' }}>:</Col>
                      <Col style={{ fontSize: '16px' }}>hiroyuki.hori@i-pro.com</Col>
                    </Row>
                    <Row>
                      <Col span={8} style={{ fontSize: '16px' }}>小林 美夏</Col>
                      <Col span={2} style={{ fontSize: '16px', textAlign: 'center' }}>:</Col>
                      <Col style={{ fontSize: '16px' }}>mika.kobayashi@ce.i-pro.com</Col>
                    </Row>
                    <Row>
                      <Col span={8} style={{ fontSize: '16px' }}>{receptionTime}</Col>
                      <Col span={2} style={{ fontSize: '16px', textAlign: 'center' }}>:</Col>
                      <Col style={{ fontSize: '16px' }}>9:00-17:30 (JST)</Col>
                    </Row>
                  </>
                }

                {user?.serviceState === 'cn' && 
                  <>
                    <Row>
                      <Col span={8} style={{ fontSize: '16px' }}>XXXX</Col>
                      <Col span={2} style={{ fontSize: '16px', textAlign: 'center' }}>:</Col>
                      <Col span={7} style={{ fontSize: '16px' }}>XXXX@i-pro.com</Col>
                    </Row>
                    <Row>
                      <Col span={8} style={{ fontSize: '16px' }}>{receptionTime}</Col>
                      <Col span={2} style={{ fontSize: '16px', textAlign: 'center' }}>:</Col>
                      <Col span={7} style={{ fontSize: '16px' }}>8:15-17:00 (CST)</Col>
                    </Row>
                  </>
                }
              </Col>
            </Row>

            <Row><Col span={24} style={{ height: '0px', borderBottom: '1px solid black' }}></Col></Row>

            <Row style={{ padding: '1.5vh 0px' }}>
              <Col span={7} >{dataSynchronizationTimeTitle}</Col>
              <Col span={17} style={{ paddingTop: '3px' }}>
                <Row>
                  <Col span={8} style={{ fontSize: '16px' }}>{dataSendTime}</Col>
                  <Col span={2} style={{ fontSize: '16px', textAlign: 'center' }}>:</Col>
                  {user?.serviceState === 'jp' &&
                    <Col span={14} style={{ fontSize: '16px' }}>{pipsSendTime}</Col>
                  }
                  {user?.serviceState === 'cn' &&
                    <Col span={14} style={{ fontSize: '16px' }}>{psnsSendTime}</Col>
                  }
                </Row>
                <Row>
                  <Col span={8} style={{ fontSize: '16px' }}>{dataReceptionTime}</Col>
                  <Col span={2} style={{ fontSize: '16px', textAlign: 'center' }}>:</Col>
                  {user?.serviceState === 'jp' &&
                    <Col span={14} style={{ fontSize: '16px' }}>{pipsReceptionTime}</Col>
                  }
                  {user?.serviceState === 'cn' &&
                    <Col span={14} style={{ fontSize: '16px' }}>{psnsReceptionTime}</Col>
                  }
                </Row>
              </Col>
            </Row>

            <Row><Col span={24} style={{ height: '0px', borderBottom: '1px solid black' }}></Col></Row>
          </div>
        </div>
      </div>
    </>

  );
}