// React APIs
import React from 'react'

// 機能内部のAPIs
import { DeliveryDetail } from './DeliveryDetail'
import { DeliveryList } from './DeliveryList'
import { DeliverySearch } from './DeliverySearch'
import { DeliveryHoc } from './DeliveryHoc'

export function Deliery() {

    const delivery = DeliveryHoc( DeliveryList, DeliverySearch, DeliveryDetail);


    return (
        <>
            {delivery}
        </>
    )
}
