import { Button, Form, Modal, Select, Checkbox, Divider } from 'antd';
import React, { useState, useEffect } from 'react'
import { FormattedMessage, useIntl, } from 'react-intl';
import { DatePicker } from '../common';
import { Field } from '../fields';
import { dateUtil, filterUtil } from '../util';

import { getSuppliers, ModelRequestDto } from './api'

import './index.css';
export interface Props {
  visible: boolean;
  close: () => void;
  handleRequest: (requestDto: ModelRequestDto) => void

}

export function InventoryEntry(props: Props) {

  return (
    <Modal
      zIndex={500}
      width={600}
      title={<FormattedMessage tagName='h4' id='inventory.inventoryRequest' />}
      footer={null}
      visible={props.visible}
      closable={false}
      afterClose={props.close}
      destroyOnClose>
      {props.visible && <InventoryEntryForm {...props} />}
    </Modal>
  );
}

const InventoryEntryForm = (props: Props) => {

 const [primaryLenders, setPrimaryLenders]  = useState<Field[]>([]);
 

  const [form] = Form.useForm<ModelRequestDto>();
  const intl = useIntl();

  // 回答希望日
  const answerDeadlineTitle = <FormattedMessage id='inventory.answerDeadline' />
  // 一次預け先コード
  const primaryLendersTitle = <FormattedMessage id='model.primaryLender' />
  // 全て選択
  const selectAll = <FormattedMessage id='docInfo.selectAll' />;

  useEffect(() => {
    getSuppliers().then((data)=>{
      setPrimaryLenders(data); 
    })
    
    if (props.visible === false) {   // 如果窗口被设为不可见(用户点击关闭按钮)，那么300毫秒后异步清除表单里的数据、
      setSelectState(false)  // 将选择全部按钮设为未选中
      form.resetFields()  // 清除表单所有数据
    }
  }, [form, props.visible])

  //单选框状态
  const [selectState, setSelectState] = useState(false)

  return (
    <Form
      form={form}
      onFinish={props.handleRequest}
      layout={'horizontal'}
      colon={false}
      size={'large'}
      labelCol={{ span: 8 }}
      requiredMark={true}
      wrapperCol={{ span: 12 }}
      preserve={false}>
      <Form.Item name='answerDeadline' label={answerDeadlineTitle}
        rules={[
          {
            required: true,
            message: intl.formatMessage({ id: 'estimate.nullError' }, { name: intl.formatMessage({ id: 'inventory.answerDeadline' }) })
          },
        ]}
      >
        <DatePicker className='datePicker' disabledDate={dateUtil.isOverDateLimit}/>
      </Form.Item>

      <Form.Item name='primaryLenders' label={primaryLendersTitle}
        rules={[
          {
            required: true,
            message: intl.formatMessage({ id: 'estimate.nullError' }, { name: intl.formatMessage({ id: 'model.primaryLender' }) })
          },
        ]}
      >
        <Select<Field>
          mode={'multiple'}
          maxTagCount={5}  // 选择框中最大允许选项数量(不包括最后提示还有多少个的数字选项)
          allowClear={true}
          autoClearSearchValue={false}
          filterOption={filterUtil.selectFilter}
          onSearch={filterUtil.onSearchForm(form, 'primaryLenders', primaryLenders)}
          loading={primaryLenders.length === 0}
          options={primaryLenders}

          onChange={ //当被选中的项发生改变时调用此事件，如果选中的项目数小于总项目数，那么将全选checkbox改为未选中，否则改为选中
            value => {
              if (form.getFieldValue('primaryLenders').length < primaryLenders.length) { //用select选中的项的数量与全部选项的数量做对比
                setSelectState(false)
              } else {
                setSelectState(true)
              }
            }
          }
          getPopupContainer={  //设置下拉跟随父节点滚动
            triggerNode => {
              return triggerNode.parentNode || document.body;
            }
          }
          dropdownRender={allSelectValue => (  // 全选按钮 2021/11/29 by ZhiTong.Bian@ibm.com
            <div>
              <div style={{ padding: '4px 8px 8px 8px', cursor: 'pointer' }}>
                <Checkbox checked={selectState} onChange={(e) => {
                  // 判断 是否 选中
                  if (e.target.checked === true) {
                    setSelectState(true) //选中时 给 checked 改变状态
                    // 当选的时候 把所有列表值赋值给functionIds
                    form.setFieldsValue({
                      primaryLenders: primaryLenders.map((item) => item.value) //如果选中 给select 赋data里面所有的值
                    })
                  } else {
                    setSelectState(false)
                    form.setFieldsValue({
                      primaryLenders: [] //如果取消全选 这清空select全部选中状态
                    })
                  }
                }}>{selectAll}</Checkbox>
              </div>
              <Divider style={{ margin: '0' }} />
              {/* Option 标签值 */}
              {allSelectValue}
            </div>
          )}
        />
      </Form.Item>

      <Form.Item className="inventory-form">
        <div className='search-button'>
          <Button className='search-close-btn' onClick={props.close}>
            <FormattedMessage id='common.close' />
          </Button>
          <Button className='search-search-btn' htmlType='submit'>
            <FormattedMessage id='common.next' />
          </Button>
        </div>
      </Form.Item>
    </Form>
  );
};
