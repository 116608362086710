const zhCN = {

  'message.reapplyConfirm' : '是否回复？',
  'message.approveConfirm' : '是否通过？',
  'message.refuseConfirm' : '是否拒绝？',
  'message.inputRequired' : '请输入{title}。',
  'message.selectRequired' : '请选择{title}。',
  'message.addSuccess' : '添加成功',
  'message.updateSuccess' : '更新成功',
  'message.deleteSuccess' : '删除成功',
  'message.uploadSuccess' : '上传成功。',
  'message.addFailure' : '添加失败。',
  'message.updateFailure' : '保存失败。',
  'message.deleteFailure' : '删除失败。',
  'message.uploadFailure' : '上传失败。',
  'message.requestFailure400' : '传送变量有误。',
  'message.requestFailure401' : '请进行认证。',
  'message.requestFailure404' : '服务器没有响应。',
  'message.requestFailure408' : '已经超时。',
  'message.requestFailure409' : '已经被其他用户修改。',
  'message.requestFailure500' : '发生服务器错误。',
  'message.requestFailure504' : '发生超时。',
  'message.delConfirm' : '是否删除？',
  'message.editConfirm' : '是否更新？',
  'message.replyConfirm' : '是否回复？',
  'message.addConfirm' : '是否要追加？',
  'message.sendConfirm' : '是否要发送？',
  'message.finishConfirm' : '是否要结束？',
  'message.cancelConfirm' : '是否要取消？',
  'message.cancelConfirm2' : '是否取消？',
  'message.checkGoodsIssue' : '请输入出库编号。',
  'message.checkLocation' : '“交付地点、到货预定日”相同的数据才可在一个批次出货。请确认选择的数据是否相同？',
  'message.currencyNull' : '货币单位必须为空',
  'message.deliveryNoUnique' : '交付单号信息已存在, 请重新输入',
  'message.readAllConfirm' : '是否要把所有数据设为已读？',
  'message.cancel2Confirm' : '是否取消？',
  'message.noForecastError' : '贵公司没有本周所需计划数据。',

  'estimate.checkSelect' : '请选择计划出库的信息。',

  'orderResponse.shortageMessage' : '出库少于回复数量。',
  'orderResponse.shortageMessage2' : '出库数量多于回复数量。',

  'message.integerError' : '只能输入{a}位以下的整数。',
  'message.positiveIntegerError' : '只能输入{a}位以下的正整数。',
  'message.excelSPQError' : '第{i}行的SPQ必须是6位以下的正整数',
  'message.excelMPQError' : '第{i}行的MPQ必须是6位以下的正整数',
  'message.excelPostiveNumberError' : '第{i}行的{key}必须是正数。',
  'message.excelNegativeNumberError' : '第{i}行的{key}禁止为负数',
  'message.excelLTPositiveNumberError' : '第{i}行的{key}必须是正数且禁止大于2147483647',
  'message.excelAssetNo' : '第{i}行的{key}只能小于15位。',
  'message.excelAuxiliaryNo' : '第{i}行的{key}只能是0到99之间的整数。',
  'message.excelCostCenterNo' : '第{i}行的{key}只能小于10位。',
  'message.excelEstimateStartDateError' : '第{i}行的{key}是过去的日期。',

  'estimate.answerDeadlineError' : '{b}应小于{a}。',
  'estimate.maxError' : '{a}必须小于{b}位。',
  'estimate.nullError' : '{name}不能为空。',
  'estimate.zeroError' : '{name}不能为零。',
  'estimate.priceError' : '{a}的小数点保留两位或两位以下。',

  'shipment' : '交付',
  'shipment.search' : '查询',
  'shipment.orderNo' : '订单单号',
  'shipment.itemCode' : '部品编号',
  'shipment.deliveryDate' : '交付日',

  'forecast' : '所需计划管理',
  'forecast.search' : '查询',
  'forecast.list' : '所需计划一览',
  'forecast.planNo' : '所需量编号',
  'forecast.detailNo' : '明细号',
  'forecast.planningDate' : 'MRP处理日',
  'forecast.buyer' : '采购商',
  'forecast.buyerName' : '采购商名称',
  'forecast.supplier' : '供应商',
  'forecast.supplierName' : '供应商名称',
  'forecast.item' : '部品编号',
  'forecast.itemName' : '部品名称',
  'forecast.iniDeliveryMonth' : '预定交付月',
  'forecast.iniDeliveryDate' : '预定交付日',
  'forecast.quantity' : '数量',
  'forecast.unit' : '单位',
  'forecast.purchasingManager' : '采购员',
  'forecast.now' : '最新数据',

  'pay' : '支付管理',

  'pays' : '支付通知',

  'pay.list' : '支付列表',
  'pay.detail' : '支付信息详细',
  'pay.payment' : '支付金额',
  'pay.contracor' : '采购商信息',
  'pay.order' : '供应商信息',
  'pay.taxRate' : '税率',
  'pay.items' : '项目',
  'pay.id' : 'id',
  'pay.dataCreationDate' : '创建日',
  'pay.paymentId' : '支付单号',
  'pay.acquisitionDate' : '交易年月',
  'pay.supplierCode' : '供应商代码',
  'pay.paymentDate' : '支付日',
  'pay.reissueFlag' : '再发行区分',
  'pay.zipCode' : '邮编',
  'pay.supplierAddress' : '供应商地址',
  'pay.supplierName' : '供应商名称',
  'pay.fax' : 'FAX',
  'pay.dateOfIssue' : '发行日',
  'pay.buyer' : '采购商',
  'pay.buyerName' : '采购商名称',
  'pay.departmentName' : '采购部门名称',
  'pay.qualifiedInvoiceIssuerRegistrationNumber' : '合格账单发行事业者登录番号',
  'pay.currencyCode' : '货币',
  'pay.totalPaymentAmount' : '支付合计',
  'pay.lastMonthPayAdjustAmount' : '上个月支付调整金额',
  'pay.purchasePrice' : '采购金额',
  'pay.accountsReceivableOffset' : '应收账款抵消',
  'pay.otherOffset' : '其他抵消',
  'pay.payAdjustAmount' : '支付调整额',
  'pay.balanceAmountPay' : '扣除金额',
  'pay.invoiceNo' : '发票编号',
  'pay.excludingTax' : '不含税金额',
  'pay.consumptionTax' : '消费税',
  'pay.totalExcludingTax' : '不含税合计金额',
  'pay.readFlag' : '收件状态',
  'pay.paymentUponEachUse' : '按量付费',

  'billingDetail' : '支付明细',
  'billingDetail.list' : '支付明细一览',
  'billingDetail.companyId' : '公司 CODE',
  'billingDetail.acquisitionDate' : '交易年月',
  'billingDetail.supplierCode' : '供应商代码',
  'billingDetail.supplierName' : '供应商名称',
  'billingDetail.transcriptionDate' : '发布日期',
  'billingDetail.deliverySlipNumber' : '送货传票号码',
  'billingDetail.purchaseOrderNo' : '采购订单NO.',
  'billingDetail.purchaseDetailNo' : '采购订单传票明细',
  'billingDetail.itemCode' : '项目代号',
  'billingDetail.itemText' : '项目文本',
  'billingDetail.incorporationQuantity' : '购买数量',
  'billingDetail.unit' : '单位',
  'billingDetail.purchaseUnitPrice' : '购买单价',
  'billingDetail.purchaseAmount' : '购买金额',
  'billingDetail.currencyCode' : '货币',
  'billingDetail.purchaseTax' : '税率',
  'billingDetail.totalPurchaseAmount' : '购买金额合计：',
  'billingDetail.year' : '年',
  'billingDetail.month' : '月',
  'billingDetail.detail' : '明细',
  'billingDetail.totalPurchaseAmountGetFailed' : '购买金额合计数据获取失败。',

  'acceptance' : '入库管理',
  'acceptance.list' : '入库列表',
  'acceptance.detail' : '入库詳細信息',
  'acceptance.orderInfo' : '订单信息',
  'acceptance.orderNo' : '订单单号',
  'acceptance.orderDetailNo' : '入库明细号',
  'acceptance.detailNo' : '订单明细号',
  'acceptance.orderDate' : '发行日',
  'acceptance.purchasingManager' : '采购员',
  'acceptance.iniDeliveryDate' : '预定交付日',
  'acceptance.productionPlanDate' : '预定交付日',
  'acceptance.item' : '部品编号',
  'acceptance.itemName' : '部品名称',
  'acceptance.unit' : '单位',
  'acceptance.orderUnit' : '订单单位',
  'acceptance.buyer' : '采购商',
  'acceptance.buyerName' : '采购商名称',
  'acceptance.supplier' : '供应商',
  'acceptance.supplierName' : '供应商名称',
  'acceptance.currencyCode' : '货币',
  'acceptance.acceptanceInfo' : '入库信息',
  'acceptance.acceptanceNo' : '入库单号',
  'acceptance.acceptanceDetailNo' : '入库明细号',
  'acceptance.acceptanceDate' : '入库日',
  'acceptance.moveType' : '移动区分',
  'acceptance.acceptanceUnitPrice' : '入库单价',
  'acceptance.acceptanceQuantity' : '入库数量',
  'acceptance.acceptanceQuantityUnit' : '入库数量单位',
  'acceptance.acceptanceAmount' : '入库金额',
  'acceptance.orderQuantity' : '订单数量',
  'acceptance.orderAmount' : '订单金额',
  'acceptance.orderUnitPrice' : '订单单价',
  'acceptance.locationCd' : '存储地点编码',
  'acceptance.locationName' : '存储地点名称',
  'acceptance.deliveryNo' : '交付单号',
  'acceptance.deliveryDetailNo' : '交付明细号',
  'acceptance.search' : '查询',
  'acceptance.buyerInfo' : '采购商信息',
  'acceptance.supplierInfo' : '供应商信息',
  'acceptance.buyerDepartmentName' : '采购部门',

  'delivery.modalTitle' : '出货',
  'delivery' : '交付管理',
  'delivery.detail' : '交付详细列表',
  'delivery.list' : '交付单列表',
  'delivery.userModifiedDate' : '更新日',
  'delivery.dataCreationDate' : '创建日',
  'delivery.deliveryNo' : '交付单号',
  'delivery.buyer' : '采购商',
  'delivery.buyerName' : '采购商名称',
  'delivery.supplier' : '供应商',
  'delivery.supplierName' : '供应商名称',
  'delivery.userName' : '创建者',
  'delivery.estimatedTimeArriva' : '到货预定日',
  'delivery.item' : '部品编号',
  'delivery.itemName' : '部品名称',
  'delivery.quantity' : '数量',
  'delivery.orderNo' : '订单单号',
  'delivery.detailNo' : '订单明细号',
  'delivery.search' : '查询',
  'delivery.unprocessed' : '出货处理',
  'delivery.unprocessedlist' : '待出货部品一览',
  'delivery.output' : '出　货',
  'delivery.deliveryStatus' : '采购flag',
  'delivery.locationCd' : '交付地址',
  'delivery.unitPrice' : '单价',
  'delivery.re-output' : '交付单打印',
  'delivery.currencyCode' : '货币',
  'delivery.eiaj' : 'EIAJ交付单',
  'delivery.dnList' : '交付单一览',
  'delivery.reportType' : '交付单种类',
  'delivery.downloadSelected' : '下载交付单',
  'delivery.selectErrorTitle' : '选择错误提示',
  'delivery.noSelectBody' : '请选择想要下载的交付单',
  'delivery.selectTooManyBody' : '最多一个批次可选择下载20条记录。',

  'orderDetail.orderInfo' : '订单信息',
  'orderDetail.orderNo' : '订单单号',
  'orderDetail.detailNo' : '订单明细号',
  'orderDetail.item' : '部品编号',
  'orderDetail.itemName' : '部品名称',
  'orderDetail.orderDate' : '发行日',
  'orderDetail.buyer' : '采购商',
  'orderDetail.buyerName' : '采购商名称',
  'orderDetail.supplier' : '供应商',
  'orderDetail.supplierName' : '供应商名称',
  'orderDetail.unitPrice' : '单价',
  'orderDetail.unit' : '单位',
  'orderDetail.quantity' : '订单数量',
  'orderDetail.amount' : '订单金额',
  'orderDetail.purchasingManager' : '采购员',
  'orderDetail.iniDeliveryDate' : '预定交付日',
  'orderDetail.productionPlanDate' : '再计划日',
  'orderDetail.currencyCode' : '货币',
  'orderDetail.locationCd' : '存储地点',
  'orderDetail.locationCode' : '存储地点编码',
  'orderDetail.infoType' : '信息种类',
  'orderDetail.orderFinishFlag' : '纳入完成标识',
  'orderDetail.remark' : '备注',
  'orderDetail.acceptanceQuantity' : '入库数量',
  'orderDetail.balanceQuantity' : '剩余订单量',
  'orderDetail.deliveryBalanceQuantity' : '剩余出货量',
  'orderDetail.deliveryQuantity' : '出货数量',
  'orderDetail.balanceAmount' : '剩余订单额',
  'orderDetail.deliveryBalanceAmount' : '剩余出货额',
  'orderDetail.deliveryFlag' : 'VMI区分',
  'orderDetail.qualityCheckFlag' : '品检',
  'orderResponse' : '交付回复',
  'orderResponse.devInstrucstionDate' : '交付指示日',
  'orderResponse.instrucstionQuantity' : '交付指示数量',
  'orderResponse.instruction' : '交付指示',
  'orderResponse.list' : '订单列表',
  'orderResponse.detail' : '订单明細',
  'orderResponse.orderResponseList' : '交付回复列表',
  'orderResponse.instructionList' : '交付指示信息',
  'orderResponse.deliveryInfoList' : '出货信息',
  'orderResponse.deliveryPlanDate' : '回复交付日',
  'orderResponse.quantity' : '数量',
  'orderResponse.responseQuantity' : '回复数量',
  'orderResponse.planDeliveryQuantity' : '计划出货数量',
  'orderResponse.deliveryInfo' : '出货信息',
  'orderResponse.detailNo' : '明细号',
  'orderResponse.estimatedTimeArriva' : '到货预定日',
  'orderResponse.addDeliveryInfo' : '追加交付信息',
  'orderResponse.uploadDeliveryInstruction' : '上传交付指示',
  'orderResponse.uploadOrderResponses' : '上传交付回复',
  'orderResponse.uploadDeliveryInfos' : '上传出货信息',
  'orderResponse.delivery' : '出货',
  'orderResponse.response' : '接受指示',
  'orderResponse.preDeliveryPlanDate' : '回复交付日（更新前）',
  'orderResponse.preQuantity' : '数量（更新前）',
  'orderResponse.currDeliveryPlanDate' : '回复交付日（更新后）',
  'orderResponse.currQuantity' : '数量（更新后）',
  'orderResponse.preEstimatedTimeArriva' : '到货预定日（更新前）',
  'orderResponse.currEstimatedTimeArriva' : '到货预定日（更新后）',
  'orderResponse.preShipDate' : '出货日（更新前）',
  'orderResponse.currShipDate' : '订单种类',
  'orderResponse.preRemarks' : '备注（更新前）',
  'orderResponse.currRemarks' : '备注（更新后）',
  'orderResponse.shipDate' : '出货日',
  'orderResponse.remarks' : '备注',
  'orderResponse.duplicate' : '日期不能重复',
  'orderResponse.over' : '总数已超上限',
  'orderResponse.responseDeleteFlag' : '回复删除标识',
  'orderResponse.deliveryDeleteFlag' : '出库删除标识',
  'orderResponse.downloadDevInstrucstionInfos' : '下载交付指示信息',

  'devInstrucstion.orderDetailKey' : 'PO_NUMPO_LINE',
  'devInstrucstion.devInstrucstionDate' : '送货日期',
  'devInstrucstion.instrucstionQuantity' : '送货数',

  'notification' : '消息通知',

  'subnotification' : '系统消息',

  'notification.id' : 'id',
  'notification.incomingDateAndTime' : '收件时间',
  'notification.senderCode' : '发件编号',
  'notification.sender' : '发件人',
  'notification.recipientCode' : '收件人编号',
  'notification.subject' : '标题',
  'notification.text' : '正文',
  'notification.list' : '通知信息列表',

  'estimate' : '询价管理',
  'estimatesSub' : '通常询价',

  'supplierEstimates' : '来自供应商报价',
  'supplierEstimates.supplier' : '本公司提案报价',
  'supplierEstimates.list' : '报价一覧',
  'supplierEstimates.supplierList' : '本公司提案报价一览',
  'supplierEstimates.requestList' : '上传本公司提案报价',
  'supplierEstimates.upload' : '数据上传',
  'supplierEstimates.detail' : '报价详细信息',
  'supplierEstimates.supplierDetail' : '本公司请求详细画面',
  'supplierEstimates.detailList' : '报价部品一览',
  'supplierEstimates.supplierDetailList' : '本社报价部品一览',
  'supplierEstimates.templateDownload' : '模板下载 ',

  'estimate.list' : '询价请求一览',
  'estimate.requestEdit' : '编辑询价请求',
  'estimate.requestClose' : '关闭询价请求',
  'estimate.cancel' : '取消询价请求',
  'estimate.id' : 'id',
  'estimate.status' : '案件状态',
  'estimate.requestNo' : '询价单号',
  'estimate.buyer' : '采购商',
  'estimate.buyerName' : '采购商名称',
  'estimate.estimatesType' : '依頼类型',
  'estimate.buyerDepartment' : '采购部门',
  'estimate.purchasingManager' : '采购员',
  'estimate.requestDate' : '请求日',
  'estimate.answerDeadline' : '回复截止日',
  'estimate.item' : '部品编号',
  'estimate.itemName' : '部品名称',
  'estimate.planningMonths' : '预算月数',
  'estimate.planQuantity' : '预算数量',
  'estimate.monthlyPlanOrderQuantity' : '月计划订单数',
  'estimate.desireUnitPrice' : '期望单价',
  'estimate.desireCurrency' : '期望货币',
  'estimate.deliveryLocation' : '存储地点',
  'estimate.responseInfo' : '报价信息',
  'estimate.responseStatus' : '回复状态',
  'estimate.supplier' : '供应商',
  'estimate.supplierName' : '供应商名称',
  'estimate.estimatedEffectiveDate' : '报价有效期限',
  'estimate.leadTime' : 'L/T',
  'estimate.originalCountryName' : '生产国',
  'estimate.makerName' : '厂家名称',
  'estimate.makerItem' : '厂家部品编号',
  'estimate.itemTypeCode' : '部品种类',
  'estimate.moq' : 'SPQ',
  'estimate.mpq' : 'MPQ',
  'estimate.unitPrice' : '单价',
  'estimate.unitCurrency' : '货币',
  'estimate.moldCost' : '摸具费用',
  'estimate.moldCurrency' : '摸具费货币',
  'estimate.moldType' : '摸具种类',
  'estimate.moldProcessNum' : '摸具工序数',
  'estimate.moldNum' : '摸具面数',
  'estimate.jigCost' : '治具费',
  'estimate.jigCurrency' : '治具费货币',
  'estimate.repairsCost' : '修理费',
  'estimate.repairsCurrency' : '修理费货币',
  'estimate.editionFee' : '版费',
  'estimate.editionCurrency' : '版费货币',
  'estimate.contractConditionsCode' : '合同条件',
  'estimate.estimateStartDate' : '报价适用开始日',
  'estimate.estimateEndDate' : '报价适用结束日',
  'estimate.prevLocation' : '制造地点（前工程）',
  'estimate.nextLocation' : '制造地点（后工程）',
  'estimate.newEstimate' : '新建询价请求',
  'estimate.requestDetail' : '询价请求明细',
  'estimate.estimateContent' : '询价请求信息',
  'estimate.responseContent' : '报价信息',
  'estimate.requestInfo' : '询价请求信息',
  'estimate.reponseDetail' : '报价明细',
  'estimate.reponseInfo' : '报价信息',
  'estimate.reponseList' : '报价一览',
  'estimate.editRequest' : '编辑询价请求',
  'estimate.currency' : '单价货币',
  'estimate.itemInfo' : '部品信息',
  'estimate.moldInfo' : '摸具信息',
  'estimate.cost' : '费用',
  'estimate.processNum' : '工程数',
  'estimate.mum' : '面数',
  'estimate.type' : '种类',
  'estimate.quantity' : '数量',
  'estimate.responseCurrencyCode' : '货币',
  'estimate.requestCount' : '请求件数',
  'estimate.responseCount' : '回复件数',
  'estimate.remarks' : '备注',
  'estimate.monthUnit' : '个月',
  'estimate.quantityUnit' : 'PC',
  'estimate.bidding' : '投标',
  'estimate.decline' : '弃标',
  'estimate.userName' : '操作者',
  'estimate.userID' : '操作者ID',
  'estimate.modifiedTime' : '更新日',
  'estimate.upload' : '附件',
  'estimate.reapply' : '再报价请求',
  'estimate.approve' : '通过',
  'estimate.refuse' : '未通过',
  'estimate.makerInfo' : '厂家信息',
  'estimate.makerName2' : '厂家名称',
  'estimate.makerItem2' : '部品编号',
  'estimate.itemTypeCode2' : '种类',
  'estimate.moldsNum' : '面数',
  'estimate.responseUser' : '回复者',
  'estimate.responseTime' : '回复时间',

  'modelManagement' : '模具管理',
  'model' : '新增模具',
  'model.detail' : '模具编辑',
  'model.list' : '模具一览',
  'model.assetNo' : '资产编号',
  'model.auxiliaryNo' : '辅助编号',
  'model.moldNo' : '模具部品编号',
  'model.modelName' : '型号名称',
  'model.item' : '部品编号',
  'model.itemName' : '部品名称',
  'model.material' : '材质',
  'model.management' : '管理',
  'model.kinds' : '种类',
  'model.primaryLender' : '一次保管商',
  'model.primaryLenderName' : '一次保管商名称',
  'model.primaryLenderAddr' : '一次保管商地址',
  'model.finalLender' : '最终保管商',
  'model.finalLenderName' : '最终保管商名称',
  'model.finalLenderAddr' : '最终保管商地址',
  'model.acquisitionDate' : '资产化日',
  'model.supplier' : '模具供应商',
  'model.text' : '文本',
  'model.supplierName' : '模具供应商名称',
  'model.costCenter' : '成本管理中心',
  'model.costCenterNo' : '成本管理中心编号',
  'model.costCenterName' : '成本管理中心名称',
  'model.weight' : '重量(KG)',
  'model.moldCavityNum' : '取数',
  'model.lifeShotsNum' : '使用寿命数',
  'model.moldsNum' : '面数',
  'model.primaryLeadDate' : '一次保管商的借出日期',
  'model.settlementNo' : '决裁No',
  'model.purchaseAmount' : '购买金额',
  'model.orderNo' : '订单单号',
  'model.depreciationStartDate' : '折旧开始日期',
  'model.discardDate' : '丢弃日期 ',
  'model.remarks' : '备注',
  'model.upload' : '上传模具信息',
  'model.assets' : '资产信息',
  'model.moldProperty' : '摸具信息',
  'model.deposit' : '保管商信息',
  'model.other' : '其他',
  'model.purchaseCurrency' : '采购货币',
  'model.fixedAssetRegistrationDecisionNumber' : '固定资产登记决定编号',
  'model.assetFlowDifferentiation' : '资产流动分类',
  'model.flowDate' : '流动日期',
  'model.finalLenderAddrCode' : '最终保管商地址编号',
  'model.assetFlowApplyCode' : '资产流动申请编号',
  'model.haveAttachments' : '有',
  'model.notHaveAttachments' : '无',
  'model.deleteFlag' : '删除标识',
  'model.haveOrNotHaveAttachments' : '是否有附件',
  'model.deleteSelected' : '删除',
  'model.confirmDeleteSelected' : '确认删除',
  'model.deleteMustSelectFirst' : '必须选择要删除的项',

  'inventory.confirmationUser' : 'IPRO确认者',
  'inventory.confirmationTime' : '确认时间',
  'inventoryConfirm' : '模具实查',
  'inventory' : '模具实查管理',
  'inventory.status' : '回复状态',
  'inventory.inventoryRequestNo' : '模具实查请求编号',
  'inventory.requestDate' : '模具实查请求日期 ',
  'inventory.requestDate2' : '请求日',
  'inventory.requester' : '模具实查请求者',
  'inventory.answerDeadline' : '希望回复日',
  'inventory.modelNum' : '模具件数 ',
  'inventory.moldsNum' : '面数',
  'inventory.actualMoldsNum' : '实际面数',
  'inventory.remarks' : '备注',
  'inventory.buyer' : '采购商',
  'inventory.detail' : '模具实查确认信息',
  'inventory.head' : '模具实查概要信息',
  'inventory.detailList' : '模具实查详细信息',
  'inventory.list' : '模具实查确认一览',
  'inventory.inventoryRequest' : '模具实查请求',
  'inventory.modifiedTime' : '更新日',
  'inventory.userName' : '更新者',
  'inventory.responseUser' : '供应商回复者',
  'inventory.responseTime' : '回复时间',
  'inventory.recordTheReason' : '{a}为 0 时，请务必在【{b}】中记载其理由。',
  'inventory.waitForReply' : '尚未回复完成，请点击右下角的【回复】按钮。',

  'docInfo' : '文档管理',
  'docInfo.list' : '文档列表',
  'docInfo.add' : '文档发送',
  'docInfo.detail' : '文档详细信息',
  'docInfo.sendInfo' : '发信信息',
  'docInfo.replyInfo' : '回复信息',
  'docInfo.buyer' : '采购商',
  'docInfo.buyerName' : '采购商名称',
  'docInfo.documentCategory' : '信息种类',
  'docInfo.title' : '标题',
  'docInfo.sendContent' : '发信内容',
  'docInfo.sendTime' : '发送时间',
  'docInfo.receiptContent' : '收件信息',
  'docInfo.receiptTime' : '收件时间',
  'docInfo.acceptingFlag' : '收件状态',
  'docInfo.supplier' : '供应商',
  'docInfo.supplierName' : '供应商名称',
  'docInfo.reply' : '回复',
  'docInfo.replyTime' : '回信时间',
  'docInfo.replyContent' : '回信内容',
  'docInfo.sendQuantity' : '发送件数',
  'docInfo.acceptQuantity' : '已读件数',
  'docInfo.replayQuantity' : '回复件数',
  'docInfo.docFiles' : '附件',
  'docInfo.selectAll' : '全部',
  'docInfo.acceptFlag' : '接受状态',
  'docInfo.replyFlag' : '回复状态',
  'docInfo.readFlag' : '收件状态',
  'docInfo.userName' : '发信人员',
  'docInfo.replyUserName' : '回复者',
  'docInfo.destinationName' : '目的地 ',
  'docInfo.destinationList' : '送信列表',
  'docInfo.uploadDestination' : '上传目的地',
  'docInfo.confirmSendDocument' : '文档发送确认',
  'docInfo.numberOfSuppliers' : '供应商数量： {i} 个',
  'docInfo.destinationNotExist' : '第 {i} 行的目的地 {name} 不存在。',

  'common.stateCode' : '变更区分',
  'common.userName' : '更新者',
  'common.userModifiedDate' : '更新日',
  'common.logout' : '退出',
  'common.save' : '保存',
  'common.add' : '追加',
  'common.update' : '更新',
  'common.clear' : '删除',
  'common.delete' : '删除',
  'common.deleted' : '已删除',
  'common.search' : '查询',
  'common.conditions' : '查询',
  'common.download' : '下载',
  'common.downloadPassed' : '下载已采用的数据',
  'common.upload' : '上传',
  'common.history' : '历史数据',
  'common.close' : '关闭',
  'common.cancel' : '取消',
  'common.cancel2' : '取消',
  'common.reset' : '重置',
  'common.readAll' : '全部已读',
  'common.edit' : '更改',
  'common.next' : '下一步',
  'common.previous' : '上一步',
  'common.confirm' : '确认',
  'common.confirmed' : '已确认',
  'common.response' : '回复',
  'common.hisory' : '变更记录',
  'common.operation' : '操作',
  'common.modalTitle' : '输入错误提示',
  'common.modalErrorTitle' : '错误提示',
  'common.basicInfomation' : '基本信息',
  'common.yes' : '是',
  'common.no' : '否',
  'common.send' : '发送',
  'common.dataCreationDate' : '登录日',
  'common.minimun' : '最小值',
  'common.maximum' : '最大值',

  'supplierEstimates.answerList' : '上传回复信息',
  'supplierEstimates.finishRequest' : '关闭案件',
  'supplierEstimates.detailDetail' : '报价部品详细信息',
  'supplierEstimates.accept' : '通过',
  'supplierEstimates.reject' : '未通过',

  'guide' : '系统帮助',
  'guide.docNotReadyNow' : '这个文档正在编写中',
  'guide.getBackLater' : '请稍后再来查看',
  'guide.os' : '操作系统',
  'guide.browser' : '浏览器',
  'guide.display' : '显示分辨率',
  'guide.language' : '系统语言',
  'guide.osVersion' : 'Windows8.1 以上版本',
  'guide.browserName' : 'Google Chrome、Microsoft Edge',
  'guide.displayPixel' : '1920 × 1080 像素 ',
  'guide.zoomTitle' : '系统的缩放与布局推荐',
  'guide.zoom' : '100% 或125%',
  'guide.languageName' : '日语，中文，英语',
  'guide.chinese' : '中文',
  'guide.English' : 'English',
  'guide.Japanese' : '日语',
  'guide.systemManual' : '系统使用手册',
  'guide.tel' : '联系我们',
  'guide.receptionTime' : '受理时间',
  'guide.china' : '中国',
  'guide.japan' : '日本',
  'guide.telChina' : '00086-XXX-XXXX-XXXX',
  'guide.telJapan' : '00081-XXX-XXXX-XXXX',
  'guide.supportInfo' : '系统帮助信息',
  'guide.download' : '下载',
  'guide.environment' : '系统环境',
  'guide.downloadIproChinaText' : 'i-Pro China专用',
  'guide.downloadIproJapanText' : 'i-Pro Japan专用',
  'guide.downloadSupplierChinaText' : 'i-Pro China供应商专用',
  'guide.downloadSupplierJapanText' : 'i-Pro Japan供应商专用',
  'guide.supplierOnly' : '供应商用',
  'guide.iproOnly' : 'i-PRO用',
  'guide.dataSynchronizationTime' : '数据同步时间',
  'guide.dataSendTime' : '发送时间',
  'guide.dataReceptionTime' : '接收时间',
  'guide.pipsSendTime' : '08时45分／10时45分／12时45分／14时45分／16时45分／18时45分／21时45分',
  'guide.pipsReceptionTime' : '02时35分／09时35分／11时35分／13时35分／15时35分／17时35分／19时35分',
  'guide.psnsSendTime' : '10时15分／15时15分／21时15分',
  'guide.psnsReceptionTime' : '11时50分／16时50分／22时50分',

  'home' : '仪表盘',
  'home.pieces' : '件',
  'home.unreadData' : '未读件数',
  'home.arrivedate' : '最新数据到达日期',
  'home.waitingShipped' : '待出货数据件数',
  'home.notanswered' : '未回答件数',
  'home.quote' : '来自供应商报价',
  'home.moldMaster' : '摸具详细',
  'home.detailInfoIncompleteNumber' : '待完善件数',
  'home.OurRequestQuotation' : '本公司请求报价',
  'home.estiamteUnclose' : '未关闭项目数',

  'batch.newestBatchInfo' : '最新{batchName}数据导入信息',
  'batch.dataImportTime' : '数据导入时间',
  'batch.newDataQuantity' : '新数据件数',
  'batch.updateDataQuantity' : '更新件数',
  'batch.skipDataQuantity' : '忽略件数',
  'batch.errorDataQuantity' : '失败件数',
  'batch.more' : '更多',
  'batch.newestBatchRunTime' : '最新{batchName}数据导入时间',
  'batch.batchDetail' : '数据导入详细信息',
  'batch.batchName' : '批处理区分',
  'batch.batchStatus' : '批处理执行状态',
  'batch.inOrderDetail' : '购买订单',
  'batch.inAcceptance' : '入库',
  'batch.inPayment' : '支付信息',

  'message.DigitRetainedUnitPrice' : '小数最多后{a}位，请重新确认。',
  'message.DigitRetained2' : '最多允许输入{n}位小数',
  'message.DigitRetainedAmountJP' : '不能出现小数，请重新确认。',
  'message.DigitRetainedAmount' : '小数最多保留2位，请重新确认。',
  'message.unitPriceMaxDecimal' : '最多保留小数6位',
  'message.lengthFailure' : '最多只能输入{length}个文字。',
  'message.lengthFailureSingleTAG' : '单个标签最多只能输入{length}个文字。',
  'message.IntegerPartLengthFailure' : '整数部分最多只能输入{length}个数字。',
  'message.fixedLengthLimit' : '只能输入{length}个文字。',
  'message.onlyHalfString' : '请输入半角文字。',
  'message.onlyPositiveInteger' : '请输入正整数。',
  'message.onlyPositiveNumber' : '请输入正数。',
  'message.noNegativeNumbers' : '{i}禁止输入负数。',
  'message.formateError' : '请输入半角英数字或者下列特殊记号。',
  'message.allowedSpecialMarkings' : '特殊记号：-　_ ',
  'message.outputDataLinesError' : '不能选择多条数据。',
  'message.commonElementError' : '不能选择{a}相同的数据。',
  'message.itemNotAllowed' : '第{i}行的部品编号在部品编号表中未登录。',
  'message.destinationNotAllowed' : '第{i}行的目的地未登录。',
  'message.timeOverTheLimit' : '第{i}行的{key}不能超过9999年12月31日。',
  'message.excelUploadFromatError' : '{i}行的{key}格式不正确或遗漏。',
  'message.excelUploadDuplicatedError' : '第{i}行和第{j}行重复。',
  'message.excelUploadFileNotFoundError' : '找不到上传的文件',
  'message.excelUploadError' : '上传时出错。',
  'message.excelUploadItemDecimalOverError' : '{orderNoTitle}：{orderNo}，{orderDetailTitle}：{orderDetailNo}，{dateTitle}：{date}的{item}的小数位最多允许{n}位。',
  'message.excelUploadItemMustGreatThenZeroError' : '{orderNoTitle}：{orderNo}，{orderDetailTitle}：{orderDetailNo}，{dateTitle}：{date}的{item}必须大于零。',
  'message.error' : '发生错误。',
  'message.priceOrCurrencyNullError' : '请同时输入{i}和{j}',
  'message.priceOrCurrencyNullError2' : '请同时输入{item}的{i}和{j}。',
  'message.uploadedFileEmpty' : '上传的文件为空。',
  'message.deliveryResponseQtyOver' : '交付回复合计数量超过订单总数',
  'message.goodIssueQtyOver' : '计划出货合计数量超过订单总数',
  'message.notAllowLineAtEnd' : '末尾不允许有横线',
  'message.noMultipleLines' : '不允许输入多个横线',
  'message.onlyNumberOrNumberAndLine' : '只允许输入数字或数字与横线“-”',
  'message.deliveryOutputFormSubmitWarning1' : '在生成交付单之前，请再次检查出货内容是否正确。',
  'message.deliveryOutputFormSubmitWarning2' : '※在生成交付单一定时间后，将无法取消交付单。',
  'message.numberTooLarge' : '数值过大',
  'message.sureYouWantToClose' : '您正在编辑的内容尚未回复，是否要关闭明细画面？',
  'message.closeWillLostEdit' : '※如果您想回复，请点击右下方的【回复】按钮。',
  'message.waiverChangeThenClose' : '关闭页面',
  'message.canNotDeleteDeletedItem' : '某些选中的项已标记为删除，因此无法删除',

  'agreement.readed' : '我已认真阅读并同意上述使用许可',
  'agreement.enter' : '进入系统',
};
export default zhCN;
