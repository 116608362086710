// React APIs
import dayjs from 'dayjs'
import React, { useState, useEffect } from 'react'
import { message, } from 'antd';
import { useIntl } from 'react-intl'
import { useLocation, useHistory, useParams } from "react-router-dom"

// Antd APIs
// Library APIs
import XLSX from 'xlsx'
import { DetailStates, ListStates } from '../common'
import { displayUtil } from '../util'

// 機能内部のAPIs
import { get, getDetail, search2Dto, dto2search, BillingDetailListDto, BillingDetailSearchDto, BillingDetailDetailDto, getDetailAmount, BillingDetailDetailAmountDto, } from './api'
import { Props as DetailProps } from './BillingDetailDetail'
import { Props as ListProps } from './BillingDetailList'
import { Props as SearchProps } from './BillingDetailSearch'
/**
 * 仕入明細のコンポネート
 */
export function BillingDetailHoc(
  Detail: React.ComponentType<DetailProps>,
  List: React.ComponentType<ListProps>,
  Search: React.ComponentType<SearchProps>) {

  // 共通のstates
  const intl = useIntl()

  // URLのデータ
  const { companyID: urlCompanyID, supplier: urlSupplier, acquisitiondate: urlAcquisitionDate, paymentDate: urlPaymentDate } = useParams<{ companyID?: string, supplier?: string, acquisitiondate?: string, paymentDate?: string }>()
  // URLのquery文字列
  const { search } = useLocation()
  // URL変更のハンドル
  const { push, goBack } = useHistory()
  // 検索条件（フィルター、ページとソート順）
  const [searchDto, setSearchDto] = useState<BillingDetailSearchDto>(search2Dto(search))

  // 一覧のstates
  const [listData, setListData] = useState<ListStates<BillingDetailListDto>>({ loading: false, total: 0, data: [] })

  // 検索のstates
  // 検査画面の表示状態
  const [searchVisible, setSearchVisible] = useState<boolean>(false)

  // 詳細のstates
  const [detailData, setDetailData] = useState<DetailStates<BillingDetailDetailDto[]>>({ visible: false })
  // 詳細のstates
  const [detailAmountData, setDetailAmountData] = useState<BillingDetailDetailAmountDto[]>()

  // URLのQuery変更によって検索を再実施する
  useEffect(() => {
    if (urlCompanyID && urlSupplier && urlAcquisitionDate && urlPaymentDate) {
      setListData(data => ({ ...data, loading: true }))
      getDetail(urlCompanyID, urlSupplier, urlAcquisitionDate, urlPaymentDate)
        .then((detailsRecord: BillingDetailDetailDto[]) => {
          getDetailAmount(urlCompanyID, urlSupplier, urlAcquisitionDate, urlPaymentDate)
            .then((detailAmountRecord: BillingDetailDetailAmountDto[]) => {
              setDetailAmountData(detailAmountRecord)
            }).catch(() => {
              message.error(intl.formatMessage({id: 'billingDetail.totalPurchaseAmountGetFailed'}))
            }).finally(() => {
              setListData(data => ({ ...data, loading: false }))
              setDetailData({ visible: true, data: detailsRecord, })
            })
        }).catch(() => {
          setListData(data => ({ ...data, loading: false }))
        })
    } else {
      setListData(data => ({ ...data, loading: true }))
      setSearchDto(search2Dto(search))

      get(search)
        .then((para: [number, BillingDetailListDto[]]) => {
          const [count, vos] = para
          setListData({ loading: false, total: count, data: vos })
        })
        .catch(() => {
          setListData(data => ({ ...data, loading: false }))
        })
    }
  }, [urlCompanyID, urlSupplier, urlAcquisitionDate, urlPaymentDate, search, intl])

  // 検索の処理
  const handleSearch = (value: any) => {
    setSearchVisible(false)
    setSearch(value)
  }
  
  // 検索条件を変更する
  const setSearch = (researchDto: BillingDetailSearchDto) => {
    // 期間範囲の編集
    // 取引年月
    if (!!researchDto.acquisitionDate && !!researchDto.acquisitionDate[0]) {
      researchDto.acquisitionDate[0] = researchDto.acquisitionDate[0].startOf('day')
    }
    if (!!researchDto.acquisitionDate && !!researchDto.acquisitionDate[1]) {
      researchDto.acquisitionDate[1] = researchDto.acquisitionDate[1].endOf('day')
    }
    // 支払日
    if (!!researchDto.paymentDate && !!researchDto.paymentDate[0]) {
      researchDto.paymentDate[0] = researchDto.paymentDate[0].startOf('day')
    }
    if (!!researchDto.paymentDate && !!researchDto.paymentDate[1]) {
      researchDto.paymentDate[1] = researchDto.paymentDate[1].endOf('day')
    }

    // 検索条件変更を反映する
    const query = dto2search(researchDto)

    // URLを再設定する
    push(`/billingDetail${query}`)
  }

  const download = () => {
    const downloadSearchDto = { ...searchDto, rowCount: 0 }

    setListData(data => ({ ...data, loading: true }))

    get(dto2search(downloadSearchDto))
      .then((para: [number, BillingDetailListDto[]]) => {
        setListData(data => ({ ...data, loading: false }))
        
        const [, vos] = para
        const excelData = []

        const titles = []
        titles.push(intl.formatMessage({ id: 'billingDetail.acquisitionDate' })) // 年月
        titles.push(intl.formatMessage({ id: 'pay.paymentDate' })) // 支払日
        titles.push(intl.formatMessage({ id: 'pay.totalPaymentAmount' }))  // 支払合計金額
        titles.push(intl.formatMessage({ id: 'billingDetail.supplierCode' })) // 仕入先コード
        titles.push(intl.formatMessage({ id: 'billingDetail.supplierName' })) // 仕入先名称
        excelData.push(titles)

        vos.forEach(vo => {
          const datas = []
          datas.push(displayUtil.date(vo.acquisitionDate))
          datas.push( vo?.paymentUponEachUseFlag === '1' ? intl.formatMessage({ id: 'pay.paymentUponEachUse' }) : displayUtil.date(vo?.paymentDate) )
          datas.push(vo.purchaseAmount)
          datas.push(vo.supplierCode)
          datas.push(vo.supplierName)

          excelData.push(datas)
        })

        const worksheet = XLSX.utils.aoa_to_sheet(excelData)
        const workbook = XLSX.utils.book_new()

        XLSX.utils.book_append_sheet(workbook, worksheet, intl.formatMessage({ id: 'billingDetail.list' }))
        XLSX.writeFile(workbook, `${intl.formatMessage({ id: 'billingDetail.list' })}_${dayjs().format('YYYYMMDDHHmmss')}.xlsx`)
      })
      .catch(() => {
        setListData(data => ({ ...data, loading: false }))
      })
  }

  const downloadDetail = () => {
    if (urlCompanyID && urlSupplier && urlAcquisitionDate && urlPaymentDate) {
      getDetail(urlCompanyID, urlSupplier, urlAcquisitionDate, urlPaymentDate).then((detailsData: BillingDetailDetailDto[]) => {
        getDetailAmount(urlCompanyID, urlSupplier, urlAcquisitionDate, urlPaymentDate).then((detailAmountsData: BillingDetailDetailAmountDto[]) => {
          if (detailsData) {
            const excelData = []

            const headerLine: string[] = []
            headerLine.push('')
            headerLine.push('')
            headerLine.push('')
            headerLine.push('')
            headerLine.push('')
            headerLine.push(`${detailsData?.[0]?.acquisitionDate.year()} ${intl.formatMessage({ id: 'billingDetail.year' })}`)
            headerLine.push(`${detailsData?.[0]?.acquisitionDate.month() + 1} ${intl.formatMessage({ id: 'billingDetail.month' })}`)
  
            excelData.push(headerLine)
  
            const supplierLine: string[] = []
            supplierLine.push(`${detailsData?.[0]?.supplierCode}`)
            headerLine.push('')
            supplierLine.push(`${detailsData?.[0]?.supplierName}`)
            
            excelData.push(supplierLine)
  
            const amountLine: string[] = []
            if(detailAmountsData) {
              amountLine.push(intl.formatMessage({ id: 'billingDetail.totalPurchaseAmount' }))
              detailAmountsData.forEach(amount => {
                amountLine.push(`${amount.currencyCode}：${displayUtil.currency(amount.currencyCode, amount.purchaseAmount)}`)
              })
              excelData.push(amountLine)
            }
  
            excelData.push([])
  
            const tableTitleLine: string[] = []
            tableTitleLine.push(intl.formatMessage({ id: 'billingDetail.transcriptionDate' }))
            tableTitleLine.push(intl.formatMessage({ id: 'billingDetail.deliverySlipNumber' }))
            tableTitleLine.push(intl.formatMessage({ id: 'billingDetail.purchaseOrderNo' }))
            tableTitleLine.push(intl.formatMessage({ id: 'billingDetail.purchaseDetailNo' }))
            tableTitleLine.push(intl.formatMessage({ id: 'billingDetail.itemCode' }))
            tableTitleLine.push(intl.formatMessage({ id: 'billingDetail.itemText' }))
            tableTitleLine.push(intl.formatMessage({ id: 'billingDetail.incorporationQuantity' }))
            tableTitleLine.push(intl.formatMessage({ id: 'billingDetail.unit' }))
            tableTitleLine.push(intl.formatMessage({ id: 'billingDetail.purchaseUnitPrice' }))
            tableTitleLine.push(intl.formatMessage({ id: 'billingDetail.purchaseAmount' }))
            tableTitleLine.push(intl.formatMessage({ id: 'billingDetail.currencyCode' }))
            tableTitleLine.push(intl.formatMessage({ id: 'billingDetail.purchaseTax' }))
  
            excelData.push(tableTitleLine)
  
            detailsData.forEach(detail => {
              const tableDataLines: (string | number)[] = []
              tableDataLines.push(detail?.transcriptionDate)
              tableDataLines.push(detail?.deliverySlipNumber)
              tableDataLines.push(detail?.purchaseOrderNo)
              tableDataLines.push(detail?.purchaseDetailNo)
              tableDataLines.push(detail?.itemCode)
              tableDataLines.push(detail?.itemText)
              tableDataLines.push(detail?.incorporationQuantity)
              tableDataLines.push(detail?.unit)
              tableDataLines.push(detail?.purchaseUnitPrice)
              tableDataLines.push(detail?.purchaseAmount)
              tableDataLines.push(detail?.currencyCode)
              tableDataLines.push(detail?.purchaseTax)
              
              excelData.push(tableDataLines)
            })
            
  
            const worksheet = XLSX.utils.aoa_to_sheet(excelData)
            const workbook = XLSX.utils.book_new()
  
            XLSX.utils.book_append_sheet(workbook, worksheet, intl.formatMessage({ id: 'billingDetail' }))
            XLSX.writeFile(workbook, `${intl.formatMessage({ id: 'billingDetail' })}_${dayjs().format('YYYYMMDDHHmmss')}.xlsx`) 
          }
        }).catch(() => {
          message.error(intl.formatMessage({id: 'billingDetail.totalPurchaseAmountGetFailed'}))
        })
      })
    }
  }

  const reset = (rowCount: number) => {
    setSearch({

      acquisitionDate: [null, null],

      paymentDate: [null, null],

      suppliers: [],

      supplierName: '',

      purchaseAmount: [undefined, undefined],

      rowCount: rowCount,
    } as BillingDetailSearchDto)
  }

  const listProps = {
    // 画面loading状態
    loading: listData.loading,
    // 件数合計
    total: listData.total,
    // 一覧画面の表示データ
    data: listData.data,
    // 検索条件（フィルター、ページとソート順）
    searchDto: searchDto,
    // 検査画面を表示する
    openSearchModal: () => setSearchVisible(true),
    //Download
    download: download,
    // 検査画面を表示する
    handleResearch: setSearch,
    handleSearch: handleSearch,
    reset: reset
  }

  const searchProps = {
    // 検査画面の表示状態
    visible: searchVisible,
    searchDto: searchDto,
    handleSearch: (value: BillingDetailSearchDto) => {
      setSearchVisible(false)
      setSearch(value)
    },
    close: () => setSearchVisible(false),
  }

  const detailProps = {
    // 詳細画面の表示状態
    visible: detailData.visible,
    details: detailData.data,
    detailAmounts: detailAmountData,
    downloadDetail,
    close: () => {
      if (dto2search(searchDto) !== '') {
        goBack()
      } else {
        push('/billingDetail')
      }
      setDetailAmountData([]) // 设置 list data 时清空 detail data
      setDetailData({visible: false, data: []}) // 设置 list data 时清空 detail data
    },
  }

  return (
    <>
      <List {...listProps} />
      <Search {...searchProps} />
      <Detail {...detailProps} />
    </>
  )
}
