import React from 'react';

import { Card, Row, Col, CardProps } from 'antd';

interface Props {
  title?: CardProps;
  cols: [JSX.Element, any][];
}

export function Detail(props: Props) {

  const cols = [];

  for (let col of props.cols) {
    cols.push(<Col span={3} style={{ textAlign: 'right', fontWeight: 'bold', }}>{col[0]}</Col>);
    cols.push(<Col span={5} style={{ textDecorationLine: 'underline' }}>{col[1]}</Col>);
  }

  return (
    <Card bordered={false} title={props.title} headStyle={{paddingTop:16}}>
      <Row gutter={[16, 18]}>
        {cols}
      </Row>
    </Card>
  );
}

export function DetailSmall(props: Props) {

  const cols = [];

  for (let col of props.cols) {
    cols.push(<Col span={3} style={{ textAlign: 'right', fontWeight: 'bold', }}>{col[0]}</Col>);
    cols.push(<Col span={5} style={{ textDecorationLine: 'underline' }}>{col[1]}</Col>);
  }

  return (
    <Card bordered={false} title={props.title} headStyle={{paddingTop:16}}>
      <Row gutter={[16, 8]}>
        {cols}
      </Row>
    </Card>
  );
}