import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl, } from 'react-intl';

import { Button, Form, Input, Modal, Select, Spin} from 'antd';

import './index.css';

import { Attachment, CommonExcelUpload, CommonExcelUploadProps, RowData } from '../common';
import { SupplierEstimatesDetailDto, SupplierEstimatesDto } from './api';
import { filterUtil } from '../util';
import { Field, useFields } from '../fields';
import { CommonFileUpload } from '../common/CommonFileUpload';

export interface Props {
  visible: boolean;
  upload: (data: SupplierEstimatesDto) => void;
  close: () => void;
  uploading: boolean;
}

export function SupplierEstimatesUpload(props: Props) {

  // 共通のstates
  const intl = useIntl();
  const buyerOptions = useFields('buyer');
  const [form] = Form.useForm<SupplierEstimatesDto>();

  const [dtos, setDtos] = useState<SupplierEstimatesDetailDto[]>([]);
  const [attachments, setAttachments] = useState<Attachment[]>([]);

  useEffect(() => {
    if (props.visible === false) {
      setDtos([]);
      setAttachments([]);
      form.resetFields();
    }
  }, [form, props.visible])

  // 購入元コード
  const buyerTitle = <FormattedMessage id='estimate.buyer' />;
  // 購買担当
  const purchasingManagerTitle = <FormattedMessage id='estimate.purchasingManager' />;

  const validate = (rowDatas: RowData[]) => {
    const dtos = rowDatas.map(
      (rowData: RowData) => {
        return { ...rowData } as unknown as SupplierEstimatesDetailDto;
      }
    );

    return dtos
  };

  // 获得数据库中的所有品番ID
  const itemOptions = useFields('item');

  interface UnitValidator {
    validate: (value: any) => boolean;
    message: string;
  }
  // 判定文件上传时品番是否是数据库品番表中的品番的回调函数 和 报错信息
  const itemValidators: UnitValidator[] = [{
    validate: (value: any) => {
      let itemOut = true
      for (let i = 0; i < itemOptions.length; i++) {  // 循环数据中的品番，如果相等则返回false并结束循环
        if (itemOptions[i].value === value) {
          itemOut = false
          break
        }
      }
      return itemOut
    }, message: 'message.itemNotAllowed'
  }];

  // 判定文件上传时非负数验证的回调函数 和 报错信息
  const excelNotNegativeNumberValidators: UnitValidator[] = [{validate: (value: any) => {
    if(value !== null && (value < 0)) {
      return true
    } else {
      return false
    }
  }, message: 'message.excelNegativeNumberError'}]

  // 判定文件上传时L/T专用的非负数验证的回调函数 和 报错信息  (禁止大于2147483647和小于等于0)
  const excelLTNotNegativeNumberValidators: UnitValidator[] = [{validate: (value: any) => {
    if(value !== null && (value <= 0 || value > 2147483647)) {
      return true
    } else {
      return false
    }
  }, message: 'message.excelLTPositiveNumberError'}]

  // 判定文件上传时SPQ是否是合规的回调函数 和 报错信息
  const excelSPQValidators: UnitValidator[] = [{validate: (value: any) => {

    // 在满足 不为空 的前提下，满足以下任意一个条件即为真: 
    // 非空验证
    const checkNotNull = value !== null
    // 1. 是小数(模1不等于0)  2. 长度超过6 (大于999999)  3. 不是正整数
    const checkRightNumber = value % 1 !== 0 || value > 999999 || value <= 0 
    const checkNumber = checkNotNull && checkRightNumber

    if( checkNumber ) {
      return true
    } else {
      return false
    }
  }, message: 'message.excelSPQError'}]

  // 判定文件上传时MPQ是否是合规的回调函数 和 报错信息
  const excelMPQValidators: UnitValidator[] = [{validate: (value: any) => {
    
    // 在满足 不为空 的前提下，满足以下任意一个条件即为真: 
    // 非空验证
    const checkNotNull = value !== null
    // 1. 是小数(模1不等于0)  2. 长度超过6 (大于999999)  3. 不是正整数
    const checkRightNumber = value % 1 !== 0 || value > 999999 || value <= 0 
    const checkNumber = checkNotNull && checkRightNumber

    if( checkNumber ) {
      return true
    } else {
      return false
    }
  }, message: 'message.excelMPQError'}]

  

  const uploadProps: CommonExcelUploadProps = {
    row: {
      columns: [
        { key: 'estimate.item', field: 'item', type: 'string', isRequired: true, validators: itemValidators, },
        { key: 'estimate.itemName', field: 'itemName', type: 'string', isRequired: true, validators: [], },
        { key: 'estimate.estimateStartDate', field: 'estimateStartDate', type: 'date', isRequired: true, validators: [], },
        { key: 'estimate.leadTime', field: 'leadTime', type: 'int', isRequired: true, validators: excelLTNotNegativeNumberValidators, },
        { key: 'estimate.moq', field: 'moq', type: 'decimal', isRequired: true, validators: excelSPQValidators, },
        { key: 'estimate.mpq', field: 'mpq', type: 'decimal', isRequired: true, validators: excelMPQValidators, },
        { key: 'estimate.unitPrice', field: 'unitPrice', type: 'decimal', isRequired: true, validators: excelNotNegativeNumberValidators, },
        { key: 'estimate.unitCurrency', field: 'unitCurrency', type: 'string', isRequired: true, validators: [], },
        { key: 'estimate.repairsCost', field: 'repairsCost', type: 'decimal', isRequired: true, validators: excelNotNegativeNumberValidators, },
        { key: 'estimate.repairsCurrency', field: 'repairsCurrency', type: 'string', isRequired: true, validators: [], },
      ],
      uniqueItems: ['item'],
    },
    validate: validate,
    upload: (data: SupplierEstimatesDetailDto[]) => {
      setDtos(data);
    },
  };

  const fileUploadProps = {
    attachments: [],
    canUpload: true,
    persist: (uuid: string) => {
      setAttachments([...attachments, { id: -1, uuid: uuid }]);
    },
    remove: (uuid: string) => {
      setAttachments(attachments.filter((attachment: Attachment) => attachment.uuid !== uuid));
    },
  };

  const upload = (form: SupplierEstimatesDto) => {
    form.details = dtos;
    form.attachments = attachments;
    props.upload(form);
    setDtos([]);
    setAttachments([]);
    // props.close();
  };

  return (
    <Modal
      title={<FormattedMessage id='supplierEstimates.requestList' />}
      width='600px'
      footer={null}
      visible={props.visible}
      destroyOnClose={true}
      onCancel={props.close}>
      <Form
        form={form}
        onFinish={upload}
        layout={'horizontal'}
        colon={false}
        size={'large'}
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 16 }}>
        <Form.Item name='buyer' label={buyerTitle}
          rules={[
            {
              required: true,
              message: intl.formatMessage({ id: 'estimate.nullError' }, { name: intl.formatMessage({ id: 'estimate.buyer' }) }),
            },
          ]}
        >
          <Select<Field>
            allowClear={true}
            autoClearSearchValue={false}
            filterOption={filterUtil.selectFilter}
            onSearch={filterUtil.onSearchForm(form, 'buyers', buyerOptions)}
            loading={buyerOptions.length === 0}
            options={buyerOptions} />
        </Form.Item>
        <Form.Item name='purchasingManagerName' label={purchasingManagerTitle}
          rules={[
            {
              required: true,
              message: intl.formatMessage({ id: 'estimate.nullError' }, { name: intl.formatMessage({ id: 'estimate.purchasingManager' }) }),
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label={<FormattedMessage id='supplierEstimates.upload' />}>
          <Spin 
            size='default'
            spinning={props.uploading}
          >
            <CommonExcelUpload {...uploadProps} />
          </Spin>
        </Form.Item>
        <Form.Item
          label={<FormattedMessage id='estimate.upload' />}>
          <CommonFileUpload {...fileUploadProps} />
        </Form.Item>

        <Form.Item>
          <div className='search-button' style={{ paddingLeft: '80px' }}>
            <Button className='search-close-btn' onClick={props.close}>
              <FormattedMessage id='common.close' />
            </Button>
            <Button className='search-search-btn' type='primary' htmlType='submit' disabled={dtos.length === 0}>
              <FormattedMessage id='common.upload' />
            </Button>
          </div>
        </Form.Item>
      </Form>
    </Modal>
  );
}

