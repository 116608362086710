// React APIs
import dayjs from 'dayjs';
import React, { useState, useEffect, useCallback } from 'react';
import { useIntl } from 'react-intl';
import { useLocation, useHistory, useParams, } from "react-router-dom";
// Antd APIs
// Library APIs
import XLSX from 'xlsx';
import { ListStates } from '../common';
import { displayUtil } from '../util';

// 共通のAPIs
// 機能内部のAPIs
import { get, search2Dto, dto2search, ForecastListRecordDto, ForecastSearchDto, getHistory, getHistoryDates, } from './api';
import { Props as ListProps } from './ForecastList'
import { Props as SearchProps } from './ForecastSearch'

/**
 * 所要計画のコンポネート
 */
export function ForecastHoc(
  List: React.ComponentType<ListProps>,
  Search: React.ComponentType<SearchProps>) {


  type OptionType = {
    value: number;
    label: any;
  };

  // 共通のstates
  const intl = useIntl();
  // URLのID
  const { date: urlDate } = useParams<{ date?: string }>();
  // URLのquery文字列
  const { search } = useLocation();
  // URL変更のハンドル-
  const { push } = useHistory();
  // 検索条件（フィルター、ページとソート順）
  const [searchDto, setSearchDto] = useState<ForecastSearchDto>(search2Dto(search));

  // 画面loading状態
  const [listData, setListData] = useState<ListStates<ForecastListRecordDto>>({ loading: false, total: 0, data: [] });

  // 検索のstates
  // 検査画面の表示状態
  const [searchVisible, setSearchVisible] = useState<boolean>(false);

  // forecast是否为空
  const [nullForecastFlag, setNullForecastFlag] = useState<boolean>(false);

  // forecast Date Option Index
  const [forecastDataOptionIndex, setForecastDataOptionIndex] = useState<number>(0)

  // 历史记录中的‘当前’选项
  const nowTitle = intl.formatMessage({id: 'forecast.now'})
  const [forecastDateOptions, setForecastDateOptions] = useState<OptionType[]>([{ label: nowTitle, value: 0 }])
  

  



  // 検索条件を変更する
  const setSearch = (researchDto: ForecastSearchDto) => {

    // // 期間範囲の編集
    if (!!researchDto.iniDeliveryDate && !!researchDto.iniDeliveryDate[0]) {
      researchDto.iniDeliveryDate[0] = researchDto.iniDeliveryDate[0].startOf('day');
    }
    if (!!researchDto.iniDeliveryDate && !!researchDto.iniDeliveryDate[1]) {
      researchDto.iniDeliveryDate[1] = researchDto.iniDeliveryDate[1].endOf('day');
    }
    const query = dto2search(researchDto)

    if (!urlDate) {
      // URLを再設定する
      push(`/forecasts${query}`);
    }
    else {
      // URLを再設定する
      push(`/forecasts/${urlDate}${query}`);
    }
  };

  const getCorrectForecastDateOptionIndex = useCallback(() => {
    if(!!urlDate) {
      const urlDateNumber = parseFloat(urlDate)
      if (!!urlDateNumber) {
        const forecastOptionIndex = forecastDateOptions.findIndex( option => option.value === urlDateNumber)
        if(!!forecastOptionIndex && forecastOptionIndex >= 0) {
          setForecastDataOptionIndex(forecastOptionIndex)
        } else {
          setForecastDataOptionIndex(0)
        }
      } else {
        setForecastDataOptionIndex(0)
      }
    } else {
      setForecastDataOptionIndex(0)
    }
  }, [forecastDateOptions, urlDate])

  const download = () => {

    setListData(data => ({ ...data, loading: true }));

    const downloadSearch = dto2search({ ...searchDto, rowCount: 0 });

    let searchResult: Promise<[number, ForecastListRecordDto[]]>;

    if (!urlDate) {
      searchResult = get(downloadSearch)
    }
    else {
      searchResult = getHistory(downloadSearch, urlDate);
    }

    searchResult
      .then((para: [number, ForecastListRecordDto[]]) => {

        setListData(data => ({ ...data, loading: false }));

        const [, vos] = para;

        const excelData = [
          [
            // intl.formatMessage({ id: 'forecast.buyer' }),
            intl.formatMessage({ id: 'forecast.buyerName' }),
            intl.formatMessage({ id: 'forecast.supplier' }),
            intl.formatMessage({ id: 'forecast.supplierName' }),
            intl.formatMessage({ id: 'forecast.planningDate' }),
            intl.formatMessage({ id: 'forecast.item' }),
            intl.formatMessage({ id: 'forecast.itemName' }),
            intl.formatMessage({ id: 'forecast.iniDeliveryMonth' }),
            intl.formatMessage({ id: 'forecast.iniDeliveryDate' }),
            intl.formatMessage({ id: 'forecast.quantity' }),
            intl.formatMessage({ id: 'forecast.unit' }),
            intl.formatMessage({ id: 'forecast.purchasingManager' }),
          ],
          ...(vos.map(vo => [
            // vo.buyer,
            vo.buyerName,
            vo.supplier,
            vo.supplierName,
            displayUtil.date(vo.planningDate),
            vo.item,
            vo.itemName,
            displayUtil.month(vo.iniDeliveryDate),
            displayUtil.date(vo.iniDeliveryDate),
            vo.quantity,
            vo.unit,
            vo.purchasingManagerName,
          ])),
        ];
        const worksheet = XLSX.utils.aoa_to_sheet(excelData);
        const workbook = XLSX.utils.book_new();

        XLSX.utils.book_append_sheet(workbook, worksheet, intl.formatMessage({ id: 'forecast' }));
        XLSX.writeFile(workbook, `${intl.formatMessage({ id: 'forecast' })}_${dayjs().format('YYYYMMDDHHmmss')}.xlsx`);
      }).catch(() => {

        setListData(data => ({ ...data, loading: false }));
      });
  };

  const reset = (rowCount: number) => {
    setSearch({

       iniDeliveryDate: [null, null],

        itemName: '',

        items: [],

        suppliers: [],

        supplierName: '',

        rowCount: rowCount

    } as ForecastSearchDto)
  }

  // 初始化 historyOptions 下拉列表的数据
  useEffect(() => {
    getHistoryDates()
      .then((historyDates) => {
        setForecastDateOptions( [{ label: nowTitle, value: 0 }, ...historyDates.map((date) => ({ label: displayUtil.date(date), value: date.valueOf() } as OptionType))] )
      })
      .catch(() => {
      });
  }, [nowTitle]);

  // 当 historyOptions 改变后，调用 getCorrectForecastDateOptionIndex 验证当前url所包含的日期并返回这个日期在option中的第几位
  useEffect(() => {
    getCorrectForecastDateOptionIndex()
  }, [forecastDateOptions, getCorrectForecastDateOptionIndex])


  // URLのQuery変更によって検索を再実施する
  useEffect(() => {
    // 画面loading状態を変更する
    setListData(data => ({ ...data, loading: true }));
    // null error model false 状態を変更する
    setNullForecastFlag(false);
    // 検索条件変更を反映する
    setSearchDto(search2Dto(search));

    let searchResult: Promise<[number, ForecastListRecordDto[]]>;

    if (!urlDate) {
      searchResult = get(search)
      getCorrectForecastDateOptionIndex()
    }
    else {
      searchResult = getHistory(search, urlDate);
      getCorrectForecastDateOptionIndex()
    }

    searchResult.then((para: [number, ForecastListRecordDto[]]) => {
      const [count, vos] = para;
      setListData({ loading: false, total: count, data: vos });

      if( (!count || count <= 0 ) && !urlDate ) {  // 如果返回的数据条数为空或小于等于 0 ，并且正在查看最新forecast (URL中无时间数据)，则将 null flag 设置true
        setNullForecastFlag(true) 
      }
    }).catch(() => {
      setListData(data => ({ ...data, loading: false }));
      setNullForecastFlag(true) 
    });

  }, [urlDate, search, getCorrectForecastDateOptionIndex]);


  const listProps: ListProps = {
    // 画面loading状態
    loading: listData.loading,
    // null forecast flag
    nullForecastFlag: nullForecastFlag,
    // 件数合計
    total: listData.total,
    // 一覧画面の表示データ
    data: listData.data,
    // 検索条件（フィルター、ページとソート順）
    searchDto: searchDto,
    forecastDateOptions: forecastDateOptions,
    correctForecastOptionIndex: forecastDataOptionIndex,
    historyDate: !!urlDate ? dayjs(parseInt(urlDate)) : undefined,
    // 検査画面を表示する
    openSearchModal: () => setSearchVisible(true),
    download: download,
    // フィルター、ページ又はソート順の変更より再検索する
    handleResearch: setSearch,
    goHistory: (historyDate?: number) => {
      if (!!historyDate) {
        push(`/forecasts/${historyDate}`);
      }
      else {
        push(`/forecasts`);
      }
    },
    reset: reset
  };

  const searchProps: SearchProps = {
    // 検査画面の表示状態
    visible: searchVisible,
    searchDto: searchDto,
    handleSearch: (value: ForecastSearchDto) => {
      setSearchVisible(false);
      setSearch(value);
    },
    close: () => setSearchVisible(false),
  };

  return (
    <>
      <List {...listProps} />
      <Search {...searchProps} />
    </>
  );
}
