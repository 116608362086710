import React from 'react';
import { Empty } from 'antd';

export function PermissionDenied() {


    return (
        <><Empty description="IProへアクセス権限なし" text-align="center" />
        <span style={{textAlign:'center', fontSize:14}}>管理者にご確認お願い致します。</span>
        </>
        );
}
