// React APIs
import React from 'react';

// 機能内部のAPIs
import { ForecastList } from './ForecastList';
import { ForecastSearch } from './ForecastSearch';
import { ForecastHoc } from './ForecastHoc';

/**
 * 所要計画のコンポネート
 */
export function Forecast() {

  const forecast = ForecastHoc(ForecastList, ForecastSearch);

  return (
    <>
      {forecast}
    </>
  );
}
