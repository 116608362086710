import React from 'react';
import { EstimateEditDetail } from './EstimationEditDetail';

import { EstimationHoc } from './EstimationHoc';
import { EstimationList } from './EstimationList';
import { EstimationSearch } from './EstimationSearch';
import { EstimationDetail } from './EstimationDetail';
import {EstimationSupplierList} from './EstimationSupplierList'
import { EstimateSpecifyEditDetail } from './EstimationSpecifyEditDetail';
import { EstimationSpecifyDetail } from './EstimationSpecifyDetail';
export function Estimation() {

    const estimation = EstimationHoc(EstimationList,EstimationSupplierList, EstimationSearch,EstimationDetail,EstimateEditDetail, EstimateSpecifyEditDetail, EstimationSpecifyDetail);

    return (
        <>
            {estimation}
        </>
    );
}
