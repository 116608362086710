import React, { useEffect, useState } from 'react';
import { FormattedMessage, } from 'react-intl';
import { Modal, Form, Input, Button, Select, Row, Col } from 'antd';

import { DatePicker, NumberRange } from '../common'

import { PaySearchDto } from './api';

import './index.css';
import { Field, useFields } from '../fields';
import { filterUtil } from '../util';
import { useUser } from '../authorization';
import { NumberRangePicker } from '../common/NumberRangePicker';

const { RangePicker } = DatePicker;

export interface Props {
  visible: boolean;
  searchDto: PaySearchDto;
  readFlagOptions: Field[];
  close: () => void;
  handleSearch: (searchDto: PaySearchDto) => void;
}

export function PaySearch(props: Props) {
  return (
    <Modal
      title={<FormattedMessage tagName='h4' id='common.search' />}
      footer={null}
      visible={props.visible}
      closable={false}
      afterClose={props.close}
      destroyOnClose={true}
      width={560}>
      {props.visible && <PaySearchForm {...props} />}
    </Modal>
  );
}

const PaySearchForm = (props: Props) => {

  const [form] = Form.useForm<PaySearchDto>();

  const user = useUser();  

  useEffect(() => {
    form.setFieldsValue(props.searchDto);
  });

  const handleSubmit = (record: PaySearchDto) => {
    // 自动调换最大值和最小值的位置，让大值在右，小值在左
    const currectTotalPaymentAmountValue = totalPaymentAmountValue?.[0] && totalPaymentAmountValue[1] && totalPaymentAmountValue[0] > totalPaymentAmountValue[1] ? [totalPaymentAmountValue[1], totalPaymentAmountValue[0]] as NumberRange : totalPaymentAmountValue
    const currectLastMonthPayAdjustAmountAllValue = lastMonthPayAdjustAmountAllValue?.[0] && lastMonthPayAdjustAmountAllValue[1] && lastMonthPayAdjustAmountAllValue[0] > lastMonthPayAdjustAmountAllValue[1] ? [lastMonthPayAdjustAmountAllValue[1], lastMonthPayAdjustAmountAllValue[0]] as NumberRange : lastMonthPayAdjustAmountAllValue
    const currectPurchasePriceAllValue  = purchasePriceAllValue?.[0] && purchasePriceAllValue[1] && purchasePriceAllValue[0] > purchasePriceAllValue[1] ? [purchasePriceAllValue[1], purchasePriceAllValue[0]] as NumberRange : purchasePriceAllValue
    const currectAccountsReceivableOffsetValue = accountsReceivableOffsetValue?.[0] && accountsReceivableOffsetValue[1] && accountsReceivableOffsetValue[0] > accountsReceivableOffsetValue[1] ? [accountsReceivableOffsetValue[1], accountsReceivableOffsetValue[0]] as NumberRange : accountsReceivableOffsetValue
    const currectOtherOffsetValue = otherOffsetValue?.[0] && otherOffsetValue[1] && otherOffsetValue[0] > otherOffsetValue[1] ? [otherOffsetValue[1], otherOffsetValue[0]] as NumberRange : otherOffsetValue
    const currectPayAdjustAmountAllValue = payAdjustAmountAllValue?.[0] && payAdjustAmountAllValue[1] && payAdjustAmountAllValue[0] > payAdjustAmountAllValue[1] ? [payAdjustAmountAllValue[1], payAdjustAmountAllValue[0]] as NumberRange : payAdjustAmountAllValue
    const currectBalanceAmountPayValue = balanceAmountPayValue?.[0] && balanceAmountPayValue[1] && balanceAmountPayValue[0] > balanceAmountPayValue[1] ? [balanceAmountPayValue[1], balanceAmountPayValue[0]] as NumberRange : balanceAmountPayValue

    record.totalPaymentAmount = currectTotalPaymentAmountValue
    record.lastMonthPayAdjustAmountAll = currectLastMonthPayAdjustAmountAllValue
    record.purchasePriceAll = currectPurchasePriceAllValue
    record.accountsReceivableOffset = currectAccountsReceivableOffsetValue
    record.otherOffset = currectOtherOffsetValue
    record.payAdjustAmountAll = currectPayAdjustAmountAllValue
    record.balanceAmountPay = currectBalanceAmountPayValue

    props.handleSearch(record)
  }

  // 支払ID
  const paymentIdTitle = <FormattedMessage id='pay.paymentId' />
  // サプライヤーコード
  const supplierCodeTitle = <FormattedMessage id='pay.supplierCode' />
  // 受注者名称
  const supplierNameTitle = <FormattedMessage id='pay.supplierName' />
  // 支払日
  const paymentDateTitle = <FormattedMessage id='pay.paymentDate' />
  // 既読フラグ
  const readFlagTitle = <FormattedMessage id='pay.readFlag' />;
  // 発行日
  const dateOfIssueTitle = <FormattedMessage id='pay.dateOfIssue' />;
  // 支払合計金額
  const totalPaymentAmountTitle = <FormattedMessage id='pay.totalPaymentAmount' />;
  // 前月支払修正額(合計)
  const lastMonthPayAdjustAmountTitle = <FormattedMessage id='pay.lastMonthPayAdjustAmount' />;
  // 仕入代金(合計)
  const purchasePriceAllTitle = <FormattedMessage id='pay.purchasePrice' />;
  // 売掛金相殺(合計)
  const accountsReceivableOffsetTitle = <FormattedMessage id='pay.accountsReceivableOffset' />;
  // その他相殺(合計)
  const otherOffsetTitle = <FormattedMessage id='pay.otherOffset' />;
  // 支払修正額(合計)
  const payAdjustAmountAllTitle = <FormattedMessage id='pay.payAdjustAmount' />;
  // 差引お支払金額(合計)
  const balanceAmountPayTitle = <FormattedMessage id='pay.balanceAmountPay' />;

  const supplierOptions = useFields('fullSupplier');

  const [totalPaymentAmountValue, setTotalPaymentAmountValue] = useState(props.searchDto.totalPaymentAmount);
  const [lastMonthPayAdjustAmountAllValue, setLastMonthPayAdjustAmountAllValue] = useState(props.searchDto.lastMonthPayAdjustAmountAll)
  const [purchasePriceAllValue, setPurchasePriceAllValue] = useState(props.searchDto.purchasePriceAll)
  const [accountsReceivableOffsetValue, setAccountsReceivableOffsetValue] = useState(props.searchDto.accountsReceivableOffset)
  const [otherOffsetValue, setOtherOffsetValue] = useState(props.searchDto.otherOffset)
  const [payAdjustAmountAllValue, setPayAdjustAmountAllValue] = useState(props.searchDto.payAdjustAmountAll)
  const [balanceAmountPayValue, setBalanceAmountPayValue] = useState(props.searchDto.balanceAmountPay)

  return (
    <Form
      form={form}
      onFinish={handleSubmit}
      layout={'horizontal'}
      colon={false}
      size={'large'}
      labelCol={{ span: 6 }}
      wrapperCol={{ span: 16 }}
      >
      <Form.Item name='paymentId' label={paymentIdTitle}>
        <Input min={1} max={40} allowClear={true}/>  
      </Form.Item>
      <Form.Item name='suppliers' label={supplierCodeTitle}>
        <Select<Field>
          mode={'multiple'}
          className="select-mutil"
          allowClear={true}
          autoClearSearchValue={false}
          filterOption={filterUtil.selectFilter}
          onSearch={filterUtil.onSearchForm(form, 'suppliers', supplierOptions)}
          loading={supplierOptions.length === 0}
          options={supplierOptions} />
      </Form.Item>
      <Form.Item name='supplierName' label={supplierNameTitle} >
        <Input min={1} max={40}  allowClear={true}/>
      </Form.Item>      
      <Form.Item name='paymentDate' label={paymentDateTitle} >
        <RangePicker style={{ width: '100%' }} allowEmpty={[true, true]} allowClear={true} />
      </Form.Item>
      <Form.Item name='dateOfIssue' label={dateOfIssueTitle} >
        <RangePicker style={{ width: '100%' }} allowEmpty={[true, true]} allowClear={true} />
      </Form.Item>
      {/* 支払合計金額 */}
      <Row style={{marginBottom: '24px'}}>
        <Col span={6} style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end', paddingRight: '10px'}}>
          {totalPaymentAmountTitle}
        </Col>
        <Col span={16}>
          <NumberRangePicker values={totalPaymentAmountValue} onChange={record => setTotalPaymentAmountValue(record)} style={{ width: '100%' }} />
        </Col>
      </Row>
      {/* 前月支払修正額(合計) */}
      <Row style={{marginBottom: '24px'}}>
        <Col span={6} style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end', paddingRight: '10px'}}>
          {lastMonthPayAdjustAmountTitle}
        </Col>
        <Col span={16}>
          <NumberRangePicker values={lastMonthPayAdjustAmountAllValue} onChange={record => setLastMonthPayAdjustAmountAllValue(record)} style={{ width: '100%' }} />
        </Col>
      </Row>
      {/* 仕入代金(合計) */}
      <Row style={{marginBottom: '24px'}}>
        <Col span={6} style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end', paddingRight: '10px'}}>
          {purchasePriceAllTitle}
        </Col>
        <Col span={16}>
          <NumberRangePicker values={purchasePriceAllValue} onChange={record => setPurchasePriceAllValue(record)} style={{ width: '100%' }} />
        </Col>
      </Row>
      {/* 売掛金相殺(合計) */}
      <Row style={{marginBottom: '24px'}}>
        <Col span={6} style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end', paddingRight: '10px'}}>
          {accountsReceivableOffsetTitle}
        </Col>
        <Col span={16}>
          <NumberRangePicker values={accountsReceivableOffsetValue} onChange={record => setAccountsReceivableOffsetValue(record)} style={{ width: '100%' }} />
        </Col>
      </Row>
      {/* その他相殺(合計) */}
      <Row style={{marginBottom: '24px'}}>
        <Col span={6} style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end', paddingRight: '10px'}}>
          {otherOffsetTitle}
        </Col>
        <Col span={16}>
          <NumberRangePicker values={otherOffsetValue} onChange={record => setOtherOffsetValue(record)} style={{ width: '100%' }} />
        </Col>
      </Row>
      {/* 支払修正額(合計) */}
      <Row style={{marginBottom: '24px'}}>
        <Col span={6} style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end', paddingRight: '10px'}}>
          {payAdjustAmountAllTitle}
        </Col>
        <Col span={16}>
          <NumberRangePicker values={payAdjustAmountAllValue} onChange={record => setPayAdjustAmountAllValue(record)} style={{ width: '100%' }} />
        </Col>
      </Row>
      {/* 差引お支払金額 */}
      <Row style={{marginBottom: '24px'}}>
        <Col span={6} style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end', paddingRight: '10px'}}>
          {balanceAmountPayTitle}
        </Col>
        <Col span={16}>
          <NumberRangePicker values={balanceAmountPayValue} onChange={record => setBalanceAmountPayValue(record)} style={{ width: '100%' }} />
        </Col>
      </Row>
      {
        !user?.isSupplier &&
        <Form.Item name='readFlag' label={readFlagTitle} >
          <Select options={props.readFlagOptions} allowClear />
        </Form.Item>
      }
      <Form.Item style={{marginLeft: '20px'}}>
        <div className='search-button' style={{ paddingLeft: '20px' }}>
          <Button className='search-close-btn' onClick={props.close}>
            <FormattedMessage id='common.close' />
          </Button>
          <Button className='search-search-btn' htmlType='submit'>
            <FormattedMessage id='common.search' />
          </Button>
        </div>
      </Form.Item>
    </Form>
  );
};
