import { Dayjs } from 'dayjs';
import { DateRange } from '../common';
import { locales } from '../locales';

import { api, PagingSearchParaVo, CommonListVo, SearchParaPropertis, converter } from '../api'

const dayjs = require('dayjs');

// アクセスURI
const uri = '/api/deliveries/';

// ページ毎行数
const rowCount = 10;
//1. 納品書再印刷：検索
// ソート順項目定義、repositoryと一致
const orderByColumns = [
    // 仕入状态
    'deliveryStatus',
    // 着荷予定日
    'estimatedTimeArriva',
    // 保管場所 4位
    'locationCd',
    // 納品番号
    'deliveryNo',
    // 受注者コード
    'supplier',
    // 受注者名称
    'supplierName',
    // 保管場所code 3位
    'locationName',
    // 作成者
    'userName',
    // 作成日 
    'userModifiedDate'
];


const outputOrderByColumns = [
    // 受注者
    'supplier',
    // 保管場所
    'locationName',
    // 着荷予定日
    'estimatedTimeArriva',
    // 品目コード
    'item',
    // 品目名称
    'itemName',
    // 注文番号
    'orderNo'
];


// 検索条件Dtoの項目一覧
const properties: SearchParaPropertis = {
    // 仕入状态
    deliveryStatus: 'string',
    // 着荷予定日
    estimatedTimeArriva: 'DateRange',
    // 保管場所
    locationCd: 'string',
    // 納品番号
    deliveryNo: 'string',
    // 受注者コード
    supplier: 'array',
    // 受注者名称
    supplierName: 'string',
    // 受渡場所
    locationName: 'string',
    // 作成者
    userName: 'string',
    // 作成日 
    userModifiedDate: 'DateRange'
};

const outputProperties : SearchParaPropertis = {

    // 受注者
    suppliers: 'array',
    // 保管場所
    locationCd:'array',
    // 受渡場所
    locationName: 'string',
    // 着荷予定日
    estimatedTimeArriva:'DateRange',
    // 品目コード
    item:'array',
    // 品目名称
    itemName:'string',
    // 注文番号
    orderNo:'string'
}

export interface DeliveryOutputSearchDto extends PagingSearchParaVo{

    // 受注者
    suppliers: string[],
    // 保管場所
    locationCd: string[],
    // 受渡場所
    locationName?: string,
    // 着荷予定日
    estimatedTimeArriva:DateRange,
    // 品目コード
    item:string[],
    // 品目名称
    itemName?:string,
    // 注文番号
    orderNo?:string

}


// 納品検索条件Dto
export interface DeliverySearchDto extends PagingSearchParaVo {
    // 仕入状态
    deliveryStatus?: string;
    // 着荷予定日
    estimatedTimeArriva: DateRange;
    // 保管場所コード
    locationCd?: string;
    // 受渡場所
    locationName?: string;
    // 納品番号
    deliveryNo?: string;
    // 受注者コード
    supplier: string[];
    // 受注者名称
    supplierName?: string;
    // 作成者
    userName?: string;
    // 作成日 
    userModifiedDate: DateRange;
}
// 检索一览
export const get = (query: string): Promise<[number, DeliveryDto[]]> => {

    if (!query || query.length === 0) {
        query = `?rowCount=${rowCount}`;
    } else {
        query += `&rowCount=${rowCount}`;
    }

    return api.get(`${uri}${query}`)
        .then((result: any) => {

            const data = result as CommonListVo<DeliveryVo>;

            return [data.total, data.vos.map(vo2ListRecordDto)];

        })

}
// searchから検索条件Dtoへ変換する
export const search2Dto = (query?: string): DeliverySearchDto => {

    return converter.search2Dto(properties, orderByColumns, query) as DeliverySearchDto;
};


// deliveryOutput searchから検索条件Dtoへ変換する


export const deliveryOutputSearch2Dto = (query?: string): DeliveryOutputSearchDto => {

    return converter.search2Dto(outputProperties, outputOrderByColumns, query) as DeliveryOutputSearchDto;
};

// 検索条件Dtoからsearchへ変換する
export const dto2search = (researchDto: DeliverySearchDto): string => {

    return converter.dto2search(properties, orderByColumns, researchDto);
};


// DeliveryOutput検索条件Dtoからsearchへ変換する
export const outputDto2search = (researchDto: DeliveryOutputSearchDto): string => {

    return converter.dto2search(outputProperties, outputOrderByColumns, researchDto);
};

//2.納品再印刷：一覧処理
// 納品ヘッダー情報VOクラス
interface DeliveryVo {
    // ID
    id: number,
    // 納品書種類
    deliveryReportType: string,
    // 着荷予定日
    estimatedTimeArriva: number,
    // 保管場所
    locationCd: string;
    // 保管場所名称
    locationName: string;
    // 納品番号
    deliveryNo: string;
    // 発注者コード
    buyer: string;
    // 発注者名称
    buyerName: string;
    // 受注者コード
    supplier: string;
    // 受注者名称
    supplierName: string;
    // 担当者
    userName: string;
    // 作成日
    userModifiedDate: number;
    // 仕入状态
    deliveryStatus?: string;
    // バージョン
    version: number;
    // 納品詳細のVOクラス
    deliveryDetails?: DeliveryNoteVo[];
}
// 納品ヘッダー情報Dtoクラス
export interface DeliveryDto {
    // ID
    id: number,
    // 納品書種類
    deliveryReportType: string,
    // 着荷予定日
    estimatedTimeArriva: Dayjs,
    // 保管場所
    locationCd: string;
    // 保管場所名称
    locationName: string;
    // 納品番号
    deliveryNo: string;
    // 発注者コード
    buyer: string;
    // 発注者名称
    buyerName: string;
    // 受注者コード
    supplier: string;
    // 受注者名称
    supplierName: string;
    // 担当者
    userName: string;
    // 作成日
    userModifiedDate: Dayjs;
    // 仕入状态
    deliveryStatus?: string;
    // バージョン
    version: number;
    // 納品詳細のVOクラス
    deliveryDetails?: DeliveryNoteDto[];
}
// 削除処理
export const removeById = (id: number, version: number): Promise<any> => {

    return api.removeById(uri, id, { id: id, version: version });

};

// pdf
export const downloadEIAJApi = (id: number): Promise<any> => {

      const lang = locales.getLang();
    return api.downloadPDF(`${uri}downloadEIAJ/${lang}`, id).then(res => {
        let fileUuid = res.headers.get('content-disposition').split('filename=')[1].replace(new RegExp('"',"gm"),'').split('#')[0]
        let fileName = res.headers.get('content-disposition').split('filename=')[1].replace(new RegExp('"',"gm"),'').split('#')[1]
        res.blob().then((blob: Blob | MediaSource) => {
            
            const aLink = document.createElement('a');
            document.body.appendChild(aLink);
            aLink.style.display='none';
            const objectUrl = window.URL.createObjectURL(blob);
            aLink.href = objectUrl;
            aLink.download = fileName;
            aLink.click();
            document.body.removeChild(aLink);
          });
          api.deleteFile2(`${uri}deletefile/`, fileUuid + fileName)
    });
};

export const downloadDNnListApi = (id: number): Promise<any> => {
    const lang = locales.getLang();
    return api.downloadPDF(`${uri}downloadDNnList/${lang}`, id).then(res => {
        let fileUuid = res.headers.get('content-disposition').split('filename=')[1].replace(new RegExp('"',"gm"),'').split('#')[0]
        let fileName = res.headers.get('content-disposition').split('filename=')[1].replace(new RegExp('"',"gm"),'').split('#')[1]
        res.blob().then((blob: Blob | MediaSource) => {
            
            const aLink = document.createElement('a');
            document.body.appendChild(aLink);
            aLink.style.display='none';
            const objectUrl = window.URL.createObjectURL(blob);
            aLink.href = objectUrl;
            aLink.download = fileName;
            aLink.click();
            document.body.removeChild(aLink);
          });
          api.deleteFile2(`${uri}deletefile/`, fileUuid + fileName)
    });
};

//3.納品詳細
// 納品詳細のVOクラス
interface DeliveryNoteVo {
    // ID
    id: number;
    // 注文番号
    orderNo: string;
    // 注文明細番号
    detailNo: number;
    // 発注者
    supplier: string;
    // 発注者名称
    supplierName: string;
    // 受注者
    buyer: string;
    // 受注者名称
    buyerName: string;
    // 購買担当CD
    purchasingManager: string;
    // 購買担当名称
    purchasingManagerName: string;
    // 情報種類
    infoType: string;
    // 初期納期
    iniDeliveryDate: number;
    // 再日程日
    productionPlanDate: number;
    // 品番
    item: string;
    // 品名
    itemName: string;
    // 単位
    unit: string;
    // 単価
    unitPrice: number;
    // 通貨コード
    currencyCode: string;
    // 発注数量
    quantity: number;
    // 品質検査区分
    qualityCheckFlag: string;
    // 安全区分
    securityType: string;
    // 安全区分内容
    text: string;
    //データ作成日
    dataCreationDate: number;
    // 納品詳細ID
    deliveryDetailId: number;
    // 出荷日
    shipDate: number;
    // 着荷予定日
    estimatedTimeArriva: number;
    //出荷予定件数
    planDeliveryQuantity: number;
    // 出荷件数
    deliveryQuantity: number;
    // 備考
    remarks: string;
    // 保管場所
    locationCd: string;
    // 保管場所名称
    locationName: string;
    // バージョン
    version: number;
}

export interface DeliveryNoteDto {
    // ID
    id: number;
    // 注文番号
    orderNo: string;
    // 注文明細番号
    detailNo: number;
    // 発注者
    supplier: string;
    // 発注者名称
    supplierName: string;
    // 受注者
    buyer: string;
    // 受注者名称
    buyerName: string;
    // 購買担当CD
    purchasingManager: string;
    // 購買担当名称
    purchasingManagerName: string;
    // 情報種類
    infoType: string;
    // 初期納期
    iniDeliveryDate: number;
    // 再日程日
    productionPlanDate: number;
    // 品番
    item: string;
    // 品名
    itemName: string;
    // 単位
    unit: string;
    // 単価
    unitPrice: number;
    // 通貨コード
    currencyCode: string;
    // 発注数量
    quantity: number;
    // 品質検査区分
    qualityCheckFlag: string;
    // 安全区分
    securityType: string;
    // 安全区分内容
    text: string;
    //データ作成日
    dataCreationDate: Dayjs;
    // 納品詳細ID
    deliveryDetailId: number;
    // 出荷日
    shipDate: Dayjs;
    // 着荷予定日
    estimatedTimeArriva: Dayjs;
    //出荷予定件数
    planDeliveryQuantity: number;
    // 出荷件数
    deliveryQuantity: number;
    // 備考
    remarks: string;
    // 保管場所
    locationCd: string;
    // 保管場所名称
    locationName: string;
    // バージョン
    version: number;
}

export interface OutPutFormCondition {
    // 納品番号
    deliveryNo: string,
    // 納品書種類
    deliveryReportType: string
}
// 納品書明細情報取得
export const getById = (urlId: string): Promise<DeliveryDto> => {

    return api.get(`${uri}/${urlId}`)
        .then((result: any) => {

            const data = result as DeliveryVo;

            return vo2ListRecordDto(data);

        });
};

//4. 納品待ち一覧
//出荷処理Dto
export interface OutputForm {
    id: number,
    version: number,
    orderNo:string
}

//出庫待ち一覧取得
export const getUnprocessedDeliveryDetails = (query?: string): Promise<DeliveryNoteDto[]> => {

    if (!query || query.length === 0) {
        query = `?rowCount=${rowCount}`;
    } else if(query.indexOf('rowCount') === -1) {
        query += `&rowCount=${rowCount}`;
    }
    return api.get(`${uri}/unprocessed${query}`)
        .then((result: any) => {
            const data = result as DeliveryNoteVo[];
            return data.map(detailVo2ListRecordDto);
        });
};

//DeliveryNoはチェックがある
export const checkCountDeliveryNo = (dno: string): Promise<boolean> => {
    dno = encodeURIComponent(dno)
    return api.get(`${uri}/deliveryNoUnique?deliveryNo=${dno}`)
        .then((result: any) => {
            return result;
        })
};
//出荷処理
export const persist = (outputForm: OutputForm[], outPut: OutPutFormCondition) => {

    return api.persistNoReturn(uri, { deliveryDetails: outputForm, deliveryNo: outPut.deliveryNo, deliveryReportType: outPut.deliveryReportType })
        .then(() => {
            // const data = result as DeliveryVo;
            // return vo2ListRecordDto(data);
        }).catch(error => {
            console.error('Error during service worker registration:', error);
        });
}

const vo2ListRecordDto = (vo: DeliveryVo): DeliveryDto => {

    return {
        ...vo,
        estimatedTimeArriva: dayjs(vo.estimatedTimeArriva),
        userModifiedDate: dayjs(vo.userModifiedDate),
        deliveryDetails: vo.deliveryDetails?.map(detailVo2ListRecordDto)
    } as DeliveryDto
}

const detailVo2ListRecordDto = (vo: DeliveryNoteVo): DeliveryNoteDto => {

    return {
        ...vo,
        shipDate: dayjs(vo.shipDate),
        dataCreationDate: dayjs(vo.dataCreationDate),
        estimatedTimeArriva: dayjs(vo.estimatedTimeArriva)
    } as DeliveryNoteDto
}











