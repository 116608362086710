import { DateRange, NumberRange, } from '../common';
import { SearchParaPropertis, } from './types';

const dayjs = require('dayjs');

export const search2Dto = (properties: SearchParaPropertis, orderByColumns: string[], query?: string,): Object => {

  let object = { page: 1, rowCount: 10 } as any;

  for (let key of Object.keys(properties)) {
    const value = properties[key];

    if (value === 'DateRange') {
      object[key] = [null, null] as DateRange;
    } else if (value === 'array') {
      object[key] = [];
    } else if (value === 'NumberRange') {
      object[key] = [undefined, undefined] as NumberRange;
    }
  }

  if (!query || query.length === 0 || !query.startsWith('?')) {
    return object;
  }

  const pairs = query.substring(1).split('&');

  for (let item of pairs) {
    const pair = item.split('=');
    const pairKey = pair[0].toLocaleLowerCase();

    switch (pairKey) {
      case 'orderby':
        const index = parseInt(pair[1]);
        if (index >= 0 && index < orderByColumns.length) {
          object.sortField = orderByColumns[index];
        }
        break;
      case 'desc':
        object.sortOrder = pair[1] === 'true' ? 'descend' : 'ascend';
        break;
      case 'page':
        object.page = parseInt(pair[1]);
        break;
      case 'rowcount':
        object.rowCount = parseInt(pair[1]);
        break;
      default:
        for (let key of Object.keys(properties)) {
          const value = properties[key];

          if (value === 'string' && pairKey === key.toLocaleLowerCase()) {
            object[key] = decodeURIComponent(pair[1]);
          } else if (value === 'DateRange' && pairKey === `${key.toLocaleLowerCase()}from`) {
            object[key][0] = dayjs(parseInt(pair[1]));
          } else if (value === 'DateRange' && pairKey === `${key.toLocaleLowerCase()}to`) {
            object[key][1] = dayjs(parseInt(pair[1]));
          } else if (value === 'array' && pairKey === `${key.toLocaleLowerCase()}`) {
            object[key].push(decodeURIComponent(pair[1]));
          } else if (value === 'NumberRange' && pairKey === `${key.toLocaleLowerCase()}from`) {
            object[key][0] = parseFloat(pair[1]);
          } else if (value === 'NumberRange' && pairKey === `${key.toLocaleLowerCase()}to`) {
            object[key][1] = parseFloat(pair[1]);
          }
        }
    }
  }

  if (!!object.sortField && !object.sortOrder) {
    object.sortOrder = 'ascend';
  }

  return object;
};

export const dto2search = (properties: SearchParaPropertis, orderByColumns: string[], dto: any): string => {

  let query = '';

  for (let key of Object.keys(properties)) {
    const value = properties[key];

    if (value === 'string') {
      if (!!dto[key]) {
        query += `&${key}=${encodeURIComponent(dto[key])}`;
      }
    } else if (value === 'DateRange') {
      if (!!dto[key] && !!dto[key][0]) {
        query += `&${key}From=${dto[key][0].valueOf()}`;
      }
      if (!!dto[key] && !!dto[key][1]) {
        query += `&${key}To=${dto[key][1].valueOf()}`;
      }
    } else if (value === 'array') {
      if(dto[key]){
        for (let item of dto[key]) {
          query += `&${key}=${encodeURIComponent(item)}`;
        }
      }
    } else if (value === 'NumberRange') {
      if (!!dto[key] && dto[key][0] !== undefined && dto[key][0] !== null) {
        query += `&${key}From=${dto[key][0]}`;
      }
      if (!!dto[key] && dto[key][1] !== undefined && dto[key][1] !== null) {
        query += `&${key}To=${dto[key][1]}`;
      }
    }
  }

  if (!!dto.sortField && !!dto.sortOrder) {
    query += `&orderBy=${orderByColumns.indexOf(dto.sortField)}`;
    if (dto.sortOrder === 'descend') {
      query += '&desc=true';
    }
  }

  if (!!dto.page && dto.page > 1) {
    query += `&page=${dto.page}`;
  }

  if (dto.rowCount !== undefined && dto.rowCount !== 10) {
    query += `&rowCount=${dto.rowCount}`;
  }

  if (query.length > 0) {
    query = `?${query.substr(1)}`;
  }

  return query;
};
