import { RollbackOutlined, SearchOutlined, DeleteOutlined, DownloadOutlined, UploadOutlined } from "@ant-design/icons";
import { Button, Table, Popover, Modal } from "antd";
import { ColumnsType, SorterResult, TablePaginationConfig } from "antd/lib/table/interface";
import React, { Key, useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Link } from "react-router-dom";
import { constListScroll, constPageSizeOptions, DateRange, Filter, InputFilter, RangeFilter, SelectFilter } from "../common";
import { SelectFilterEnterable } from "../common/SelectFilter"
import { useFields } from "../fields";
import { arrayUtil, displayUtil, filterUtil } from '../util';
import { ModelDto, ModelSearchDto } from "./api";
import { useUser } from '../authorization';

export interface Props {
    loading: boolean;
    refresh: boolean;
    total: number;
    data: ModelDto[];
    searchDto: ModelSearchDto;
    openModelDelete: (deleteModel: ModelDto[]) => void;
    openSearchModal: () => void;
    handleResearch: (researchDto: ModelSearchDto) => void;
    handleSearch: (searchDto: ModelSearchDto) => void;
    openUpload: (url: 'upload') => () => void;
    download: () => void;
    downloadTemplate: () => void;
    reset: (rowCount: number) => void;
}
export function ModelList(props: Props) {

    const materials = useFields('material');
    const managements = useFields('management');
    const kinds = useFields('kinds');
    const deleteFlagOptions = useFields('deleteFlag');
    const primaryLenders = useFields('supplier');
    const fullSupplierOptions = useFields('fullSupplier')
    const items = useFields('item');
    const user = useUser();
    const intl = useIntl();
    const [rowCount, setRowCount] = useState<number>(10);

    // 状态来追踪选中的行
    const [selectedRowKeys, setSelectedRowKeys] = useState<Key[]>([]);
    const [selectedModel, setSelectedModel] = useState<ModelDto[]>([]);

    useEffect(() => {
        setSelectedModel([])
        setSelectedRowKeys([])
    }, [props.refresh])

    const handleTableChange = (pagination: TablePaginationConfig,
        filters: Record<string, (string | number | boolean)[] | null>,
        sorter: SorterResult<ModelDto> | SorterResult<ModelDto>[],
        extra: any) => {

        // 再検索の条件を作成する
        const researchDto: ModelSearchDto = { ...props.searchDto };
        let researchFlag = false;

        //材質の場合
        const { material: preMaterial } = researchDto;
        const { material } = filters;
        const preMaterials = preMaterial ? [preMaterial] : [];
        const curentMaterials = material ? material as string[] : [];

        if (!!pagination.pageSize && rowCount !== pagination.pageSize) {
            setRowCount(pagination.pageSize)
        }

        if (!arrayUtil.equals(curentMaterials, preMaterials)) {
            researchDto.material = curentMaterials[0];
            props.handleSearch(researchDto);
        }
        //管理の場合
        const { management: preManagement } = researchDto;
        const { management } = filters;
        const preManagements = preManagement ? [preManagement] : [];
        const curentManagements = management ? management as string[] : [];
        if (!arrayUtil.equals(curentManagements, preManagements)) {
            researchDto.management = curentManagements[0];
            props.handleSearch(researchDto);
        }
        //種類の場合
        const { kinds: preKind } = researchDto;
        const { kinds } = filters;
        const preKinds = preKind ? [preKind] : [];
        const curentKinds = kinds ? kinds as string[] : [];
        if (!arrayUtil.equals(curentKinds, preKinds)) {
            researchDto.kinds = curentKinds[0];
            props.handleSearch(researchDto);
        }

        //削除フラグの場合
        const { deleteFlag: preDeleteFlag } = researchDto;
        const { deleteFlag  } = filters;
        const preDeleteFlags = preDeleteFlag ? [preDeleteFlag] : [];
        const curentDeleteFlags= deleteFlag ? deleteFlag as string[] : [];
        if (!arrayUtil.equals(curentDeleteFlags, preDeleteFlags)) {
            researchDto.deleteFlag = curentDeleteFlags[0];
            props.handleSearch(researchDto);
        }

        // 共通検索条件変更を反映する
        if (!!pagination.pageSize && props.searchDto.rowCount !== pagination.pageSize) {
            researchDto.page = 1;
            researchDto.rowCount = pagination.pageSize;
            researchFlag = true;
        }
        else if (props.searchDto.page !== pagination.current) {
            researchDto.page = pagination.current;
            researchFlag = true;
        }

        const fixSorter = sorter as SorterResult<ModelDto>;
        if (props.searchDto.sortField !== fixSorter.field
            || props.searchDto.sortOrder !== fixSorter.order) {
            researchDto.sortField = fixSorter.field as string | undefined;
            researchDto.sortOrder = fixSorter.order;
            researchFlag = true;
        }

        if (researchFlag) {
            props.handleResearch(researchDto);
        }
    };

    const filterSelect1 = (itemTitle: 'primaryLenders' | 'item') =>
        (value: string[]) => {
            if (props.searchDto[itemTitle] !== value) {
                const researchDto: ModelSearchDto = { ...props.searchDto };
                researchDto[itemTitle] = value;
                researchDto.page = 1;
                props.handleResearch(researchDto);
            }
        };

    const filterInput = (itemTitle: 'assetNo' | 'auxiliaryNo' | 'moldNo' | 'modelName' | 'itemName' | 'primaryLenderName' | 'primaryLenderAddr' | 'finalLender' | 'finalLenderName' | 'finalLenderAddr' | 'finalLenderAddrCode' | 'fixedAssetRegistrationDecisionNumber') =>
        (value?: string) => {
            if (props.searchDto[itemTitle] !== value) {
                const researchDto: ModelSearchDto = { ...props.searchDto };
                researchDto[itemTitle] = value;
                researchDto.page = 1;
                props.handleResearch(researchDto);
            }
        };
    const filterRanger = (itemTitle: 'acquisitionDate') =>
        (value: DateRange) => {
            if (props.searchDto[itemTitle] !== value) {
                const researchDto: ModelSearchDto = { ...props.searchDto };
                researchDto[itemTitle] = value;
                researchDto.page = 1;
                props.handleResearch(researchDto);
            }
        };

    const sortOrder = (itemTitle: string): 'ascend' | 'descend' | null | undefined => {

        if (itemTitle === props.searchDto.sortField) {
            return props.searchDto.sortOrder;
        }
        return null;
    };

    //filter
    type filterType = Filter<ModelDto>;


    const assetNoFilter: filterType = {
        filterIcon: <SearchOutlined />,
        filterDropdown: <InputFilter value={props.searchDto.assetNo} onChange={filterInput('assetNo')} />,
        filteredValue: !props.searchDto.assetNo ? [] : [props.searchDto.assetNo],
    };
    const auxiliaryNoFilter: filterType = {
        filterIcon: <SearchOutlined />,
        filterDropdown: <InputFilter value={props.searchDto.auxiliaryNo} onChange={filterInput('auxiliaryNo')} />,
        filteredValue: !props.searchDto.auxiliaryNo ? [] : [props.searchDto.auxiliaryNo],
    };
    const moldNoFilter: filterType = {
        filterIcon: <SearchOutlined />,
        filterDropdown: <InputFilter value={props.searchDto.moldNo} onChange={filterInput('moldNo')} />,
        filteredValue: !props.searchDto.moldNo ? [] : [props.searchDto.moldNo],
    };
    const modelNameFilter: filterType = {
        filterIcon: <SearchOutlined />,
        filterDropdown: <InputFilter value={props.searchDto.modelName} onChange={filterInput('modelName')} />,
        filteredValue: !props.searchDto.modelName ? [] : [props.searchDto.modelName],
    };
    const itemFilter: filterType = {
        filterIcon: <SearchOutlined />,
        filterDropdown: <SelectFilterEnterable fields={items} values={props.searchDto.item} onChange={filterSelect1('item')} />,
        filteredValue: props.searchDto.item,
    };
    const itemNameFilter: filterType = {
        filterIcon: <SearchOutlined />,
        filterDropdown: <InputFilter value={props.searchDto.itemName} onChange={filterInput('itemName')} />,
        filteredValue: !props.searchDto.itemName ? [] : [props.searchDto.itemName],
    };

    const materialFilter: filterType = {
        filters: filterUtil.field2Fileter(materials), filterMultiple: false,
        filteredValue: !props.searchDto.material ? [] : [props.searchDto.material],
        onFilter: (value: any, record: ModelDto) => record.material === value
    };
    const managementFilter: filterType = {
        filters: filterUtil.field2Fileter(managements), filterMultiple: false,
        filteredValue: !props.searchDto.management ? [] : [props.searchDto.management],
        onFilter: (value: any, record: ModelDto) => record.management === value
    };
    const kindsFilter: filterType = {
        filters: filterUtil.field2Fileter(kinds), filterMultiple: false,
        filteredValue: !props.searchDto.kinds ? [] : [props.searchDto.kinds],
        onFilter: (value: any, record: ModelDto) => record.kinds === value
    };
    const primaryLenderAddrFilter: filterType = {
        filterIcon: <SearchOutlined />,
        filterDropdown: <InputFilter value={props.searchDto.primaryLenderAddr} onChange={filterInput('primaryLenderAddr')} />,
        filteredValue: !props.searchDto.primaryLenderAddr ? [] : [props.searchDto.primaryLenderAddr],
    };
    const finalLenderFilter: filterType = {
        filterIcon: <SearchOutlined />,
        filterDropdown: <InputFilter value={props.searchDto.finalLender} onChange={filterInput('finalLender')} />,
        filteredValue: !props.searchDto.finalLender ? [] : [props.searchDto.finalLender],
    }; const finalLenderNameFilter: filterType = {
        filterIcon: <SearchOutlined />,
        filterDropdown: <InputFilter value={props.searchDto.finalLenderName} onChange={filterInput('finalLenderName')} />,
        filteredValue: !props.searchDto.finalLenderName ? [] : [props.searchDto.finalLenderName],
    };
    const finalLenderAddrFilter: filterType = {
        filterIcon: <SearchOutlined />,
        filterDropdown: <InputFilter value={props.searchDto.finalLenderAddr} onChange={filterInput('finalLenderAddr')} />,
        filteredValue: !props.searchDto.finalLenderAddr ? [] : [props.searchDto.finalLenderAddr],
    };
    const finalLenderAddrCodeFilter: filterType = {
        filterIcon: <SearchOutlined />,
        filterDropdown: <InputFilter value={props.searchDto.finalLenderAddrCode} onChange={filterInput('finalLenderAddrCode')} />,
        filteredValue: !props.searchDto.finalLenderAddrCode ? [] : [props.searchDto.finalLenderAddrCode],
    };
    const primaryLendersFilter: filterType = {
        filterIcon: <SearchOutlined />,
        filterDropdown: <SelectFilter fields={fullSupplierOptions} values={props.searchDto.primaryLenders} onChange={filterSelect1('primaryLenders')} />,
        filteredValue: props.searchDto.primaryLenders,
    };
    const acquisitionDateFilter: filterType = {
        filterIcon: <SearchOutlined />,
        filterDropdown: <RangeFilter values={props.searchDto.acquisitionDate} onChange={filterRanger('acquisitionDate')} />,
        filteredValue: filterUtil.range2stringArray(props.searchDto.acquisitionDate),
    };
    const deleteFlagFilter: filterType = {
        filters: filterUtil.field2Fileter(deleteFlagOptions), filterMultiple: false,
        filteredValue: !props.searchDto.deleteFlag ? [] : [props.searchDto.deleteFlag],
        onFilter: (value: any, record: ModelDto) => record.deleteFlag === value
    };
    const fixedAssetRegistrationDecisionNumberFilter: filterType = {
        filterIcon: <SearchOutlined />,
        filterDropdown: <InputFilter value={props.searchDto.fixedAssetRegistrationDecisionNumber} onChange={filterInput('fixedAssetRegistrationDecisionNumber')} />,
        filteredValue: !props.searchDto.fixedAssetRegistrationDecisionNumber ? [] : [props.searchDto.fixedAssetRegistrationDecisionNumber],
    };

    const assetNoLink = (text: any, record: ModelDto, index: number) => {
        return <Link to={`/models/${record.id}`}>{record.assetNo}</Link>;
    };

    const itemRender = (text: any, record: ModelDto, index: number) => {
        let div: JSX.Element[] = []
        record.items.forEach((i) => { div.push(<div>{i}</div>) });
        return div;
    }
    
    const assetFlowApplyCodeRender = (text: any, record: ModelDto, index: number) => {
        let div: JSX.Element[] = []
        record?.assetFlowApplyCodes?.forEach((i) => { div.push(<div>{i}</div>) });
        return div;
    }

    const fileRender = (text: any, record: ModelDto, index: number) => {
        return record?.attachments?.length > 0 ? <FormattedMessage id='model.haveAttachments' /> : <FormattedMessage id='model.notHaveAttachments' />
    }

    // タイトル
    // 資産番号
    const assetNoTitle = <FormattedMessage id='model.assetNo' />
    // 補助番号
    const auxiliaryNoTitle = <FormattedMessage id='model.auxiliaryNo' />
    // 金型品番
    const moldNoTitle = <FormattedMessage id='model.moldNo' />
    // 機種名
    const modelNameTitle = <FormattedMessage id='model.modelName' />
    // 品番
    const itemTitle = <FormattedMessage id='model.item' />
    // 品名
    const itemNameTitle = <FormattedMessage id='model.itemName' />
    // 材質
    const materialTitle = <FormattedMessage id='model.material' />
    // 管理
    const managementTitle = <FormattedMessage id='model.management' />
    // 種別
    const kindsTitle = <FormattedMessage id='model.kinds' />
    // 一次預け先コード
    const primaryLendersTitle = <FormattedMessage id='model.primaryLender' />
    // 一次預け先名称
    const primaryLenderNameTitle = <FormattedMessage id='model.primaryLenderName' />
    // 一次預け先住所
    const primaryLenderAddrTitle = <FormattedMessage id='model.primaryLenderAddr' />
    // 最終預け先コード
    const finalLenderTitle = <FormattedMessage id='model.finalLender' />
    // 最終預け先名称
    const finalLenderNameTitle = <FormattedMessage id='model.finalLenderName' />
    // 最終預け先住所
    const finalLenderAddrTitle = <FormattedMessage id='model.finalLenderAddr' />
    // 取得年月日
    const acquisitionDateTitle = <FormattedMessage id='model.acquisitionDate' />
    // 最終預け先住所コード
    const finalLenderAddrCodeTitle = <FormattedMessage id='model.finalLenderAddrCode' />;
    // 削除フラグ
    const deleteFlagTitle = <FormattedMessage id='model.deleteFlag' />;
    // 固定資産登録決裁番号
    const fixedAssetRegistrationDecisionNumberTitle = <FormattedMessage id='model.fixedAssetRegistrationDecisionNumber' />;
    // 添付ファイル有無
    const haveOrNotHaveAttachmentsTitle = <FormattedMessage id='model.haveOrNotHaveAttachments' />;
    // 資産流動申請番号
    const assetFlowApplyCodesTitle = <FormattedMessage id='model.assetFlowApplyCode' />;

    const width120 = '120px';
    const width150 = '150px';
    const width170 = '170px';
    const width180 = '180px';
    const width200 = '200px';
    const width240 = '240px';

    const columns: ColumnsType<ModelDto> = [
        // 資産番号
        {
            title: assetNoTitle,
            width: width180,
            dataIndex: 'assetNo',
            ...assetNoFilter,
            render: assetNoLink,
            sorter: true,
            sortOrder: sortOrder('assetNo'),
        },
        // 補助番号
        {
            title: auxiliaryNoTitle,
            width: width150,
            dataIndex: 'auxiliaryNo',
            ...auxiliaryNoFilter,
        },
        // 金型品番
        {
            title: moldNoTitle,
            width: width240,
            dataIndex: 'moldNo',
            ...moldNoFilter,
            sorter: true,
            sortOrder: sortOrder('moldNo'),
        },
        // 機種名
        {
            title: modelNameTitle,
            width: width150,
            dataIndex: 'modelName',
            ...modelNameFilter,
            sorter: true,
            sortOrder: sortOrder('modelName'),
            ellipsis: true
        },
        // 品番
        {
            title: itemTitle,
            width: width240,
            dataIndex: 'item',
            ...itemFilter,
            render: itemRender,
            sorter: true,
            sortOrder: sortOrder('item'),
            ellipsis: true
        },
        // 品名
        {
            title: itemNameTitle,
            width: width150,
            dataIndex: 'itemName',
            ...itemNameFilter,
            sorter: true,
            sortOrder: sortOrder('itemName'),
            ellipsis: true
        },
        // 材質
        {
            title: materialTitle,
            width: width120,
            dataIndex: 'material',
            render: displayUtil.field(materials),
            ...materialFilter,
            sorter: true,
            sortOrder: sortOrder('material'),
            ellipsis: true
        },
        // 管理
        {
            title: managementTitle,
            width: width150,
            dataIndex: 'management',
            render: displayUtil.field(managements),
            ...managementFilter,
            sorter: true,
            sortOrder: sortOrder('management'),
            ellipsis: true
        },
        // 種別
        {
            title: kindsTitle,
            width: width150,
            dataIndex: 'kinds',
            render: displayUtil.field(kinds),
            ...kindsFilter,
            sorter: true,
            sortOrder: sortOrder('kinds'),
            ellipsis: true
        },
        // 一次預け先コード
        {
            title: primaryLendersTitle,
            width: width200,
            dataIndex: 'primaryLender',
            ...primaryLendersFilter,
            sorter: true,
            sortOrder: sortOrder('primaryLender'),
        },
        // 一次預け先名称
        {
            title: primaryLenderNameTitle,
            width: width200,
            dataIndex: 'primaryLender',
            // ...primaryLenderNameFilter,
            render: displayUtil.field(primaryLenders),
            // sorter: true,
            // sortOrder: sortOrder('primaryLenderName'),
            ellipsis: true
        },
        // 一次預け先住所
        {
            title: primaryLenderAddrTitle, width: width200,
            dataIndex: 'primaryLenderAddr',
            ...primaryLenderAddrFilter,
            sorter: true, sortOrder: sortOrder('primaryLenderAddr'),
            ellipsis: true
        },
        // 最終預け先コード
        {
            title: finalLenderTitle, width: width170,
            dataIndex: 'finalLender',
            ...finalLenderFilter,
            sorter: true, sortOrder: sortOrder('finalLender'),
        },
        // 最終預け先名称
        {
            title: finalLenderNameTitle, width: width200,
            dataIndex: 'finalLenderName',
            ...finalLenderNameFilter,
            sorter: true, sortOrder: sortOrder('finalLenderName'),
            ellipsis: true
        },
        // 最終預け先住所
        {
            title: finalLenderAddrTitle, width: width200,
            dataIndex: 'finalLenderAddr',
            ...finalLenderAddrFilter,
            sorter: true, sortOrder: sortOrder('finalLenderAddr'),
            ellipsis: true
        },
        
        // 最終預け先住所コード
        {
            title: finalLenderAddrCodeTitle, width: width240,
            dataIndex: 'finalLenderAddrCode',
            sorter: true, sortOrder: sortOrder('finalLenderAddrCode'),
            ...finalLenderAddrCodeFilter,
            ellipsis: true
        },
        // 取得年月日
        {
            title: acquisitionDateTitle, width: width200,
            dataIndex: 'acquisitionDate',
            ...acquisitionDateFilter,
            sortOrder: sortOrder('acquisitionDate'),
            sorter: true, render: displayUtil.date
        },
        // 削除フラグ
        {
            title: deleteFlagTitle, width: width150,
            dataIndex: 'deleteFlag',
            render: displayUtil.field(deleteFlagOptions),
            sorter: true, sortOrder: sortOrder('deleteFlag'),
            ...deleteFlagFilter,
            ellipsis: true
        },
        // 固定資産登録決裁番号
        {
            title: fixedAssetRegistrationDecisionNumberTitle, width: width240,
            dataIndex: 'fixedAssetRegistrationDecisionNumber',
            sorter: true, sortOrder: sortOrder('fixedAssetRegistrationDecisionNumber'),
            ...fixedAssetRegistrationDecisionNumberFilter,
            ellipsis: true
        },
        // 資産流動申請番号
        {
            title: assetFlowApplyCodesTitle, width: width240,
            dataIndex: 'assetFlowApplyCodes',
            render: assetFlowApplyCodeRender,
            ellipsis: true
        },
        // 添付ファイル有無
        {
            title: haveOrNotHaveAttachmentsTitle, width: width150,
            dataIndex: 'attachments',
            render: fileRender,
        },
    ];

    const paginationProps: TablePaginationConfig = {
        current: props.searchDto.page,
        total: props.total,
        defaultPageSize: props.searchDto.rowCount,
        position: ['bottomLeft'],
        showSizeChanger: true,
        pageSizeOptions: constPageSizeOptions,
    }
    const title = () => {
        return (
            <>
                <FormattedMessage id='model.list' />
                <Button onClick={props.openSearchModal} style={{ marginLeft: 10, marginRight: 10 }} >
                    <SearchOutlined />
                    <FormattedMessage id='common.conditions' />
                </Button>
                <Button onClick={props.download} style={{ marginLeft: 10, marginRight: 10 }} >
                    <DownloadOutlined />
                    <FormattedMessage id='common.download' />
                </Button>
                <Button onClick={props.openUpload('upload')} style={{ marginLeft: 10, marginRight: 10 }} >
                    <UploadOutlined />
                    <FormattedMessage id='model.upload' />
                </Button>
                {!user?.isSupplier &&
                    <div style={{ display: 'inline-block' }}>
                        <Button onClick={props.downloadTemplate} style={{ margin: '0 0 0 10px', marginRight: 10 }}>
                            <DownloadOutlined />
                            <FormattedMessage id='supplierEstimates.templateDownload' />
                        </Button>
                    </div>
                }
                {selectedModel.length > 0 ? 
                    <>
                        <Button onClick={ () => { handleOpenModelDelete(selectedModel)} } style={{ marginLeft: 10, marginRight: 10 }} >
                            <DeleteOutlined />
                            <FormattedMessage id='model.deleteSelected' />
                        </Button>
                    </>
                    :
                    <>
                        <Popover placement="top" content={<FormattedMessage id='model.deleteMustSelectFirst' />}>
                            <Button disabled={true} style={{ marginLeft: 10, marginRight: 10 }} >
                                <DeleteOutlined />
                                <FormattedMessage id='model.deleteSelected' />
                            </Button>
                        </Popover>
                    </>
                }

                <Button className='reset-btn' onClick={ () => {props.reset(rowCount)} }>
                    <RollbackOutlined />
                    <FormattedMessage id='common.reset' />
                </Button>
            </>
        )
    };

    const handleOpenModelDelete = (selectedModels: ModelDto[]) => {
        if (selectedModels.every(selectedModel => selectedModel.deleteFlag !== '1')) {
            props.openModelDelete(selectedModels)
        } else {
            Modal.error({
                title: intl.formatMessage({id: 'message.canNotDeleteDeletedItem'}), // 削除フラグ立てているため削除できません
                // content: <>{selectedModels.filter(selectedModel => selectedModel.deleteFlag === '1').map(selectedDeletedModel => <>{selectedDeletedModel?.assetNo?.trim()}-{selectedDeletedModel?.auxiliaryNo}<br/></>).concat()}</>, // 削除フラグが 1 の資産番号
                okText: intl.formatMessage({id: 'common.close'}), // 閉じる
            });
        }
    }

    const rowSelectionProps = {
        fixed: false,
        selectedRowKeys: selectedRowKeys,
        onChange: (selectedRowKeys: Key[], selectedRows: ModelDto[]) => {
            setSelectedRowKeys(selectedRowKeys)
            setSelectedModel(selectedRows)
        }
    }

    return (
        <Table<ModelDto>
            className="list-table"
            rowSelection={rowSelectionProps}
            dataSource={props.data}
            columns={columns}
            pagination={paginationProps}
            loading={props.loading}
            rowClassName={displayUtil.rowClassNameModelHightLight}
            rowKey={'id'}
            tableLayout={'fixed'}
            title={title}
            onChange={handleTableChange}
            scroll={constListScroll}
        />
    );
}

