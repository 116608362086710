import React, { useState } from 'react';
import { FormattedMessage, } from 'react-intl';
import { Button, Modal, Spin } from 'antd';

import './index.css';

import { CommonExcelUpload, CommonExcelUploadProps, RowData, UnitValidator } from '../common';
import { ModelUploadDto } from './api';

export interface Props {
  visible: boolean;
  upload: (data: ModelUploadDto[]) => Promise<any>;
  uploading: boolean;
  close: () => void;
}

export function ModelUpload(props: Props) {


  const [dtos, setDtos] = useState<ModelUploadDto[]>([]);

  const validate = (rowDatas: RowData[]) => {

    const errors: string[] = [];

    const dtos = rowDatas.map(
      (rowData: RowData) => {

        const dto: ModelUploadDto = { ...rowData, } as unknown as ModelUploadDto;

        // 購入先コード
        // if (!!rowData['supplier'] && (rowData['supplier'] as string).length > 0) {
        //   const supplier = toKey(suppliers, rowData['supplier'] as string);
        //   if (!supplier) {
        //     errors.push(`資産番号：${rowData['assetNo']}の購入先コードが対象外となりました。`);
        //   } else {
        //     dto.supplier = supplier;
        //   }
        // }

        return dto;
      }
    );

    if (errors.length > 0) {
      throw errors;
    }

    return dtos;
  };

  const assetNoValidators: UnitValidator[] = [{
    validate: (value: any) => {
      if (!!value) {
        const assetNo = value as string;
        if (assetNo.length > 15) {
          return true;
        }
      }
      return false;
    }, message: 'message.excelAssetNo',
  }];

  const auxiliaryNoValidators: UnitValidator[] = [{
    validate: (value: any) => {
      if (value !== undefined) {
        const auxiliaryNo = value as number
        if (auxiliaryNo < 0 || auxiliaryNo > 99 || auxiliaryNo % 1 > 0) {
          return true;
        }
      }
      return false;
    }, message: 'message.excelAuxiliaryNo',
  }];

  const costCenterNoValidators: UnitValidator[] = [{
    validate: (value: any) => {
      if (value !== undefined) {
        if (value.length > 10) {
          return true;
        }
      }
      return false;
    }, message: 'message.excelCostCenterNo',
  }];

  const uploadProps: CommonExcelUploadProps = {
    row: {
      columns: [
        // 資産番号
        { key: 'model.assetNo', field: 'assetNo', type: 'string', isRequired: true, validators: assetNoValidators, },
        // 補助番号
        { key: 'model.auxiliaryNo', field: 'auxiliaryNo', type: 'int', isRequired: true, validators: auxiliaryNoValidators, },
        // 購入先コード
        { key: 'model.text', field: 'text', type: 'string', isRequired: false, validators: [], },
        // 取得年月日
        { key: 'model.acquisitionDate', field: 'acquisitionDate', type: 'date', isRequired: false, validators: [], },
        // 原価センター番号
        { key: 'model.costCenter', field: 'costCenterNo', type: 'string', isRequired: false, validators: costCenterNoValidators, },
        // 原価センター名
        { key: 'model.costCenterName', field: 'costCenterName', type: 'string', isRequired: false, validators: [], },
      ],
      uniqueItems: ['assetNo', 'auxiliaryNo'],
    },
    validate: validate,
    upload: (data: ModelUploadDto[]) => {
      setDtos(data);
    },
  };

  const upload = () => {
    props.upload(dtos).then(() => {
      setDtos([])
      props.close();
    })
  }

  return (
    <Modal
      title={<FormattedMessage id='model.upload' />}
      width='1080px'
      footer={null}
      visible={props.visible}
      bodyStyle={{ paddingBottom: 80 }}
      destroyOnClose={true}
      onCancel={props.close}
    >
      <Spin
        size='default'
        spinning={props.uploading}
      >
        <CommonExcelUpload {...uploadProps} />
      </Spin>
      <div className='search-button upload-buttom'>
        <Button className='search-close-btn' onClick={props.close}>
          <FormattedMessage id='common.cancel' />
        </Button>
        <Button className='search-search-btn' type='primary' onClick={upload} disabled={dtos.length === 0}>
          <FormattedMessage id='common.upload' />
        </Button>
      </div>
    </Modal >
  );
}

