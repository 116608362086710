import React from 'react';
import { Drawer, Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { FormattedMessage, useIntl } from 'react-intl';
import { displayUtil } from '../util';
import './index.css';
import { BatchRecordDto } from './api'

export interface Props {
  disable: boolean;
  detailData?: BatchRecordDto[];
  handleClose: () => void;
}

export function HomeBatchDetail(props: Props) {

  const intl = useIntl();



  //Batch name
  const batchNameRander = (text: any, record: BatchRecordDto, index: number) => {
    return intl.formatMessage({id: `batch.${record.batchName}`});
  };


  const batchDetailTitle = <FormattedMessage id='batch.batchDetail' />;

  const batchNameTitle = <FormattedMessage id='batch.batchName' />;
  const batchInsertQuantityTitle = <FormattedMessage id='batch.newDataQuantity' />;
  const batchUpdateQuantityTitle = <FormattedMessage id='batch.updateDataQuantity' />;
  const batchSkipQuantityTitle = <FormattedMessage id='batch.skipDataQuantity' />;
  const batchErrorQuantityTitle = <FormattedMessage id='batch.errorDataQuantity' />;
  const batchDataImportTimeTitle = <FormattedMessage id='batch.dataImportTime' />;


  const width150 = '160px';
  const width130 = '130px';
  const width100 = '100px';

  const column: ColumnsType<BatchRecordDto> = [
    {
      title: batchNameTitle,
      dataIndex: "batchName",
      width: width100,
      render: batchNameRander,
      ellipsis: true,
    },
    {
      title: batchInsertQuantityTitle,
      dataIndex: "batchInsertCount",
      width: width130,
      ellipsis: true,
    },
    {
      title: batchUpdateQuantityTitle,
      dataIndex: "batchUpdateCount",
      width: width130,
      ellipsis: true,
    },
    {
      title: batchErrorQuantityTitle,
      dataIndex: "batchErrorCount",
      width: width130,
      ellipsis: true,
    },
    {
      title: batchDataImportTimeTitle,
      dataIndex: "batchRunTime",
      render: displayUtil.datetime,
      width: width150,
    },
  ]


  return (
    <Drawer
      title={batchDetailTitle}
      width={1280}
      footer={null}
      visible={props.disable}
      destroyOnClose={true}
      onClose={props.handleClose}>
        <Table<BatchRecordDto>
          className="list-detail-table"
          rowClassName={displayUtil.rowClassName} // 表格隔行变色
          sticky={false}
          pagination={false}
          scroll={{ y: '60vh' }}
          columns={column}
          dataSource={props.detailData}
          rowKey={'id'}
        />
    </Drawer >
  );
}
