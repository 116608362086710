import { UploadOutlined } from '@ant-design/icons';
import { Button, Form, Modal, Select, Upload } from 'antd';
import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useFields } from '../fields';
import { EstimateRequestUpdateDto } from './api';

export interface Props {
    visible: boolean;
    detail?:EstimateRequestUpdateDto;
    close: () => void;
    handleSubmit: (uploadEstimate: EstimateRequestUpdateDto) => void;
}

export function EstimationUpload(props: Props) {

    const [form1] = Form.useForm<EstimateRequestUpdateDto>();
        useEffect(() => {
            if(!!props.detail){
                return form1.setFieldsValue(props.detail);
            }
        });
    
     //供应商
    const supplierOptions = useFields('supplier');
    return (
        <Modal
            title={<FormattedMessage tagName='h4' id='common.search' />}
            footer={null}
            visible={props.visible}
            closable={false}
            afterClose={props.close}>
            <Form
                form={form1}
                onFinish={props.handleSubmit}
                layout={'horizontal'}
                colon={false}
                size={'large'}
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 12 }}>
                <Form.Item name='supplierName' 
                    label={<FormattedMessage id='estimate.buyerDepartment' /> }
                    rules={[{required:true, message:''}, {max:60, message:''}]}>
                    { <Select options={supplierOptions} />} 
                </Form.Item>
                <Form.Item name='estiamtes' 
                    label={<FormattedMessage id='estimate.requestInfo' />}>
                    <Upload >
                        <Button icon={<UploadOutlined />}>Click to Upload</Button>
                     </Upload >
                </Form.Item>
               <Form.Item name='itemName' 
                    label={<FormattedMessage id='estimate.requestInfo' />}>
                    <Upload >
                        <Button icon={<UploadOutlined />}>Click to Upload</Button>
                     </Upload >
                </Form.Item>
               {/*   <Form.Item name='itemName' label={<FormattedMessage id='estimate.requestInfo' />}>
                    <Upload >
                        <Button icon={<UploadOutlined />}>Click to Upload</Button>
                     </Upload >
                </Form.Item> */}
                <Form.Item>
                    <div className='search-button'>
                        <Button className='search-close-btn' onClick={props.close}>
                            <FormattedMessage id='common.close' />
                        </Button>
                        <Button className='search-search-btn' type='primary' htmlType='submit'>
                            <FormattedMessage id='estimate.requestInfo' />
                        </Button>
                    </div>
                </Form.Item>
            </Form>
        </Modal>
    );
};



