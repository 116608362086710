import React from 'react';
import { FormattedMessage, } from 'react-intl';
import { Drawer, Divider, Table, Col, Row } from 'antd';
import { ColumnsType } from 'antd/lib/table';

import { Detail } from '../common'

import { PayDetailDto, PaymentDetailTableVo } from './api'
import './index.css';
import { displayUtil } from '../util';

export interface Props {
  visible: boolean;
  detail?: PayDetailDto;
  close: () => void;
}


export function PayDetail(props: Props) {
  // 発注者情報：
  const orderingPartyCols: [JSX.Element, any][] = [
    // 支払コード
    [<FormattedMessage id='pay.paymentId' />, props.detail?.paymentId],
    // 取引年月
    [<FormattedMessage id='pay.acquisitionDate' />, displayUtil.month(props.detail?.acquisitionDate)],
    // 発行日
    [<FormattedMessage id='pay.dateOfIssue' />, displayUtil.date(props.detail?.dateOfIssue)],
    // 発注者コード
    [<FormattedMessage id='pay.buyer' />, props.detail?.buyer],
    // 発注者名称
    [<FormattedMessage id='pay.buyerName' />, props.detail?.buyerName],
    // 発注者部署名
    [<FormattedMessage id='pay.departmentName' />, props.detail?.departmentName],
  ];
  // 受注者情報：
  const contractorCols: [JSX.Element, any][] = [
    // 郵便番号
    [<FormattedMessage id='pay.zipCode' />, props.detail?.zipCode],
    // 受注者名称
    [<FormattedMessage id='pay.supplierName' />, props.detail?.supplierName],
    // 受注者住所
    [<FormattedMessage id='pay.supplierAddress' />, props.detail?.supplierAddress],
    // FAX
    [<FormattedMessage id='pay.fax' />, props.detail?.fax],
    // 仕入先コード
    [<FormattedMessage id='pay.supplierCode' />, props.detail?.supplier],
  ];


  // 支払合計：
  const paymentCols: [JSX.Element, any][] = [
    // 支払日
    [<FormattedMessage id='pay.paymentDate' />, props.detail?.paymentUponEachUseFlag === '1' ? <FormattedMessage id='pay.paymentUponEachUse' /> : displayUtil.date(props.detail?.paymentDate)],
    // 支払合計金額
    [<FormattedMessage id='pay.totalPaymentAmount' />, displayUtil.currency(props.detail?.currencyCode, props.detail?.totalPaymentAmount),],
    // 通貨コード
    [<FormattedMessage id='pay.currencyCode' />, props.detail?.currencyCode],
  ];

  const title = (
    <>
      <FormattedMessage id='pay.detail' />
      {/* <Button className='download-btn'>
        <FilePdfOutlined style={{ color: 'red' }} />
        <FormattedMessage id='common.download' />
      </Button> */}
    </>
  )
  // 税率テキスト
  const taxRateTitle = <FormattedMessage id='pay.taxRate' />
  const taxRateWidth = '80px';

  // 項目
  const itemsTitle = <FormattedMessage id='pay.items' />
  const itemsWidth = '80px';
  // 前月支払修正額
  const lastMonthPayAdjustAmountTitle = <FormattedMessage id='pay.lastMonthPayAdjustAmount' />
  const lastMonthPayAdjustAmountWidth = '110px';
  //仕入代金
  const purchasePriceTitle = <FormattedMessage id='pay.purchasePrice' />
  const purchasePriceWidth = '90px';
  // 売掛金相殺
  const accountsReceivableOffsetTitle = <FormattedMessage id='pay.accountsReceivableOffset' />
  const accountsReceivableOffsetWidth = '90px';
  // その他相殺
  const otherOffsetTitle = <FormattedMessage id='pay.otherOffset' />
  const otherOffsetWidth = '90px';
  // 支払修正額
  const payAdjustAmountTitle = <FormattedMessage id='pay.payAdjustAmount' />
  const payAdjustAmountWidth = '90px';
  // 差引お支払金額
  const balanceAmountPayTitle = <FormattedMessage id='pay.balanceAmountPay' />
  const balanceAmountPayWidth = '110px';

  //金額フォーマット変換
  const showLastMonthPayAdjustAmount = (text: any, record: PaymentDetailTableVo, index: number) => {
    return displayUtil.currency(record.currencyCode, record.lastMonthPayAdjustAmount)
  };
  const showPurchasePrice = (text: any, record: PaymentDetailTableVo, index: number) => {
    return displayUtil.currency(record.currencyCode, record.purchasePrice)
  };
  const showAccountsReceivableOffset = (text: any, record: PaymentDetailTableVo, index: number) => {
    return !record.accountsReceivableOffset ? "" : displayUtil.currency(record.currencyCode, record.accountsReceivableOffset)

  };
  const showOtherOffset = (text: any, record: PaymentDetailTableVo, index: number) => {
    return !record.otherOffset ? "" : displayUtil.currency(record.currencyCode, record.otherOffset)
  };
  const showPayAdjustAmount = (text: any, record: PaymentDetailTableVo, index: number) => {
    return displayUtil.currency(record.currencyCode, record.payAdjustAmount)
  };
  const showBalanceAmountPay = (text: any, record: PaymentDetailTableVo, index: number) => {
    return !record.balanceAmountPay ? "" : displayUtil.currency(record.currencyCode, record.balanceAmountPay)
  };

  const detailColumns: ColumnsType<PaymentDetailTableVo> = [
    // 税率テキスト
    { title: taxRateTitle, dataIndex: 'taxRate', width: taxRateWidth },
    // 項目
    { title: itemsTitle, dataIndex: 'items', width: itemsWidth },
    // 前月支払修正額
    { title: lastMonthPayAdjustAmountTitle, dataIndex: 'lastMonthPayAdjustAmount', width: lastMonthPayAdjustAmountWidth, render: showLastMonthPayAdjustAmount },
    // 仕入代金
    { title: purchasePriceTitle, dataIndex: 'purchasePrice', width: purchasePriceWidth, render: showPurchasePrice },
    // 売掛金相殺
    { title: accountsReceivableOffsetTitle, dataIndex: 'accountsReceivableOffset', width: accountsReceivableOffsetWidth, render: showAccountsReceivableOffset },
    // その他相殺
    { title: otherOffsetTitle, dataIndex: 'otherOffset', width: otherOffsetWidth, render: showOtherOffset },
    // 支払修正額
    { title: payAdjustAmountTitle, dataIndex: 'payAdjustAmount', width: payAdjustAmountWidth, render: showPayAdjustAmount },
    // 差引お支払金額
    { title: balanceAmountPayTitle, dataIndex: 'balanceAmountPay', width: balanceAmountPayWidth, render: showBalanceAmountPay }

  ]

  return (
    <Drawer
      title={title}
      width='1180px'
      footer={null}
      visible={props.visible}
      bodyStyle={{  paddingTop: 0,}}
      destroyOnClose={true}
      onClose={props.close}>
      <Detail
        title={<FormattedMessage id='pay.contracor' />}
        cols={orderingPartyCols}
      />
      <Divider className="divider" />
      <Detail
        title={
          <>
            <FormattedMessage id='pay.order' />
            {
              props.detail?.qualifiedInvoiceIssuerRegistrationNumber && // 適格請求書発行事業者登録番号は存在しない場合、タイトルも値も表示されない
              <>
                <span style={{fontSize: 15, marginLeft: '20px'}}>
                  <FormattedMessage id='pay.qualifiedInvoiceIssuerRegistrationNumber' /> {props.detail?.qualifiedInvoiceIssuerRegistrationNumber}
                </span>
              </>
            }
          </>
        }
        cols={contractorCols}
      />
      <Divider className="divider" />
      <Detail
        title={<FormattedMessage id='pay.payment' />}
        cols={paymentCols}
      />
      <Row className='payment-note'>
        <Col span={20} offset={0}>{props.detail?.note1_1}</Col>
        <Col span={20} offset={0}>{props.detail?.note1_2}</Col>
      </Row>
      <Table<PaymentDetailTableVo>
        className="list-detail-table"
        columns={detailColumns}
        dataSource={props.detail?.paymentDetailTableVos}
        bordered={false}
        size={'small'}
        rowKey={'id'}
        tableLayout={'fixed'}
        pagination={false}
      />
      <Row className='payment-note'>
        <Col span={1} offset={0} >注）</Col>
        <Col span={20} offset={0}>
          {/* 不要删除下方 note3 和 note7 前的全角空格 */}
          <Row>{props.detail?.note2}</Row>
          <Row>　　{props.detail?.note3}</Row>
          <Row>{props.detail?.note4}</Row>
          <Row>{props.detail?.note5}</Row>
          <Row>{props.detail?.note6}</Row>
          <Row>　　{props.detail?.note7}</Row>
          <Row>{props.detail?.note8}</Row>
          <Row>{props.detail?.note9}</Row>
        </Col>
      </Row>
    </Drawer >

  );
}
