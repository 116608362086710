import { Card, Button, Col, Drawer, Row, Table, Popconfirm, } from 'antd';
import { FileExcelOutlined, SearchOutlined } from '@ant-design/icons';

import { ColumnsType } from 'antd/lib/table';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { EstimateRequestDetailListDto, EstimateReponseDto } from './api'
import { displayUtil } from '../util';
import { Link } from 'react-router-dom';
import { useFields } from '../fields';
// import { CommonFileUpload } from '../common/CommonFileUpload';
import { CommonFileDisplay } from '../common/CommonFileDisplay'
import "./index.css"
import TextArea from 'antd/lib/input/TextArea';

export interface Props {
  visible: boolean;
  detail?: EstimateRequestDetailListDto;
  close: () => void;
  openRequestDetailModal: () => void;
  requestClose: () => void;
  cancel: () => void;
}

export function EstimationDetail(props: Props) {

  //案件状態
  const statusOptions = useFields('estimateStatus');
  //案件状態
  const responseStatusOptions = useFields('responseStatus');
  //供应商
  const supplierOptions = useFields('supplier');

  const buyerDepartmentOptions = useFields('buyerDepartment');

  const estimatesTypeOptions = useFields('estimatesType');
  //金额类型
  // const disireCurrencyOptions = useFields('currency');

  // const showDesireUnitPrice = (detail?: EstimateRequestDetailListDto) => {

  //   if (!!detail?.desireCurrency) {
  //     let label;
  //     disireCurrencyOptions.forEach(d => {
  //       if (d.value === detail.desireCurrency.trim()) {
  //         label = d.label;
  //       }
  //     })
  //     return !detail?.desireUnitPrice ? "" : displayUtil.currency(label, detail?.desireUnitPrice)
  //   }
  // };


  const fileDownloadProps = {
    attachments: !!props.detail?.attachments ? props.detail.attachments : [],
    canUpload: false,
    persist: () => { },
    remove: () => { },
  };


  //見積依頼編集画面依頼項目
  const estimationCols1: [any, any, any][] = [
    //案件状態
    [<FormattedMessage id='estimate.status' />, displayUtil.field(statusOptions)(props.detail?.status), ""],
    //入札依頼番号
    [<FormattedMessage id='estimate.requestNo' />, props.detail?.requestNo, ""],
    //見積依頼日
    [<FormattedMessage id='estimate.requestDate' />, displayUtil.date(props.detail?.requestDate), ""],
    //発注部門
    [<FormattedMessage id='estimate.buyerDepartment' />, displayUtil.field(buyerDepartmentOptions)(props.detail?.buyerDepartmentCode), ""],
    //購買担当
    [<FormattedMessage id='estimate.purchasingManager' />, props.detail?.purchasingManagerName, ""],
    //見積タイプ
    [<FormattedMessage id='estimate.estimatesType' />, displayUtil.field(estimatesTypeOptions)(props.detail?.estimatesType), ""],
    //品番
    [<FormattedMessage id='estimate.item' />, props.detail?.item, ""],
    //品名
    [<FormattedMessage id='estimate.itemName' />, props.detail?.itemName, ""],
    //回答希望日
    [<FormattedMessage id='estimate.answerDeadline' />, displayUtil.date(props.detail?.answerDeadline), ""],
    //企画数量
    [<FormattedMessage id='estimate.planQuantity' />, props.detail?.planQuantity, ""],
    //企画月数
    [<FormattedMessage id='estimate.planningMonths' />, props.detail?.planningMonths, ""],
    //希望単価
    [<FormattedMessage id='estimate.desireUnitPrice' />, props.detail?.desireUnitPrice, ""],
    //希望単価通貨
    [<FormattedMessage id='estimate.desireCurrency' />, props.detail?.desireCurrency, ""],
    //月発注予定数
    [<FormattedMessage id='estimate.monthlyPlanOrderQuantity' />, props.detail?.monthlyPlanOrderQuantity, ""],


  ]
  const cols = [];
  for (let col of estimationCols1) {
    cols.push(<Col span={3} style={{ textAlign: 'right', fontWeight: 'bold', }}>{col[0]}</Col>);
    cols.push(<Col span={3} style={{ textDecorationLine: 'underline' }}>{col[1]}</Col>);
    cols.push(<Col span={2} style={{ textDecorationLine: 'underline' }}>{col[2]}</Col>);
  }
  //見積依頼編集画面依頼項目
  const estimationCols2: [JSX.Element, any, any][] = [

    //受渡場所
    [<FormattedMessage id='estimate.deliveryLocation' />, props.detail?.deliveryLocation, ""],
    //備考
    [<FormattedMessage id='estimate.remarks' />, <TextArea value={props.detail?.remarks} bordered={false} disabled id={'remarkTextArea'} autoSize={{ maxRows: 3 }} style={{ padding: '0', color: 'rgba(0, 0, 0, 0.85)', textDecoration: 'underline', cursor: 'text' }}/>, ""],
    //図面・仕様書
    // [<FormattedMessage id='' />, <Upload itemRender={}><Button icon={<UploadOutlined />}>Upload</Button></Upload>,""],
    //サプライヤー
    //    [<FormattedMessage id='' />, <SelectFilter fields={supplierOptions} values={filterExistSupplier(props.detail?.suppliers)} onChange={function (values: string[]): void {
    //      throw new Error('Function not implemented.');
    //    } } />,""],
    //ユーザーID
    [<FormattedMessage id='estimate.userName' />, props.detail?.userName, ""],
    //ユーザー名称
    [<FormattedMessage id='estimate.modifiedTime' />, displayUtil.date(props.detail?.modifiedTime), ""],

    [<FormattedMessage id='estimate.upload' />, <CommonFileDisplay  {...fileDownloadProps} />, ""],
  ]
  for (let col of estimationCols2) {
    cols.push(<Col span={3} style={{ textAlign: 'right', fontWeight: 'bold', }}>{col[0]}</Col>);
    cols.push(<Col span={5} style={{ textDecorationLine: 'underline' }}>{col[1]}</Col>);
  }

  // 見積有効日
  const estimatedEffectiveDateTitle = <FormattedMessage id='estimate.estimatedEffectiveDate' />;
  // リードタイム
  const leadTimeTitle = <FormattedMessage id='estimate.leadTime' />;
  // メーカー名
  const makerNameTitle = <FormattedMessage id='estimate.makerName2' />;
  // メーカー品番
  const makerItemTitle = <FormattedMessage id='estimate.makerItem2' />;
  // 部品種別
  const itemTypeCodeTitle = <FormattedMessage id='estimate.itemTypeCode2' />;
  // 最低発注数量
  const moqTitle = <FormattedMessage id='estimate.moq' />;
  // 最小梱包数量
  const mpqTitle = <FormattedMessage id='estimate.mpq' />;
  // 単価
  const unitPriceTitle = <FormattedMessage id='estimate.unitPrice' />;
  // 単価通貨コード
  const unitCurrencyTitle = <FormattedMessage id='estimate.currency' />;
  // 型費
  const moldCostTitle = <FormattedMessage id='estimate.cost' />;
  // 型通貨コード
  const moldCurrencyTitle = <FormattedMessage id='estimate.currency' />;
  // 型の種類
  const moldTypeTitle = <FormattedMessage id='estimate.type' />;
  // 型の工程数
  const moldProcessNumTitle = <FormattedMessage id='estimate.processNum' />;
  // 型の面数
  const moldNumTitle = <FormattedMessage id='estimate.moldsNum' />;
  // 見積適用開始日
  const estimateStartDateTitle = <FormattedMessage id='estimate.estimateStartDate' />;
  // 見積適用終了日
  const estimateEndDateTitle = <FormattedMessage id='estimate.estimateEndDate' />;
  // 回答状態
  const responseStatusTitle = <FormattedMessage id='estimate.responseStatus' />;
  // 購買先
  const supplierTitle = <FormattedMessage id='estimate.supplier' />;
  // 購買先名称
  const supplierNameTitle = <FormattedMessage id='estimate.supplierName' />;
  // 更新者
  const usernameTitle = <FormattedMessage id='common.userName' />;
  // 更新时间
  const modifiedTimeTitle = <FormattedMessage id='common.userModifiedDate' />;

  // // 部品情報
  // const itemInfoTitle = <FormattedMessage id='estimate.itemInfo' />;
  // // 金型情報
  // const moldInfoTitle = <FormattedMessage id='estimate.moldInfo' />;
  // // メーカー情報
  // const makerInfoTitle = <FormattedMessage id='estimate.makerInfo' />;

  //金額変換
  const showUnitPrice = (text: any, record: EstimateReponseDto, index?: number) => {
    console.log(record);
    return !record.unitPrice ? "" : displayUtil.currency(record.unitCurrency, record.unitPrice)
  };
  const showMoldCost = (text: any, record: EstimateReponseDto, index?: number) => {
    return !record.moldCost ? "" : displayUtil.currency(record.moldCurrency, record.moldCost)
  };

  const width250 = '250px';
  const width200 = '200px';
  const width160 = '160px';
  const width140 = '140px';
  const width120 = '120px';
  const width100 = '100px';

  const supplierLink = (text: any, record: EstimateReponseDto, index: number) => {
    let random = Math.round(Math.random() * 100000);
    return <Link to={`/estimations/detail/${random}/${record.requestId}/${record.respondentsId}`}>{record.supplier}</Link>;
  };

  const columns: ColumnsType<EstimateReponseDto> = [
    //回答状態
    { title: responseStatusTitle, width: width100, dataIndex: 'responseStatus', render: displayUtil.field(responseStatusOptions) },
    //購買先
    { title: supplierTitle, width: width120, dataIndex: 'supplier', render: supplierLink },
    //購買名称
    { title: supplierNameTitle, width: width200, dataIndex: 'supplier', render: displayUtil.field(supplierOptions), ellipsis: true },
    // 見積有効日
    {
      title: estimatedEffectiveDateTitle, width: width140,
      dataIndex: 'estimatedEffectiveDate', render: displayUtil.date
    },
    // メーカー名
    { title: makerNameTitle, width: width160, dataIndex: 'makerName', ellipsis: true },
    // メーカー品番
    { title: makerItemTitle, width: width250, dataIndex: 'makerItem', ellipsis: true },
    // 部品種別
    { title: itemTypeCodeTitle, width: width100, dataIndex: 'itemTypeCode' },
    // 最低発注数量
    { title: moqTitle, width: width100, dataIndex: 'moq'},
    // 最小梱包数量
    { title: mpqTitle, width: width100, dataIndex: 'mpq' },
    // リードタイム
    { title: leadTimeTitle, width: width100, dataIndex: 'leadTime'},
    // 単価
    { title: unitPriceTitle, width: width140, dataIndex: 'unitPrice', render: showUnitPrice},
    // 単価通貨コード
    { title: unitCurrencyTitle, width: width120, dataIndex: 'unitCurrency'},
    // 型の種類
    { title: moldTypeTitle, width: width140, dataIndex: 'moldType'},
    // 型費
    { title: moldCostTitle, width: width140, dataIndex: 'moldCost', render: showMoldCost },
    // 型通貨コード
    { title: moldCurrencyTitle, width: width120, dataIndex: 'moldCurrency'},
    // 型の工程数
    { title: moldProcessNumTitle, width: width100, dataIndex: 'moldProcessNum'},
    // 型の面数
    { title: moldNumTitle, width: width100, dataIndex: 'moldNum' },
    // 見積適用開始日
    { title: estimateStartDateTitle, width: width140, dataIndex: 'estimateStartDate', render: displayUtil.date },
    // 見積適用終了日
    { title: estimateEndDateTitle, width: width140, dataIndex: 'estimateEndDate', render: displayUtil.date },
    // 更新者
    { title: usernameTitle, width: width140,dataIndex: 'userName'},
    // 更新日
    { title: modifiedTimeTitle, width: width140, dataIndex: 'modifiedTime', render: displayUtil.date },
  ];
  const title = () => {
    return (
      <>
        <FormattedMessage id='estimate.requestInfo' />
        <Button onClick={props.openRequestDetailModal} className={'bottom-left-padding'} disabled={props.detail?.status !== '001'}>
          <SearchOutlined />
          <FormattedMessage id='estimate.requestEdit' />
        </Button>
        <Popconfirm
          title={<FormattedMessage id='message.finishConfirm' />}
          onConfirm={props.requestClose}
          okText={<FormattedMessage id='common.confirm' />}
          cancelText={<FormattedMessage id='common.cancel' />} 
          disabled={props.detail?.status !== '001'} >
          <Button className={'bottom-left-padding'} disabled={props.detail?.status !== '001'}>
            <FormattedMessage id='estimate.requestClose' />
          </Button>
        </Popconfirm>
        <Popconfirm
          title={<FormattedMessage id='message.cancelConfirm' />}
          onConfirm={props.cancel}
          okText={<FormattedMessage id='common.confirm' />}
          cancelText={<FormattedMessage id='common.cancel' />} 
          disabled={props.detail?.status !== '001'}>
          <Button className={'bottom-left-padding'} disabled={props.detail?.status !== '001'}>
            <FileExcelOutlined />
            <FormattedMessage id='estimate.cancel' />
          </Button>
        </Popconfirm>
      </>
    )
  };
  return (
    <>
      <Drawer
        title={<FormattedMessage id='estimate.requestDetail' />}
        width='1380px'
        footer={null}
        visible={props.visible}
        bodyStyle={{ paddingBottom: 80 }}
        destroyOnClose={true}
        onClose={props.close}
        zIndex={1}>
        <Card bordered={false} title={title()}>
          <Row gutter={[12, 12]}>
            {cols}
          </Row>
        </Card>
        <Card bordered={false} title={<FormattedMessage id='estimate.reponseList' />}>
          {<Table<EstimateReponseDto>
            id="estimeation-detail-table"
            className={"list-detail-table"}
            // className="ant-table"
            pagination={false}
            columns={columns}
            dataSource={props.detail?.estimateReponseDtos}
            rowClassName={displayUtil.rowClassName}
            rowKey={'id'}
            tableLayout={'fixed'}
            scroll={{ x: 1900, y: 400 }}
          />}
        </Card>
      </Drawer >
    </>
  )
}