import React, { useCallback, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button, Select, Table, Modal } from 'antd';
import { HistoryOutlined, RollbackOutlined, SearchOutlined, DownloadOutlined } from '@ant-design/icons';
import { TablePaginationConfig } from 'antd/es/table';
import { ColumnsType } from 'antd/lib/table';
import { SorterResult } from 'antd/es/table/interface';

import { displayUtil, filterUtil, } from '../util'
import { InputFilter, RangeFilter, Filter, DateRange, SelectFilter, constListScroll, constPageSizeOptions, } from '../common'
import { useFields } from '../fields';

import { ForecastSearchDto, ForecastListRecordDto, } from './api'
import './index.css';
import { Dayjs } from 'dayjs';
import { useUser } from '../authorization';

type OptionType = {
  value: number;
  label: any;
};

export interface Props {
  loading: boolean;
  nullForecastFlag: boolean;
  searchDto: ForecastSearchDto;
  total: number;
  data: ForecastListRecordDto[];
  forecastDateOptions: OptionType[];
  correctForecastOptionIndex: number;
  historyDate?: Dayjs;
  download: () => void;
  openSearchModal: () => void;
  handleResearch: (researchDto: ForecastSearchDto) => void;
  goHistory: (historyDate: number) => void;
  reset: (rowCount: number) => void;
}

export function ForecastList(props: Props) {

  const user = useUser();
  const intl = useIntl();
  const fullSupplierOptions = useFields('fullSupplier')
  const itemOptions = useFields('item');
  const [rowCount, setRowCount] = useState<number>(10);
  // ページング、フィルター、ソート順の処理
  const handleTableChange = (pagination: TablePaginationConfig,
    filters: Record<string, (string | number | boolean)[] | null>,
    sorter: SorterResult<ForecastListRecordDto> | SorterResult<ForecastListRecordDto>[],
    extra: any) => {

    // 再検索の条件を作成する
    const researchDto: ForecastSearchDto = { ...props.searchDto };
    let researchFlag = false;
    if (!!pagination.pageSize && rowCount !== pagination.pageSize) {
      setRowCount(pagination.pageSize)
    }

    if (!!pagination.pageSize && props.searchDto.rowCount !== pagination.pageSize) {
      researchDto.page = 1;
      researchDto.rowCount = pagination.pageSize;
      researchFlag = true;
    }
    else if (props.searchDto.page !== pagination.current) {
      researchDto.page = pagination.current;
      researchFlag = true;
    }

    const fixSorter = sorter as SorterResult<ForecastListRecordDto>;
    if (props.searchDto.sortField !== fixSorter.field
      || props.searchDto.sortOrder !== fixSorter.order) {
      researchDto.sortField = fixSorter.field as string | undefined;
      researchDto.sortOrder = fixSorter.order;
      researchFlag = true;
    }

    if (researchFlag) {
      props.handleResearch(researchDto);
    }
  };

  const popNoForecastError = useCallback(() => {
    Modal.error({
        title: intl.formatMessage({ id: 'common.modalErrorTitle' }),
        content: intl.formatMessage({ id: 'message.noForecastError' }),
        okText: intl.formatMessage({ id: 'common.close' })
    });
  }, [intl])

  useEffect(() => {
    if (!!props.nullForecastFlag && props.nullForecastFlag === true) {
      popNoForecastError()
    }
  }, [popNoForecastError, props.nullForecastFlag])

  const filterSelect = (itemTitle: 'items' | 'suppliers') =>
    (value: string[]) => {
      if (props.searchDto[itemTitle] !== value) {
        const researchDto: ForecastSearchDto = { ...props.searchDto };
        researchDto[itemTitle] = value;
        researchDto.page = 1;
        props.handleResearch(researchDto);
      }
    };

  const filterInput = (itemTitle: 'itemName' | 'supplierName') =>
    (value?: string) => {
      if (props.searchDto[itemTitle] !== value) {
        const researchDto: ForecastSearchDto = { ...props.searchDto };
        researchDto[itemTitle] = value;
        researchDto.page = 1;
        props.handleResearch(researchDto);
      }
    };
  const filterRanger = (itemTitle: 'iniDeliveryDate') =>
    (value: DateRange) => {
      if (props.searchDto[itemTitle] !== value) {
        const researchDto: ForecastSearchDto = { ...props.searchDto };
        researchDto[itemTitle] = value;
        researchDto.page = 1;
        props.handleResearch(researchDto);
      }
    };

  const sortOrder = (itemTitle: string): 'ascend' | 'descend' | null | undefined => {

    if (itemTitle === props.searchDto.sortField) {
      return props.searchDto.sortOrder;
    }

    return null;
  };

  type filterType = Filter<ForecastListRecordDto>;
  // MRP処理日
  const planningDateTitle = <FormattedMessage id='forecast.planningDate' />;
  // 発注者名称
  const buyerNameTitle = <FormattedMessage id='forecast.buyerName' />;
  // 受注者コード
  const supplierTitle = <FormattedMessage id='forecast.supplier' />;
  // 受注者名称
  const supplierNameTitle = <FormattedMessage id='forecast.supplierName' />;
  // 品番
  const itemcodeTitle = <FormattedMessage id='forecast.item' />;
  // 品名
  const itemNameTitle = <FormattedMessage id='forecast.itemName' />;
  // 数量
  const quantityTitle = <FormattedMessage id='forecast.quantity' />;
  // 納品予定日
  const iniDeliveryDateTitle = <FormattedMessage id='forecast.iniDeliveryDate' />;
  // 納入予定月
  const iniDeliveryMonthTitle = <FormattedMessage id='forecast.iniDeliveryMonth' />;
  // 購買担当
  const purchasingManagerTitle = <FormattedMessage id='forecast.purchasingManager' />;

  const supplierNameFilter: filterType = {
    filterIcon: <SearchOutlined />,
    filterDropdown: <InputFilter value={props.searchDto.supplierName} onChange={filterInput('supplierName')} />,
    filteredValue: !props.searchDto.supplierName ? [] : [props.searchDto.supplierName],
  };
  const suppliersFilter: filterType = {
    filterIcon: <SearchOutlined />,
    filterDropdown: <SelectFilter fields={fullSupplierOptions} values={props.searchDto.suppliers} onChange={filterSelect('suppliers')} />,
    filteredValue: props.searchDto.suppliers,
  };
  const itemsFilter: filterType = {
    filterIcon: <SearchOutlined />,
    filterDropdown: <SelectFilter fields={itemOptions} values={props.searchDto.items} onChange={filterSelect('items')} />,
    filteredValue: props.searchDto.items,
  };
  const itemNameFilter: filterType = {
    filterIcon: <SearchOutlined />,
    filterDropdown: <InputFilter value={props.searchDto.itemName} onChange={filterInput('itemName')} />,
    filteredValue: !props.searchDto.itemName ? [] : [props.searchDto.itemName],
  };
  const iniDeliveryDateFilter: filterType = {
    filterIcon: <SearchOutlined />,
    filterDropdown: <RangeFilter values={props.searchDto.iniDeliveryDate} onChange={filterRanger('iniDeliveryDate')} />,
    filteredValue: filterUtil.range2stringArray(props.searchDto.iniDeliveryDate),
  };

  const width100 = '90px';
  const width120 = '120px';
  const width140 = '140px';
  const width160 = '160px';
  const width240 = '240px';
  const width260 = '260px';


  const array = [
    // 発注者コード
    // {
    //   title: buyerTitle, width: width140,
    //   dataIndex: 'buyer',
    // },
    // 発注者名称
    {
      title: buyerNameTitle, width: width160,
      dataIndex: 'buyerName',
      ellipsis: true,
    },
    // 受注者コード
    {
      title: supplierTitle, width: width140,
      dataIndex: 'supplier',
      sorter: true, sortOrder: sortOrder('supplier'),
      ...suppliersFilter
    },
    // 受注者名称
    {
      title: supplierNameTitle, width: width260,
      dataIndex: 'supplierName',
      sorter: true, sortOrder: sortOrder('supplierName'),
      ellipsis: true,
      ...supplierNameFilter
    },
    //　MPR処理日
    {
      title: planningDateTitle, width: width120,
      dataIndex: 'planningDate',
      render: displayUtil.date
    },
    // //MRP処理日
    // {
    //   title: dataCreationDateTitle, width: width100,
    //   dataIndex: 'dataCreationDate',
    //   render: displayUtil.date
    // },
    //  // 計画番号
    //   {
    //     title: planNumberTitle, width: width100,
    //     dataIndex: 'planNo',

    //   },
    // // 明細番号
    // {
    //   title: detailNoTitle,width: width100,
    //   dataIndex: 'detailNo',
    // },
    // 品目コード
    {
      title: itemcodeTitle, width: width240,
      dataIndex: 'item',
      sorter: true, sortOrder: sortOrder('item'),
      ...itemsFilter
    },
    // 品名
    {
      title: itemNameTitle, width: width140,
      dataIndex: 'itemName',
      sorter: true, sortOrder: sortOrder('itemName'),
      ellipsis: true,
      ...itemNameFilter
    },

    // 納入予定月
    {
      title: iniDeliveryMonthTitle, width: width140,
      dataIndex: 'iniDeliveryDate',
      render: displayUtil.month
    },
    // 納入予定日
    {
      title: iniDeliveryDateTitle, width: width160,
      dataIndex: 'iniDeliveryDate',
      render: displayUtil.date,
      sorter: true, sortOrder: sortOrder('iniDeliveryDate'), ...iniDeliveryDateFilter
    },
    // 数量
    {
      title: quantityTitle, width: width100,
      dataIndex: 'quantity1'

    },
    // 購買担当
    {
      title: purchasingManagerTitle, width: width120,
      dataIndex: 'purchasingManagerName',
      ellipsis: true
    },
  ];

  const columns: ColumnsType<ForecastListRecordDto> = user?.isSupplier ? array
    : array.filter(item => item.dataIndex !== 'buyer' && item.dataIndex !== 'buyerName');

  const paginationProps: TablePaginationConfig = {
    current: props.searchDto.page,
    total: props.total,
    defaultPageSize: props.searchDto.rowCount,
    position: ['bottomLeft'],
    showSizeChanger: true,
    pageSizeOptions: constPageSizeOptions,
  }


  const title = () => (
    <>
      {!!props.historyDate ? <><FormattedMessage id='forecast.list' /></> : <FormattedMessage id='forecast.list' />}
      <Button onClick={props.openSearchModal} style={{ marginLeft: 10, marginRight: 10 }} >
        <SearchOutlined />
        <FormattedMessage id='common.conditions' />
      </Button>
      <Button onClick={props.download} style={{ marginLeft: 10, marginRight: 10 }} >
        <DownloadOutlined />
        <FormattedMessage id='common.download' />
      </Button>
      <Button className='reset-btn' onClick={() => { props.reset(rowCount); }}>
        <RollbackOutlined />
        <FormattedMessage id='common.reset' />
      </Button>
      <HistoryOutlined />
      <FormattedMessage id='common.history' />
      <Select
        value={[props.forecastDateOptions[props.correctForecastOptionIndex].value, props.forecastDateOptions[props.correctForecastOptionIndex].label]}
        onChange={(value) => props.goHistory(value as unknown as number)}
        options={props.forecastDateOptions}
        style={{ width: 130, marginLeft: 10, marginRight: 10 }} />
          
      
    </>)

  return (
    <Table<ForecastListRecordDto>
      className="list-table"
      pagination={paginationProps}
      columns={columns}
      dataSource={props.data}
      loading={props.loading}
      rowClassName={displayUtil.rowClassName}
      rowKey={'id'}
      tableLayout={'fixed'}
      title={title}
      onChange={handleTableChange}
      scroll={constListScroll}
    />
  );
}
