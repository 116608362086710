import { Attachment, DateRange } from '../common';
import { api, CommonListVo, converter, PagingSearchParaVo, SearchParaPropertis } from '../api'
import { Dayjs } from 'dayjs';
import { locales } from '../locales';

const dayjs = require('dayjs');

// アクセスURI
const uri = '/api/document';

// ページ毎行数
const rowCount = 10;

//1. 検索一覧画面
// ソート順項目定義、repositoryと一致
const orderByColumns = [
  "documentCategory",
  "title",
  "sendTime",
  "replyFlag",
  "supplier"
]

// 検索条件Dtoの項目一覧
const properties: SearchParaPropertis = {
  documentCategory: 'array',
  title: 'string',
  sendTime: 'DateRange',
  replyFlag: 'array',
   // 受注者コード
   suppliers: 'array',
   // 受注者名称
   supplierName: 'string',
}

//検索条件DTO
export interface DocSearchDto extends PagingSearchParaVo {
  documentCategory: string[];
  title?: string;
  sendTime: DateRange;
  replyFlag: string[];
  suppliers:string[];

}
//一覧VO
interface DocListRecorVo extends DocSentVo,DocReplyVo {
  // 发送数量  送信先数
  sendQuantity: number;
  // 接收数量  受信件数
  readQuantity: number;
  // 回复数量  返信件数
  replyQuantity: number;

  // 详细画面ID
  detailId: number;
  // 受注者  
  supplier: string;
  //  返信ユーザー名称
  replyUserName: string;
  //　返信フラグ
  replyFlag: string;
  // 未读フラグ
  unReadFlag: boolean;
  // details
  details: DocReplyVo[];
  // replyMessage
  replyMessage: string;
  // acceptFlag
  acceptFlag: string;
}
//一覧DTO
export interface DocListRecordDto extends DocSentDto,DocReplyDto{
  // 发送数量  送信先数
  sendQuantity: number;
  // 接收数量  受信件数
  acceptQuantity: number;
  // 回复数量  返信件数
  replayQuantity: number;

  // 返信ID
  detailId: number;
  // 受注者  
  supplier: string;
  //  返信ユーザー名称
  replyUserName: string;
  //　返信フラグ
  replyFlag: string;
  // 未读フラグ
  unReadFlag: boolean;
  // details
  details: DocReplyDto[];
  // replyMessage
  replyMessage: string;
  // acceptFlag
  acceptFlag: string;
  // uniqueDocKey 用于标识唯一列的key
  uniqueDocKey: string;

}
// searchから検索条件Dtoへ変換する
export const search2Dto = (query?: string): DocSearchDto => {

  return converter.search2Dto(properties, orderByColumns, query) as DocSearchDto;
};

// 検索条件Dtoからsearchへ変換する
export const dto2search = (researchDto: any): string => {

  const dto = researchDto as DocSearchDto
  if(typeof(dto.documentCategory)=='string'){
    dto.documentCategory = [dto.documentCategory]
  }
  if(typeof(dto.replyFlag)=='string'){
    dto.replyFlag =[dto.replyFlag]
  }
  return converter.dto2search(properties, orderByColumns, researchDto);
};
//一覧DTO⇒VO
const vo2ListRecordDto = (vo: DocListRecorVo): DocListRecordDto => {
  return {
    ...vo,
    acceptQuantity: vo.readQuantity,
    replayQuantity: vo.replyQuantity,
    details: vo.details && vo.details.map(detail => detailVo2Dto(detail)),
    sendTime: vo.sendTime === 0?null:dayjs(vo.sendTime),
    replyTime: vo.replyTime === 0 ? null : dayjs(vo.replyTime),
    modifiedDate: vo.modifiedDate === 0 ? null : dayjs(vo.modifiedDate),
    uniqueDocKey: vo.id + '-' + vo.detailId 
    
  } as DocListRecordDto;
};
//一覧VO⇒DTO
const listRecordDto2Vo = (dto: DocListRecordDto): DocListRecorVo => {
  return {
    ...dto,
    readQuantity: dto.acceptQuantity,
    replyQuantity: dto.replayQuantity,
    details: dto.details && dto.details.map(detail => detailDto2Vo(detail)),
    sendTime: !!dto.sendTime ? dto.sendTime.valueOf() : 0,
    replyTime: !!dto.replyTime ? dto.replyTime.valueOf() : 0,
    modifiedDate: !!dto.modifiedDate ? dto.modifiedDate.valueOf() : 0
  } as DocListRecorVo;
};
//一覧情報取得処理
export const get = (query: string): Promise<[number, DocListRecordDto[]]> => {

  if (!query || query.length === 0) {
    query = `?rowCount=${rowCount}`;
  } else {
    query += `&rowCount=${rowCount}`;
  }

  return api.get(`${uri}/list${query}`)
    .then((result: any) => {
      const data = result as CommonListVo<DocListRecorVo>;

      return [data.total, data.vos.map(vo2ListRecordDto)];

    });
};
//送信情報削除処理
export const removeById = (data: DocListRecordDto): Promise<any> => {

  return api.removeById(`${uri}/delete/`, data.id,listRecordDto2Vo(data)).then((result: any) => {
    return result;
  });
};

//２．送信情報新規画面
//送信情報VO
interface DocCreateVo  {
  id: number;
  documentCategory: string;
  title: string;
  message: string;
  supplier: string[];
  version: number;
  lang: string

  attachments: Attachment[];
}

//送信情報DTO
export interface DocCreateDto extends DocCreateVo {
}
//送信情報DTO⇒VO
const createDto2Vo = ( dto: DocCreateDto ): DocCreateVo =>{
  return {...dto, lang:locales.getLang()}
}
//送信情報VO⇒DTO
const createVo2Dto = ( vo: DocCreateVo ): DocCreateDto =>{
  return {...vo}
}
//送信情報Insert
export const createDoc = (dto: DocCreateDto): Promise<DocCreateVo> => {
  return api.persist(`${uri}/insert`,createDto2Vo(dto))
    .then((result: any) => {
      const data = result as DocCreateVo;
      return createVo2Dto(data);
    });
}
//３．詳細画面（i-Pro用）
//詳細Vo（i-Pro用）
export interface DocDetailVo extends DocSentVo {
  //送信情報
  sendQuantity: number;
  readQuantity: number;
  replyQuantity: number;

  // 更新日
  modifiedDate: number;

  //返信一覧
  details: DocReplyVo[]
}
//詳細DTO（i-Pro用）
export interface DocDetailDto  extends DocSentDto {

  //送信情報
  sendQuantity: number;
  readQuantity: number;
  replyQuantity: number;

  // 更新日
  modifiedDate: Dayjs;
  
  version: number;
  //返信一覧
  details: DocReplyDto[]
}

//詳細VO⇒DTO（i-Pro用）
export const detailVo2Dto = (vo: DocReplyVo): DocReplyDto => {
  return {
    ...vo,
    replyTime: vo.replyTime === 0 ? null : dayjs(vo.replyTime),
    modifiedDate: vo.modifiedDate === 0 ? null : dayjs(vo.modifiedDate)
  }
}
//詳細DTO⇒VO（i-Pro用）
export const detailDto2Vo = (dto: DocReplyDto): DocReplyVo => {
  return {
    ...dto,
    replyTime: !!dto.replyTime ? dto.replyTime.valueOf() : 0,
    modifiedDate: !!dto.modifiedDate ? dto.modifiedDate.valueOf() : 0
  }
}
//詳細の返信一覧DTO⇒TO（i-Pro用）
const vo2FormDto = (vo: DocDetailVo): DocDetailDto => {
  return {
    ...vo,
    sendTime: vo.sendTime === 0?null:dayjs(vo.sendTime),
    details: vo.details && vo.details.map(detail => detailVo2Dto(detail)),
    modifiedDate: vo.modifiedDate === 0 ? null : dayjs(vo.modifiedDate)

  };
};
//詳細情報取得（i-Pro用）
export const getById = (urlId: string): Promise<DocDetailDto> => {
  return api.get(`${uri}/detail?id=${urlId}`)
    .then((result: any) => {
      const data = result as DocDetailVo;
      return vo2FormDto(data);
    });
};
//返信情報について、受領処理（i-Pro用）
export const accept = (dto: DocReplyDto): Promise<DocReplyVo> => {
  const vo: DocReplyVo = detailDto2Vo(dto);

  return api.merge(`${uri}/accept/`, vo.id,vo)
}

//返信情報について、受領ない処理（i-Pro用）
export const notAccept = (dto: DocReplyDto): Promise<DocReplyVo> => {
  const vo: DocReplyVo = detailDto2Vo(dto);

  return api.merge(`${uri}/notAccept/`, vo.id,vo)
}
//返信情報について、削除処理（i-Pro用）
export const detailDelete = (dto: DocReplyDto): Promise<DocReplyVo> => {
  const vo: DocReplyVo = detailDto2Vo(dto)
  
  return api.removeById(`${uri}/delete/detail/`,vo.id, vo).then(result => {
    return result;
  })
}

//4．詳細画面（サプライヤー専用)

//詳細VO（サプライヤー専用）
export interface DocDetailSupplierVo extends DocSentVo,DocReplyVo{

  //返信情報
  detailId:number;
  replyUserId:string;
  replyUserName:string;
  documentVersion:number;
}
//詳細DTO（サプライヤー専用）
export interface DocDetailSupplierDto extends DocSentDto,DocReplyDto{
  //返信情報
  detailId:number;
  replyUserId:string;
  replyUserName:string;
  documentVersion:number;
}
//詳細VO⇒DTO（サプライヤー専用）
export const vo2FormDto1 = (vo: DocDetailSupplierVo): DocDetailSupplierDto => {
  return {
    ...vo,
    replyTime: vo.replyTime === 0 ? null : dayjs(vo.replyTime),
    sendTime: vo.sendTime === 0?null:dayjs(vo.sendTime),
    modifiedDate: vo.modifiedDate === 0 ? null : dayjs(vo.modifiedDate)
  };
};
//詳細DTO⇒VO（サプライヤー専用）
export const formDto2Vo1 = (dto: DocDetailSupplierDto, form?: DocDetailSupplierDto): DocDetailSupplierVo => {

  return {
    ...form,
    ...dto,
    sendTime: !!dto.sendTime ? dto.sendTime.valueOf() : 0,
    replyTime: !!dto.replyTime ? dto.replyTime.valueOf() : 0,
    modifiedDate: !!dto.modifiedDate ? dto.modifiedDate.valueOf() : 0
  } as DocDetailSupplierVo;
};

//詳細情報取得（サプライヤー用）
export const getByDetail = (urlId: string, detailId: string): Promise<DocDetailSupplierDto> => {

  return api.get(`${uri}/detail/supplier?id=${urlId}&detailId=${detailId}`)
    .then((result: any) => {
      const data = result as DocDetailSupplierVo;

      return vo2FormDto1(data);
    });
};
//サプライヤーより返信処理
export const replyCommit = (dto: DocDetailSupplierDto, form?: DocDetailSupplierDto) => {
  const valuevo:DocDetailSupplierVo=formDto2Vo1(dto, form);
  return api.merge(`${uri}/reply/`, valuevo.id ,valuevo)
    .then((result: DocDetailSupplierVo) => {
      //vo->dto
      return vo2FormDto1(result);
    });
}

//99. 共通定義
//送信情報VO
export interface DocSentVo {
  // id
  id: number;
  // 発注者  
  buyer: string;
  // 文件类型
  documentCategory: string;
  // 发送时间  送信時間
  sendTime: number;
  // 文件名
  title: string;
  // 消息
  message: string;
  // ユーザーID
  userId:string;
  // ユーザー名称
  userName: string;    
  //version
  version:number;

  attachments: Attachment[];
}

 //返信情報VO
  export interface DocReplyVo {
  //ID
  id: number;
  //documentId
  documentId: number;
  //サプライヤー
  supplier: string;
  //既読フラグ
  readFlag:string;
  //返信フラグ
  replyFlag:string;
  //受領フラグ
  acceptFlag:string;
  //返信時間
  replyTime:number;
  //返信メッセージ
  replyMessage:string;
  //返信ユーザー
  userId:string;
  //返信ユーザー名称
  userName:string;
  //更新日
  modifiedDate:number;
  //バージョン
  version:number;

  detailAttachments: Attachment[];
}
//送信情報DTO
export interface DocSentDto {
  // id
  id: number;
  // 発注者  
  buyer: string;
  // 文件类型
  documentCategory: string;
  // 发送时间  送信時間
  sendTime: Dayjs;
  // 文件名
  title: string;
  // 消息
  message: string;
  // ユーザーID
  userId:string;
  // ユーザー名称
  userName: string;    
  //version
  version:number;

  attachments: Attachment[];
}

 //返信情報DTO
  export interface DocReplyDto{
  //ID
  id: number;
  //documentId
  documentId: number;
  //サプライヤー
  supplier: string;
  //既読フラグ
  readFlag:string;
  //返信フラグ
  replyFlag:string;
  //受領フラグ
  acceptFlag:string;
  //返信時間
  replyTime:Dayjs;
  //返信メッセージ
  replyMessage:string;
  //返信ユーザー
  userId:string;
  //返信ユーザー名称
  userName:string;
  // 更新日
  modifiedDate: Dayjs;
  //バージョン
  version:number;

  detailAttachments: Attachment[];
}

// 获取文件列表 file list
export const getFileList = (fileUuids: string[]): Promise<Attachment[]> => {
  return api.get(`/api/file/list?uuids=${[...fileUuids]}`)
    .then((result: Attachment[]) => {
      return result;
    });
};


export const downloadTemplate = () => {
  const lang = locales.getLang();
  return api.downloadTemplate(`${uri}/downloadTemplate`, lang).then(res=>{
    res.blob().then((blob: Blob | MediaSource) => {
      const aLink = document.createElement('a');
      aLink.setAttribute('charset', 'UTF-8')
      document.body.appendChild(aLink);
      aLink.style.display='none';
      const objectUrl = window.URL.createObjectURL(blob);
      aLink.href = objectUrl;
      aLink.download = decodeURIComponent(res.headers.get('content-disposition').split('filename=')[1]);
      aLink.click();
      document.body.removeChild(aLink);
    });
  })
}