import { Dayjs } from 'dayjs';
import { DateRange, } from '../common';
import { api, PagingSearchParaVo, CommonListVo, SearchParaPropertis, converter, } from '../api'

const dayjs = require('dayjs');
// アクセスURI
const uri = '/api/notifications';

// ページ毎行数
const rowCount = 10;

// ソート順項目定義、repositoryと一致
const orderByColumns = [
    // 送信者コード
    "senderCode",
    // 件名
    "subject",
    // 着信日時
    "incomingDateAndTime",
    // 本文
    "text"
];

// 検索条件Dtoの項目一覧
const properties: SearchParaPropertis = {
    // 送信者コード
    senderCode: 'string',
    // 件名
    subject: 'string',
    // 着信日時
    incomingDateAndTime: 'DateRange'
};

// 情報お知らせのVOクラス
interface NotificationVo {
    // ID
    id: number,
    // 着信日時
    incomingDateAndTime: number,
    // 送信者コード
    senderCode: string,
    // 受信者コード
    recipientCode: string,
    // 件名
    subject: string,
    // 本文
    text: string
}
// 情報お知らせ検索条件Dto
export interface NotificationSearchDto extends PagingSearchParaVo {
    // 送信者コード
    senderCode?: string,
    // 件名
    subject?: string,
    // 着信日時
    incomingDateAndTime: DateRange
}

export interface NotificationListRecordDto {
    // ID
    id: number,
    // 着信日時
    incomingDateAndTime: Dayjs,
    // 送信者コード
    senderCode: string,
    // 受信者コード
    recipientCode: string,
    // 件名
    subject: string,
    // 本文
    text: string

}
const vo2ListRecordDto = (vo: NotificationVo): NotificationListRecordDto => {
    return {
        ...vo,
        incomingDateAndTime: dayjs(vo.incomingDateAndTime)
    } as NotificationListRecordDto;
};
export const get = (query: string): Promise<[number, NotificationListRecordDto[]]> => {
    if (!query || query.length === 0) {
        query = `?rowCount=${rowCount}`;
    } else {
        query += `&rowCount=${rowCount}`;
    }
    return api.get(`${uri}${query}`)
        .then((result: any) => {

            const data = result as CommonListVo<NotificationVo>;
            return [data.total, data.vos.map(vo2ListRecordDto)];
        });
};

// searchから検索条件Dtoへ変換する
export const search2Dto = (query?: string): NotificationSearchDto => {
    return converter.search2Dto(properties, orderByColumns, query) as NotificationSearchDto;
};
// 検索条件Dtoからsearchへ変換する
export const dto2search = (researchDto: NotificationSearchDto): string => {
    return converter.dto2search(properties, orderByColumns, researchDto);
};