const jaJP = {

  'message.reapplyConfirm' : '返信しますか？',
  'message.approveConfirm' : '通過しますか？',
  'message.refuseConfirm' : '通過しますか？',
  'message.inputRequired' : '{title}を入力ください。',
  'message.selectRequired' : '{title}を選択ください。',
  'message.addSuccess' : '追加が成功しました。',
  'message.updateSuccess' : '更新が成功しました。',
  'message.deleteSuccess' : '削除が成功しました。',
  'message.uploadSuccess' : 'アップロードが成功しました。',
  'message.addFailure' : '追加が失敗しました。',
  'message.updateFailure' : '更新が失敗しました。',
  'message.deleteFailure' : '削除が失敗しました。',
  'message.uploadFailure' : 'アップロードが失敗しました。',
  'message.requestFailure400' : '送信パラメーターにミスがあります。',
  'message.requestFailure401' : '認証が必要です。',
  'message.requestFailure404' : 'サーバーが回答していませんでした。',
  'message.requestFailure408' : 'タイムアウトでした。',
  'message.requestFailure409' : '既に他のユーザーより更新されました。',
  'message.requestFailure500' : 'サーバー上にミスが発生しました。',
  'message.requestFailure504' : 'タイムアウトが発生しました。',
  'message.delConfirm' : '削除しますか？',
  'message.editConfirm' : '更新しますか？',
  'message.replyConfirm' : '返信しますか？',
  'message.addConfirm' : '追加しますか？',
  'message.sendConfirm' : '送信しますか？',
  'message.finishConfirm' : '終了しますか？',
  'message.cancelConfirm' : 'キャンセルしますか？',
  'message.cancelConfirm2' : '取消しますか？',
  'message.checkGoodsIssue' : '出荷番号を入力してくだささい。',
  'message.checkLocation' : '「受渡場所、着荷予定日」が一致していません。一致したデータのみ一括出荷が可能です。',
  'message.currencyNull' : '通貨単位は空でなければならない',
  'message.deliveryNoUnique' : '納品番号がありますので入力し直してください',
  'message.readAllConfirm' : '全ての項目を既読にしますか？',
  'message.cancel2Confirm' : '取消しますか？',
  'message.noForecastError' : '御社には今週の所要計画がございません。',

  'estimate.checkSelect' : '出荷予定情報を選択してください。',

  'orderResponse.shortageMessage' : '出荷数が回答数より少ないです。',
  'orderResponse.shortageMessage2' : '出荷数が回答数より多いです。',

  'message.integerError' : '{a}ビット以下の入力整数のみ',
  'message.positiveIntegerError' : '{a}ビット以下の入力正の整数のみ',
  'message.excelSPQError' : '{i}行目の SPQ は6以下の正の整数しか入力できません。',
  'message.excelMPQError' : '{i}行目の MPQ は6以下の正の整数しか入力できません。',
  'message.excelPostiveNumberError' : '{i}行目の{key}はゼロより大きい数を入力してください。',
  'message.excelNegativeNumberError' : '{i}行目の{key}は負の値に禁止されています。',
  'message.excelLTPositiveNumberError' : '{i}行目の{key}は正の整数しか入力できません、2147483647より大きい値を禁止します。',
  'message.excelAssetNo' : '{i}行目の{key}は15桁数以下を入力してください。',
  'message.excelAuxiliaryNo' : '{i}行目の{key}は0～99の整数を入力してください。',
  'message.excelCostCenterNo' : '{i}行目の{key}は10桁数以下を入力してください。',
  'message.excelEstimateStartDateError' : '{i}行目の{key}は過去日付です。',

  'estimate.answerDeadlineError' : '{b}は{a}より前の日付に指定してください。',
  'estimate.maxError' : '{a}は最大に{b}文字まで入力可能です',
  'estimate.nullError' : '{name}を入力してください。',
  'estimate.zeroError' : '{name}はゼロにできません。',
  'estimate.priceError' : '{a}は小数点以下2桁まで入力してください。',

  'shipment' : '納品',
  'shipment.search' : '検索',
  'shipment.orderNo' : '注文番号',
  'shipment.itemCode' : '品番',
  'shipment.deliveryDate' : '納品日',

  'forecast' : '所要計画管理',
  'forecast.search' : '検索',
  'forecast.list' : '所要計画一覧',
  'forecast.planNo' : '計画番号',
  'forecast.detailNo' : '明細番号',
  'forecast.planningDate' : 'MRP処理日',
  'forecast.buyer' : '発注者',
  'forecast.buyerName' : '発注者名称',
  'forecast.supplier' : '受注者',
  'forecast.supplierName' : '受注者名称',
  'forecast.item' : '品番',
  'forecast.itemName' : '品名',
  'forecast.iniDeliveryMonth' : '納入予定月',
  'forecast.iniDeliveryDate' : '納入予定日',
  'forecast.quantity' : '数量',
  'forecast.unit' : '単位',
  'forecast.purchasingManager' : '購買担当',
  'forecast.now' : '最新データ',

  'pay' : '支払管理',

  'pays' : '支払通知',

  'pay.list' : '支払一覧',
  'pay.detail' : '支払情報明細',
  'pay.payment' : '支払金額',
  'pay.contracor' : '発注者情報',
  'pay.order' : '受注者情報',
  'pay.taxRate' : '税率',
  'pay.items' : '項目',
  'pay.id' : 'ID',
  'pay.dataCreationDate' : 'データ作成日',
  'pay.paymentId' : '支払コード',
  'pay.acquisitionDate' : '取引年月',
  'pay.supplierCode' : '仕入先コード',
  'pay.paymentDate' : '支払日',
  'pay.reissueFlag' : '再発行区分',
  'pay.zipCode' : '郵便番号',
  'pay.supplierAddress' : '受注者住所',
  'pay.supplierName' : '受注者名称',
  'pay.fax' : 'FAX',
  'pay.dateOfIssue' : '発行日',
  'pay.buyer' : '発注者',
  'pay.buyerName' : '発注者名称',
  'pay.departmentName' : '発注部署名',
  'pay.qualifiedInvoiceIssuerRegistrationNumber' : '適格請求書発行事業者登録番号',
  'pay.currencyCode' : '通貨',
  'pay.totalPaymentAmount' : '支払合計',
  'pay.lastMonthPayAdjustAmount' : '前月支払修正額',
  'pay.purchasePrice' : '仕入代金',
  'pay.accountsReceivableOffset' : '売掛金相殺',
  'pay.otherOffset' : 'その他相殺',
  'pay.payAdjustAmount' : '支払修正額',
  'pay.balanceAmountPay' : '差引お支払金額',
  'pay.invoiceNo' : 'インボイス番号',
  'pay.excludingTax' : '税抜金額',
  'pay.consumptionTax' : '消費税',
  'pay.totalExcludingTax' : '税抜合計金額',
  'pay.readFlag' : '受信フラグ',
  'pay.paymentUponEachUse' : '都度支払',

  'billingDetail' : '支払明細',
  'billingDetail.list' : '支払明細一覧',
  'billingDetail.companyId' : '会社コード',
  'billingDetail.acquisitionDate' : '取引年月',
  'billingDetail.supplierCode' : '仕入先コード',
  'billingDetail.supplierName' : '仕入先名称',
  'billingDetail.transcriptionDate' : '転記日付',
  'billingDetail.deliverySlipNumber' : '納品伝票番号',
  'billingDetail.purchaseOrderNo' : '購買発注NO.',
  'billingDetail.purchaseDetailNo' : '購買発注伝票明細',
  'billingDetail.itemCode' : '品目コード',
  'billingDetail.itemText' : '品目テキスト',
  'billingDetail.incorporationQuantity' : '仕入数量',
  'billingDetail.unit' : '単位',
  'billingDetail.purchaseUnitPrice' : '仕入単価',
  'billingDetail.purchaseAmount' : '仕入金額',
  'billingDetail.currencyCode' : '通貨',
  'billingDetail.purchaseTax' : '税率',
  'billingDetail.totalPurchaseAmount' : '仕入金額合计：',
  'billingDetail.year' : '年',
  'billingDetail.month' : '月度',
  'billingDetail.detail' : '明細',
  'billingDetail.totalPurchaseAmountGetFailed' : '仕入金額合计データを取得していませんでした。',

  'acceptance' : '検収管理',
  'acceptance.list' : '検収一覧',
  'acceptance.detail' : '検収詳細情報',
  'acceptance.orderInfo' : '注文情報',
  'acceptance.orderNo' : '注文番号',
  'acceptance.orderDetailNo' : '検収明細番号',
  'acceptance.detailNo' : '注文明細番号',
  'acceptance.orderDate' : '発行日',
  'acceptance.purchasingManager' : '購買担当',
  'acceptance.iniDeliveryDate' : '納入予定日',
  'acceptance.productionPlanDate' : '納入予定日',
  'acceptance.item' : '品番',
  'acceptance.itemName' : '品名',
  'acceptance.unit' : '単位',
  'acceptance.orderUnit' : '注文単位',
  'acceptance.buyer' : '発注者',
  'acceptance.buyerName' : '発注者名称',
  'acceptance.supplier' : '受注者',
  'acceptance.supplierName' : '受注者名称',
  'acceptance.currencyCode' : '通貨',
  'acceptance.acceptanceInfo' : '検収情報',
  'acceptance.acceptanceNo' : '検収番号',
  'acceptance.acceptanceDetailNo' : '検収明細番号',
  'acceptance.acceptanceDate' : '検収日',
  'acceptance.moveType' : '移動区分',
  'acceptance.acceptanceUnitPrice' : '検収単価',
  'acceptance.acceptanceQuantity' : '検収数量',
  'acceptance.acceptanceQuantityUnit' : '検収数量単位',
  'acceptance.acceptanceAmount' : '検収金額',
  'acceptance.orderQuantity' : '注文数量',
  'acceptance.orderAmount' : '注文金額',
  'acceptance.orderUnitPrice' : '注文単価',
  'acceptance.locationCd' : '保管場所コード',
  'acceptance.locationName' : '保管場所名称',
  'acceptance.deliveryNo' : '納品番号',
  'acceptance.deliveryDetailNo' : '納入明細番号',
  'acceptance.search' : '検索',
  'acceptance.buyerInfo' : '発注者情報',
  'acceptance.supplierInfo' : '受注者情報',
  'acceptance.buyerDepartmentName' : '発注部門',

  'delivery.modalTitle' : '出荷',
  'delivery' : '納入管理',
  'delivery.detail' : '納品詳細一覧',
  'delivery.list' : '納品書一覧',
  'delivery.userModifiedDate' : '更新日',
  'delivery.dataCreationDate' : '作成日',
  'delivery.deliveryNo' : '納品番号',
  'delivery.buyer' : '発注者',
  'delivery.buyerName' : '発注者名称',
  'delivery.supplier' : '受注者',
  'delivery.supplierName' : '受注者名称',
  'delivery.userName' : '作成者',
  'delivery.estimatedTimeArriva' : '着荷予定日',
  'delivery.item' : '品番',
  'delivery.itemName' : '品名',
  'delivery.quantity' : '数量',
  'delivery.orderNo' : '注文番号',
  'delivery.detailNo' : '注文明細番号',
  'delivery.search' : '検索',
  'delivery.unprocessed' : '出荷処理',
  'delivery.unprocessedlist' : '出荷待部品一覧',
  'delivery.output' : '出　荷',
  'delivery.deliveryStatus' : '仕入flag',
  'delivery.locationCd' : '受渡場所',
  'delivery.unitPrice' : '単価',
  'delivery.re-output' : '納品書出力',
  'delivery.currencyCode' : '通貨',
  'delivery.eiaj' : 'EIAJ納品書',
  'delivery.dnList' : '一覧納品書',
  'delivery.reportType' : '納品書種類',
  'delivery.downloadSelected' : '納品書ダウンロード',
  'delivery.selectErrorTitle' : '選択エラー提示',
  'delivery.noSelectBody' : '納品書を選択してください。',
  'delivery.selectTooManyBody' : '最大に一括で２０件のデータを選択して、ダウンロードできます。',

  'orderDetail.orderInfo' : '注文情報',
  'orderDetail.orderNo' : '注文番号',
  'orderDetail.detailNo' : '注文明細番号',
  'orderDetail.item' : '品番',
  'orderDetail.itemName' : '品名',
  'orderDetail.orderDate' : '発行日',
  'orderDetail.buyer' : '発注者',
  'orderDetail.buyerName' : '発注者名称',
  'orderDetail.supplier' : '受注者',
  'orderDetail.supplierName' : '受注者名称',
  'orderDetail.unitPrice' : '単価',
  'orderDetail.unit' : '単位',
  'orderDetail.quantity' : '注文数量',
  'orderDetail.amount' : '注文金額',
  'orderDetail.purchasingManager' : '購買担当',
  'orderDetail.iniDeliveryDate' : '納入予定日',
  'orderDetail.productionPlanDate' : '再日程日',
  'orderDetail.currencyCode' : '通貨',
  'orderDetail.locationCd' : '保管場所',
  'orderDetail.locationCode' : '保管場所コード',
  'orderDetail.infoType' : '情報種類',
  'orderDetail.orderFinishFlag' : '納入完了Flag',
  'orderDetail.remark' : '備考',
  'orderDetail.acceptanceQuantity' : '検収数量',
  'orderDetail.balanceQuantity' : '注文残数',
  'orderDetail.deliveryBalanceQuantity' : '出荷残数',
  'orderDetail.deliveryQuantity' : '出荷数量',
  'orderDetail.balanceAmount' : '注文残額',
  'orderDetail.deliveryBalanceAmount' : '出荷残額',
  'orderDetail.deliveryFlag' : 'VMI区分',
  'orderDetail.qualityCheckFlag' : '品検',
  'orderResponse' : '納入回答',
  'orderResponse.devInstrucstionDate' : '納品指示日',
  'orderResponse.instrucstionQuantity' : '納品指示数量',
  'orderResponse.instruction' : '納品指示',
  'orderResponse.list' : '注文一覧',
  'orderResponse.detail' : '注文明細',
  'orderResponse.orderResponseList' : '納入回答情報',
  'orderResponse.instructionList' : '納品指示情報',
  'orderResponse.deliveryInfoList' : '出荷情報',
  'orderResponse.deliveryPlanDate' : '回答納期',
  'orderResponse.quantity' : '数量',
  'orderResponse.responseQuantity' : '回答数量',
  'orderResponse.planDeliveryQuantity' : '出荷予定数量',
  'orderResponse.deliveryInfo' : '出荷情報',
  'orderResponse.detailNo' : '明細番号',
  'orderResponse.estimatedTimeArriva' : '着荷予定日',
  'orderResponse.addDeliveryInfo' : '納入情報を追加する',
  'orderResponse.uploadDeliveryInstruction' : '納品指示アップロード',
  'orderResponse.uploadOrderResponses' : '納入回答アップロード',
  'orderResponse.uploadDeliveryInfos' : '出荷情報アップロード',
  'orderResponse.delivery' : '出荷',
  'orderResponse.response' : '指示受入',
  'orderResponse.preDeliveryPlanDate' : '納入回答日（更新前）',
  'orderResponse.preQuantity' : '数量（更新前）',
  'orderResponse.currDeliveryPlanDate' : '納入回答日（更新後）',
  'orderResponse.currQuantity' : '数量（更新後）',
  'orderResponse.preEstimatedTimeArriva' : '着荷予定日（更新前）',
  'orderResponse.currEstimatedTimeArriva' : '着荷予定日（更新後）',
  'orderResponse.preShipDate' : '出荷日（更新前）',
  'orderResponse.currShipDate' : '出荷日（更新後）',
  'orderResponse.preRemarks' : '備考（更新前）',
  'orderResponse.currRemarks' : '備考（更新後）',
  'orderResponse.shipDate' : '出荷日',
  'orderResponse.remarks' : '備考',
  'orderResponse.duplicate' : '重複な日付',
  'orderResponse.over' : '上限オバー',
  'orderResponse.responseDeleteFlag' : '返信削除フラグ ',
  'orderResponse.deliveryDeleteFlag' : '出荷削除フラグ ',
  'orderResponse.downloadDevInstrucstionInfos' : '納品指示ダウンロード',

  'devInstrucstion.orderDetailKey' : 'PO_NUMPO_LINE',
  'devInstrucstion.devInstrucstionDate' : '出荷日',
  'devInstrucstion.instrucstionQuantity' : '出荷数量',

  'notification' : 'お知らせ',

  'subnotification' : 'システムお知らせ',

  'notification.id' : 'ID',
  'notification.incomingDateAndTime' : '着信日時',
  'notification.senderCode' : '送信者コード',
  'notification.sender' : '送信者',
  'notification.recipientCode' : '受信者コード',
  'notification.subject' : '件名',
  'notification.text' : '本文',
  'notification.list' : '情報お知らせ一覧',

  'estimate' : '見積管理',
  'estimatesSub' : '一般見積',

  'supplierEstimates' : '先方依頼',
  'supplierEstimates.supplier' : '当社依頼',
  'supplierEstimates.list' : '先方依頼一覧',
  'supplierEstimates.supplierList' : '当社依頼一覧',
  'supplierEstimates.requestList' : '当社依頼アップロード',
  'supplierEstimates.upload' : 'データアップロード',
  'supplierEstimates.detail' : '先方依頼詳細情報',
  'supplierEstimates.supplierDetail' : '当社依頼詳細画面',
  'supplierEstimates.detailList' : '先方依頼部品一覧',
  'supplierEstimates.supplierDetailList' : '当社依頼部品一覧',
  'supplierEstimates.templateDownload' : 'テンプレートダウンロード',

  'estimate.list' : '見積依頼一覧',
  'estimate.requestEdit' : '見積依頼編集',
  'estimate.requestClose' : '見積依頼終了',
  'estimate.cancel' : '見積依頼取消',
  'estimate.id' : 'ID',
  'estimate.status' : '案件状態',
  'estimate.requestNo' : '見積番号',
  'estimate.buyer' : '発注者',
  'estimate.buyerName' : '発注者名称',
  'estimate.estimatesType' : 'カテゴリー',
  'estimate.buyerDepartment' : '発注部門',
  'estimate.purchasingManager' : '購買担当',
  'estimate.requestDate' : '依頼日',
  'estimate.answerDeadline' : '回答締切日',
  'estimate.item' : '品番',
  'estimate.itemName' : '品名',
  'estimate.planningMonths' : '企画月数',
  'estimate.planQuantity' : '企画数量',
  'estimate.monthlyPlanOrderQuantity' : '月発注予定数',
  'estimate.desireUnitPrice' : '希望単価',
  'estimate.desireCurrency' : '希望単価通貨',
  'estimate.deliveryLocation' : '保管場所',
  'estimate.responseInfo' : '見積回答情報',
  'estimate.responseStatus' : '回答状態',
  'estimate.supplier' : '受注者',
  'estimate.supplierName' : '受注者名称',
  'estimate.estimatedEffectiveDate' : '見積有効期限',
  'estimate.leadTime' : 'L/T',
  'estimate.originalCountryName' : '原産国',
  'estimate.makerName' : 'メーカー名称',
  'estimate.makerItem' : 'メーカー品番',
  'estimate.itemTypeCode' : '部品種別',
  'estimate.moq' : 'SPQ',
  'estimate.mpq' : 'MPQ',
  'estimate.unitPrice' : '単価',
  'estimate.unitCurrency' : '通貨',
  'estimate.moldCost' : '型費',
  'estimate.moldCurrency' : '型通貨',
  'estimate.moldType' : '型の種類',
  'estimate.moldProcessNum' : '型の工程数',
  'estimate.moldNum' : '型の面数',
  'estimate.jigCost' : '治具費',
  'estimate.jigCurrency' : '治具費通貨',
  'estimate.repairsCost' : '修理費',
  'estimate.repairsCurrency' : '修理費通貨',
  'estimate.editionFee' : '版費',
  'estimate.editionCurrency' : '版費通貨',
  'estimate.contractConditionsCode' : '契約条件',
  'estimate.estimateStartDate' : '見積適用開始日',
  'estimate.estimateEndDate' : '見積適用終了日',
  'estimate.prevLocation' : '生産場所（前工程）',
  'estimate.nextLocation' : '生産場所（後工程）',
  'estimate.newEstimate' : '見積依頼登録',
  'estimate.requestDetail' : '見積依頼明細',
  'estimate.estimateContent' : '見積依頼情報',
  'estimate.responseContent' : '見積回答情報',
  'estimate.requestInfo' : '見積依頼情報',
  'estimate.reponseDetail' : '見積回答明細',
  'estimate.reponseInfo' : '見積回答情報',
  'estimate.reponseList' : '見積回答一覧',
  'estimate.editRequest' : '見積依頼編集',
  'estimate.currency' : '単価通貨',
  'estimate.itemInfo' : '部品情報',
  'estimate.moldInfo' : '金型情報',
  'estimate.cost' : '費用',
  'estimate.processNum' : '工程数',
  'estimate.mum' : '面数',
  'estimate.type' : '種類',
  'estimate.quantity' : '数量',
  'estimate.responseCurrencyCode' : '通貨',
  'estimate.requestCount' : '依頼件数',
  'estimate.responseCount' : '回答件数',
  'estimate.remarks' : '備考',
  'estimate.monthUnit' : 'ヶ月',
  'estimate.quantityUnit' : 'PC',
  'estimate.bidding' : '応札',
  'estimate.decline' : '辞退',
  'estimate.userName' : 'オペレーター',
  'estimate.userID' : 'オペレーターID',
  'estimate.modifiedTime' : '更新日',
  'estimate.upload' : '添付ファイル',
  'estimate.reapply' : '再見積依頼',
  'estimate.approve' : '採用',
  'estimate.refuse' : '不採用',
  'estimate.makerInfo' : 'メーカー情報',
  'estimate.makerName2' : 'メーカー名称',
  'estimate.makerItem2' : '品番',
  'estimate.itemTypeCode2' : '種別',
  'estimate.moldsNum' : '面数',
  'estimate.responseUser' : '返信担当',
  'estimate.responseTime' : '返信日時',

  'modelManagement' : '金型管理',
  'model' : '金型登録',
  'model.detail' : '金型編集',
  'model.list' : '金型一覧',
  'model.assetNo' : '資産番号',
  'model.auxiliaryNo' : '補助番号',
  'model.moldNo' : '金型品番',
  'model.modelName' : '機種名',
  'model.item' : '品番',
  'model.itemName' : '品名',
  'model.material' : '材質',
  'model.management' : '管理',
  'model.kinds' : '種別',
  'model.primaryLender' : '一次預け先',
  'model.primaryLenderName' : '一次預け先名称',
  'model.primaryLenderAddr' : '一次預け先住所',
  'model.finalLender' : '最終預け先',
  'model.finalLenderName' : '最終預け先名称',
  'model.finalLenderAddr' : '最終預け先住所',
  'model.acquisitionDate' : '資本化日付',
  'model.supplier' : '金型購入先',
  'model.text' : 'テキスト',
  'model.supplierName' : '金型購入先名称',
  'model.costCenter' : '原価センター',
  'model.costCenterNo' : '原価センター番号',
  'model.costCenterName' : '原価センター名',
  'model.weight' : '重量(KG)',
  'model.moldCavityNum' : '取数',
  'model.lifeShotsNum' : 'ライフショット数',
  'model.moldsNum' : '面数',
  'model.primaryLeadDate' : '一次預け先の貸与日',
  'model.settlementNo' : '決済No',
  'model.purchaseAmount' : '仕入金額',
  'model.orderNo' : '注文番号',
  'model.depreciationStartDate' : '償却開始日',
  'model.discardDate' : '廃棄日',
  'model.remarks' : '備考',
  'model.upload' : '資産情報アップロード',
  'model.assets' : '資産情報',
  'model.moldProperty' : '金型情報',
  'model.deposit' : '預け先情報',
  'model.other' : 'その他',
  'model.purchaseCurrency' : '仕入通貨',
  'model.fixedAssetRegistrationDecisionNumber' : '固定資産登録決裁番号',
  'model.assetFlowDifferentiation' : '資産流動区分',
  'model.flowDate' : '流動日付',
  'model.finalLenderAddrCode' : '最終預け先住所コード',
  'model.assetFlowApplyCode' : '資産流動申請番号',
  'model.haveAttachments' : '有',
  'model.notHaveAttachments' : '無',
  'model.deleteFlag' : '削除フラグ',
  'model.haveOrNotHaveAttachments' : '添付ファイル有無',
  'model.deleteSelected' : '削除',
  'model.confirmDeleteSelected' : '削除の確認',
  'model.deleteMustSelectFirst' : '削除する項目を選択する必要があります',

  'inventory.confirmationUser' : 'IPRO確認担当',
  'inventory.confirmationTime' : '確認日時',
  'inventoryConfirm' : '有高確認',
  'inventory' : '金型有高管理',
  'inventory.status' : '回答状態',
  'inventory.inventoryRequestNo' : '有高確認依頼番号',
  'inventory.requestDate' : '有高確認依頼日',
  'inventory.requestDate2' : '依頼日',
  'inventory.requester' : '有高確認依頼者',
  'inventory.answerDeadline' : '回答希望日',
  'inventory.modelNum' : '金型件数',
  'inventory.moldsNum' : '面数',
  'inventory.actualMoldsNum' : '実面数',
  'inventory.remarks' : '特記事項',
  'inventory.buyer' : '購入元',
  'inventory.detail' : '有高確認情報',
  'inventory.head' : '有高確認ヘッダー情報',
  'inventory.detailList' : '有高確認詳細情報',
  'inventory.list' : '有高確認一覧',
  'inventory.inventoryRequest' : '有高確認依頼',
  'inventory.modifiedTime' : '更新日',
  'inventory.userName' : '更新担当',
  'inventory.responseUser' : '仕入先返信担当',
  'inventory.responseTime' : '返信日時',
  'inventory.recordTheReason' : '{a}を 0 で入れた場合、その理由を【{b}】に必ずご記載ください。',
  'inventory.waitForReply' : 'まだ返信完了しておりません。右下の【返信】をクリックしてください。',

  'docInfo' : 'ドキュメント管理',
  'docInfo.list' : 'ドキュメント一覧',
  'docInfo.add' : 'ドキュメント転送',
  'docInfo.detail' : 'ドキュメント詳細',
  'docInfo.sendInfo' : '送信情報',
  'docInfo.replyInfo' : '返信情報',
  'docInfo.buyer' : '発注者',
  'docInfo.buyerName' : '発注者名称',
  'docInfo.documentCategory' : '情報種類',
  'docInfo.title' : 'タイトル',
  'docInfo.sendContent' : '送信メッセージ',
  'docInfo.sendTime' : '送信時間',
  'docInfo.receiptContent' : '受信メッセージ',
  'docInfo.receiptTime' : '発信時間',
  'docInfo.acceptingFlag' : '受信フラグ',
  'docInfo.supplier' : '受注者',
  'docInfo.supplierName' : '受注者名称',
  'docInfo.reply' : '返信',
  'docInfo.replyTime' : '返信時間',
  'docInfo.replyContent' : '返信メッセージ',
  'docInfo.sendQuantity' : '発信件数',
  'docInfo.acceptQuantity' : '既読件数',
  'docInfo.replayQuantity' : '返信件数',
  'docInfo.docFiles' : '添付ファイル',
  'docInfo.selectAll' : '全て',
  'docInfo.acceptFlag' : '受入フラグ',
  'docInfo.replyFlag' : '返信フラグ',
  'docInfo.readFlag' : '受信フラグ',
  'docInfo.userName' : '送信担当',
  'docInfo.replyUserName' : '返信担当',
  'docInfo.destinationName' : '送信先',
  'docInfo.destinationList' : '送信先リスト',
  'docInfo.uploadDestination' : '送信先アップロード',
  'docInfo.confirmSendDocument' : 'ドキュメント転送の確認',
  'docInfo.numberOfSuppliers' : '仕入先コード件数：{i} 件',
  'docInfo.destinationNotExist' : '行 {i} の送信先 {name} は存在しません。',

  'common.stateCode' : '訂正区分',
  'common.userName' : '更新担当',
  'common.userModifiedDate' : '更新日',
  'common.logout' : 'ログアウト',
  'common.save' : '保存',
  'common.add' : '追加',
  'common.update' : '更新',
  'common.clear' : 'クリア',
  'common.delete' : '削除',
  'common.deleted' : '削除済',
  'common.search' : '検索',
  'common.conditions' : '検索',
  'common.download' : 'ダウンロード',
  'common.downloadPassed' : '採用情報タウンロード',
  'common.upload' : 'アップロード',
  'common.history' : '過去データ',
  'common.close' : '閉じる',
  'common.cancel' : 'キャンセル',
  'common.cancel2' : '取消',
  'common.reset' : 'リセット',
  'common.readAll' : '全て既読',
  'common.edit' : '編集',
  'common.next' : '次へ',
  'common.previous' : '前へ',
  'common.confirm' : '確認',
  'common.confirmed' : '確認済',
  'common.response' : '返信',
  'common.hisory' : '更新履歴',
  'common.operation' : 'オペレーション',
  'common.modalTitle' : '入力エラー提示',
  'common.modalErrorTitle' : 'エラー提示',
  'common.basicInfomation' : '基本情報',
  'common.yes' : 'はい',
  'common.no' : 'いいえ',
  'common.send' : '送信',
  'common.dataCreationDate' : '登録日',
  'common.minimun' : '最小値',
  'common.maximum' : '最大値',

  'supplierEstimates.answerList' : '一括回答アップロード',
  'supplierEstimates.finishRequest' : '案件終了',
  'supplierEstimates.detailDetail' : '先方依頼部品詳細情報',
  'supplierEstimates.accept' : '採用',
  'supplierEstimates.reject' : '不採用',

  'guide' : 'サポート',
  'guide.docNotReadyNow' : '文書編集中です。',
  'guide.getBackLater' : '暫くお待ちください。',
  'guide.os' : 'OS',
  'guide.browser' : 'ブラウザ',
  'guide.display' : 'ディスプレイ',
  'guide.language' : 'サポート言語',
  'guide.osVersion' : 'Windows8.1 以降',
  'guide.browserName' : 'Google Chrome、Microsoft Edge',
  'guide.displayPixel' : '横1920× 縦1080 ピクセル',
  'guide.zoomTitle' : '拡大縮小とレイアウト',
  'guide.zoom' : '100% 或は 125%',
  'guide.languageName' : '日本語、中国、英語',
  'guide.chinese' : '中国語',
  'guide.English' : '英語',
  'guide.Japanese' : '日本語',
  'guide.systemManual' : 'システムマニュアル',
  'guide.tel' : 'メールのお問い合わせ',
  'guide.receptionTime' : '受付時間',
  'guide.china' : '中国',
  'guide.japan' : '日本',
  'guide.telChina' : '00086-XXX-XXXX-XXXX',
  'guide.telJapan' : '00081-XXX-XXXX-XXXX',
  'guide.supportInfo' : 'サポート情報',
  'guide.download' : 'ダウンロード',
  'guide.environment' : '動作環境',
  'guide.downloadIproChinaText' : 'i-Pro China専用',
  'guide.downloadIproJapanText' : 'i-Pro Japan専用',
  'guide.downloadSupplierChinaText' : 'i-Pro Chinaサプライヤー専用',
  'guide.downloadSupplierJapanText' : 'i-Pro Japanサプライヤー専用',
  'guide.supplierOnly' : 'サプライヤー用',
  'guide.iproOnly' : 'i-PRO用',
  'guide.dataSynchronizationTime' : 'データ送信受信',
  'guide.dataSendTime' : '送信時間',
  'guide.dataReceptionTime' : '受信時間',
  'guide.pipsSendTime' : '08時45分／10時45分／12時45分／14時45分／16時45分／18時45分／21時45分',
  'guide.pipsReceptionTime' : '02時35分／09時35分／11時35分／13時35分／15時35分／17時35分／19時35分',
  'guide.psnsSendTime' : '10時15分／15時15分／21時15分',
  'guide.psnsReceptionTime' : '11時50分／16時50分／22時50分',

  'home' : 'ダッシュボード',
  'home.pieces' : '件',
  'home.unreadData' : '未読件数',
  'home.arrivedate' : '最新データ着信日',
  'home.waitingShipped' : '出荷待ちデータ件数',
  'home.notanswered' : '未回答件数',
  'home.quote' : '先方依頼見積',
  'home.moldMaster' : '金型マスター',
  'home.detailInfoIncompleteNumber' : '未完成件数',
  'home.OurRequestQuotation' : '当社依頼見積',
  'home.estiamteUnclose' : '未クローズ案件数',

  'batch.newestBatchInfo' : '最新{batchName}バッチ情報',
  'batch.dataImportTime' : 'データ連携時間',
  'batch.newDataQuantity' : '新規件数',
  'batch.updateDataQuantity' : '更新件数',
  'batch.skipDataQuantity' : 'スキップ件数',
  'batch.errorDataQuantity' : 'エラー件数',
  'batch.more' : '詳細',
  'batch.newestBatchRunTime' : '最新{batchName}バッチ連携時間',
  'batch.batchDetail' : 'バッチ詳細情報',
  'batch.batchName' : 'バッチ区分',
  'batch.batchStatus' : 'バッチ状態',
  'batch.inOrderDetail' : '購買発注',
  'batch.inAcceptance' : '検収',
  'batch.inPayment' : '支払情報',

  'message.DigitRetainedUnitPrice' : '小数点以下の最大桁数は{a}です、ご確認してください 。',
  'message.DigitRetained2' : '小数点以下最大{n}桁までご入力ください。',
  'message.DigitRetainedAmountJP' : '小数点以下の桁数は禁止されています、ご確認してください。 ',
  'message.DigitRetainedAmount' : '小数点以下の最大桁数は2です、ご確認してください。',
  'message.unitPriceMaxDecimal' : '小数点以下6桁までです',
  'message.lengthFailure' : '最大に{length}文字まで入力可能です。',
  'message.lengthFailureSingleTAG' : '1 つのタグで最大{length}文字まで入力可能です。',
  'message.IntegerPartLengthFailure' : '整数部分最大{length}桁まで入力可能です。',
  'message.fixedLengthLimit' : '{length}桁の文字しか入力できません。',
  'message.onlyHalfString' : '半角文字を入力してください。',
  'message.onlyPositiveInteger' : 'ゼロより大きい整数を入力してください。',
  'message.onlyPositiveNumber' : 'ゼロより大きい数を入力してください。',
  'message.noNegativeNumbers' : '{i}は負の数を入力できません。',
  'message.formateError' : '半角の英数字と下記特殊記号で入れてください。',
  'message.allowedSpecialMarkings' : '特殊記号：-　_ ',
  'message.outputDataLinesError' : '複数のデータを選択できません 。',
  'message.commonElementError' : '{a}と同じデータを選択できません 。',
  'message.itemNotAllowed' : '{i}行目の品番は品番マスタに登録していません。',
  'message.destinationNotAllowed' : '{i}行目の送信先は登録していません。',
  'message.timeOverTheLimit' : '行{i}の{key}は12/31/9999を超えることはできません',
  'message.excelUploadFromatError' : '{i}行目の{key}がフォーマット不正又は漏れていました。',
  'message.excelUploadDuplicatedError' : '{i}行目は{j}行目重複となりました。',
  'message.excelUploadFileNotFoundError' : 'アップロードファイルが見つかれませんでした。',
  'message.excelUploadError' : 'アップロード中エラーが発生しました。',
  'message.excelUploadItemDecimalOverError' : '{orderNoTitle}：{orderNo}、{orderDetailTitle}：{orderDetailNo}、{dateTitle}：{date}の{item}を小数点以下最大{n}桁までご入力ください。',
  'message.excelUploadItemMustGreatThenZeroError' : '{orderNoTitle}：{orderNo}、{orderDetailTitle}：{orderDetailNo}、{dateTitle}：{date}の{item}をゼロより大きい数でご入力ください。',
  'message.error' : 'エラーが発生',
  'message.priceOrCurrencyNullError' : '{i}と{j}を入力してください。',
  'message.priceOrCurrencyNullError2' : '{item}の{i}と{j}を入力してください。',
  'message.uploadedFileEmpty' : 'アップロードされたファイルは空です。',
  'message.deliveryResponseQtyOver' : '納入回答合計数量が注文数量を超えている',
  'message.goodIssueQtyOver' : '出荷予定合計数量が注文数量を超えている',
  'message.notAllowLineAtEnd' : '末尾にハイフンを入れることはできません',
  'message.noMultipleLines' : '複数のハイフンを使用することはできません',
  'message.onlyNumberOrNumberAndLine' : '数字または数字とハイフン「-」のみ入力可能です',
  'message.deliveryOutputFormSubmitWarning1' : '納品書出力に進めていきますが再度出荷内容が正しいかご確認ください。',
  'message.deliveryOutputFormSubmitWarning2' : '※納品書出力後一定時間を過ぎたら、納品書の取消ができなくなりますため',
  'message.numberTooLarge' : '数値が大きすぎる',
  'message.sureYouWantToClose' : '編集中の内容がまだ返信できていないので、明細画面を閉じますか？',
  'message.closeWillLostEdit' : '※返信する場合右下の【返信】をクリックしてください',
  'message.waiverChangeThenClose' : '画面を閉じる',
  'message.canNotDeleteDeletedItem' : '削除フラグ立てているため削除できません',

  'agreement.readed' : '上記の使用許諾に同意します',
  'agreement.enter' : 'システムへ',
};
export default jaJP;
