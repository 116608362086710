import React, { useEffect, useState } from 'react';
import { FormattedMessage, } from 'react-intl';

import { Table, Button, Modal, } from 'antd';

import { displayUtil } from '../util';
import { constListScroll, } from '../common'

import './index.css';
import { getDeliveryInfoHistoryById, DeliveryInfoHistoryDto } from './api';
import { useFields } from '../fields';

export interface Props {
  id?: number;
  visible: boolean;
  close: () => void;
  download: () => void;
}

export function OrderResponseDetailDeliveryInfoHistory(props: Props) {

  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<DeliveryInfoHistoryDto[]>([]);

  useEffect(
    () => {
      setLoading(true);

      if (props.visible && !!props.id && props.id > 0) {

        getDeliveryInfoHistoryById(props.id)
          .then((record: DeliveryInfoHistoryDto[]) => {
            setData(record);
            setLoading(false);
          })
          .catch(() => {
            setLoading(false);
          });
      } else {

        setData([]);
        setLoading(false);
      }
    }, [props.visible]
  );

  const dateWidth = '180px';
  const stateCodeWidth = '130px';
  const quantityWidth = '130px';
  const detailNoWidth = '100px';
  const userNameWidth = '100px';
  const remarksWidth = '150px';

  const orderDetailHistoryStatus = useFields('orderDetailHistoryStatus')

  // 明細番号
  const detailNoTitle = <FormattedMessage id='orderResponse.detailNo' />;
  const stateCodeTitle = <FormattedMessage id='common.stateCode' />;
  const userNameTitle = <FormattedMessage id='common.userName' />;
  const userModifiedDateTitle = <FormattedMessage id='common.userModifiedDate' />;

  // 着荷日（変更前）
  const preEstimatedTimeArrivaTitle = <FormattedMessage id='orderResponse.preEstimatedTimeArriva' />;
  // 出荷日（変更前）
  const preShipDateTitle = <FormattedMessage id='orderResponse.preShipDate' />;
  // 出荷数量（変更前）
  const preShipQuantityTitle = <FormattedMessage id='orderResponse.preQuantity' />;
  // 備考（変更前）
  const preRemarksTitle = <FormattedMessage id='orderResponse.preRemarks' />;

  // 着荷日（変更後）
  const currEstimatedTimeArrivaTitle = <FormattedMessage id='orderResponse.currEstimatedTimeArriva' />;
  // 出荷日
  const currShipDateTitle = <FormattedMessage id='orderResponse.currShipDate' />;
  // 出荷数量
  const currShipQuantityTitle = <FormattedMessage id='orderResponse.currQuantity' />;
  // 備考（変更後）
  const currRemarksTitle = <FormattedMessage id='orderResponse.currRemarks' />;

  const title = <>
        <FormattedMessage id='common.hisory' />
        <Button onClick={props.download} style={{ marginLeft: 10, marginRight: 10 }} ><FormattedMessage id='common.download' /></Button>
      </>

  const deliveryInfoHistoryColumns: any = [
    // 訂正区分
    { title: stateCodeTitle, dataIndex: 'stateCode', width: stateCodeWidth, render: displayUtil.field(orderDetailHistoryStatus) },
    // 明細番号
    { title: detailNoTitle, dataIndex: 'detailNo', width: detailNoWidth },
    // 出荷日(変更前)
    { title: preShipDateTitle, dataIndex: 'preShipDate', render: displayUtil.date, width: dateWidth },
    // 着信予定日(変更前)
    { title: preEstimatedTimeArrivaTitle, dataIndex: 'preEstimatedTimeArriva', render: displayUtil.date, width: dateWidth },
    // 出荷数量(変更前)
    { title: preShipQuantityTitle, dataIndex: 'preQuantity', width: quantityWidth },
    // 備考(変更前)
    { title: preRemarksTitle, dataIndex: 'preRemarks', width: remarksWidth },

    // 出荷日
    { title: currShipDateTitle, dataIndex: 'shipDate', render: displayUtil.date, width: dateWidth },
    // 着信予定日
    { title: currEstimatedTimeArrivaTitle, dataIndex: 'estimatedTimeArriva', render: displayUtil.date, width: dateWidth },
    // 出荷数量
    { title: currShipQuantityTitle, dataIndex: 'quantity', width: quantityWidth },
    // 備考
    { title: currRemarksTitle, dataIndex: 'remarks', width: remarksWidth },
    { title: userNameTitle, dataIndex: 'userName', width: userNameWidth },
    { title: userModifiedDateTitle, dataIndex: 'userModifiedDate', render: displayUtil.date, width: dateWidth },
  ];

  

  return (<Modal
    title={title}
    footer={null}
    visible={props.visible}
    closable={true}
    onCancel={props.close}
    width={1600}>
    <Table<DeliveryInfoHistoryDto>
      className="list-detail-table"
      bordered={false}
      dataSource={data}
      loading={loading}
      rowClassName={displayUtil.rowClassName}
      columns={deliveryInfoHistoryColumns}
      pagination={false}
      size='small'
      scroll={constListScroll}
    />
    <div className='historyMiddleCloseButton'>
      <Button className='search-close-btn' onClick={props.close}>
        <FormattedMessage id='common.close' />
      </Button>
    </div>
    
  </Modal>);
}
