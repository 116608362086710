import React, { useEffect, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { useIntl } from "react-intl";
import XLSX from 'xlsx';

// 共通のAPIs
// 機能内部のAPIs
import { DetailStates, ListStates } from "../common";
import dayjs from "dayjs";
import { get, getById, dto2search, ModelSearchDto, search2Dto, ModelDto, ModelUploadDto, merge, upload, removeById, downloadTemplateApi, removeByModelList } from "./api";
import { Props as ListProps } from './ModelList';
import { Props as DeleteProps } from './ModelDelete';
import { Props as SearchProps } from './ModelSearch';
import { Props as DetailProps } from './ModelDetail';
import { Props as UploadProps } from './ModelUpload';
import { displayUtil } from "../util";
import { useFields } from "../fields";

/**
 * 所要計画のコンポネート
 */
export function ModelHoc(
    List: React.ComponentType<ListProps>,
    Delete: React.ComponentType<DeleteProps>,
    Search: React.ComponentType<SearchProps>,
    Detail: React.ComponentType<DetailProps>,
    ModelDetailUpload: React.ComponentType<UploadProps>) {

    // 共通のstates
    const intl = useIntl();
    const materials = useFields('material');
    const managements = useFields('management');
    const kinds = useFields('kinds');
    const primaryLenders = useFields('supplier');
    const deleteFlagOptions = useFields('deleteFlag');
    const assetFlowDifferentiationOption = useFields("assetFlowDifferentiation")

    // URLのID
    const { id: urlId } = useParams<{ id?: string }>();
    // URLのquery文字列
    const { search } = useLocation();
    // URL変更のハンドル
    const { push, goBack } = useHistory();
    // 検索条件（フィルター、ページとソート順）
    const [searchDto, setSearchDto] = useState<ModelSearchDto>(search2Dto(search));
    // Flag
    const [refresh, setRefresh] = useState<boolean>(false);

    // 一覧のstates
    const [listData, setListData] = useState<ListStates<ModelDto>>({ loading: false, total: 0, data: [] });

    // 削除のstates
    // 削除画面の表示状態
    const [deleteVisible, setDeleteVisible] = useState<boolean>(false);
    // 削除待ちの金型データ
    const [selectedDeleteModelData, setSelectedDeleteModelData] = useState<ModelDto[]>([])

    // 検索のstates
    // 検査画面の表示状態
    const [searchVisible, setSearchVisible] = useState<boolean>(false);

    // 詳細のstates
    const [detailData, setDetailData] = useState<DetailStates<ModelDto>>({ visible: false });

    const [uploading, setUploading] = useState<boolean>(false);

    // URLのQuery変更によって検索を再実施する
    useEffect(() => {
        setDetailData(data => ({ ...data, visible: false }));

        if (!!urlId) {
            if (urlId !== 'upload') {
                getById(urlId)
                    .then((record: ModelDto) => {
                        setDetailData({ visible: true, data: record });
                    })
                    .catch(() => {
                    })
            }
        } else {
            setListData(data => ({ ...data, loading: true }))
            setSearchDto(search2Dto(search));

            get(search)
                .then((para: [number, ModelDto[]]) => {
                    const [count, vos] = para;
                    setListData({ loading: false, total: count, data: vos })
                })
                .catch(() => {
                    setListData(data => ({ ...data, loading: false }))
                });

        }
    }, [urlId, search, refresh]);


    const downloadTemplate = () => {
        downloadTemplateApi();
    }

    // 検索条件を変更する
    const setSearch = (researchDto: ModelSearchDto) => {

        // 期間範囲の編集
        if (!!researchDto.acquisitionDate && !!researchDto.acquisitionDate[0]) {
            researchDto.acquisitionDate[0] = researchDto.acquisitionDate[0].startOf('day');
        }
        if (!!researchDto.acquisitionDate && !!researchDto.acquisitionDate[1]) {
            researchDto.acquisitionDate[1] = researchDto.acquisitionDate[1].endOf('day');
        }
        // 検索条件変更を反映する
        const query = dto2search(researchDto);

        // URLを再設定する
        push(`/models${query}`);
    };
    // 検索の処理
    const handleSearch = (value: ModelSearchDto) => {
        setSearchVisible(false)
        setSearch(value);
    };

    // 詳細の処理
    const handleSubmit = (tempForm: ModelDto) => {
        if (!!detailData.data) {

            const data = detailData.data;

            tempForm.id = data.id;
            tempForm.version = data.version;
            tempForm.assetNo = data.assetNo;

            merge(tempForm)
                .then(
                    (dtos: ModelDto) => {
                        setDetailData({ data: dtos, visible: true })
                    }
                ).catch(
                    () => {
                    }
                );
        }
    };

    const handleUpload = (data: ModelUploadDto[]) => {
        setUploading(true)
        return upload(data)
            .then(() => {
                setUploading(false)
            }).catch(() => {
                setUploading(false)
            })
    }

    const download = () => {

        const downloadSearchDto = { ...searchDto, rowCount: 0 };

        setListData(data => ({ ...data, loading: true }));

        get(dto2search(downloadSearchDto))
            .then((para: [number, ModelDto[]]) => {

                setListData(data => ({ ...data, loading: false }));

                const [, vos] = para;

                const excelData = [
                    [
                        // 資産番号
                        intl.formatMessage({ id: 'model.assetNo' }),
                        // 補助番号
                        intl.formatMessage({ id: 'model.auxiliaryNo' }),
                        // text
                        intl.formatMessage({ id: 'model.text' }),
                        // 金型取得年月日
                        intl.formatMessage({ id: 'model.acquisitionDate' }),
                        // 原価センター
                        intl.formatMessage({ id: 'model.costCenter' }),
                        // 原価センター名
                        intl.formatMessage({ id: 'model.costCenterName' }),

                        // 金型品番
                        intl.formatMessage({ id: 'model.moldNo' }),
                        // 機種名
                        intl.formatMessage({ id: 'model.modelName' }),
                        // 管理
                        intl.formatMessage({ id: 'model.management' }),
                        // 種別
                        intl.formatMessage({ id: 'model.kinds' }),
                        // 材質
                        intl.formatMessage({ id: 'model.material' }),
                        // 重量
                        intl.formatMessage({ id: 'model.weight' }),
                        // 取数
                        intl.formatMessage({ id: 'model.moldCavityNum' }),
                        // ライフショット数
                        intl.formatMessage({ id: 'model.lifeShotsNum' }),
                        // 面数
                        intl.formatMessage({ id: 'model.moldsNum' }),
                        // 部品品番
                        intl.formatMessage({ id: 'model.item' }),
                        // 部品品名
                        intl.formatMessage({ id: 'model.itemName' }),

                        // 一次預け先コード
                        intl.formatMessage({ id: 'model.primaryLender' }),
                        // 一次預け先名称
                        intl.formatMessage({ id: 'model.primaryLenderName' }),
                        // 一次預け先住所
                        intl.formatMessage({ id: 'model.primaryLenderAddr' }),
                        // 一次預け先の貸与日
                        intl.formatMessage({ id: 'model.primaryLeadDate' }),
                        // 最終預け先コード
                        intl.formatMessage({ id: 'model.finalLender' }),
                        // 最終預け先住所コード
                        intl.formatMessage({ id: 'model.finalLenderAddrCode' }),
                        // 最終預け先名称
                        intl.formatMessage({ id: 'model.finalLenderName' }),
                        // 最終預け先住所
                        intl.formatMessage({ id: 'model.finalLenderAddr' }),

                        // 決済NO
                        intl.formatMessage({ id: 'model.settlementNo' }),
                        // 固定資産登録決裁番号
                        intl.formatMessage({ id: 'model.fixedAssetRegistrationDecisionNumber'}),
                        // 仕入金額
                        intl.formatMessage({ id: 'model.purchaseAmount' }),
                        // 仕入通貨
                        intl.formatMessage({ id: 'model.purchaseCurrency' }),
                        // 発注番号
                        intl.formatMessage({ id: 'model.orderNo' }),
                        // 償却開始日
                        intl.formatMessage({ id: 'model.depreciationStartDate' }),
                        // 資産流動区分
                        intl.formatMessage({ id: 'model.assetFlowDifferentiation'}),
                        // 流動日付
                        intl.formatMessage({ id: 'model.flowDate'}),
                        // 資産流動申請番号
                        intl.formatMessage({ id: 'model.assetFlowApplyCode'}),
                        // 廃棄日
                        intl.formatMessage({ id: 'model.discardDate' }),
                        // 登録日
                        intl.formatMessage({ id: 'common.dataCreationDate'}),
                        // 更新日
                        intl.formatMessage({ id: 'common.userModifiedDate'}),
                        // 更新者
                        intl.formatMessage({ id: 'common.userName'}),
                        // 備考
                        intl.formatMessage({ id: 'model.remarks' }),
                        // 添付ファイル有無
                        intl.formatMessage({ id: 'model.haveOrNotHaveAttachments'}),
                        // 削除フラグ
                        intl.formatMessage({ id: 'model.deleteFlag'}),
                    ],
                    ...(vos.map(vo => [
                        // 資産番号
                        vo.assetNo,
                        // 補助番号
                        vo.auxiliaryNo,
                        // text
                        vo.text,
                        // 取得年月日
                        displayUtil.date(vo.acquisitionDate),
                        // 原価センター番号
                        vo.costCenterNo,
                        // 原価センター名
                        vo.costCenterName,
                        
                        // 金型品番
                        vo.moldNo,
                        // 機種名
                        vo.modelName,
                        // 管理
                        displayUtil.field(managements)(vo.management),
                        // 種別
                        displayUtil.field(kinds)(vo.kinds),
                        // 材質
                        displayUtil.field(materials)(vo.material),
                        // 重量
                        vo.weight,
                        // 取数
                        vo.moldCavityNum,
                        // ライフショット数
                        vo.lifeShotsNum,
                        // 面数
                        vo.moldsNum,
                        // 品番
                        vo.items.join(','),
                        // 品名
                        vo.itemName,

                        // 一次預け先コード
                        vo.primaryLender,
                        // 一次預け先名称
                        displayUtil.field(primaryLenders)(vo.primaryLender),
                        // 一次預け先住所
                        vo.primaryLenderAddr,
                        // 一次預け先の貸与日
                        displayUtil.date(vo.primaryLeadDate),
                        // 最終預け先コード
                        vo.finalLender,
                        // 最終預け先住所コード
                        vo.finalLenderAddrCode,
                        // 最終預け先名称
                        vo.finalLenderName,
                        // 最終預け先住所
                        vo.finalLenderAddr,

                        // 決済NO
                        vo.settlementNo,
                        // 固定資産登録決裁番号
                        vo.fixedAssetRegistrationDecisionNumber,
                        // 仕入金額
                        vo.purchaseAmount,
                        // 仕入通貨
                        vo.purchaseCurrency,
                        // 発注番号
                        vo.orderNo,
                        // 償却開始日
                        displayUtil.date(vo.depreciationStartDate),
                        // 資産流動区分
                        displayUtil.field(assetFlowDifferentiationOption)(vo.assetFlowDifferentiation),
                        // 流動日付
                        displayUtil.date(vo.flowDate),
                        // 資産流動申請番号
                        vo.assetFlowApplyCodes?.join(','),
                        // 廃棄日
                        displayUtil.date(vo.discardDate),
                        // 登録日
                        displayUtil.date(vo.createdDate),
                        // 更新日
                        displayUtil.date(vo.modifiedDate),
                        // 更新者
                        vo.modifiedUser,
                        // 備考
                        vo.remarks,
                        // 添付ファイル有無
                        vo?.attachments?.length > 0 ? intl.formatMessage({ id: 'model.haveAttachments'}) : intl.formatMessage({ id: 'model.notHaveAttachments'}),
                        // 削除フラグ
                        displayUtil.field(deleteFlagOptions)(vo.deleteFlag),
                    ])),
                ];
                const worksheet = XLSX.utils.aoa_to_sheet(excelData);
                const workbook = XLSX.utils.book_new();
                XLSX.utils.book_append_sheet(workbook, worksheet, intl.formatMessage({ id: 'model' }));
                XLSX.writeFile(workbook, `${intl.formatMessage({ id: 'model' })}_${dayjs().format('YYYYMMDDHHmmss')}.xlsx`);
            })
            .catch(() => {

                setListData(data => ({ ...data, loading: false }));
            });

    }

    // 削除画面を表示
    const openDeletePage = (deleteModel: ModelDto[]) => {
        setSelectedDeleteModelData(deleteModel)
        setDeleteVisible(true)
    }

    // 明細画面をクローズ
    const closeDetail = () => {
        if (dto2search(searchDto) !== '') {
            goBack();
        } else {
            push('/models');
        }
    }

    // 選択のデータを削除する
    const handleDeleteSelect = (selectedDeleteModel: ModelDto[]) => {
        removeByModelList(selectedDeleteModel).then(() => {
            setSelectedDeleteModelData([])
        }).finally(() => {
            setDeleteVisible(false)
            setRefresh(!refresh)
        })
    }

    // 削除画面をクローズする
    const closeDeletePage = () => {
        setDeleteVisible(false)
        setSelectedDeleteModelData([])
    }

    // レコードを削除する
    const handleDeleteInDetail = (id: number, version: number) => {

        removeById(id, version)
            .then(() => {
                closeDetailPage()
            });
    };

    // 明細画面をクローズする
    const closeDetailPage = () => {
        if (dto2search(searchDto) !== '') {
            goBack();
        } else {
            push('/models');
        }
    }

    // TODO
    const reset = (rowCount: number) => {
        setSearch({
            assetNo: '',
            auxiliaryNo: '',
            // 金型品番
            moldNo: '',
            // 機種名
            modelName: '',
            // 品番
            item: [],
            // 品名
            itemName: '',
            // 材質
            material: '',
            // 管理
            management: '',
            // 種別
            kinds: '',
            // 一次預け先コード
            primaryLenders: [],
            // 一次預け先名称
            primaryLenderName: '',
            // 一次預け先住所
            primaryLenderAddr: '',
            // 最終預け先コード
            finalLender: '',
            // 最終預け先名称
            finalLenderName: '',
            // 最終預け先住所
            finalLenderAddr: '',
            // 最終預け先住所コード
            finalLenderAddrCode: '',
            // 取得年月日
            acquisitionDate: [null, null],
            // 削除フラグ
            deleteFlag: '',
            // 固定資産登録決裁番号
            fixedAssetRegistrationDecisionNumber: '',

            rowCount: rowCount

        } as ModelSearchDto)
    }

    const listProps = {
        // 画面loading状態
        loading: listData.loading,
        // 画面リフレッシュ状態
        refresh: refresh,
        // 件数合計
        total: listData.total,
        // 一覧画面の表示データ
        data: listData.data,
        // 検索条件（フィルター、ページとソート順）
        searchDto: searchDto,
        // 削除画面を表示
        openModelDelete: openDeletePage,
        // 検査画面を表示する
        openSearchModal: () => setSearchVisible(true),
        // アプロード画面を表示
        openUpload: (url: 'upload') => () => {
            // URLを再設定する
            push(`/models/${url}`);
        },
        //Download
        download: download,
        // 検査画面を表示する
        handleResearch: setSearch,
        handleSearch: handleSearch,
        downloadTemplate: downloadTemplate,
        reset: reset
    };

    const deleteProps = {
        visible: deleteVisible,
        close: closeDeletePage,
        selectedDeleteModelData: selectedDeleteModelData,
        handleDeleteSelect: handleDeleteSelect,
    }

    const searchProps = {
        // 検査画面の表示状態
        visible: searchVisible,
        searchDto: searchDto,
        handleSearch: (value: ModelSearchDto) => {
            setSearchVisible(false);
            setSearch(value);
        },
        close: () => setSearchVisible(false),
    };

    //  金型詳細一览
    const detailProps = {
        // 詳細画面の表示状態
        visible: detailData.visible,
        detail: detailData.data,
        handleSubmit: handleSubmit,
        close: closeDetailPage,
        handleDeleteInDetail,
    };

    const modelDetailUploadUploadProps: UploadProps = {
        visible: urlId === 'upload',
        upload: handleUpload,
        uploading: uploading,
        close: closeDetail,
    };

    return (
        <>
            <List {...listProps} />
            <Delete {...deleteProps}/>
            <Search {...searchProps} />
            <Detail {...detailProps} />
            <ModelDetailUpload {...modelDetailUploadUploadProps} />

        </>
    );
}

