import React from 'react';

import { Button, Upload, } from 'antd';
import { UploadChangeParam, UploadFile, } from "antd/es/upload/interface";
import { UploadOutlined } from '@ant-design/icons';

import './index.css';
import { Attachment } from '.';

export interface Props {
  attachments: Attachment[];
  canUpload: boolean;
  maxCount?: number;
  persist: (uuid: string) => void;
  remove: (uuid: string) => void;
}

interface FixUploadFile extends UploadFile {
  uuid?: string;
}

export function CommonFileUpload(props: Props) {

  const fileVo2UploadFile = (attachment: Attachment): FixUploadFile => {

    let name = !!attachment.name ? attachment.name : '';
    const type = !!attachment.type ? attachment.type : '';
    const size = !!attachment.size ? attachment.size : 0;
    if (name.length > 0 && type.length > 0) {
      name = `${name}.${type}`
    }

    return {
      ...attachment,
      name: name,
      type: type,
      size: size,
      uid: attachment.uuid,
      uuid: attachment.uuid,
      url: `/api/file/download/${attachment.uuid}`,
      status: 'done',
    };
  }

  const beforeUpload = (file: UploadFile) => {
    const isLimit100M = file.size / 1024 / 1024 < 100;
    if (!isLimit100M) {
      return false;
    }
    return true;
  };

  const onChange = (info: UploadChangeParam) => {

    if (info.file.status === 'done' && !!info.file.response) {

      info.fileList.forEach(entity => {
        const fixFile = entity as FixUploadFile;
        if (entity.uid === info.file.uid) {
          fixFile.url = `/api/file/download/${info.file.response}`;
          fixFile.uuid = info.file.response;
        }
      });

      props.persist(info.file.response);
    }
  };

  const onRemove = (file: UploadFile) => {
    const fixFile = file as FixUploadFile;
    if (!!fixFile.uuid) {
      props.remove(fixFile.uuid);
    }
  }

  return (
    <>

      <Upload
        action={'/api/file/upload'}
        defaultFileList={props.attachments.map(fileVo2UploadFile)}
        maxCount={props.maxCount}
        onChange={onChange}
        beforeUpload={beforeUpload}
        onRemove={onRemove}
        showUploadList={{ showRemoveIcon: props.canUpload, showDownloadIcon: props.canUpload }}>

        {props.canUpload && <Button icon={<UploadOutlined />}>Upload</Button>}
      </Upload >
    </>
  );
}

