import React, { useEffect } from "react";
import { FormattedMessage } from "react-intl";

import { Button, Col, Form, Row, Select, } from "antd";
import { Field } from "../fields";
import { filterUtil } from "../util";

interface Props {
  fields: Field[];
  values: string[];
  onChange: (values: string[]) => void;
}

export function SelectFilter(props: Props) {

  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue({ select: props.values });
  });

  const onFinish = (temp: any) => {

    props.onChange(temp.select);
  };

  const onReset = () => {

    form.setFieldsValue({ select: [] });
  };

  return (
    <Form
      className='selectFilter'
      form={form}
      onFinish={onFinish}
      colon={false}
      size={'middle'}>
      <Row>
        <Col span='16'>
          <Form.Item name='select' style={{marginBottom: '0px'}}>
            <Select<Field>
              mode={'multiple'}
              allowClear={true}
              autoClearSearchValue={false}
              filterOption={filterUtil.selectFilter}
              onSearch={filterUtil.onSearchForm(form, 'select', props.fields)}
              loading={props.fields.length === 0}
              options={props.fields} />
          </Form.Item>
        </Col>
        <Col span='8'>
          <Form.Item style={{marginBottom: '0px'}}>
            <Button className='search-search-btn' type='primary' htmlType='submit'>
              <FormattedMessage id='common.search' />
            </Button>
            <Button className='reset-btn' onClick={onReset}>
              <FormattedMessage id='common.reset' />
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
}

export function SelectFilterEnterable(props: Props) {

  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue({ select: props.values });
  });

  const onFinish = (temp: any) => {

    props.onChange(temp.select);
  };

  const onReset = () => {

    form.setFieldsValue({ select: [] });
  };

  return (
    <Form
      className='selectFilter'
      form={form}
      onFinish={onFinish}
      colon={false}
      size={'middle'}>
      <Row>
        <Col span='16'>
          <Form.Item name='select'>
            <Select<Field>
              mode={'tags'}
              allowClear={true}
              autoClearSearchValue={false}
              filterOption={filterUtil.selectFilter}
              onSearch={filterUtil.onSearchForm(form, 'select', props.fields)}
              loading={props.fields.length === 0}
              options={props.fields} />
          </Form.Item>
        </Col>
        <Col span='8'>
          <Form.Item>
            <Button className='search-search-btn' type='primary' htmlType='submit'>
              <FormattedMessage id='common.search' />
            </Button>
            <Button className='reset-btn' onClick={onReset}>
              <FormattedMessage id='common.reset' />
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
}
