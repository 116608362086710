import React, { useEffect, useState } from 'react';
import { FormattedMessage, } from 'react-intl';
import { Modal, Form, Input, Button, Select, Row, Col } from 'antd';

import { DatePicker, NumberRange, } from '../common'

import { BillingDetailSearchDto } from './api';

import './index.css';
import { Field, useFields } from '../fields';
import { filterUtil } from '../util';
import { NumberRangePicker } from '../common/NumberRangePicker';

const { RangePicker } = DatePicker;

export interface Props {
  visible: boolean;
  searchDto: BillingDetailSearchDto;
  close: () => void;
  handleSearch: (searchDto: BillingDetailSearchDto) => void;
}

export function BillingDetailSearch(props: Props) {
  return (
    <Modal
      title={<FormattedMessage tagName='h4' id='common.search' />}
      footer={null}
      visible={props.visible}
      closable={false}
      afterClose={props.close}
      destroyOnClose={true}
      width={560}
    >
      {props.visible && <BillingDetailSearchForm {...props} />}
    </Modal>
  )
}
const BillingDetailSearchForm = (props: Props) => {
  const [form] = Form.useForm<BillingDetailSearchDto>()

  const fullSupplierOptions = useFields('fullSupplier')

  useEffect(() => {
    form.setFieldsValue(props.searchDto)
  });

  const handleSubmit = (record: BillingDetailSearchDto) => {
    // 自动调换最大值和最小值的位置，让大值在右，小值在左
    const currectPurchaseAmountValue = purchaseAmountValue?.[0] && purchaseAmountValue[1] && purchaseAmountValue[0] > purchaseAmountValue[1] ? [purchaseAmountValue[1], purchaseAmountValue[0]] as NumberRange : purchaseAmountValue
    record.purchaseAmount = currectPurchaseAmountValue
    props.handleSearch(record)
  }

  // タイトル
  // 年月
  const acquisitionDateTitle = <FormattedMessage id='billingDetail.acquisitionDate' />;
  // 支払日
  const paymentDateTitle = <FormattedMessage id='pay.paymentDate' />;
  // 支払合計金額
  const totalPaymentAmountTitle = <FormattedMessage id='pay.totalPaymentAmount' />;
  // 仕入先コード
  const supplierCodeTitle = <FormattedMessage id='billingDetail.supplierCode' />;
  // 仕入先名称
  const supplierNameTitle = <FormattedMessage id='billingDetail.supplierName' />;

  // NumberRangePicker Value
  // 支払合計金額
  const [purchaseAmountValue, setPurchaseAmountValue] = useState(props.searchDto.purchaseAmount)

  return (
    <Form
      form={form}
      onFinish={handleSubmit}
      layout={'horizontal'}
      colon={false}
      size={'large'}
      labelCol={{ span: 6 }}
      wrapperCol={{ span: 16 }}
    >
      <Form.Item name='acquisitionDate' label={acquisitionDateTitle} >
        <RangePicker style={{ width: '100%' }} allowEmpty={[true, true]} allowClear={true} />
      </Form.Item>
      <Form.Item name='paymentDate' label={paymentDateTitle} >
        <RangePicker style={{ width: '100%' }} allowEmpty={[true, true]} allowClear={true} />
      </Form.Item>
      
      {/* 支払合計 */}
      <Row style={{marginBottom: '24px'}}>
        <Col span={6} style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end', paddingRight: '10px'}}>
          {totalPaymentAmountTitle}
        </Col>
        <Col span={16}>
          <NumberRangePicker values={purchaseAmountValue} onChange={record => setPurchaseAmountValue(record)} style={{ width: '100%' }} />
        </Col>
      </Row>
      <Form.Item name='suppliers' label={supplierCodeTitle}>
        <Select<Field>
          mode={'multiple'}
          className="select-mutil"
          allowClear={true}
          autoClearSearchValue={false}
          filterOption={filterUtil.selectFilter}
          onSearch={filterUtil.onSearchForm(form, 'suppliers', fullSupplierOptions)}
          loading={fullSupplierOptions.length === 0}
          options={fullSupplierOptions} />
      </Form.Item>
      <Form.Item name='supplierName' label={supplierNameTitle} >
        <Input min={1} max={40}  allowClear={true}/>
      </Form.Item> 
      <Form.Item style={{marginLeft: '20px'}}>
        <div className='search-button' style={{ paddingLeft: '20px' }}>
          <Button className='search-close-btn' onClick={props.close}>
            <FormattedMessage id='common.close' />
          </Button>
          <Button className='search-search-btn' htmlType='submit'>
            <FormattedMessage id='common.search' />
          </Button>
        </div>
      </Form.Item>
    </Form>
  )
}
