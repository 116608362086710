import React, { useEffect } from 'react';
import { FormattedMessage, } from 'react-intl';
import { Modal, Form, Input, Button, Select } from 'antd';
import { DeliverySearchDto } from './api';
import { Field, useFields } from '../fields';
import { filterUtil, } from '../util';
import { DatePicker, } from '../common'

const { RangePicker } = DatePicker;
export interface Props {
  visible: boolean;
  searchDto: DeliverySearchDto;
  close: () => void;
  handleSearch: (searchDto: DeliverySearchDto) => void;
}
export function DeliverySearch(props: Props) {

  return (
    <Modal
      title={<FormattedMessage tagName='h4' id='delivery.search' />}
      footer={null}
      visible={props.visible}
      closable={false}
      afterClose={props.close}
      destroyOnClose={true}
      width={560}>
      {props.visible && <DeliverySearchForm {...props} />}
    </Modal>
  );
}


const DeliverySearchForm = (props: Props) => {

  const [form] = Form.useForm<DeliverySearchDto>();
  const supplierOptions = useFields('fullSupplier');
  const locationCdOptions = useFields('locationName');
  const deliveryStatusOptions = useFields('deliveryStatus')

  useEffect(() => {
    form.setFieldsValue(props.searchDto);
  });

  // タイトル
  const purchaseFlagTitle = <FormattedMessage id='delivery.deliveryStatus' />;
  const locationCdTitle = <FormattedMessage id='delivery.locationCd' />;
  const estimatedTimeArrivaTitle = <FormattedMessage id='delivery.estimatedTimeArriva' />;
  const supplierTitle = <FormattedMessage id='delivery.supplier' />;
  const supplierNameTitle = <FormattedMessage id='delivery.supplierName' />;
  const deliveryNoTitle = <FormattedMessage id='delivery.deliveryNo' />;
  const userNameTitle = <FormattedMessage id='delivery.userName' />;
  const userModifiedDateTitle = <FormattedMessage id='delivery.dataCreationDate' />;

  return (
    <Form
      form={form}
      onFinish={props.handleSearch}
      layout={'horizontal'}
      colon={false}
      size={'large'}
      labelCol={{ span: 6 }}
      wrapperCol={{ span: 16 }}>
      <Form.Item name='deliveryStatus' label={purchaseFlagTitle} >
        <Select options={deliveryStatusOptions}  allowClear={true}/>
      </Form.Item>
      <Form.Item name='locationName' label={locationCdTitle} >
        <Select options={locationCdOptions}  allowClear={true}/>
      </Form.Item>
      <Form.Item name='estimatedTimeArriva' label={estimatedTimeArrivaTitle} >
        <RangePicker allowEmpty={[true, true]} style={{ width: '100%' }} allowClear={true}/>
      </Form.Item>
      <Form.Item name='deliveryNo' label={deliveryNoTitle} >
        <Input min={1} max={40} allowClear={true}/>
      </Form.Item>
      <Form.Item name='supplier' label={supplierTitle} >
        <Select<Field>
          mode={'multiple'}
          className="select-mutil"
          allowClear={true}
          autoClearSearchValue={false}
          filterOption={filterUtil.selectFilter}
          onSearch={filterUtil.onSearchForm(form, 'supplier', supplierOptions)}
          loading={supplierOptions.length === 0}
          options={supplierOptions} />
      </Form.Item>
      <Form.Item name='supplierName' label={supplierNameTitle} >
        <Input min={1} max={40} allowClear={true}/>
      </Form.Item>
      <Form.Item name='userName' label={userNameTitle} >
        <Input min={1} max={40} allowClear={true}/>
      </Form.Item>
      <Form.Item name='userModifiedDate' label={userModifiedDateTitle} >
        <RangePicker allowEmpty={[true, true]} style={{ width: '100%' }} allowClear={true}/>
      </Form.Item>
      <Form.Item style={{marginLeft: '20px'}}>
        <div className='search-button' style={{ paddingLeft: '20px' }}>
          <Button className='search-close-btn' onClick={props.close}>
            <FormattedMessage id='common.close' />
          </Button>
          <Button className='search-search-btn' htmlType='submit'>
            <FormattedMessage id='common.search' />
          </Button>
        </div>
      </Form.Item>
    </Form>
  );
}
