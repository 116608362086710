import { FormInstance } from 'antd';
import { Dayjs } from 'dayjs';

import { DateRange, NumberRange } from '../common';
import { Field } from '../fields';

export const field2Fileter = (fields: Field[]) => {

  return fields.map(
    (f) => {
      return { text: f.label, value: f.value };
    }
  );
};

export const range2stringArray = (range: DateRange): string[] => {

  if (!range) {
    return [];
  }

  const [from, to] = range;

  if (!from && !to) {
    return [];
  }

  const fromNumber = !from ? 0 : +from.startOf('day');
  const toNumber = !to ? Infinity : +to.endOf('day');

  return [`${fromNumber}~${toNumber}`];
};

export const numberRange2stringArray = (range: NumberRange): string[] => {

  if (!range) {
    return [];
  }

  const [from, to] = range;

  if (!from && !to) {
    return [];
  }

  return [`${from}~${to}`];
};

export const onRangeFilter = (fieldValue?: Dayjs, rangeString?: string) => {

  if (!rangeString || !rangeString.includes('~')) {
    return true;
  }

  if (!fieldValue) {
    return false;
  }

  const strs = rangeString.split("~");

  const [from, to] = strs.map(str => Number(str));
  // fieldValueのタイプが文字列の可能性があります。
  const fieldNumber = +fieldValue;

  return fieldNumber >= from && fieldNumber <= to;
};

export const selectFilter = (inputValue: string, options: any): boolean => {

  if (!!inputValue && !!options && !!options.value && !!options.label) {
    inputValue = inputValue.trim();
    if (inputValue.length === 0) {
      return false;
    }

    if (inputValue.split(/[ ,\t\n]/).some(word => wordFilter(word, options))) {
      return true;
    }
  }
  return false;
};

const wordFilter = (word: string, options: any): boolean => {
  word = word.trim();
  if (word.length === 0) {
    return false;
  }
  return options['value'].includes(word) || options['label'].includes(word);
}

export const onSearchForm = (form: FormInstance<any>, key: string, options: Field[]) => (inputValue: string) => {

  const current = form.getFieldValue(key);

  const merged = selectSearch(current, options)(inputValue);

  form.setFieldsValue({ select: merged });
}

const selectSearch = (current: any, options: Field[]) => (inputValue?: string): string[] => {
  if (!inputValue) {
    return current;
  }

  const words = inputValue.split(/[,\t]/).map(map2Option(options)).filter(v => !!v) as string[];

  if (words.length === 0) {
    return current;
  }

  return mergeInputs(current, words);
};

const map2Option = (options: Field[]) => (word: string): string | undefined => {
  word = word.trim();
  if (word.length === 0) {
    return undefined;
  }

  for (let option of options) {
    if (option.value === word || option.label === word) {
      return option.value;
    }
  }

  return undefined;
}

const mergeInputs = (current: any, words: string[]): string[] => {
  if (!current) {
    return words;
  }

  if (typeof (current) === 'string') {

    const filted = words.filter(word => current !== word);

    return [current, ...filted];
  }
  else if (typeof (current) === 'object' && current instanceof Array && current.length !== 0) {

    const filted = words.filter(word => !current.includes(word));

    return [...current, ...filted];
  }

  return words;
}
