import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl, } from 'react-intl';

import { Button, Table, Form, Popconfirm } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { HistoryOutlined } from '@ant-design/icons';

import './index.css';
import { useUser } from '../authorization';
import { EditableCellNumberCheck, OrderResponseEditableCellNumberCheck } from '../common'
import { displayUtil } from '../util';

import { DeliveryInfoRecordDto, OrderListRecordDto, DetailRecordDto } from './api';

export interface Props {
  detail?: OrderListRecordDto;
  editingDetail?: DetailRecordDto;
  gap: number;
  loading: boolean;
  setEditingDetail: (dto?: DetailRecordDto) => void;
  save: (record: DetailRecordDto) => void;
  remove: (record: DetailRecordDto) => void;
  showHistory: () => void;
}

export function OrderResponseDetailDeliveryInfo(props: Props) {

  // 共通のstates
  const intl = useIntl();

  const [form] = Form.useForm();
  const user = useUser();

  const [dataSource, setDataSource] = useState<DeliveryInfoRecordDto[]>([]);
  const [addGap, setAddGap] = useState<number>(0);

  useEffect(
    () => {
      if (!!props.detail) {
        if (!!props.editingDetail && props.editingDetail.type === 'DeliveryInfo') {
          form.resetFields();
          form.setFieldsValue(props.editingDetail);
          if (props.editingDetail.id <= 0) {
            const tempDto = props.editingDetail as DeliveryInfoRecordDto;
            setDataSource([...props.detail.deliveryDetails, tempDto]);
            setAddGap(!!tempDto.quantity ? tempDto.quantity : 0);
            return;
          }
        }
        setDataSource(props.detail.deliveryDetails);
        return;
      }
      setDataSource([]);
    }, [props.detail, props.editingDetail]
  );

  const isEditing = (record: DeliveryInfoRecordDto) => (!!props.editingDetail && props.editingDetail.type === 'DeliveryInfo' && props.editingDetail.id === record.id);

  const submitForm = (record: DeliveryInfoRecordDto) => {
    form.validateFields()
      .then((row) => {

        const dto: DeliveryInfoRecordDto = { ...record, ...row, };

        props.save(dto);
      })
      .catch((errInfo) => {
        throw errInfo;
      });
  };

  // 編集ボタン関連
  const editButtons = (text: any, record: DeliveryInfoRecordDto, index?: number) => {

    // 編集中の場合、保存とキャンセルボタンを表示する
    if (isEditing(record)) {
      return (<>
        <Popconfirm
          title={intl.formatMessage({ id: 'message.editConfirm' })}
          onConfirm={() => submitForm(record)}
          okText={intl.formatMessage({ id: 'common.confirm' })}
          cancelText={intl.formatMessage({ id: 'common.cancel2' })} >
          <Button type='link'>
            <FormattedMessage id='common.save' />
          </Button>
        </Popconfirm>
        <Button type='link' onClick={() => props.setEditingDetail(undefined)}>
          <FormattedMessage id='common.cancel2' />
        </Button>
      </>)
    }

    // サプライヤー且つ出荷していない場合だけは編集可能
    if (user?.isSupplier) {
      return (<>
        <Button type='link' onClick={() => props.setEditingDetail(record)} disabled={!!props.editingDetail || !(!record.deliveryId || record.deliveryId < 0)}>
          <FormattedMessage id='common.edit' />
        </Button>
        <Popconfirm disabled={!!props.editingDetail || !(!record.deliveryId || record.deliveryId < 0)}
          title={intl.formatMessage({ id: 'message.cancel2Confirm' })}
          onConfirm={() => props.remove(record)}

          okText={intl.formatMessage({ id: 'common.yes' })}
          cancelText={intl.formatMessage({ id: 'common.no' })} >
          <Button type='link' disabled={!!props.editingDetail || !(!record.deliveryId || record.deliveryId < 0)}>
            <FormattedMessage id='common.cancel2' />
          </Button>
        </Popconfirm>
      </>);
    }
  }

  const dateWidth = '210px';
  const detailNoWidth = '70px';
  const planDeliveryQuantityWidth = '100px';
  const remarksWidth = '280px';
  const operationWidth = '130px';

  // 回答数量
  const planDeliveryQuantityTitle = <FormattedMessage id='orderResponse.planDeliveryQuantity' />;
  // 明細番号
  const detailNoTitle = <FormattedMessage id='orderResponse.detailNo' />;
  // 着荷日
  const estimatedTimeArrivaTitle = <FormattedMessage id='orderResponse.estimatedTimeArriva' />;
  // 出荷日
  const shipDateTitle = <FormattedMessage id='orderResponse.shipDate' />;
  // 備考
  const remarksTitle = <FormattedMessage id='orderResponse.remarks' />;

  const deliveryInfoColumns: any = [
    // 明細番号
    { title: detailNoTitle, dataIndex: 'detailNo', width: detailNoWidth },
    // 出荷日
    { title: shipDateTitle, dataIndex: 'shipDate', editable: true, type: 'date', render: displayUtil.date, required: true, width: dateWidth },
    // 着荷日
    { title: estimatedTimeArrivaTitle, dataIndex: 'estimatedTimeArriva', editable: true, type: 'date', render: displayUtil.date, width: dateWidth },
    // 出荷数量
    { title: planDeliveryQuantityTitle, dataIndex: 'quantity', editable: true, gap: props.gap - addGap, type: 'number', width: planDeliveryQuantityWidth, maxLength: 100, },
    //備考
    { title: remarksTitle, dataIndex: 'remarks', editable: true, type: 'string', width: remarksWidth, required: true },
    // アクション
    { title: '', dataIndex: 'operation', render: editButtons, width: operationWidth },
  ];

  const mergedDeliveryInfoColumns: ColumnsType<DeliveryInfoRecordDto> = deliveryInfoColumns.map(
    (col: any) => {
      if (!col.editable) {
        return col;
      }

      return {
        ...col,
        onCell: (record: DeliveryInfoRecordDto) => {
          
          const numberInputValidator = (value: any): string | undefined => {

            if(value === undefined || value === null) {
              return intl.formatMessage({id: 'estimate.nullError'}, {name: intl.formatMessage({id: col.title?.props?.id})})
            }

            const valueDecimalIsOverThree = value !== +( +value.toFixed(3)) // 将输入的数字四舍五入保留三位小数截取，如果截取前的数和截取后的数相等，则证明输入的数字小数点小于三位小数

            if(value <= 0) {
              return intl.formatMessage({id: 'message.onlyPositiveNumber'})
            } else if(value > col.gap + (!!record.quantity ? record.quantity : 0)) {
              return intl.formatMessage({id: 'message.deliveryResponseQtyOver'})
            } else if (valueDecimalIsOverThree) {
              return intl.formatMessage({ id: 'message.DigitRetained2' }, { n: '3' })
            } else { 
              return undefined
            }
          }

          return {
            record,
            inputType: col.type,
            dataIndex: col.dataIndex,
            title: col.title,
            requiredFlag: col.required,
            max: col.gap + (!!record.quantity ? record.quantity : 0),
            editing: isEditing(record),
            notAllowValues: [],
            numberInputValidator: numberInputValidator,
            maxLength: col.maxLength,
          }
        },
      };
    });

  const titleButtons = () => {
    if (!(user?.isSupplier)) {
      return (<></>);
    }

    if (!!props.editingDetail) {
      if (props.editingDetail.type === 'DeliveryInfo') {
        return (<Button onClick={() => props.setEditingDetail(undefined)} style={{ marginLeft: '20px' }}>
          <FormattedMessage id='common.cancel' />
        </Button>);
      } else {
        return (<></>);
      }
    }

    if (props.gap === 0) {
      return (<></>);
    }

    return (<Button onClick={() => props.setEditingDetail({ id: -1, type: 'DeliveryInfo' })} style={{ marginLeft: '20px' }}>
      <FormattedMessage id='common.add' />
    </Button>);
  };

  const title = () => (<>
    <FormattedMessage id='orderResponse.deliveryInfoList' />
    {titleButtons()}
    <Button onClick={props.showHistory} style={{ marginLeft: '20px' }} >
      <HistoryOutlined />
      <FormattedMessage id='common.history' />
    </Button>
  </>)

  return (<Form form={form} component={false}>
    <Table<DeliveryInfoRecordDto>
      title={title}
      className="list-detail-table"
      bordered={false}
      loading={props.loading}
      rowClassName={displayUtil.rowClassName}
      components={{
        body: {
          // cell: EditableCell,
          cell: OrderResponseEditableCellNumberCheck
        },
      }}
      dataSource={dataSource}
      columns={mergedDeliveryInfoColumns}
      pagination={false}
      size='small'
      style={{ transform: 'translateY(-50px)' }}
      scroll={{ scrollToFirstRowOnChange: true, y: '150px' }}
    />
  </Form>);
}

