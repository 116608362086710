import React, { useEffect, useState } from 'react';
import { FormattedMessage, } from 'react-intl';
import { Modal, Form, Input, Select, Button, Row, Col, } from 'antd';

import { DatePicker, NumberRange, } from '../common'
import { filterUtil, } from '../util'
import { OrderResponseSearchDto } from './api';
import './index.css';
import { Field, useFields } from '../fields';
import { useUser } from '../authorization';
import { NumberRangePicker } from '../common/NumberRangePicker';

const { RangePicker } = DatePicker;

export interface Props {
  visible: boolean;
  searchDto: OrderResponseSearchDto;
  close: () => void;
  handleSearch: (searchDto: OrderResponseSearchDto) => void;
}

export function OrderResponseSearch(props: Props) {

  console.log(props.searchDto);
  return (
    <Modal
      title={<FormattedMessage tagName='h4' id='common.search' />}
      width={560}
      footer={null}
      visible={props.visible}
      closable={false}
      afterClose={props.close}
      destroyOnClose={true}
      style={{ top: '50px' }}>
      {props.visible && <OrderResponseSearchForm {...props} />}
    </Modal>
  );
}

const OrderResponseSearchForm = (props: Props) => {

  const [form] = Form.useForm<OrderResponseSearchDto>();
  const user = useUser();

  const itemOptions = useFields('item');
  const supplierOptions = useFields('fullSupplier');
  const infoTypeOptions = useFields('infoType');

  useEffect(() => {
    form.setFieldsValue(props.searchDto);
  });

  const handleSubmit = (record: OrderResponseSearchDto) => {
    // 自动调换最大值和最小值的位置，让大值在右，小值在左
    const currectUnitPriceValue = unitPriceValue?.[0] && unitPriceValue[1] && unitPriceValue[0] > unitPriceValue[1] ? [unitPriceValue[1], unitPriceValue[0]] as NumberRange : unitPriceValue
    const currectAmountValue = amountValue?.[0] && amountValue[1] && amountValue[0] > amountValue[1] ? [amountValue[1], amountValue[0]] as NumberRange : amountValue
    const currectBalanceAmountValue = balanceAmountValue?.[0] && balanceAmountValue[1] && balanceAmountValue[0] > balanceAmountValue[1] ? [balanceAmountValue[1], balanceAmountValue[0]] as NumberRange : balanceAmountValue

    record.unitPrice = currectUnitPriceValue
    record.amount = currectAmountValue
    record.balanceAmount = currectBalanceAmountValue

    props.handleSearch(record)
  }

  // 注文番号
  const orderNoTitle = <FormattedMessage id='orderDetail.orderNo' />;
  // 受注者コード
  const supplierTitle = <FormattedMessage id='orderDetail.supplier' />;
  // 受注者名称
  const supplierNameTitle = <FormattedMessage id='orderDetail.supplierName' />;
  // 注文年月日
  const orderDateTitle = <FormattedMessage id='orderDetail.orderDate' />;
  // 品番
  const itemTitle = <FormattedMessage id='orderDetail.item' />;
  // 品名
  const itemNameTitle = <FormattedMessage id='orderDetail.itemName' />;
  // 情種類
  const infoTypeTitle = <FormattedMessage id='orderDetail.infoType' />;
  // 納期
  const iniDeliveryDateTitle = <FormattedMessage id='orderDetail.iniDeliveryDate' />;
  // 再計画日
  const productionPlanDateTitle = <FormattedMessage id='orderDetail.productionPlanDate' />;
  // 単価
  const unitPriceTitle = <FormattedMessage id='orderDetail.unitPrice' />;
  // 金額
  const amountTitle = <FormattedMessage id='orderDetail.amount' />;
  // 注文残金額
  const balanceAmountTitle = <FormattedMessage id='orderDetail.balanceAmount' />;
  // 購買担当
  const purchasingManagerTitle = <FormattedMessage id='orderDetail.purchasingManager' />;
  // 保管場所
  const locationNameTitle = <FormattedMessage id='orderDetail.locationCd' />;

  const [unitPriceValue, setUnitPriceValue] = useState(props.searchDto.unitPrice)
  const [amountValue, setAmountValue] = useState(props.searchDto.amount)
  const [balanceAmountValue, setBalanceAmountValue] = useState(props.searchDto.balanceAmount)

  return (
    <Form
      form={form}
      onFinish={handleSubmit}
      layout={'horizontal'}
      colon={false}
      size={'large'}
      labelCol={{ span: 6 }}
      wrapperCol={{ span: 16 }}>
      <Form.Item name='orderNo' label={orderNoTitle} style={{ marginBottom: '10px' }}>
        <Input min={1} max={40} allowClear={true} />
      </Form.Item>
      <Form.Item name='suppliers' label={supplierTitle} style={{ marginBottom: '5px' }} >
        <Select<Field>
          mode={'multiple'}
          className="select-mutil"
          allowClear={true}
          autoClearSearchValue={false}
          filterOption={filterUtil.selectFilter}
          onSearch={filterUtil.onSearchForm(form, 'suppliers', supplierOptions)}
          loading={supplierOptions.length === 0}
          options={supplierOptions}
          style={{ maxHeight: '100px' }} />
      </Form.Item>
      <Form.Item name='supplierName' label={supplierNameTitle} style={{ marginBottom: '10px' }} >
        <Input min={1} max={40} allowClear={true} />
      </Form.Item>
      <Form.Item name='orderDate' label={orderDateTitle} style={{ marginBottom: '10px' }}>
        <RangePicker allowEmpty={[true, true]} style={{ width: '100%' }} allowClear={true} />
      </Form.Item>
      <Form.Item name='items' label={itemTitle} style={{ marginBottom: '5px' }} >
        <Select<Field>
          mode={'multiple'}
          className="select-mutil"
          allowClear={true}
          autoClearSearchValue={false}
          filterOption={filterUtil.selectFilter}
          onSearch={filterUtil.onSearchForm(form, 'items', itemOptions)}
          loading={itemOptions.length === 0}
          options={itemOptions}
          style={{ maxHeight: '100px' }} />
      </Form.Item>
      <Form.Item name='itemName' label={itemNameTitle} style={{ marginBottom: '10px' }} >
        <Input min={1} max={40} allowClear={true} />
      </Form.Item>
      <Form.Item name='infoType' label={infoTypeTitle} style={{ marginBottom: '10px' }} >
        <Select options={infoTypeOptions} allowClear={true} />
      </Form.Item>
      <Form.Item name='iniDeliveryDate' label={iniDeliveryDateTitle} style={{ marginBottom: '10px' }}>
        <RangePicker allowEmpty={[true, true]} style={{ width: '100%' }} allowClear={true} />
      </Form.Item>
      {user?.serviceState === 'cn' &&
        <Form.Item name='productionPlanDate' label={productionPlanDateTitle} style={{ marginBottom: '10px' }}>
          <RangePicker allowEmpty={[true, true]} style={{ width: '100%' }} allowClear={true} />
        </Form.Item>
      }
      {/* 単価 */}
      <Row style={{marginBottom: '10px'}}>
        <Col span={6} style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end', paddingRight: '10px'}}>
          {unitPriceTitle}
        </Col>
        <Col span={16}>
          <NumberRangePicker values={unitPriceValue} onChange={record => setUnitPriceValue(record)} style={{ width: '100%' }} />
        </Col>
      </Row>
      {/* 金額 */}
      <Row style={{marginBottom: '10px'}}>
        <Col span={6} style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end', paddingRight: '10px'}}>
          {amountTitle}
        </Col>
        <Col span={16}>
          <NumberRangePicker values={amountValue} onChange={record => setAmountValue(record)} style={{ width: '100%' }} />
        </Col>
      </Row>
      {/* 注文残金額 */}
      <Row style={{marginBottom: '10px'}}>
        <Col span={6} style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end', paddingRight: '10px'}}>
          {balanceAmountTitle}
        </Col>
        <Col span={16}>
          <NumberRangePicker values={balanceAmountValue} onChange={record => setBalanceAmountValue(record)} style={{ width: '100%' }} />
        </Col>
      </Row>
      <Form.Item name='purchasingManagerName' label={purchasingManagerTitle} style={{ marginBottom: '10px' }} >
        <Input min={1} max={40} allowClear={true} />
      </Form.Item>
      <Form.Item name='locationCd' label={locationNameTitle} style={{ marginBottom: '10px' }} >
        <Input min={1} max={10} allowClear={true} />
      </Form.Item>
      <Form.Item style={{ marginLeft: '20px' }}>
        <div className='search-button' style={{ paddingLeft: '20px' }}>
          <Button className='search-close-btn' onClick={props.close}>
            <FormattedMessage id='common.close' />
          </Button>
          <Button className='search-search-btn' htmlType='submit'>
            <FormattedMessage id='common.search' />
          </Button>
        </div>
      </Form.Item>
    </Form>
  );
};
