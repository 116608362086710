import React, { useEffect } from 'react';
import { FormattedMessage, } from 'react-intl';
import { Modal, Form, Input, Select, Button, } from 'antd';

import { DatePicker, } from '../common'
import { filterUtil, } from '../util'
import { SupplierEstimatesSearchDto } from './api';
import './index.css';
import { Field, useFields } from '../fields';
import { useUser } from '../authorization';

const { RangePicker } = DatePicker;

export interface Props {
  visible: boolean;
  searchDto: SupplierEstimatesSearchDto;
  close: () => void;
  handleSearch: (searchDto: SupplierEstimatesSearchDto) => void;
}

export function SupplierEstimatesSearch(props: Props) {

  return (
    <Modal
      title={<FormattedMessage tagName='h4' id='common.search' />}
      footer={null}
      visible={props.visible}
      closable={false}
      afterClose={props.close}
      destroyOnClose={true}
      width={560}>
      {props.visible && <SupplierEstimatesSearchForm {...props} />}
    </Modal>
  );
}

const SupplierEstimatesSearchForm = (props: Props) => {

  const user = useUser();
  const statusOptions = useFields('status');
  const supplierOptions = useFields('fullSupplier');
  const buyerOptions = useFields('buyer');
  const [form] = Form.useForm<SupplierEstimatesSearchDto>();

  useEffect(() => {
    form.setFieldsValue(props.searchDto);
  });

  // 案件状態 
  const statusTitle = <FormattedMessage id='estimate.status' />;
  // 入札依頼番号
  const requestNoTitle = <FormattedMessage id='estimate.requestNo' />;
  // 購入元コード
  const buyerTitle = <FormattedMessage id='estimate.buyer' />;
  // 購入先コード 
  const supplierTitle = <FormattedMessage id='estimate.supplier' />;
  // 購買担当
  const purchasingManagerTitle = <FormattedMessage id='estimate.purchasingManager' />;
  // 依頼日
  const requestDateTitle = <FormattedMessage id='estimate.requestDate' />;
  // 受注者名称
  const supplierNameTitle = <FormattedMessage id='estimate.supplierName' />;

  return (
    <Form
      form={form}
      onFinish={props.handleSearch}
      layout={'horizontal'}
      colon={false}
      size={'large'}
      labelCol={{ span: 6 }}
      wrapperCol={{ span: 16 }}>
      <Form.Item name='requestNo' label={requestNoTitle} >
        <Input min={1} max={40} />
      </Form.Item>
      <Form.Item name='suppliers' label={supplierTitle} >
        <Select<Field>
          mode={'multiple'}
          allowClear={true}
          autoClearSearchValue={false}
          filterOption={filterUtil.selectFilter}
          onSearch={filterUtil.onSearchForm(form, 'suppliers', supplierOptions)}
          loading={supplierOptions.length === 0}
          options={supplierOptions} />
      </Form.Item>
      <Form.Item name='supplierName' label={supplierNameTitle} >
        <Input min={1} max={40} allowClear />
      </Form.Item>
      {/* {user?.isSupplier &&
        <Form.Item name='buyers' label={buyerTitle} >
          <Select<Field>
            mode={'multiple'}
            allowClear={true}
            autoClearSearchValue={false}
            filterOption={filterUtil.selectFilter}
            onSearch={filterUtil.onSearchForm(form, 'buyers', buyerOptions)}
            loading={buyerOptions.length === 0}
            options={buyerOptions} />
        </Form.Item>
      } */}
      <Form.Item name='purchasingManagerName' label={purchasingManagerTitle} >
        <Input min={1} max={40} />
      </Form.Item>
      <Form.Item name='requestDate' label={requestDateTitle}>
        <RangePicker allowEmpty={[true, true]} style={{ width: '100%' }} />
      </Form.Item>
      <Form.Item name='status' label={statusTitle} >
        <Select<Field>
          mode={'multiple'}
          allowClear={true}
          autoClearSearchValue={false}
          filterOption={filterUtil.selectFilter}
          onSearch={filterUtil.onSearchForm(form, 'statuses', statusOptions)}
          loading={statusOptions.length === 0}
          options={statusOptions} />
      </Form.Item>
      <Form.Item style={{marginLeft: '20px'}}>
        <div className='search-button' style={{ paddingLeft: '20px' }}>
          <Button className='search-close-btn' onClick={props.close}>
            <FormattedMessage id='common.close' />
          </Button>
          <Button className='search-search-btn' htmlType='submit'>
            <FormattedMessage id='common.search' />
          </Button>
        </div>
      </Form.Item>
    </Form>
  );
};
