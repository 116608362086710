import { Layout, Spin } from 'antd';
import React, { useEffect } from 'react';
import { useUser } from '../authorization';
import { PermissionDenied } from '../permissionDenied';
import { SuppierCommnunityHeader } from './SuppierCommnunityHeader';
import { SuppierCommnunityRouter } from './SuppierCommnunityRouter';
import { SuppierCommnunityFooter } from './SuppliercommunityFooter';


export function SuppierCommnunity() {

  const user = useUser();

  return (
    !!user ?
      <Layout>
        <SuppierCommnunityHeader />
        {
          user.auth ?
            <SuppierCommnunityRouter />
            :
            <PermissionDenied />
        }
        <SuppierCommnunityFooter />
      </Layout>
      :
      <Spin size="large" />
  );
}